import React, {Component} from 'react';
import './Notes.css'
import axios from "axios/index";
import { Alert } from 'react-bootstrap';


export class Notes extends Component{
   constructor(props){
       super(props);
           this.state = {
               user : {},
               noteTitle: '',
               notes: '',
               show: false,
               alertStyle: true,
               alert: '',
               tileId: null,
               tileList: [],
               disabled: '',
       };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
   }

    componentDidMount() {
        if(this.props.step4Child){this.setState({disabled:'disabled'})}
        if(this.props.notesSingleChild){
          console.log("notesSingleChild");
          axios.get(`/tiles/list/${this.props.username}`)
              .then((res)=>{
                  console.log(res.data.result);
                  this.setState({tileList: res.data.result});
                  console.log(this.state.tileList);
              });
        } 
    }



   publishNote() {
        this.props.newNoteActive(true);
        console.log(this.props.username)
        console.log(this.props.noteTitle)
        console.log(this.props.notes)
        console.log(this.state.tileId)
      axios.post('/notes/create', { 
        username : this.props.username,
        title : this.props.noteTitle,
        content: this.props.notes,
        tileId : this.state.tileId,
        parent : null,
        }
         )
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({ show: true });
                this.setState({ alert: 'success' });
                this.setState({ alertStyle: true });
                if(this.props.notesSingleChild){
                  this.props.refreshNotes(true);
                }
            }
            if(res.status !== 200){
                this.setState({ show: true });
                this.setState({alert:'error'});
                this.setState({ alertStyle: false });
                }}).catch(error => {
                this.setState({alert:'error'});
                this.setState({ alertStyle: false });
            });
   }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

   noteTitleChange = (e) => {
    this.props.noteTitleHandler(e.target.value);
    this.setState({noteTitle: e.target.value});
  };

   notesChange = (e) => {
    this.props.notesHandler(e.target.value);
    this.setState({notes: e.target.value});
  };

createList=()=>{
        if(this.state.tileList){
            return this.state.tileList.map((item, i)=>{
                   return(
                       <li>
                          <span className="capitalize"><input className="inline-radio" name="radioNote" onChange={()=>this.getId(i)} type="radio" />{item.tile_category} {item.plan.length > 1 ? 'Activity' : item.plan[0].description}</span>
                       </li>

                   )
            });
        }
    };

    setNoteTitle(){
        if(this.props.hasOwnProperty('notes') && this.props.notes !== null){
            return this.props.notes.title
        }else{
            return this.props.noteTitle ? this.props.noteTitle : this.state.noteTitle
        }
    }

    setNoteContent(){
        if(this.props.hasOwnProperty('notes')){
            return this.props.notes.content
        }else{ return " ";}
    }

  getId = (i, tileList) => {
      this.setState({tileId: this.state.tileList[i].tile.id}, () => {
        console.log(this.state.tileId);
      })    
 
    // if(this.state.checked === false) {
    //   this.setState({tileId: this.state.tileList[i].tile.id}, () => {
    //     console.log(this.state.tileId);
    //   })    
    // }
    // else{
    //   this.setState({tileId: null}, () => {
    //     console.log(this.state.tileId);
    //   })      
    // }

    // this.setState({checked: !this.state.checked}, () => {
    //   console.log(this.state.checked)
    // })
  }

    render(){

      let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
      let cls = this.props.singleTileChild || this.props.notesSingleChild ? "notes-container col-md-12 padding-bottom" : "notes-container col-md-6 col-xs-12";
        return(
            <div className={cls}>
                <div>
                    <label className="notes-title-label">Note Title</label>
                    {this.props.step4Child
                      ? <input type="text"
                           disabled
                           className='notes-title col-md-12'
                           placeholder='Note Title'
                           onChange={this.noteTitleChange}
                           value={this.props.noteTitle}
                     />   
                      : <input type="text"
                           className='notes-title col-md-12'
                           placeholder='Starting Protein'
                           onChange={this.noteTitleChange}
                           value={this.props.noteTitle}
                     /> 
                    }
                </div>            
                <div>
                    <label className="notes-label">Notes</label>
                    {this.props.step4Child
                      ? <textarea 
                           disabled
                           type="text"
                           className='notes col-md-12'
                           placeholder='notes'
                           onChange={this.notesChange}
                           value={this.props.noteContent}
                           />    
                      : <textarea 
                         type="text"
                         className='notes col-md-12'
                         placeholder='notes'
                         onChange={this.notesChange}
                         value={this.props.noteContent}
                         />  
                    }
                          
                </div>
                {/*<div>
                    <input id="checkBox" checked="" type="checkbox" /><span>Flag for review/feedback from Team Lead (Karlie)</span>
                </div>*/}
                {this.props.notesSingleChild
                  ? <div className="notes-radio-container col-md-6">
                      <ul className="no-list-style">
                        {this.createList()}
                      </ul>
                    </div>
                  : null
                }
                {this.props.step4Child || this.props.step3Child
                  ? null
                  : 
                      <div>
                          <input className="btn primary" onClick={()=>this.publishNote()} type="submit" value="Post Note" />
                      </div>
                }
                {this.state.show
                  ?  <Alert bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                        <p>{this.state.alert}</p>
                      </Alert>
                  : null
                }
            </div>

        )

    }
}
