import React from 'react';
import {Route, withRouter} from 'react-router-dom'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';


const CreateSteps = ({match, username}) =>{
    return (
        <div class="container tile-creation">
            <Route
                path={match.url+"/step1"}
                render={()=><Step1 match={match} username={username}/>}
            />
            <Route
                path={match.url+"/step-2"}
                render={()=><Step2 match={match} username={username}/>}
            />
            <Route
                path={match.url+"/step-3"}
                render={()=><Step3 match={match} username={username}/>}
            />
            <Route
                path={match.url+"/step-4"}
                render={()=><Step4 match={match} username={username}/>}
            />
        </div>
    );
};

export default withRouter(CreateSteps);