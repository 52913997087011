import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// styles
import styles from "./clientHeader.module.css";

// actions
import { checkLogin, registerSession } from "../../store/actions/authActions";
import { fetchSelf } from "../../actions/action_user";
import { setCompliance } from "../../actions/action_policy";
import { getDailyScore } from "../../store/actions/wellnessScoreActions";
import { checkCompliance } from "../../store/actions/policyActions";
import { fetchUnreadInbox } from "../../store/actions/postsActions";
import { fetchUserCoaches } from "../../store/actions/userActions";

// components
import PhotoContainer from "../profilePhotos/container";
import TreoLogo from "../header-logo.png";
import { STATUSES } from "../../serverVariables/statuses";
import { RANKS } from "../../serverVariables/ranks";
import { useWindowSize } from "../../hooks/useWindowSize";
import BackButton from "../BackButton";

const ClientHeader = (props) => {
  // state
  const { user, complianceCheck, dailyScore, alertOptions } = props;
  // props
  const { compliance, type = "thin", backUrl } = props;
  // actions
  const {
    checkLogin,
    fetchSelf,
    setCompliance,
    getDailyScore,
    registerSession,
  } = props;

  const [state, updateState] = useState({
    loggedIn: true,
    username: "",
    redirect: false,
    status: "",
    unread: null,
    multipleCoaches: false,
    coachUsername: null,
    noCoach: true,
    logo: null,
    showModal: false,
  });
  const sessionTime = useRef(0);

  const { width } = useWindowSize();

  function setState(value) {
    updateState((prevState) => ({
      ...prevState,
      ...value,
    }));
  }

  const mount = () => {
    window.scrollTo(0, 0);
    //check if a person is logged in
    checkLogin().then((res) => {
      const onboarding =
        window.location.href.indexOf("Onboard") > 0 ||
        window.location.href.indexOf("onboard") > 0;
      const profile =
        window.location.href.indexOf("profile") > 0 ||
        window.location.href.indexOf("account") > 0 ||
        window.location.href.indexOf("coach") > 0 ||
        window.location.href.indexOf("inbox") > 0 ||
        window.location.href.indexOf("policies") > 0 ||
        window.location.href.indexOf("privacy") > 0;
      if (!res.data && !onboarding) {
        window.location = "/";
      }
      // push to assessment if you've agreed to policies but are still onboarding
      if (
        !onboarding &&
        !profile &&
        res.data.user_status_id < STATUSES.onboarding_coach_consult_pending &&
        res.data.user_status_id > STATUSES.onboarding_terms_of_service
      ) {
        window.location = "/member/onboard-assessment";
      }
      setState({ username: props.match.params.username });
      fetchSelf().then((res) => {
        const { user } = res.payload.data;
        if (user.organization.brand_switch) {
          setState({ logo: user.organization.logo || TreoLogo });
        } else {
          setState({ logo: TreoLogo });
        }
        // run compliance check
        checkCompliance(user.username)
          .then((complianceRes) => {
            setCompliance(complianceRes);
          })
          .catch((e) => console.log(e));

        fetchUnreadInbox(user.username)
          .then((res) => {
            if (res > 0) setState({ unread: res });
          })
          .catch((e) => console.log(e));
      });
    });
    getCoach();
  };

  const getCoach = () => {
    fetchUserCoaches().then((result) => {
      if (result.length > 1) {
        setState({ multipleCoaches: true, noCoach: false });
      } else if (result.length === 0) {
        setState({ multipleCoaches: true, noCoach: true });
      } else {
        setState({ coachUsername: result[0].username, noCoach: false });
      }
    });
  };

  function notify(time) {
    sessionTime.current = 0;
    registerSession(time);
  }

  useEffect(mount, []);

  useEffect(() => {
    notify(0);
    const interval = setInterval(() => {
      const newTime = sessionTime.current + 1;
      sessionTime.current = newTime;
      if (newTime > 3600) {
        clearInterval(interval);
      } else if (newTime % (60 * 5) === 0) {
        notify(60 * 5);
      }
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
        notify(sessionTime.current);
      }
    };
  }, []);

  // get wellness score for user
  useEffect(() => {
    getDailyScore();
  }, [getDailyScore]);

  let scoreClass = "noScore";
  const goal = alertOptions.daily_goal || 1;
  if (
    Math.round(dailyScore / goal) * 100 >= 0 &&
    Math.round(dailyScore / goal) * 100 < 50
  ) {
    scoreClass = "low";
  } else if (
    Math.round(dailyScore / goal) * 100 >= 50 &&
    Math.round(dailyScore / goal) * 100 < 75
  ) {
    scoreClass = "average";
  } else if (
    Math.round(dailyScore / goal) * 100 >= 75 &&
    Math.round(dailyScore / goal) * 100 < 90
  ) {
    scoreClass = "high";
  } else if (Math.round(dailyScore / goal) * 100 >= 90) {
    scoreClass = "highest";
  }

  // send to compliance screen if user hasn't agreed to policies
  if (!complianceCheck && !compliance) {
    return <Redirect to={`/member/clientDash/${user.username}/policies`} />;
  }
  if (!user) return <div />;
  const brandSwitch =
    user.organization.brand_switch && user.organization.bg_color;
  const colorSwitch =
    user.organization.brand_switch && user.organization.text_color;
  let styleClass = "";
  let stylesObj = {};
  if (!brandSwitch) {
    if (type === "transparent") {
      styleClass = `wellness-bg header-overlay`;
    } else {
      styleClass = `wellness-bg thin ${scoreClass}`;
    }
  } else {
    stylesObj.background = user.organization.bg_color;
    styleClass = `wellness-bg thin`;
  }
  if (type === "clear" && !brandSwitch) styleClass = "";

  let rankBorder = "";
  const rank = user.game.rank_name.toLowerCase();
  if (rank === RANKS.BRONZE) rankBorder = styles.bronzeRank;
  if (rank === RANKS.SILVER) rankBorder = styles.silverRank;
  if (rank === RANKS.GOLD) rankBorder = styles.goldRank;
  if (rank === RANKS.PLATINUM) rankBorder = styles.platinumRank;

  return (
    <div style={stylesObj} className={styleClass}>
      <div className="header-overlay">
        <Navbar className={`${styles.links} ${styles.nav}`}>
          <Navbar.Header className="hidden-xs">
            {width > 767 ? (
              <Navbar.Brand>
                {backUrl ? (
                  <div className={`${styles.back}`} data-cy="backBtn">
                    <BackButton to={backUrl} color="white" />
                  </div>
                ) : (
                  <Link to={user ? `/member/clientDash/${user.username}` : ""}>
                    {state.logo ? (
                      <img
                        className="header-logo"
                        src={state.logo}
                        alt="Treo Logo"
                      />
                    ) : null}
                  </Link>
                )}
              </Navbar.Brand>
            ) : null}
          </Navbar.Header>

          <Nav>
            {width <= 767 ? (
              <div className="mobile--logo">
                {backUrl ? (
                  <div className={`${styles.back}`} data-cy="backBtn">
                    <BackButton to={backUrl} color="white" />
                  </div>
                ) : (
                  <Link
                    className="d:i-b"
                    to={user ? `/member/clientDash/${user.username}` : ""}
                  >
                    {state.logo ? (
                      <img src={state.logo} alt="Treo Logo" />
                    ) : null}
                  </Link>
                )}
              </div>
            ) : null}
            <Link
              className="client-header-links no-text-decoration tour-coach-scheduleBtn tour-coach-messageBtn"
              to={user ? `/member/clientDash/${user.username}/inbox` : ""}
            >
              <div
                style={
                  colorSwitch ? { color: user.organization.text_color } : {}
                }
                className="hidden-xs hidden-sm"
              >
                MESSAGING
              </div>
              <span>
                <i
                  style={
                    colorSwitch ? { color: user.organization.text_color } : {}
                  }
                  className="fa fa-envelope fa-lg"
                />
                {state.unread < 1 ? null : (
                  <div className="header-unread-notification">
                    {state.unread}
                  </div>
                )}
              </span>
            </Link>
            <Link
              className="client-header-links"
              to={user ? `/member/clientDash/${user.username}/account` : ""}
            >
              <div
                style={
                  colorSwitch ? { color: user.organization.text_color } : {}
                }
                className="hidden-xs hidden-sm"
              >
                PROFILE
              </div>
              {user ? (
                <PhotoContainer
                  className={`${rankBorder} tour-profile-intro`}
                  color={colorSwitch ? user.organization.text_color : null}
                  image={user.profile_image}
                  username={user.username}
                  url_path={`member/clientDash/${user.username}/account`}
                />
              ) : null}
            </Link>
          </Nav>
        </Navbar>
      </div>
    </div>
  );
};

function mapStateToProps({ user, complianceCheck, dailyScore, alertOptions }) {
  return { user, complianceCheck, dailyScore, alertOptions };
}

const actions = {
  fetchSelf,
  setCompliance,
  getDailyScore,
  checkLogin,
  registerSession,
};

export default withRouter(connect(mapStateToProps, actions)(ClientHeader));
