import React, {useState, useRef, useEffect} from "react";
import { withRouter } from "react-router-dom";
import {Alert} from "react-bootstrap";
import {connect} from "react-redux";

// styles
import "../Account.css";
import styles from './personalGoal.module.css';


// actions
import {
    fetchAlertOptions,
    setAlertNotification,
    setDailyGoal,
    setNickname,
} from "../../../../store/actions/userActions";
import {fetchSelf, fetchSingleUser} from "../../../../actions/action_user";

// components
import {BoxContainer} from "../../../containers/boxContainer/boxContainer";
import ToggleButton from "react-toggle-button";
import {PrimaryButton} from "../../../buttons/buttons";
import {NICKNAME_STATUSES} from "../../../../serverVariables/leaderboardNicknameStatuses";

const MIN = 50;
const MAX = 170;

const PersonalGoal = props => {
    // state
    const {alertOptions, user, singleUser} = props;
    // actions
    const {fetchAlertOptions, fetchSelf, fetchSingleUser} = props;
    // local
    const [state, updateState] = useState({
        show: false,
        alertStyle: true,
        alert: '',
        error:'',
    });
    const rangeBar = useRef();
    const [goal, setGoal] = useState(0);
    const [alert, setAlert] = useState(false);
    const [nickname, setName] = useState(singleUser && singleUser.user ? singleUser.user.nickname : user.nickname);
    const [error, setError] = useState('');

    useEffect(() => {
        if (goal) rangeBar.current.style.setProperty('--seek-before-width', `${((goal-MIN)/(MAX-MIN))*100}%`);
    }, [goal]);

    useEffect(() => {
        try{
            const userId = singleUser && singleUser.user ? singleUser.user.id : null;
            fetchAlertOptions(userId);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        setAlert(alertOptions.points_alert);
        setGoal(alertOptions.daily_goal);
    }, [alertOptions]);

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function handleDismiss() {
        setState({ show: false });
    }

    async function accountUpdate(e) {
        e.preventDefault();
        setState({ show: false });

        const userId = singleUser && singleUser.user ? singleUser.user.id : null;

        try {
            await Promise.all([
                setDailyGoal(goal, userId),
                setAlertNotification(alert, userId),
            ]);
            if (nickname !== user.nickname) {
                if (nickname.length > 25) return setError('Nickname cannot be greater than 25 characters');
                if (!nickname) return setError('Nickname cannot be blank');
                setError('');
                await setNickname(nickname, userId);
            }

            if (userId) {
                await fetchAlertOptions(userId);
                await fetchSingleUser(singleUser.user.username);
            } else{
                await fetchAlertOptions();
                await fetchSelf();
            }
            setState({
                show: true,
                alert: 'success',
                alertStyle: true,
                formChanged: false,
            });
        } catch(e) {
            setState({
                show: true,
                alert: 'error',
                alertStyle: false,
            });
        }
    }


    function renderSaveButton() {
        if (state.error) {
            return (
                <PrimaryButton className="btn primary" type="submit">SAVE</PrimaryButton>
            );
        } else {
            return (
                <PrimaryButton className="btn primary" type="submit">SAVE</PrimaryButton>
            );
        }
    }

    function changeRange(){
        setGoal(rangeBar.current.value);
    }

    function statusFlag(status) {
        if (status === NICKNAME_STATUSES.PENDING) {
            return <div className={styles.pendingFlag}>Pending Approval</div>
        } else if (status === NICKNAME_STATUSES.REJECTED || status === NICKNAME_STATUSES.ASSIGNED) {
            return <div className={styles.rejectionFlag}>The nickname you selected was not approved. You have been assigned a random nickname. You may choose another.</div>
        }
    }

    const changeAlertStyle = state.alertStyle ? "success" : "danger";
    return (
        <BoxContainer className='padding-standard'>
            <form onSubmit={e => accountUpdate(e)}>
                <div>
                    <h3 className={styles.heading}>Game Settings</h3>
                    <div className={styles.goalContainer}>
                        <div className={styles.goal} data-cy="personal-goal-donut">{goal ? goal : ''}</div>
                        <input
                            value={goal}
                            min={MIN}
                            max={MAX}
                            ref={rangeBar}
                            className={styles.progressBar}
                            type="range"
                            step='1'
                            defaultValue={goal}
                            onChange={changeRange}
                            data-cy="personal-goal-slider"/>
                        <p className={styles.explainer}>Set how many points you plan to earn each day</p>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.text}>Alert when points are earned</div>
                        <div className={styles.toggle} data-cy="personal-toggle">
                            <ToggleButton
                                value={alert}
                                onToggle={val => {
                                    setAlert(!val)
                                }}
                                colors={{
                                active: {
                                    base: '#4bbc4e',
                                    hover: 'rgb(177, 191, 215)',
                                }
                            }}/>
                        </div>
                    </div>
                </div>

                <form>
                    <div className="form-group">
                        <div>
                            <label htmlFor="nickname">Leaderboard Name {statusFlag(user.nickname_status)}</label>
                        </div>
                        <input
                            value={nickname}
                            onChange={e=>setName(e.target.value)}
                            type="text"
                            className="form-control"
                            id="nickname"/>
                        {error ? <span className="text-danger">{error}</span> : null}
                    </div>
                    {state.show ? (
                        <Alert
                            className="save-profile-alert"
                            bsStyle={changeAlertStyle}
                            onDismiss={handleDismiss}
                        >
                            <p>{state.alert}</p>
                        </Alert>
                    ) : null}
                    <div className="d:f j-c:f-e">{renderSaveButton()}</div>
                </form>

            </form>
        </BoxContainer>
    );
}

function mapStateToProps({alertOptions, user, singleUser}){
    return {alertOptions, user, singleUser};
}

const actions = {
    fetchAlertOptions,
    fetchSelf,
    fetchSingleUser,
}

export default withRouter(connect(mapStateToProps, actions)(PersonalGoal));


