export const INTENSITIES = {
    LOW: 5,
    MODERATE: 6,
    HIGH: 7,
}

export const INTENSITYTYPE = {
    LOW: "Low",
    MODERATE: "Moderate",
    HIGH: "High",
}