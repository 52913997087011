import React from 'react';

// styles
import styles from './filter.module.css';

// components
import {ReactComponent as TimesIcon} from '../../../images/times-circle.svg';
import {ReactComponent as Checkmark} from '../../../images/filled-checkmark.svg';
import {BoxContainer} from "../../containers/boxContainer/boxContainer";

const Filter = props => {
    // props
    const {tags, allTags, setTags, close} = props;

    function toggleTag(tag) {
        const found = tags.find(item => item.id === tag.id);
        if (found) {
            // remove the tag
            const updated = tags.filter(item => item.id !== tag.id);
            setTags(updated);
        } else {
            // add tag
            setTags([...tags, tag]);
        }
    }
    return (
        <BoxContainer className={styles.container}>
            <div className={styles.header}>
                <h4 className='m:0'>Narrow down your selection</h4>
                <button className={styles.btn} onClick={close}>
                    <TimesIcon className={styles.times}/>
                </button>
            </div>
            <p>Choose multiple items below</p>
            <div className={styles.tagContainer}>
                {allTags.map(tag => (
                    <button className={styles.tagRow} onClick={() => toggleTag(tag)}>
                        {tags.find(item => tag.id === item.id) ?
                            <Checkmark className={styles.checkmark}/>
                            : <div className={styles.circle}/>
                        }
                        <p className={styles.tag}>{tag.name}</p>
                    </button>
                ))}
            </div>
        </BoxContainer>
    )
}
export default Filter;