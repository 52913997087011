import React from 'react';

// styles
import styles from './memberStatistics.module.css';

const MemberStatistics = props => {
    // props
    const { user, title, boxes } = props;
    return (
        <div className="container">
            <h3>{title}</h3>
            {user.start_date ?
                <div className='d:f'>
                    { boxes.map(box => (
                        <div className={styles.stat}>
                            <span className={styles.score}>{box.statistic}</span>
                            <p className={styles.scoreLabel}>{box.description}</p>
                        </div>
                    ))}
                </div>
                : null
            }
        </div>
    )
}

export default MemberStatistics;