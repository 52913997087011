import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// components
import {ContentLink} from "../links/links";
import checkmark from "../../images/checkmark.png";
import LockImage from '../../images/icon-lock.png';
import ContentImage from '../../images/icon-circles.png';
import NoodleImage from '../../images/noodle-overlay.png';
import {ProgressBar} from "react-bootstrap";
import {fetchMemberContent} from "../../store/actions/pathwaysActions";
import ClientHeader from "../clientHeader/clientHeader";

const JustForYouSingle = props => {
    // props
    const {match} = props;
    // state
    const {user} = props;
    // local
    const categoryId = match.params.category
    const [ state, updateState ] = useState({
        jfy: null,
        content: null,
        overview: null,
        loading: true,
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    // grab content on mount
    useEffect(() => {
        fetchMemberContent().getJustForYou()
            .then(jfy => {
                const content = jfy.subcategories.find(item => item.category_id === parseInt(categoryId));
                const overview = content.content.find(item => item.overview);
                setState({ loading: false, jfy, content, overview });
                console.log({
                    pathwayId: jfy.category_id,
                    lifestyleId: content.category_id,
                });
            })
            .catch(e => console.log(e));
    }, []);

    if (state.loading) return <div/>;

    return (
        <>
            <ClientHeader type={'transparent'} backUrl={'/member/just_for_you'}/>
            <div>
                <div
                    style={ state.content ? { backgroundImage: `url(${state.content.hero_image_url})`} : null }
                    className="wellness-bg wydBG pathwaysBackground">
                    <div className='pathwaysOverlay'/>
                    <div>
                        <div className="container client-dash">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h2 className="no-margin t-t:c">{state.content.category_name}</h2>
                                    {state.content.description ? <p>{state.content.description}</p> : null}
                                    <div className='fullWidth'>
                                        { state.content.count > 0 ?
                                            <ProgressBar className='w:2of4' now={(state.content.complete / state.content.count)*100}/>
                                            : null
                                        }
                                        <p className="progressbar-p t-t:n">{state.content.complete} of {state.content.count}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`container client-dash ${!state.overview ? 'no-overview' : ''}`}>
                        <div className="row" >
                            <div className='contentLinkContainer'>
                                { state.overview ?
                                    <div className="col-xs-12">
                                        <ContentLink
                                            to={`/member/pathways/${user.username}/content/${state.overview.id}?pathwayId=${state.jfy.category_id}&lifestyleId=${state.content.category_id}&just_for_you=true`}
                                            text={`1. ${state.overview.title}`}
                                            image={state.overview.completed ? { src: checkmark, alt: 'checkmark'} : <i className="fas fa-caret-right img"/>}
                                            background='#6BA543 no-repeat center right'
                                            color='#fff'
                                            tileImage={NoodleImage}
                                            className='video'
                                        />
                                    </div>
                                    : null
                                }
                                { state.content ? state.content.content.map(item => {
                                        if (item.overview) return null;
                                        const image = { src: '', alt: 'icon' };
                                        if (item.completed) image.src = checkmark;
                                        return (
                                            <div className="col-xs-12 col-sm-6 contentLink">
                                                <ContentLink
                                                    overlay
                                                    key={item.id}
                                                    disabled={item.lock_status !== 'unlocked'}
                                                    text={item.title}
                                                    image={image}
                                                    tileImage={item.thumbnail || ContentImage}
                                                    lockImage={item.lock_status !== 'unlocked' ? LockImage : null}
                                                    to={`/member/pathways/${user.username}/content/${item.id}?pathwayId=${state.jfy.category_id}&lifestyleId=${state.content.category_id}&just_for_you=true`}
                                                />
                                            </div>
                                        )})
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-actions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <button
                                    className="btn client-white"
                                    onClick={()=>props.history.push(`/member/just_for_you`)}>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

function mapStateToProps({ user, }) {
    return { user, };
}

export default connect(mapStateToProps)(JustForYouSingle);