//This component will populate the tiles via a GET request to the DB
import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

let tile = [];
export class Tiles extends React.Component {
    constructor() {
        super();
        this.state = {
            tileList: []
        };
        this.handleClick = this.handleClick.bind(this);
    }


    createList=()=>{
        if(this.state.tileList){
            return this.state.tileList.map((tileList)=>{
               if(tileList.plan.length > 1){
                   return(
                       <Link to={this.props.match.url+'/TileDetail'}>
                           <div className='row'>
                               <div className='col-xs-12'>
                                   <h4>{tileList.tile.category}</h4>
                               </div>
                               <div className="col-xs-12 col-md-3">
                                   <div>
                                       <img src="http://via.placeholder.com/50x50" alt="tile img"/>
                                   </div>
                                   <p>Plan Description</p>
                               </div>
                               <div className="col-xs-12 col-md-3">
                                   <h4>12.4 Hours Avg</h4>
                                   <p>+.04 hours above goal</p>
                               </div>
                               <div className="col-xs-12 col-md-3">
                                   goal bar
                               </div>
                               <div className="col-xs-12 col-md-3">
                                   graph
                               </div>
                           </div>
                       </Link>
                   )
               }else{
                   return(
                       <Link to={this.props.match.url+'/TileDetail/'+ tileList.tile.id}>
                           <div className='row'>
                               <div className="col-xs-12 col-md-3">
                                   <div>
                                       <img src="http://via.placeholder.com/50x50" alt="tile img"/>
                                   </div>
                                   <h4>{tileList.tile.category}</h4>
                                   {<p>{tileList.plan[0].description}</p>}
                               </div>
                               <div className="col-xs-12 col-md-3">
                                   <h4>12.4 Hours Avg</h4>
                                   <p>+.04 hours above goal</p>
                               </div>
                               <div className="col-xs-12 col-md-3">
                                   goal bar
                               </div>
                               <div className="col-xs-12 col-md-3">
                                   graph
                               </div>
                           </div>
                       </Link>
                   )
               }
            });
        }
    };

    componentDidMount() {
        axios.get(`/tiles/list/${this.props.username}`)
            .then((res)=>{
                this.setState({tileList: res.data.result});
            });
    }

handleClick = (e, tileList) => {
    // access to e.target here
    tile = tileList;
    console.log(tile);
};

    render() {
        return (
            <div>
                <div>
                    <Link to={this.props.match.url+'/create/step1'}>Add New Tile</Link>
                </div>
                <div>
                    <div>
                        <div>
                            <ul  className="tiles-ul">{this.createList()}</ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
