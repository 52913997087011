import axios from 'axios';

export const ALTER_FORM = 'ALTER_FORM';
export const FORM_START = 'FORM_START';
export const FORM_RESULT = 'FORM_RESULT';
export const FORM_SUBMIT = 'FORM_SUBMIT';
export const FORM_ADD_QUESTION = 'FORM_ADD_QUESTION';
export const FORM_REMOVE_QUESTION = 'FORM_REMOVE_QUESTION';
export const FORM_ALTER_QUESTION = 'FORM_ALTER_QUESTION';
export const FORM_ADD_LIST = 'FORM_ADD_LIST';
export const FORM_REMOVE_LIST = 'FORM_REMOVE_LIST';
export const FORM_ALTER_LIST = 'FORM_ALTER_LIST';
export const FORM_RESET = 'FORM_RESET';
export const LOAD_FORM = 'LOAD_FORM';
export const CLIENT_FORM_LIST = 'CLIENT_FORM_LIST';
export const CLIENT_FORM = 'CLIENT_FORM';
export const LOAD_COACH_FORM = 'LOAD_COACH_FORM';
export const FORM_BULK_ALTER_QUESTIONS = 'FORM_BULK_ALTER_QUESTIONS';

export const alterForm = (obj) => {
    return { type: ALTER_FORM, payload: obj, }
};

export const resetForm = () => {
    return { type: FORM_RESET, payload: null }
};

export const updateStart = (obj) => {
    return { type: FORM_START, payload: obj, }
};

export const updateSubmit = (obj) => {
    return { type: FORM_SUBMIT, payload: obj, }
};

export const updateResult = (obj) => {
    return { type: FORM_RESULT, payload: obj, }
};

export const addQuestion = () => {
    return { type: FORM_ADD_QUESTION }
};

export const removeQuestion = (question) => {
    return { type: FORM_REMOVE_QUESTION, payload: question }
};

export const alterQuestion = (question) => {
    return { type: FORM_ALTER_QUESTION, payload: question }
};

export const addList = (question) => {
    return { type: FORM_ADD_LIST, payload: question }
};

export const removeList = (question, list) => {
    return { type: FORM_REMOVE_LIST, payload: { question, LIST: list } }
};

export const alterList = (question, list) => {
    return { type: FORM_ALTER_LIST, payload: { question, LIST: list } }
};

// pass single form for clients to reducer
export function setForm(form){
    return { type: CLIENT_FORM, payload: form };
}

// load a single form for client via axios call
export const loadFormToState = (formId) => {
    const url = `/forms/single?formId=${formId}`;
    const request = axios.get(url);
    return { type: LOAD_FORM, payload: request };
};

// load a single form to state for coach
export const loadCoachFormToState = (formId) => {
    const url = `/forms/single?formId=${formId}`;
    const request = axios.get(url);
    return { type: LOAD_COACH_FORM, payload: request };
};

// takes the entire array of questions currently in newForm state and passes them to reducer
export const bulkAlterQuestions = (questions) => {
    return { type: FORM_BULK_ALTER_QUESTIONS, payload: questions };
};