import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

// styles
import styles from './singleOnDemand.module.css';

// actions
import {fetchFeaturedSingle, setContentFavorite} from "../../../store/actions/contentActions";
import {setChangedScoreFlag, getDailyScore} from "../../../store/actions/wellnessScoreActions";
import {checkAlerts} from "../../../store/actions/alertActions";

//components
import ClientHeader from "../../clientHeader/clientHeader";
import VimeoVideoPlayer from "../../vimeoVideoPlayer/vimeoVideoPlayer";
import BrightcovePlayer from "../../brightcovePlayer/brightcovePlayer";
import {ReactComponent as InstructorIcon} from "../../../images/icons/Icons_Filter_FitnessPlayer_Instructor.svg";
import LoveReaction from "../../community/loveReaction/loveReaction";
import {INTENSITIES} from "../../../serverVariables/intensities";
import {CONTENT_META_TYPES} from "../../../serverVariables/contentMetaTypes";
import {recordContentView} from "../../../store/actions/pathwaysActions";

const SingleOnDemand = props => {
    // props
    const {match: {params}} = props;
    // actions
    const {getDailyScore, setChangedScoreFlag, checkAlerts,} = props;
    // state
    const {user} = props;
    // local
    const [content, setContent] = useState(null);
    const [categoryId, setCategoryId] = useState('');
    const [meta, setMeta] = useState(null);
    const [favorite, setFavorite] = useState(false);

    // grab content on mount
    useEffect(() => {
        fetchFeaturedSingle(params.contentId)
            .then(result => {
                setContent(result)
                const instructor = result.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.TRAINER);
                const classType = result.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES);
                const intensity = result.meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.INTENSITY);
                setMeta({
                    length: result.format_length,
                    classType: classType.selected_option.content_meta_option_label,
                    instructor: instructor.selected_option.content_meta_option_label,
                    intensity: intensity.selected_option.content_meta_option_id,
                });
                if (result.categories.length) {
                    const [cat] = result.categories;
                    setCategoryId(cat.id);
                }
                setFavorite(result.user_favorite);
            })
            .catch(e => console.log(e));

        recordView(params.contentId)
            .catch(e => console.log(e));
    }, []);

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    function setLevel(intensity){
        if (intensity === INTENSITIES.LOW) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot}`}/>
                    <span className={`${styles.dot}`}/>
                </div>
            )
        }
        if (intensity === INTENSITIES.MODERATE) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot}`}/>
                </div>
            )
        }
        if (intensity === INTENSITIES.HIGH) {
            return (
                <div className={styles.intensity}>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                    <span className={`${styles.dot} ${styles.active}`}/>
                </div>
            )
        }
    }

    async function makeFavorite() {
        try {
            setFavorite(!favorite);
            setContentFavorite(content.id);
        } catch (e) {
            console.log(e);
        }
    }

    async function recordView(contentId) {
        const res = await recordContentView(contentId);
        if (res && res.result.alert_waiting){
            setChangedScoreFlag(parseInt(res.result.score.score));
            checkAlerts();
            getDailyScore();
        }
    }

    if (!content || !meta) return <div/>;
    return (
        <>
            <ClientHeader backUrl={`/member/on-demand/${categoryId}`}/>
            <div>
                {isValidHttpUrl(content.content) ?
                    <VimeoVideoPlayer id={content.id} identifier={user.username} src={content.content}/>
                    :
                    <BrightcovePlayer id={content.id} videoId={content.content} identifier={user.username}/>
                }
            </div>
            <div className={styles.gradient}>
                <div className="container general-container">
                    <p className={styles.title}><span>{meta.length} Min.</span> <span>{meta.classType}</span></p>
                    <div className={styles.meta}>
                        <span className={styles.instructor}><InstructorIcon/> {meta.instructor}</span>
                        <span className={styles.level}>{setLevel(meta.intensity)} Level</span>
                        <span className={styles.favorite}>
                            <LoveReaction
                                callback={makeFavorite}
                                active={favorite}
                                color='transparent'
                                stroke='#ffffff'
                                animatedColor='#e5466c'
                                animatedStroke='#transparent'
                                className={styles.icon}/>
                            <span onClick={makeFavorite}>Favorite</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="container">
                <h4 className={styles.episode}>Ep {content.episode}</h4>
                <div dangerouslySetInnerHTML={{__html: content.description}}/>
            </div>
        </>
    )
}

function mapStateToProps({user}) {
    return {user}
}

const actions = {
    getDailyScore,
    setChangedScoreFlag,
    checkAlerts,
}

export default connect(mapStateToProps, actions)(SingleOnDemand);