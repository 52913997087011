import React, {useState, useEffect} from 'react';

// styles
import styles from './badgeAlert.module.css';

// images
import BadgeShadow from'../../images/badge_shadow.png';

// components
import Badge from "../badge/badge";
import {PrimaryButton} from "../buttons/buttons";

const BadgeAlert = props => {
    // props
    const {oldScore, newScore, dismiss, newBadge, oldBadge, name, description} = props;
    // local
    const [score, setScore] = useState(oldScore);
    const [showScore, setShowScore] = useState(false);
    const [showNewBadge, setShowNewBadge] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowScore(true)
            incrementScore();
        }, 2000);
    }, []);

    function incrementScore() {
        setScore(prev => {
            if (prev < newScore) {
                setTimeout(incrementScore, 150);
                return prev + 50;
            } else {
                setShowNewBadge(true);
                return newScore;
            }
        });
    }

    const small = window.innerHeight < 500;

    return (
        <div className={styles.container}>
            <div className={styles.headingContainer}>
                <div className={`${styles.heading} ${small ? styles.small : ''}`}>New Badge <br/><strong>UNLOCKED</strong></div>
            </div>

            <div className={`${styles.badgeContainer} ${small ? styles.small : ''}`}>
                {/* Old badge */}
                <div className={`${styles.oldBadge} ${showNewBadge ? styles.fadeOldBadge : ''}`}>
                    <Badge className={styles.badge} badge={oldBadge}/>
                    <img className={styles.shadow} src={BadgeShadow} alt=""/>
                </div>

                {/* New Badge */}
                {showNewBadge ?
                    <div className={`${styles.newBadge} ${small ? styles.small : ''}`}>
                        <h4 className={styles.badgeName}>{name}</h4>
                        <Badge className={styles.badge} badge={newBadge}/>
                        <img className={styles.shadow} src={BadgeShadow} alt=""/>
                    </div>
                    : null
                }
            </div>

            {/* Score */}
            <div>
                {showScore && !showNewBadge ?
                    <div className={styles.score}>
                        {score}/{newScore}
                    </div>
                    : null
                }
                {showNewBadge ?
                    <p className={`${styles.description}`}>{description}</p>
                    : null
                }
            </div>
            { showNewBadge ? <PrimaryButton onClick={dismiss}>Keep Going!</PrimaryButton> : null}
        </div>
    )
}

export default BadgeAlert;