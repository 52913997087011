// npm modules
import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import { updateStart } from "../../actions/action_forms";

// components
import InputImage from "../imageThumbnail/imageThumbnail";

const NewStartScreen = ({ screen, updateStart }) => {
    const [ media, setMedia ] = useState('image');

    const toggleMedia = (value) => {
        if (value ===  'image') {
            setMedia('image');
            updateStart({ ...screen, VIDEO: '' });
        } else if (value === 'video') {
            setMedia('video');
            updateStart({ ...screen, IMAGE: null });
        }
    };

    // change to correct media if it was presented on load
    useEffect(() => {
        if (screen.IMAGE) setMedia('image');
        if (screen.VIDEO) setMedia('video');
    }, [screen.IMAGE, screen.VIDEO]);

    return (
        <div>
            <h2>Start Screen</h2>
            <div className='form-group'>
                <label htmlFor="headline">Headline</label>
                <input
                    onChange={e => updateStart({ ...screen, HEADLINE: e.target.value })}
                    value={screen.HEADLINE}
                    type="text"
                    id="headline"
                    className="form-control"
                    placeholder='Headline Text'/>
                { screen.validation && screen.validation.HEADLINE ?
                    screen.validation.HEADLINE.map(err => <span key={err.trim()} className="text-danger">{err}</span>):
                    null
                }
            </div>
            <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                    onChange={e => updateStart({  ...screen, CONTENT: e.target.value })}
                    value={screen.CONTENT}
                    id="description"
                    rows="3"
                    className='form-control'
                    placeholder='Page description'/>
                { screen.validation && screen.validation.CONTENT ?
                    screen.validation.CONTENT.map(err => <span key={err.trim()} className="text-danger">{err}</span>):
                    null
                }
            </div>
            <div className="form-group">
                <label>
                    <input
                        type="radio"
                        name="media"
                        value='image'
                        onChange={()=>toggleMedia('image')}
                        checked={media === 'image'}/>
                    Image</label>
                <label>
                    <input
                        type="radio"
                        name="media"
                        value='video'
                        onChange={()=>toggleMedia('video')}
                        checked={media === 'video'}/>
                    Video</label>
            </div>
            <div className="form-group">
                { media === 'image' ?

                    <InputImage setImage={file => updateStart({ ...screen, IMAGE: file })} image={screen.IMAGE }/>
                    : null
                }
                { media === 'video' ?
                    <label>https://player.vimeo.com/video/
                        <input
                            type='text'
                            placeholder='Vimeo ID'
                            className='form-control'
                            onChange={e => updateStart({ ...screen, VIDEO: e.target.value })}
                            value={screen.VIDEO}/>
                    </label> :
                    null
                }
            </div>
            <div className="form-group">
                <label htmlFor="button">Button Label</label>
                <input
                    onChange={e => updateStart({ ...screen, BUTTON: e.target.value })}
                    value={screen.BUTTON}
                    type="text"
                    id="button"
                    className="form-control"
                    placeholder='Page button'/>
                { screen.validation && screen.validation.BUTTON ?
                    screen.validation.BUTTON.map(err => <span key={err.trim()} className="text-danger">{err}</span>):
                    null
                }
            </div>
        </div>
    )
};

const actions = {
    updateStart,
};

export default connect(null, actions)(NewStartScreen);

NewStartScreen.propTypes = {
    screen: propTypes.shape({
        HEADLINE: propTypes.string,
        CONTENT: propTypes.string,
        IMAGE: propTypes.oneOfType([propTypes.string, propTypes.object]),
        BUTTON: propTypes.string,
    }),
    updateStart: propTypes.func
};