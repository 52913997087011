import mainApi from "./mainApi";

export function calendarApi() {
    return {
        // get a list of calendly calendars for a coach
        getCalendars: function(coachId) {
            let url = '/calendar/calendly/list';
            if (coachId) url += `?coachId=${coachId}`;
            return mainApi.get(url);
        },
        // add a calendly calendar to a coach
        postCalendar: function(val) {
            return mainApi.post('/calendar/calendly/add', val);
        },
        // update a calendly calendar to a coach
        updateCalendar: function (id, val) {
            return mainApi.put(`/calendar/calendly/update/${id}`, val);
        },
        // delete a calendly calendar to a coach
        deleteCalendar: function (id) {
            return mainApi.delete(`/calendar/calendly/delete/${id}`);
        },
        // get api key for calendly
        getCalendlyApiKey: function(id) {
            return mainApi.get(`/calendar/calendly/apikey?coach_id=${id}`);
        },
        // set a calendly api key
        updateCalendlyApiKey: function(key) {
            return mainApi.put('/calendar/calendly/apikey', {newKey: key});
        },
        // get a list of events for a coach
        getCalendlyEvents: function(coachId) {
            return mainApi.get(`/calendar/calendly/events/${coachId}`);
        },
        // record a calendar event
        recordAppointment: function(coachId, eventId, calendarId) {
            return mainApi.post('/facts/record/schedule/', {
                coach_id: coachId,
                event_id: eventId,
                calendar_id: calendarId,
            });
        }
    }
}