import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import ContentSlider from '../contentDisplay/contentSlider';
import ContentTabs from '../contentDisplay/contentTabs';

class ContentDisplayContainer extends Component{
    constructor(props){
        super(props);
        this.state={
            categories:[],
            message:'',

            //determines if content from first category should be loaded
            pristine:true,

            //content filters
            title:'',
            type:'',
            status:'published',
            category:'',
            page:1,
            limit:1000,
        };
        this.getContent=this.getContent.bind(this);
        this.getFilteredContent=this.getFilteredContent.bind(this);
        this.showCategories=this.showCategories.bind(this);
        this.loadLatest=this.loadLatest.bind(this);
        this.getContentAssignedToTile=this.getContentAssignedToTile.bind(this);
    }

    componentDidMount(){
        this.loadLatest();
        if(this.props.hasOwnProperty('categories') && this.props.categories === 'none' && this.props.tile) this.getContentAssignedToTile(this.props.tile);
    }

    componentWillUpdate(prevState){
        if(this.props.categories){
            let that = this;
            if(prevState.categories !== this.props.categories && !this.props.loadLatest){
                this.setState({category:prevState.categories[0].name},
                    ()=>that.getContent());
            }
        }
    }


    showCategories(){
        if(this.props.hasOwnProperty('loadLatest')){
            if(this.props.loadLatest){
                //no categories were chosen and not set to invisible
                if(!this.props.hasOwnProperty('categories')){
                    if(this.state.categories.length===0) this.getCategories();
                    return <ContentTabs defaultValue={this.props.defaultValue} getContent={this.getFilteredContent} categories={this.state.categories ? [{name:'Latest'}, ...this.state.categories] : null}/>
                }
            }
        }

        //chosen categories were passed in and no tile in view
        if(this.props.hasOwnProperty('categories') && this.props.categories && !this.props.tile){
            return <ContentTabs
                defaultValue={this.props.defaultValue}
                getContent={this.getFilteredContent}
                categories={this.props.categories}/>
        }

        //chosen categories were passed in and tile is in view
        if(this.props.hasOwnProperty('categories') && this.props.categories && this.props.categories !== 'none' && this.props.tile){
            return <ContentTabs
                defaultValue={this.props.defaultValue}
                getContent={this.getFilteredContent}
                getContentAssigned={this.getContentAssignedToTile}
                categories={this.props.categories}
                tile={this.props.tile}/>
        }

        //don't show categories but do show latest
        if(this.props.hasOwnProperty('categories') && this.props.categories === 'invisible'){
            return <ContentTabs
                getContent={this.getFilteredContent}
                categories={this.state.categories ? [{name:'Latest'}, ...this.state.categories] : null}/>
        }

        //don't show categories
        if(this.props.hasOwnProperty('categories') && this.props.categories === 'none') return
    }

    getContentAssignedToTile(){
        const username = this.props.user ? this.props.user.username : null;
        if(this.props.tile && this.props.basicContent){
            axios.get(`/content/tile/${this.props.tileId}`)
                .then(result=>{
                    let message = '';
                    if (!result.data.result.length) {
                        message =
                            <p>Looking for helpful tips and information to support you on your path toward achieving
                                your goal?
                                <Link
                                    to={`/member/clientDash/${username}/inbox-newmessage`}>Ask
                                    your coach to recommend content!</Link>
                            </p>;
                    }
                    this.setState({content:result.data.result, message})
                });
        }
        else if(this.props.tile){
            axios.get(`/content/tile/${this.props.tile.id}`)
                .then(result=>{
                    let message = '';
                    if (!result.data.result.length) {
                        message =
                            <p>Looking for helpful tips and information to support you on your path toward achieving
                                your goal?
                                <Link
                                    to={`/member/clientDash/${username}/inbox-newmessage`}>Ask
                                    your coach to recommend content!</Link>
                            </p>;
                    }
                    this.setState({content:result.data.result, message})
                });
        }
    }


    getCategories(){
        axios.get('/content/categories')
            .then(categories=> this.setState({categories:categories.data.categories}));
    }

    //used to get content
    getContent(e){
        if(e)e.preventDefault();
        let category = encodeURIComponent(this.state.category);
        let url=encodeURI(`/content/list/${this.state.page}?title=${this.state.title}&type=${this.state.type}&status=${this.state.status}&category=${category}&limit=${this.state.limit}`);
        axios.get(url)
            .then(content=>{
                this.setState({content:content.data.result.content});
                this.setState({pages:content.data.result.pages})
            })
            .catch(err=>{
                if(err.hasOwnProperty('response'))this.setState({error:err.response.message});
                if(err.hasOwnProperty('message'))this.setState({error:err.message})
            });
    }

    //used to change the category of content that will be gotten
    getFilteredContent(category){
        if(category.toLowerCase() === 'latest'){
            this.loadLatest();
            return;
        }
        this.setState({category, page:1},
            ()=>this.getContent());
    }

    loadLatest(){
        if(this.props.hasOwnProperty('loadLatest')){
            if(this.props.loadLatest){
                let that = this;
                that.setState({limit:25, category:''}, ()=>{
                    that.getContent();
                    that.setState({limit:1000});
                })
            }
        }
    }

    render(){
        return(
            <div className='container content-modal'>
                <div className='row'>
                    <div className='col-xs-12'>
                    {this.props.singleTileChild
                        ?null
                        :<h3>Recommended Content</h3>
                    }
                        {this.showCategories()}
                        { this.state.content && this.state.content.length ?
                            <ContentSlider
                            singleTileChild={this.props.singleTileChild}
                            tileId={this.props.tileId}
                            inbox={this.props.inbox ? this.props.inbox : null}
                            attachContent={this.props.attachContent ? this.props.attachContent : null}
                            content={this.state.content}
                            modal={this.props.modal}
                        /> : this.state.message}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ContentDisplayContainer);
