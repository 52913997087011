import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    addDays,
    addMonths,
    addYears,
    endOfWeek,
    formatISO,
    format,
    getDaysInMonth,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subYears
} from "date-fns";

// actions
import {
    getOrganizationSeatHistoryMonthly,
    getOrganizationSeatHistoryTotal,
} from "../../../actions/action_organizations";

// styles
import styles from './organizatinSeatHistory.module.css';


// components
import { ToggleButtons } from "../../buttons/buttons";
import Graph from "../../graph/graph";

const GRAPH_BUTTONS = {
    MONTHLY: 'MONTHLY',
    TOTAL: 'TOTAL',
};
const RANGES = { YEAR: 'YEAR', MONTH: 'MONTH', WEEK: 'WEEK' };

const OrganizationSeatHistory = (props) => {
    // props
    const { organization } = props;
    // actions
    const { getHistoryMonthly, getHistoryTotal } = props;
    // state
    const { seatHistory } = props;

    const [ graphButton, setGraphButton ] = useState(GRAPH_BUTTONS.MONTHLY); // track which data point is active in seat graph
    const [ date, setDate ] = useState(new Date());
    const [ range, setRange ] = useState(RANGES.YEAR);
    const [ loading, setLoading ] = useState(false); // whether or not new seat history is being fetched
    const options = [
        { label: 'year', value:'year', onClick: () => { getYearlyData(); setRange(RANGES.YEAR) } },
        { label: 'month', value: 'month', onClick: () => { getMonthlyData(); setRange(RANGES.MONTH) } },
        { label: 'week', value: 'week', onClick: () => { getWeeklyData(); setRange(RANGES.WEEK) } }
    ];

    function setRangeText() {
        switch(range) {
            case RANGES.YEAR:
                return format(date, 'yyyy');
            case RANGES.MONTH:
                return format(date, 'MMMM');
            case RANGES.WEEK:
                return `${format(startOfWeek(date), "L'/'d'/'yy")} - ${format(endOfWeek(date), "L'/'d'/'yy")}`;
            // no default
        }
    }

    // function that sets off actions to get different history
    function getHistory(date, days) {
        if (loading) return;
        setLoading(true);
        switch(graphButton) {
            case GRAPH_BUTTONS.MONTHLY:
                return getHistoryMonthly(organization.id, date, days)
                    .then(() => setLoading(false));
            case GRAPH_BUTTONS.TOTAL:
                return getHistoryTotal(organization.id, date, days)
                    .then(() => setLoading(false));
            default:
                return getHistoryMonthly(organization.id, date, days)
                    .then(() => setLoading(false));
        }
    }

    function getYearlyData() {
        const year = formatISO(startOfYear(date));
        getHistory(year, 365);
    }

    function getMonthlyData() {
        const month = formatISO(startOfMonth(date));
        getHistory(month, getDaysInMonth(date));
    }

    function getWeeklyData() {
        const day = formatISO(startOfWeek(date));
        getHistory(day, 7);
    }

    function goBack() {
        switch(range) {
            case RANGES.YEAR:
                return setDate(subYears(date, 1));
            case RANGES.MONTH:
                return setDate(subMonths(date, 1));
            case RANGES.WEEK:
                return setDate(subDays(date, 7));
            // no default
        }
    }

    function goForward() {
        switch(range) {
            case RANGES.YEAR:
                return setDate(addYears(date, 1));
            case RANGES.MONTH:
                return setDate(addMonths(date, 1));
            case RANGES.WEEK:
                return setDate(addDays(date, 7));
            // no default
        }
    }

    function switchToMonthly(){
        setGraphButton(GRAPH_BUTTONS.MONTHLY);
        setDate(new Date());
    }

    function switchToTotal(){
        setGraphButton(GRAPH_BUTTONS.TOTAL);
        setDate(new Date());
    }

    // fetch new data whenever date or graph buttons change
    useEffect(() => {
        if (range === RANGES.YEAR) getYearlyData();
        if (range === RANGES.MONTH) getMonthlyData();
        if (range === RANGES.WEEK) getWeeklyData();
    }, [date]);

    return (
        <div>
            <div className={styles.graphContainer}>
                <div className={styles.graph}>
                    <div>
                        <div style={{ width: '300px'}}>
                            <ToggleButtons options={options}/>
                        </div>
                        <Graph data={seatHistory} legend/>
                        <div className={styles.rangeContainer}>
                            <div className={styles.rangeBack}>
                                <span onClick={goBack}>
                                    <button className="btn back"/>
                                    <span className="p-l:1">Previous</span>
                                </span>
                            </div>
                            <div className={styles.range}>{setRangeText()}</div>
                            <div className={styles.rangeForward}>
                                <span onClick={goForward}>
                                    <span className="p-r:1">Next</span>
                                    <button className="btn forward"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.graphButtons}>
                    <h3>Data Points</h3>
                    <div className={styles.buttonGroup}>
                        <div
                            className={`${styles.button} hover ${graphButton === GRAPH_BUTTONS.MONTHLY ? styles.active : ''}`}
                            onClick={switchToMonthly}>
                            <span className={`${styles.bar} ${styles.monthlyBar}`}/>
                            Monthly Available
                        </div>
                        <div
                            className={`${styles.button} hover ${graphButton === GRAPH_BUTTONS.TOTAL ? styles.active : ''}`}
                            onClick={switchToTotal}>
                            <span className={`${styles.bar} ${styles.totalBar}`}/>
                            Total Available
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps({ seatHistory }) {
    return {
        seatHistory,
    }
}

const actions = {
    getHistoryMonthly: getOrganizationSeatHistoryMonthly,
    getHistoryTotal: getOrganizationSeatHistoryTotal,
}

export default connect(mapStateToProps, actions)(OrganizationSeatHistory);