import React, {useEffect, useRef, useState} from 'react';

// styles
import styles from './goalDistribution.module.css';
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const GoalDistribution = props => {
    // props
    const {data} = props;
    // local
    const container = useRef();
    const [containerState, setContainerState] = useState(container.current);

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [data]);

    function onResize() {
        if (container.current) {
            const size =  container.current.clientWidth-265;
            setContainerState(size);
        }
    }

    if (!data) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }
    let total = data.total;
    if (!total) total = 1;
    return (
        <div ref={container} className={styles.containerResponsive}>
            <div className={styles.row}>
                <div className={styles.label}>Lasting Weight Loss</div>
                <div style={{width: `${containerState*(data.lasting_weight_loss/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.lasting_weight_loss} <strong>({Math.floor((data.lasting_weight_loss/total)*100)}%)</strong></div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Building a Stronger Leaner Body</div>
                <div style={{width: `${containerState*(data.stronger_leaner_body/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.stronger_leaner_body} <strong>({Math.floor((data.stronger_leaner_body/total)*100)}%)</strong></div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Improve Heart and Cognitive Health</div>
                <div style={{width: `${containerState*(data.heart_and_cognitive_health/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.heart_and_cognitive_health} <strong>({Math.floor((data.heart_and_cognitive_health/total)*100)}%)</strong></div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>More Energy, Less Stress</div>
                <div style={{width: `${containerState*(data.more_energy/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.more_energy} <strong>({Math.floor((data.more_energy/total)*100)}%)</strong></div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Make Physical Activity a Regular Habit</div>
                <div style={{width: `${containerState*(data.physical_activity/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.physical_activity} <strong>({Math.floor((data.physical_activity/total)*100)}%)</strong></div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Make Healthier Food Choices</div>
                <div style={{width: `${containerState*(data.healthier_food_choices/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.healthier_food_choices} <strong>({Math.floor((data.healthier_food_choices/total)*100)}%)</strong></div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Better Blood Pressure</div>
                <div style={{width: `${containerState*(data.better_blood_pressure/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.better_blood_pressure} <strong>({Math.floor((data.better_blood_pressure/total)*100)}%)</strong></div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Better Blood Sugar</div>
                <div style={{width: `${containerState*(data.better_blood_sugar/total)}px`}} className={styles.bar}/>
                <div className={styles.number}>{data.better_blood_sugar} <strong>({Math.floor((data.better_blood_sugar/total)*100)}%)</strong></div>
            </div>
        </div>
    )
};
export default GoalDistribution;