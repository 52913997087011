import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import GenericTextArea from '../../genericWYSIWYG/genericWYSIWYG';
import Giphy from "../../Giphy/giphyContainer";
import { sanitize } from 'dompurify';


let recipient = [];
let filteredRecipients = [];

class NewMessage extends Component{
   constructor(props){
       super(props);
           this.state = {
               gif: null,
       };

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
   }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

   replyChange = (reply) => {
       const text = sanitize(reply);
       this.props.replyHandler(text);
       this.setState({text});
  };


    select(item){
      recipient.push(item);
      filteredRecipients = recipient.filter(function(item, pos) {
          return recipient.indexOf(item) === pos;
      })
      this.props.recipientHandler(filteredRecipients);
    }

    gifChange = (gif) => {
        const image = `<img style="width:250px;height: auto;" src=${gif.images.downsized_medium.url} alt="gif"/>`;
        this.setState({ gif: image });
    };

    render(){
        return(
          <div>
        {/*display all coaches or teammates? Who is available to send to? Multiple select?*/}
            {/*this is for multiple recipients{this.createRecipientList()}*/}

            <div className="row">
              <div className="reply-container col-md-12">          
                <label className="reply-label">Message</label>
                  <div>
                      <Giphy callback={this.gifChange}/>
                  </div>
                  <GenericTextArea
                      className='notes col-md-12'
                      value={this.state.reply}
                      plugins = 'paste lists emoticons'
                      toolbar ='undo redo | bold italic | numlist bullist | alignleft aligncenter alignright'
                      onChangeHandler={this.replyChange}
                      addContent={this.state.gif}
                  />
              </div>
            </div>          
          </div>
        )
    }
}

function mapStateToProps({message}){
    return {message}
}

export default withRouter(connect(mapStateToProps)(NewMessage));