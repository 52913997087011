import React from 'react';
import moment from 'moment';

// styles
import styles from './organizationTile.module.css';

// component
import OrgPic from '../../images/organization.png';

const OrganizationTile = ({ organization }) => {
    let monthlyTaken, monthlyTotal, monthlyActive, monthlyActiveTotal, taken, total, totalActive, totalActiveTotal;
    const seats = organization.seats;
    if (seats.monthly_seats) {
       monthlyTaken = seats.monthly_seats.usage.total;
       monthlyTotal = seats.monthly_seats.capacity;
       monthlyActive = seats.monthly_seats.usage.active;
       monthlyActiveTotal = seats.monthly_seats.usage.total;
    }
    if (seats.total_seats) {
        taken = seats.total_seats.usage.total;
        total = seats.total_seats.capacity;
        totalActive = seats.total_seats.usage.active;
        totalActiveTotal = seats.total_seats.capacity;
    }

    if (organization.status_id === 7) {
        return (
            <li className='tile tile--dashboard'>
                <div className="row client-search-list">
                    <div className="col-md-2 col-xs-12">
                        <div className={styles.logoContainer}>
                            <img id={`org-${organization.id}`} className={styles.logo} src={organization.logo || OrgPic} alt={organization.name} />
                        </div>
                    </div>
                    <div className="col-md-2 col-xs-12 tile-border-right">
                        <h4 className={styles.name}>{organization.name}</h4>
                        <p>(Organization ID: {organization.id})</p>
                        <p className={`${styles.dates} text-danger`}>
                            Archived on:
                            <span className={styles.output}>{moment(new Date().toISOString()).format('MM/DD/YYYY')}</span>
                        </p>

                    </div>
                    <div className={`col-xs-12 col-sm-8 ${styles.stat}`}></div>
                </div>
            </li>
        )
    }

    // don't show stats if not available
    if (!seats.total_seats || !seats.monthly_seats){
        return (
            <li className='tile tile--dashboard'>
                <div className="row client-search-list">
                    <div className="col-md-2 col-xs-12">
                        <div className={styles.logoContainer}>
                            <img id={`org-${organization.id}`} className={styles.logo} src={organization.logo || OrgPic} alt={organization.name} />
                        </div>                    </div>
                    <div className="col-md-2 col-xs-12 tile-border-right">
                        <h4 className={styles.name}>{organization.name}</h4>
                        <p>(Organization ID: {organization.id})</p>
                        <p className={styles.dates}>
                            Start Date:
                            <span className={styles.output}>{moment(organization.start_date).format('MM/DD/YYYY')}</span>
                        </p>
                        <p className={styles.dates}>
                            End Date:
                            <span className={styles.output}>{moment(organization.end_date).format('MM/DD/YYYY')}</span>
                        </p>
                    </div>
                    <div className={`col-xs-12 col-sm-8 ${styles.stat}`}>
                        <p>
                            To enable sign-up please set seat limits
                        </p>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <li className='tile tile--dashboard'>
            <div className="row client-search-list">
                <div className="col-md-2 col-xs-12">
                    <div className={styles.logoContainer}>
                        <div id={`org-${organization.id}`} className={styles.logo} style={{backgroundImage: `url(${organization.logo || OrgPic})`}} />
                    </div>
                </div>
                <div className="col-md-2 col-xs-12 tile-border-right">
                    <h4 className={styles.name}>{organization.name}</h4>
                    <p>(Organization ID: {organization.id})</p>
                    <p className={styles.dates}>
                        Start Date:
                        <span className={styles.output}>{moment(organization.start_date).format('MM/DD/YYYY')}</span>
                    </p>
                    <p className={styles.dates}>
                        End Date:
                        <span className={styles.output}>{moment(organization.end_date).format('MM/DD/YYYY')}</span>
                    </p>
                </div>
                <div className={`col-xs-6 col-sm-3 col-md-2 tile-border-right ${styles.stat}`}>
                    <p className={styles.lgStat}>
                        {monthlyTaken}
                        <span className={styles.smStat}> / {monthlyTotal}</span>
                    </p>
                    <p className={`${styles.label} visible-xs visible-sm`}>Seats Taken (Month)</p>
                </div>
                <div className={`col-xs-6 col-sm-3 col-md-2 tile-border-right ${styles.stat}`}>
                    <p className={styles.lgStat}>
                        {monthlyActive}
                        <span className={styles.smStat}> / {monthlyActiveTotal}</span>
                    </p>
                    <p className={`${styles.label} visible-xs visible-sm`}>Active Members (Month)</p>
                </div>
                <div className={`col-xs-6 col-sm-3 col-md-2 tile-border-right ${styles.stat}`}>
                    <p className={styles.lgStat}>
                        {taken}
                        <span className={styles.smStat}> / {total}</span>
                    </p>
                    <p className={`${styles.label} visible-xs visible-sm`}>Seats Taken (Total)</p>
                </div>
                <div className={`col-xs-6 col-sm-3 col-md-2 ${styles.stat}`}>
                    <p className={styles.lgStat}>
                        {totalActive}
                        <span className={styles.smStat}> / {totalActiveTotal}</span>
                    </p>
                    <p className={`${styles.label} visible-xs visible-sm`}>Active Members (Total)</p>
                </div>
            </div>
        </li>
    )
}

export default OrganizationTile;