// npm modules
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import {connect} from 'react-redux';

// actions
import {fetchTakenAssessment, fetchAnswer} from "../../../store/actions/assessmentActions";

// components
import Loading from "../../Loading/Loading";
import ClientHeader from "../../clientHeader/clientHeader";
import ClientAssessmentViewTaken from "./clientAssessmentViewTaken";


const ClientAssessmentViewContainer = props => {
    // props
    const {match, history, user} = props;
    // set loading state
    const [ loading, setLoading ] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [assessment, setAssessment] = useState(null);

    // on first render
    useEffect(() => {
        async function prepare() {
            try {
                const pathwayId = match.params.id;
                const response = await fetchTakenAssessment(pathwayId);
                let questions = [];
                let updatedAssessment = {};

                if (response.assessment.elements) {
                    updatedAssessment.assessment_completed = response.assessment.assignment.taken
                    updatedAssessment.healthgoal = response.healthgoal;
                    // grab all the questions
                    questions = response.assessment.elements.filter(el => parseInt(el.typeId) === 8);
                    // grab answers for each question
                    questions = await Promise.all(questions.map(q => {
                        const questionWithAnswer = {label: q.LIST.label};
                        return fetchAnswer(q.LIST.id)
                            .then(a => {
                                questionWithAnswer.answers = [{selected: true, label: a.value}];
                                return questionWithAnswer;
                            });
                    }));
                } else {
                    updatedAssessment = {...response};
                    Object.keys(response.assessment).forEach(key => {
                        if (Array.isArray(response.assessment[key])){
                            response.assessment[key].forEach(item => questions.push(item));
                        }
                    });
                }
                setQuestions(questions);
                setAssessment(updatedAssessment);
            } catch(e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        prepare();
    }, []);

    return (
        <div className="client-container">
            <ClientHeader backUrl={`/member/clientDash/${user.username}/assessments`}/>
            { loading || !assessment ?
                <div className="margin-top-25">
                    <Loading/>
                </div> :
                <div className='container'>
                    <ClientAssessmentViewTaken assessment={assessment} questions={questions}/>
                    <div className="footer-actions">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-6">
                                    <button className="btn client-white" onClick={() => history.push(`/member/clientDash/${user.username}/assessments`)}>Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

const mapStateToProps = ({ user }) => {
    return { user };
};
export default withRouter(connect(mapStateToProps)(ClientAssessmentViewContainer));