import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import './Details.css';
import moment from 'moment';
import { Alert} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {PrimaryButton} from "../../buttons/buttons";

let userData;

class Details extends React.Component {
    constructor(props){
        super(props);
        this.state={
          gender: '',
          birthday: '',
          height: undefined,
          weight: undefined,
          activity_level: undefined,
          startDate: undefined,
          show: false,
          alertStyle: true,
          alert: '',
          active:false,
          value: "",
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.height = this.height.bind(this);
    }


  goBack() {
    window.history.back();
  }

    componentDidMount() {
      axios.get(`/user/client/single/${this.props.username}`)
      .then((res)=> {
        userData = res.data.user;
          if (_.includes(['male', 'female', 'prefer not to say'], userData.gender)){
              this.setState({ gender: userData.gender });
          } else { this.setState({ gender: 'Select Gender'}); }
        this.setState({ birthday: moment(userData.birthday).format('YYYY-MM-DD') });
        this.setState({ height: userData.height });
        userData.weight ? this.setState({ weight: userData.weight }) : this.setState({ weight: '' });
      })
    }



  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }


  accountUpdate(e) {
      e.preventDefault();
      if(this.state.gender === "Select Gender"){
          this.setState({ show: true });
          this.setState({ alert: 'Please select a gender' });
          this.setState({ alertStyle: false });
          return;
      }
      if(this.state.weight === "" ){
        this.setState({weight: userData.weight});
      }
      if(this.state.height === ""){
        this.setState({height: userData.height});
      }
      let birthday = '';
      if(moment(this.state.birthday).isValid()) {
          const date = moment(this.state.birthday);
          birthday = new Date(date.year(), date.month(), date.date());
      }
      axios.post(`/user/${this.props.username}/client-detail/add?gender=${this.state.gender}&dob=${birthday}&height=${this.state.height}&weight=${this.state.weight}&activity_level=${this.state.activity_level}`)
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({ show: true });
                this.setState({ alert: 'success' });
                this.setState({ alertStyle: true });
            }})
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
    }


    renderSelect=()=>{
        let choices = ['Select Gender', 'Male', 'Female', 'Prefer Not To Say'];
        return choices.map((item, i)=>{
            if(item === 'Select Gender'){
              return <option className="hide" value="0">Select Gender</option>
            }
            else if(this.state.gender.toLowerCase() === item.toLowerCase()){
                return <option value={item.toLowerCase()} selected>{item}</option>
            }else{
                return <option value={item.toLowerCase()}>{item}</option>
            }
        });
    };

  height(event) {
    let height = parseInt(event.target.value);
    this.setState({height: height})
  }

  renderWeightSelect=()=>{


        let weight = this.state.weight;
        let options =  [];
        for(let i = 50; i <= 500; i++){
            options.push(i);
        }
        return options.map(option=>{
            if(option === parseInt(weight)){
                return <option value={option} selected>{option}</option>
            }else{
                return <option value={option}>{option}</option>
            }
        });

  };

    handleDate = (e) => {
        this.setState({birthday: moment(e.target.value).format('YYYY-MM-DD')});
    }


	render() {
    let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
    console.log(this.state.birthday);
    return (
      <BoxContainer className='padding-standard'>
        <form onSubmit={(e)=>this.accountUpdate(e)}>
            <div className="container">
                
                <div className="row">
                  <div className="col-xs-12">
                    <p>USER INFORMATION</p>            
                  </div>
                </div>
                
            <div className="user-info-tiles-container">
                <div className="row">
                  <div className="col-xs-6 user-info-tiles">
                    <div className='form-group'>
                        <label>Gender</label>
                        <select className='form-control' onChange={e=>this.setState({gender:e.target.value})} required>
                            {this.renderSelect()}
                        </select>
                    </div>
                  </div>
                  <div className="col-xs-6 user-info-tiles">
                    <div className='form-group'>
                    <label>Birthday</label>
                        <input value={this.state.birthday} onChange={this.handleDate} className='form-control' type="date"/>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-xs-6 user-info-tiles">
                    <div className='form-group'>
                        <label>Height</label>       

                        <select className='form-control' value={this.state.height} onChange={this.height}>
                          <option value="48">4'0"</option>
                          <option value="49">4'1"</option>
                          <option value="50">4'2"</option>
                          <option value="51">4'3"</option>
                          <option value="52">4'4"</option>
                          <option value="53">4'5"</option>
                          <option value="54">4'6"</option>
                          <option value="55">4'7"</option>
                          <option value="56">4'8"</option>
                          <option value="57">4'9"</option>
                          <option value="58">4'10"</option>
                          <option value="59">4'11"</option>

                          <option value="60">5'0"</option>
                          <option value="61">5'1"</option>
                          <option value="62">5'2"</option>
                          <option value="63">5'3"</option>
                          <option value="64">5'4"</option>
                          <option value="65">5'5"</option>
                          <option value="66">5'6"</option>
                          <option value="67">5'7"</option>
                          <option value="68">5'8"</option>
                          <option value="69">5'9"</option>
                          <option value="70">5'10"</option>
                          <option value="71">5'11"</option>

                          <option value="72">6'0"</option>
                          <option value="73">6'1"</option>
                          <option value="74">6'2"</option>
                          <option value="75">6'3"</option>
                          <option value="76">6'4"</option>
                          <option value="77">6'5"</option>
                          <option value="78">6'6"</option>
                          <option value="79">6'7"</option>
                          <option value="80">6'8"</option>
                          <option value="81">6'9"</option>
                          <option value="82">6'10"</option>
                          <option value="83">6'11"</option>

                          <option value="84">7'0"</option>
                          <option value="85">7'1"</option>
                          <option value="86">7'2"</option>
                          <option value="87">7'3"</option>
                          <option value="88">7'4"</option>
                          <option value="89">7'5"</option>
                          <option value="90">7'6"</option>
                          <option value="91">7'7"</option>
                          <option value="92">7'8"</option>
                          <option value="93">7'9"</option>
                          <option value="94">7'10"</option>
                          <option value="95">7'11"</option>

                          <option value="96">8'0"</option>

                        </select>

                    </div>
                </div>
                <div className="col-xs-6 user-info-tiles">
                    <div className='form-group'>
                        <label>Weight (pounds)</label>
                        <select className='form-control' onChange={e=>this.setState({weight : e.target.value})}>
                            {this.renderWeightSelect()}
                        </select>
                    </div>
                </div>
               </div>
                {this.state.show
                    ?  <Alert className="save-profile-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                        <p>{this.state.alert}</p>
                    </Alert>
                    : null
                }
                <div className="d:f j-c:f-e">
                    <PrimaryButton className="btn primary" type="submit">SAVE</PrimaryButton>
                </div>
            </div>
        </div>
        </form>
      </BoxContainer>
    );
  }
}

export default withRouter(Details);
