import React, {useState} from 'react';

// styles
import styles from './section_5.module.css';

// components
import Strong from '../../../images/strong.jpg';
import {PrimaryButton} from "../../buttons/buttons";

const Section_5_Intro = props => {
    // props
    const {next} = props;
    // local
    const [ready, setReady] = useState(false);
    return (
        <div className={`${ready ? styles.fadeIn : ''} ${styles.container}`}>
            <h2 className={styles.heading}>UNLOCK YOUR <strong>MOTIVATION</strong></h2>
            <img onLoad={() => setReady(true)} className={styles.image} src={Strong} alt="" data-cy="imgKid"/>
            <p className={styles.text}>Commit to your journey, understanding setbacks happen. Are you ready for change?</p>
            <div className="t-a:c">
                <PrimaryButton onClick={next} data-cy="goalBtn">Next</PrimaryButton>
            </div>
        </div>
    )
}
export default Section_5_Intro;