import React from 'react';
import Encourage from './Encourage';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

class EncourageMain extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            confirmation:true,
            show: false,
            alertStyle: true,
            alert: '',
            message:'',
            messageError: '',
            charactersRemaining: 140,
            characterLimit: 140,
            success: false,
        };
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    componentDidMount(){
      let url = window.location.href;
      let message = url.split('&message=').pop();
      message = parseInt(message);

      switch (message){
        case 1:
          message = "Congratulations";
          break;

        case 2:
          message = 'I got your back';
          break;

        default:
          message = 'I got your back';
          break;
      }

      this.changeMessage(message);

    }

    sendMessage = () => {
      const message = this.state.message;
      if (message.trim() === '') return this.setState({ messageError: 'Please include a personalized message' });
      if (message.length > this.state.characterLimit) return this.setState({ messageError: 'Message is too long' });
      let token = window.location.href.split('token=').pop().split('&message=')[0];
      axios.post(`/rewards/${token}`, {
        message: message
      })
          .then((res)=>{
            this.setState({confirmation:true, success: true})
          })
          .catch((error)=>{
            this.setState({ show: true });
            this.setState({alert:error.response.data.err});
            this.setState({ alertStyle: false });
          });
    };

    changeMessage = (message) => {
      let length = message.length;
      this.setState({ message, charactersRemaining: this.state.characterLimit - length });
    };

    handleDismiss() {
      this.setState({ show: false });
    }

    teammateDecision(){
      let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
      if( this.state.confirmation && this.state.success === false){
        return (
            <Encourage
                messageError={this.state.messageError}
                send={this.sendMessage}
                message={this.state.message}
                changeMessage={this.changeMessage}
                charactersRemaining={this.state.charactersRemaining}/>
        )
      }
      else if (this.state.success === true){
        return (
            <div className="container text-center">
              <div className="row">
                <div className="teammate-logo-container margin-top">
                  <img src={'https://s3.us-east-2.amazonaws.com/treo/Treo_Wellness_Coaching.png'} alt='Treo Logo' />
                </div>
              </div>
              <div className="row">
                <h3>Thanks for your support!</h3>
                <p>Your message has been sent.</p>
              </div>
            </div>
        )
      }
      else{ 
        return(
            <div className="container text-center">
                <div className="row">
                  <div className="teammate-logo-container margin-top">
                    <img src={'https://s3.us-east-2.amazonaws.com/treo/Treo_Wellness_Coaching.png'} alt='Treo Logo' />
                  </div>
                </div>
                <div className="row">
                  <h3>Your encouragement was not logged. Please try again, or contact TREO support.</h3>
                </div>
                <div className="row">
                {this.state.show
                    ?  <Alert className="float-right" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                          <p>{this.state.alert}</p>
                       </Alert>
                    : null
                }
                </div>
            </div>
          ) 
      }
    }

    render(){
        return (
            <div>
                {this.teammateDecision()}
            </div>
        )
    }
}

export default EncourageMain;