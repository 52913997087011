import React, { useEffect, useState } from "react";

//styles
import styles from "./WorkOutofDayLanding.module.css";

// components
import { ReactComponent as InstructorIcon } from "../../images/icons/Icons_Filter_FitnessPlayer_Instructor.svg";
import BackButton from "../BackButton";
import BrightcovePlayer from "../brightcovePlayer/brightcovePlayer";

// routes
import {
  fetchWotdOrgAccess,
  fetchtSingleWotdCategoryVideo,
} from "../../store/actions/wotdActions";

// variables
import { CONTENT_META_TYPES } from "../../serverVariables/contentMetaTypes";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import { INTENSITYTYPE } from "../../serverVariables/intensities";

const WorkOutofDayVideo = (props) => {
  const categoryId = props.match.params.videoId;
  const organizationId = props.match.params.organizationId;

  const [videoData, setVideoData] = useState({
    id: "",
    content: "",
    classType: "",
    instructor: "",
    intensity: "",
    title: "",
    episode: "",
    classLength: "",
    description: "",
  });

  const [accessData, setAccessData] = useState({
    access: null,
    code: "",
    orgId: null,
    branding: false,
    thumbnail: "",
    signupCode: "",
    wotdSignup: false,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  async function fetchAccess() {
    try {
      const response = await fetchWotdOrgAccess(organizationId);
      setAccessData({
        access: response.data.result.wotd_access,
        code: response.data.result.wotd_access,
        orgId: response.data.result.id,
        branding: response.data.result.whitelabeled,
        thumbnail: response.data.result.whitelabeled_logo,
        signupCode: response.data.result.wotd_signup_code,
        wotdSignup: response.data.result.wotd_signup,
      });
    } catch (err) {
      console.log(err);
    }
  }
  async function fetchVideoData() {
    try {
      const currentDate = new Date();
      const currentTime = currentDate.toISOString();
      const response = await fetchtSingleWotdCategoryVideo(
        categoryId,
        currentTime
      );
      const classType = response.data.result.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES
      );
      const intensity = response.data.result.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id === CONTENT_META_TYPES.INTENSITY
      );

      const instructor = response.data.result.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id === CONTENT_META_TYPES.TRAINER
      );

      const classLength = response.data.result.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id ===
          CONTENT_META_TYPES.CLASS_LENGTH
      );

      let level;

      if (
        intensity.selected_option.content_meta_option_label ===
        INTENSITYTYPE.LOW
      ) {
        level = 1;
      }
      if (
        intensity.selected_option.content_meta_option_label ===
        INTENSITYTYPE.MODERATE
      ) {
        level = 2;
      }

      if (
        intensity.selected_option.content_meta_option_label ===
        INTENSITYTYPE.HIGH
      ) {
        level = 3;
      }

      const regex = /(<([^>]+)>)/gi;
      let description = null;
      if (response.data.result.description) {
        description = response.data.result.description.replace(regex, "");
      }

      setVideoData({
        id: response.data.result.id,
        content: response.data.result.content,
        instructor: instructor.selected_option.content_meta_option_label,
        classType: classType.selected_option.content_meta_option_label,
        intensity: level,
        title: response.data.result.category[0].name,
        episode: response.data.result.episode,
        classLength: classLength.selected_option.content_meta_option_label,
        description: description,
      });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchAccess();
    fetchVideoData();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let fontSize = windowWidth > 510 ? "20px" : "14px";
  const iconSize = windowWidth > 510 ? "40px" : "28px";

  if (windowWidth < 376) {
    fontSize = 0;
  }

  const size = windowWidth > 450 ? "25" : "20";

  const StarRating = ({ level }) => {
    return (
      <div>
        {[...Array(3)].map((_, index) => (
          <div
            className={`${styles.dot} ${
              index < level ? styles.filled : styles.empty
            }`}
            key={index}
          ></div>
        ))}
      </div>
    );
  };

  if (accessData.access === null) {
    return (
      <div className={styles.redirectContainer}>
        <Loading />
      </div>
    );
  }

  if (accessData.access === true && !videoData.id) {
    return (
      <div className={styles.redirectContainer}>
        <Loading />
      </div>
    );
  }

  if (accessData.access === true) {
    return (
      <div>
        {/* Header */}
        <div className={styles.headerBackground}>
          <div className={styles.btnLocation}>
            <BackButton
              to={`/${organizationId}/workoutoftheday`}
              color="white"
              fontSize={fontSize}
              IconSize={iconSize}
            />
          </div>
          <h1 className={styles.headerTitle}>Workout of the Day</h1>
        </div>

        <BrightcovePlayer
          wotdView={true}
          id={videoData.id}
          organizationId={organizationId}
          identifier={"wotd##123"}
          videoId={videoData.content}
        />

        <div className={styles.videoWorkoutContainer}>
          <div className={styles.subtitleContainer}>
            <span className={styles.videoTitle}>
              {videoData.classLength} Min. {videoData.classType}
            </span>

            {/* Icons */}
            <div className={styles.iconWrapper}>
              <span className={styles.iconContainer}>
                <InstructorIcon stroke={"#78b03d"} width={size} />
                <span className={styles.textColor}>
                  {videoData.instructor}{" "}
                </span>
              </span>
              <span className={styles.iconContainer}>
                <StarRating level={parseInt(videoData.intensity)} />
                <span className={styles.textColor}> Level</span>
              </span>
            </div>
          </div>
        </div>

        <div className={styles.alignContainerCenter}>
          <div className={styles.centerWidthWrapper}>
            <h4>Episode {videoData.episode}</h4>
            <span>
              <h4>Description:</h4>
              <p> {videoData.description}</p>
            </span>
            {accessData.wotdSignup ? (
              <div className={styles.learnMoreContainer}>
                <h4>Like what you see?</h4>
                <Link
                  to={`/signup?orgId=${organizationId}&inviteCode=${accessData.signupCode}&state=${categoryId}`}
                >
                  <button className={`btn ${styles.signUpBtn}`}>Sign Up</button>
                </Link>{" "}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.redirectContainer}>
      <h1>Organization Does not have access </h1>
      <Loading />
    </div>
  );
};

export default WorkOutofDayVideo;
