import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';

// styles
import styles from './featuredVideo.module.css';

// actions
import {fetchFeaturedSingle} from "../../../store/actions/contentActions";
import {recordContentView} from "../../../store/actions/pathwaysActions";

// components
import VimeoVideoPlayer from "../../vimeoVideoPlayer/vimeoVideoPlayer";
import ClientHeader from "../../clientHeader/clientHeader";
import BrightcovePlayer from "../../brightcovePlayer/brightcovePlayer";

const FeaturedVideo = props => {
    // props
    const {user, match} = props;
    // local
    const [content, setContent] = useState(null);

    // grab content on mount
    useEffect(() => {
        fetchFeaturedSingle(match.params.contentId)
            .then(result => {
                console.log(result);
                setContent(result)
            })
            .catch(e => console.log(e));
    }, []);

    // record content view
    useEffect(() => {
        const seconds = window.location.href.lastIndexOf('localhost') > 0 ? .5 : 10;
        const timer = setTimeout(() => {
            recordContentView(match.params.contentId)
                .catch(e => console.log(e));
        }, 1000 * seconds);
        return () => {
            if (timer) clearTimeout(timer);
        }
    }, []);

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    if (!content) return <div/>;
    let backUrl = `/member/clientDash/${user.username}`;
    if (content.categories.length) backUrl = `/member/featured/${content.categories[0].id}`;
    return (
        <>
            <ClientHeader type={'transparent'} backUrl={backUrl}/>
            <div>
                <div className={`wellness-bg wydBG pathwaysBackground`}>
                    <div className='pathwaysOverlay'/>
                    <div className="container client-dash">
                        <div className="row">
                            <div className="col-xs-12">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container client-dash">
                    <div className="row">
                        <div className="col-xs-12">
                   
                            {isValidHttpUrl(content.content) ?
                                <VimeoVideoPlayer id={content.id} identifier={user.username} src={content.content}/>
                                :
                                <BrightcovePlayer id={content.id} videoId={content.content} identifier={user.username} offset/>
                            }
                            <div className={`${styles.copy}`}>
                                <h1 className='m-t:0'>{content.title}</h1>
                                <div dangerouslySetInnerHTML={{__html: content.description}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

function mapStateToProps({user}){
    return {user};
}

export default withRouter(connect(mapStateToProps)(FeaturedVideo));