import React, { useState, useEffect } from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    addDays,
    addMonths,
    addYears,
    differenceInDays,
    endOfWeek,
    endOfMonth,
    endOfYear,
    format,
    formatISO,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subYears
} from "date-fns";

// styles
import styles from './TWSDisplay.module.css';

// actions
import {
    getWellnessScoreHistory,
    getLifetimeScoreHistory,
    fetchGeneralScoreActivity,
} from "../../store/actions/wellnessScoreActions";

// components
import ClientHeader from "../clientHeader/clientHeader";
import { ToggleButtons } from "../buttons/buttons";
import Graph from "../graph/graph";
import { BoxContainer } from "../containers/boxContainer/boxContainer";
import MemberStatistics from "../memberStatistics/memberStatistics";
import { StandardDropdown } from "../dropdowns/standardDropdown/standardDropdown";
import ActivityCalendar from "../activityCalendar/activityCalendar";
import { ErrorAlert } from "../alerts/alerts";

const GRAPH_BUTTONS = {
    DAILY_SCORE: 'DAILY_SCORE',
    LIFETIME_SCORE: 'LIFETIME_SCORE',
};
const RANGES = { YEAR: 'YEAR', MONTH: 'MONTH', WEEK: 'WEEK' };
const DD_LABELS = {
    HABIT: 'HABIT',
    LIFETIME: 'LIFETIME',
    DAILY: 'DAILY',
};

const TWSDisplay = (props) => {
    // state
    const { user, scoreHistory, dailyScore } = props;
    // actions
    const { getWellnessScoreHistory, getLifetimeScoreHistory } = props;

    const [ graphButton, setGraphButton ] = useState(GRAPH_BUTTONS.DAILY_SCORE); // track which toggle is active on the score choice
    const [ date, setDate ] = useState(new Date());
    const [ range, setRange ] = useState(RANGES.WEEK);
    const [ view, setView ] = useState(DD_LABELS.HABIT);
    const [ activity, setActivity ] = useState([]);
    const [ calendarError, setCalendarError ] = useState('');
    const dateOptions = [
        { label: 'week', value: 'week', onClick: () => { getWeeklyData(); setRange(RANGES.WEEK); } },
        { label: 'month', value: 'month', onClick: () => { getMonthlyData(); setRange(RANGES.MONTH); } },
        { label: 'year', value:'year', onClick: () => { getYearlyData(); setRange(RANGES.YEAR); } },
    ];
    const dropdownOptions = [
        { value: DD_LABELS.HABIT, label: 'Habit Calendar' },
        { value: DD_LABELS.DAILY, label: 'Activity Graph (Daily Score)' },
        { value: DD_LABELS.LIFETIME, label: 'Activity Graph (Lifetime Score)'}
    ];

    function setRangeText() {
        switch(range) {
            case RANGES.YEAR:
                return format(date, 'yyyy');
            case RANGES.MONTH:
                return format(date, 'MMMM yyyy');
            case RANGES.WEEK:
                return `${format(startOfWeek(date), "L'/'d'/'yy")} - ${format(endOfWeek(date), "L'/'d'/'yy")}`;
            // no default
        }
    }

    // function that sets off actions to get different history
    function getHistory(start, end) {
        try{
            switch(graphButton) {
                case GRAPH_BUTTONS.DAILY_SCORE:
                    return getWellnessScoreHistory(user.username, start, end, user.start_date || user.created_at)
                        .catch(e => console.log(e));
                case GRAPH_BUTTONS.LIFETIME_SCORE:
                    const days = differenceInDays(new Date(end), new Date(start));
                    return getLifetimeScoreHistory(start, days+1, null, user.start_date || user.created_at)
                        .catch(e => console.log(e));
                 // no default
            }
        } catch (err) {
            console.log(err);
        }
    }

    function getYearlyData() {
        const start = formatISO(startOfYear(date));
        const end = formatISO(endOfYear(date));
        getHistory(start, end);
    }

    function getMonthlyData() {
        const start = formatISO(startOfMonth(date));
        const end = formatISO(endOfMonth(date));
        getHistory(start, end);
    }

    function getWeeklyData() {
        const start = formatISO(startOfWeek(date));
        const end = formatISO(endOfWeek(date));
        getHistory(start, end);
    }

    async function getHabitActivity() {
        try{
            setCalendarError('');
            const { activity_calendar } = await fetchGeneralScoreActivity(date);
            const trendEvents = [];
            let span = {};

            activity_calendar.forEach((log, i) => {
                // check if a span has started
                if (log.activity && !span.start) {
                    span.start = log.date;
                }
                // if a span has started, check if it's stopped
                if (span.start) {
                    if (!log.activity) {
                        span.end = activity_calendar[i-1].date;
                        trendEvents.push(span);
                        span = {};
                    }
                }
                // cap a span that ends on the last day of the month
                if (i === activity_calendar.length-1 && span.start && !span.end){
                    span.end = activity_calendar[activity_calendar.length-1].date;
                    trendEvents.push(span);
                }
            })
            setActivity(trendEvents);
        } catch (e) {
            console.log(e);
            setCalendarError('Error retrieving habit activity');
        }
    }

    function goBack() {
        switch(range) {
            case RANGES.YEAR:
                return setDate(subYears(date, 1));
            case RANGES.MONTH:
                return setDate(subMonths(date, 1));
            case RANGES.WEEK:
                return setDate(subDays(date, 7));
            // no default
        }
    }

    function goForward() {
        switch(range) {
            case RANGES.YEAR:
                return setDate(addYears(date, 1));
            case RANGES.MONTH:
                return setDate(addMonths(date, 1));
            case RANGES.WEEK:
                return setDate(addDays(date, 7));
            // no default
        }
    }

    function switchToDaily(){
        setGraphButton(GRAPH_BUTTONS.DAILY_SCORE);
        setDate(new Date());
    }

    function switchToLifetime(){
        setGraphButton(GRAPH_BUTTONS.LIFETIME_SCORE);
        setDate(new Date());
    }

    function switchToHabitActivity() {
        setDate(new Date());
        getHabitActivity();
    }

    function changeView(val) {
        if (val.value === DD_LABELS.HABIT) {
            switchToHabitActivity();
        }
        if (val.value === DD_LABELS.LIFETIME) {
            setRange(RANGES.WEEK);
            switchToLifetime();
        }
        if (val.value === DD_LABELS.DAILY) {
            setRange(RANGES.WEEK);
            switchToDaily();
        }
        setView(val.value);
    }

    function renderView() {
    if (view === DD_LABELS.HABIT) {
        return (
            <div>
                <ActivityCalendar events={activity} callback={setDate}/>
            </div>
        )
    }

    if (view === DD_LABELS.LIFETIME || view === DD_LABELS.DAILY) {
        return (
            <BoxContainer className={styles.graphContainer}>
                <div className={styles.dateHeader}>
                    <span className={styles.dateYear}>{setRangeText()}</span>
                    <span className={styles.dateNav}>
                    <i onClick={goBack} className="fas fa-chevron-left"/>
                    <i onClick={goForward} className="fas fa-chevron-right"/>
                </span>
                </div>
                <Graph data={scoreHistory} height={400}/>
            </BoxContainer>
        )
    }

    }

    // fetch new data whenever date or graph buttons change
    useEffect(() => {
        if (view === DD_LABELS.DAILY || view === DD_LABELS.LIFETIME) {
            if (range === RANGES.YEAR) getYearlyData();
            if (range === RANGES.MONTH) getMonthlyData();
            if (range === RANGES.WEEK) getWeeklyData();
        }
        if ( view === DD_LABELS.HABIT) {
            getHabitActivity();
        }
    }, [date]);

    // sets the bg color based on wellness score
    let scoreClass = 'noScore';
    if (dailyScore >= 0 && dailyScore < 50 ) {
        scoreClass = 'low';
    } else if (dailyScore >= 50 && dailyScore < 75 ) {
        scoreClass = 'average';
    } else if (dailyScore >= 75 && dailyScore < 90 ) {
        scoreClass = 'high';
    } else if (dailyScore >= 90) {
        scoreClass = 'highest';
    }
    const branded = user.organization.brand_switch && user.organization.bg_color ? 'branded' : '';
    return (
        <div className="client-container">
            <div>
                <ClientHeader type='transparent' backUrl={`/member/clientDash/${user.username}`}/>
            </div>
            <div className={`${scoreClass} wellness-bg largeDesktop ${branded}`}>
                <MemberStatistics
                    user={user}
                    title='Wellness Score History'
                    boxes={[
                        { statistic: user.weekly_wellness_score, description: '7 Day Avg (Points)' },
                        { statistic: user.monthly_wellness_score, description: '30 Day Avg (Points)' },
                        { statistic: user.yearly_wellness_score, description: 'Current Year Avg (Points)' },
                    ]}/>
            </div>
            <div className='container client-dash'>
                <div className={styles.toggleContainer}>
                    <div className={styles.toggles}>
                        <StandardDropdown options={dropdownOptions} callback={changeView}/>
                    </div>
                    <div className={styles.toggles}>
                        { view === DD_LABELS.LIFETIME ?
                            <ToggleButtons
                                inactiveColor='#fff'
                                activeColor='#272727'
                                className={styles.dateToggle}
                                options={dateOptions}/>
                                : null
                        }
                        { view === DD_LABELS.DAILY ?
                            <ToggleButtons
                                inactiveColor='#fff'
                                activeColor='#272727'
                                className={styles.dateToggle}
                                options={dateOptions}/>
                                : null
                        }
                    </div>
                </div>
                { calendarError ? <ErrorAlert>{calendarError}</ErrorAlert> : null }
                { renderView() }
            </div>
            <div className="footer-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <button className="btn client-white" onClick={()=>props.history.push('/')}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state){
    return {
        user: state.user,
        scoreHistory: state.scoreHistory,
        dailyScore: state.dailyScore
    }
}

const actions = {
    getWellnessScoreHistory,
    getLifetimeScoreHistory,
}

export default withRouter(connect(mapStateToProps, actions)(TWSDisplay));