import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {Modal} from "react-bootstrap";

// actions
import {fetchSingleUser} from "../../../actions/action_user";

// components
import { DangerButton, PrimaryButton } from "../../buttons/buttons";
import { ErrorAlert } from "../../alerts/alerts";

const ClientStatus = (props) => {
    // props
    const {client} = props;
    // actions
    const {fetchSingleUser} = props;

    const [tmpStatus, setTmpStatus] = useState(client.user.user_status_id);
    const [error, setError] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [showModal, setShowModal] = useState(false);

    function submit(e) {
        e.preventDefault();
        closeModal();
        axios.put(`/user/change-status/${tmpStatus}/username/${client.user.username}`)
            .then(() => {
                fetchSingleUser(client.user.username)
                    .then((res) => {
                        setTmpStatus(res.payload.data.user.user_status_id);
                        setError('');
                    });
            }).catch(e => {
            setError(e.response.data);
        })
    }

    function openModal(status) {
        setTmpStatus(parseInt(status));
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    useEffect(() => {
        axios.get(`/user/manual/statuses/${client.user.user_status_id}`)
            .then(res => {
                setStatuses(res.data.result)
            })
            .catch(e => setError(e.response.data));
    }, []);

    return (
        <form onSubmit={submit}>
            {error ? <ErrorAlert>{error}</ErrorAlert> : null}
            <div className='form-group'>
                <select
                    className='form-control'
                    id="userStatus"
                    value={tmpStatus}
                    onChange={e => openModal(e.target.value)}>
                    {statuses.map(status => <option key={status.id} value={status.id}>{status.label}</option>)}
                </select>
            </div>
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Account Status Change</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StatusChangeText client={client} statusId={tmpStatus} statusList={statuses}/>
                </Modal.Body>
                <Modal.Footer>
                    <DangerButton onClick={closeModal} className='m-r:1'>Cancel</DangerButton>
                    <PrimaryButton
                        onClick={submit}
                    >Yes, Change</PrimaryButton>
                </Modal.Footer>
            </Modal>
        </form>
    )
}

const actions = {
    fetchSingleUser,
};

export default connect(null, actions)(ClientStatus);

function StatusChangeText({statusId, client, statusList}) {
    const currentStatusText = statusList.find(status => status.id === client.user.user_status_id).label;
    const newStatusText = statusList.find(status => status.id === statusId).label;
    switch (statusId) {
        case 7:
            return (
                <div>
                    <p>You are trying to change this user status
                        from <strong>{currentStatusText}</strong> to <strong>{newStatusText}</strong></p>
                    <p>This will close <strong>{client.user.first_name}'s</strong> account.</p>
                    <ul>
                        <li>Member will no longer be able to log in</li>
                    </ul>
                </div>
            )
        case 8:
            return (
                <div>
                    <p>You are trying to change this user status
                        from <strong>{currentStatusText}</strong> to <strong>{newStatusText}</strong></p>
                    <p>This will close <strong>{client.user.first_name}'s</strong> account.</p>
                    <ul>
                        <li>Member will no longer be able to log in</li>
                    </ul>
                </div>
            )
        default:
            return (
                <div>
                    <p>You are trying to change this user status
                        from <strong>{currentStatusText}</strong> to <strong>{newStatusText}</strong></p>
                </div>
            )
    }
}