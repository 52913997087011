// npm modules
import React from 'react';
import PropTypes from 'prop-types';

const ClientPathwayReferences = ({clientPathway}) => {
    function primaryResearch(goal) {
        switch (goal.health_goal_name) {
            case "Lasting Weight Loss":
                return (
                    <WeightLoss/>
                );
            case "Build a Stronger Leaner Body":
                return (
                    <BodyComposition/>
                );
            case "Improve Heart and Cognitive Health":
                return (
                    <HeartAndBrain/>
                );
            case "More Energy, Less Stress":
                return (
                    <MoreEnergy/>
                );
            case "Make Physical Activity a Regular Habit":
                return (
                    <PhysicalActivity/>
                );
            case "Healthier Food Choices":
                return (
                    <FoodChoices/>
                );
            case "Improve Endurance":
                return (
                    <Endurance/>
                );
            case "Improve Speed and Strength":
                return (
                    <SpeedAndStrength/>
                );
            case "Better Blood Pressure":
                return (
                    <BetterBloodPressure/>
                );
            case "Better Blood Sugar":
                return (
                    <BetterBloodSugar/>
                );
            // no default
        }
    }

    return (
        <div className="container reference-container">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                <div className="content-container">
                    <h1>Research References</h1>
                    {primaryResearch(clientPathway.health_goals[0])}
                </div>
            </div>
        </div>
    )
};
ClientPathwayReferences.propTypes = {
    clientPathway: PropTypes.shape({
        health_goals: PropTypes.array,
    })
};


export default ClientPathwayReferences;

function WeightLoss() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. Garrow JS & Summerbell CD. Meta-analysis: Effect of exercise, with or without dieting, on the
                    body composition of overweight subjects . EurJ Clin Nutr(1995) 49(1): 1-10.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.3945/ajcn.113.058362">https
                    ://doi.org/10.3945/ajcn.113.058362</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. Elfhag K & Rossner S. Who succeeds in maintaining weight loss ? A conceptual review of factors
                    associated with weight loss maintenance and weight regain. ObesRev (2005) 6(1): 67-85.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1111/j.1467-789X.2005.00170.x">https
                    ://doi.org/10.1111/j.1467-789X.2005.00170.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. Rolls BJ, Ello-Martin JA & TohillBC. What can intervention studies tell us about the relations
                    hip between fruit and vegetable consumption and weight management? NutrRev (2004) 62(1): 1-17.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1161/CIRCULATIONAHA.109.876185">https://doi.org/10.1161/CIRCULATIONAHA.109.876185</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. SchwingshacklL, Hoffmann G, Kalle-Uhlmann T, Arregui M, Buijsse B & Boeing H. Fruit and vegetable
                    consumption and changes in anthropometric variables in adult populations: A systematic review and
                    meta-analysis of prospective cohort studies. PLoSOne (2015) 10(10): e0140846.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1371/journal.pone.0140846">https
                    ://doi.org/10.1371/journal.pone.0140846</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. LedouxTA, HingleMD & BaranowskiT. Relationship of fruit and vegetable intake with adiposity: A
                    systematic review. Obesity Reviews (2011) 12(5): e143-50.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1111/j.1467-789X.2010.00786.x">https
                    ://doi.org/10.1111/j.1467-789X.2010.00786.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. Moro T, Tinsley G, Bianco A, Marcolin G, Pacelli QF, Battaglia G, Palm a A, Gentil P, Neri M &
                    Paoli A.Effects of eight weeks of time-restricted feeding (16/8) on basal metabolism, maximal s
                    trength, body com pos ition, inflam mation, and cardiovas cular risk factors in res is tance-trained
                    m ales. J TranslMed (2016) 14(1): 290.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1186/s12967-016-1044-0"> https
                    ://doi.org/10.1186/s12967-016-1044-0</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. Gabel K, HoddyKK, Haggerty N, Song J, Kroeger CM, Trepanows kiJF, Panda S & VaradyKA. Effects of
                    8-hour time restricted feeding on body weight and metabolic dis ease ris k factors in obes e adults
                    : A pilot s tudy. NutrHealthy Aging (2018) 15(4): 345-53
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.3233/NHA-170036">https://doi.org/10.3233/NHA-170036</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Gill S & Panda S. A smartphone app reveals erratic diurnal eating patterns in humans that can be
                    modulated for health benefits . Cell Metab(2015) 22(5): 789-98
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1016/j.cm et.2015/09.005">https://doi.org/10.1016/j.cm
                    et.2015/09.005</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. How many times should you eat to lose weight?
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://optim is ingnutrition.com/author/martykendall/">https://optim is
                    ingnutrition.com/author/martykendall/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. KahleovaH, LlorenJI, Mas hchakA, Hill M & Fraser GE. Meal frequency and timing are associated
                    with changes in body mass index in Adventist Health Study 2. J Nutr(2017) 147(9): 1722-1728.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3945/jn.116.244749">https://doi.org/10.3945/jn.116.244749/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. PopkinB & DuffeyKJ. Does hunger and satiety drive eating anymore? Increasing eating occasions
                    and decreasing time between eating occasions in the United States . Am J Clin Nutr(2010) 91(5):
                    1342-7
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3945/ajcn.2009.28962"> https://doi.org/10.3945/ajcn.2009.28962</a>
            </div>
        </div>
    )
}

function BodyComposition() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. TsitkanousS, SpengosK, StasinakiAN, ZarasN, BogdanisG, PapadimasG & Terzis G. Effects of
                    high-intsensityinterval cycling performed after resistance training on muscle strength and
                    hypertrophy. ScandJ Med Sci Sports (2017) 27(11): 1317-1327.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1111/sms.12751">https://doi.org/10.1111/sms.12751</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. FrimelTN, SinacorDR & VillarealDT. Exercise attenuates the weight-loss-induced reduction in
                    muscle mass in frail obese older adults. Med Sci Sports Exerc(2008) 40(7): 1213-1219
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1249/MSS.0B013e31816a85ce">https://doi.org/10.1249/MSS.0B013e31816a85ce</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. GarrowJS & SummerbellCD. Meta-analysis: Effect of exercise, with or without dieting, on the body
                    composition of overweight subjects. EurJ Clin Nutr(1995) 49: 1-10.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://www.researchgate.net/publication/15486230_Meta-analysis_Effect_of_exercise_with_or_without_dieting_on_the_body_composition_of_overweight_subjects">https://www.researchgate.net/publication/15486230_Meta-analysis_Effect_of_exercise_with_or_without_dieting_on_the_body_composition_of_overweight_subjects</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. WewegeM, van den Berg R, Ward RE & KeechA. The effects of high-intensity interval training versus
                    moderate-intensity continuous training on body composition in overweight and obese adults: A
                    systematic review and meta-analysis. ObesRev (2017) 18(6): 635-646.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1111/obr.12532">https://doi.org/10.1111/obr.12532 </a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. MaillardF, Pereira B & BoisseauN. Effect of high-intensity interval training on total, abdominal
                    and visceral fat mass: A meta-analysis. Sports Medicine (2018) 1: 269-288.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1007/s40279-017-0807-y">https://doi.org/10.1007/s40279-017-0807-y</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. ShoenfeldBJ, Aragon AA & Krieger JW. The effect of protein timing on muscle strength and
                    hypertrophy: A meta-anlysis. Journal of the International Society of Sports Nutrition (2013) 10(53):
                    1-13.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1186/1550-2783-10-53">https://doi.org/10.1186/1550-2783-10-53</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. NedeltchevaAV, KilkusJM, Imperial J, SchoellerDA, & PenevPD. Insufficient sleep undermines
                    dietary efforts to reduce adiposity. Annals of Internal Medicine (2010) 153: 435-441.</p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1059/0003-4819-153-7-201010050-000068">https://doi.org/10.1059/0003-4819-153-7-201010050-000068</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Thomson CA, Morrow KL, Flatt SW, Wertheim BC, Perfect MM, Ravia JJ, Sherwood NE, KaranjaN & Rock
                    CL. Relationship between sleep quality and quantity and weight loss in women participating in a
                    weight-loss intervention trial. Obesity (2012) 20(7): 1419-1425</p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1038.oby.2012.62">https://doi.org/10.1038.oby.2012.62</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. Spiegel K, TasaliE, PenevP & Van CauterE. Brief communication: Sleep curtailment in healthy young
                    men is associated with decreased leptin levels, elevated ghrelin levels, and increased hunger and
                    appetite. Annals of Internal Medicine (2004) 141: 846-850.</p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.7326/0003-4819-141-11-200412070-00008">https://doi.org/10.7326/0003-4819-141-11-200412070-00008/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. Knutson KL & Van CauterE. Associations between sleep loss and increased risk of obesity and
                    diabetes. Annals of the New York Academy of Science (2008) 1129: 287-304 </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1196/annals.1417.033">https://doi.org/10.1196/annals.1417.033</a>
            </div>
        </div>
    )
}

function HeartAndBrain() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. Larsen FJ, Schiffer TA, BorniquelS, SahlinK, EkblomB, Lundberg JO & WeitzbergE. Dietary inorganic
                    nitrate improves mitochondrial efficiency in humans. Cell Metab(2011) 13: 149-59. </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.cmet.2011.01.004">https://doi.org/10.1016/j.cmet.2011.01.004</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. Larsen FJ, WeitzbergE, Lundberg JO & EkblomB. Effects of dietary nitrate on oxygen cost during
                    exercise. Acta Physiologica(2007) 191: 59–66. </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1111/j.1748-1716.2007.01713.x">https://doi.org/10.1111/j.1748-1716.2007.01713.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. Ashworth A, Mitchell K, Blackwell JR, VanhataloA & Jones AM. High-nitrate vegetable diet
                    increases plasma nitrate and nitrite concentrations and reduces blood pressure in healthy women.
                    Public Health Nutrition (2015) 18(4): 2669-78. </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1017/S1368980015000038">https://doi.org/10.1017/S1368980015000038</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. Tangney CC, KwasnyMJ, Li H, Wilson RS, Evans DA & Morris MC. Adherence to a Mediterranean-type
                    dietary pattern and cognitive decline in a community population. AmerJ of Clin Nutr(2011) 93(3):
                    601-607. </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3945/ajcn.110.007369">https://doi.org/10.3945/ajcn.110.007369</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. YaffeY, FioccoAJ, Lindquist K, VittinghoffE, SimonsickEM, Newman AB, Satterfield S, RosanoC,
                    Rubin SM, AyonayonHN & Harris TB. Predictors of maintaining cognitive function in older adults: The
                    Health ABC Study. Neurology (2009) 72(23): 2029-2035. </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1212/WNL.0b013e3181a92c36 ">https://doi.org/10.1212/WNL.0b013e3181a92c36</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. NabbS & Benton D. The influence on cognition of the interaction between the macro-nutrient
                    content of breakfast and glucose tolerance. Physiology & Behavior (2006) 87(1): 16-23.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.physbeh.2005/08.034">https://doi.org/10.1016/j.physbeh.2005/08.034</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. Erickson KI, Voss MW, Prakash RS, BasakC, Szabo A. ChaddockL, Kim JS, HeoS, Alves H . . . Kramer
                    AF. Exercise training increases size of hippocampus and improves memory. PNAS (2011) (108)7:
                    3017-22.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1017/pnas.1015950108">https://doi.org/10.1017/pnas.1015950108</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. AlfiniAJ, Weiss LR, LeitnerBP, Smith TJ, HagbergJM & Smith JC. Hippocampal and cerebral blood
                    flow after exercise cessation in master athletes. Frontiers in Aging Neuroscience (2016) 8.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3389/fnagi.2016.00184">https://doi.org/10.3389/fnagi.2016.00184</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. Alves CR, TessaroVH, Teixeira LA, MurakavaK, RoschelH, GualanoB & TakitoMY. Influence of acute
                    high-intensity aerobic interval exercise bout on selective attention and short-term memory tasks.
                    Percept Mot Skills (2014) 118(1): 63-72.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.2466/22.06.PMS.118k10w4">https://doi.org/10.2466/22.06.PMS.118k10w4</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10.Fu TC, Wang CH, Lin PS, Hsu CC, CherngWJ, Huang SC, Liu MH, Chiang CL & Wang JS. Aerobic interval
                    training improves oxygen uptake efficiency by enhancing cerebral and muscular hemodynamics in
                    patients with heart failure. IntJ Cardiol(2013) 167(1): 41-50.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.ijcard.2011.11.086">https://doi.org/10.1016/j.ijcard.2011.11.086</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. Lu X, Wu T, Huang P, Lin S, QiuF, Meng X, Gao J & Li J. Effect and mechanism of intermittent
                    myocardial ischemia induced by exercise on coronary collateral formation. Am J Phys Med
                    Rehabil(2008) 87(10): 803-14.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1097/PHM.0b013e31817faed0">https://doi.org/10.1097/PHM.0b013e31817faed0</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    12. GuimaraesGV, CiolacEG, Carvalho VO, D'AvilaVM, BortolottoLA & BocchiEA. Effects of continuous
                    vs. interval exercise training on blood pressure and arterial stiffness in treated hypertension.
                    HypertensRes (2010) 33(6): 627-32.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1038/hr.2010.42">https://doi.org/10.1038/hr.2010.42</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    13. TordiN, MourotL, Colin E & RegnardJ. Intermittent versus constant aerobic exercise: Effects on
                    arterial stiffness. EurJ ApplPhysiol(2010) 108(4): 801-9.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1007/s00421-009-1285-1">https://doi.org/10.1007/s00421-009-1285-1</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    14. WisloffU, StøylenA, LoennechenJP, BruvoldM, RognmoØ, Haram PM, TjønnaAE, HelgerudJ, SlørdahlSA,
                    Lee SJ, VidemV, Bye A, Smith GL, NajjarSM, EllingsenØ & SkjaerpeT. Superior cardiovascular effect of
                    aerobic interval training versus moderate continuous training in heart failure patients: A
                    randomized study. Circulation (2007) 115(24): 3086-94.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1161/CIRCULA TIONA HA.106.675041">https://doi.org/10.1161/CIRCULA
                    TIONA HA.106.675041</a>
            </div>
        </div>
    )
}

function MoreEnergy() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. Mishra SI, Scherer RW, Geigle PM, BerlansteinDR, TopalogluO, GotayCC. Exercise interventions on
                    health-related quality of life for cancer survivors. Cochrane Database SystRev (2012) 8: Cd007566.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD007566.pub2/abstrac">https://www.cochranelibrary.com/cdsr/doi/10.1002/14651858.CD007566.pub2/abstrac</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. Lane AM, Crone-Grant D, & Lane H. Mood changes following exercise: Perceptual and motor skills
                    (2002) 94(3): 732–734.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.2466/pms.2002.94.3.732">https://doi.org/10.2466/pms.2002.94.3.732</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. SveaasSH, Berg IJ, FongenC, ProvanSA & DagfinrudH. High-intensity cardiorespiratory and strength
                    exercises reduced emotional distress and fatigue in patients with axial spondyloarthritis: A
                    randomized controlled pilot study. ScandJ Rheumatol(2018) 47(2): 117-21. </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1080/03009742.2017">https://doi.org/10.1080/03009742.2017</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. NoakesTD. Physiological models to understand exercise fatigue and the adaptations that predict or
                    enhance athletic performance. ScandJ Med Sci Sports (2000) 10:123−45.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pdfs.semanticscholar.org/03a9/88ec786aba618809e95180ec4b567c9c4a98.pdf">https://pdfs.semanticscholar.org/03a9/88ec786aba618809e95180ec4b567c9c4a98.pdf</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. LinnemannA, DitzenB, Strahler J, DoerrJM & NaterUM. Music listening as a means of stress
                    reduction in daily life. Psychoneuroendocrinology(2015) 60: 82-90
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.psyneuen.2015.06.008">https://doi.org/10.1016/j.psyneuen.2015.06.008</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. Wood C. Mood change and perceptions of vitality: A comparison of the effects of relaxation,
                    visualization and yoga. Journal of the Royal Society of Medicine (1993) 86(5): 254-8.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1293998/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1293998/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. Kiecolt-Glaser JK, Bennett JM, AndridgeR, Peng J, Shapiro CL, Malarkey WB, Emery CF, Layman R,
                    MrozekEE & Glaser R. Yoga's impact on inflammation, mood, and fatigue in breast cancer survivors: A
                    randomized controlled trial. Journal of Clinical Oncology (2014) 32(10): 1040-9
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1200/JCO.2013.51.8860">https://doi.org/10.1200/JCO.2013.51.8860</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Boehm K, OstermannT, MilazzoS & Bussing A. Effects of yoga interventions on fatigue: A
                    meta-analysis. Evidence-Based Complementary and Alternative Medicine (2012): 1-9.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1155/2012/124703">https://doi.org/10.1155/2012/124703</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. Johansson B, BjuhrH & RönnbäckL. Mindfulness-based stress reduction (MBSR) improves long-term m
                    ental fatigue after stroke or traumatic brain injury. Brain Injury (2012) 26(13-14): 1621-8.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3109/02699052.2012.700082">https://doi.org/10.3109/02699052.2012.700082</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. MacLean KA, Ferrer E, AicheleSR, BridwellDA, ZanescoAP, Jacobs TL, King BG, Rosenberg EL,
                    SahdraBK, Shaver PR, Wallace BA, MangunGR & SaronCD. Intensive meditation training improves
                    perceptual discrimination and sustained attention. Ps ycholSci (2010) 21(6): 829-39
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1177/0956797610371339">https://doi.org/10.1177/0956797610371339</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11.Staats S& Horner K. Allocating time to people and pets: Correlates with income and well-being in
                    a Midwest community sample. The Journal of Psychology (1999) 133(5): 541-52.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1080/00223989909599761">https://doi.org/10.1080/00223989909599761</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    12. Cavanaugh LA, Leonard HA & ScammonDL. A tail of two personalities: How canine companions shape
                    relationships and well-being. Journal of Business Research (2008) 61(5): 469-79.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.jbusres.2007.07.024">https://doi.org/10.1016/j.jbusres.2007.07.024</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    13. Soga M, Gaston KJ & YamauraY. Gardening is beneficial for health: A meta-analysis. PrevMed Rep
                    (2016) 5: 92-9
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi/org/10.1016/j.pmedr.2016.11.007">https://doi/org/10.1016/j.pmedr.2016.11.007</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    14. Kidd JL, PachanaNA & AlpassFM. Benefits of gardening: An exploratory study of mid-aged women in
                    New Zealand. Journal of Therapeutic Horticulture (2001) 11: 4-19.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://www.researchgate.net/profile/Nancy_Pachana/publication/43492548_Benefits_of_gardening_An_exploratory_study_of_mid-aged_women_in_New_Zealand/links/5699be0b08aea14769437b15/Benefits-of-gardening-An-exploratory-study-of-mid-aged-women-in-New-Zealand.pdf">https://www.researchgate.net/profile/Nancy_Pachana/publication/43492548_Benefits_of_gardening_An_exploratory_study_of_mid-aged_women_in_New_Zealand/links/5699be0b08aea14769437b15/Benefits-of-gardening-An-exploratory-study-of-mid-aged-women-in-New-Zealand.pdf</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    15. Van den Berg R, Mook-Kanamori DO, Donga E, van Dijk M, van Dijk JG, LammersG, van KralingenKW,
                    PrehnC, AdamskiJ, RomijnJA, van Dijk KW, CorssmitEPM, RensenPCN & BiermaszNR. A single night of
                    sleep curtailment increases plasma acylcarnitines: Novel insights in the relationship between sleep
                    and insulin resistance. Archives of Biochemistry and Biophysics (2016) 589: 145-51.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.abb.2015.09.017">https://doi.org/10.1016/j.abb.2015.09.017</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    16. Saner NJ, Bishop DJ & Bartlett JD. Is exercise a viable therapeutic intervention to mitigate
                    mitochondrial dysfunction and insulin resistance induced by sleep loss? Sleep Medicine Reviews
                    (2018) 37: 60-8.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.smrv.2017.01.001">https://doi.org/10.1016/j.smrv.2017.01.001</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    17. Broussard JL, EhrmannDA, van CauterE, TasaliE & Brady MJ. Impaired insulin signaling in human
                    adipocytes after experimental sleep restriction: A randomized, crossover study. Ann Intern Med
                    (2012) 157(8): 549-57.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.7326/0003-4819-157-8-201210160-00005">https://doi.org/10.7326/0003-4819-157-8-201210160-00005</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    18. MujcicR & Oswald AJ. The evolution of well-being and happiness after increases in consumption of
                    fruit and vegetables. Am J Public Health (2016) 106(8):1504-10.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.2105/AJPH.2016.303260">https://doi.org/10.2105/AJPH.2016.303260</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    19. CarrAC, BozonetSM, PullarJM & VissersMC. Mood improvement in young adult males following
                    supplementation with gold kiwifruit, a high-vitamin C food. J NutrSci (2013) 2: 1-8.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="http://doi.org/10.1017/jns.2013.12">http://doi.org/10.1017/jns.2013.12</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    20. Smith AP & Rogers R. Positive effects of a healthy snack (fruit) versus an unhealthy snack
                    (chocolate/crisps) on subjective reports of mental and physical health: a preliminary intervention
                    study. Front Nutr(2014) 1: 1-5.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3389/fnut.2014.00010">https://doi.org/10.3389/fnut.2014.00010</a>
            </div>
        </div>
    )
}

function PhysicalActivity() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. HelselDL, JakicicJM & Otto AD. Comparison of techniques for self-monitoring eating and exercise
                    behaviors on weight loss in a correspondence-based intervention. Journal of the Academy of Nutrition
                    and Dietetics (2007) 107(10): 1807-18010.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.jada.2007.07.014">https://doi.org/10.1016/j.jada.2007.07.014</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. CarelsRA, Darby LA, Rydin S, Douglass OM, CacciapagliaHM & O’Brien WH. The relationship between
                    self-monitoring, outcome expectancies, difficulties with eating and exercise and physical activity
                    and weight loss treatment outcomes. Annals of Behavioral Medicine (2005) 30(3): 182-190.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1207/s15324796abm3003_2">https://doi.org/10.1207/s15324796abm3003_2</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. Blair SN, Kohl HW, Gordon NF & PaffenbargerRS. How much physical activity is good for health?
                    Annual Reviews of Public Health (1992) 13: 99–126.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1146/annurev.pu.13.050192.000531">https://doi.org/10.1146/annurev.pu.13.050192.000531</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. Segar ML, Eccles JS & Richardson CR: Type of physical activity goal influences participation in
                    healthy midlife women. WomensHealth Issues (2008) 18: 281-291.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.whi.2008.02.003">https://doi.org/10.1016/j.whi.2008.02.003</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. Hariri AR, Brown SM, Williamson DE, Flory JD, de Wit H & ManuckSB. Preference for immediate over
                    delayed rewards is associated with magnitude of ventral striatal activity. J Neurosci(2006) 26:
                    13213-13217.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1523/JNEUROSCI.3446-06.2006">https://doi.org/10.1523/JNEUROSCI.3446-06.2006</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. LallyP, van Jaarsveld CHM, Potts HWW & Wardle J. How are habits formed: Modelling habit formation
                    in the real world. Euro J SocPsychol(2010) 40: 998–1009.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1002/ejsp.674">https://doi.org/10.1002/ejsp.674</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. LallyP & Gardner B. Promoting habit formation. Health PsycholRev (2011) 7: 137-158.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1080/17437199.2011.603640">https://doi.org/10.1080/17437199.2011.603640</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Gill S & Panda S. A smartphone app reveals erratic diurnal eating patterns in humans that can be
                    modulated for health benefits. Cell Metab(2015) 22(5): 789-98.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.cmet.2015/09.005">https://doi.org/10.1016/j.cmet.2015/09.005</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. Bartlett JD, Close GL, MacLarenDPM, Gregson W, DrustB & Morton JP. High-intensity interval
                    running is perceived to be more enjoyable than moderate-intensity continuous exercise: Implications
                    for exercise adherence. Journal of Sports Sciences (2011) 29(6): 547-553.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1080/02640414.2010.545427">https://doi.org/10.1080/02640414.2010.545427</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. ThumJS, Parsons G, Whittle T & AstorinoTA. High-intensity interval training elicits higher
                    enjoyment than moderate intensity continuous exercise. PLoSONE (2017) 12(1): e0166299.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1371/journal.pone.0166299">https://doi.org/10.1371/journal.pone.0166299</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. Martinez N, Kilpatrick MW, Salomon K & Jung ME. Affective and enjoyment responses to
                    high-intensity interval training in overweight-to-obese and insufficiently active adults. Journal of
                    Sport & Exercise Psychology (2015) 37(2): 138-149.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1123/jsep.2014-0212">https://doi.org/10.1123/jsep.2014-0212</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    12. FollandJP, Irish CS, Roberts JC, TarrJE & Jones DA. Fatigue is not a necessary stimulus for
                    strength gains during resistance training. British Journal of Sports Medicine (2002) 36: 370-373
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="http://dx.doi.org/10.1136/bjsm.36.5.370">http://dx.doi.org/10.1136/bjsm.36.5.370</a>
            </div>
        </div>
    )
}

function FoodChoices() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. Malik VS, Pan A, Willett WC & Hu FB. Sugar-sweetened beverages and weight gain in children and
                    adults: a systematic review and meta-analysis. Am J Clin Nutr(2013) 98(4): 1084-102.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3945/ajcn.113.058362">https://doi.org/10.3945/ajcn.113.058362</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. Hu FB & Malik VS. Sugar-Sweetened beverages and risk of obesity and type 2 diabetes:
                    Epidemiologic evidence. PhysiolBehav(2010) 100(1): 47-54.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.physbeh.2010.01.036">https://doi.org/10.1016/j.physbeh.2010.01.036</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. Malik VS, PopkinBM, Bray GA, DespresJP & Hu FB. Sugar-sweetened beverages, obesity, type 2
                    diabetes mellitus, and cardiovascular disease risk. Circulation (2010) 121(11): 1356-64
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1161/CIRCULATIONAHA.109.876185">https://doi.org/10.1161/CIRCULATIONAHA.109.876185</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. TeMorengaL, Mallard S & Mann J. Dietary sugars and body weight: Systematic review and
                    meta-analyses of randomisedcontrolled trials and cohort studies. BMJ (2012) 346:e7492.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="http://doi.org/10.1136/bmj.e7492">http://doi.org/10.1136/bmj.e7492</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. Tate DF, Turnery-McGrievyG, Lyons E, Stevens J, Erickson K, PolzienK, Diamond M, Wang X &
                    PopkinB. Replacing caloric beverages with water or diet beverages for weight loss in adults: Main
                    results of the Choose Healthy Options Consciously Everyday (CHOICE) randomized clinical trial. Am J
                    Clin Nutr(2012) 95(30): 555-563.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3945/ajcn.111.026378">https://doi.org/10.3945/ajcn.111.026378</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. BreymeyerKL, Lampe JW, McGregor BA & NeuhouserML. Subjective mood and energy levels of healthy
                    weight and overweight/obese healthy adults on high-and low-glycemic load experimental diets.
                    Appetite (2016) 107: 253-259.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.appet.2016/08.008">https://doi.org/10.1016/j.appet.2016/08.008</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. Larsen FJ, Schiffer TA, BorniquelS, SahlinK, EkblomB, Lundberg JO & WeitzbergE. Dietary inorganic
                    nitrate improves mitochondrial efficiency in humans. Cell Metab(2011) 13: 149–159.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.cmet.2011.01.004">https://doi.org/10.1016/j.cmet.2011.01.004</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Larsen FJ, WeitzbergE, Lundberg JO & EkblomB. Effects of dietary nitrate on oxygen cost during
                    exercise. Acta Physiologica(2007) 191: 59–66.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1111/j.1748-1716.2007.01713.x">https://doi.org/10.1111/j.1748-1716.2007.01713.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. MachtM. How emotions affect eating: A five-way model. Appetite (2008) 50(1): 1-11.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.appet.2007.07.002">https://doi.org/10.1016/j.appet.2007.07.002</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. Blum K, ThanosPK & Gold MS. Dopamine and glucose, obesity, and reward deficiency syndrome.
                    Frontiers in Psychology (2014) 5(1): 1-11.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3389/fpsyg.2014.00919">https://doi.org/10.3389/fpsyg.2014.00919</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. McClernonFJ, YancyWS, EbersteinJA, Atkins RC & WestmanEC. The effects of a low-carbohydrate
                    ketogenic diet and a low-fat diet on mood, hunger, and other self-reported symptoms. Obesity (2007)
                    15(1): 61-67.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1038/oby.2007.516">https://doi.org/10.1038/oby.2007.516</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    12. Arentson-Lantz E, ClairmontS, Paddon-Jones D, Tremblay A & ElangoR. Protein: A nutrient in
                    focus. Applied Physiology, Nutrition, and Metabolism (2015) 40(8): 755-761.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1139/apnm-2014-0530">https://doi.org/10.1139/apnm-2014-0530</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    13. Van der LaanLN, PapiesEK, HoogeIT & SmeetsPA. Goal-directdvisual attention drives health goal
                    priming: An eye-tracking experiment. Health Psychology (2017) 36(1): 82-90.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1037/hea0000410">https://doi.org/10.1037/hea0000410</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    14. ForwoodSE, Ahern AL, Hollands GJ, Ng YL & Marteau TM. Priming healthy eating. You can’t prime
                    all the people all of the time. Appetite (2015) (89): 93-102.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.appet.2015.01.018">https://doi.org/10.1016/j.appet.2015.01.018</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    15. Turner-McGrievyGM, Wilcox S, BouttéA, Hutto BE, Singletary C, MuthER & Hoover AW. The dietary
                    intervention to enhance tracking with mobile devices (diet mobile) study: A 6-month randomized
                    weight loss trial. Obesity (2017) (8): 1336-1342.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1002/oby.21889">https://doi.org/10.1002/oby.21889</a>
            </div>
        </div>
    )
}

function Endurance() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. HelselDL, JakicicJM & Otto AD. Comparison of techniques for self-monitoring eating and exercise
                    behaviors on weight loss in a correspondence-based intervention. Journal of the Academy of Nutrition
                    and Dietetics (2007) 107(10): 1807-18010.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.jada.2007.07.014">https://doi.org/10.1016/j.jada.2007.07.014</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. CarelsRA, Darby LA, Rydin S, Douglass OM, CacciapagliaHM & O’Brien WH. The relationship between
                    self-monitoring, outcome expectancies, difficulties with eating and exercise and physical activity
                    and weight loss treatment outcomes. Annals of Behavioral Medicine (2005) 30(3): 182-190.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1207/s15324796abm3003_2">https://doi.org/10.1207/s15324796abm3003_2</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. Blair SN, Kohl HW, Gordon NF & PaffenbargerRS. How much physical activity is good for health?
                    Annual Reviews of Public Health (1992) 13: 99–126
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1146/annurev.pu.13.050192.000531">https://doi.org/10.1146/annurev.pu.13.050192.000531</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. Segar ML, Eccles JS & Richardson CR: Type of physical activity goal influences participation in
                    healthy midlife women. WomensHealth Issues (2008) 18: 281-291.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.whi.2008.02.003">https://doi.org/10.1016/j.whi.2008.02.003</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. Hariri AR, Brown SM, Williamson DE, Flory JD, de Wit H & ManuckSB. Preference for immediate over
                    delayed rewards is associated with magnitude of ventral striatal activity. J Neurosci(2006) 26:
                    13213-13217.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1523/JNEUROSCI.3446-06.2006">https://doi.org/10.1523/JNEUROSCI.3446-06.2006</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. LallyP, van Jaarsveld CHM, Potts HWW & Wardle J. How are habits formed: Modelling habit formation
                    in the real world. Euro J SocPsychol(2010) 40: 998–1009.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1002/ejsp.674">https://doi.org/10.1002/ejsp.674</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. LallyP & Gardner B. Promoting habit formation. Health PsycholRev. (2011) 7: 137-158.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1080/17437199.2011.603640">https://doi.org/10.1080/17437199.2011.603640</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Westgarth-Taylor C, Hawley J, Rickard S, MyburghKH, NoakesTD & Dennis SC. Metabolic and
                    performance adaptations to interval training in endurance-trained cyclists. J ApplPhysiol(1997)
                    75(4): 298-304.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1007/s004210050164">https://doi.org/10.1007/s004210050164</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. Coyle EF. Very intense exercise‐training is extremely potent and time efficient: A reminder. J
                    ApplPhysiol(2005) 98: 1983–1984.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1152/japplphysiol.00215.2005">https://doi.org/10.1152/japplphysiol.00215.2005</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. Burgomaster KA, HowarthKR, Phillips SM, RakobowchukM, Macdonald MJ, McGee SL & GibalaMJ. Similar
                    metabolic adaptations during exercise after low volume sprint interval and traditional endurance
                    training in humans. Experimental Physiology (2008) 151-160.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1113/jphysiol.2007.142109">https://doi.org/10.1113/jphysiol.2007.142109</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. GibalaMJ, Little PJ, Van EssanM, Wilkin GP, Burgomaster KA, SafdarA, RahaS & TarnopolskyMA.
                    Short‐term sprint interval versus traditional endurance training: similar initial adaptations in
                    human skeletal muscle and exercise performance. J Physiol(2006) 575: 901–911.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1113/jphysiol.2006.112094 ">https://doi.org/10.1113/jphysiol.2006.112094 </a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    12. RakobowchukM, TanguayS, Burgomaster KA, HowarthKR, GibalaMJ & MacDonald MJ. Sprint interval and
                    traditional endurance training induce similar improvements in peripheral arterial stiffness and
                    flow-mediated dilation in healthy humans. Am J PhysiolRegulIntegrComp Physiol(2008) 295: 236-242
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1152/ajpregu.00069.2008">https://doi.org/10.1152/ajpregu.00069.2008</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    13. MilanovićZ, SporišG & Weston M. Effectiveness of high-intensity interval training (hit) and
                    continuous endurance training for VO2max improvements: A systematic review and meta-analysis of
                    controlled trials. Sports Med (2015) 45: 1469-1481.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1007/s40279-015-0365-0">https://doi.org/10.1007/s40279-015-0365-0</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    14. Larsen FJ, Schiffer TA, BorniquelS, SahlinK, EkblomB, Lundberg JO & WeitzbergE. Dietary
                    inorganic nitrate improves mitochondrial efficiency in humans. Cell Metab(2011) 13: 149–159
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.cmet.2011.01.004">https://doi.org/10.1016/j.cmet.2011.01.004</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    15. Larsen FJ, WeitzbergE, Lundberg JO & EkblomB. Effects of dietary nitrate on oxygen cost during
                    exercise. Acta Physiologica(2007) 191: 59–66.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1111/j.1748-1716.2007.01713.x">https://doi.org/10.1111/j.1748-1716.2007.01713.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    16. Stannard SR, ConstantiniNW & Miller JC. The effect of glycemic index on plasma glucose and
                    lactate levels during incremental exercise. IntJ Sport NutrExercMetab(2000) 10(1): 51-61.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1123/ijsnem.10.1.51">https://doi.org/10.1123/ijsnem.10.1.51</a>
            </div>
        </div>
    )
}

function SpeedAndStrength() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. BuchheitM, LaursenP. High-intensity interval training, solutions to the programming puzzle.
                    Sports Med (2013) 43: 313–38.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1007/s40279-013-0029-x">https://doi.org/10.1007/s40279-013-0029-x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. Weston KS, WisløffU & Coombes JS. High-intensity interval training in patients with
                    lifestyle-induced cardiometabolic disease: A systematic review and meta-analysis. Br J Sports Med
                    (2013): 1227-1234.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1136/bjsports-2013-092576">https://doi.org/10.1136/bjsports-2013-092576</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. IaiaFM & BangsboJ. Speed endurance training is a powerful stimulus for physiological adaptations
                    and performance improvements of athletes. ScandJ Med Sci Sports (2010) 2: 11-23.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1111/j.1600-0838.2010.01193.x">https://doi.org/10.1111/j.1600-0838.2010.01193.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. BillatVL. Interval training for performance: A scientific and empirical practice. Sports Medicine
                    (2001) 31(1): 13-31.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.2165/00007256-200131010-00002">https://doi.org/10.2165/00007256-200131010-00002</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. GibalaMJ & McGee SL. Metabolic adaptations to short-term high-intensity interval training: A
                    little pain for a lot of gain. ExercSport Sci Rev (2008) 36(2): 58-63.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1097/JES.0b013e318168ec1f">https://doi.org/10.1097/JES.0b013e318168ec1f</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. Meckel Y, EliakimA, SaraevM, ZaldivarF, Cooper DM, SagivM & NemetD. The effect of brief sprint
                    interval exercise on growth factors and inflammatory mediators. Journal of Strength and Conditioning
                    Research (2009) 23(1): 225-230.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1519/JSC.0b013e3181876a9a">https://doi.org/10.1519/JSC.0b013e3181876a9a</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. Hazell TJ, Hamilton CD, Oliver TD & Lemon PWR. Running sprint interval training induces fat loss
                    in women. Applied Physiology, Nutrition, and Metabolism, (2014) 39(8): 944-950.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1139/apnm-2013-0503">https://doi.org/10.1139/apnm-2013-0503</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. WewegeM, van den Berg R, Ward RE & KeechA. The effects of high-intensity interval training vs.
                    moderate-intensity continuous training on body composition in overweight and obese adults: A
                    systematic review and meta-analysis. ObesRev (2017) 18(6): 635-646
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1111/obr.12532 ">https://doi.org/10.1111/obr.12532 </a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. TsitkanousS, SpengosK, StasinakiAN, ZarasN, BogdanisG, PapadimasG & Terzis G. Effects of
                    high-intsensityinterval cycling performed after resistance training on muscle strength and
                    hypertrophy. ScandJ Med Sci Sports (2017) 27(11): 1317-1327.
                </p>
                <a target="_blank" rel='noopener noreferrer' href="https://doi.org/10.1111/sms.12751">https://doi.org/10.1111/sms.12751</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. Seitz LB, Reyes A, Tran TT, De Villarreal ES & HaffGG. Increases in lower-body strength transfer
                    positively to sprint performance: A systematic review with meta-analysis. Sports Med (2014) 44:
                    1693-1702.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1007/s40279-014-0227-1">https://doi.org/10.1007/s40279-014-0227-1</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. ShoenfeldBJ, Aragon AA & Krieger JW. The effect of protein timing on muscle strength and
                    hypertrophy: A meta-anlysis. Journal of the International Society of Sports Nutrition (2013) 10(53):
                    1-13.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1186/1550-2783-10-53">https://doi.org/10.1186/1550-2783-10-53</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    12. Arentson-Lantz E, ClairmontS, Paddon-Jones D, Tremblay A & ElangoR. Protein: A nutrient in
                    focus. Applied Physiology, Nutrition, and Metabolism (2015) 40(8): 755-761.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1139/apnm-2014-0530">https://doi.org/10.1139/apnm-2014-0530</a>
            </div>
        </div>
    )
}

function BetterBloodPressure() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. Wu et al. Fruit and vegetables consumption and incident hypertension: Dose-response meta-analysis
                    of prospective cohort studies. J Hum Hypertens 2016; 30(10): 573-80.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/27306085/">https://pubmed.ncbi.nlm.nih.gov/27306085/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. John et al. Effects of fruit and vegetable consumption on plasma antioxidant concentrations and
                    blood pressure: A randomized controlled trial. Lancet 2002; 359: 1969–74.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/12076551/">https://pubmed.ncbi.nlm.nih.gov/12076551/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. Li et al. Gut microbiota dysbiosis contributes to the development of hypertension. Microbiome
                    2017; 5: 14.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://microbiomejournal.biomedcentral.com/articles/10.1186/s40168-016-0222-x">https://microbiomejournal.biomedcentral.com/articles/10.1186/s40168-016-0222-x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. Malik et al. Impact of sugar-sweetened beverages on blood pressure. Am J Cardiol 2014; 113(9):
                    1574-80.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/24630785/">https://pubmed.ncbi.nlm.nih.gov/24630785/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. Te Morenga et al. Dietary sugars and cardiometabolic risk: Systematic review and meta-analyses of
                    randomized controlled trials of the effects on blood pressure and lipids. Am J Clin Nutr 2014;
                    100(1): 65-79.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/24808490/">https://pubmed.ncbi.nlm.nih.gov/24808490/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. Whyte & Laughlin. The effects of acute and chronic exercise on the vasculature. Acta Physiologica
                    2010; 199(4): 441-50.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1748-1716.2010.02127.x">https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1748-1716.2010.02127.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. Arroll & Beaglehole. Does physical activity lower blood pressure: A critical review of the
                    clinical trials. Journal of Clinical Epidemiology 1992; 45(5): 439-47.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://www.jclinepi.com/article/0895-4356(92)90093-3/pdf">https://www.jclinepi.com/article/0895-4356(92)90093-3/pdf</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Hamer et al. The effect of acute aerobic exercise on stress related blood pressure responses: A
                    systematic review and meta-analysis. Biol Psychol 2006; 71(2): 183-90.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/15979232/">https://pubmed.ncbi.nlm.nih.gov/15979232/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. Lusardi et al. Effects of a restricted sleep regimen on ambulatory blood pressure monitoring in
                    normotensive subjects. Am J Hypertens 1996; 9: 503-5.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/8735182/">https://pubmed.ncbi.nlm.nih.gov/8735182/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. Tochikubo et al. Effects of insufficient sleep on blood pressure monitored by a new
                    multibiomedical recorder. Hypertension 1996; 27: 1318–24.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://www.ahajournals.org/doi/epub/10.1161/01.HYP.27.6.1318">https://www.ahajournals.org/doi/epub/10.1161/01.HYP.27.6.1318</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. Knutson. Sleep duration and cardiometabolic risk: A review of the epidemiologic evidence. Best
                    Practices in Clinical Endocrinology and Metabolism 2010; 5: 731-43.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.beem.2010.07.001">https://doi.org/10.1016/j.beem.2010.07.001</a>
            </div>
        </div>
    )
}

function BetterBloodSugar() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-md-6'>
                <p>
                    1. Cooperet al. A prospective study of the association between quantity and variety of fruit and
                    vegetable intake and incident type 2 diabetes. Diabetes Care 2012; 35(6): 1293-1300.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.2337/dc11-2388">https://doi.org/10.2337/dc11-2388</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    2. Weickert et al. Cereal fiber improves whole-body insulin sensitivity in overweight and obese
                    women. Diabetes Care 2006; 29(4): 775-80.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.2337/diacare.29.04.06.dc05-2374">https://doi.org/10.2337/diacare.29.04.06.dc05-2374</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    3. Tangney et al. Adherence to a Mediterranean-type dietary pattern and cognitive decline in a
                    community population. Amer J of Clin Nutr (2011) 93(3): 601-7.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3945/ajcn.113.058362">https://doi.org/10.3945/ajcn.113.058362</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    4. Yaffe et al. Predictors of maintaining cognitive function in older adults: The Health ABC Study.
                    Neurology (2009) 72(23): 2029-2035.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1161/CIRCULATIONAHA.109.876185">https://doi.org/10.1161/CIRCULATIONAHA.109.876185</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    5. Koning et al. Sugar-sweetened and artificially sweetened beverage consumption and risk of type 2
                    diabetes in men. The American Journal of Clinical Nutrition 2011; 93(6): 1321-7.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.3945/ajcn.110.007922">https://doi.org/10.3945/ajcn.110.007922</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    6. Nabb & Benton. The influence on cognition of the interaction between the macro-nutrient content
                    of breakfast and glucose tolerance. Physiology & Behavior (2006) 87(1): 16-23.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1111/j.1467-789X.2005.00170.x">https://doi.org/10.1111/j.1467-789X.2005.00170.x</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    7. Colberg et al. Exercise and type 2 diabetes: The American College of Sports Medicine and the
                    American Diabetes Association: Joint position statement executive summary. Diabetes Care 2010;
                    33(12): 2692-6.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.2337/dc10-1548">https://doi.org/10.2337/dc10-1548</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    8. Bird & Hawley. Update on the effects of physical activity on insulin sensitivity in humans. BMJ
                    Open Sport Exerc Med 2017; 2(1): e000143.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1136/bmjsem-2016-000143">https://doi.org/10.1136/bmjsem-2016-000143</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    9. Gonzalez-Ortiz et al. Effect of sleep deprivation on insulin sensitivity and cortisol
                    concentration in healthy subjects. Diabetes Nutr Metab 2000; 13: 80–3.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/10898125/">https://pubmed.ncbi.nlm.nih.gov/10898125/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    10. Shankar et al. Insufficient rest or sleep and its relation to cardiovascular disease, diabetes
                    and obesity in a national, multiethnic sample. PLoS One (2010) 5(11): e14189.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://pubmed.ncbi.nlm.nih.gov/21152066/">https://pubmed.ncbi.nlm.nih.gov/21152066/</a>
            </div>
            <div className='col-xs-12 col-md-6'>
                <p>
                    11. Knutson. Sleep duration and cardiometabolic risk: A review of the epidemiologic evidence. Best
                    Practices in Clinical Endocrinology and Metabolism (2010) (5): 731-43.
                </p>
                <a target="_blank" rel='noopener noreferrer'
                   href="https://doi.org/10.1016/j.beem.2010.07.001">https://doi.org/10.1016/j.beem.2010.07.001</a>
            </div>
        </div>
    )
}