import React from 'react';
import Error from '../ErrorMessage/Error';
import axios from 'axios';
import queryString from 'query-string';


class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state={
            password:'',
            password2:null,
            message:null,
            flag:false
        }
    }

    alignPassword=(password_1, password_2)=>{
        if(password_2){
            if(password_1 !== password_2 && this.state.flag === false){
                this.setState({message:"Passwords do not match!", flag:true});
            }else if(password_1 === password_2 && this.state.flag === true){
                this.setState({flag:false, message:null});
            }
        }
    };

    resetPassword=(e, token, password)=>{
        e.preventDefault();
        token = queryString.parse(token).token;
        console.log(token);
        if(password.length >= 7){
            axios.put('/login/change-password/confirmation', {token, password})
                .then(()=>{
                    window.location.href = "/";
                })
                .catch(e=>this.setState({message:e.response.data.error}));
        }else{
            this.setState({message:'Password must be greater that 7 characters'});
        }
    };

    render(){
        this.alignPassword(this.state.password, this.state.password2);
        return (
            <div className="apple-background">
                <form onSubmit={(e)=>this.resetPassword(e, this.props.location.search, this.state.password2)}>
                    <div className='password-form'>
                        <h1>Forgot Password</h1>
                        <Error message={this.state.message}/>
                        <p>Enter your new password in the form below. Password must be greater that 7 characters.</p>
                        <label htmlFor="password1">
                            New Password
                        </label>
                        <input type="text" placeholder='New Password' value={this.state.password} onChange={event => this.setState({password:event.target.value})}/>

                        <label htmlFor="password2">
                            Confirm New Password
                        </label>
                        <input type="text" placeholder='New Password' value={this.state.password2} onChange={event => this.setState({password2:event.target.value})}/>

                        <div className='margin-top-2x'>
                            <button className={'btn primary'} onClick={(e)=>this.resetPassword(e, this.props.location.search, this.state.password2)}>RESET</button>
                        </div>
                    </div>
                </form>

            </div>
        )
    }
}

export default ForgotPassword;