import {GET_COACH} from "../actions/action_coach";

export default function(state={}, action){
    switch(action.type){
        case GET_COACH:
            return action.payload.data.results;
        // no default
    }

    return state;
}