import React from 'react';
import { AreaChart, BarChart, Bar, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// styles
import styles from './demographics.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const Demographics = props => {
    const {ageData, weightData, heightData, genderData} = props;

    function ageDemographic() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={ageData}
                    margin={{
                        bottom: 5,
                        right: 15,
                        left: 0,
                        top: 0,
                    }}
                >
                    <XAxis dataKey="age" interval={20}/>
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="members" stroke="#4BBC4E" fill="#4BBC4E" />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    function weightDemographic() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={weightData}
                    margin={{
                        bottom: 5,
                        right: 15,
                        left: 0,
                        top: 0,
                    }}
                >
                    <XAxis domain={['dataMin', 'dataMax']} type='number' tickCount={5} dataKey="weight"/>
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="members" stroke="#4BBC4E" fill="#4BBC4E" />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    function heightDemographic() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    type='number'
                    data={heightData}
                    margin={{
                        bottom: 5,
                        right: 15,
                        left: 0,
                        top: 0,
                    }}
                >
                    <XAxis domain={[48, 'dataMax']} type='number' interval={10} dataKey="height"/>
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="members" stroke="#4BBC4E" fill="#4BBC4E" />
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    function genderDemographic() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    barCategoryGap={'0%'}
                    barGap={0}
                    data={genderData}
                    margin={{
                        bottom: 5,
                        right: 15,
                        left: 0,
                        top: 0,
                    }}
                >
                    <XAxis dataKey="name" padding={{left: 30, right: 30}}/>
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="gender" fill="#4BBC4E" />
                </BarChart>
            </ResponsiveContainer>
        )
    }

    return (
        <div className={styles.demographicsContainer}>
            <div className={styles.demographics}>
                {ageData ? <div className="t-a:c m-b:2">Age</div> : null}
                <div className={styles.inner}>
                    {ageData ? ageDemographic() : <GraphPlaceholder/>}
                </div>
            </div>
            <div className={styles.demographics}>
                {weightData ? <div className="t-a:c m-b:2">Weight</div> : null}
                <div className={styles.inner}>
                    {weightData ? weightDemographic() : <GraphPlaceholder/>}
                </div>
            </div>
            <div className={styles.demographics}>
                {heightData ? <div className="t-a:c m-b:2">Height</div> : null}
                <div className={styles.inner}>
                    {heightData ? heightDemographic() : <GraphPlaceholder/>}
                </div>
            </div>
            <div className={styles.demographics}>
                {genderData ? <div className="t-a:c m-b:2">Gender</div> : null}
                <div className={styles.inner}>
                    {genderData ? genderDemographic() : <GraphPlaceholder/>}
                </div>
            </div>
        </div>
    )
}

export default Demographics;