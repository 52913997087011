//client detail subheader component
import React from 'react';
import axios from 'axios';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchSingleUser, clearSingleUserState} from "../../../actions/action_user";
import {getUserWellnessScore} from "../../../actions/action_wellnessScore";
import PhotoContainer from '../../Client/Inbox/container';

class AdminSubHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          adminList:[],
          profileImage: '',
          username: '',
          firstName:'',
          lastName:'',
        };
    }


    componentDidMount() {
      axios.get(`/user/admin/single/${this.props.username}`)
        .then((res => {
          console.log(res)
          this.setState({coachList:res.data.results, firstName:res.data.results.first_name, lastName:res.data.results.last_name})
          this.setState({profileImage:res.data.results.profile_image})
        }))
          .catch((error)=>{
             console.log(error);
          });   
    }

    render() {

        return(
            <div className="">
              <div className="page-header-container">
                  <div className="container">
                      <div className="row">
                          <div className="col-sm-12">
                            <nav>
                              <ol className="breadcrumb ">
                                <li className="breadcrumb-item underline "><a href="/dashboard" class="text-light">Dashboard</a></li><span className="arrow"></span> 
                                <li className="breadcrumb-item text-light active capitalize">{this.props.username ? this.state.firstName + ' ': null} {this.props.username ? this.state.lastName : null}</li>  
                              </ol>
                            </nav>
                          </div>
                          <div className="col-sm-12 col-md-6 margin-top">
                              <div className="profile-image">
                                <PhotoContainer 
                                image={this.state.profileImage} 
                                />
                              </div>
                              <div className="profile-container">
                                  <div className="profile-name">
                                    {this.state.coachTitle}
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
                </div>


                <div className="subHeader-container">
                  <div className="container">
                      <div className="row">
                          <div className="col-sm-12">
                            <ul className="subheaderNav">
                                <li className="current"><Link className="disabledCursor" to={this.props.match.url}>Admin Summary</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            );
    }
};

function mapStateToProps({singleUser, wellnessScore}) {
    return {
        singleUser, wellnessScore
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        fetchSingleUser,
        getUserWellnessScore,
        clearSingleUserState
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSubHeader));
