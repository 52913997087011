import React from 'react';
import parseISO from "date-fns/parseISO";
import format from 'date-fns/format';

// styles
import styles from './targetedActivity.module.css';

import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import Loading from "../../../Loading/Loading";

const TargetedActivity = props => {
    // props
    const {data, nextPage} = props;


    if (data.data === null || !Array.isArray(data.data)) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }
    return (
        <div  className={styles.container}>
            {data.data.map((action, i) => {
                const date = parseISO(action.score_time);
                const time = format(date, 'h:mm aaa');
                const day = format(date, 'MM/dd/yy');
                return (
                    <div key={`${day}${time}${i}`} className={`${i%2 === 0 ? styles.colored : ''} ${styles.row}`}>
                        <div className={styles.actions}>{action.activity}</div>
                        <div className={styles.date}>
                            <div>{time} - {day}</div>
                        </div>
                    </div>
                )
            })}
            {data.page < data.pages || !data.pages ?
                <div className={`${styles.row} ${styles.last}`}>
                    {data.loading ? <Loading color='#fff'/> : <div onClick={nextPage} className={styles.actions}>Load More</div>}
                </div>
                :
                <div className={`${styles.row} ${styles.last}`}>
                    <div className={styles.actions}>No More Activity</div>
                </div>
            }
        </div>
    )
}

export default TargetedActivity;