import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { addDays, isAfter, format } from 'date-fns'
import {Link} from "react-router-dom";
import moment from "moment";
import {Button, Glyphicon, Modal} from "react-bootstrap";

// styles
import styles from './calendlyEventView.module.css';

// actions
import {getEvents} from "../../store/actions/calendarActions";

// components
import {BoxContainer} from "../containers/boxContainer/boxContainer";
import {ErrorAlert} from "../alerts/alerts";

const CalendlyEventView = props => {
    // state
    const {singleCoach} = props;
    // local
    const [mounted, setMounted] = useState(false);
    const [error, setError] = useState(null);
    const [events, setEvents] = useState([]);
    const [stats, setStats] = useState({today: 0, thisWeek: 0, total: 0});
    const [showModal, setShowModal] = useState({show: false, event: null});

    function isToday(date) {
        let midnight = new Date();
        midnight.setHours(0,0,0,0);
        const tomorrow = addDays(midnight, 1);
        return !isAfter(new Date(date), tomorrow);
    }

    function isThisWeek(date) {
        let midnight = new Date();
        midnight.setHours(0,0,0,0);
        const nextWeek = addDays(midnight, 7);
        return !isAfter(new Date(date), nextWeek);
    }

    function priorityDot(date) {
        if (isToday(date)) return <span className={`${styles.circle} ${styles.red}`}/>;
        if (isThisWeek(date)) return <span className={`${styles.circle} ${styles.yellow}`}/>;
        return <span className={`${styles.circle} ${styles.green}`}/>
    }

    function parseMember(member, user) {
        if (user) {
            const orgId = user.org_sign_ups && user.org_sign_ups.length ? user.org_sign_ups[0].organization_id : '';
            const orgName = user.org_sign_ups && user.org_sign_ups.length && user.org_sign_ups[0].organization ? user.org_sign_ups[0].organization.name : '';
            return (
                <span>
                    <Link to={`/client/${user.username}/overview`}>{user.first_name} {user.last_name}</Link>
                    {orgId && orgName ?
                        <span>
                            &nbsp;-&nbsp;
                            <Link to={`/dashboard/Organization/${orgId}/overview`}>{orgName} ({orgId})</Link>
                        </span>
                        : null
                    }
                </span>
            )
        }
        return (
            <span>{member.name}</span>
        )

    }

    function openModal(event) {
        setShowModal({show: true, event});
    }

    function closeModal() {
        setShowModal({show: false, event: null});
    }

    // grab events on mount
    useEffect(() => {
        getEvents(singleCoach.id)
            .then(result => {
                const eventStats = {today: 0, thisWeek: 0, total: result.length};
                result.forEach(stat => {
                    if (isToday(stat.start_time)) eventStats.today += 1;
                    if (!isToday(stat.start_time) && isThisWeek(stat.start_time)) eventStats.thisWeek += 1;
                })
                setMounted(true);
                setStats(eventStats);
                setEvents(result)
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setError(e.response.data.error)
                } else {
                    setError('Unable to get list of Calendly events. If this error persists, please contact your systems administrator.')
                }
                setMounted(true);
            });
    }, []);

    if (error) {
        return (
            <div className="container margin-top-lrg">
                <h2 className="m:0 margin-bottom">Coach Upcoming Events</h2>
                <ErrorAlert>{error}</ErrorAlert>
            </div>
        )
    }
    if (events.length === 0 && !mounted) {
        return <div/>
    }

    return (
        <div className="container margin-top-lrg">
            <h2 className="m:0 margin-bottom">Coach Upcoming Events</h2>
            <div className={`${styles.eventsStats} margin-bottom-med`}>
                <BoxContainer className={styles.stat}>
                    <h2>{stats.today}</h2>
                    <p><span className={`${styles.circle} ${styles.red}`}/>Today</p>
                </BoxContainer>
                <BoxContainer className={styles.stat}>
                    <h2>{stats.thisWeek}</h2>
                    <p><span className={`${styles.circle} ${styles.yellow}`}/>This Week</p>
                </BoxContainer>
                <BoxContainer className={styles.stat}>
                    <h2>{stats.total}</h2>
                    <p><span className={`${styles.circle} ${styles.green}`}/>Total</p>
                </BoxContainer>
            </div>
            <div>
                {events.length ?
                    <div className={`${styles.event}`}>
                        <div className={styles.flag}><span className={styles.circle}/></div>
                        <div className={styles.date}><strong>Date</strong></div>
                        <div className={styles.time}><strong>Time</strong></div>
                        <div className={styles.member}><strong>Member</strong></div>
                    </div>
                    :<p>No events scheduled</p>
                }
                {events.map(item => (
                    <BoxContainer key={item.uri} className={`${styles.event} margin-bottom`}>
                        <div className={styles.flag}>{priorityDot(item.start_time)}</div>
                        <div className={styles.date}>{isToday(item.start_time) ? 'Today' : format(new Date(item.start_time), 'MM/dd/yy')}</div>
                        <div className={styles.time}>{format(new Date(item.start_time), 'hh:mma')}</div>
                        <div className={styles.member}>{parseMember(item.member, item.user)}</div>
                        <div className={styles.button} onClick={()=>openModal(item)}>View Event</div>
                    </BoxContainer>
                ))}
            </div>
            <Modal show={showModal.show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Event event={showModal.event}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function mapStateToProps({singleCoach}) {
    return {singleCoach};
}
export default connect(mapStateToProps)(CalendlyEventView);

function Event({event}) {
    if (!event) return <div/>
    return(
        <div>
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <h2>{`${moment(event.start_time).format('MMMM Do YYYY')}`}</h2>
                    <h3>{`${moment(event.start_time).format('h:mm a')} - ${moment(event.end_time).format('h:mm a')}`}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div>
                        <h4><Glyphicon glyph="glyphicon glyphicon-list" /> What</h4>
                    </div>
                    <p>{event.name}</p>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div>
                        <h4><Glyphicon glyph="glyphicon glyphicon-user" /> Members</h4>
                    </div>
                    <p>{event.member.name}</p>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div>
                        <h4><Glyphicon glyph="glyphicon glyphicon-globe" /> Status</h4>
                    </div>
                    <p>{event.status}</p>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div>
                        <h4><Glyphicon glyph="glyphicon glyphicon-calendar" /> Created at</h4>
                    </div>
                    <p>{moment(event.created_at).calendar()}</p>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div>
                        <h4><Glyphicon glyph="glyphicon glyphicon-envelope" /> Email</h4>
                    </div>
                    <p>{event.member.email}</p>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div>
                        <h4><Glyphicon glyph="glyphicon glyphicon-bullhorn" /> Preferred Method</h4>
                    </div>
                    <p></p>
                </div>
                { event.member.questions_and_answers.map(question => (
                    <div className="col-xs-12">
                        <h5>{question.question}</h5>
                        <p>{question.answer}</p>
                    </div>
                ))}

            </div>
        </div>
    )
}