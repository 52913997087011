import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import './Step3.css'
import {GoalBar} from './GoalBar';
import {Notes} from './Notes';
import {  } from 'react-bootstrap';



let newPlan = {};
let intialPlanArr = [];

class Step3 extends Component{
   constructor(props){
       super(props);
           this.state = {
               firstName : '',
               planUnits: '',
               planUnits1: '',
               planUnits2: '',
               planUnits3: '',
               planUnits4: '',
               planUnits5: '',
               planUnits6: '',
               planStatus: ["a", 1],
               planStatus1: ["a", 1],
               planStatus2: ["a", 1],
               planStatus3: ["a", 1],
               planStatus4: ["a", 1],
               planStatus5: ["a", 1],
               description: this.props.location.state.description,
               value: [null, 5, 15],
               value1: [null, 5, 15],
               value2: [null, 5, 15],
               value3: [null, 5, 15],
               value4: [null, 5, 15],
               value5: [null, 5, 15],
               noteTitle: '',
               notes: '',
               // planStatus: this.props.location.state.planStatus,
               scaleStart:[null, 0],
               scaleStart1:[null, 0],
               scaleStart2:[null, 0],
               scaleStart3:[null, 0],
               scaleStart4:[null, 0],
               scaleStart5:[null, 0],
               scaleEnd:[null, 20],
               scaleEnd1:[null, 20],
               scaleEnd2:[null, 20],
               scaleEnd3:[null, 20],
               scaleEnd4:[null, 20],
               scaleEnd5:[null, 20],
               hide: true,
               custom: '',
               newPlans: {},
               step3Child: true,
               initialScaleStart: 0,
               initialScaleEnd: 20,
               initialMinPoint: 5, 
               initialMaxPoint: 15,
               inverted: false,
               inverted1: false,
               inverted2: false,
               inverted3: false,
               inverted4: false,
               inverted5: false,
               increment: [0, 1],
               increment1: [1, 1],
               increment2: [2, 1],
               increment3: [3, 1],
               increment4: [4, 1],
               increment5: [5, 1],
               initialIncrement:1,
       };
   }

      incrementChangeHandler = (value) => {
        if(value[0] === 0){
          this.setState({
            increment: value
          }, () => console.log(this.state.increment))
        }
        else if(value[0] === 1){
           this.setState({
            increment1: value
          }, () => console.log(this.state.increment1))       
        }
        else if(value[0] === 2){
           this.setState({
            increment2: value
          }, () => console.log(this.state.increment2))       
        }
        else if(value[0] === 3){
           this.setState({
            increment3: value
          }, () => console.log(this.state.increment3))       
        } 
        else if(value[0] === 4){
           this.setState({
            increment4: value
          }, () => console.log(this.state.increment4))       
        }
        else if(value[0] === 5){
           this.setState({
            increment5: value
          }, () => console.log(this.state.increment5))       
        }
      }

      invertChangeHandler = (value) => {
        console.log(value)
        if(value[0] === 0){
          this.setState({
            inverted: value
          }, () => console.log(this.state.inverted))
        }
        else if(value[0] === 1){
           this.setState({
            inverted1: value
          }, () => console.log(this.state.inverted1))       
        }
        else if(value[0] === 2){
           this.setState({
            inverted2: value
          }, () => console.log(this.state.inverted2))       
        }
        else if(value[0] === 3){
           this.setState({
            inverted3: value
          }, () => console.log(this.state.inverted3))       
        } 
        else if(value[0] === 4){
           this.setState({
            inverted4: value
          }, () => console.log(this.state.inverted4))       
        }
        else if(value[0] === 5){
           this.setState({
            inverted5: value
          }, () => console.log(this.state.inverted5))       
        }
      }

     scaleStartHandler = (scaleStartValue) => {

      if(scaleStartValue[0] === 0){
        this.setState({
          scaleStart: scaleStartValue
        })
      }
      else if(scaleStartValue[0] === 1){
         this.setState({
          scaleStart1: scaleStartValue
        })       
      }
      else if(scaleStartValue[0] === 2){
         this.setState({
          scaleStart2: scaleStartValue
        })       
      }
      else if(scaleStartValue[0] === 3){
         this.setState({
          scaleStart3: scaleStartValue
        })       
      } 
      else if(scaleStartValue[0] === 4){
         this.setState({
          scaleStart4: scaleStartValue
        })       
      }
      else if(scaleStartValue[0] === 5){
         this.setState({
          scaleStart5: scaleStartValue
        })       
      }

      };

     scaleEndHandler = (scaleEndValue) => {
      if(scaleEndValue[0] === 0){
        this.setState({
          scaleEnd: scaleEndValue
        })
      }
      else if(scaleEndValue[0] === 1){
         this.setState({
          scaleEnd1: scaleEndValue
        })       
      }
      else if(scaleEndValue[0] === 2){
         this.setState({
          scaleEnd2: scaleEndValue
        })       
      }
      else if(scaleEndValue[0] === 3){
         this.setState({
          scaleEnd3: scaleEndValue
        })       
      } 
      else if(scaleEndValue[0] === 4){
         this.setState({
          scaleEnd4: scaleEndValue
        })       
      }
      else if(scaleEndValue[0] === 5){
         this.setState({
          scaleEnd5: scaleEndValue
        })       
      }

      };



    valueHandler = (valueFromGoalBar) => {
      console.log(valueFromGoalBar)
      if(valueFromGoalBar[0] === 0){
        this.setState({
          value: valueFromGoalBar
        })
      }
      else if(valueFromGoalBar[0] === 1){
         this.setState({
          value1: valueFromGoalBar
        })       
      }
      else if(valueFromGoalBar[0] === 2){
         this.setState({
          value2: valueFromGoalBar
        })       
      }
      else if(valueFromGoalBar[0] === 3){
         this.setState({
          value3: valueFromGoalBar
        })       
      } 
      else if(valueFromGoalBar[0] === 4){
         this.setState({
          value4: valueFromGoalBar
        })       
      }
      else if(valueFromGoalBar[0] === 5){
         this.setState({
          value5: valueFromGoalBar
        })       
      }

    }

     descriptionHandler = (descriptionValue) => {
        // this.setState({
        //   description: descriptionValue
        // })
        this.setState({description: this.state.description.concat(descriptionValue)})

      };

     planStatusHandler = (planStatusValue) => {
      if(planStatusValue[0] === 0){
        this.setState({
          planStatus: planStatusValue[planStatusValue.length-1]
        })
      }
      else if(planStatusValue[0] === 1){
         this.setState({
          planStatus1: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 2){
         this.setState({
          planStatus2: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 3){
         this.setState({
          planStatus3: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 4){
         this.setState({
          planStatus4: planStatusValue
        })       
      }
      else if(planStatusValue[0] === 5){
         this.setState({
          planStatus5: planStatusValue
        })       
      }
      };

      planStatusHandlerNew = (value) => {
        if(value[0] === 0){
          this.setState({
            planStatus: value
          }, () => console.log(this.state.planStatus))
        }
        else if(value[0] === 1){
           this.setState({
            planStatus1: value
          }, () => console.log(this.state.planStatus1))       
        }
        else if(value[0] === 2){
           this.setState({
            planStatus2: value
          }, () => console.log(this.state.planStatus2))       
        }
        else if(value[0] === 3){
           this.setState({
            planStatus3: value
          }, () => console.log(this.state.planStatus3))       
        } 
        else if(value[0] === 4){
           this.setState({
            planStatus4: value
          }, () => console.log(this.state.planStatus4))       
        }
        else if(value[0] === 5){
           this.setState({
            planStatus5: value
          }, () => console.log(this.state.planStatus5))       
        }
      }

     noteTitleHandler = (noteTitleValue) => {
        this.setState({
          noteTitle: noteTitleValue
        })
      };

     notesHandler = (notesValue) => {
        this.setState({
          notes: notesValue
        })
      };

     planUnitsHandler = (planUnitsValue) => {
      if(planUnitsValue.startsWith(0)){
        this.setState({
          planUnits: planUnitsValue
        })
      }
      else if(planUnitsValue.startsWith(1)){
         this.setState({
          planUnits1: planUnitsValue
        })       
      }
      else if(planUnitsValue.startsWith(2)){
         this.setState({
          planUnits2: planUnitsValue
        })       
      }
      else if(planUnitsValue.startsWith(3)){
         this.setState({
          planUnits3: planUnitsValue
        })       
      }
      else if(planUnitsValue.startsWith(4)){
         this.setState({
          planUnits4: planUnitsValue
        })       
      }
      else if(planUnitsValue.startsWith(5)){
         this.setState({
          planUnits5: planUnitsValue
        })       
      }
      else if(planUnitsValue.startsWith(6)){
         this.setState({
          planUnits6: planUnitsValue
        })       
      }
      };

    moveToStep4(
                categoryId,
                category, 
                description, 
                planStatus,
                planStatus1,
                planStatus2,
                planStatus3,
                planStatus4,
                planStatus5, 
                scaleStart,
                scaleStart1,
                scaleStart2,
                scaleStart3,
                scaleStart4,
                scaleStart5,
                scaleEnd,
                scaleEnd1,
                scaleEnd2,
                scaleEnd3,
                scaleEnd4,
                scaleEnd5,  
                planUnits,
                planUnits1,
                planUnits2,
                planUnits3,
                planUnits4,
                planUnits5,
                planUnits6, 
                newPlans, 
                value, 
                value1, 
                value2, 
                value3, 
                value4,
                value5,
                noteTitle,
                notes,
                inverted,
                inverted1,
                inverted2,
                inverted3,
                inverted4,
                inverted5,
                increment,
                increment1,
                increment2,
                increment3,
                increment4,
                increment5,
                ){
        if(this.state.planUnits.length <= 1) {
            alert('plan units required')
            return;
        }
        if(this.state.description[this.state.description.length-1] === "Custom" || this.state.description[this.state.description.length-1] === ""){
          alert("a unique custom description is required");
          return;
          }
        if(this.props.location.state.custom === true){
          for(var i=this.state.description.length -2; this.state.description[i] !== "Custom"; i--) {
            this.state.description.splice(i, 1)
          }
  // eslint-disable-next-line
          for(var i = 0; i <= this.state.description.length; i++){
            if(this.state.description[i] === "Custom") {
              this.state.description.splice(i, 1);
            }
          }
        }
        if(this.state.description.reduce((a, b) => a && intialPlanArr.includes(b), true)){
            alert('tile already exists')
            this.setState({description: this.props.location.state.description})
            return;
        }
        if(intialPlanArr.includes(this.state.description[this.state.description.length-1])){
                console.log(this.state.description.length-1)
                alert("a unique custom description is required")
                this.setState({description: this.props.location.state.description})
                return;
        }
        let currentPath = this.props.location.pathname,
            parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
        this.props.history.push({
            pathname: parentPath+'/step-4',
            state: {
                categoryId:categoryId,
                category:category,
                description:description,
                planStatus:planStatus,
                planStatus1:planStatus1,
                planStatus2:planStatus2,
                planStatus3:planStatus3,
                planStatus4:planStatus4,
                planStatus5:planStatus5,
                scaleStart:scaleStart,
                scaleStart1:scaleStart1,
                scaleStart2:scaleStart2,
                scaleStart3:scaleStart3,
                scaleStart4:scaleStart4,
                scaleStart5:scaleStart5,
                scaleEnd:scaleEnd,
                scaleEnd1:scaleEnd1,
                scaleEnd2:scaleEnd2,
                scaleEnd3:scaleEnd3,
                scaleEnd4:scaleEnd4,
                scaleEnd5:scaleEnd5,
                planUnits:planUnits,
                planUnits1:planUnits1,
                planUnits2:planUnits2,
                planUnits3:planUnits3,
                planUnits4:planUnits4,
                planUnits5:planUnits5,
                planUnits6:planUnits6,
                newPlans:newPlans,
                value:value, 
                value1:value1, 
                value2:value2, 
                value3:value3, 
                value4:value4,
                value5:value5,
                noteTitle:noteTitle,
                notes:notes,
                inverted:inverted,
                inverted1:inverted1,
                inverted2:inverted2,
                inverted3:inverted3,
                inverted4:inverted4,
                inverted5:inverted5,
                increment:increment,
                increment1:increment1,
                increment2:increment2,
                increment3:increment3,
                increment4:increment4,
                increment5:increment5,
            }
        })      
    }

      newPlanObj = () => {
        this.setState(prevState => ({
          newPlans: { 
              ...prevState.newPlans,
              newPlan
          }
      }))

    }; 

    componentDidMount() {

        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({firstName:res.data.user.first_name});
            })
        axios.get(`/tiles/list/${this.props.username}`)
            .then(res => {
                this.setState({allTilesArr: res.data.result})

                console.log(this.state.allTilesArr)
            })
               this.findDuplicateTiles()

    }

    findDuplicateTiles=()=>{
            if(this.state.allTilesArr){
                return this.state.allTilesArr.map((item, i)=>{   
                    // if(item.plan[0])console.log(item.plan[0].description)
                    // let categoryId = item.tile.tile_category_id;
                    // let plan = item.plan[0].description;
                    if(item.plan.length > 1){
                        let y = item.plan.length; 
                        for(let x=0; x < y; x++)
                        intialPlanArr.push(item.plan[x].description);
                    }
                    else{
                        if(item.plan[0])intialPlanArr.push(item.plan[0].description)
                    }
                });
            }
        };


      loop() {
      
      var i;
      var bars = [];
      for (i = 0; i < this.props.location.state.description.length; i++) { 
        newPlan[i] =  { 
                category:this.props.location.state.category,
                description:this.props.location.state.description[i],
                planStatus:'',
                scaleStart:0,
                scaleEnd:0,
                planUnits:'',
                minPoint:0,
                maxPoint:0,
          };
        bars.push(<GoalBar 
                     incrementChangeHandler = {this.incrementChangeHandler}
                     invertChangeHandler = {this.invertChangeHandler}
                     singleDescription = {this.props.location.state.description[i]}
                     planUnitsHandler={this.planUnitsHandler}
                     valueHandler={this.valueHandler}
                     scaleStartHandler={this.scaleStartHandler}
                     scaleEndHandler={this.scaleEndHandler}
                     descriptionHandler={this.descriptionHandler}
                     category={this.props.location.state.category} 
                     description={this.props.location.state.description}
                     hide={this.state.hide}
                     planStatusHandler={this.planStatusHandler} 
                     planStatusHandlerNew={this.planStatusHandlerNew}
                     newPlans={this.state.newPlans}
                     planStatuses={[this.state.planStatus,this.state.planStatus1,this.state.planStatus2,this.state.planStatus3,this.state.planStatus4,this.state.planStatus5]}
                     planStatus={this.state.planStatus}
                     planStatus1={this.state.planStatus1}
                     planStatus2={this.state.planStatus2}
                     planStatus3={this.state.planStatus3}
                     planStatus4={this.state.planStatus4}
                     planStatus5={this.state.planStatus5}
                     id={i}
                     scaleStart={this.state.initialScaleStart}
                     scaleEnd={this.state.initialScaleEnd}
                     minPoint={this.state.initialMinPoint}
                     maxPoint={this.state.initialMaxPoint}
                     increments={[this.state.increment,this.state.increment1,this.state.increment2]}
                     increment={this.state.increment}
                     increment1={this.state.increment1}
                     increment2={this.state.increment2}
                     increment3={this.state.increment3}
                     increment4={this.state.increment4}
                     increment5={this.state.increment5}
                     />);
        }
        
        return bars;
      }
      
    goBack() {
        window.history.back();
    }


    render(){

      this.findDuplicateTiles()
        return(
            <div>
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.firstName}</p>
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="col-md-1 col-xs-12">                        
                        <button className="btn"  onClick={()=>this.goBack()}>BACK</button>    
                    </div>
                    <div className="col-md-10 col-xs-12">
                        <ul className="progressbar text-center">
                            <li className="complete">Step 1: Select Tile</li>
                            <li className="complete">Step 2: Select Plan</li>
                            <li className="active">Step 3: Set Goals</li>
                            <li>Step 4: Confirm & Save</li>
                        </ul>
                    </div>
                    <div className="col-md-1 col-xs-12">                           
                        <button className="btn primary" onClick={()=>this.moveToStep4(
                        this.props.location.state.categoryId,
                        this.props.location.state.category,
                        this.state.description,
                        this.state.planStatus,
                        this.state.planStatus1,
                        this.state.planStatus2,
                        this.state.planStatus3,
                        this.state.planStatus4,
                        this.state.planStatus5,
                        this.state.scaleStart,
                        this.state.scaleStart1,
                        this.state.scaleStart2,
                        this.state.scaleStart3,
                        this.state.scaleStart4,
                        this.state.scaleStart5,
                        this.state.scaleEnd,
                        this.state.scaleEnd1,
                        this.state.scaleEnd2,
                        this.state.scaleEnd3,
                        this.state.scaleEnd4,
                        this.state.scaleEnd5,
                        this.state.planUnits,
                        this.state.planUnits1,
                        this.state.planUnits2,
                        this.state.planUnits3,
                        this.state.planUnits4,
                        this.state.planUnits5,
                        this.state.planUnits6,
                        this.state.newPlans,
                        this.state.value,
                        this.state.value1,
                        this.state.value2,
                        this.state.value3,
                        this.state.value4,
                        this.state.value5,
                        this.state.noteTitle,
                        this.state.notes,
                        this.state.inverted,
                        this.state.inverted1,
                        this.state.inverted2,
                        this.state.inverted3,
                        this.state.inverted4,
                        this.state.inverted5,
                        this.state.increment,
                        this.state.increment1,
                        this.state.increment2,
                        this.state.increment3,
                        this.state.increment4,
                        this.state.increment5,
                        )}>Next Step</button>
                    
                    </div>
                </div> 
                          
                <div className="row tile-creation--container">
                    <div className="col-md-6 col-xs-12">
                        <h3>Goal Bar Settings</h3>
                        <p>Set initial goal</p>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <h3>Tile Coaching Notes</h3>  
                        <p>Private Notes for future reference (Member will never see this)</p>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6" >
                        {this.loop()}
                    </div>
                    
                    <Notes 
                    noteTitleHandler={this.noteTitleHandler}
                    notesHandler={this.notesHandler}
                    step3Child={this.state.step3Child}
                    />
                    
                </div>
            </div>
        )

    }
}

export default withRouter(Step3);