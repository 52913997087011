import React, {useState} from 'react';

// styles
import styles from './tagList.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {ReactComponent as Plus} from '../../../images/plus-solid-circle.svg';
import {PrimaryButton} from "../../buttons/buttons";

const TagList = props => {
    // props
    const {tags, selected, callback, createCallback} = props;
    // local
    const [edit, setEdit] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    function save(e) {
        e.preventDefault();
        setIsOpen(false);
        setEdit('');
        createCallback(edit);
    }
    return (
        <div>
            <div className="d:f">
                <h4 className={styles.title}>Tags</h4>
                <button onClick={() => setIsOpen(!isOpen)} className={styles.addButton}><Plus/></button>
            </div>
            {isOpen ?
                <form onSubmit={save}>
                    <div className={`form-group d:f ${styles.form}`}>
                        <input
                            className="form-control"
                            value={edit}
                            onChange={event => setEdit(event.target.value)}
                            type="text"/>
                        <PrimaryButton className={styles.save} onClick={save}>Save</PrimaryButton>
                    </div>
                </form>
                : null
            }
            <BoxContainer className={styles.tagContainer}>
                {tags.map(tag =>(
                    <button
                        onClick={() => callback(tag)}
                        className={selected && tag.id === selected.id ? `${styles.tag} ${styles.selected}` : styles.tag}>
                        {tag.name} ({tag.count})
                    </button>
                ))}
            </BoxContainer>
        </div>
    )
}

export default TagList;