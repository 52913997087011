import React, { Component } from 'react';

import AmCharts from "@amcharts/amcharts3-react";
import moment from 'moment';


class LineGraph extends Component{

    constructor(props){
        super(props);
        this.handleData = this.handleData.bind(this);
        this.renderGraph = this.renderGraph.bind(this);

    }

    formatData(){
        let list = this.props.graph_data.map((item) => {
            return ({date: moment.parseZone(item.date).format('YYYY/MM/DD'), value: item.plan_value})
        });
        return this.handleData(list)
    }

    handleData(list){
      
      if(list && list.length > 0){
        let date_tracker = moment(this.props.time).startOf(this.props.timerange);
        let range = moment(this.props.time).endOf(this.props.timerange).add(1, 'days');
        for(let i=0; i<=list.length; i++){
          if(i === list.length && date_tracker.isSame(range, "day")){
              break;
          }else if(i === list.length){
              //handle data if element at this array index was empty
              let datapoint = {date:moment(date_tracker).format('YYYY/MM/DD'), value:null};
              //place new data at loop index
              list.splice(i, 0, datapoint);
          }else{
              if(moment(list[i].date).isAfter(date_tracker, 'day')){
                  //handle data if element at this array index was empty
                  let datapoint = {date:moment(date_tracker).format('YYYY/MM/DD'), value:null};
                  //place new data at loop index
                  list.splice(i, 0, datapoint);
              }
          }
            //increment date_tracker
            if(moment(date_tracker).isBefore(range)){
                date_tracker = moment(date_tracker).add(1, 'days');
          
              }else if(moment(date_tracker).isAfter(range, 'day')){
              break;
            }
        }
   
          list = list.map(item=>{
              if(moment(item.date).isAfter(moment())) item.value = null;
              return item;
          });
        this.props.enableTimeChange();
        return this.renderGraph(list);
      }
    }

    renderGraph(list){
        let chart_config= {
            "type": "serial",
            "theme": "light",
            "marginRight": 40,
            "marginTop": 50,
            "marginLeft":40,
            "autoMarginOffset": 20,
            "mouseWheelZoomEnabled":false,
            "dataDateFormat": "YYYY-MM-DD",
            "valueAxes": [{
                "axisAlpha": 0,
                "gridThickness": 2,
                "gridColor": "black",
                "position": "left",
                "dashLength":1,
                "ignoreAxisWidth":true,
                "color":"black"
            }],
            "balloon": {
                "borderThickness": 1,
                "shadowAlpha": 0
            },

            "graphs": [{
                "id": "g1",
                "balloon":{
                    "drop":true
                },
                "bullet": "round",
                "bulletBorderAlpha": 1,
                "bulletColor": "#00b200",
                "bulletSize": 7,
                "connect": false,
                "hideBulletsCount": 50,
                "lineThickness": 3,
                "lineColor":"#00b200",
                "title": "red line",
                "type": "smoothedLine",
                "useLineColorForBulletBorder": true,
                "valueField": "value",
                "balloonText": "<span style='font-size:16px;'>[[value]]</span>"
            }],
            "chartScrollbar": {
                "autoGridCount": true,
                "graph": "g1",
                "scrollbarHeight": 60,
                "offset": 30,
                "oppositeAxis": false,
                "backgroundAlpha": 0,
                "selectedBackgroundAlpha": 0.3,
                "selectedBackgroundColor": "rgba(0,0,0,.2)",
                "graphFillAlpha": 0,
                "graphLineAlpha": 0.5,
                "selectedGraphFillAlpha": .5,
                "selectedGraphLineAlpha": 1,
                "color":"black"
            },
            "chartCursor": {
                "pan": true,
                "valueLineEnabled": true,
                "valueLineBalloonEnabled": true,
                "cursorAlpha":1,
                "cursorColor":"#258cbb",
                "limitToGraph":"g1",
                "valueLineAlpha":0.5,
                "valueZoomable":true
            },
            "categoryField": "date",
            "categoryAxis": {
                "axisColor": "black",
                "color": "black",
                "gridColor": "black",
                "parseDates": true,
                "dashLength": 1,
                "minPeriod": "DD",
                "minorGridEnabled": true,
            },
            "responsive": {
                "enabled": true
            },
            "dataProvider": list
        }

        return(
            <AmCharts.React
                options={chart_config}
                style={{ width: "100%", height: "500px" }}
            />
        )
    }

    render(){
        return(
            <div className="tile--graph-container" style={{ height: 500 }}>
                {this.formatData()}
            </div>
        )
    }

}

export default LineGraph;

/*
  let chart = AmCharts.makeChart("chartdiv", {
          "type": "serial",
          "theme": "light",
          "dataProvider": list,
          "marginRight": 40,
          "marginTop": 50,
          "marginLeft":40,
          "autoMarginOffset": 20,
          "mouseWheelZoomEnabled":false,
          "dataDateFormat": "YYYY-MM-DD",
          "valueAxes": [{
            "id": "v1",
            "axisAlpha": 0,
            "gridThickness": 2,
            "color": "#fff",
            "gridColor": "#fff",
            "position": "left",
            "dashLength":1,
            "ignoreAxisWidth":true
          }],
          "gridAboveGraphs": true,
          "startDuration": 1,
          "balloon": {
              "borderThickness": 1,
              "shadowAlpha": 0
          },
          "graphs": [ {
            "id": "g1",
            "balloon":{
                "drop":true
            },
            "bullet": "round",
            "bulletBorderAlpha": 1,
            "bulletColor": "#FFFFFF",
            "bulletSize": 7,
            "connect": false,
            "hideBulletsCount": 50,
            "lineThickness": 3,
            "lineColor":"#fff",
            "title": "red line",
            "type": "smoothedLine",
            "useLineColorForBulletBorder": true,
            "valueField": "value",
            "balloonText": "<span style='font-size:16px;'>[[value]]</span>"
          } ],
          "chartCursor": {
            "pan": true,
            "valueLineEnabled": true,
            "valueLineBalloonEnabled": true,
            "cursorAlpha":1,
            "cursorColor":"#258cbb",
            "limitToGraph":"g1",
            "valueLineAlpha":0.5,
            "valueZoomable":true
          },
          "categoryField": "date",
          "categoryAxis": {
            "axisColor": "#fff",
            "color": "#fff",
            "gridColor": "rgba(255,255,255,.5)",
            "parseDates": true,
            "dashLength": 1,
            "minPeriod": "DD",
            "minorGridEnabled": true,
          },
          "chartScrollbar": {
            "autoGridCount": true,
            "graph": "g1",
            "scrollbarHeight": 60,
            "offset": 30,
            "oppositeAxis": false,
            "backgroundAlpha": 0,
            "selectedBackgroundAlpha": 0.3,
            "selectedBackgroundColor": "rgba(0,0,0,.2)",
            "graphFillAlpha": 0,
            "graphLineAlpha": 0.5,
            "selectedGraphFillAlpha": .5,
            "selectedGraphLineAlpha": 1,
            "autoGridCount":true,
            "color":"rgba(255,255,255,.5)"
          },
          "responsive": {
            "enabled": true
          },
          "listeners": [{
            "event": "rendered"
          }]
        }, 2000);

        return(
          <div id="chartdiv" style={{ width: "100%", height: "500px"}}>
            {this.chart}
            <div
              id="curtain"
              style={{
                background: "#4E5964",
                color: "#fff",
                "font-size": "30px",
                height: "100%",
                width: "100%"
              }}
            >
            <span
              style= {{
                display: "block",
                position: "absolute",
                top: "49%",
                width: "100%",
                "text-align": "center"
              }}
            >
              Chart is loading...
            </span>
            </div>
          </div>
        */
        
