import axios from 'axios';


//get single wellness score for single user
export const SINGLE_WELLNESS_SCORE = 'SINGLE_WELLNESS_SCORE';
export function getUserWellnessScore(username){
    let url = `/score/wellness-score/${username}`;
    let wellnessScoreRequest = axios.get(url);

    return {
        type: SINGLE_WELLNESS_SCORE,
        payload: wellnessScoreRequest
    }


}
