//client detail view
import React, {useContext, useEffect, useState} from 'react';
import {withRouter, Link, Route} from 'react-router-dom';
import ClientHeader from "../../clientHeader/clientHeader";
import CoachingTeamMain from '../CoachingTeam/CoachingTeamMain';
import axios from 'axios';
import {connect} from 'react-redux';
import Footer from '../../Footer/Footer';
import {ShepherdTourContext} from "react-shepherd";

const ClientProfileMain = props => {
    //context
    const tour = useContext(ShepherdTourContext);
    // props
    const {user, location, history} = props;
    //state
    const [wellnessScore, setWellnessScore] = useState('');

    useEffect(() => {
        //get client wellness score
        axios.get('/score/wellness-score')
            .then(res => setWellnessScore(res.data.results.score))
            .catch(err=>console.log('ERROR: ', err));

        // start any tours
        if (location.state === 'start-tour' && !tour.isActive()) {
            tour.start();
            if (history.location.state) {
                history.replace({
                    ...history.location,
                    state: ''
                });
            }
        }
    }, []);

    //   function goBack(){
    //     window.history.back();
    //   }

    // function logOutUser(e) {
    // e.preventDefault();
    //   axios.get('/logout')
    //       .then(res=>{
    //           if(res.status === 200) window.location.assign('/');
    //       })
    //       .catch()
    // }

    // sets the bg color based on wellness score
    // eslint-disable-next-line 
    let scoreClass = 'low';
    if (wellnessScore >= 51 && wellnessScore < 75 ) {
        scoreClass = 'average';
    } else if (wellnessScore >= 75 && wellnessScore < 90 ) {
        scoreClass = 'high';
    } else if (wellnessScore >= 90) {
        scoreClass = 'highest';
    }

    const plural = user && user.first_name && user.first_name[user.first_name.length-1] === 's';
    return(
        <div className="client-container">
            <ClientHeader/>
            <Route path={`/member/clientDash/${user.username}/coaching-team`} render={()=><CoachingTeamMain/>}/>

            <div className="container client-account-dash">
                <div className="row">
                    <ul  className="tiles-ul">
                        { user.user_status_id === 3 ?
                            <li className="tiles-li col-xs-12 col-sm-6">
                                <Link to="/member/onboard-assessment">
                                    <div className="tile onboarding-tile">
                                        <div className="tile-titles">
                                            <h4 className='heading'>COMPLETE ONBOARDING</h4>
                                            <p className='subheading'>Return to the onboarding process</p>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            : null
                        }
                        <li className="tiles-li col-xs-12 col-sm-6">
                            <Link to={`/member/clientDash/${user.username}/account`} className='tour-profile-tile'>
                                <div className={"tile"}>
                                    <div className="tile-titles">
                                        <h4>PROFILE</h4>
                                        <p>Update Your Information</p>
                                    </div>
                                    <div className="tile-icon icon-doc">
                                    </div>
                                </div>
                            </Link>
                        </li>

                        <li className="tiles-li col-xs-12 col-sm-6">
                            <Link to={user ? `/member/clientDash/${user.username}/pathways` : ''}>
                                <div className={"tile"}>
                                    <div className="tile-titles">
                                        <h4>{plural ? `${user.first_name}'` : `${user.first_name}'s`} Pathway</h4>
                                        <p>Your Personalized Guide to Results</p>
                                    </div>
                                    <div className="tile-icon icon-doc">
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="tiles-li col-xs-12 col-sm-6">
                            <Link to={`/member/clientDash/${user.username}/coaching-team/coaches`}>
                                <div className={"tile"}>
                                    <div className="tile-titles">
                                        <h4>COACHING TEAM</h4>
                                        <p>Contact Your Coach</p>
                                    </div>
                                    <div className="tile-icon icon-doc">
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="tiles-li col-xs-12 col-sm-6">
                            <Link to={`/member/clientDash/${user.username}/Teammates`}>
                                <div className="tile tour-teamBtn">
                                    <div className="tile-titles">
                                        <h4>TEAMMATES</h4>
                                        <p>Enlist Support</p>
                                    </div>
                                    <div className="tile-icon icon-doc">
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="tiles-li col-xs-12 col-sm-6">
                            <Link to={`/member/clientDash/${user.username}/assessments`}>
                                <div className={"tile"}>
                                    <div className="tile-titles">
                                        <h4>ASSESSMENTS</h4>
                                        <p>Identify Areas for Improvement</p>
                                    </div>
                                    <div className="tile-icon icon-doc">
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="tiles-li col-xs-12 col-sm-6">
                            <Link to={`/member/clientDash/${user.username}/policies`}>
                                <div className={"tile"}>
                                    <div className="tile-titles">
                                        <h4>Policies</h4>
                                        <p>Review Terms of Service</p>
                                    </div>
                                    <div className="tile-icon icon-doc">
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-link"><Footer /></div>
            </div>
        </div>
    );
};

function mapPropsToState({user}){
    return {user}
}

export default withRouter(connect(mapPropsToState)(ClientProfileMain));
