import React, { useState } from "react";
import format from "date-fns/format";
import parse from "date-fns/parse";
import setDate from "date-fns/setDate";
import setMonth from "date-fns/setMonth";
import setYear from "date-fns/setYear";

// styles
import styles from "./scheduleEdit.module.css";

// components
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import { ReactComponent as AudioIcon } from "../../../images/headphones.svg";
import { ReactComponent as PlayIcon } from "../../../images/play.svg";
import { ReactComponent as PdfIcon } from "../../../images/file-pdf-solid.svg";
import { ReactComponent as LinkIcon } from "../../../images/external-link-alt-solid.svg";
import { PrimaryButtonSm } from "../../buttons/buttons";
import { ErrorAlert } from "../../alerts/alerts";
import TimePicker from "../TimePicker/TimePicker";
import { useEffect } from "react";

const ScheduleEdit = (props) => {
  // props
  const {
    content,
    save,
    update,
    remove,
    error,
    classOftheDay,
    startHour,
    setStartHour,
    startPeriod,
    endHour,
    setEndHour,
    endPeriod,
    setEndPeriod,
    setStartPeriod,
    calendarEvents,
  } = props;
  // local
  const [start, setStart] = useState(content.start);
  const [end, setEnd] = useState(content.end);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const eventIdToFind = content.eventId;

  useEffect(() => {
    let eventDate = null;
    if (classOftheDay) {
      eventDate = getStartAndEndDatesByEventId(calendarEvents, eventIdToFind);
    }

    if (eventDate && eventDate.start) {
      setEventStartDate(eventDate.start);
    }
    if (eventDate && eventDate.end) {
      setEventEndDate(eventDate.end);
    }
  }, [calendarEvents, eventIdToFind]);

  function getStartAndEndDatesByEventId(eventArray, eventIdToFind) {
    const userTimezone = Intl.DateTimeFormat(undefined, {
      timeZoneName: "short",
    }).resolvedOptions().timeZone;

    for (const event of eventArray) {
      const extendedProps = event.extendedProps;
      if (extendedProps && extendedProps.eventId === eventIdToFind) {
        const startInLocalTime = new Date(event.start).toLocaleString(
          undefined,
          {
            timeZone: userTimezone,
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }
        );

        const endInLocalTime = new Date(event.end).toLocaleString(undefined, {
          timeZone: userTimezone,
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });

        return { start: startInLocalTime, end: endInLocalTime };
      }
    }
    return null; 
  }

  const chooseFormat = (val) => {
    if (val === "audio")
      return (
        <div className={styles.audio}>
          <AudioIcon />
        </div>
      );
    if (val === "video")
      return (
        <div className={styles.play}>
          <PlayIcon />
        </div>
      );
    if (val === "pdf")
      return (
        <div className={styles.pdf}>
          <PdfIcon />
        </div>
      );
    if (val === "link")
      return (
        <div className={styles.link}>
          <LinkIcon />
        </div>
      );
  };

  function showCategories() {
    if (content.subcategory) {
      return (
        <div>
          <div>{content.category}</div>
          <div className="d:f">
            <span className={styles.childCategory} />
            <span>{content.subcategory}</span>
          </div>
        </div>
      );
    } else {
      return <div>{content.category}</div>;
    }
  }

  function changeStartDate(val) {
    let temp = parse(val, "yyyy-MM-dd", new Date());
    let newStart = setDate(start, temp.getDate());
    newStart = setMonth(newStart, temp.getMonth());
    newStart = setYear(newStart, temp.getFullYear());
    setStart(newStart);
  }

  function changeEndDate(val) {
    let temp = parse(val, "yyyy-MM-dd", new Date());
    let newEnd = setDate(start, temp.getDate());
    newEnd = setMonth(newEnd, temp.getMonth());
    newEnd = setYear(newEnd, temp.getFullYear());
    setEnd(newEnd);
  }

  const handleStartHourChange = (e) => setStartHour(parseInt(e.target.value));
  const handleStartPeriodChange = (e) => setStartPeriod(e.target.value);
  const handleEndHourChange = (e) => setEndHour(parseInt(e.target.value));
  const handleEndPeriodChange = (e) => setEndPeriod(e.target.value);

  return (
    <BoxContainer className="padding-standard">
      {error ? (
        <div className="margin-bottom-15">
          <ErrorAlert>{error}</ErrorAlert>
        </div>
      ) : null}
      <div>
        <h4>
          Scheduled Event:{" "}
          <span className="f-w:400">
            {format(content.start, "LLL do, yyyy")}
          </span>
        </h4>
      </div>
      <div className={styles.main}>
        <div className={styles.display}>
          <div
            className={`${styles.thumbnail}`}
            style={{ backgroundImage: `url(${content.thumbnail})` }}
          >
            {content.length ? (
              <span className={styles.length}>{content.length} min</span>
            ) : null}
            {chooseFormat(content.format)}
            <h5 className={styles.title}>{content.title}</h5>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            save(content.id, start, end);
          }}
          className={styles.form}
        >
          <div className="form-group">
            {classOftheDay ? (
              <label>Start: </label>
            ) : (
              <label>Start (Availability starts at 12am on this date)</label>
            )}
            {eventStartDate !== null && classOftheDay ? (
              <span> {eventStartDate}</span>
            ) : null}

            <div className={styles.inputs}>
              <input
                type="date"
                className="form-control m-r:1"
                onChange={(e) => changeStartDate(e.target.value)}
                value={format(start, "yyyy-MM-dd")}
              />

              {classOftheDay ? (
                <TimePicker
                  selectedHour={startHour}
                  selectedPeriod={startPeriod}
                  onHourChange={handleStartHourChange}
                  onPeriodChange={handleStartPeriodChange}
                />
              ) : null}
            </div>
          </div>

          <div className="form-group">
            {classOftheDay ? (
              <label>End: </label>
            ) : (
              <label>End (Availability ends at 12am on this date)</label>
            )}
            {eventEndDate !== null && classOftheDay ? (
              <span> {eventEndDate}</span>
            ) : null}
            <div className={styles.inputs}>
              <input
                type="date"
                className="form-control m-r:1"
                onChange={(e) => changeEndDate(e.target.value)}
                value={format(end, "yyyy-MM-dd")}
              />
              {classOftheDay ? (
                <TimePicker
                  selectedHour={endHour}
                  selectedPeriod={endPeriod}
                  onHourChange={handleEndHourChange}
                  onPeriodChange={handleEndPeriodChange}
                />
              ) : null}{" "}
            </div>
          </div>

          <div className={styles.actions}>
            <div className={styles.actionBtns}>
              {content.eventId ? (
                <PrimaryButtonSm
                  onClick={(e) => {
                    e.preventDefault();
                    update(content.eventId, content.id, start, end);
                  }}
                  className="m-r:1"
                >
                  Update
                </PrimaryButtonSm>
              ) : (
                <PrimaryButtonSm
                  onClick={(e) => {
                    e.preventDefault();
                    save(content.id, start, end);
                  }}
                  className="m-r:1"
                >
                  Save
                </PrimaryButtonSm>
              )}
              {content.eventId ? (
                <button
                  type="button"
                  onClick={() => remove(content.eventId)}
                  className={styles.delete}
                >
                  Delete
                </button>
              ) : null}
            </div>
          </div>
        </form>
      </div>
      <div className="margin-bottom-15">
        <label>Category</label>
        {showCategories()}
      </div>
    </BoxContainer>
  );
};

export default ScheduleEdit;
