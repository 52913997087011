//client detail subheader component
import React from 'react';
import axios from 'axios';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchSingleUser, clearSingleUserState} from "../../../actions/action_user";
import {getUserWellnessScore} from "../../../actions/action_wellnessScore";
import PhotoContainer from "../clientDetail/container";

class CoachSubHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         user : {},
         coachTitle: '',
         clientImg: '',
         activeMembers:'',
         unread:'',
         active:null,
         show: false,
         show2: false,
         show3: false,
         alertStyle: true,
         alert: '',

        };
      this.handleDismiss = this.handleDismiss.bind(this);
      this.handleShow = this.handleShow.bind(this);
      this.handleDismiss2 = this.handleDismiss2.bind(this);
      this.handleShow2 = this.handleShow2.bind(this);
      this.handleDismiss3 = this.handleDismiss3.bind(this);
      this.handleShow3 = this.handleShow3.bind(this);
    }

    handleDismiss() {
      this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    handleDismiss2() {
      this.setState({ show2: false });
    }

    handleShow2() {
      this.setState({ show2: true });
    }

    handleDismiss3() {
      this.setState({ show3: false });
    }

    handleShow3() {
      this.setState({ show3: true });
    }


    componentDidMount() {
      this.setState({tabStyle1: false})
      this.props.fetchSingleUser(this.props.username)
      axios.get(`/user/coach/single/${this.props.username}`)
        .then(res => {
            this.setState({coachTitle:`Coach ${res.data.results.firstName}`})
            this.setState({clientImg:res.data.results.profile_image});
            this.setState({firstName:res.data.results.firstName, lastName:res.data.results.lastName});
            console.log(res)
          this.setState({user:res.data.results, active: res.data.results.active});
        })
        .catch(err=>console.log(err));

           axios.get(`/coach-metrics/client-count/${this.props.username}`)
            .then((res)=> {
              this.setState({activeMembers: res.data.result})
            })
            .catch((error)=>{
               console.log(error);
            });

           axios.get(`/inbox/${this.props.username}/1?read=false`)
            .then((res)=> {
              this.setState({unread: res.data.result.totalMessages})
            })
            .catch((error)=>{
               console.log(error);
            });
    }

    deleteClientStep1(){
      this.setState({show:true})
    }

    deleteClientStep2(){
      this.setState({show:false})
      this.setState({show2:true})
    }

    deleteClientStep3(){
      this.setState({show:false})
      this.setState({show2:false})
      axios.put(`/user/remove-user/${this.props.username}`)
        .then((res)=>{
          if(res.data.message === 'SUCCESS'){
          this.setState({ show3: true });
          this.setState({ alert: 'success' });
          this.setState({ alertStyle: true });
          this.props.history.push({
              pathname: `/dashboard`,
          })
        }})
        .catch(e=>{
            console.log(e)
            this.setState({ show3: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
    }

    changeStatus=()=>{
        if(this.state.active !== null){
            let that = this;
            this.setState({active : !this.state.active}, ()=>{
                axios.put(`/coach/set-status/${that.props.username}`,{active:that.state.active})
                    .catch(e=>console.log(e));
            });
        }
    };


    render() {
        return(
            <div className="">
              <div className="page-header-container">
                  <div className="container">
                      <div className="row">
                          <div className="col-sm-12">
                            <nav>
                              <ol className="breadcrumb ">
                                <li className="breadcrumb-item underline "><a href="/dashboard" class="text-light">Dashboard</a></li><span className="arrow"></span> 
                                <li className="breadcrumb-item underline "><a href="/dashboard/CoachSearch" class="text-light">Coaches</a></li><span className="arrow"></span>
                                <li className="breadcrumb-item text-light active">{this.state.firstName} {this.state.lastName}</li>  
                              </ol>
                            </nav>
                          </div>
                          <div className="col-sm-12 col-md-8 margin-top">
                              <div className="col-xs-12 col-sm-3 col-md-3">
                                  <div className="profile-image admin-dash--summary">
                                    <PhotoContainer
                                    image={this.state.clientImg}
                                    url_path={`/coach/${this.props.username}`}
                                    />
                                  </div>
                              </div>
                              <div className="col-xs-12 col-sm-9 col-md-9">
                                  <div className="profile-container">
                                      <div className="profile-name">
                                        <h1 className="no-margin">{this.state.coachTitle}</h1>
                                          {this.state.active !== null ? <h3>Status: {this.state.active ? 'Accepting New Members' : 'Paused'}</h3> : null}
                                      </div>
                                      <div className="row">
                                          <div className="col-xs-6">
                                              <h3>{this.state.activeMembers} Members</h3>
                                          </div>
                                          <div className="col-xs-6">
                                              <h3>{this.state.unread} Unread Messages</h3>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-sm-12 col-md-4 text-right margin-top">
                              <Link to={'/coach/'+this.props.username+'/inbox'} className='btn primary'>
                                  + Message Coach
                              </Link>
                              <div>
                                  {this.state.active !== null ? <button className='btn primary margin-top-2x' onClick={this.changeStatus}>Change Availability</button> : null}
                              </div>
                          </div>
                      </div>

                  </div>
                </div>
            </div>
            );
    }
};

function mapStateToProps({user, singleUser, wellnessScore}) {
    return {
        user,
        singleUser,
        wellnessScore
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        fetchSingleUser,
        getUserWellnessScore,
        clearSingleUserState
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoachSubHeader));
