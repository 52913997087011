import React, {useState} from 'react';


// styles
import styles from './display.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {ReactComponent as FilterIcon} from '../../../images/filter-icon.svg';
import {GeneralPagination} from "../../pagination/generalPagination/generalPagination";
import ReactSelectCategories from "../../reactSelectCategories/reactSelectCategories";
import {PrimaryButtonSm} from "../../buttons/buttons";
import {CONTENT_META_TYPES} from "../../../serverVariables/contentMetaTypes";
import Loading from '../../Loading/Loading';

const Display = props => {
    // props
    const {content, pages, changePage, categories, setCategory, category, title, setTitle, submit} = props;
    // local
    const [open, setOpen] = useState(false);
    return (
        <BoxContainer className={styles.container}>
            <div className={styles.header}>
                <h4 className='m:0'>Content</h4>
                <FilterIcon className={styles.filterIcon} onClick={() => setOpen(!open)}/>
            </div>
            <form onSubmit={submit} className={`${styles.form} ${open ? styles.open : ''}`}>
                <div className="form-group">
                    <input
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        type="text"
                        className={`form-control ${styles.search}`}
                        placeholder='Search Titles'/>
                </div>
                <div className="form-group">
                    <ReactSelectCategories
                        placeholder='All Categories'
                        className='capitalize'
                        name='categories'
                        label='Category'
                        list={categories}
                        value={category}
                        onChange={val => {
                            if(!val.value){
                                setCategory(null);
                            } else {
                                setCategory(val)
                            }
                        }}
                    />
                </div>
                <PrimaryButtonSm onClick={submit}>Submit</PrimaryButtonSm>
            </form>
            <div className={styles.contentContainer}>
                {content.map(item => {
                    let category, subcategory;
                    if (item.categories.length) {
                        category = item.categories[0].name;
                        if (item.categories[0].parent_category && item.categories[0].parent_category.name) {
                            subcategory = category;
                            category = item.categories[0].parent_category.name;
                        }
                    }
                    return (<ItemRow content={item} key={item.id}category={category} subcategory={subcategory}/>)
                })}
            </div>
            <div className="margin-top-15">
                <GeneralPagination pages={pages} onPageChange={changePage} pageRangeDisplayed={2}/>
            </div>
        </BoxContainer>
    )
}

export default Display;

function ItemRow({content, category, subcategory}) {

    function showCategories() {
        if (subcategory) {
            return (
                <div key={content.id}>
                    <div>{category}</div>
                    <div className='d:f'>
                        <span className={styles.childCategory}/><span>{subcategory}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div>{category}</div>
            )
        }
    }
    let instructor, classType;
    classType = content.meta ? content.meta.find(m => m.meta_type.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES) : null;
    instructor = content.meta ? content.meta.find(m => m.meta_type.content_meta_type_id === CONTENT_META_TYPES.TRAINER) : null;
    return (
        <BoxContainer
            data-id={content.id}
            data-title={content.title}
            data-thumbnail={content.thumbnail}
            data-category={category}
            data-subcategory={subcategory}
            data-length={content.format_length}
            data-forma={content.format}
            className='event'>
            <div>
                <h4>{content.title}</h4>
                <div className={styles.meta}>
                    {instructor ?
                        <div className='m-r:2'>
                            <label className='m:0'>Instructor</label>
                            <div>{instructor.selected_option.content_meta_option_label}</div>
                        </div>
                        : null
                    }
                    {classType ?
                        <div>
                            <label className='m:0'>Class Type</label>
                            <div>{classType.selected_option.content_meta_option_label}</div>
                        </div>
                        : null
                    }
                </div>
                <div className={styles.taxonomy}>
                    <div className={styles.format}>
                        <label className='m:0'>Format</label>
                        <div>{content.format}</div>
                    </div>
                    <div className={styles.category}>
                        <label className='m:0'>Category</label>
                        {showCategories()}
                    </div>
                </div>
            </div>
        </BoxContainer>
    )
}