import mainApi from "./mainApi";

export function postsApi() {
    return {
        // get unread number of inbox messages for user
        getUnread: function(username) {
            return mainApi.get(`/posts/user/${username}/unread`);
        },
        // get all messages for a user for a given page
        getMessages: function(page, options) {
            let url = `/posts/threads/page/${page}?`;
            if (options.needResponse) url += 'needsResponse=true&';
            if (options.username) url += `username=${options.username}&`;
            if (options.override) url += `override=${options.override}&`
            if (options.organizationId) url += `organization_id=${options.organizationId}&`;
            return mainApi.get(url);
        },
        // get archived messages
        getArchived: function(page, options) {
            let url = `/posts/threads/page/${page}?archived=true&`;
            if (options.needResponse) url += 'needsResponse=true&';
            if (options.username) url += `username=${options.username}&`;
            if (options.override) url += `override=${options.override}&`
            if (options.organizationId) url += `organization_id=${options.organizationId}&`;
            return mainApi.get(url);
        },
        // get a single thread
        getThread: function(threadId) {
            return mainApi.get(`/posts/thread/id/${threadId}`);
        },
        // set a thread to read
        setThreadRead: function(threadId, username) {
            return mainApi.put(`/posts/read/thread/${threadId}/user/${username}`)
        },
        // set a thread to archived/unarchived
        setArchiveStatus: function(threadId, status, username) {
            return mainApi.put(`/posts/thread/${threadId}/user/${username}/status/${status}`)
        },
        // get a list of coaches and their # of unread messages
        getAdminCoachList: function() {
            return mainApi.get(`/posts/message-center/coaches`);
        }
    }
}