import React, {useState, useEffect} from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {Modal} from "react-bootstrap";
import Link from "react-router-dom/Link";

// styles
import styles from './equipment.module.css';

// actions
import {fetchEquipment, newEquipment, removeContentMeta, updateContentMeta} from "../../../store/actions/contentActions";


// components
import {PrimaryButton, PrimaryButtonSm} from "../../buttons/buttons";
import {ReactComponent as TimesIcon} from '../../../images/times-solid.svg';
import {ReactComponent as EditIcon} from '../../../images/icons/edit.svg';
import {BoxContainer} from "../../containers/boxContainer/boxContainer";

const Equipment = props => {
    // local
    const [modal, setShowModal] = useState(false);
    const [edit, setEdit] = useState({id: '', name: '', state: 'New'});
    const [equipment, setEquipment] = useState([]);
    const [confirm, setConfirm] = useState(null);

    useEffect(() => {
        loadEquipment();
    }, []);

    async function loadEquipment() {
        try {
            const res = await fetchEquipment();
            setEquipment(res);
        } catch (e) {
            console.log(e);
        }
    }

    function showModal(id, val) {
        if (val) setEdit({id: id, name: val, state: 'Update'});
        setShowModal(true);
        setConfirm(null);
    }

    function closeModal() {
        setEdit({id: '', name: '', state: 'New'});
        setShowModal(false);
    }

    async function remove(id) {
        if (id !== confirm) {
            setConfirm(id);
            return;
        }
        try {
            await removeContentMeta(id);
            await loadEquipment();
        } catch(e) {
            console.log(e);
        }
        setConfirm(null);
        loadEquipment();
    }

    async function add() {
        try {
            await newEquipment(edit.name);
            loadEquipment();
            closeModal();
        } catch (e) {
            console.log(e);
        }
    }

    async function update() {
        try {
            await updateContentMeta(edit.id, edit.name);
            loadEquipment();
            closeModal();
        } catch (e) {
            console.log(e);
        }
    }

    function submitUpdate(e) {
        e.preventDefault();
        if (edit.state === 'New') {
            add();
        } else {
            update();
        }
    }
    return (
        <div>
            <div className="page-header-container searchForm-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb ">
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item underline ">
                                        <Link to="/dashboard" className="text-light">
                                            Dashboard
                                        </Link>
                                    </li>
                                    <span className="arrow"/>
                                    <li className="breadcrumb-item underline ">
                                        <Link to="/dashboard/Content" className="text-light">
                                            Content
                                        </Link>
                                    </li>
                                    <span className="arrow"/>
                                    <li
                                        className="breadcrumb-item text-light active"
                                        aria-current="page"
                                    >
                                        Equipment
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <h1>Equipment</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`container margin-top-25 ${styles.header}`}>
                <div/>
                <PrimaryButton
                    className={styles.new}
                    onClick={()=>showModal()}>+ New</PrimaryButton>
            </div>

            <div className={'container'}>
                <div className={`${styles.row} ${styles.head}`}>
                    <div className={styles.name}>
                        Name
                    </div>
                    <div className={styles.classes}>
                        Classes
                    </div>
                    <div className={styles.updated}>
                        Updated
                    </div>
                    <div className={styles.buttons}/>
                </div>

                <div>
                    {equipment.map(eq => {
                        return (
                            <div key={eq.content_meta_option_id}>
                                <BoxContainer className={styles.row}>
                                    <div className={styles.name}>{eq.label}</div>
                                    <div className={styles.classes}>{eq.class_count} classes</div>
                                    <div className={styles.updated}>{format(parseISO(eq.updated_at), 'MM/dd/yyyy')}</div>
                                    <div className={styles.buttons}>
                                        <EditIcon onClick={() => showModal(eq.content_meta_option_id, eq.label)}/>
                                        { confirm === eq.content_meta_option_id ?
                                            <div className={styles.confirmContainer}>
                                                <button onClick={() => remove(eq.content_meta_option_id)} className={styles.confirm}>Confirm</button>
                                                <button onClick={() => setConfirm(null)} className={styles.cancel}>Cancel</button>
                                            </div>
                                            :
                                            <TimesIcon onClick={() => remove(eq.content_meta_option_id)}/>
                                        }
                                    </div>
                                </BoxContainer>
                            </div>
                        )
                    })}
                </div>
            </div>

            <Modal show={modal} onHide={closeModal}>
                <BoxContainer className='padding-standard'>
                    <h4>{edit.state} Equipment Item</h4>
                    <form onSubmit={submitUpdate}>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                onChange={e=>setEdit({...edit, name: e.target.value})}
                                value={edit.name}
                                type="text"
                                className="form-control"/>
                        </div>
                        <PrimaryButtonSm onClick={submitUpdate}>Submit</PrimaryButtonSm>
                    </form>
                </BoxContainer>
            </Modal>
        </div>
    )
}
export default Equipment;