import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

class NotesHeader extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    render(){
      return(
            <div>
              <div className="">                                      
                  <div className="profile-name">                                      
                     {this.props.author}                               
                  </div>
                  <p>{this.props.time}</p>                  
              </div>
            </div>  

      )
    }
}

export default withRouter(NotesHeader);