import React from 'react';
import { Link } from 'react-router-dom';

import style from './styles.module.css';

export const BasicLink = ({ className = '', children, ...props }) => (
    <Link tabIndex={0} className={`${style.link} ${className}`} {...props}>
        {children}
    </Link>
);

export const PrimaryLink = ({ className = '', children, ...props }) => (
    <Link tabIndex={0} className={`${style.link} ${style.primary} ${className}`} {...props}>
        {children}
    </Link>
);

export const ContentLink = ({
	text,
	image,
	tileImage,
	overlay,
	color,
	background,
	lockImage,
	className = '',
	children,
	...props
}) => {
	const styleObj = {};
	if (background) styleObj.background = background;
	if (tileImage) styleObj.backgroundImage = `url(${tileImage})`;
	return (
		<>
			<Link
				style={styleObj}
				tabIndex={0}
				className={`pathway-tile ${style.contentLink} ${props.disabled ? style.disabled : null} ${className}`}
				{...props}>
				{ overlay ? <div className={style.overlay}/> : null }
				<div className="col-xs-9">
					<div style={color ? { color } : null} className={`title ${style.title}`}>{text}</div>
					{ children }
				</div>
				<div className="col-xs-3 text-right pathway-icon">
					{
						image && image.src ?
							<img className={style.img} src={image.src} alt={image.alt}/>
							:
							null
					}
					{
						image && image.type ? image : null
					}
					{
						lockImage ?
							<img className={style.img} src={lockImage} alt='disabled'/>
							:
							null
					}
				</div>
			</Link>
			{ props.disabled ? <div className={style.disableLink}/> : null }
		</>
	)
};