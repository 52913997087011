import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// styles
import styles from "./clientAssessmentRoute.module.css";

// actions
import {
  fetchAssessment,
  updateHealthGoal,
  updateAssessmentAnswer,
  assessmentAttempt,
} from "../../store/actions/assessmentActions";
import { updateProfile, updateStatus } from "../../store/actions/userActions";
import {
  makePathway,
  postAssessmentProfile,
} from "../../store/actions/pathwaysActions";
import { getSingleOrganization } from "../../actions/action_organizations";


// components
import ClientHeader from "../clientHeader/clientHeader";
import Intro from "./intro/intro";
import HealthGoal from "./healthGoal/healthGoal";
import Questions from "./questions/questions";
import Section_1 from "./section_1/section_1";
import Section_2 from "./section_2/section_2";
import Section_3 from "./section_3/section_3";
import Section_4 from "./section_4/section_4";
import Section_5 from "./section_5/section_5";
import ProfileQuestions from "./profileQuestions/profileQuestions";
import Congratulations from "./congratulations/congratulation";
import Loading from "../Loading/Loading";
import ProgressBar from "./progressBar/progressBar";
import { STATUSES } from "../../serverVariables/statuses";
import AssessmentOption from "./assessmentOption/AssessmentOption";

const ClientAssessmentRoute = (props) => {
  // props
  const urlParams = new URLSearchParams(props.history.location.search);

  const pageParam = urlParams.get("pageNum");
  // global props and actions
  const { user, updateProfile, updateStatus} = props;

  useEffect(() => {
    // Access the "page" parameter from the URL using props.history
    if (pageParam) {
      setPage(3);
      setCreateLifestyle(true);
    }
  }, [props.history]);
  // local
  const [ready, setReady] = useState(false);
  const [page, setPage] = useState(1);
  const [assessment, setAssessment] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [createlifestyle, setCreateLifestyle] = useState(false);

  // grab assessment on first render
  useEffect(() => {
    async function mount() {
      try {
        const response = await fetchAssessment();
        const updatedAssessment = {
          name: response.name,
          pathway_id: response.pathway_id,
        };
        const copy = { ...response };
        delete copy.name;
        delete copy.pathway_id;
        const keys = Object.keys(copy);
        keys.forEach((key, i) => {
          updatedAssessment[key] = copy[key].map((question) => {
            const answered = question.answers.find((answer) => answer.selected);
            if (answered) question.answer = answered;
            return question;
          });
        });
        setAssessment(updatedAssessment);
        if (user.user_status_id > STATUSES.onboarding_terms_of_service) {
          assessmentAttempt(response.pathway_id);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setReady(true);
      }
    }

    mount();
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
    return () => (document.body.style.backgroundColor = null);
  }, []);

  function goToPage(val) {
    setPage(val);
    window.scrollTo(0, 0);
  }

  function goBack() {
    if (pageParam && page == 3) {
      props.history.push(`/member/clientDash/${user.username}/assessments/`);
    }
    if (createlifestyle === false && page === 13) {
      goToPage(2);
    }
    if (page === 15) {
      goToPage(2);
      setPage(2);
    } else if (page === 1) {
      return;
    } else if (createlifestyle === false && page === 13) {
      goToPage(15);
      setPage(15);
    } else {
      goToPage(page - 1);
    }
  }

  function next() {
    goToPage(page + 1);
  }


  function setHealthGoal() {
    try {
      // if skipped assessment option is enabled direct user to first text page
      if(user.organization.optional_assessment === false){
        setCreateLifestyle(true)
        goToPage(3);
      } else {
        goToPage(15);
      }

    } catch (e) {
      console.log(e);
    }
  }

  async function setProfile(birthday, weight, height, gender) {
    const query = `dob=${birthday}&weight=${weight}&height=${height}&gender=${gender}`;
    try {
      await updateProfile(user.username, query);

      // update user status
      if (user.user_status_id < STATUSES.active_coached) {
        await updateStatus(
          user.username,
          STATUSES.active_not_coached
        ).catch((e) => console.log(e));
      }

      // if user selects to take assessment when sign up
      await updateHealthGoal(selectedGoal);
      if (createlifestyle) {
        await postAssessmentProfile(assessment.pathway_id);
        await makePathway(assessment.pathway_id);
      } else {
        // if user chooses to take assessment later
        await makePathway(assessment.pathway_id);
      }
    } catch (e) {
      console.log(e);
    } finally {
      next();
    }
  }

  function progress(val, createLifestyle, pageParam) {
    if (val === 1 || val === 14 || val === 15 || val === 2) return;
    if (!createLifestyle) {
      return (
        <div>
          <ProgressBar
            stage={13}
            createLifestyle={createLifestyle}
            submit={pageParam ? true : false}
          />
          <div className={styles.stage}>{stageName()}</div>
        </div>
      );
    }

    return (
      <div>
        <ProgressBar
          stage={val}
          createLifestyle={createLifestyle}
          submit={pageParam ? true : false}
        />
        <div className={styles.stage}>{stageName()}</div>
      </div>
    );



    function stageName() {
      switch (val) {
        case 2:
          return "HEALTH GOAL";
        case 3:
        case 4:
          return "FOOD";
        case 5:
        case 6:
          return "EATING PATTERNS";
        case 7:
        case 8:
          return "ACTIVITIES";
        case 9:
        case 10:
          return "RECOVERY";
        case 11:
        case 12:
          return "GOALS & SUPPORT";
        case 13:
          return "PROFILE";
        // no default
      }
    }
  }

  function answerQuestion(question_id, answer) {
    const updatedAssessment = {
      name: assessment.name,
      pathway_id: assessment.pathway_id,
    };
    const copy = { ...assessment };
    delete copy.name;
    delete copy.pathway_id;
    const keys = Object.keys(copy);
    for (let key of keys) {
      updatedAssessment[key] = copy[key].map((item) => {
        if (item.question_id === question_id) {
          return {
            ...item,
            answer,
          };
        } else {
          return item;
        }
      });
    }
    setAssessment(updatedAssessment);
    try {
      updateAssessmentAnswer(
        assessment.pathway_id,
        question_id,
        answer.answer_id
      );
    } catch (e) {
      console.log(e);
    }
  }

  function footer(val) {
    if (val === 1 || val === 14) return null;
    else
      return (
        <div className="footer-actions">
          <div className="container">
            <div className="row">
              <div className="col-xs-6">
                <button className="btn client-white" onClick={goBack}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      );
  }

  function choosePage(val) {
    switch (val) {
      case 1:
        return <Intro user={user} next={next} />;
      case 2:
        return (
          <HealthGoal
            next={setHealthGoal}
            selectedGoal={selectedGoal}
            setSelectedGoal={setSelectedGoal}
            goToPage={goToPage}
          />
        );
      case 3:
        return <Section_1 next={next} />;
      case 4:
        return (
          <Questions
            questions={assessment.section_1}
            answerQuestion={answerQuestion}
            next={next}
            assessment={assessment}
          />
        );
      case 5:
        return <Section_2 next={next} />;
      case 6:
        return (
          <Questions
            questions={assessment.section_2}
            answerQuestion={answerQuestion}
            next={next}
          />
        );
      case 7:
        return <Section_3 next={next} />;
      case 8:
        return (
          <Questions
            questions={assessment.section_3}
            answerQuestion={answerQuestion}
            next={next}
          />
        );
      case 9:
        return <Section_4 next={next} />;
      case 10:
        return (
          <Questions
            questions={assessment.section_4}
            answerQuestion={answerQuestion}
            next={next}
          />
        );
      case 11:
        return <Section_5 next={next} />;
      case 12:
        return (
          <Questions
            questions={assessment.section_5}
            answerQuestion={answerQuestion}
            next={next}
            user={user}
            assessment={assessment}
            submit={pageParam ? true : false}
          />
        );
      case 13:
        return (
          <ProfileQuestions
            finish={setProfile}
            createlifestyle={createlifestyle}
            setCreateLifestyle={setCreateLifestyle}
          />
        );
      case 14:
        return (
          <Congratulations user={user} pathwayId={assessment.pathway_id} />
        );
      case 15:
        return (
          <AssessmentOption
            next={next}
            selectedGoal={selectedGoal}
            goToPage={goToPage}
            createlifestyle={createlifestyle}
            setCreateLifestyle={setCreateLifestyle}
          />
        );

      default:
        return <Intro user={user} next={next} />;
    }
  }

  return (
    <div className="client-container">
      <ClientHeader />
      {!ready ? (
        <div className="t-a:c margin-top-25">
          <Loading />
        </div>
      ) : (
        <div className={`${styles.container}`}>
          <div className="margin-top-25 margin-bottom-25">
            {progress(page, createlifestyle, pageParam)}
          </div>
          {choosePage(page, createlifestyle, pageParam)}
        </div>
      )}
      {footer(page)}
    </div>
  );
};

function mapStateToProps({ user ,  organization,}) {
  return { user,  organization,};
}

const actions = {
  updateProfile,
  updateStatus,
  getSingleOrganization,
};
export default connect(mapStateToProps, actions)(ClientAssessmentRoute);


