import React from 'react';
import {Alert} from "react-bootstrap";
import Loader from "react-loader-spinner";

// styles
import styles from './alerts.module.css';

// components
import alternativeCheckmark from "../../images/checkmark.png";


export const ErrorAlert = ({children, handleDismiss, ...props}) => {
    return (
        <Alert style={{maxWidth: '100%', width: 'auto', display: 'inline-block'}} {...props} bsStyle="danger"
               onDismiss={handleDismiss}>
            {children}
        </Alert>
    )
}

export const InfoAlert = ({children, handleDismiss, ...props}) => {
    return (
        <Alert style={{maxWidth: '100%', width: 'auto', display: 'inline-block'}} {...props} bsStyle="info"
               onDismiss={handleDismiss}>
            {children}
        </Alert>
    )
}

export const SecondaryInfoAlert = ({children, ...props}) => {
    return (
        <div {...props} className={`bg-info ${styles.secondaryInfo} ${props.className}`}>
            {children}
        </div>
    )
}

export const SaveAlert = ({complete, savingMessage='', savedMessage='', ...props}) => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (!complete) {
        return (
            <div className={styles.savingContainer}>
                <span className={styles.saving} {...props}>
                    <Loader
                        type={'Puff'}
                        color='#ccc'
                        height={25}
                        width={25}
                    />
                    <span className={styles.text}>{savingMessage || 'Saving'}</span>
                </span>
            </div>
        )
    }

    return (
        <div className={styles.savingContainer}>
            <span className={styles.saving} {...props}>
            {isIE11 ?
                <img className={styles.alternativeCheckmark} src={alternativeCheckmark} alt="checkmark"/>
                :
                <svg className={`checkmark ${styles.checkmark}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
            }
                <span className={`${styles.text} ${styles.saveText}`}>{savedMessage || 'Saved'}</span>
        </span>
        </div>
    )
}

export const WarningAlert = ({complete = true, text='', completedText, ...props}) => {
    if (!complete) {
        return (
            <div className={styles.warningContainer}>
                <span className={styles.warning} {...props}>
                    <Loader
                        type={'Puff'}
                        color='#ccc'
                        height={25}
                        width={25}
                    />
                    { text ? <span className={styles.text}>{text}</span> : null }
                </span>
            </div>
        )
    }

    return (
        <div className={styles.savingContainer}>
            <span className={styles.warning} {...props}>
                <svg className={styles.icon} viewBox="0 0 20 20">
                    <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"/>
                </svg>
                { completedText ? <span className={`${styles.text} ${styles.warningText}`}>{completedText}</span> : null }
            </span>
        </div>
    )
}

export const SecondaryWarningAlert = ({children, ...props}) => {
    return (
        <div className={`bg-warning ${styles.secondaryWarning}`} {...props}>
            {children}
        </div>
    )
}

export const DangerAlert = ({children, ...props}) => {
    return (
        <div className={`bg-danger ${styles.danger}`} {...props}>
            {children}
        </div>
    )
}