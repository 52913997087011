import React from 'react';

const WaitlistConfirmed = ({ firstName }) => {
    return (
        <div>
            <h3>You've been added to our wait list</h3>
            <p>Hi {firstName} -</p>
            <p>
                Due to overwhelming demand, we seem to have reached our monthly limit for new sign ups. Bummer.
            </p>
            <p>
                Good news though! We've added you to our waitlist so you can get access to TREO before we invite any new members.
            </p>
            <p>We will email you when a spot becomes available.</p>
        </div>
    )
}

export default WaitlistConfirmed;