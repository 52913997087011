import React, {useRef} from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment';

// styles
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import '@fortawesome/fontawesome-free/css/all.css'; // needs additional webpack config!
import bootstrapPlugin from '@fullcalendar/bootstrap';

const ScheduleCalendar = props => {
    // props
    const {calendarEvents, openModal, onChange, save, showDailyView} = props;
    // local
    const calendarComponentRef = useRef();

    const initialView = showDailyView ? "dayGridWeek" : "dayGridMonth"
    

    function handleDateClick({event}) {
        const timezone = moment(event._instance.range.start).utcOffset()/60;
        const start = moment(event._instance.range.start).subtract(timezone, "hour");
        openModal({
            event,
            eventId: event._def.extendedProps.eventId,
            id: event._def.extendedProps.contentId,
            title: event._def.title,
            category: event._def.extendedProps.category,
            subcategory: event._def.extendedProps.subcategory,
            thumbnail: event._def.extendedProps.thumbnail,
            length: event._def.extendedProps.length,
            format: event._def.extendedProps.format,
            start: start.toDate(),
            end: moment(event._instance.range.end).subtract(timezone, "hour").toDate(),
        });
    }

    function handleDrop({event}) {
        const timezone = moment(event._instance.range.start).utcOffset()/60;
        const start = moment(event._instance.range.start).subtract(timezone, "hour");
        if (start.isBefore(moment().subtract(1, 'day'))) return event.remove();
        save(
            event._def.extendedProps.eventId,
            event._def.extendedProps.contentId,
            moment(event._instance.range.start).subtract(timezone, "hour").toDate(),
            moment(event._instance.range.end).subtract(timezone, "hour").toDate()
        );
    }

    function handleDrag({event}) {
        const timezone = moment(event._instance.range.start).utcOffset()/60;
        const start = moment(event._instance.range.start).subtract(timezone, "hour");
        if (start.isBefore(moment().subtract(1, 'day'))) return event.remove();
        openModal({
            event,
            id: event._def.extendedProps.contentId,
            title: event._def.title,
            category: event._def.extendedProps.category,
            subcategory: event._def.extendedProps.subcategory,
            thumbnail: event._def.extendedProps.thumbnail,
            length: event._def.extendedProps.length,
            format: event._def.extendedProps.format,
            start: start.toDate(),
            end: moment(event._instance.range.end).subtract(timezone, "hour").toDate(),
        });
    }
    return (
        <FullCalendar
            editable={true}
            droppable
            initialView={initialView}
            headerToolbar={{
                right: "prev,next today",
                center: "",
                left: "title"
            }}
            header={{
                right: "prev,next today",
                center: "",
                left: "title"
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, momentTimezonePlugin]}
            ref={calendarComponentRef}
            events={calendarEvents}
            eventDrop={handleDrop}
            eventReceive={handleDrag}
            eventClick={handleDateClick}
            eventResize={handleDrop}
            defaultAllDay={false}
            validRange={{start: moment().add(1, 'day').format('yyyy-MM-dd')}}
            datesSet={onChange}
            timeZone={'America/Chicago'}
        />
    )
}

export default ScheduleCalendar;