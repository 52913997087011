// npm modules
import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

// actions
import { setClientPathwayList } from "../../store/actions/pathwaysActions";

const ClientAssessmentListContainer = (props) => {
  //state
  const { clientPathwayList = [] } = props;


  // actions
  const { setClientPathwayList } = props;
  // on mount scheduling
  useEffect(() => {
    setClientPathwayList();
  }, []);
  return (
    <div className="row">
      <ul className="tiles-ul">
        {clientPathwayList.map((pathway) => (
          <li className="tiles-li col-xs-12 col-sm-6">
            <Link
              to={`/member/clientDash/${props.match.params.username}/pathways/${pathway.id}`}
            >
              <div className={"tile"}>
                <div className="tile-titles">
                  <h4>
                    {moment(pathway.created_at).format("MMMM Do, YYYY")} Pathway
                  </h4>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = ({ clientPathwayList }) => {
  return {
    clientPathwayList,
  };
};

const actions = {
  setClientPathwayList,
};

ClientAssessmentListContainer.propTypes = {
  clientPathwayList: PropTypes.array,
  loadFormList: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, actions)(ClientAssessmentListContainer)
);
