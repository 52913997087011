import React from 'react';
import { Alert} from 'react-bootstrap';
import axios from 'axios';

class Unsubscribe extends React.Component{
    constructor(props){
        super(props);
        this.state ={
          show: false,
          alertStyle: true,
          alert: '',
        }
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    unsubscribe(e){
      e.preventDefault()
      axios.post(`/teammates/unsubscribe`, {
        token:this.props.token
      })
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                console.log(res)
                this.setState({show:true, alertStyle:true, alert:"You have been unsubscribed."})
            }})
        .catch((error)=>{
          console.log(error)
          this.setState({show:true, alertStyle:false, alert:error.response.data.err})
        });
    }

    handleDismiss() {
      this.setState({ show: false });
    }

    render(){
        let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
        return (
            <div className="container text-center">

                <div className="row">
                  <div className="teammate-logo-container margin-top">
                    <img src={'https://s3.us-east-2.amazonaws.com/treo/Treo_Wellness_Coaching.png'} alt='Treo Logo' />
                  </div>
                </div>

                <div className="row">
                  <h2>Sorry to see you go</h2>
                  <h4>Clicking below will unsubscribe you as a teammate</h4>
                </div>
                <form onSubmit={(e)=>this.unsubscribe(e)}>
                  <div className="row margin-top-lrg">
                    <input className="btn teammate-sign-up-btn" type="submit" value="unsubscribe"/>
                  </div>
                </form>
                {this.state.show
                    ?  <Alert className="save-profile-alert confirm-teammate-alert" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                          <p>{this.state.alert}</p>
                        </Alert>
                    : null
                }
            </div>
        )
    }
}

export default Unsubscribe;