// npm modules
import React  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';

// actions
import { loadVersionToState } from "../../actions/action_policy";

// components
import WYSIWYG from '../WYSIWYG/WYSIWYG';
import PolicyVersionList from './policyVersionList';

const PolicyVersion = ({ mostCurrentVersion, editable, singlePolicy, errors={}, policyVersion, loadVersionToState }) => {
    return (
        <form onSubmit={e => e.preventDefault()} className='row'>
            <div className="col-xs-12 col-sm-8">
                <div className="form-group">
                    <label htmlFor='policy-name'>Policy Name { !editable ? <i className="fas fa-lock"/> : null}</label>
                    <input
                        disabled
                        className='form-control'
                        type="text"
                        placeholder='Policy Name'
                        id='policy-name'
                        value={singlePolicy.title || ''}
                        readOnly/>
                </div>
                <div className="form-group">
                    { errors.version ? errors.version.map(err => <p className='text-danger' key={err.trim()}>{err}</p>) : null }
                    <label htmlFor='version-number'>Version Number { !editable ? <i className="fas fa-lock"/> : null}</label>
                    <input
                        readOnly={!editable}
                        className='form-control'
                        type="text"
                        placeholder='v1.0'
                        id='version-number'
                        value={policyVersion.version_number || ''}
                        onChange={e => loadVersionToState({ ...policyVersion, version_number: e.target.value })}/>
                </div>
                <div className="form-group">
                    { errors.text ? errors.text.map(err => <p className='text-danger' key={err.trim()}>{err}</p>) : null }
                    <label htmlFor='version-text'>
                        Text { !editable ? <i className="fas fa-lock"/> : null}
                        { editable && mostCurrentVersion ?
                            <span
                                className='p-x:1 text-muted t-d:u hover'
                                onClick={()=>loadVersionToState({...policyVersion, text: mostCurrentVersion.text })}
                            >Import Current Version</span>
                            : null
                        }
                    </label>
                    <WYSIWYG
                        disabled={!editable}
                        value={policyVersion.text}
                        plugins ='link paste lists'
                        toolbar ='undo redo | bold italic | numlist bullist | alignleft aligncenter alignright'
                        onChangeHandler={(val) => loadVersionToState({ ...policyVersion, text: sanitize(val) })}
                    />
                </div>
            </div>
            <div className="col-xs-12 col-sm-4">
                <PolicyVersionList/>
            </div>
        </form>
    )
};

const mapStateToProps = ({ policyVersion, singlePolicy }) => {
    return {
        policyVersion,
        singlePolicy
    }
};

const actions = {
    loadVersionToState,
};

export default connect(mapStateToProps, actions)(PolicyVersion);

PolicyVersion.propTypes = {
    editable: PropTypes.bool,
    policyVersion: PropTypes.shape({
        text: PropTypes.string,
    }),
    singlePolicy: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number,
    }),
    errors: PropTypes.shape({
       versionNumber: PropTypes.string,
       text: PropTypes.string,
    }),
    loadVersionToState: PropTypes.func,
};

