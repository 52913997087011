import React from 'react';
import axios from 'axios';
import {withRouter, Link, Redirect } from 'react-router-dom';
import { Button, Navbar, Nav } from 'react-bootstrap';
import './header.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PhotoContainer from '../../profilePhotos/container';
import _ from 'lodash';
import TreoLogo from '../../header-logo.png';

// actions
import {fetchSelf} from "../../../actions/action_user";
import { setCompliance } from "../../../actions/action_policy";


let currentUser = "Client";

class HeaderClient extends React.Component {
  constructor(props) {
    super(props);
    if(currentUser) {
      this.state={
        loggedIn: true,
        username: '',
        redirect: false,
        status:'',
        unread:null,
        multipleCoaches:false,
        coachUsername:null,
        noCoach:true,
        logo: null,
      };
    }
    else {
      this.state={
        loggedIn: false,
      };
    }
      this.logOutUser=this.logOutUser.bind(this)
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  componentDidMount() {
      window.scrollTo(0, 0);
      let that = this;
      //check if a person is logged in
      axios.put('/login/check')
          .then(res=>{
              const onboarding = (window.location.href.indexOf('Onboard') > 0 || window.location.href.indexOf('onboard') > 0);
              const profile =
                  window.location.href.indexOf('profile') > 0 ||
                  window.location.href.indexOf('account') > 0 ||
                  window.location.href.indexOf('coach') > 0 ||
                  window.location.href.indexOf('inbox') > 0 ||
                  window.location.href.indexOf('policies') > 0 ||
                  window.location.href.indexOf('privacy') > 0;
              if (!res.data && !onboarding) window.location = '/';
              if (!onboarding && !profile && res.data.user_status_id === 3) window.location = '/onboard-assessment';
              that.setState({username: that.props.match.params.username});
              that.props.fetchSelf()
                  .then(response =>{
                      if (that.props.user.organization.brand_switch) {
                            this.setState({ logo: that.props.user.organization.logo || TreoLogo });
                      } else {
                          that.setState({ logo: TreoLogo })
                      }
                    // run compliance check
                    axios.get(`/policy/fullcompliance/user/${that.props.user.username}`)
                        .then(complianceRes => {
                          this.props.setCompliance(complianceRes.data.result);
                      }).catch(e => console.log(e));

                    axios.get(`/posts/user/${that.props.user.username}/unread`)
                        .then((res)=>{
                          if(res.data.result > 0) that.setState({unread:res.data.result});
                        })
                        .catch(e => console.log(e));
                  })
          });
      this.getCoach();
  }

  getCoach=()=>{
    axios.get(`/user/coaching-team/`)
        .then(res => {
          if(res.data.result.length > 1){
            this.setState({multipleCoaches:true, noCoach:false})
          }
          else if(res.data.result.length === 0) {
            this.setState({multipleCoaches:true, noCoach:true})
          }
          else {
            this.setState({coachUsername:res.data.result[0].username, noCoach:false})
          }
        })
  }

  logOutUser(e) {
    e.preventDefault();
      axios.get('/logout')
          .then(res=>{
              if(res.status === 200) window.location.assign('/');
          })
          .catch()
    }

    renderDashLink=()=>{
        if(!_.isEmpty(this.props.user)){
          if(this.props.user.user_status_id !== 3){
            return(
                <Link className="client-header-links" to={this.props.user ? `/member/clientDash/${this.props.user.username}` : ''}>
                    <div className="hidden-xs hidden-sm">DASHBOARD</div>
                </Link>
            )
          }else{
            return(
                <Link className="client-header-links" to={'/member/onboard-assessment/'}>
                    <div className="hidden-xs hidden-sm">ONBOARDING</div>
                </Link>
            )
          }
        }
    };

    renderScheduleLink = () =>{
        if(!this.state.noCoach && this.props.user){
            const username = this.props.user.username;
            if(this.props.user.user_status_id !== 3){
                if(this.state.multipleCoaches){
                    return (
                        <Link className="client-header-links no-text-decoration"
                              to={`/member/clientDash/${username}/Coaching-Team/coaches`}
                        >
                            <div className="hidden-xs hidden-sm">SCHEDULE</div>
                            <span><i class="fa fa-phone fa-lg"></i></span>
                        </Link>
                    )
                }else{
                    return(
                        <Link className="client-header-links no-text-decoration"
                              to={{
                                  pathname:`/member/clientDash/Coaching-Team/Schedule`,
                                  state:{coachUsername:this.state.coachUsername}
                              }} >
                            <div className="hidden-xs hidden-sm">SCHEDULE</div>
                            <span><i class="fa fa-phone fa-lg"></i></span>
                        </Link>
                    )
                }
            }
        }
    };

    renderPhone = () => {
      if (!this.state.noCoach && this.props.user){
          if (this.props.user.user_status_id === 1){
              if (this.state.multipleCoaches){
                  return (
                      <Link className="client-header-links no-text-decoration"
                            to={`/member/clientDash/${this.props.user.username}/Coaching-Team/coaches`} >
                          <div className="hidden-xs hidden-sm">SCHEDULE</div> <span><i class="fa fa-phone fa-lg"></i></span>
                      </Link>
                  )
              }else{
                  return (
                      <Link className="client-header-links no-text-decoration" to={{
                          pathname:`/member/clientDash/Coaching-Team/Schedule`,
                          state:{coachUsername:this.state.coachUsername}
                      }} >
                          <div className="hidden-xs hidden-sm">SCHEDULE</div> <span><i class="fa fa-phone fa-lg"></i></span>
                      </Link>
                  )
              }
          }
      }
    };



  render() {
    // eslint-disable-next-line no-unused-vars
    let loggedInMessage;
    // eslint-disable-next-line no-unused-vars
    let logOutButton;
    if(this.state.loggedIn) {
      loggedInMessage = <h3 className="current-user">{currentUser}</h3>
      logOutButton = <input type="submit" value="Log Out"/>
    }
    else {
      window.location.assign('/');
    }

    if (!this.props.complianceCheck && !this.props.compliance) {
        return (
            <Redirect to={`/member/clientDash/${this.props.user.username}/policies`}/>
        )
    }
    console.log(this.props.user);
    return (
      <div className="header-overlay">

          <Navbar>
            <Navbar.Header className="hidden-xs">
              <Navbar.Brand>
                <a href={this.props.user ? `/member/clientDash/${this.props.user.username}` : ''}>
                    { this.state.logo ? <img className='header-logo' src={this.state.logo} alt='Treo Logo' /> : null }
                </a>
              </Navbar.Brand>
              
            </Navbar.Header>
                           
              <Nav>
                <div className="visible-xs mobile--logo">
                  <a className='d:i-b' href={this.props.user ? `/member/clientDash/${this.props.user.username}` : ''}>
                      { this.state.logo ? <img src={this.state.logo} alt='Treo Logo' /> : null }
                  </a>
                </div>
                  {this.renderDashLink()}
                <Link className="client-header-links no-text-decoration" to={this.props.user ? `/member/clientDash/${this.props.user.username}/inbox` : ''}>
                        <div className="hidden-xs hidden-sm">MESSAGING</div> <span><i class="fa fa-envelope fa-lg"></i>{this.state.unread < 1 ? null : <div className="header-unread-notification">{this.state.unread}</div>}</span>
                </Link>
                {this.renderPhone()}
                  <Link className="client-header-links" to={this.props.user ? `/member/clientDash/${this.props.user.username}/profile` : ''}>
                       <div className="hidden-xs hidden-sm">PROFILE</div>
                      {this.props.user ?
                          <PhotoContainer
                          image={this.props.user.profile_image}
                          username={this.props.user.username}
                          url_path={`member/clientDash/${this.props.user.username}/profile`}
                          />
                      : null}
                  </Link>
              </Nav>
              <form className="logout-form hidden-xs hidden-sm hidden-med" onSubmit={this.logOutUser}>
                <Navbar.Form pullRight>

                        

                  <Button type="submit">Log Out</Button>
                </Navbar.Form>
              </form>
              
          </Navbar>
      </div>
    );
  }
}


function mapPropsToState({user, complianceCheck, }){
    return {user, complianceCheck, }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ fetchSelf, setCompliance }, dispatch);
}

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(HeaderClient));
