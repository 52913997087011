import React, {useEffect, useState} from 'react';
import {Route, withRouter} from 'react-router-dom';

// actions
import {
    fetchContentTypes,
    fetchContentTypeCategories,
    fetchSubcategories,
    fetchCategoryContent,
    saveCategoryOrder,
    saveContentOrder,
    saveCategoryMeta,
    removeCategory,
} from "../../store/actions/contentActions";

// components
import MainCategories from "./mainCategories/mainCategories";
import LinkList from "../links/linkList/linkList";
import {ErrorAlert} from "../alerts/alerts";
import Category from "./category/category";

const Categories = props => {
    // props
    const {match, history} = props;
    // local
    const [generalError, setGeneralError] = useState(null);
    const [links, setLinks] = useState([]);
    const [contentType, setContentType] = useState(null);
    const [contentTypeList, setContentTypeList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({});
    const [subcategories, setSubcategories] = useState([]);
    const [content, setContent] = useState([]);

    // grab content types on mount
    useEffect(() => {
        fetchContentTypes()
            .then(types => {
                setContentTypeList(types);
                const hrefs = types.map(type => ({
                    href: `/dashboard/Content/type/${type.id}`,
                    label: type.type.charAt(0).toUpperCase() + type.type.slice(1),
                }));
                setLinks(hrefs);
                const {params: {type}} = match;
                setContentType(types.find(item => item.id === parseInt(type)));
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setGeneralError(e.response.data.error)
                } else {
                    setGeneralError('Unable to load content types. Please refresh to try again. If this error persists contact system admin');
                }
            })
    }, []);

    // update content type when changing between links
    useEffect(() => {
        setCategories([]);
        setCategory({});
        setSubcategories([]);
        const {params: {type}} = match;
        setContentType(contentTypeList.find(item => item.id === parseInt(type)));
    }, [match.params.type]);

    // update categories whenever content type changes
    useEffect(() => {
        getCategories()
    }, [contentType]);

    // remove category state when selecting a subcategory to view
    useEffect(() => {
        if (category && category.category) setCategories([]);
    }, [category]);

    // get list of categories under a given content type
    const getCategories = (searchParams) => {
        if (!contentType) return;
        fetchContentTypeCategories(contentType.id, searchParams)
            .then(cats => setCategories(cats))
            .catch(e => {
                if (e.response && e.response.data) {
                    setGeneralError(e.response.data.error)
                } else {
                    setGeneralError('Unable to load categories. Please refresh to try again. If this error persists contact system admin');
                }
            })
    }

    // get a specific category
    const getCategory = (category) => {
        return new Promise(resolve => {
            fetchSubcategories(category)
                .then(cats => {
                    const categoryClone = {...cats};
                    delete categoryClone.sub_categories;
                    setCategory(categoryClone);
                    setSubcategories(cats.sub_categories);
                    resolve(cats);
                })
                .catch(e => {
                    if (e.response && e.response.data) {
                        setGeneralError(e.response.data.error)
                    } else {
                        setGeneralError('Unable to load categories. Please refresh to try again. If this error persists contact system admin');
                    }
                })
        })
    }

    // get content belonging to a specific category
    const getContent = (category) => {
        fetchCategoryContent(category)
            .then(list => {
                setContent(list);
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setGeneralError(e.response.data.error)
                } else {
                    setGeneralError('Unable to load content. Please refresh to try again. If this error persists contact system admin');
                }
            })
    }

    // change the order of categories
    const categoryOrder = list => {
        const ids = list.map(item => item.category_id);
        setSubcategories(list);
        saveCategoryOrder(contentType.id, ids)
            .then(() => {
                getCategory(category.category_id);
            })
    }

    

    // change the order of main categories
    const mainCategoryOrder = list => {
        setCategories(list);
        const ids = list.map(item => item.category_id);
        saveCategoryOrder(contentType.id, ids)
            .then(() => {
                getCategories(contentType.id);
            })
    }

    // change the order of content
    const contentOrder = list => {
        const ids = list.map(item => item.id);
        saveContentOrder(contentType.id, ids);
        setContent(list);
        getContent(category.category_id);
    }

    // save new category metadata
    const saveCategory = async ({id, title, description, hero, tile, parent, instructor, collection, collectionLabel, studio}) => {
        const data = new FormData();
        // append body
        data.append('description', description || '');
        data.append('content_type_id', contentType.id);
        data.append('instructor_label', instructor || "");
        data.append('collection_label', collectionLabel || "");
        data.append('collection', collection);
        // append files
        if (hero) data.append('hero_image', hero);
        if (tile) data.append('tile_image', tile);
        if (studio) data.append('studio_image', studio);
        try {
            if (id) {
                data.append('name', title);
                return new Promise(resolve => {
                    saveCategoryMeta(data, id)
                        .then(result => {
                            getCategory(id);
                            resolve(result);
                        })
                })
            } else {
                if (parent) data.append('parent_category_id', parent);
                return new Promise(resolve => {
                    saveCategoryMeta(data, title, true)
                        .then(result => {
                            if (parent) {
                                history.push(`${match.url}/sub/${result.id}`);
                            } else {
                                history.push(`${match.url}/cat/${result.id}`);
                            }
                            getContent(result.id);
                            getCategory(result.id);
                            resolve(result);
                        })
                })
            }
        } catch (e) {
            if (e.response && e.response.data) {
                setGeneralError(e.response.data.error)
            } else {
                setGeneralError('Unable to create category. Please refresh to try again. If this error persists contact system admin');
            }
        }
    }

    // remove a category
    const removeSingleCategory = categoryId => {
        removeCategory(categoryId)
            .then(() => {
                getCategories();
                if (category && category.category_id) getCategory(category.category_id);
            })
            .catch(e =>{
                if (e.response && e.response.data) {
                    setGeneralError(e.response.data.error)
                } else {
                    setGeneralError('Unable to remove category. Please refresh to try again. If this error persists contact system admin');
                }
            })
    }

    // preform any unmounting clean up
    const cleanUp = (type)=> {
        getCategories(type);
        setContent([]);
        setCategory({});
        setSubcategories([]);
    }

    return (
        <div>
            <div className="page-header-container searchForm-container">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb ">
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item underline ">
                                        <a href="/dashboard" className="text-light">
                                            Dashboard
                                        </a>
                                    </li>
                                    <span className="arrow"></span>
                                    <li className="breadcrumb-item underline ">
                                        <a href="/dashboard/Content" className="text-light">
                                            Content
                                        </a>
                                    </li>
                                    <span className="arrow"></span>
                                    <li
                                        className="breadcrumb-item text-light active"
                                        aria-current="page"
                                    >
                                        Categories
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <h1>Categories</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-3 margin-top-lrg">
                        <LinkList links={links} exact={false}/>
                    </div>
                    <div className="col-xs-12 col-md-9 margin-top-lrg">
                        {/*set general error for page*/}
                        {generalError ? <ErrorAlert>{JSON.stringify(generalError)}</ErrorAlert> : null}

                        {/*create routes for category nav*/}
                        <Route path={`${match.url}/`} exact render={()=>{
                            return (
                                <MainCategories
                                    contentType={contentType}
                                    categories={categories}
                                    getCategories={getCategories}
                                    remove={removeSingleCategory}
                                    setCategoryOrder={mainCategoryOrder}
                                    cleanUp={() => {
                                        setCategories([])
                                    }}/>
                            )
                        }}/>

                        {/*create routes for categories*/}
                        <Route path={`${match.url}/cat/:category`} render={() => {
                            return (
                                <Category
                                    contentType={contentType}
                                    category={category}
                                    subcategories={subcategories}
                                    content={content}
                                    getCategory={getCategory}
                                    getContent={getContent}
                                    cleanUp={cleanUp}
                                    setCategoryOrder={categoryOrder}
                                    setContentOrder={contentOrder}
                                    saveCategory={saveCategory}
                                    remove={removeSingleCategory}/>
                            )
                        }}/>

                        {/*create routes for subcategories*/}
                        <Route path={`${match.url}/sub/:category`} render={() => {
                            return (
                                <Category
                                    contentType={contentType}
                                    category={category}
                                    subcategories={subcategories}
                                    content={content}
                                    getCategory={getCategory}
                                    getContent={getContent}
                                    cleanUp={cleanUp}
                                    setCategoryOrder={categoryOrder}
                                    setContentOrder={contentOrder}
                                    saveCategory={saveCategory}
                                    remove={removeSingleCategory}/>
                            )
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withRouter(Categories);