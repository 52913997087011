import React, { useEffect, useState } from 'react';
import {withRouter,} from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';

// actions
import {fetchMemberContent, fetchCategoryContent} from "../../store/actions/pathwaysActions";

// components
import {ContentLink} from "../links/links";
import checkmark from "../../images/checkmark.png";
import LockImage from '../../images/icon-lock.png';
import ContentImage from '../../images/icon-circles.png';
import NoodleImage from '../../images/noodle-overlay.png';
import {ProgressBar} from "react-bootstrap";
import ClientHeader from "../clientHeader/clientHeader";

const PathwayContentLifeStyle = ({ user, ...props }) => {
    const params = new URLSearchParams(props.location.search);
    const pathwayId = params.get('pathwayId');
    const categoryId = params.get('lifestyleId');
    const [ state, updateState ] = useState({
        content: null,
        category: null,
        overview: null,
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function fetchContent() {
        fetchMemberContent().getHealthGoal()
            .then(result => {
                setState({ content: result, goal: true });
            })
            .catch(e => console.log(e));
    }

    // grab content on mount
    useEffect(() => {
        fetchContent();
        fetchCategoryContent(categoryId)
            .then(result => {
                const overview = result.content.find(item => item.overview);
                setState({ category: result, overview });
            })
            .catch(e => console.log(e));
    }, []);

    if (!user || _.isEmpty(user)) return <div/>;

    return (
        <>
            <ClientHeader type={'transparent'} backUrl={`/member/pathways/${user.username}/pathway?pathwayId=${pathwayId}`}/>
            <div>
                <div
                    style={ state.category ? { backgroundImage: `url(${state.category.hero_image_url})`} : null }
                    className="wellness-bg wydBG pathwaysBackground">
                    <div className='pathwaysOverlay'/>
                    { state.category ?
                        <div>
                            <div className="container client-dash">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <h2 className="no-margin t-t:c">{state.category.category_name}</h2>
                                        {state.category.description ? <p>{state.category.description}</p> : null}
                                        <div className='fullWidth'>
                                            { state.category.count > 0 ?
                                                <ProgressBar className='w:2of4' now={(state.category.complete / state.category.count)*100}/>
                                                : null
                                            }
                                            <p className="progressbar-p t-t:n">{state.category.complete} of {state.category.count}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div>
                    <div className={`container client-dash ${!state.overview ? 'no-overview' : ''}`}>
                        <div className="row" >
                            <div className='contentLinkContainer'>
                                { state.overview ?
                                    <div className="col-xs-12">
                                        <ContentLink
                                            to={`/member/pathways/${user.username}/content/${state.overview.id}?pathwayId=${pathwayId}&lifestyleId=${state.overview.categories.length ? state.overview.categories[0].id : ''}`}
                                            text={`1. ${state.overview.title}`}
                                            image={state.overview.completed ? { src: checkmark, alt: 'checkmark'} : <i className="fas fa-caret-right img"/>}
                                            background='#6BA543 no-repeat center right'
                                            color='#fff'
                                            tileImage={NoodleImage}
                                            className='video tour-pathway-subcategory'
                                        />
                                    </div>
                                    : null
                                }
                                { state.content ? state.content.subcategories.map(category => {
                                    if (category.content.length === 0 || category.category_id !== parseInt(categoryId)) return null;
                                    return category.content.map((content, index) => {
                                        if (content.overview) return null;
                                        const image = { src: '', alt: 'icon' };
                                        if (content.completed) image.src = checkmark;
                                        return (
                                            <div className="col-xs-12 col-sm-6 contentLink">
                                                <ContentLink
                                                    className={!state.overview && index === 0 ? 'tour-pathway-subcategory' : ''}
                                                    overlay
                                                    key={content.id}
                                                    disabled={content.lock_status !== 'unlocked'}
                                                    text={`${index+1}. ${content.title}`}
                                                    image={image}
                                                    tileImage={content.thumbnail || ContentImage}
                                                    lockImage={content.lock_status !== 'unlocked' ? LockImage : null}
                                                    to={`/member/pathways/${user.username}/content/${content.id}?pathwayId=${pathwayId}&lifestyleId=${categoryId}`}
                                                />
                                            </div>
                                        )})
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-actions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <button
                                    className="btn client-white"
                                    onClick={()=>props.history.push(`/member/pathways/${user.username}/pathway?pathwayId=${pathwayId}`)}>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

function mapStateToProps({ user, }) {
    return { user, };
}

export default withRouter(connect(mapStateToProps)(PathwayContentLifeStyle));