import React from'react';
import {Route} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import Tiles from "../tiles/tiles";
import CreateTile from "../CreateTile/CreateSteps";
import ClientSummary from "../ClientSummary/ClientSummary";
import SingleTile from "../tiles/singleTile";
import Teammates from "../Teammates/Teammates";
import Profile from "../Profile/Profile";
import NotesMain from "../Notes/NotesMain";
import CoachingTeam from "../CoachingTeam/CoachingTeam";
import AssessmentContainer from '../../assessmentView/assessmentViewContainer';
import PathwaysContainer from "../../pathwaysView/pathwaysViewContainer";
import ContentMemberReporting from "../../contentMemberReporting/contentMemberReporting";
import TeamInbox from "../../inbox/teamInbox/teamInbox";
import CommunityAdmin from "../../communityAdmin/communityAdmin";

const ClientDisplay = ({match, username, userPermission}) =>{
    return(
        <div>
            <Route
                exact
                path={match.url+"/overview"}
                render={()=><ClientSummary match={match} username={username}/>}
            />
            <Route
                path={match.url+"/reporting"}
                render={()=><ContentMemberReporting/>}
            />
            <Route
                path={match.url+"/tiles"}
                render={()=><Tiles {...withRouter} match={match} username ={username}/>}
            />
            <Route
                path={match.url+'/TileDetail/:id'}
                    render={()=><SingleTile match={match} username={username}/>}
            />
            <Route
                path={match.url+"/create"}
                render={()=><CreateTile {...withRouter} match={match} username ={username}/>}
            />
            <Route
                path={match.url+"/teammates"}
                render={()=><Teammates {...withRouter} match={match} username ={username}/>}
            />
            <Route
                path={match.url+"/profile"}
                render={()=><Profile {...withRouter} match={match} username ={username}/>}
            />
            <Route
                path={match.url+"/inbox"}
                component={TeamInbox}
            />
            <Route
                path={match.url+"/notes"}
                render={()=><NotesMain {...withRouter} match={match} username ={username}/>}
            />
            <Route
                  path={match.url+"/assessment"}
                  render={()=><AssessmentContainer {...withRouter} match={match} username={username}/>}
            />
            <Route
                path={match.url+"/coaching-team"}
                render={()=><CoachingTeam {...withRouter} match={match} username ={username}/>}
            />
            <Route
                path={match.url+"/pathways"}
                render={()=><PathwaysContainer {...withRouter} match={match} username ={username}/>}
            />
            <Route path={match.url+"/community"} component={CommunityAdmin}/>

        </div>
    )

};


export default withRouter(ClientDisplay);
