import mainApi from "./mainApi";

export function alertApi() {
    return {
        getAlerts: function() {
            // get a list of all pending alerts
            return mainApi.get(' /alert/all');
        },
        deleteAlert: function(alertId) {
            // delete an alert from the server that has been seen
            return mainApi.put(`/alert/acknowledged/${alertId}`);
        }
    }
}