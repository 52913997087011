import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

// actions
import {
    getSingleOrganization,
} from "../../../actions/action_organizations";

// styles
import styles from './organizationsSeats.module.css';

// components
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import SeatsWidget from "../../seatsWidget/seatsWidget";
import { PrimaryButton } from "../../buttons/buttons";
import InfoIcon from "../../../images/info_icon.png";
import ReactTooltip from "react-tooltip";
import OrganizationSeatHistory from "./organizationSeatHistory";
import {InfoAlert} from "../../alerts/alerts";

const OrganizationSeats = (props) => {
    // props
    const { organization } = props;
    // actions
    const { getSingleOrganization } = props;

    const [ total, setTotal ] = useState(organization.seats.total_seats ? organization.seats.total_seats.capacity : 0); // total number of seats
    const [ monthlyValue, setMonthlyValue ] = useState(organization.seats.monthly_seats ? organization.seats.monthly_seats.capacity : 0); // actual value chosen for monthly available seats
    const [ changes, setChanges ] = useState(false); // keep track of changes made to monthly/total seat form

    function submit(e) {
        e.preventDefault();
        axios.post(`/organizations/seats/edit/${organization.id}`, {
            total_seats: total,
            monthly_seats: monthlyValue
        })
            .then(() => {
                getSingleOrganization(organization.id);
                setChanges(false);
            })
            .catch(e => console.log(e));
    }

    return (
        <div className='margin-top-2x'>
            <div className="row margin-bottom-2x">
                <div className="col-xs-12 col-sm-6">
                    <h3>Current Seat Usage</h3>
                    <p>[ widget helpful description here ]</p>
                    <SeatsWidget containerClass={styles.boxes} organization={organization}/>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <h3>Seat Management</h3>
                    <p>[ widget helpful description here ]</p>
                    <form onSubmit={submit}>
                        <BoxContainer className={styles.boxes}>
                            { changes ? <InfoAlert className='m-t:0'>You have unsaved changes</InfoAlert> : null}
                            <div className="form-group">
                                <label htmlFor="monthlyAvailable">Monthly Available</label>
                                <p className={`hover ${styles.icon}`} data-tip='Number of seats available for the remainder of the month'>
                                    <img src={InfoIcon} alt='monthly seat info'/>
                                </p>
                                <input
                                    value={monthlyValue}
                                    onChange={e => {
                                        setChanges(true);
                                        setMonthlyValue(parseInt(e.target.value))
                                    }}
                                    min={organization.seats.monthly_seats ? organization.seats.monthly_seats.usage.total : 0}
                                    max={10000}
                                    type="number"
                                    id="monthlyAvailable"
                                    className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="totalAvailable">Total Available</label>
                                <p className={`hover ${styles.icon}`} data-tip='Total number of seats to be made available over the lifespan of the organization'>
                                    <img src={InfoIcon} alt='total seat info'/>
                                </p>
                                <input
                                    value={total}
                                    min={organization.seats.total_seats ? organization.seats.total_seats.usage.total : 0}
                                    max={10000}
                                    onChange={e => {
                                        setChanges(true);
                                        setTotal(parseInt(e.target.value))
                                    }}
                                    type="number"
                                    id="totalAvailable"
                                    className="form-control"/>
                            </div>
                            <div className="form-group">
                                <PrimaryButton onClick={submit} type='submit'>Save Changes</PrimaryButton>
                            </div>
                        </BoxContainer>
                    </form>
                </div>
            </div>
            <div>
                <h3>Current Seat Usage</h3>
                <p>[ widget helpful description here ]</p>
                <BoxContainer className={styles.boxes}>
                    <OrganizationSeatHistory organization={organization}/>
                </BoxContainer>
            </div>

            <ReactTooltip  place='top' type='light' event='click' effect='solid' globalEventOff='click' clickable />
        </div>
    )
}

const actions = {
    getSingleOrganization,
}
export default connect(null, actions)(OrganizationSeats);