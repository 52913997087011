import React, { useEffect } from 'react';

// styles
import styles from './planSelection.module.css';

// icons
import {HabitTileIcon} from "../habitTileIcon/habitTileIcon";

const PlanSelection = props => {
    // props
    const { tile, close, next } = props;

    function setModalHeight() {
        const modal = document.getElementById('selectionModal');
        const width = window.innerWidth;
        if (width < 776) {
            const height = window.innerHeight;
            modal.style.height = `${height - 30}px`;
        } else {
            modal.style.height = null;
        }
    }

    useEffect(() => {
        setModalHeight();
        window.addEventListener('resize', setModalHeight);
        return () => window.removeEventListener('resize', setModalHeight);
    }, []);
    return (
        <div id='selectionModal' className={styles.selectionContainer}>
            <div className={styles.exit} onClick={close}>&times;</div>
            {tileIcon(tile.tile.tile_category)}
            <p className={styles.group}>{tile.tile.tile_category}</p>
            <h3 className={styles.action}>Select Your Activity</h3>
            <div className={styles.buttonContainer}>
                { tile.plan.map(plan => {
                    return (<button key={plan.id} className={styles.button} onClick={() => next(plan)}>{plan.description}</button>)
                })}
            </div>
        </div>
    )
}

export default PlanSelection;

function tileIcon (tile) {
    switch(tile) {
        case 'what you do':
            return (
                <div className={styles.icon}>
                    <HabitTileIcon tile={7} size={200}/>
                </div>
            );
        case 'when you eat':
            return (
                <div className={styles.icon}>
                    <HabitTileIcon tile={6} size={200}/>
                </div>
            );
        case 'what you eat':
            return (
                <div className={styles.icon}>
                    <HabitTileIcon tile={5} size={200}/>
                </div>
            );
        // no default
    }
}