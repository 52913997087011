import {GET_BOOKINGS} from "../actions/action_calendar";
import {RESET_BOOKINGS} from "../actions/action_calendar";

export function bookingsReducer (state=null,action ){
    switch (action.type){
        case GET_BOOKINGS:
            return action.payload.data.result;

        case RESET_BOOKINGS:
            return action.payload;
        // no default
    }
    return state;
}
