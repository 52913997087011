import React, {useCallback, useEffect, useRef, useState} from "react";
import {BigPlayButton, Player} from "video-react";
import {connect} from 'react-redux';

// styles
import styles from './videoPlayer.module.css';

// actions
import {recordViewChunk} from "../../store/actions/contentActions";
import {checkAlerts} from "../../store/actions/alertActions";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import {getDailyScore, setChangedScoreFlag} from "../../store/actions/wellnessScoreActions";

const VimeoVideoPlayer = props => {

    
    // props
    const {id, identifier, src } = props
    // actions
    const {checkAlerts, setChangedScoreFlag} = props;
    //local
    const [player, setPlayer] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const currentTime = useRef(0);
    const ended = useRef(false);
    const [session, setSession] = useState(`${identifier}-${new Date().getTime()}`);
    const interval = useRef(null);
    const [initialView, setInitialView] = useState(false);
    const duration = useRef(0);

    const handleStateChange = useCallback(state => {
        if (duration.current === 0 || isNaN(duration.current)) duration.current = state.duration;
        if (!state.paused && !isPlaying) setIsPlaying(true);
        if (state.paused) {
            setIsPlaying(false);
        }
        currentTime.current = state.currentTime;
        if(state.ended) {
            setIsPlaying(false);
            ended.current = true;
        } else if (!state.ended && ended.current) {
            ended.current = false;
        }
    }, [player]);

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const notify = useCallback((start, duration, total) => {
        try{
            recordViewChunk(id, start, duration, session, total)
                .then(res => {
                    console.log(res);
                    if (res.alert_waiting){
                        checkAlerts();
                        getDailyScore();
                        setChangedScoreFlag(parseInt(res.daily_score.score.replace(',', '')));
                    }
                });
        } catch(e) {
            console.log(e);
        }
    }, [id, session]);

    useDidMountEffect(() => {
        if (!player) return;
        player.load();
        setSession(`${identifier}-${new Date().getTime()}`);
    }, [id]);

    useDidMountEffect(() => {
        notify(0, 0, 0);
    }, [session]);


    useEffect(() => {
        if (player) {
            player.subscribeToStateChange(handleStateChange);
        }
    }, [player, handleStateChange]);

    useEffect(() => {
        if (!isValidHttpUrl(src)) return;
        if (!isPlaying && interval.current) {
            clearInterval(interval.current);
            interval.current = null;
            return;
        }
        if (ended.current && interval.current) {
            clearInterval(interval.current);
            interval.current = null;
            return;
        }
        if (!ended.current && interval.current === null && isPlaying) {
            if (!initialView) {
                notify(0, 0, 0);
                setInitialView(true);
            }
            const seconds = 5
            interval.current = setInterval(() => {
                notify(Math.ceil(currentTime.current)-seconds, seconds, duration.current);
            }, seconds * 1000)
        }
        return () => {
            clearInterval(interval.current);
        }
    }, [isPlaying, ended, notify]);

    if (isValidHttpUrl(src)) {
        return (
            <div>
                <div className={`${styles.videoContainer} ${styles.customVideoContainer}`}>
                    <Player playsInline autoPlay={false} ref={(player) => { setPlayer(player) }}>
                        <BigPlayButton position="center" />
                        <source src={src} />
                    </Player>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className={styles.videoContainer}>
                <iframe
                    title={id}
                    className={styles.videoIframe}
                    src={`https://player.vimeo.com/video/${src}`}
                    width="100%"
                    height="450"
                    frameBorder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowFullScreen
                />
            </div>
        </div>
    )
}

const actions = {
    checkAlerts,
    getDailyScore,
    setChangedScoreFlag,
}

export default connect(null, actions)(VimeoVideoPlayer);