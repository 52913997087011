import mainApi from "./mainApi";

export function pathwaysApi() {
    return {
        // get a single pathway for a client providing a pathway id and optional user id
        getClientPathway: function(pathwayId, userId) {
            let url = `/pathways/single/${pathwayId}`;
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // get all the pathways for a client
        getClientPathwayList: function(userId) {
            let url = '/pathways/all';
            if (userId) url += `?user_id=${userId}`;
            return mainApi.get(url);
        },
        // get the next pathways video for a user that hasn't been viewed
        getNextUnviewedContent: function() {
            return mainApi.get('/pathways/content/getNext');
        },
        // get the next content of a given piece of content
        getNextContent: function(contentId) {
            return mainApi.get(`/pathways/content/nextVideo/${contentId}`);
        },
        // create a new pathway for a member
        createPathway: function(pathwayId){
            return mainApi.post(`/pathways/createpathway/${pathwayId}`);
        },

        getLifestyle: function (pathwayId){
            return mainApi.post(`/pathways/createlifestyle/${pathwayId}`)
        },
        // get content for just for you content category assigned to user
        getContent: function(content) {
            return mainApi.get(`/pathways/content/${content}`);
        },
        // get all just for you categories
        getJustForYouCategories: function () {
            return mainApi.get('/pathways/content/justforyou/list ');
        },
        // assign a just for you category to a member
        assignJustForYou: function(categories) {
            return mainApi.post('/pathways/content/justforyou/assign', categories);
        },
        // check if content exists for a given category
        checkContentInCategory: function(categoryId) {
            return mainApi.get(`/pathways/content/checkForContent/${categoryId}`);
        },
        // get content within a given category
        getContentByCategory: function(categoryId) {
            return mainApi.get(`/pathways/content/bycategory/${categoryId}`)
        },
        // get a single piece of content given a contentId
        getSingleContent: function(contentId) {
            return mainApi.get(`/pathways/content/single/${contentId}`);
        },
        // record that content has been viewed by a member
        recordView: function(contentId) {
            return mainApi.post(`/pathways/content/record/${contentId}`);
        },
        // get stats of member pathways history
        getStats: function(userId, categoryId) {
            let url = `/pathways/content/stats/${userId}`;
            if (categoryId) url += `?category_id=${categoryId}`;
            return mainApi.get(url);
        }
    }
}