import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

// styles
import styles from './featuredAudio.module.css';

// actions
import {fetchFeaturedSingle} from "../../../store/actions/contentActions";

//components
import {recordContentView} from "../../../store/actions/pathwaysActions";
import AudioPlayer2 from "../../audioPlayer/audioPlayer2";
import ClientHeader from "../../clientHeader/clientHeader";
import BrightcoveAudioPlayer from "../../audioPlayer/brightcoveAudioPlayer/brightcoveAudioPlayer";

const FeaturedAudio = props => {
    // props
    const {match} = props;
    //state
    const {user} = props;
    // local
    const [time, setTime] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const playing = useRef(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        vibrate(isPlaying);
    }, [isPlaying]);

    // grab content on mount
    useEffect(() => {
        fetchFeaturedSingle(match.params.contentId)
            .then(result => {
                setContent(result);
            })
            .catch(e => console.log(e));
    }, []);

    // record view
    useEffect(() => {
        const seconds = window.location.href.lastIndexOf('localhost') > 0 ? .5 : 10;
        const timer = setTimeout(() => {
            recordContentView(match.params.contentId)
                .catch(e => console.log(e));
        }, 1000 * seconds);
        return () => {
            if (timer) clearTimeout(timer);
        }
    }, []);


    const calculateTime = seconds => {
        const minutes = Math.floor(seconds/60);
        const secs = Math.floor(seconds%60);
        const returnedSeconds = secs < 10 ? `0${secs}` : `${secs}`;
        setTime(`${minutes}:${returnedSeconds}`) ;
    }

    const setPlaying = bool => {
        playing.current = bool;
        setIsPlaying(bool);
    }

    const vibrate = (bool) => {
        const circle1 = document.getElementById('circle1');
        if (circle1) {
            if (bool && !circle1.classList.contains(styles.expand)) {
                circle1.classList.add(styles.expand);
            } else {
                circle1.classList.remove(styles.expand);
            }
        }
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }


    if (!content) return <div/>
    let backUrl = `/member/clientDash/${user.username}`;
    if (content.categories.length) backUrl = `/member/featured/${content.categories[0].id}`;
    return (
        <>
            <ClientHeader type={'transparent'} backUrl={backUrl}/>
            <div className={styles.container} style={{backgroundImage: `url(${content.thumbnail})`}}>
                <div className={styles.overlay}/>
                <div className={styles.time}>
                    <p>{time}</p>
                    <div className={styles.circle}/>
                    <div id='circle1' className={styles.circle}/>
                    <div id='circle2' className={styles.circle}/>
                    <div id='circle3' className={styles.circle}/>
                </div>
                <div className={styles.player}>
                    {isValidHttpUrl(content.content) ?
                        <AudioPlayer2
                            id={content.id}
                            identifier={user.id}
                            title={content.title}
                            src={content.content}
                            timeCallback={calculateTime}
                            playingCallback={setPlaying}/>
                        :
                        <BrightcoveAudioPlayer
                            id={content.id}
                            identifier={user.id}
                            title={content.title}
                            src={content.content}
                            timeCallback={calculateTime}
                            playingCallback={setPlaying}/>
                    }
                </div>
                <div className="footer-actions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <button className="btn client-white" onClick={()=>props.history.push(`/member/featured/${content.categories[0].id}`)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps({user}) {
    return ({user})
}

export default withRouter(connect(mapStateToProps)(FeaturedAudio));