
// npm modules
import React, { useEffect, useState } from 'react';
import randomstring from 'randomstring';

// styles
import styles from './imageThumbnail.module.css';

const InputImage = ({ image, setImage }) => {
    const id = randomstring.generate({ length: 5, charset: 'alphabetic' });
    const [ show, setShow ] = useState('none');
    const [ thumbnail, setThumbnail ] = useState(null);
    const [ preview, setPreview ] = useState(null);

    // capture update to thumbnail image
    useEffect(() => {
        if (thumbnail){
            const reader = new FileReader();

            reader.onload = function(event) {
                setPreview(event.target.result);
            };

            reader.readAsDataURL(thumbnail);
        }
    }, [thumbnail]);

    // set preview if image is passed into component on mount and is a file object
    useEffect(() => {
        if (image && image.name) {
            setThumbnail(image);
        }
    });

    function onImageChange(file) {
        setThumbnail(file);
        if (setImage) setImage(file);
    }

    if (image || preview) {
        return (
            <div
                onMouseEnter={setImage ? ()=>setShow('flex') : null}
                onMouseLeave={setImage ? ()=>setShow('none') : null}
                style={{backgroundImage: `url(${preview ? preview : image})`}}
                className={styles.thumbnail}>
                <img className='sr-only' src={preview ? preview : image} alt="thumbnail"/>
                <div
                    onClick={setImage ? ()=> document.getElementById(id).click() : null}
                    className={styles.text} style={{ display: show }}
                >
                    Upload Image
                </div>
                {
                    !image ?
                        <div className={styles.placeholder}>Upload Image</div>
                        :
                        null
                }
                <input
                    id={id}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={e => onImageChange(e.target.files[0])}
                />
            </div>
        )
    }
    return (
        <input
            id={id}
            type="file"
            onChange={e => onImageChange(e.target.files[0])}
        />
    )
};

export default InputImage;