import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { ProgressBar } from "react-bootstrap";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { isEmpty } from 'lodash';

// styles
import styles from './pathwayContentMain.module.css';

// actions
import {
    fetchMemberContent,
    fetchNextUnviewedContent,
    checkContentExists
} from "../../store/actions/pathwaysActions";

// components
import {ContentLink} from "../links/links";
import checkmark from '../../images/checkmark.png';
import Plus from '../../images/plussign-lightgreen.png';
import ClientHeader from "../clientHeader/clientHeader";

const PathwayContentMain = ({ pathway, coach, user, ...props}) => {
    const [ foundationContent, setFoundationContent ] = useState(null);
    const [ coachContent, setCoachContent ] = useState(null);
    const [ healthContent, setHealthContent ] = useState(null);
    const [ jfyContent, setJfyContent ] = useState(null);
    const [ contentExists, setContentExists ] = useState(null);
    const [ nextContent, setNextContent ] = useState(null);

    const categoryProgress = content => {
        if (content.subcategories && content.subcategories.length) {
            let complete = 0;
            let total = content.subcategories.filter(cat => cat.content.length).length;
            content.subcategories.forEach(category => {
                if (category.complete === category.count && category.count > 0) ++complete;
            });
            if (content.content && content.content.length) {
                content.content.forEach(item => {
                    if (item.overview) ++total;
                    if (item.completed) ++complete;
                })
            }
            return { complete, total };
        }else {
            return { complete: 0, total: 0 };
        }
    }

    // grab foundations content on mount
    useEffect(() => {
        if (foundationContent) return;
        fetchMemberContent().getFoundations()
            .then(result => {
                setFoundationContent(result);
            }).catch(e => console.log(e));
    }, []);

    // grab health goal content on mount
    useEffect(() => {
        if (healthContent) return;
        fetchMemberContent().getHealthGoal()
            .then(result => {
                setHealthContent(result);
                checkContentExists(result.category_id)
                    .then(result => {
                        setContentExists(result);
                    })
            }).catch(e => console.log(e));
    }, []);

    // grab coach content on mount
    useEffect(() => {
        if (!coach || coachContent) return;
        fetchMemberContent().getCoach()
            .then(result => {
                setCoachContent(result.count ? result : {});
            }).catch(e => console.log(e));
    }, [coach]);

    // grab just for you content on mount
    useEffect(() => {
        fetchMemberContent().getJustForYou()
            .then(jfy => {
                setJfyContent(jfy);
            })
            .catch(e => console.log(e));
    }, []);

    // grab next video on mount
    useEffect(() => {
        fetchNextUnviewedContent()
            .then(next => {
                setNextContent(next);
            }).catch(e => console.log(e));
    }, []);

    if (!user || _.isEmpty(user) || !pathway || !coach || !coachContent || !foundationContent || !healthContent || !jfyContent) {
        return ( <div/>);
    }

    let nextUrl = '';
    if (nextContent) {
        nextUrl = `/member/pathways/${user.username}/content/${nextContent.id}`;
        if (nextContent.pathway_type === 'coach') nextUrl += '?pathwayId=coach';
        if (nextContent.pathway_type === 'foundation') nextUrl += '?pathwayId=foundation';
        if (nextContent.pathway_type === 'healthgoal') {
            nextUrl +=  `?pathwayId=${pathway.id}&lifestyleId=${nextContent.categories[0].id}`
        }
    }
    let jfyImage = null;
    if (jfyContent && jfyContent.count > 0 && jfyContent.complete === jfyContent.count){
        jfyImage = { src: checkmark, alt: 'checkmark' };
    }
    if (jfyContent && !jfyContent.subcategories.length) {
        jfyImage = { src: Plus, alt: 'plus sign' };
    }
    return (
        <>
            <ClientHeader type={'transparent'} backUrl={`/member/clientDash/${user.username}`}/>
            <div>
                <div
                    className={`wellness-bg wydBG pathwaysBackground`}
                    style={{backgroundImage: `url(${healthContent ? healthContent.hero_image_url : ''})`,}}>
                    <div className='pathwaysOverlay'/>
                    <div className="container client-dash">
                        <div className="row">
                            <div className="col-xs-12">
                                <div>
                                    <h2>Your Pathway</h2>
                                    {healthContent ? <p>{healthContent.description}</p> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container client-dash tour-pathway-overview">
                        <div className="row">
                            <div>
                                { nextContent ?
                                    <div className="col-xs-12">
                                        <Link to={nextUrl} className="pathway-tile video" data-cy="pathway-tile-video">
                                            <div className='popout'>Up Next</div>
                                            <div className="col-xs-9">
                                                <div className="title">{nextContent.title}</div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                fontSize: '18px',
                                                top: '-2px'}} className="col-xs-3 text-right pathway-icon">
                                                <span className='glyphicon glyphicon-play'/>
                                            </div>
                                        </Link>
                                    </div>
                                    : null
                                }
                                <div className="col-xs-12 col-sm-6">
                                    <ContentLink
                                        className='tour-pathway-foundation'
                                        to={`/member/pathways/${user.username}/pathway?pathwayId=foundations`}
                                        text={'Foundations for Change'}
                                        image={foundationContent && foundationContent.complete === foundationContent.count ? { src: checkmark, alt: 'checkmark' } : null}
                                        tileImage={foundationContent ? foundationContent.tile_image_url : null}
                                        overlay
                                        data-cy="foundation-tile"
                                    >
                                        <div className='fullWidth'>
                                            { foundationContent.count > 0 ?
                                                <ProgressBar className='w:2of4' now={(foundationContent.complete / foundationContent.count)*100}/>
                                                : null
                                            }
                                            <p className='t-t:n'>{foundationContent.complete} of {foundationContent.count}</p>
                                        </div>
                                    </ContentLink>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <ContentLink
                                        className='tour-pathway-tile'
                                        to={`/member/pathways/${user.username}/pathway?pathwayId=${pathway.id}`}
                                        text={pathway.health_goals[0].health_goal_name}
                                        image={healthContent && healthContent.count > 0 && healthContent.complete === healthContent.count ? { src: checkmark, alt: 'checkmark' } : null}
                                        tileImage={healthContent ? healthContent.tile_image_url : null}
                                        overlay
                                        disabled={contentExists === false}
                                        data-cy="health-goal-tile"
                                    >
                                        <div className='fullWidth'>
                                            { contentExists === true ?
                                                <ProgressBar
                                                    className='w:2of4'
                                                    now={(categoryProgress(healthContent).complete / categoryProgress(healthContent).total)*100}/>
                                                : null
                                            }
                                            { contentExists === true ?
                                                <p className='t-t:n'>{categoryProgress(healthContent).complete} of {categoryProgress(healthContent).total}</p>
                                                :
                                                null
                                            }
                                            { contentExists === false ?
                                                <p className='green comingSoon'>Coming Soon!</p> : null
                                            }
                                        </div>
                                    </ContentLink>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <ContentLink
                                        className='tour-jfy-tile'
                                        to={`/member/just_for_you`}
                                        text={jfyContent.category_name}
                                        image={jfyImage}
                                        tileImage={jfyContent.tile_image_url}
                                        overlay
                                        data-cy="jfy-tile"
                                    >
                                        { jfyContent.subcategories.length ?
                                            <div className='fullWidth'>
                                                <ProgressBar
                                                    className='w:2of4'
                                                    now={(categoryProgress(jfyContent).complete / categoryProgress(jfyContent).total)*100}/>
                                                <p className='t-t:n'>{categoryProgress(jfyContent).complete} of {categoryProgress(jfyContent).total}</p>
                                            </div>
                                            :
                                            <div className={styles.noContentLabel}>
                                                <p className='green comingSoon'>Find content that connects with you.</p>
                                                <p>&nbsp;</p>
                                            </div>
                                        }
                                    </ContentLink>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <ContentLink
                                        to={`/member/pathways/${user.username}/pathway?pathwayId=coach`}
                                        text={`Meet Coach ${coach.first_name}`}
                                        image={ !isEmpty(coachContent) && coachContent && coachContent.complete === coachContent.count ? { src: checkmark, alt: 'checkmark' } : null}
                                        tileImage={coachContent ? coachContent.tile_image_url : null}
                                        overlay
                                        disabled={!coachContent || isEmpty(coachContent)}
                                        data-cy="coach-tile"
                                    >
                                        <div className='fullWidth'>
                                            { coachContent.count > 0 ?
                                                <ProgressBar className='w:2of4' now={(coachContent.complete / coachContent.count)*100}/>
                                                : null
                                            }
                                            { isEmpty(coachContent) || !coachContent ?
                                                <p className='green comingSoon'>Coming Soon!</p>
                                                :
                                                <p className='t-t:n'>{coachContent.complete} of {coachContent.count}</p>
                                            }
                                        </div>
                                    </ContentLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-actions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <button className="btn client-white" onClick={()=>props.history.push('/')}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

function mapStateToProps({ user }) {
    return { user };
}

export default withRouter(connect(mapStateToProps)(PathwayContentMain));
