import React, { Component } from 'react'


class PlanSelector extends Component {
  constructor(props){
      super(props);
  }

  renderPlans(){
      if(this.props.plans){
        if(this.props.plans.length < 2){
          return <h3 className="capitalize">{this.props.plans[0].description}</h3>
        }else{
          // eslint-disable-next-line no-unused-vars
            let list =  this.props.plans.map(item=>{
                return ({label:item.description, value:item.id})
            });

            return (
                <select className='form-control' onChange={e => this.props.handleSelectChange(e)} value={this.props.selected}>
                    {this.props.plans.map(item => <option value={item.id}>{item.description}</option>)}
                </select>
            )
      }
  }}

  render(){
    return(
      <div className="col-xs-12 col-md-4 capitalize">
        {this.renderPlans()}
      </div>
    )
  }

}

export default PlanSelector;