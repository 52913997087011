import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';

// styles
import styles from './clientDashMain.module.css';

// actions
import { setClientPathwayList } from "../../../store/actions/pathwaysActions";
import {
    fetchClientTiles,
    setClientTile,
    resetClientTile,
    fetchArchivedClientTiles,
    updateTileStatus
} from "../../../store/actions/tileActions";
import {getDailyScore, setChangedScoreFlag, fetchMonthlyScore, getLifetimeScore} from "../../../store/actions/wellnessScoreActions";
import {checkAlerts} from "../../../store/actions/alertActions";

// components
import ClientHeader from "../../clientHeader/clientHeader";
import WellnessScore from '../clientDetail/WellnessScore';
import ClientTileDashboard from "../../clientTileDashboard/clientTileDashboard";
import PhotoContainer from "../Teammates/container";
import Slider from "react-slick";
import Confetti from 'react-dom-confetti';
import ClientTileLogging from "../../clientTileLogging/clientTileLogging";
import {ReactComponent as RightArrow} from '../../../images/right_arrow.svg';
import {PrimaryLink} from "../../links/links";
import {ReactComponent as TimesIcon} from '../../../images/times-solid.svg';
import {ReactComponent as PlusIcon} from '../../../images/circle-plus_icon.svg';
import {SecondaryInfoAlert} from "../../alerts/alerts";
import {HabitTileIcon} from "../../habitTileIcon/habitTileIcon";

const config = {
  angle: 90,
  spread: 108,
  startVelocity: 90,
  elementCount: 250,
  decay: 0.9,
  colors: [
      '#fff', 
      '#00325c',
      '#7eaf5b',
      '#c4b02d'
  ]
};

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const ClientDashMain = props => {
    // state
    const { user, clientPathwayList, clientTile, tileList, dailyScore } = props;
    // actions
    const { setClientPathwayList, setClientTile, resetClientTile, fetchClientTiles, getDailyScore, setChangedScoreFlag, checkAlerts, fetchMonthlyScore, getLifetimeScore} = props;
    // props
    const { match, history } = props;
    // local
    const [inactiveTiles, setInactiveTiles] = useState([]);
    const [inactivePage, setInactivePage] = useState(1);
    const [inactivePages, setInactivePages] = useState(1);
    const [inactiveError, setInactiveError] = useState('');
    const [activeError, setActiveError] = useState('');
    const [ state, updateState ] = useState({
        username: match.params.username,
        clientDashChild: true,
        show: false,
        kudos:[],
        confetti:false,
        teammateMessage:null,
        confettiOverlay:false,
        pathway: '',
        logShow: false,
        loggingModal: false,
        tileModal: false,
        removalConfirm: null,
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function handleClose() {
      setState({ show: false, confettiOverlay:false });
      resetClientTile();
    }

    function handleShow() {
      setState({ show: true });
      handleConfetti();
    }

    function handleConfetti() {
        setState({ show: true, confettiOverlay:true });
        setState({ confetti: true }, () => {
            setState({ confetti: false });
        });

    }


    function handleConfettiOverlay(){
        if(state.confettiOverlay){
            return(
                <div className="confetti-overlay">
                    <Confetti active={ state.confetti } config={ config }  />
                </div>
            )
        }
    }

    async function archivedTiles() {
        const archived = await fetchArchivedClientTiles(inactivePage)
            .catch(e => console.log(e));
        setInactiveTiles(archived.result);
        setInactivePages(archived.pages);
    }

    function openLogModal(tile) {
        setState({ logShow: true });
        setClientTile({ tile });
    }

    async function closeLogModal(oldScore){
        setState({ logShow: false });
        try {
            await getLifetimeScore();
            await getDailyScore();
            await checkAlerts();
            setChangedScoreFlag(parseInt(oldScore));
            fetchClientTiles();
            fetchMonthlyScore();
            resetClientTile();
        } catch (e) {
            console.log(e);
        }
    }

    function closeInfo() {
        setState({loggingModal: false});
    }

    function closePersonalize() {
        setState({tileModal: false});
        clearErrors();
        setState({removalConfirm: null});
    }

    function increaseInactivePage(){
        if(inactivePage >= inactivePages) return;
        setInactivePage(prevState => prevState+1);
    }

    function decreaseInactivePage(){
        if (inactivePage <= 1) return;
        setInactivePage(prevState => prevState-1);
    }

    function confirmTileRemoval(tile) {
        setState({removalConfirm: tile});
    }

    async function setInactiveTile(tile) {
        setState({removalConfirm: null});
        if (tileList.length <= 2) {
            setActiveError('You must have at least 2 habit tiles');
            return;
        }
        clearErrors();
        await updateTileStatus(tile.tile.id, 3, user.username)
            .catch(e => console.log(e));
        fetchClientTiles();
        await getDailyScore();
        setChangedScoreFlag(dailyScore);
        if (inactivePage === 1) {
            archivedTiles();
        } else {
            setInactivePage(1);
        }
    }

    async function setActiveTile(tile) {
        setState({removalConfirm: null});
        if (tileList.length >= 6) {
            setInactiveError('You may not have more than 6 habit tiles');
            return;
        }
        clearErrors();
        await updateTileStatus(tile.tile.id, 1, user.username)
            .catch(e => console.log(e));
        fetchClientTiles();
        await getDailyScore();
        setChangedScoreFlag(dailyScore);
        if (inactivePage === 1) {
            archivedTiles();
        } else {
            setInactivePage(1);
        }
    }

    function clearErrors() {
        setInactiveError('');
        setActiveError('');
    }

    useEffect(() => {
        setState({username: match.params.username}, () => {
            axios.get(`/rewards/${state.username}`)
                .then((res)=>{
                    if(res.data.results.length > 0){
                        setState({kudos:res.data.results})
                        handleShow();
                    }
                })
                .catch((error)=>{

                });
        });
        setClientPathwayList()
            .catch(e => console.log(e));
    }, []);

    // wait for pathway list to be set
    useEffect(() => {
        if (clientPathwayList.length) setState({ pathway: clientPathwayList[0].id})
    }, [clientPathwayList]);

    // fetch members tile list
    useEffect(() => {
        fetchClientTiles();
    }, []);

    // fetch archived tiles
    useEffect(() => {
        if(inactivePage) archivedTiles();
    }, [inactivePage]);
    return(
        <div className="client-container">
            {/*Confetti*/}
            {handleConfettiOverlay()}

            {/*Header*/}
            <ClientHeader type='clear' backUrl={`/member/clientDash/${user.username}`}/>
            <WellnessScore username={state.username} />

            <div className="pos:r">
                <div className={styles.bulgeContainer}>
                    <div className={styles.concave}/>
                    <div className={styles.bulge}/>
                </div>

                {/*Tiles*/}
                <div className='container client-dash'>
                    <ClientTileDashboard
                        pathwayLink={state.pathway >= 0 ? `/member/clientDash/${user.username}/pathways/${state.pathway}` : null}
                        tiles={tileList}
                        openLog={openLogModal}/>
                </div>
            </div>
            { tileList && tileList.length > 0 ?
                <div className="container client-dash">
                    <button className={styles.btn} onClick={() => setState({loggingModal: true})}>Learn About Logging <RightArrow/></button>
                    <button className={styles.btn} onClick={() => setState({tileModal: true})}>Personalize Your Habit Tiles <RightArrow/></button>
                </div>
                : null
            }

            {/*habit logging modal*/}
            <Modal className={`${styles.loggingModal}`} show={state.logShow} onHide={closeLogModal}>
                { clientTile ?
                    <ClientTileLogging close={closeLogModal}/>
                    : null }
            </Modal>

            {/*Logging info modal*/}
            <Modal
                className={styles.infoContainer}
                show={state.loggingModal}
                onHide={closeInfo}>
                <div className={styles.info}>
                    <h4 className={styles.infoTitle}>Daily Logging in your <br/> Habit Builder</h4>
                    <p className='margin-bottom-25'>Daily activity logging helps establish lasting habits and takes less than 2 minutes. Logging keeps your new habit top of mind, and you can use your Habit Builder for either: </p>
                    <ul className={styles.infoList}>
                        <li>Intention Setting – Log your intended behaviors each morning and update if needed.</li>
                        <li>Tracking – Log your actual actions after completion.</li>
                    </ul>
                    <h5 className={styles.infoQuestions}>Still have questions?</h5>
                    <p className='margin-bottom-15 t-a:c'>Your coach is here for you. Reach out now.</p>
                    <div className={styles.infoBtns}>
                        <PrimaryLink to={`/member/clientDash/${user.username}/inbox-newmessage`}>Send Message</PrimaryLink>
                        <PrimaryLink to={`/member/clientDash/${user.username}/Coaching-Team/coaches`}>Schedule Call</PrimaryLink>
                    </div>
                    <div className="t-a:c">
                        <button onClick={closeInfo} className={styles.infoClose}>Close</button>
                    </div>
                </div>
            </Modal>

            {/*inactive habit tile modal*/}
            <Modal className={styles.tilesContainer} show={state.tileModal} onHide={closePersonalize}>
                <div className={styles.tiles}>
                    <h4 className={styles.title}>Personalize Your Habit Tiles</h4>
                    <h5 className='t-a:c margin-bottom-15'>Remove a Habit Tile</h5>
                    {activeError ? <p className="text-danger margin-bottom-15">{activeError}</p> : null}
                    {tileList && tileList.length ?
                        tileList.map(tile => {
                            const title = tile.plan.length > 1 ? 'Activity' : tile.plan[0].description;
                            const plan = tile.plan[0];
                            return (
                                <div>
                                    <div className={styles.tile}>
                                        <div className='d:f a-i:c flx-g:1'>
                                            <div className={styles.icon}>
                                                <HabitTileIcon tile={plan.description}/>
                                            </div>
                                            <p className={styles.plan}>{title}</p>
                                        </div>
                                        <TimesIcon onClick={()=>{
                                            if (state.removalConfirm && state.removalConfirm.tile.id === tile.tile.id) {
                                                setInactiveTile(tile)
                                            } else {
                                                confirmTileRemoval(tile);
                                            }
                                        }} className={`${styles.actions} ${ state.removalConfirm && state.removalConfirm.tile.id === tile.tile.id ? styles.confirmX : ''}`}/>
                                    </div>
                                    {state.removalConfirm && state.removalConfirm.tile.id === tile.tile.id ?
                                        <p className={`text-danger ${styles.confirm}`}>Press <TimesIcon/> again to confirm removal</p>
                                        : null
                                    }
                                </div>
                            )
                        })
                        :null
                    }
                    <h5 className='t-a:c margin-bottom-15 margin-top-25'>Restore Previous Habit Tile</h5>
                    {inactiveError ? <p className="text-danger margin-bottom-15">{inactiveError}</p> : null}
                    {inactiveTiles.length === 0 ?
                        <SecondaryInfoAlert>No tiles have been retired</SecondaryInfoAlert>:
                        null
                    }
                    {inactiveTiles && inactiveTiles.length ?
                        inactiveTiles.map(tile => {
                            const title = tile.plan.length > 1 ? 'Activity' : tile.plan[0].description;
                            const plan = tile.plan[0];
                            return (
                                <div className={`${styles.tile} ${styles.grey}`}>
                                    <div className='d:f a-i:c flx-g:1'>
                                        <div className={styles.icon}>
                                            <HabitTileIcon tile={plan.description}/>
                                        </div>
                                        <p className={styles.plan}>{title}</p>
                                    </div>
                                    <PlusIcon onClick={()=>setActiveTile(tile)} className={`${styles.actions} ${styles.plus}`}/>
                                </div>
                            )
                        })
                        :null
                    }
                    {inactivePages > 1 ?
                        <div className={styles.chevrons}>
                            <i className={`fas fa-chevron-left ${inactivePage <= 1 ? styles.disabled : ''}`} onClick={decreaseInactivePage}/>
                            <i className={`fas fa-chevron-right ${inactivePage >= inactivePages ? styles.disabled : ''}`} onClick={increaseInactivePage}/>
                        </div>
                        : <div className="margin-bottom-25"/>
                    }
                    <div className={styles.tileQuestions}>
                        <h5 className={styles.title}>Contact a Coach for New Tiles or to Modify your Daily Goals</h5>
                        <div className={styles.btns}>
                            <PrimaryLink to={`/member/clientDash/${user.username}/inbox-newmessage`}>Send Message</PrimaryLink>
                            <PrimaryLink to={`/member/clientDash/${user.username}/Coaching-Team/coaches`}>Schedule Call</PrimaryLink>
                        </div>
                    </div>

                    <div className="t-a:c">
                        <button onClick={closePersonalize} className={styles.tilesClose}>Close</button>
                    </div>
                </div>
            </Modal>
            <div>
                <Modal className="kudos-container" show={state.show} onHide={handleClose}>
                    <div class="exitBtn teammates-exitBtn" onClick={handleClose}><div>X</div></div>
                    <Slider {...settings}>
                        {
                            state.kudos.map((item, i)=>{
                                return(
                                    <div>
                                        <Modal.Header closeButton>
                                            <PhotoContainer
                                                image={item.teammate.profile_picture}
                                            />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <h4 className="capitalize">{item.teammate.first_name} sent you kudos!</h4>
                                            <p className="capitalize">{item.message}</p>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <div className="text-center">
                                                <p>Kudos {i+1} of {state.kudos.length}</p>
                                            </div>
                                        </Modal.Footer>
                                    </div>

                                )
                            })
                        }
                    </Slider>

                </Modal>
            </div>
            <div className="footer-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <button className="btn client-white" onClick={()=>history.push(`/member/clientDash/${state.username}`)}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        clientPathwayList: state.clientPathwayList,
        clientTile: state.clientTile,
        dailyScore: state.dailyScore,
        tileList: state.clientTileList,
    }
}

const actions = {
    setClientPathwayList,
    setClientTile,
    resetClientTile,
    fetchClientTiles,
    getDailyScore,
    setChangedScoreFlag,
    checkAlerts,
    fetchMonthlyScore,
    getLifetimeScore,
}
export default withRouter(connect(mapStateToProps, actions)(ClientDashMain));
