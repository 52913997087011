import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import { Modal } from "react-bootstrap";
import FileDownload from "js-file-download";

// styles
import styles from './clientSearch.module.css';

// actions
import { fetchClients } from "../../store/actions/userActions";
import {fetchBiweeklyExport, fetchClientExport} from "../../store/actions/exportActions";
import { fetchOrganizations } from "../../store/actions/organizationActions";

// components
import { PrimaryButton, SecondaryButton } from "../buttons/buttons";
import ClientSortAndFilter from "../clientSortAndFilter/clientSortAndFilter";
import ClientResult from "./clientResult";
import { GeneralPagination } from "../pagination/generalPagination/generalPagination";
import { PERMISSIONS } from "../../serverVariables/permissions";


const ClientSearch = props => {
    // state
    const { user } = props;

    // local
    const resetRef = useRef();
    const [ state, updateState ] = useState({
        clients: [],
        total: 0,
        pages: 0,
        query: null,
        name: '',
        coach: null,
        coachCheckbox: false,
        page: 1,
        exportModal: false,
        exporting: false,
        organizations: []
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    async function changePage({ selected }) {
        window.scrollTo(0, 0);
        const num = selected + 1;
        const updatedQuery = state.coachCheckbox ? `${state.query}&coach=${state.coach}` : state.query;
        const clients = await fetchClients(num, updatedQuery);
        setState({
            page: num,
            clients: clients.users,
            pages: clients.pages,
            total: clients.count,
        });
    }

    function sortAndFilter(queryString) {
        if (state.name) {
            setState({ query: `${queryString}&filter=name:${state.name}`})
        } else {
            setState({ query: queryString });
        }

    }

    function resetSearch() {
        const coachCheckbox = user.permission_id === PERMISSIONS.COACH;
        setState({
            coachCheckbox,
            query: '',
        });
        resetRef.current.resetEverything();
    }

    function exportClients() {
        setState({ exporting: true, exportModal: true });
        fetchClientExport(user.username)
            .then((response) => {
                setState({ exporting: false, exportModal: false, });
                const date = new Date();
                const day = date.getDate().toString();
                const month = (date.getMonth()+1).toString();
                const year = date.getFullYear().toString();
                const file_name = (`report_${user.last_name}_${month}-${day}-${year}.csv`).trim();
                FileDownload(response, file_name);
            });
    }

    function biweeklyExport() {
        setState({ exporting: true, exportModal: true });
        fetchBiweeklyExport(user.user_id)
            .then((response) => {
                setState({ exporting: false, exportModal: false, });
                const date = new Date();
                const day = date.getDate().toString();
                const month = (date.getMonth()+1).toString();
                const year = date.getFullYear().toString();
                const file_name = (`bi-weekly report ${user.last_name}_${month}-${day}-${year}.xlsx`).trim();
                FileDownload(response, file_name);
            })
            .catch(e => console.log(e));
    }

    useEffect(() => {
        if(!isNil(state.query)) changePage({ selected: 0 });
    }, [ state.query, state.coachCheckbox ]);

    // check if a user is a coach on mount
    useEffect(() => {
        let coach = null;
        let coachCheckbox = false;
        if (user && user.permission_id === PERMISSIONS.COACH) {
            coach = user.username;
            coachCheckbox = true;
        }
        setState({
            coach,
            coachCheckbox,
            query: '',
        });
    }, []);

    useEffect(() => {
        let userId = null;
        if (user && user.permission_id === PERMISSIONS.COACH) {
            userId = user.id;
        }
        fetchOrganizations(userId)
            .then(orgs => setState({ organizations: orgs || [] }))
            .catch(e => console.log(e));
    }, []);

    if (!user) return <div/>;
    return (
        <div>
            <div className={styles.header}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav>
                                <ol className="breadcrumb ">
                                    <li className="breadcrumb-item underline ">
                                        <Link to='/dashboard' className="text-light">Dashboard</Link>
                                    </li>
                                    <span className="arrow"/>
                                    <li className="breadcrumb-item text-light active">Members</li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-sm-12 flex-horizontally">
                            <h1 className="inline-block">Members</h1>
                            { user.permission_id === PERMISSIONS.COACH ?
                                <div>
                                    <PrimaryButton
                                        disabled={state.exporting}
                                        onClick={biweeklyExport}
                                        className={`primary float-right m-l:2 ${styles.exportBtn}`}
                                    >Bi-Weekly Report</PrimaryButton>
                                    <SecondaryButton
                                        disabled={state.exporting}
                                        onClick={exportClients}
                                        className={`primary float-right`}
                                    >Export Members</SecondaryButton>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <form className='row' onSubmit={e => {
                        e.preventDefault();
                        sortAndFilter(state.query);
                    }}>
                        <div className="col-sm-12 col-md-4">
                            <label>Search</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Search by name, username, phone, or email'
                                value={state.name}
                                onChange={e => setState({ name: e.target.value })}/>
                            { user.permission_id === PERMISSIONS.COACH ?
                                <div className='client-search-checkbox'>
                                    <label htmlFor='myClients'>
                                        My Members
                                        <input
                                            id='myClients'
                                            type={'checkbox'}
                                            checked={state.coachCheckbox}
                                            onClick={e => setState({ coachCheckbox: e.target.checked })}/>
                                    </label></div> : null}
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label>&nbsp;</label>
                            <PrimaryButton onClick={() => sortAndFilter(state.query)} className="margin-top-2x m-r:2" data-cy='memberSearchButton'>Search</PrimaryButton>
                            <SecondaryButton
                                className='margin-top-2x'
                                onClick={resetSearch}
                            >Reset</SecondaryButton>
                        </div>
                    </form>
                </div>
            </div>
            <div className={`container ${styles.container}`}>
                <div className="row">
                    <div className="col-xs-12 col-lg-3">
                        <ClientSortAndFilter
                            className={styles.search}
                            ref={resetRef}
                            callback={sortAndFilter}
                            organization={state.organizations}/>
                    </div>
                    <div className="col-xs-12 col-lg-9" data-cy='membersList'>
                        <p className={styles.count}>{state.total || 0} members found</p>
                        {state.clients.map(client => <ClientResult client={client} data-cy='member'/>)}

                        <div className="pagination-container" >
                            <GeneralPagination pages={state.pages} onPageChange={changePage}/>
                        </div>
                    </div>
                </div>
            </div>

            <Modal backdrop={'static'} show={state.exportModal} onHide={() => setState({ exportModal: false })}>
                <Modal.Body>
                    <h3 className='text-danger'>Exporting Members. Please do not navigate away from page.</h3>
                </Modal.Body>
            </Modal>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

const actions = {};

export default connect(mapStateToProps, actions)(ClientSearch);