import axios from 'axios';

//get all the data in a single message
export const SET_MESSAGE = 'SET_MESSAGE';
export function setMessage(messageObj){
    return{
        type: SET_MESSAGE,
        payload:messageObj
    };
}

//fetches message based on id
export const FETCH_MESSAGE_BY_ID = 'FETCH_MESSAGE_BY_ID';
export function fetchMessageById(id, username){
  console.log('fetching message with id');
  let url = `/inbox/${username}/messages/detail/:id`;
  let messageByIdRequest = axios.get(url);

  return {
    type: FETCH_MESSAGE_BY_ID,
    payload: messageByIdRequest
  }
}

//fetches all messages based on username
export const FETCH_MESSAGES_BY_USERNAME = 'FETCH_MESSAGES_BY_USERNAME';
export function fetchMessagesByUsername(username){
  console.log('fetching messages with username');

  let url = `/inbox/${username}/1`;
  let messagesByUsernameRequest = axios.get(url);

  return {
    type: FETCH_MESSAGES_BY_USERNAME,
    payload: messagesByUsernameRequest
  }
}
