// npm modules
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

// styles
import './assessmentsViewContainer.css';

// actions
import {fetchTakenAssessment, fetchAssessmentList, fetchAnswer, fetchAssessmentReminder} from "../../store/actions/assessmentActions";

// components
import CoachAssessment from "../takenAssessment/coachView/coachAssessment";
import Loading from "../Loading/Loading";
import { PrimaryButton } from '../buttons/buttons';

const AssessmentContainer = ({ singleUser }) => {
	// hold array of assessment objects
	const [list, setList ] = useState([]);
	const [selected, setSelected ] = useState('');
	const [assessment, setAssessment] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [btnText, setBtnText] = useState("Send Assessment Reminder")

	useEffect(() => {
		fetchAssessments();
	}, []);


	useEffect(() => {
		if (selected) fetchSingleAssessment(selected);
	}, [selected]);

	// grab user assessments
	async function fetchAssessments() {
		try {
			const list = await fetchAssessmentList(singleUser.user.id);
			setList(list);
		} catch(e) {
			console.log(e);
		}
	}



	const handleReminder = ( )=> {
		setBtnText('loading')
		const res = fetchAssessmentReminder(singleUser.user.id)
		setBtnText('reminder sent')
	}

	// grab single assessment
	async function fetchSingleAssessment(pathwayId) {
		try {
			setLoading(true);
			const response = await fetchTakenAssessment(pathwayId, singleUser.user.id);
			let questions = [];
			let updatedAssessment = {};
			if (response.assessment.elements) {
				updatedAssessment.assessment_completed = response.assessment.assignment.taken
				updatedAssessment.healthgoal = response.healthgoal;
				// grab all the questions
				questions = response.assessment.elements.filter(el => parseInt(el.typeId) === 8);
				// grab answers for each question
				questions = await Promise.all(questions.map(q => {
					const questionWithAnswer = {label: q.LIST.label};
					return fetchAnswer(q.LIST.id)
						.then(a => {
							questionWithAnswer.answers = [{selected: true, label: a.value}];
							return questionWithAnswer;
						});
				}));
			} else {
				updatedAssessment = {...response};
				Object.keys(response.assessment).forEach(key => {
					if (Array.isArray(response.assessment[key])){
						response.assessment[key].forEach(item => questions.push(item));
					}
				});
			}
			setQuestions(questions);
			setAssessment(updatedAssessment);
		} catch(e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	}

    return (
        <div className="container margin-top">
			<h2>Assessments</h2>
			<form>
				<div className="form-group">
					<select className='form-control' value={selected} onChange={e => setSelected(e.target.value)}>
						<option value=''>Select an assessment</option>
						{list.map(assessment => <option key={assessment.pathway_id} value={assessment.pathway_id}>{format(parseISO(assessment.assigned_date), 'MM/dd/yyyy')}</option>)}
					</select>
				</div>
			</form>
			{ loading ?
				<div className="margin-standard">
					<Loading/>
				</div>
				:
				<div>{ assessment ? <CoachAssessment assessment={assessment} questions={questions}/> : null}</div>
			}


{questions.length > 0? null :   


<PrimaryButton onClick={handleReminder}>{btnText}</PrimaryButton>
 }


		</div>

    )
};

const mapStateToProps = ({ singleUser }) => {
    return {
        singleUser,
    }
};

export default connect(mapStateToProps)(AssessmentContainer);
