export const HEALTH_GOALS = {
    LASTING_WEIGHT_LOSS: 1,
    STRONGER_LEANER_BODY: 2,
    IMPROVE_HEART_COGNITIVE_HEALTH: 3,
    MORE_ENERGY_LESS_STRESS: 4,
    PHYSICAL_ACTIVITY_HABIT: 5,
    HEALTHIER_FOOD_CHOICES: 6,
    IMPROVE_ENDURANCE: 7,
    IMPROVE_SPEED_AND_STRENGTH: 8,
    BETTER_BLOOD_PRESSURE: 9,
    BETTER_BLOOD_SUGAR: 10,
};