import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {Alert, Popover, OverlayTrigger, Glyphicon} from "react-bootstrap";
import {connect} from 'react-redux';

// styles
import "./Account.css";
import styles from './notifications.module.css';

// actions
import {fetchEmailList, setEmailList} from "../../../store/actions/userActions";

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {PrimaryButton} from "../../buttons/buttons";

let userData;

class Notifications extends React.Component {
    constructor(){
        super();
        this.state={
            firstName: '',
            lastName: '',
            email: '',
            show: false,
            alertStyle: true,
            alert: '',
            video_call_method:'',
            video_call_methodId:'',
            video_call_address: '',
            contactFreqId: '',
            contactFreqArr: [],
            phone: null,
            alt_phone: null,
            alt_email: '',
            birthday:'',
            profile_image:'',
            platformId:[],
            commPrefArr:[],
            commPref: '',
            commPrefId: '',
            contactFreq: '',
            newPassword:'',
            encodedPassword:'',
            imgUploaded:true,
            error:'',
            subscription_type : null,
            expiration : null,
            weekly_updates: false,
            carrier:false,
            carrier_error:false,
            notification_setting: false,
            timezone: '',
            // notification settings
            textReminderFrequency: null,
            textReminderTime: null,
            startDate: null,
            emailList: [],
        };
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    async getInfo() {
        try {
            const res = await axios.get(`/user/client/single/${this.props.username}`)
            userData = res.data.user;
            let alteredTime = null;
            if (userData.text_notification_time) {
                const tnt = moment(userData.text_notification_time).utc();
                let hour = moment(tnt).utc().hour();
                if (hour < 10) hour = `0${hour}`;
                alteredTime = `${hour}:00:00`;
            }
            let emailList;
            if (this.props.singleUser) {
                emailList = await fetchEmailList(this.props.singleUser.user.id);
            } else {
                emailList = await fetchEmailList();
            }
            this.setState({
                firstName: userData.first_name,
                lastName: userData.last_name,
                email: userData.email,
                video_call_method: userData.video_call_method,
                video_call_address: userData.video_call_address,
                commPref: userData.contact_method,
                contactFreq: userData.contact_frequency,
                phone: userData.phone,
                profile_image: userData.profile_image,
                expiration: userData.expiration,
                subscription_type: userData.subscription_type,
                weekly_updates: userData.updates,
                carrier: !!userData.carrier,
                notification_setting: userData.notification_setting,
                timezone: userData.timezone,
                alt_phone: userData.alt_phone,
                alt_email: userData.alt_email,
                textReminderFrequency: userData.text_notification_type,
                textReminderTime: alteredTime,
                startDate: userData.start_date,
                emailList,
            });

            await axios.get(`/user/contactFrequency`).then(res => {
                this.setState({ contactFreqArr: res.data.results });
            });
        } catch (e) {
            console.log(e);
        }
    }

    componentDidMount() {
        this.getInfo();
    }

    handleTextMessage = event => {
        if (event.target.value === "true") {
            //make sure a phone is present
            if (!this.state.phone || this.state.phone.toString().length < 10) {
                return this.setState({
                    carrier_error:
                        "Please enter valid phone number to enable text messaging"
                });
            }
        }

        this.setState({ carrier: event.target.value });
    };

    goBack() {
        window.history.back();
    }

    handleDismiss() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    async accountUpdate(e) {
        e.preventDefault();
        this.setState({ show: false });
        let query_string = "";

        if (this.state.commPrefId) {
            query_string = query_string + `contact_method=${this.state.commPrefId}&`;
        }
        if (this.state.contactFreqId) {
            query_string =
                query_string + `contact_frequency=${this.state.contactFreqId}&`;
        }

        if (this.state.newPassword) {
            query_string = query_string + `password=${this.state.newPassword}&`;
        }
        if (this.state.weekly_updates) {
            query_string = query_string + `updates=${this.state.weekly_updates}&`;
        }
        if (this.state.timezone) query_string +=`&timezone=${this.state.timezone}&`;

        query_string += `carrier=${(!!this.state.textReminderFrequency)}&`;
        if (this.state.textReminderTime) {
            const hour = this.state.textReminderTime.substr(0,2);
            query_string += `text_notification_time=${moment().utc().hour(hour).format()}&`;
        }
        query_string += `text_notification_type=${this.state.textReminderFrequency !== 'null' ? this.state.textReminderFrequency : ''}&`;
        if (this.state.notification_setting) query_string += `notification_setting=${this.state.notification_setting}`;

        try {
            await axios.post(`/user/${this.props.username}/client-detail/add?${query_string}`);
            await Promise.all(this.state.emailList.map(pref => setEmailList(pref.preference_id, pref.isOptedIn)));
            await this.getInfo();
            this.setState({ show: true });
            this.setState({ alert: "success" });
            this.setState({ alertStyle: true });
            this.setState({ formChanged: false });
        } catch(e) {
            this.setState({ show: true });
            this.setState({ alert: "error" });
            this.setState({ alertStyle: false });
        }
    }


    errorHandler = error => {
        this.setState({ error: error });
        this.setState({ show: true });
        this.setState({ alert: error });
        this.setState({ alertStyle: false });
    };

    saveChanges() {
        if (!this.state.imgUploaded) {
            return (
                <input
                    disabled
                    className="btn primary"
                    type="submit"
                    value="UPLOADING"
                />
            );
        } else if (this.state.error) {
            return (
                <PrimaryButton className="btn primary" type="submit">SAVE</PrimaryButton>
            );
        } else {
            return (
                <PrimaryButton className="btn primary" type="submit">SAVE</PrimaryButton>
            );
        }
    }

    renderTimezoneSelect=()=>{
        let timezone = this.state.timezone;
        let options =  [
            {
                "offset": null,
                "name": "Choose Local Timezone"
            },
            {
                "offset": "GMT-12:00",
                "name": "Etc/GMT-12"
            },
            {
                "offset": "GMT-11:00",
                "name": "Etc/GMT-11"
            },
            {
                "offset": "GMT-11:00",
                "name": "Pacific/Midway"
            },
            {
                "offset": "GMT-10:00",
                "name": "America/Adak"
            },
            {
                "offset": "GMT-09:00",
                "name": "America/Anchorage"
            },
            {
                "offset": "GMT-09:00",
                "name": "Pacific/Gambier"
            },
            {
                "offset": "GMT-08:00",
                "name": "America/Dawson_Creek"
            },
            {
                "offset": "GMT-08:00",
                "name": "America/Ensenada"
            },
            {
                "offset": "GMT-08:00",
                "name": "America/Los_Angeles"
            },
            {
                "offset": "GMT-07:00",
                "name": "America/Chihuahua"
            },
            {
                "offset": "GMT-07:00",
                "name": "America/Denver"
            },
            {
                "offset": "GMT-06:00",
                "name": "America/Belize"
            },
            {
                "offset": "GMT-06:00",
                "name": "America/Cancun"
            },
            {
                "offset": "GMT-06:00",
                "name": "America/Chicago"
            },
            {
                "offset": "GMT-06:00",
                "name": "Chile/EasterIsland"
            },
            {
                "offset": "GMT-05:00",
                "name": "America/Bogota"
            },
            {
                "offset": "GMT-05:00",
                "name": "America/Havana"
            },
            {
                "offset": "GMT-05:00",
                "name": "America/New_York"
            },
            {
                "offset": "GMT-04:30",
                "name": "America/Caracas"
            },
            {
                "offset": "GMT-04:00",
                "name": "America/Campo_Grande"
            },
            {
                "offset": "GMT-04:00",
                "name": "America/Glace_Bay"
            },
            {
                "offset": "GMT-04:00",
                "name": "America/Goose_Bay"
            },
            {
                "offset": "GMT-04:00",
                "name": "America/Santiago"
            },
            {
                "offset": "GMT-04:00",
                "name": "America/La_Paz"
            },
            {
                "offset": "GMT-03:00",
                "name": "America/Argentina/Buenos_Aires"
            },
            {
                "offset": "GMT-03:00",
                "name": "America/Montevideo"
            },
            {
                "offset": "GMT-03:00",
                "name": "America/Araguaina"
            },
            {
                "offset": "GMT-03:00",
                "name": "America/Godthab"
            },
            {
                "offset": "GMT-03:00",
                "name": "America/Miquelon"
            },
            {
                "offset": "GMT-03:00",
                "name": "America/Sao_Paulo"
            },
            {
                "offset": "GMT-03:30",
                "name": "America/St_Johns"
            },
            {
                "offset": "GMT-02:00",
                "name": "America/Noronha"
            },
            {
                "offset": "GMT-01:00",
                "name": "Atlantic/Cape_Verde"
            },
            {
                "offset": "GMT",
                "name": "Europe/Belfast"
            },
            {
                "offset": "GMT",
                "name": "Africa/Abidjan"
            },
            {
                "offset": "GMT",
                "name": "Europe/Dublin"
            },
            {
                "offset": "GMT",
                "name": "Europe/Lisbon"
            },
            {
                "offset": "GMT",
                "name": "Europe/London"
            },
            {
                "offset": "UTC",
                "name": "UTC"
            },
            {
                "offset": "GMT+01:00",
                "name": "Africa/Algiers"
            },
            {
                "offset": "GMT+01:00",
                "name": "Africa/Windhoek"
            },
            {
                "offset": "GMT+01:00",
                "name": "Atlantic/Azores"
            },
            {
                "offset": "GMT+01:00",
                "name": "Atlantic/Stanley"
            },
            {
                "offset": "GMT+01:00",
                "name": "Europe/Amsterdam"
            },
            {
                "offset": "GMT+01:00",
                "name": "Europe/Belgrade"
            },
            {
                "offset": "GMT+01:00",
                "name": "Europe/Brussels"
            },
            {
                "offset": "GMT+02:00",
                "name": "Africa/Cairo"
            },
            {
                "offset": "GMT+02:00",
                "name": "Africa/Blantyre"
            },
            {
                "offset": "GMT+02:00",
                "name": "Asia/Beirut"
            },
            {
                "offset": "GMT+02:00",
                "name": "Asia/Damascus"
            },
            {
                "offset": "GMT+02:00",
                "name": "Asia/Gaza"
            },
            {
                "offset": "GMT+02:00",
                "name": "Asia/Jerusalem"
            },
            {
                "offset": "GMT+03:00",
                "name": "Africa/Addis_Ababa"
            },
            {
                "offset": "GMT+03:00",
                "name": "Asia/Riyadh89"
            },
            {
                "offset": "GMT+03:00",
                "name": "Europe/Minsk"
            },
            {
                "offset": "GMT+03:30",
                "name": "Asia/Tehran"
            },
            {
                "offset": "GMT+04:00",
                "name": "Asia/Dubai"
            },
            {
                "offset": "GMT+04:00",
                "name": "Asia/Yerevan"
            },
            {
                "offset": "GMT+04:00",
                "name": "Europe/Moscow"
            },
            {
                "offset": "GMT+04:30",
                "name": "Asia/Kabul"
            },
            {
                "offset": "GMT+05:00",
                "name": "Asia/Tashkent"
            },
            {
                "offset": "GMT+05:30",
                "name": "Asia/Kolkata"
            },
            {
                "offset": "GMT+05:45",
                "name": "Asia/Katmandu"
            },
            {
                "offset": "GMT+06:00",
                "name": "Asia/Dhaka"
            },
            {
                "offset": "GMT+06:00",
                "name": "Asia/Yekaterinburg"
            },
            {
                "offset": "GMT+06:30",
                "name": "Asia/Rangoon"
            },
            {
                "offset": "GMT+07:00",
                "name": "Asia/Bangkok"
            },
            {
                "offset": "GMT+07:00",
                "name": "Asia/Novosibirsk"
            },
            {
                "offset": "GMT+08:00",
                "name": "Etc/GMT+8"
            },
            {
                "offset": "GMT+08:00",
                "name": "Asia/Hong_Kong"
            },
            {
                "offset": "GMT+08:00",
                "name": "Asia/Krasnoyarsk"
            },
            {
                "offset": "GMT+08:00",
                "name": "Australia/Perth"
            },
            {
                "offset": "GMT+08:45",
                "name": "Australia/Eucla"
            },
            {
                "offset": "GMT+09:00",
                "name": "Asia/Irkutsk"
            },
            {
                "offset": "GMT+09:00",
                "name": "Asia/Seoul"
            },
            {
                "offset": "GMT+09:00",
                "name": "Asia/Tokyo"
            },
            {
                "offset": "GMT+09:30",
                "name": "Australia/Adelaide"
            },
            {
                "offset": "GMT+09:30",
                "name": "Australia/Darwin"
            },
            {
                "offset": "GMT+09:30",
                "name": "Pacific/Marquesas"
            },
            {
                "offset": "GMT+10:00",
                "name": "Etc/GMT+10"
            },
            {
                "offset": "GMT+10:00",
                "name": "Australia/Brisbane"
            },
            {
                "offset": "GMT+10:00",
                "name": "Australia/Hobart"
            },
            {
                "offset": "GMT+10:00",
                "name": "Asia/Yakutsk"
            },
            {
                "offset": "GMT+10:30",
                "name": "Australia/Lord_Howe"
            },
            {
                "offset": "GMT+11:00",
                "name": "Asia/Vladivostok"
            },
            {
                "offset": "GMT+11:30",
                "name": "Pacific/Norfolk"
            },
            {
                "offset": "GMT+12:00",
                "name": "Etc/GMT+12"
            },
            {
                "offset": "GMT+12:00",
                "name": "Asia/Anadyr"
            },
            {
                "offset": "GMT+12:00",
                "name": "Asia/Magadan"
            },
            {
                "offset": "GMT+12:00",
                "name": "Pacific/Auckland"
            },
            {
                "offset": "GMT+12:45",
                "name": "Pacific/Chatham"
            },
            {
                "offset": "GMT+13:00",
                "name": "Pacific/Tongatapu"
            },
            {
                "offset": "GMT+14:00",
                "name": "Pacific/Kiritimati"
            }
        ];

        return options.map((option, i)=>{
            if (i === 0 && timezone === '') return <option value={''} selected disabled>{option.name}</option>;
            let selected = false;
            if(option.name === timezone) selected = true;
            return <option value={option.name} selected={selected}>{option.name} - {option.offset}</option>
        });

    };


    TimePopover(){
        return (
            <Popover id="popover-positioned-left">
                All times are based on Central Standard Time
            </Popover>
        )
    }

    calcWeeks = () => {
        if (moment(this.state.startDate).isValid()) {
            const duration = moment.duration(moment().diff(this.state.startDate));
            const weeks = duration.asWeeks();
            return <span>{Math.floor(weeks)} weeks</span>
        } else {
            return <span>n/a</span>
        }
    }

    toggleResourcePreference = (pref, bool) => {
        const updated = this.state.emailList.map(item => {
            if (pref.preference_id === item.preference_id) {
                return ({
                    ...item,
                    isOptedIn: bool
                })
            }
            return item;
        });
        this.setState({emailList: updated});
    }

    render() {
        let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
        const timeArray = [];
        let time = moment().startOf("day");
        while (time.isBefore(moment().endOf("day"))) {
            timeArray.push({
                item: moment(time).format("h A"),
                value: moment(time).utc().format("HH:mm:ss"),
            });
            time = moment(time).add(1, "hour");
        }
        return (
            <BoxContainer className='padding-standard'>
                <form onSubmit={e => this.accountUpdate(e)}>
                    <div>
                        <div className="row">
                            <div className="col-xs-6 col-sm-3">
                                <div className="form-group">
                                    <label className="method-label">Weekly Email Updates</label>

                                    <select
                                        className="form-control"
                                        value={this.state.weekly_updates}
                                        onChange={event =>
                                            this.setState({ weekly_updates: event.target.value })
                                        }
                                    >
                                        <option value="true">Receive Updates</option>
                                        <option value="false">Opt Out</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3">
                                <div className="form-group">
                                    <label className="method-label">
                                        Message Notification Emails
                                    </label>
                                    <select
                                        className="form-control"
                                        value={this.state.notification_setting}
                                        onChange={e =>
                                            this.setState({ notification_setting: e.target.value })
                                        }
                                    >
                                        <option value="true">Get Notifications</option>
                                        <option value="false">Opt Out</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xs-12">
                                <h4>Text Notifications</h4>
                            </div>
                            <div className="col-xs-12 col-sm-3">
                                <div className="form-group">
                                    <label className="method-label">Reminder Frequency</label>
                                    <select
                                        className="form-control"
                                        value={this.state.carrier || this.state.textReminderFrequency !== '' ? this.state.textReminderFrequency : ''}
                                        onChange={e =>
                                            this.setState({ textReminderFrequency: e.target.value })
                                        }
                                    >
                                        <option value="">Do not send reminders</option>
                                        <option value="daily">Always send reminders each day</option>
                                        <option value="activity">Only send reminders when I don’t log in for 1 day</option>
                                    </select>
                                </div>
                            </div>

                            { this.state.textReminderFrequency ?
                                <div className="col-xs-12 col-sm-3">
                                    <div className="form-group">
                                        <label className="method-label">
                                            Reminder Time
                                            <OverlayTrigger trigger="click" rootClose placement="left" overlay={this.TimePopover()}>
                          <span className='p-x:.5'>
                              <Glyphicon className='treoGlyph' glyph="glyphicon glyphicon-question-sign" />
                          </span>
                                            </OverlayTrigger>
                                        </label>
                                        <select
                                            className="form-control"
                                            value={this.state.textReminderTime}
                                            onChange={e =>
                                                this.setState({ textReminderTime: e.target.value })
                                            }
                                        >
                                            <option value="" disabled selected>
                                                Select Time
                                            </option>
                                            {timeArray.map(time => {
                                                return <option value={time.value}>{time.item}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>
                                : null
                            }
                            <div className="col-xs-6 col-sm-3">
                                <div className='form-group'>
                                    <label>Time Zone</label>
                                    <select className='form-control' onChange={e=>this.setState({timezone : e.target.value})}>
                                        {this.renderTimezoneSelect()}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {this.state.emailList.length ?
                            <div className="row">
                                <div className="col-xs-12">
                                    <h4>Email Campaign Preferences</h4>
                                    <p>Choose which email updates you want to receive</p>
                                    { this.state.emailList.map(pref => (
                                        <div className={styles.resources}>
                                            <input
                                                onChange={e=>this.toggleResourcePreference(pref, e.target.checked)}
                                                checked={pref.isOptedIn}
                                                type="checkbox"
                                                id={`pref_${pref.preference_id}`}/>
                                            <label htmlFor={`pref_${pref.preference_id}`}>{pref.name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            : null
                        }
                        {this.state.show ? (
                            <Alert
                                className="save-profile-alert"
                                bsStyle={changeAlertStyle}
                                onDismiss={this.handleDismiss}
                            >
                                <p>{this.state.alert}</p>
                            </Alert>
                        ) : null}
                        <div className="d:f j-c:f-e">{this.saveChanges()}</div>
                    </div>
                </form>
            </BoxContainer>
        );
    }
}

function mapStateToProps({singleUser}){
    return {singleUser};
}
export default withRouter(connect(mapStateToProps)(Notifications));


