import { exportApi } from "../../api/exportApi";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// call the server for a excel file of exported member information
export async function fetchClientExport(username) {
    const { data } = await exportApi().getClientExport(username);
    return data;
}

// call the server for an excel workbook file of a bi weekly report
export async function fetchBiweeklyExport(userId) {
    const {data} = await exportApi().getBiweeklyExport(userId);
    return data;
}