import React, { useEffect, useState } from "react";

// components
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { BoxContainer } from "../../../../containers/boxContainer/boxContainer";
import { ToggleButtons } from "../../../../buttons/buttons";
import { COLORS } from "../../../contentAnalytics/colors";
import Loading from "../../../../Loading/Loading";
import parse from "date-fns/parse";
import format from "date-fns/format";

// styles
import styles from "./WotdLineChart.module.css";

// data source
import {
  fetchDailyContentWotdAverage,
  fetchDailyWotdCategoryAverage,
  fetchDailyWotdCategoryViews,
  fetchDailyWotdCategoryWatchTime,
  fetchDailyWotdContentViews,
  fetchDailyWotdContentWatchTime,
  fetchWotdCategoryAvgDurations,
  fetchWotdCategoryAvgWatchTime,
  fetchWotdCategoryViews,
  fetchWotdContentAvgDurations,
  fetchWotdContentViews,
  fetchWotdContentWatchTimes,
} from "../../../../../store/actions/analyticsActions";
import { useMemo } from "react";

const WotdLineChart = (props) => {
  // props
  const { query, version, toggle , setToggle } = props;

  const VIEWS = "VIEWS";
  const DURATION = "DURATION";
  const WATCHTIME = "WATCHTIME";
  // local
  const [viewsSelection, setViewsSelection] = useState(VIEWS);
  const [data, setData] = useState(data);
  const [dataKeys, setDataKeys] = useState("views");
  const [gotKey, setGotKey] = useState([]);
  const [initialKeyLoad, setInitialKeyLoad] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dataAsArray.length > 0) {
      setInitialKeyLoad(gotKey.slice(1, 3) || []);
    }
  }, [dataAsArray, gotKey]);

  useEffect(() => {
    setIsLoading(true);
    if (toggle === "category") {
      getCategoryViews();
      toggleViewsData(VIEWS);
    } else if (toggle === "content") {
      toggleViewsData(VIEWS);
      setDataKeys("views");
      getContentViews();
    }
  }, [toggle, query]);

  const handleMouseClick = (event, item) => {
    const isSelected = initialKeyLoad.includes(item);
    if (isSelected) {
      const updatedItems = initialKeyLoad.filter((key) => key !== item);
      setInitialKeyLoad(updatedItems);
    } else {
      if (!initialKeyLoad.includes(item)) {
        setInitialKeyLoad([...initialKeyLoad, item]); // Add the selected item if not already in initialKeyLoad and there are less than 2 checked items
      }
    }
  };

  function toggleViewsData(val) {
    setViewsSelection(val);
  }

  //-----------------getContentData------------------------//
  const getContentViews = async () => {
    if(version === 'v2' ){
      await fetchDataAndProcessData(fetchDailyWotdContentViews, query, (item) => item);
    } else {
      await fetchDataAndProcessData(fetchWotdContentViews, query, (item) => item);

    }
  };

  const getContentWatchTime = async () => {
    if(version === 'v2' ){
      await fetchDataAndProcessData(
        fetchDailyWotdContentWatchTime,
        query,
        (point) => ({
          date: point.date,
          minutes: Math.ceil(point.total_duration / 60),
        })
      )
    } else {
      await fetchDataAndProcessData(
        fetchWotdContentWatchTimes,
        query,
        (point) => ({
          date: point.date,
          minutes: Math.ceil(point.total_duration / 60),
        })
      );
    }
  };



  const getContentDuration = async () => {
    if(version === 'v2' ){
      await fetchDataAndProcessData(
        fetchDailyContentWotdAverage,
        query,
        (point) => ({
          date: point.date,
          minutes: Math.ceil(point.average_duration / 60),
        })
      );
    } else {
      await fetchDataAndProcessData(
        fetchWotdContentAvgDurations,
        query,
        (point) => ({
          date: point.date,
          minutes: Math.ceil(point.average_duration / 60),
        })
      );
    }

  };

  //-----------------getCategoryData------------------------//
  const getCategoryViews = async () => {

    if(version === 'v2' )
    {
      await fetchDataAndProcessData(
        fetchDailyWotdCategoryViews,
        query,
        (item) => item
      );
    } else {
      await fetchDataAndProcessData(
        fetchWotdCategoryViews,
        query,
        (item) => item
      );
    }

  };

  const getCategoryWatchTime = async () => {



    if(version === 'v2' ) {
      await fetchDataAndProcessData(
        fetchDailyWotdCategoryWatchTime,
        query,
        (item) => {
          const updated = { date: item.date };
          delete item.date;
          Object.keys(item).forEach(
            (key) => (updated[key] = Math.ceil(item[key]))
          );
          return updated;
        }
      );
    } else {
      await fetchDataAndProcessData(
        fetchWotdCategoryAvgWatchTime,
        query,
        (item) => {
          const updated = { date: item.date };
          delete item.date;
          Object.keys(item).forEach(
            (key) => (updated[key] = Math.ceil(item[key]))
          );
          return updated;
        }
      );
    }

  };

  const getCategoryDuration = async () => {
    if(version === 'v2' ) {
      await fetchDataAndProcessData(
        fetchDailyWotdCategoryAverage,
        query,
        (item) => {
          const updated = { date: item.date };
          delete item.date;
          Object.keys(item).forEach(
            (key) => (updated[key] = Math.ceil(item[key] / 60))
          );
          return updated;
        }
      );
    } else {
      await fetchDataAndProcessData(
        fetchWotdCategoryAvgDurations,
        query,
        (item) => {
          const updated = { date: item.date };
          delete item.date;
          Object.keys(item).forEach(
            (key) => (updated[key] = Math.ceil(item[key] / 60))
          );
          return updated;
        }
      );
    }

  };

  //-----------------Helper Functions------------------------//
  async function fetchDataAndProcessData(
    fetchFunction,
    query,
    transformFunction
  ) {
    try {
      let data = await fetchFunction(query);
      data = data.map(transformFunction);
      setData(data);
      const keys = Object.keys(data[0]);
      setGotKey(keys);
      setIsLoading(false);
      return data;
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  const dateFormatter = (date) => {
    const result = parse(date, "yyyy-MM-dd", new Date()).getTime();
    return format(result, "MM/dd/yy");
  };

  // converts category data to array and gets total counts of each key
  const convertObjectToArrayAndGetCounts = (data) => {
    console.log(data);

    const totalCounts = {};
    for (const key in data) {
      const item = data[key];
      for (const subKey in item) {
        if (subKey !== "date") {
          totalCounts[subKey] = (totalCounts[subKey] || 0) + item[subKey];
        }
      }
    }
    const dataAsArray = Object.entries(totalCounts).map(([key, value]) => ({
      key,
      value,
    }));
    return { dataAsArray, totalCounts };
  };

  const { dataAsArray } = useMemo(() => {
    console.log(toggle, viewsSelection);
    return convertObjectToArrayAndGetCounts(data);
  }, [data]);

  const options = [
    {
      label: "Content",
      value: "Content",
      onClick: () => {
        setToggle("content");
      },
    },
    {
      label: "Category",
      value: "Category",
      onClick: () => {
        setToggle("category");
      },
    },
  ];

  return (
    <BoxContainer className={styles.container} data-cy='wotd-analytics-card'>
      <div className="margin-bottom-15">
        <span
          onClick={() => {
            if (toggle === "content") {
              setIsLoading(true);

              toggleViewsData(VIEWS);
              setDataKeys("views");
              getContentViews();
            } else if (toggle === "category") {
              setIsLoading(true);

              toggleViewsData(VIEWS);
              setDataKeys("views");
              getCategoryViews();
            }
          }}
          className={`${styles.tab} ${
            viewsSelection === VIEWS ? styles.active : ""
          }`}
        >
          Views
        </span>
        <span
          onClick={() => {
            setIsLoading(true);
            if (toggle === "content") {
              toggleViewsData(WATCHTIME);
              getContentWatchTime();
              setDataKeys("minutes");
            } else if (toggle === "category") {
              toggleViewsData(WATCHTIME);
              getCategoryWatchTime();
              setDataKeys("minutes");
            }
          }}
          className={`${styles.tab} ${
            viewsSelection === WATCHTIME ? styles.active : ""
          }`}
        >
          Watch Time
        </span>
        <span
          onClick={() => {
            setIsLoading(true);
            if (toggle === "content") {
              toggleViewsData(DURATION);
              getContentDuration();
              setDataKeys("minutes");
            } else if (toggle === "category") {
              toggleViewsData(DURATION);
              getCategoryDuration();
              setDataKeys("minutes");
            }
          }}
          className={`${styles.tab} ${
            viewsSelection === DURATION ? styles.active : ""
          }`}
        >
          Avg. Duration
        </span>
      </div>
      <ToggleButtons
        inactiveColor="#fff"
        activeColor="#272727"
        className={styles.toggle}
        options={options}
      />
      <h4 classNAme={styles.chartTitle}> Workout of the Day </h4>
      <ResponsiveContainer width="95%" height={300} debounce={1}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <LineChart
            data={data}
            margin={{
              top: 15,
            }}
          >
            <XAxis dataKey="date" tickFormatter={dateFormatter} />
            <YAxis
              label={{
                value: dataKeys,
                angle: -90,
                offset: -20,
                position: "left",
                style: {
                  fontSize: "14px",
                },
              }}
              tick={{
                style: {
                  fontSize: "12px",
                  fill: "#666666",
                },
              }}
            />{" "}
            <Tooltip />
            <Legend />
            {toggle === "content" && (
              <Line type="monotone" dataKey={dataKeys} stroke="#82ca9d" />
            )}
            {toggle === "category" &&
              initialKeyLoad.length > 0 &&
              initialKeyLoad.map((key, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  stroke={COLORS[index]}
                />
              ))}
          </LineChart>
        )}
      </ResponsiveContainer>

      {toggle === "category" && !isLoading ? (
        <div className={styles.categories}>
          <table id="table" className="table content-table">
            <thead>
              <tr>
                <th scope="col">CATEGORY</th>
                <th scope="col">{dataKeys.toUpperCase()}</th>
              </tr>
            </thead>
            <tbody style={{ boxShadow: "none" }}>
              {dataAsArray.map((item, i) => (
                <tr key={`cat_${item.key}`}>
                  <td>
                    <input
                      id={`cat_${item}${i}`}
                      type="checkbox"
                      onChange={(event) => handleMouseClick(event, item.key)}
                      checked={initialKeyLoad.includes(item.key)}
                    />
                    <label htmlFor={`cat_${item.key}${i}`}>
                      {item.key.toUpperCase()}
                    </label>
                  </td>

                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </BoxContainer>
  );
};

export default WotdLineChart;
