// npm modules
import React from 'react';
import propTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';


const PolicyList = ({ list=[] }) => {
    return (
        <div>
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th >Title</th>
                    <th>Current Version</th>
                    <th>Last Version Created</th>
                    <th># of Versions</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                { list.map(i => (
                    <tr key={i.policy_id}>
                        <td>
                            <Link to={`/dashboard/Policy/${i.title.split(' ').join('-')}/${i.policy_id}/${i.version_number || 'new'}`}>{i.title}</Link>
                        </td>
                        <td>{i.version_number ? i.version_number : 'No versions created'}</td>
                        <td>{moment(i.last_updated).format('MM/DD/YYYY')}</td>
                        <td>{i.version_count}</td>
                        <td>{ i.status_id === 3 ? 'Inactive'  : 'Active' }</td>
                    </tr>
                ) )}
                </tbody>
            </Table>

        </div>
    )
};

export default PolicyList;

PolicyList.propTypes = {
    list: propTypes.array.isRequired,
};