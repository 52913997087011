import {
    ALTER_FORM,
    FORM_RESET,
    FORM_START,
    FORM_SUBMIT,
    FORM_RESULT,
    FORM_ADD_QUESTION,
    FORM_REMOVE_QUESTION,
    FORM_ALTER_QUESTION,
    FORM_ADD_LIST,
    FORM_ALTER_LIST,
    FORM_REMOVE_LIST,
    CLIENT_FORM,
    LOAD_FORM,
    LOAD_COACH_FORM,
    FORM_BULK_ALTER_QUESTIONS,
} from "../actions/action_forms";
import randomstring from 'randomstring';

const initialState = {
    title: '',
    start: {
        typeId: 7, // start screen
        HEADLINE:'',
        CONTENT:'',
        IMAGE: null,
        VIDEO: '',
        BUTTON: 'Start Your Assessment',
    },
    questions: [{ typeId: 8, id: Date.now(), HEADLINE: '', DESCRIPTION: '', IMAGE: null, LIST: [] }],
    submit: {
        typeId: 10, // submit screen
        HEADLINE:'',
        CONTENT:'',
        IMAGE: null,
        BUTTON: 'Get Your Results',
    },
    result: {
        typeId: 9, // result screen
        HEADLINE:'',
        CONTENT:'',
        IMAGE: null,
        VIDEO: '',
        BUTTON: 'View Pathways Report',
    },
};
export const newFormReducer = (state=initialState, action) => {
    switch (action.type) {
        //alter general info about form
        case ALTER_FORM:
            return {
                ...state,
                ...action.payload
            };

        // reset form to initial data
        case FORM_RESET:
            return initialState;

        // alter the start screen of a form
        // expect payload of entire start element
        case FORM_START:
            return { ...state, start: action.payload };

        // alter the submit screen of a form
        // expect payload of entire submit element
        case FORM_SUBMIT:
            return { ...state, submit: action.payload };

        // alter the result screen of a form
        // expects payload of entire result element
        case FORM_RESULT:
            return { ...state, result: action.payload };

        // add a new question to a form
        // no payload expected
        case FORM_ADD_QUESTION:
            return {
                ...state,
                questions: [...state.questions, { typeId:8, id: Date.now(), HEADLINE: '', DESCRIPTION: '', IMAGE: null, LIST: [] }]
            };

        // remove a question from a form
        // expect payload of single question object
        case FORM_REMOVE_QUESTION:
            const trimmedQuestions = state.questions.filter(item => item.id !== action.payload.id);
            return { ...state, questions: trimmedQuestions };

        // edit an existing question in a form
        // expects payload of single question object
        case FORM_ALTER_QUESTION:
            const alteredQuestions = state.questions.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload;
                } else {
                    return item;
                }
            });
            return { ...state, questions: alteredQuestions };

        // make bulk edit to all of the questions at once
        case FORM_BULK_ALTER_QUESTIONS:
            return { ...state, questions: action.payload };

        // add a new list item to a mc question in a form
        // expects payload of single question object
        case FORM_ADD_LIST:
            const questions = state.questions.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, LIST: [...item.LIST, { id: Date.now(), value:'' }]}
                } else { return item }
            });
            return { ...state, questions };

        // change a list item in a form question
        // expect payload of single question and single list object
        case FORM_ALTER_LIST:
            const altered = state.questions.map(item => {
                if (item.id === action.payload.question.id) {
                    // this is the question to alter
                    const newList = item.LIST.map(listItem => {
                        if (listItem.id === action.payload.LIST.id) {
                            // this is the list item to alter
                            return action.payload.LIST;
                        } else { return listItem; }
                    });
                    return { ...item, LIST: newList }
                } else { return item; }
            });
            return { ...state, questions: altered };

        // remove a list item in a form question
        // expect payload of single question and single list object
        case FORM_REMOVE_LIST:
            const removed = state.questions.map(item => {
                if (item.id === action.payload.question.id) {
                    // this is the question to alter
                    const newList = item.LIST.filter(listItem => listItem.id !== action.payload.LIST.id);
                    return { ...item, LIST: newList }
                } else { return item; }
            });
            return { ...state, questions: removed };

        // load form into state from server response
        case LOAD_FORM:
            // go through an compose an object for state
            const fetched = action.payload.data.result;
            const composed = {
                title: fetched.title,
                questions: [],
            };
            fetched.elements.forEach(el => {
                if (el.typeId === 19) composed.profile = true; // set whether profile questions have been added
                if (el.typeId === 7) {
                    // this is the start element
                    composed.start = {
                        typeId: 7,
                        HEADLINE: el.HEADLINE ? el.HEADLINE.text : '',
                        CONTENT: el.CONTENT ? el.CONTENT.text : '',
                        IMAGE: el.IMAGE? el.IMAGE.src : null,
                        VIDEO: el.VIDEO ? el.VIDEO.src : '',
                        BUTTON: el.BUTTON ? el.BUTTON.text: '',
                        validation: undefined,
                    };
                } else if (el.typeId === 10) {
                    // this is the submit element
                    composed.submit = {
                        typeId: 10,
                        HEADLINE: el.HEADLINE ? el.HEADLINE.text : '',
                        CONTENT: el.CONTENT ? el.CONTENT.text : '',
                        IMAGE: el.IMAGE? el.IMAGE.src : null,
                        BUTTON: el.BUTTON ? el.BUTTON.text: '',
                        validation: undefined,
                    };
                } else if (el.typeId === 9) {
                    // this is the result element
                    composed.result = {
                        typeId: 9,
                        HEADLINE: el.HEADLINE ? el.HEADLINE.text : '',
                        CONTENT: el.CONTENT ? el.CONTENT.text : '',
                        IMAGE: el.IMAGE? el.IMAGE.src : null,
                        VIDEO: el.VIDEO ? el.VIDEO.src : '',
                        BUTTON: el.BUTTON ? el.BUTTON.text: '',
                        validation: undefined,
                    };
                } else if (el.typeId === 8) {
                    // this is a question element
                    const question = {
                        typeId: 8,
                        id: el.id,
                        prev: el.prev,
                        next: el.next,
                        optional: el.LIST.optional,
                        categoryId: el.LIST.taxonomy,
                        HEADLINE: el.LIST.label,
                        DESCRIPTION: el.LIST.text,
                        IMAGE: el.IMAGE? el.IMAGE.src : null,
                        LIST: el.LIST.list.map(item => ({
                            id: randomstring.generate({length: 10, charset: 'alphanumeric'}),
                            value: item,
                            validation: undefined,
                        })),
                        validation: undefined,
                    };
                    composed.questions.push(question);
                }
            });
            if (!composed.result) {
                composed.result = {
                    typeId: 9,
                    HEADLINE: '',
                    CONTENT: '',
                    IMAGE: null,
                    VIDEO: '',
                    BUTTON: '',
                    validation: undefined,
                };
            }
            return composed;

        default:
            return state;
    }
};

// handle the state of a single form
export const singleFormReducer = (state=null, action) => {
    switch(action.type){
        case CLIENT_FORM:
            return action.payload;

        case LOAD_COACH_FORM:
            if (action.payload.data && action.payload.data.result) {
                return action.payload.data.result;
            }
            return state;

        default:
            return state;
    }
};
