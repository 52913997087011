import React from 'react';

// components
import PathwayContentGroups from "./pathwayContentGroups";
import PathwayContentLifeStyle from "./pathwayContentLifestyle";

const PathwayContentCategory = (props) => {
    const params = new URLSearchParams(props.location.search);
    const pathwayId = params.get('pathwayId');
    const lifestyleId = params.get('lifestyleId');

    // show top level category page as no lifestyle id is present
    if (pathwayId && !lifestyleId) {
        return (
            <PathwayContentGroups/>
        )
    }
    if (pathwayId && lifestyleId) {
        return (
            <PathwayContentLifeStyle/>
        )
    }
    return(
        <div/>
    )
};

export default PathwayContentCategory;