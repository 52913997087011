import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {ProgressBar} from "react-bootstrap";
import _ from 'lodash';
import {withRouter} from "react-router-dom";

import {fetchMemberContent} from "../../store/actions/pathwaysActions";
import {fetchUserCoaches} from "../../store/actions/userActions";

// components
import {ContentLink} from "../links/links";
import checkmark from "../../images/checkmark.png";
import ContentBackground from '../../images/icon-circles.png';
import LockImage from '../../images/icon-lock.png';
import NoodleImage from "../../images/noodle-overlay.png";
import ClientHeader from "../clientHeader/clientHeader";



const PathwayContentGroups = ({ user, clientPathway, ...props }) => {
    // check if pathwayId is present in url
    const params = new URLSearchParams(props.location.search);
    const pathwayId = params.get('pathwayId');
    const [ state, updateState ] = useState({
        content: null,
        overview: null,
        goal: false,
        coach: null,
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function fetchContent() {
        if (pathwayId === 'foundations') {
            fetchMemberContent().getFoundations()
                .then(result => {
                    const overview = result.content.filter(item => item.overview).shift(); // grab first overview item
                    setState({ content: { ...result, content: result.content.filter(item => !item.overview)}, overview });
                })
                .catch(e => console.log(e));
        } else if (pathwayId === 'coach') {
            fetchMemberContent().getCoach()
                .then(result => {
                    const overview = result.content.filter(item => item.overview).shift(); // grab first overview item
                    setState({ content: { ...result, content: result.content.filter(item => !item.overview)}, overview });
                })
                .catch(e => console.log(e));
        } else {
            fetchMemberContent().getHealthGoal()
                .then(result => {
                    const overview = result.content.length && result.content[0].overview ? result.content[0] : null;
                    setState({ content: result, goal: true, overview });
                })
                .catch(e => console.log(e));
        }
    }
    function renderLinks() {
        if (!state.content) return;
        if (state.content && !state.goal) { // in either coach/foundation video lis
            return (
                state.content.content.map((item, index) => {
                        const image = { src: '', alt: 'icon' };
                        if (item.completed) image.src = checkmark;
                        return (
                            <div className="col-xs-12 col-sm-6 contentLink">
                                <ContentLink
                                    overlay
                                    key={item.id}
                                    disabled={item.lock_status !== 'unlocked'}
                                    text={`${state.overview ? index+2 : index+1}. ${item.title}`}
                                    image={image}
                                    tileImage={item.thumbnail || ContentBackground}
                                    lockImage={item.lock_status !== 'unlocked' ? LockImage : null}
                                    to={`/member/pathways/${user.username}/content/${item.id}?pathwayId=${pathwayId}`}
                                />
                            </div>
                        )
                    }
                )
            )
        } else if (state.content) {
            return state.content.subcategories.map((category, i) => {
                return (
                    <div className="col-xs-12 col-sm-6 contentLink">
                        <ContentLink
                            className={i === 0 ? 'tour-pathway-category' : ''}
                            disabled={ !category.content.length}
                            key={category.category_id}
                            text={category.category_name}
                            image={category.complete === category.count && category.count > 0 ? { src: checkmark, alt: 'checkmark' } : null}
                            tileImage={category.tile_image_url}
                            overlay
                            to={`/member/pathways/${user.username}/pathway?pathwayId=${pathwayId}&lifestyleId=${category.category_id}`}

                        >
                            <div className='fullWidth'>
                                { category.count > 0 ?
                                    <ProgressBar className='w:2of4' now={(category.complete / category.count)*100}/>
                                    : null
                                }
                                { category.count > 0 ?
                                    <p className='t-t:n'>{category.complete} of {category.count}</p>
                                    :
                                    <p className='green comingSoon'>Content Coming Soon</p>
                                }
                            </div>
                        </ContentLink>
                    </div>
                )
            })
        }
    }
    function yourPathway() {
        if (clientPathway.id) {
            if (pathwayId === 'coach' && state.coach) {
                return (
                    <div>
                        <h2 className="no-margin">Meet Coach { state.coach.first_name }</h2>
                        {state.content && state.content.description ? <p>{state.content.description}</p> : null }
                    </div>
                )
            }
            if (pathwayId === 'foundations') {
                return (
                    <div>
                        <h2 className="no-margin">Foundations For Change</h2>
                        {state.content && state.content.description ? <p>{state.content.description}</p> : null }
                    </div>
                )
            }
            if (pathwayId !== 'coach' && pathwayId !== 'foundation') {
                return (
                    <div>
                        <h2 className="no-margin">{clientPathway.health_goals[0].health_goal_name}</h2>
                        {state.content && state.content.description ? <p>{state.content.description}</p> : null }
                    </div>
                )
            }
        }
    }

    const categoryProgress = content => {
        if (content.subcategories && content.subcategories.length) {
            let complete = 0;
            let total = content.subcategories.filter(cat => cat.content.length).length;
            content.subcategories.forEach(category => {
                if (category.complete === category.count && category.count > 0) ++complete;
            });
            if (content.content && content.content.length) {
                content.content.forEach(item => {
                    if (item.overview) ++total;
                    if (item.completed) ++complete;
                })
            }
            return { complete, total };
        }else {
            return { complete: 0, total: 0 };
        }
    }

    // grab content on mount
    useEffect(() => {
        fetchContent();
    }, []);

    // grab coach on mount if needed
    useEffect(() => {
        if (pathwayId === 'coach') {
            fetchUserCoaches()
                .then(results => {
                    if (results.length) {
                        setState({ coach: results[0] });
                    }
                }).catch(e => console.log(e));
        }
    }, []);

    if (!user || _.isEmpty(user) || !state.content) return <div/>;

    return (
        <>
            <ClientHeader type={'transparent'} backUrl={`/member/pathways/${user.username}`}/>
            <div>
                <div
                    style={ state.content ? { backgroundImage: `url(${state.content.hero_image_url})`} : null }
                    className="wellness-bg wydBG pathwaysBackground">
                    <div className='pathwaysOverlay'/>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                { yourPathway() }
                                { state.content ?
                                    <div>
                                        { state.content.count > 0 ?
                                            <ProgressBar className='w:2of4' now={(categoryProgress(state.content).complete / categoryProgress(state.content).total)*100}/>
                                            : null
                                        }
                                        { state.content.count > 0 ?
                                            state.goal ?
                                                <p>{categoryProgress(state.content).complete} of {categoryProgress(state.content).total}</p>
                                                :
                                                <p>{state.content.complete} of {state.content.count}</p>
                                            :
                                            <p>Content Coming Soon</p>
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    clientPathway.id ?
                        <div>
                            <div className={`container client-dash ${!state.overview ? 'no-overview' : ''}`}>
                                <div className="row">
                                    <div className='contentLinkContainer'>
                                        { state.overview ?
                                            <div className="col-xs-12">
                                                <ContentLink
                                                    to={`/member/pathways/${user.username}/content/${state.overview.id}?pathwayId=${pathwayId}`}
                                                    text={`1. ${state.overview.title}`}
                                                    image={state.overview.completed ? { src: checkmark, alt: 'checkmark'} : <i className="fas fa-caret-right img"/>}
                                                    background='#6BA543 no-repeat center right'
                                                    color='#fff'
                                                    tileImage={NoodleImage}
                                                    className='video'
                                                />
                                            </div>
                                            : null
                                        }
                                        {renderLinks()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
                <div className="footer-actions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <button className="btn client-white" onClick={()=>props.history.push(`/member/pathways/${user.username}`)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

function mapStateToProps({ user, clientPathway, clientPathwayList }) {
    return { user, clientPathway, clientPathwayList };
}

export default withRouter(connect(mapStateToProps)(PathwayContentGroups));