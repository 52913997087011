import React from 'react';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
// styles
import styles from './singleBadge.module.css';

// components
import Badge from "../../badge/badge";
import BadgeLock from "../../../images/Badge_Lock200x200.png";
import LikeReaction from "../likeReaction/likeReaction";
import WowReaction from "../wowReaction/wowReaction";
import EnvyReaction from "../envyReaction/envyReaction";
import LoveReaction from "../loveReaction/loveReaction";
import {REACTION_TYPES} from "../../../serverVariables/reactionTypes";

const SingleBadge = props => {
    // props
    const {badge} = props;

    function abbrNum(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10,decPlaces);

        // Enumerate number abbreviations
        var abbrev = [ "k", "m", "b", "t" ];

        // Go through the array backwards, so we do the largest first
        for (var i=abbrev.length-1; i>=0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10,(i+1)*3);

            // If the number is bigger or equal do the abbreviation
            if(size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number*decPlaces/size)/decPlaces;

                // Handle special case where we round up to the next abbreviation
                if((number === 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }

        return number;
    }

    if (!badge || !badge.name) {
        return (
            <div className={styles.container}>
                <div className={styles.name}>Locked</div>
                <div>
                    <img className={styles.badge} src={BadgeLock} alt=""/>
                </div>
                <div className={styles.description}>
                    Earn 1500 points in the current month to unlock you next badge.
                </div>
            </div>
        )
    }

    let likes, loves, wows, envies, max;
    const reactions = badge.reactions;
    if (reactions) {
        likes = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LIKE);
        if (likes) likes = likes.count;
        else likes = 0;

        wows = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.WOW);
        if (wows) wows = wows.count;
        else wows = 0;

        envies = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.ENVY);
        if (envies) envies = envies.count;
        else envies = 0;

        loves = reactions.find(rec => rec.reaction_type_id === REACTION_TYPES.LOVE);
        if (loves) loves = loves.count;
        else loves = 0;

        if (loves || wows || envies || likes) {
            const temp = [likes, wows, envies, loves];
            max = temp.indexOf(Math.max(...temp));
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.name}>{badge.name}</div>
            <div>
                <Badge className={styles.badge} badge={badge.name}/>
            </div>
            <div className={styles.points}>
                <div>
                    <p>Earned</p>
                    <strong>{format(parseISO(badge.earned), 'MM/dd/yy')}</strong>
                </div>
                <div>
                    <p>Monthly Points</p>
                    <strong>{badge.score}</strong>
                </div>
            </div>
            <div className={styles.reactionsContainer}>
                <div>
                    <div className={styles.reactions}>
                        <div>
                            <span>{abbrNum(likes, 1)}</span>
                            <LikeReaction
                                active={max === 0}
                                disabled
                                className={`${styles.icon} ${styles.like}`}
                                color='transparent'
                                stroke='#354052'
                                animatedColor='#6bd0f3'
                                animatedStroke='#e8ecf0'/>
                        </div>
                        <div>
                            <span>{abbrNum(wows, 1)}</span>
                            <WowReaction
                                active={max === 1}
                                disabled
                                className={styles.icon}
                                color='transparent'
                                stroke='#354052'
                                animatedColor='#f7cb5d'
                                animatedStroke='#c78430'/>
                        </div>
                        <div>
                            <span>{abbrNum(envies, 1)}</span>
                            <EnvyReaction
                                active={max === 2}
                                disabled
                                className={styles.icon}
                                color='transparent'
                                stroke='#354052'
                                animatedColor='#adcb54'
                                animatedStroke='#3f4e3d'/>
                        </div>
                        <div>
                            <span>{abbrNum(loves, 1)}</span>
                            <LoveReaction
                                active={max === 3}
                                disabled
                                className={styles.icon}
                                color='transparent'
                                stroke='#354052'
                                animatedColor='#e5466c'
                                animatedStroke='transparent'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.description}>
                {badge.description}
            </div>
        </div>
    )
}
export default SingleBadge;