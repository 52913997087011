import React, {useState, useEffect, useContext} from 'react';
import {connect} from "react-redux";
import {Route, withRouter} from "react-router-dom";

// styles
import './clientContainer.css';

// components
import Notification from "../../clientHeader/notificaition/notification";
import ClientHubContainer from "../../clientHub/clientHubContainer";
import ClientDashMain from "../../Client/clientDash/clientDashMain";
import PathwaysContentContainer from "../../pathwaysContent/pathwaysContentContainer";
import JustForYouContentContainer from "../../justForYouContent/justForYouContentContainer";
import FeatureContent from "../../featuredContent/featuredContentContainer";
import TWSDisplay from "../../twsDisplay/TWSDisplay";
import ClientTrendsContainer from "../../clientTrends/clientTrendsContainer";
import SingleTileIntroList from "../../Client/singleTileDisplay/singleTileIntroDisplay";
import AddTileActivity from "../../Client/addActivity/AddTileActivity";
import ClientProfileMain from "../../Client/ClientProfile/ClientProfileMain";
import ProfileNav from "../../Client/ClientProfile/ProfileNav";
import CoachingTeamContainer from "../../Client/CoachingTeam/CoachingTeamContainer";
import MessageMain from "../../Client/Inbox/MessageMain";
import NewMessageMain from "../../Client/Inbox/NewMessageMain";
import ReplyMain from "../../Client/Inbox/ReplyMain";
import ArchivedMessagesMain from "../../Client/Inbox/ArchivedMessagesMain";
import ClientAssessmentsMain from "../../Client/clientAssessments/ClientAssessmentsMain";
import ClientAssessmentViewContainer from "../../takenAssessment/clientView/clientAssessmentViewContainer";
import ScheduleContainer from "../../Client/Calendar/ScheduleContainer";
import TeammatesMain from "../../Client/Teammates/TeammatesMain";
import PolicyComplianceContainer from "../../policyComplianceView/policyComplianceContainer";
import ClientPathwaysRoute from "../../clientPathwaysList/clientPathwaysListRoute";
import ClientPathwayReportRoute from "../../clientPathwayReport/clientPathwayReportRoute";
import {ShepherdTourContext} from "react-shepherd";
import _ from "lodash";
import {ALERT_TYPES} from "../../../serverVariables/alertTypes";
import {acknowledgeAlert, clearAlert} from "../../../store/actions/alertActions";
import Community from "../../community/community";
import ClientNavBar from "../../navigation/clientNavBar/clientNavBar";
import MoreNavigation from "../../navigation/moreNavigation/moreNavigation";
import CommunityOthers from "../../communityOthers/communityOthers";
import CommunityReactions from "../../communityReactions/communityReactions";
import MemberMail from "../../memberMail/memberMail";
import OnDemandContainer from "../../onDemand/onDemandContainer";

const  ClientContainer = props => {
    const tour = useContext(ShepherdTourContext);
    // props
    const {match} = props;
    // state
    const {alertSubscription, alertOptions} = props;
    // actions
    const {clearAlert, acknowledgeAlert} = props;
    // local
    const [alert, setAlert] = useState(false); // decide whether to show alert ui
    const [showMoreNav, setShowMoveNav] = useState(false);

    // subscribe to alerts
    useEffect(() => {
        if (!_.isEmpty(alertSubscription) && !alertSubscription.fired) {
            acknowledgeAlert();
            const {alert_type: {name}} = alertSubscription;
            if (name === ALERT_TYPES.POINTS_SCORED || name === ALERT_TYPES.DAILY_GOAL || name === ALERT_TYPES.MONTHLY_GOAL){
                if (tour && tour.isActive()){
                    return hideAlert(100);
                }
                showAlert(alertSubscription);
            }
        }
    }, [alertSubscription]);

    function showAlert(val){
        if(alertOptions.points_alert) setAlert(val);
        setTimeout(hideAlert, 5000);
    }

    function hideAlert(time=2000) {
        setAlert(false);
        setTimeout(() => clearAlert(), time);
    }
    const onOnboarding = window.location.pathname.lastIndexOf('onboard-assessment') > 0;
    return (
        <>
            <div className={`notification ${alert ? 'show' : ''}`}>
                <Notification data={alert}/>
            </div>

            {/* HOME ROUTES */}
            <Route path={`${match.url}/clientDash/:username`} exact component={ClientHubContainer}/>
            <Route path={`${match.url}/clientDash/`} exact component={ClientHubContainer}/>
            <Route path={`${match.url}/featured`} component={FeatureContent}/>
            <Route path={`${match.url}/score`} exact component={TWSDisplay}/>
            <Route path={`${match.url}/on-demand`} component={OnDemandContainer}/>

            {/* COMMUNITY ROUTES */}
            <Route path={`${match.url}/community`} component={Community}/>
            <Route path={`${match.url}/view-victories/:userId`} component={CommunityOthers}/>
            <Route path={`${match.url}/view-reactions`} component={CommunityReactions}/>

            {/* HABIT ROUTES */}
            <Route path={`${match.url}/clientDash/:username/habits`} component={ClientDashMain} />
            <Route path={`${match.url}/tile/:tileId`} exact component={ClientTrendsContainer}/>
            <Route path={`${match.url}/tile/intro/:tileId`} exact component={SingleTileIntroList}/>
            <Route path={`${match.url}/addActivity`} component={AddTileActivity}/>

            {/* PATHWAY ROUTES */}
            <Route path={`${match.url}/pathways/:username`} component={PathwaysContentContainer} />
            <Route path={`${match.url}/just_for_you`} component={JustForYouContentContainer}/>

            <Route path={`${match.url}/clientDash/:username/profile`} component={ClientProfileMain}/>
            <Route path={`${match.url}/clientDash/:username/account`} component={ProfileNav}/>
            <Route path={`${match.url}/clientDash/:username/coaching-team`} component={CoachingTeamContainer}/>
            <Route path={`${match.url}/clientDash/:username/inbox`} component={MemberMail}/>
            <Route path={`${match.url}/clientDash/:username/inbox-message/:id`} component={MessageMain}/>
            <Route path={`${match.url}/clientDash/:username/inbox-newmessage`} component={NewMessageMain}/>
            <Route path={`${match.url}/clientDash/:username/inbox-newreply`} component={ReplyMain}/>
            <Route path={`${match.url}/clientDash/:username/inbox-archived`} component={ArchivedMessagesMain}/>
            <Route path={`${match.url}/clientDash/:username/assessments`} exact component={ClientAssessmentsMain}/>
            <Route path={`${match.url}/clientDash/:username/assessments/:id`} component={ClientAssessmentViewContainer}/>
            <Route path={`${match.url}/clientDash/Coaching-Team/Schedule`} component={ScheduleContainer}/>
            <Route path={`${match.url}/clientDash/:username/Teammates`} component={TeammatesMain}/>
            <Route path={`${match.url}/clientDash/:username/policies`} component={PolicyComplianceContainer}/>
            <Route path={`${match.url}/clientDash/:username/pathways`} exact component={ClientPathwaysRoute} />
            <Route path={`${match.url}/clientDash/:username/pathways/:pathwayId`} component={ClientPathwayReportRoute}/>
            {onOnboarding ?
                null
                :
                <div className='mainNav'>
                    <ClientNavBar
                        closeMore={() => setShowMoveNav(false)}
                        more={showMoreNav}
                        openMore={() => setShowMoveNav(!showMoreNav)}/>
                </div>
            }
            <div className={`moreNav ${showMoreNav ? 'open' : ''}`}>
                <MoreNavigation close={() => setShowMoveNav(false)}/>
            </div>
        </>
    )
}
function mapStateToProps({alertSubscription, alertOptions}) {
    return {alertSubscription, alertOptions};
}

const actions = {
    clearAlert,
    acknowledgeAlert,
};

export default withRouter(connect(mapStateToProps, actions)(ClientContainer));