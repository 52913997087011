import React, {useState} from 'react';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {Table} from "react-bootstrap";

// styles
import styles from './goalExportList.module.css'

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import {PrimaryButtonSm} from "../../../buttons/buttons";
import {SecondaryInfoAlert} from "../../../alerts/alerts";

const GoalExportList = props => {
    const {data, csvExport, exporting} = props;
    // local
    const [showList, setShowList] = useState(false);

    function renderList() {
        if (showList) {
            return(
                <div className={styles.table}>
                    <Table striped bordered hover size="sm">
                        <thead className="hidden-xs hidden-sm">
                        <tr>
                            <th>Member Name</th>
                            <th>Rank</th>
                            <th>Badge Earned</th>
                            <th>Date Earned</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.user_data.map(i => (
                            <tr key={i.name}>
                                <td className={styles.name}>{`${i.first_name} ${i.last_name}`}</td>
                                <td>{i.rank}</td>
                                <td>{i.level}</td>
                                <td>{format(parseISO(i.achievement_date, new Date()), 'MM/dd/yy')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {showList.length > 500 ? <SecondaryInfoAlert>This list contains over 500 members. Please click the Export button to see the full list.</SecondaryInfoAlert> : null}
                </div>
            )
        }
    }

    if (!data) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    return (
        <div className={styles.containerResponsive}>
            <div className={styles.header}>
                <div>
                    <h4>Members Who Met Monthly Goal: {data.monthly_goals_achieved}</h4>
                    <button
                        className={styles.listButton}
                        onClick={() => setShowList(!showList)}
                        tabIndex={0}
                        type="button">
                        {showList ? 'Hide List' : 'Show List'}
                    </button>
                </div>
                <PrimaryButtonSm
                    disabled={exporting}
                    onClick={csvExport}
                    className={styles.exportButton}>
                    Export
                    <i className="fa-solid fa-file-export"/>
                </PrimaryButtonSm>
            </div>
            {renderList()}
        </div>
    )
}

export default GoalExportList;