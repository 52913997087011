import React, {useState} from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// styles
import styles from './engagementGraph.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import {ToggleButtons} from "../../../buttons/buttons";
import parse from "date-fns/parse";
import format from "date-fns/format";

const EngagementGraph = props => {
    // props
    const {activeData, durationData} = props;
    // local
    const [toggle, setToggle] = useState('active');

    if (!activeData || !durationData) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    const dateOptions = [
        { label: 'Engaged Members', value: 'Active Members', onClick: () => setToggle('active') },
        { label: 'Avg. Duration', value: 'Avg. Duration', onClick: () => setToggle('duration') },
    ];

    const dateFormatter = date => {
        const result =  parse(date, 'yyyy-MM-dd', new Date()).getTime();
        return format(result, 'MM/dd/yy');
    };

    return (
        <div>
            <div className='margin-bottom-15'>
                <ToggleButtons
                    inactiveColor='#fff'
                    activeColor='#272727'
                    className={styles.dateToggle}
                    options={dateOptions}/>
            </div>
            <div className={styles.container}>
                {toggle === 'active' ?
                    <ResponsiveContainer width="100%" height="100%" debounce={1}>
                        <LineChart
                            data={activeData}
                            margin={{
                                top: 15,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <XAxis
                                dataKey="date"
                                tickFormatter={dateFormatter}/>/>
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="members" stroke="#4BBC4E" dot={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                    :
                    <ResponsiveContainer width="100%" height="100%" debounce={1}>
                        <LineChart
                            data={durationData}
                            margin={{
                                top: 15,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <XAxis
                                dataKey="date"
                                tickFormatter={dateFormatter}/>/>
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="minutes" stroke="#4BBC4E" dot={false}/>
                        </LineChart>
                    </ResponsiveContainer>
                }
            </div>
        </div>
    )
}

export default EngagementGraph;