import {GET_COMMUNITY_NOTIFICATIONS} from "../storeConstants";

// place the number of waiting community notifications into state
export function communityReducer(state=0, action) {
    switch(action.type) {
        case GET_COMMUNITY_NOTIFICATIONS:
            return action.payload;
        default:
            return state;
    }
}