import React from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

// styles
import styles from './seatsWidget.module.css';

// components
import { BoxContainer } from "../containers/boxContainer/boxContainer";
import { ProgressBar } from "../progressBar/progressBar";
import InfoIcon from "../../images/info_icon.png";
import {InfoAlert} from "../alerts/alerts";

const SeatsWidget = ({ containerClass, organization, links }) => {
    let monthlyTaken, monthlyTotal, monthlyActive, monthlyActiveTotal, taken, total;
    const seats = organization.seats;
    if (seats.monthly_seats) {
        monthlyTaken = seats.monthly_seats.usage.total;
        monthlyTotal = seats.monthly_seats.capacity;
        monthlyActive = seats.monthly_seats.usage.active;
        monthlyActiveTotal = seats.monthly_seats.usage.total;
    }
    if (seats.total_seats) {
        taken = seats.total_seats.usage.total;
        total = seats.total_seats.capacity;
        // totalActive = seats.total_seats.usage.active;
        // totalActiveTotal = seats.total_seats.capacity;
    }
    if (!seats.total_seats || !seats.monthly_seats) {
        return (
            <BoxContainer className={containerClass}>
                <div className={styles.placeholder}>
                    { links ?
                        <InfoAlert className='m:0'>
                            To enable sign-up please <Link to={`/dashboard/Organization/${organization.id}/seats`}>
                            set seat limits</Link>
                        </InfoAlert>
                        : <p className='m:0'>To enable sign-up please set seat limits</p>
                    }
                </div>
            </BoxContainer>
        )
    }
    return (
        <BoxContainer className={containerClass}>
            <div className={styles.measure}>
                <h4 className='m:0'>Monthly Active</h4>
                <ProgressBar className='m-b:1' color='#71F64E' partial={monthlyActive} total={monthlyActiveTotal}/>
                <p>{monthlyActive} / {monthlyActiveTotal}</p>
            </div>
            <div className={styles.measure}>
                <h4 className='m:0'>
                    Monthly Available
                    <p className={`hover ${styles.icon}`} data-tip='Number of seats available for the remainder of the month'>
                        <img src={InfoIcon} alt='monthly seat info'/>
                    </p>
                    { links ?
                        <Link className={styles.editLink} to={`/dashboard/Organization/${organization.id}/seats`}>
                            Edit
                        </Link>
                        :null
                    }
                </h4>
                <ProgressBar className='m-b:1' color='#FB7B3A' partial={monthlyTaken} total={monthlyTotal}/>
                <p>{monthlyTaken} / {monthlyTotal}</p>
            </div>
            <div className={styles.measure}>
                <h4 className='m:0'>
                    Total Available
                    <p className={`hover ${styles.icon}`} data-tip='Number of seats that an organization has used and can use of their total limit'>
                        <img src={InfoIcon} alt='total seat info'/>
                    </p>
                    { links ?
                        <Link className={styles.editLink} to={`/dashboard/Organization/${organization.id}/seats`}>
                            Edit
                        </Link>
                        : null
                    }
                </h4>
                <ProgressBar className='m-b:1' color='#F9E250' partial={taken} total={total}/>
                <p>{taken} / {total}</p>
            </div>
            <ReactTooltip  place='top' type='light' event='click' effect='solid' globalEventOff='click' clickable />
        </BoxContainer>
    )
}

export default SeatsWidget;