import {SET_COACH} from "../actions/action_coach";

export default function (state={},action ){
    switch (action.type){

        case SET_COACH:
            return Object.assign({}, action.payload);
        // no default

    }
    return state;
}
