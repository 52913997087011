// npm modules
import React, {useEffect, useState,} from 'react';
import axios from 'axios';
import Masonry from 'react-masonry-component';

// styles
import styles from './giphySearch.module.css';

const masonryOptions = {
    transitionDuration: 0
};

const GiphySearch = ({ callback, close }) => {
    const [ state, updateState ] = useState({
        selected: null,
        listener: null,
        offset: 0,
        gifs: [],
        term: '',
        term2: '',
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    // use useRefs here because event listener wraps state in enclosure
    const [scrollEvent, setScrollEvent] = useState(false);
    const setNewScrollEvent = state => setScrollEvent(state);

    // number of gifs to fetch per call
    const LIMIT = 25;

    // update to scroll / scroll was detected
    useEffect(() => {
        cleanUp();
    },[ scrollEvent ]);

    // trigger a search
    useEffect(() => {
        search();
    }, [state.term2]);

    // unmount & clear event listeners
    useEffect(() => {
        return () => {
            const gifSet = document.getElementById('gifSet');
            if (gifSet) {
                gifSet.removeEventListener('scroll', scroll);
            }
        }
    },[]);
    if (callback) callback(state.selected);

    const cleanUp = () => {
        if (state.term !== state.term2) {
            setState({ gifs: [], term2: state.term });
        } else {
            search();
        }
    };

    const search = async () => {
        const encoded = encodeURIComponent(state.term);
        const url = `https://api.giphy.com/v1/gifs/search?q=${encoded}&api_key=SjSg19NQ7MbSXHp0KfKFsdWP2elNwafo&limit=${LIMIT}&offset=${state.offset*LIMIT}`;
        const search = await axios.get(url)
            .catch(e=>console.log(e));
        if (search && search.data) {
            state.gifs = setState({
                gifs: [ ...state.gifs || [], ...search.data.data ],
                selected: null,
                offset: state.offset + 1
            });
        }
        const gifSet = document.getElementById('gifSet');
        if (gifSet && !state.listener) {
            setState({ listener: true} );
            gifSet.addEventListener('scroll', scroll);
        }
    };

    const scroll = () => {
        const gifSet = document.getElementById('gifSet');
        if (gifSet) {
            if( gifSet.scrollTop === (gifSet.scrollHeight - gifSet.offsetHeight)) {
                setNewScrollEvent(freshState => !freshState);
            }
        }
    };

    const form = () => {
        return (
            <div className={styles.form}>
                <input type="text" value={state.term} onChange={e=> setState({ term: e.target.value})} placeholder='Search GIPHY'/>
                <i onClick={cleanUp} className="fas fa-search"> </i>
                {close ? <i onClick={close} className="fas fa-times"> </i> : null}
            </div>
        )
    };

    if (state.selected) {
        return (
            <div>
                {form()}
            </div>
        )
    }

    return (
        <div>
            {form()}
            <Masonry
                id='gifSet'
                className={styles.gifSet}
                elementType={'ul'}
                options={masonryOptions}
                disableImagesLoaded={false}
                updateOnEachImageLoad={true}
            >
                {state.gifs.map(gif => (
                    <li onClick={()=>setState({ selected: gif })}>
                        <img className={styles.gifTile} src={gif.images.preview_gif.url} alt={gif.title}/>
                    </li>
                ))}
            </Masonry>
        </div>
    )
};

export default GiphySearch;