import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import './Step3.css'
import 'rc-slider/assets/index.css';
import {GoalBar} from './GoalBar';
import {Notes} from './Notes';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {setNote} from "../../../actions/action_notes";


class Step3 extends Component{
   constructor(props){
       super(props);
           this.state = {
               user : {},
               planUnits: '',
               description: this.props.location.state.description,
               value: [0, 40, 60, 100],
               noteTitle: '',
               notes: ''
       };
   }

     planUnitsHandler = (planUnitsValue) => {
        this.setState({
          planUnits: planUnitsValue
        })
      };

    valueHandler = (valueFromGoalBar) => {
        this.setState({
            value: valueFromGoalBar
        })
    };

     descriptionHandler = (descriptionValue) => {
        this.setState({
          description: descriptionValue
        })
      };

     noteTitleHandler = (noteTitleValue) => {
        this.setState({
          noteTitle: noteTitleValue
        })
      };

     notesHandler = (notesValue) => {
        this.setState({
          notes: notesValue
        })
      };

    moveToStep4(category, description, scaleStart, scaleEnd, minPoint, maxPoint, planUnits){
        if(this.state.notes && this.state.noteTitle){
            this.props.setNote({
                title: this.state.notes,
                content:this.state.noteTitle,
                username:this.props.username
            });
        }
        let currentPath = this.props.location.pathname,
            parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
        this.props.history.push({
            pathname: parentPath+'/step-4',
            state: {
                category:category,
                description:description,
                planStatus:'active',
                scaleStart:scaleStart,
                scaleEnd:scaleEnd,
                planUnits:planUnits,
                minPoint:minPoint,
                maxPoint:maxPoint,
            }
        })      
    }

    componentDidMount() {
        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({user:res.data.results});
            })
    }

    render(){
        console.log(this.state.noteTitle);
        console.log(this.state.notes);
        return(
            <div>
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.user.firstName}</p>
                    </div>
                    <div className="col-md-9 col-xs-12">
                        <ul className="progressbar">
                            <li className="active">Step 1</li>
                            <li className="active">Step 2</li>
                            <li className="active">Step 3</li>
                            <li>Step 4</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <h3>Goal Bar Settings</h3>
                        <p>Set initial goal</p>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <h3>Tile Coaching Notes</h3>  
                        <p>Private Notes for future reference (Members will never see this)</p>
                    </div>
                </div>
                <div className="row">
                    <GoalBar
                     planUnitsHandler={this.planUnitsHandler}
                     valueHandler={this.valueHandler}
                     descriptionHandler={this.descriptionHandler}
                     category={this.props.location.state.category} 
                     description={this.props.location.state.description}
                     />
                    <Notes 
                    noteTitleHandler={this.noteTitleHandler}
                    notesHandler={this.notesHandler}
                    noteContent={this.state.notes}
                    noteTitle={this.state.noteTitle}
                    />
                    <button onClick={()=>this.moveToStep4(
                        this.props.location.state.category, 
                        this.props.location.state.description,
                        this.state.value[0],
                        this.state.value[3],
                        this.state.value[1],
                        this.state.value[2],
                        this.state.planUnits,
                        )}>Save &amp; Next Step</button>
                </div>
            </div>
        )

    }
}


function mapStateToProps({notes}){
    return{
        //notes:notes
    }
}

function mapDispatchToProps(dispatch){
    bindActionCreators({setNote}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Step3));