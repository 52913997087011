import React from 'react';
import {Link} from "react-router-dom";
import differenceInSeconds from "date-fns/differenceInSeconds";
import parseISO from "date-fns/parseISO";

// styles
import styles from './communitySingleReaction.module.css';

// components
import {REACTION_TYPES} from "../../serverVariables/reactionTypes";
import LikeReaction from "../community/likeReaction/likeReaction";
import LoveReaction from "../community/loveReaction/loveReaction";
import WowReaction from "../community/wowReaction/wowReaction";
import EnvyReaction from "../community/envyReaction/envyReaction";
import {VICTORY_TYPES} from "../../serverVariables/victoryTypes";

const CommunitySingleReaction = props => {
    // props
    const {reaction, link} = props;

    function selectReaction(type) {
        if (type === REACTION_TYPES.LIKE) return <span>liked <LikeReaction active disabled animatedColor='#6bd0f3' animatedStroke='#e8ecf0' className={styles.icon}/></span>
        if (type === REACTION_TYPES.LOVE) return <span>loved <LoveReaction active disabled animatedColor='#e5466c' animatedStroke='#transparent' className={styles.icon}/></span>
        if (type === REACTION_TYPES.WOW) return <span>wowed <WowReaction active disabled animatedColor='#f7cb5d' animatedStroke='#c78430' className={styles.icon}/></span>
        if (type === REACTION_TYPES.ENVY) return <span>envied <EnvyReaction active disabled animatedColor='#adcb54' animatedStroke='#3f4e3d' className={styles.icon}/></span>
    }

    function selectVictory(reaction, type) {
        if (type === VICTORY_TYPES.BADGES) {
            return <span>{reaction.victory.level_achievement.level.name} badge earned</span>;
        }
        if (type === VICTORY_TYPES.STREAK) {
            return <span>7 Day Streak</span>;
        }
        if (
            type === VICTORY_TYPES.MEALTIME_SPACING ||
            type === VICTORY_TYPES.EATING_OCCASIONS ||
            type === VICTORY_TYPES.VEGETABLES_AND_FRUIT ||
            type === VICTORY_TYPES.PROTEIN_SERVINGS ||
            type === VICTORY_TYPES.SWEETENED_FOODS ||
            type === VICTORY_TYPES.SWEETENED_DRINKS ||
            type === VICTORY_TYPES.STRESS_RELEASING ||
            type === VICTORY_TYPES.SLEEP ||
            type === VICTORY_TYPES.PHYSICAL_ACTIVITY ||
            type === VICTORY_TYPES.SPRINT_INTENSITY ||
            type === VICTORY_TYPES.STRENGTH_BUILDING ||
            type === VICTORY_TYPES.RELATED_SKILLS ||
            type === VICTORY_TYPES.FOUNDATIONS ||
            type === VICTORY_TYPES.COACH ||
            type === VICTORY_TYPES.JUST_FOR_YOU ||
            type === VICTORY_TYPES.CHECK_IN_15 ||
            type === VICTORY_TYPES.CHECK_IN_8 ||
            type === VICTORY_TYPES.VIDEOS_WATCHED_8 ||
            type === VICTORY_TYPES.VIDEOS_WATCHED_15
        ) {
            return <span>{reaction.victory.victory_type.name} badge earned</span>
        }
        if (type === VICTORY_TYPES.RANK) {
            return <span>{reaction.victory.victory_type.name}</span>;
        }
    }

    function createTimeLabel(time) {
        let label = 's';
        let diff = differenceInSeconds(new Date(), parseISO(time));
        if (diff > 60) {
            diff = Math.ceil(diff/60);
            label = 'm';
        }
        if (diff > 60) {
            diff = Math.ceil(diff/60);
            label = 'h';
        }
        if (diff > 24 && label === 'h') {
            diff = Math.ceil(diff/24);
            label = 'd';
        }
        if (diff > 7 && label === 'd') {
            diff = Math.ceil(diff/7);
            label = 'w';
        }
        if (diff > 52 && label === 'w') {
            diff = Math.ceil(diff/52);
            label = 'y'
        }
        diff = `${diff}${label}`;
        return(diff);
    }

    const profileImg = reaction.user.profile_image || 'https://treo.s3.us-east-2.amazonaws.com/assets/avatar.png';
    let url = `/member/view-victories/${reaction.user.user_id}`;
    if (link) url = link;
    return (
        <div className={styles.row} key={reaction.reaction_id}>
            {!reaction.member_seen ?
                <div className={styles.unseen}/>
                : null
            }
            <Link to={url}>
                <div className={styles.profile} style={{backgroundImage: `url(${profileImg})`}}/>
            </Link>
            <span className={styles.text}>
                <Link to={url}><strong>{reaction.user.nickname}</strong></Link> &nbsp;
                {selectReaction(reaction.reaction_type.reaction_type_id)}
                a victory:&nbsp;
                {selectVictory(reaction, reaction.victory.victory_type.victory_type_id)}
                <span className={styles.time}>{createTimeLabel(reaction.reaction_created_at)}</span>
                    </span>
        </div>
    )
}

export default CommunitySingleReaction;