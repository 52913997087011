import React from 'react';
import profileImage from '../../../images/avatar-1577909_960_720.png';

const ProfileImage = ({username, image}) =>{
    return(
            <div className="profile-image-coach">

                    <img src={image ? image : profileImage} alt="Profile"/>

            </div>
    )
};

export default ProfileImage;