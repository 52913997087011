import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import styles from './AddEquipBtn.module.css'
class NewEquipment extends React.Component {
    constructor(props){
        super(props);
          this.state={
            equipment:this.props.name,
            sprint8:this.props.sprint8
        }
    }

  equipmentChange = (e) => {
      this.setState({equipment:e.target.value})

  };  

  sprint8Change = (e) => {
      this.setState({sprint8:!this.state.sprint8})
  };    

  addGym(){
      axios.post(`/user/client-equipment/${this.props.username}/add`, {
        name: this.state.equipment,
        sprint8: this.state.sprint8,
      })
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                this.setState({showAdd:true})
                this.setState({ show: true });
                this.setState({ alert: 'success' });
                this.setState({ alertStyle: true });
                this.setState({ update: true })
                this.props.updateChangeHandler(this.state.update);
            }})
        .catch((error)=>{
           console.log(error);
            this.setState({ show: true });
            this.setState({alert:'error'});
            this.setState({ alertStyle: false });
        });
  }

  cancelGym(){
    this.setState({equipment:''});
    this.setState({sprint8:false});
    this.props.showAddChangeHandler(true);
  }


	render() {
    return (
      <div className="col-md-6">
          <BoxContainer className='padding-standard'>
              <div>
                  <div>
                      <label>NEW EQUIPMENT</label>
                      <input
                          type="text"
                          // placeholder={this.props.name}
                          value={this.state.equipment}
                          onChange={(e) => this.equipmentChange(e)}
                      />
                      <input className="sprint8-check" id="checkBox" defaultChecked={this.props.sprint8}
                             value={this.state.sprint8} type="checkbox" onChange={(e) => this.sprint8Change(e)}/>
                      <label className="sprint8-check-label">Sprint8 Equipped</label>
                  </div>
              </div>

              <div className='row' >


                <div className={`margin-top ${styles.equipBtnContainer}`}>

            
                      <div className="col-xs-6">
                          <button  className="btn add-equip-btn" onClick={(e) => this.addGym(e)}>ADD EQUIPMENT</button>
                      </div>
                      <div className="col-xs-6">
                          <button  className={`btn ${styles.deleteEquipBtn}`} onClick={(e) => this.cancelGym(e)}>CANCEL</button>
                      </div>
                      </div>


              </div>
          </BoxContainer>
      </div>
    );
  }
}

export default withRouter(NewEquipment);


