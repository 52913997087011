import React from 'react';
import {Link} from 'react-router-dom';

// styles
import styles from './categoryRow.module.css';

// components
import {ReactComponent as LockIcon} from "../../../images/lock_icon.svg";
import {InlineDangerButton} from "../../buttons/buttons";
import {BoxContainer} from "../../containers/boxContainer/boxContainer";

const CategoryRow = props => {
    const {movable, provided, title, to, locked, flag, removeAction} = props;
    return (
        <BoxContainer className={styles.row}>
            {movable && provided ? <i {...provided.dragHandleProps} className={`fas fa-arrows-alt margin-right-15`}/> : null}
            <Link to={to} className={styles.title}>
                {title}
                {flag ? <span className={styles.flag}>{flag}</span> : null}
            </Link>
            {locked ? <LockIcon className={styles.lock}/> : null}
            {removeAction ?
                <InlineDangerButton onClick={removeAction} className='margin-left-15'>Remove</InlineDangerButton>
                : null
            }
        </BoxContainer>
    )
}
export default CategoryRow;