import React, { useState, useCallback, useEffect } from "react";
import format from "date-fns/format";
import startOfToday from "date-fns/startOfToday";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import parse from "date-fns/parse";

// styles
import styles from "./wotdAnalytics.module.css";

// components
import AnalyticsCard from "./features/analyticsCard/AnalyticsCard";
import WotdLineChart from "./features/wotdLineChart/WotdLineChart";
import TopContent from "./features/topContent/TopContent";
import {
  fetchDailyContentList,
  fetchDailyWotViews,
  fetchDailyWotdWatchTime,
  fetchPopularContentList,
  fetchWotdVideoList,
  fetchWotdViews,
  fetchWotdWatchTime,
} from "../../../store/actions/analyticsActions";
import { fetchClassCategoriesDropdown } from "../../../store/actions/contentActions";
import { formatArray } from "../../contentScheduler/contentScheduleFunctions";

const WotdAnalytics = (props) => {
  const { filters} = props;
  const [startDate, setStartDate] = useState(subDays(startOfToday(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [dailyViews, setDailyViews] = useState(null);
  const [watchTime, setWatchTime] = useState(null);
  const [avgWatchTime, setAvgWatchTime] = useState(null);
  const [search, setSearch] = useState({
    title: "",
    category: null,
    contentType: null,
    sort: "views-desc",
    limit: 10,
  });
  const [page, setPage] = useState(1);
  const [content, setContent] = useState({ content: [], pages: 0 });
  const [loading, setLoading] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [toggle, setToggle] = useState("content");


  // const [version, setVersion] = useState([wotdVersion ])
  const [query, setQuery] = useState({
    start: startDate,
    end: endDate,
    organizationId: filters.organization,
  });

  const urlParts = window.location.pathname.split("/");
  const version = urlParts[urlParts.length - 1];

  useEffect(() => {
    runQuery();
  }, [query , version, toggle]);

  useEffect(() => {
    const newQuery = {
      start: startDate,
      end: endDate,
      organizationId: filters.organization,
    };
    setQuery(newQuery);
  }, [startDate, endDate, filters, version]);

  useEffect(() => {
    setPage(1);
    runSearch();
  }, [page, search.sort, query, version]);

  function runQuery() {
    getDailyViews();
    getWatchTime();
    fetchCategoryFilterData();
  }

  const handleDate = useCallback((e, range) => {
    const { value } = e.target;
    const parsedDate = parse(value, "yyyy-MM-dd", new Date());
    const updatedDate =
      range === "start" ? startOfDay(parsedDate) : endOfDay(parsedDate);
    if (range === "start") {
      setStartDate(updatedDate);
    } else {
      setEndDate(updatedDate);
    }
  }, []);

  async function getDailyViews() {
    try {

      if(version === 'v2'){
        const response = await fetchDailyWotViews(query)
        console.log(response);
        setDailyViews(response);
      } else {
        const response = await fetchWotdViews(query);
        setDailyViews(response.result);
      }
      
 
    } catch (e) {
      console.log(e);
    }
  }

  async function getWatchTime() {
    try {

      let time 
      if(version === 'v2'){
       time = await fetchDailyWotdWatchTime(query)
       console.log(time)
      } else {
        const response = await fetchWotdWatchTime(query);
          time = response.result
      }
      time = Math.floor(parseInt(time) / 60); // minutes
      setAvgWatchTime(time);
      if (time < 60) time = `${time} min`;
      else {
        let hours = Math.floor(time / 60);
        if (hours < 1) hours = 1;
        time = `${hours}hr ${time % 60}min`;
      }
      setWatchTime(time);
    } catch (e) {
      console.log(e);
    }
  }

  async function runSearch(e) {
    if (e && e.preventDefault) e.preventDefault();
    try {
      const criteria = {
        page,
        ...search,
        category: search.category ? search.category.value : null,
      };
      let list 
      if(version === 'v2'){
       list = await fetchDailyContentList(query, criteria)
      } else {
        list = await fetchWotdVideoList(query, criteria);
      }
      setContent({
        content:
          list.content && Array.isArray(list.content) ? list.content : [],
        pages: list.pages,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchCategoryFilterData() {
    try {
      const response = await fetchClassCategoriesDropdown();
      let formattedData = formatArray(response);
      setCategoryOptions(formattedData);
    } catch (e) {
      console.log(e);
    }
  }

  function secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    return `${h > 0 ? h + ":" : ""}${m > 0 ? m : 0}:${s > 9 ? s : "0" + s}`;
  }

  return (
    <div style={{ height: "auto" }}>
      <h3 className={styles.heading}>Workout of the Day</h3>
      <form className={styles.filter}>
        <div>
          <label>Choose Date Range</label>
          <div className={`form-group ${styles.dateRange}`}>
            <input
              max={format(subDays(endDate, 1), "yyyy-MM-dd")}
              type="date"
              value={format(startDate, "yyyy-MM-dd", {})}
              onChange={(e) => handleDate(e, "start")}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span className="p-r:1 p-l:1">to</span>
            <input
              min={format(addDays(startDate, 1), "yyyy-MM-dd")}
              max={format(new Date(), "yyyy-MM-dd")}
              type="date"
              value={format(endDate, "yyyy-MM-dd")}
              onChange={(e) => handleDate(e, "end")}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>
        </div>
      </form>
      <div className={styles.cardWrapper} data-cy='wotd-analytics-card'>
        <AnalyticsCard title={"Daily Views"} data={dailyViews} />
        <AnalyticsCard title={"Total Watch Time"} data={watchTime}/>
        <AnalyticsCard
          title={"Avg Watch Time"}
          data={secondsToHms(avgWatchTime / dailyViews)}
        />
      </div>
      <div data-cy='box-wotd'>
        <WotdLineChart query={query} data-cy='test' version={version} toggle={toggle} setToggle={setToggle}/>
        <TopContent
          page={page}
          setPage={setPage}
          content={content}
          search={search}
          setSearch={setSearch}
          runSearch={runSearch}
          loading={loading}
          setLoading={setLoading}
          categoryOptions={categoryOptions}
        />
      </div>
    </div>
  );
};

export default WotdAnalytics;
