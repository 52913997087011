import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import DashBoxes from './dashBoxes';
import axios from "axios/index";
import ContentDisplayContainer from '../../contentDisplay/MainContainer';
import moment from 'moment';


class CoachDashMain extends Component{
    constructor(props){
        super(props);
           this.state = {
           	expectingContact:null,
           	missedContact:null,
           	unreadTotal:null,
           	totalMembers:null,
           	daysInMonth:moment().daysInMonth(),
           	totalAtRisk:null,
           	atRiskUsernames:null,
       };
    }

    componentDidMount() {
		axios.get(`/admin/coaches/client-contact-details/${this.props.user.id}`)
		.then(res => {
			let coach = res.data.result.coaches[0];
			this.setState({totalMembers:coach.clients.length})
			//filter coaches array for all at risk members
			let clients = coach.clients;
			let atRisk_clients = clients.filter(client=>{
                return client.expectsContact || client.coachMissedContact
			});

			let atRisk_usernames = atRisk_clients.map(client=>client.username);

			//set state of at risk clients
			this.setState({
				totalAtRisk : atRisk_clients.length,
				missedContact : coach.missedClientContacts,
				expectingContact : coach.clientsExpectingContact,
				atRiskUsernames : atRisk_usernames
			});

		});

		axios.get(`/coach/client-count/${this.props.user.username}`)
		.then(res => {
			this.setState({totalMembers: res.data.result})
		});

		axios.get(`/admin/streak/total/average/${this.props.user.id}`)
		.then(res => {
			this.setState({averageStreak: res.data.result.streakAverage})
		});

		axios.get(`/admin/streak/total/median/${this.props.user.id}`)
		.then(res => {
			this.setState({medianStreak: res.data.result.streakMedian})
		});

        axios.get(`/posts/user/${this.props.user.username}/unread`)
            .then((res)=>{
                this.setState({unread:res.data.result});
            });
    }

    render(){
    return(
        <div className="container">
	        <div className="col-sm-12 no-padding-left">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item text-light active">Dashboard</li>  
                </ol>
              </nav>
	        </div>	 
			<DashBoxes 
			atRiskUsernames={this.state.atRiskUsernames}
			totalAtRisk={this.state.totalAtRisk}
			expectingContact={this.state.expectingContact}
			missedContact={this.state.missedContact}
			totalMembers={this.state.totalMembers}
			medianStreak={this.state.medianStreak}
			averageStreak={this.state.averageStreak}
			unread={this.state.unread}
			/>
			<ContentDisplayContainer
				loadLatest={true}
				defaultValue={true}
			/>
        </div>
    )
    }
}

export default withRouter(CoachDashMain);

