import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ContentThumbnail from './contentThumbnail';
import Slider from "react-slick";

class ContentSlider extends Component{
    constructor(props){
        super(props);
        this.state={page:1, slider:this.slider};
        this.renderSlider=this.renderSlider.bind(this);
        this.renderContentThumbs=this.renderContentThumbs.bind(this);
    }

    renderSlider(){
        let regular = [
            {
                breakpoint: 4000,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    swipeToSlide: false,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    swipeToSlide: false,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                    swipeToSlide: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    swipeToSlide: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    swipeToSlide: true,
                }
            }
        ];

        let modal = [
            {
                breakpoint: 4000,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                    swipeToSlide: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    swipeToSlide: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    swipeToSlide: true,
                }
            }
        ]
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            centerMode: false,
            responsive: this.props.modal ? modal : regular,
            
        };
      return(
          <div>
              <Slider {...settings}>
                  {this.renderContentThumbs()}
              </Slider>
          </div>
      )
    };

    renderContentThumbs(){
        if(this.props.hasOwnProperty('content') && this.props.content){
            return this.props.content.map(piece=>{
                console.log(piece.read)
                return (
                    <ContentThumbnail
                        singleTileChild={this.props.singleTileChild}
                        tileId={this.props.tileId}
                        read={piece.read}
                        title={piece.title}
                        thumbnail={piece.thumbnail}
                        content={piece.content}
                        hero_image={piece.hero_image}
                        content_type={piece.content_type}
                        id={piece.id}
                        inbox={this.props.inbox ? this.props.inbox : null}
                        attachContent={this.props.attachContent ? this.props.attachContent : null}
                        handleReadChange={this.handleReadChange}
                    />
                )
            });
        }
    };

    render(){
        return(
            <div>
                {this.renderSlider()}
            </div>
        )
    }
}

export default withRouter(ContentSlider)