import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

// styles
import styles from './newMembersGraph.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const NewMembersGraph = props => {
    // props
    const {data} = props;

    if (!data) {
        return (
            <div className={styles.placeholder}>
                <GraphPlaceholder/>
            </div>
        )
    }

    const dateFormatter = date => {
        const result =  parse(date, 'yyyy-MM-dd', new Date()).getTime();
        return format(result, 'MM/dd/yy');
    };

    return (
        <ResponsiveContainer width="100%" height="100%" debounce={1}>
            <LineChart
                data={data}
                margin={{
                    top: 15,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <XAxis
                    dataKey="date"
                    tickFormatter={dateFormatter}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="members" stroke="#4BBC4E" dot={false} />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default NewMembersGraph;