import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// styles
import styles from './memberGrowthGraph.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";
import parse from "date-fns/parse";
import format from "date-fns/format";
const MemberGrowthGraph = props => {
    // props
    const {data} = props;

    const dateFormatter = date => {
        const result =  parse(date, 'yyyy-MM-dd', new Date()).getTime();
        return format(result, 'MM/dd/yy');
    };

    if (!data) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <ResponsiveContainer width="100%" height="100%" debounce={1}>
                <LineChart
                    data={data}
                    margin={{
                        top: 15,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis dataKey="date" tickFormatter={dateFormatter}/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="members" stroke="#4BBC4E" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default MemberGrowthGraph;