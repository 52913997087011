import axios from 'axios';

//get all assessments for specific user
export const FETCH_USER_ASSESSMENTS = 'FETCH_USER_ASSESSMENTS';
export function fetchUserAssessments(username){
  let url = `/assessment/${username}/page/1`;
  let assessmentsByUserRequest = axios.get(url);

  return {
    type: FETCH_USER_ASSESSMENTS,
    payload: assessmentsByUserRequest
  };
}

//get single assessment based on id
export const FETCH_ASSESSMENTS_BY_ID = 'FETCH_ASSESSMENTS_BY_ID';
export function fetchAssessmentsById(id,username){
  let url;
  if(username){
      url= `/assessment/single/${id}?username=${username}`;
  }else{
      url= `/assessment/single/${id}`;
  }
  let assessmentsByIdRequest = axios.get(url);

  return {
    type: FETCH_ASSESSMENTS_BY_ID,
    payload: assessmentsByIdRequest
  };

}

//change responses to assessments as user inputs them
export const RESPONSE_CHANGE = 'RESPONSE_CHANGE';
export function alterResponses(response){
    return{
        type:RESPONSE_CHANGE,
        payload: response
    }
}

//change responses to assessments as user inputs them
export const RESET_RESPONSES = 'RESET_RESPONSES';
export function resetResponses(){
    return{
        type:RESET_RESPONSES,
        payload: []
    }
}
