import axios from 'axios';

//get all the data on the user making the call
export const FETCH_SELF = 'FETCH_SELF';
export function fetchSelf(){
    let url = `/user`;
    let userRequest = axios.get(url);
    return{
        type: FETCH_SELF,
        payload:userRequest
    };
}

//get all the data of a provided user
export const FETCH_SINGLE_USER = 'FETCH_SINGLE_USER';
export function fetchSingleUser(username){
    let url = `/user/client/single/${username}`;
    let singleUserRequest = axios.get(url);

    return{
        type: FETCH_SINGLE_USER,
        payload:singleUserRequest
    }
}

//clear single user current state
export const CLEAR_SINGLE_USER = 'CLEAR_SINGLE_USER';
export function clearSingleUserState() {
    return{
        type:CLEAR_SINGLE_USER,
        payload:null
    }
}

//set a username filter for client searching
export const SET_USERNAME_SEARCH = 'SET_USERNAME_SEARCH';
export function setUsernameSearch(usernames){
    return{
        type : SET_USERNAME_SEARCH,
        payload : usernames
    }
}

//clear username search array
export const CLEAR_USERNAME_SEARCH = 'CLEAR_USERNAME_SEARCH';
export function clearUsernameSearch(){
    return {
        type : CLEAR_USERNAME_SEARCH,
        payload : null,
    }
}