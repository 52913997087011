import {GET_INTRO_TILES} from "../actions/action_tile";
import {GET_SINGLE_INTRO_TILE} from "../actions/action_tile";
import {SET_SINGLE_INTRO_TILE} from "../actions/action_tile";

export function tileListReducer(state=null, action){
    switch(action.type){
        case GET_INTRO_TILES:
            return action.payload.data.result
        // no default
    }
    return state;
}

export function tileListPageReducer(state=null, action){
    switch(action.type){
        case GET_INTRO_TILES:
            return 15//action.payload.data.count
        // no default
    }
    return state;
}

export function introTileReducer(state=null, action){
    switch(action.type){
        case GET_SINGLE_INTRO_TILE:
            return {tile:action.payload.data.tile, plan:action.payload.data.plan,};

        case SET_SINGLE_INTRO_TILE:
            return action.payload;
        // no default
    }
    return state;
}