import React, {useState, useEffect} from "react";

// styles
import styles from './imageThumbnailDetailed.module.css';

export function ImageThumbnailDetailed({ title='', image, setImage, setTemporary }) {
    const [preview, setPreview] = useState(null);

    function setTemporaryThumbnail(file) {
        const reader = new FileReader();

        reader.onload = function(event) {
            setPreview(event.target.result);
            setImage(file);
            if (setTemporary) setTemporary(event.target.result);
        };

        reader.readAsDataURL(file);
    }

    function remove(){
        setPreview(null);
        setImage(null);
    }

    // change the preview to an image if passed in
    useEffect(() => {
        if (image && image.name) {
            setTemporaryThumbnail(image);
        } else if (image && typeof image === 'string') {
            setPreview(image)
            if (setTemporary) setTemporary(image);
        } else {
            setPreview(null);
            if (setTemporary) setTemporary(null);
        }
    }, [image]);

    // Render
    if (preview) {
        let imageName = '';
        if (typeof image === 'string') imageName = image;
        if (image && image.name) imageName = image.name;
        return (
            <div className='clearfix'>
                <p>{ title }</p>
                <img className={styles.image} src={preview} alt="preview"/>
                <div className={styles.details}>
                    <p className={styles.fileName}>{imageName}</p>
                    <p onClick={remove}>x Remove</p>
                </div>
            </div>
        )

    }
    return (
        <div>
            <label htmlFor={`${title}_image`}>{ title }</label>
            <input
                className={`select-form-control ${styles.input}`}
                onChange={e => setTemporaryThumbnail(e.target.files[0])}
                id={`${title}_image`}
                accept="image/*"
                type="file"/>
        </div>
    )
}
