import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { sanitize } from "dompurify";

class WYSIWYGTinyMCE extends React.Component {
    constructor(props){
        super(props);
        this.state={
            editor: null,
            content: this.props.value || ''
        };
    }
    handleEditorChange = (content, editor) => {
        this.setState({ content });
        this.props.onChangeHandler(sanitize(content));
    }

    render() {
        const that = this;
        return (
            <div style={this.props.style} className={this.props.className}>
                <label>{this.props.lable}</label>
                <Editor
                    init={{
                        plugins: this.props.plugins,
                        toolbar: this.props.toolbar,
                        images_upload_handler: function (blobInfo, success, failure) {
                            let upload_type = blobInfo.blob().type;
                            let extension = blobInfo.filename().split('.').pop();
                            axios.post(`/content/create-url/${extension}`,{upload_type})
                                .then(presigned_url=>{
                                    axios.put(presigned_url.data.result.presigned_url, blobInfo.blob(),{
                                        headers:{
                                            'Content-Type':upload_type
                                        }
                                    })
                                        .then(()=>{
                                            success(presigned_url.data.result.new_url);
                                        })
                                })
                                .catch(e=>console.log(e));
                        },
                        setup:editor=>{
                            this.setState({editor})
                        },
                        init_instance_callback: function(editor) {
                            editor.on('blur', (e) => {
                                if (that.props.onBlur) that.props.onBlur(editor.getContent());
                            });
                        }
                    }}
                    value={this.state.content}
                    onEditorChange={this.handleEditorChange}
                    // onChange={(event) =>{
                    //     this.props.onChangeHandler(sanitize(event.target.getContent()));
                    // }
                    // }
                    // onInput={(event) => {
                    //     this.props.onChangeHandler(sanitize(event.target.getContent()));
                    // }
                    // }
                    // onKeyUp={(event) => {
                    //     this.props.onChangeHandler(sanitize(this.state.editor.getContent()));
                    // }
                    // }
                />
            </div>
        );
    }
}

export default WYSIWYGTinyMCE;


