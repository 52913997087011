import React from'react';
import {Route} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import AdminSummary from "./AdminSummary"


const AdminDisplay = ({match, username}) =>{
    return(
        <div>
            <Route
                exact
                path={match.url}
                render={()=><AdminSummary match={match} username={username}/>}
            />
        </div>
    )

};


export default withRouter(AdminDisplay);
