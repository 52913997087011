import React from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

// styles
import styles from './coachAssessment.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {HEALTH_GOALS} from "../../../serverVariables/healthGoals";
import WeightLossIcon from "../../../images/icon-pathwayreport-primary_weightloss.png";
import BodyCompIcon from "../../../images/icon-pathwayreport-primary_bodycomp.png";
import BrainHealthIcon from "../../../images/icon-pathwayreport-primary_brainhealth.png";
import EnergyIcon from "../../../images/icon-pathwayreport-primary_energy.png";
import ActivityIcon from "../../../images/icon-pathwayreport-primary_activity.png";
import FoodChoicesIcon from "../../../images/icon-pathwayreport-primary_foodchoices.png";
import BetterBloodSugarIcon from "../../../images/icon-pathway-bbs.png";
import BetterBloodPressureIcon from "../../../images/icon-pathway-bbp.png";
import SingleQuestion from "../singleQuestion/singleQuestion";

const CoachAssessment = props => {
    // props
    const {assessment, questions} = props;
    function chooseHealthIcon(id) {
        switch(id) {
            case HEALTH_GOALS.LASTING_WEIGHT_LOSS:
                return WeightLossIcon;
            case HEALTH_GOALS.STRONGER_LEANER_BODY:
                return BodyCompIcon;
            case HEALTH_GOALS.IMPROVE_HEART_COGNITIVE_HEALTH:
                return BrainHealthIcon;
            case HEALTH_GOALS.MORE_ENERGY_LESS_STRESS:
                return EnergyIcon;
            case HEALTH_GOALS.PHYSICAL_ACTIVITY_HABIT:
                return ActivityIcon;
            case HEALTH_GOALS.HEALTHIER_FOOD_CHOICES:
                return FoodChoicesIcon;
            case HEALTH_GOALS.BETTER_BLOOD_SUGAR:
                return BetterBloodSugarIcon;
            case HEALTH_GOALS.BETTER_BLOOD_PRESSURE:
                return BetterBloodPressureIcon;
            // no default
        }
    }
    return (
        <BoxContainer className={styles.container}>
            <div className={styles.heading}>
                <div>
                    <h3 className={styles.headingText}>Assessment Statistics</h3>
                    <div className={styles.statistics}>
                        <div>
                            <div>
                                <span>Complete: {
                                    assessment.assessment_completed ?
                                        <span className='green'>{format(parseISO(assessment.assessment_completed), 'MM/dd/yyyy')}</span>
                                        :
                                        <span className="text-warning">Incomplete</span>
                                }</span>
                            </div>
                            <div>
                                <span>Attempts: {
                                    assessment.attempt_log && assessment.attempt_log.length ?
                                        <span className='green'>{assessment.attempt_log.length}</span>
                                        :
                                        <span className="text-info">N/A</span>
                                }</span>
                            </div>
                            <div>
                                <span>Time to complete: {
                                    assessment.completion_time && assessment.attempt_log && assessment.attempt_log.length ?
                                        <span className='green'>{assessment.completion_time}</span>
                                        :
                                        <span className="text-info">N/A</span>
                                }</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className={styles.headingText}>Health Goal</h3>
                    <div className={styles.healthGoal}>
                        <img src={chooseHealthIcon(assessment.healthgoal.id)} alt=""/>
                        <div className={styles.goal}>
                            <span>{assessment.healthgoal.label}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h3 className={styles.headingText}>Questions</h3>
                <div className={styles.questionsContainer}>
                    {questions.map((q,i) => {
                        return(
                            <div className={styles.questions}>
                                <SingleQuestion question={q} ordinal={i}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </BoxContainer>
    )
}

export default CoachAssessment;