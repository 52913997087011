import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

// styles
import styles from './organizationReporting.module.css';

// actions
import {fetchUserContentHistory} from "../../../store/actions/contentActions";

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import Loading from "../../Loading/Loading";

const OrganizationReporting = props => {
    // state
    const {singleUser} = props;
    // props
    const {sort} = props;
    // local
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadHistory();
    },[sort]);

    async function loadHistory() {
        try {
            setLoading(true);
            const res = await fetchUserContentHistory(singleUser.user.id, sort);
            setContent(res);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    function showCategories(content) {
        if (content.category.subcategory) {
            return (
                <div>
                    <div>{content.category.name}</div>
                    <div className='d:f'>
                        <span className={styles.childCategory}/><span>{content.category.subcategory.name}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div>{content.category.name}</div>
            )
        }
    }

    function createTimeLabel(time) {
        let diff = parseInt(time);
        let label = 's';
        if (diff > 60) {
            diff = Math.ceil(diff/60);
            label = 'm';
        }
        if (diff > 60) {
            diff = Math.ceil(diff/60);
            label = 'h';
        }
        if (diff > 24 && label === 'h') {
            diff = Math.ceil(diff/24);
            label = 'd';
        }
        if (diff > 7 && label === 'd') {
            diff = Math.ceil(diff/7);
            label = 'w';
        }
        if (diff > 52 && label === 'w') {
            diff = Math.ceil(diff/52);
            label = 'y'
        }
        diff = `${diff}${label}`;
        return(diff);
    }

    if (loading) {
        return (
            <div className="container margin-top-lrg">
                <Loading/>
            </div>
        )
    }
    return (
        <div>
            {content.map(piece => {
                return (
                    <BoxContainer key={piece.id} className={styles.historyItem}>
                        <div className={styles.thumbnail} style={{backgroundImage: `url(${piece.thumbnail})`}}/>
                        <div className={styles.info}>
                            <h2 className={styles.title}>{piece.content_title}</h2>
                            <p>
                                <span className={styles.length}><strong>Length: </strong>{piece.content_length} min</span>
                                <span><strong>Published: </strong>{piece.publish_date ? format(parseISO(piece.publish_date), 'MMM do, yyyy') : null}</span></p>
                            <p>
                                <span className={styles.watched}><strong>Watched: </strong>{piece.last_watched ? format(parseISO(piece.last_watched), 'MMM do, yyyy') : null}</span>
                                <span><strong>Total Views: </strong>{piece.views}</span>
                            </p>
                            <p>
                                <span><strong>Duration Watched: </strong>{createTimeLabel(piece.total_time)}</span>
                            </p>
                        </div>
                        <div className={styles.categories}>
                            <span className={styles.format}><strong>Format</strong><div>{piece.format}</div></span>
                            <div>
                                <strong>Category</strong>
                                {showCategories(piece)}
                            </div>
                        </div>
                    </BoxContainer>
                )
            })}
        </div>
    )
};

function mapStateToProps({user, singleUser}) {
    return ({user, singleUser})
}

export default connect(mapStateToProps)(OrganizationReporting);