import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import CreateHeader from './createHeader';
import ContentCreatorForm from './contentCreatorForm';

class ContentCreator extends Component{
    render(){
        return(
            <div>
                <CreateHeader/>
                <ContentCreatorForm {...this.props}/>
            </div>
        );
    }
}

export default withRouter(ContentCreator);