import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { connect } from "react-redux";
import { setClientPathway } from "../../store/actions/pathwaysActions";
import { usePrevious } from "../../hooks/usePrevious";
import _ from "lodash";

const ClientAssessmentListContainer = (props) => {
  const { assessments, clientPathway } = props;

  const { setClientPathway } = props;

  const prevClientPathway = usePrevious(clientPathway); // hold the previous state of client pathways

  useEffect(() => {
    if (assessments && assessments.length > 0) {
      // Check if assessments is not undefined and has at least one item
      const pathwayId = assessments[0].pathway_id;
      console.log(pathwayId);
      const res = setClientPathway(pathwayId);
      console.log(res)
    } else {
      console.log('Assessments are undefined or empty.');
    }
  }, [clientPathway, assessments]);


useEffect(() => {
  if (!_.isEqual(clientPathway, prevClientPathway)) {
    console.log(clientPathway)
  }
}, [clientPathway]);

if (_.isEmpty(clientPathway)) return ( <div/> );
  return (
    <div className="row">
      <ul className="tiles-ul">
        {assessments.map((form) => (
          <li className="tiles-li col-xs-12 col-sm-6">
            {clientPathway.lifestyle ? (
              <Link
                to={`/member/clientDash/${props.match.params.username}/assessments/${form.pathway_id}`}
              >
                <div className={"tile"}>
                  <div className="tile-titles">
                    <h4>
                      Assessment:{" "}
                      {format(parseISO(form.assigned_date), "MM/dd/yyyy")}
                    </h4>
                  </div>
                </div>
              </Link>
            ) : (
              <Link to={`/member/onboard-assessment?pageNum=3`}>
                <div className={"tile"}>
                  <div className="tile-titles">
                    <h4>
                      Take Assessment{" "}
                      {format(parseISO(form.assigned_date), "MM/dd/yyyy")}
                    </h4>
                  </div>
                </div>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const actions = {
  setClientPathway  ,
};
function mapStateToProps({ user, clientPathway }) {
  return { user, clientPathway };
}

export default withRouter(connect(mapStateToProps, actions)(ClientAssessmentListContainer))

