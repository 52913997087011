import React from 'react'
import styles from './DailyWotd.module.css';

const DailyWotdHeader = () => {
  return (
    <div>
    <h1 className={styles.headerTitle}>WELCOME TO WORKOUT OF THE DAY</h1>
    <p className={styles.headerSubtitle}>Choose how you move</p>
    
    </div>  )
}

export default DailyWotdHeader