// npm modules
import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {connect} from "react-redux";

// styles
import styles from "./pathwaysContentSingle.module.css";

// actions
import {
    fetchNextContent,
    fetchSingleContent,
    recordContentView,
} from "../../store/actions/pathwaysActions";

// components
import { PrimaryButton} from "../buttons/buttons";
import VimeoVideoPlayer from "../vimeoVideoPlayer/vimeoVideoPlayer";
import ClientHeader from "../clientHeader/clientHeader";
import BrightcovePlayer from "../brightcovePlayer/brightcovePlayer";

const PathwayContentSingle = ({user, ...props}) => {
    // props
    const params = new URLSearchParams(props.location.search);
    const pathwayId = params.get('pathwayId');
    const lifestyleId = params.get('lifestyleId');
    const contentId = props.match.params.contentId;
    // local
    const [jfy, setJfy] = useState(params.get('just_for_you'));
    const [content, setContent] = useState(null);
    const [nextContent, setNextContent] = useState(null);
    const [viewed, setViewed] = useState(false);

    // grab next video on mount
    useEffect(() => {
        if (!nextContent && contentId && content) {
            fetchNextContent(contentId)
                .then(result => {
                    if (Array.isArray(result) && result.length > 0) {
                        return setNextContent(result[0]);
                    } else if (result) {
                        setNextContent(result);
                    }
                })
                .catch(e => console.log(e));
        }
    }, [content]);

    // grab content on mount
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setNextContent(null);
        fetchSingleContent(contentId)
            .then(result => {
                if (result.length) {
                    const content = result.shift();
                    // check if jfy content
                    if (content.categories[0].parent_category && content.categories[0].parent_category.name === 'just for you') {
                        setJfy('true');
                        props.history.push(`${props.match.url}?pathwayId=${pathwayId}&lifestyleId=${lifestyleId}&just_for_you=true`);
                    }
                    if (window.location.href.lastIndexOf('localhost') > 0) {
                        setViewed(true);
                    } else{
                        setViewed(content.completed);
                    }

                    setContent(content);
                }
            })
            .catch(e => console.log(e));
    }, [contentId]);

    // mark as complete after x seconds
    useEffect(() => {
        const seconds = window.location.href.lastIndexOf('localhost') > 0 ? .5 : 10;
        const timer = setTimeout(() => {
            recordContentView(contentId)
                .catch(e => console.log(e));
            setViewed(true);
        }, 1000 * seconds);
        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [contentId]);

    console.log(content)
    if (!content || _.isEmpty(content) || !user) return <div/>;

    const backUrl = () => {
        if (pathwayId === 'just_for_you') return '/member/just_for_you';
        if (jfy) return `/member/just_for_you/category/${lifestyleId}`;
        if (pathwayId && !lifestyleId) return `/member/pathways/${user.username}/pathway?pathwayId=${pathwayId}`;
        if (pathwayId && lifestyleId) return `/member/pathways/${user.username}/pathway?pathwayId=${pathwayId}&lifestyleId=${lifestyleId}`;
        return '/';
    };

    const nextUrl = () => {
        let nextLifestyle = lifestyleId;
        let nextPathway = pathwayId;
        const updated = nextContent.categories[0];
        if (updated.parent_category && updated.parent_category.id) nextPathway = updated.parent_category.id;
        if (lifestyleId && lifestyleId !== updated.id) nextLifestyle = updated.id;
        return `/member/pathways/${user.username}/content/${nextContent.id}?pathwayId=${nextPathway}${lifestyleId ? '&lifestyleId=' + nextLifestyle : ''}`;
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }
    return (
        <>
            <ClientHeader type={'transparent'} backUrl={backUrl()}/>
            <div>
                <div className={`wellness-bg wydBG pathwaysBackground`}>
                    <div className='pathwaysOverlay'/>
                    <div className="container client-dash">
                        <div className="row">
                            <div className="col-xs-12">

                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container client-dash">
                        <div className="row">
                            <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                                <div className='tour-pathway-content'>
                                    {isValidHttpUrl(content.content) ?
                                        <VimeoVideoPlayer
                                            id={content.id}
                                            identifier={user.username}
                                            src={content.content}/>
                                        :
                                        <BrightcovePlayer
                                            offset
                                            id={content.id}
                                            identifier={user.username}
                                            videoId={content.content}/>
                                    }
                                </div>


                                <div className={styles.pathwayContent}>
                                    <p className='m:0'>{content.categories.map(category => {
                                        return (
                                            <span className='d:f margin-bottom-15 a:i-c'>
                                               {category.parent_category && category.parent_category.name ?
                                                   <span>
                                                       <span className={styles.category}>{category.parent_category.name}</span>
                                                       |
                                                   </span>
                                                   : null
                                               }
                                               <span className={styles.category}>{category.name}</span>
                                            </span>
                                            )
                                        })}
                                    </p>
                                    <PrimaryButton
                                        disabled={!viewed}
                                        onClick={() => props.history.push(nextUrl())}
                                        className={`${styles.inlineNext} tour-pathway-next`}>Next Video</PrimaryButton>
                                    <h1 className='m-t:0'>{content.title}</h1>
                                    <div dangerouslySetInnerHTML={{__html: content.description}}/>
                                </div>

                                <div className="d:f j-c:c">
                                    {user && nextContent ? (
                                        <PrimaryButton
                                            onClick={() => props.history.push(nextUrl())}
                                            className="btn primary"
                                            disabled={!viewed}
                                        >
                                            Next Video
                                        </PrimaryButton>
                                    ) : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function mapStateToProps({user}) {
    return {user};
}

export default withRouter(connect(mapStateToProps)(PathwayContentSingle));
