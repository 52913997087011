import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'

export const TimeRangeHeader = (props) => {
  return (
      <div className={props.width > 891 ? "col-xs-12 col-md-7 no-padding-left no-padding-right" : "col-xs-12 col-md-7 margin-top-lrg no-padding-left no-padding-right"}>
        <ButtonGroup className="btn-group btn-group-justified graph--options">
          <div className="btn-group" role="group">
          <Button className={props.timerange === "week" ? "active" : null} onClick={(e) => {props.handleHeaderChange('week', 'weeks')} }>
            Week
          </Button>
          </div>
          <div className="btn-group" role="group">
          <Button className={props.timerange === "month" ? "active" : null} onClick={(e) => {props.handleHeaderChange('month', 'months')} } >
            Month
          </Button>
          </div>
          <div className="btn-group" role="group">
          <Button className={props.timerange === "year" ? "active" : null} onClick={(e) => {props.handleHeaderChange('year', 'years')} }>
            Year
          </Button>
          </div>
        </ButtonGroup>
      </div>

  )
}
