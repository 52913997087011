import React, {Component} from 'react';
import profileImage from '../../../../images/avatar-1577909_960_720.png';

export class ImageUpload extends Component{
    constructor(props){
        super(props);
        this.state = {
            maxWidth : 1200,
            maxHeight : 1200,
        };
        // this.resizeImage = this.resizeImage.bind(this);
        this.uploadProfileImage=this.uploadProfileImage.bind(this);
    }

    triggerFileInput(){
        document.getElementById('profileImage').click();
    }

    uploadProfileImage(e){
        this.props.setProfileImage(e.target.files[0]);
    }


    // resizeImage(blob, maxWidth, maxHeight, orientation){
    //     let that = this;
    //     let maximum_width = maxWidth;
    //     let maximum_height = maxHeight;
    //     ImageTools.resize(blob, {
    //         width: maxWidth, // maximum width
    //         height: maxHeight, // maximum height
    //         orientation
    //     }, function(blob, didItResize) {
    //         // didItResize will be true if it managed to resize it, otherwise false (and will return the original file as 'blob')
    //         //1MB size limit
    //         if(blob.size > (1024 * 1024)){
    //             //if size is too big still call reducer function again and change size to 75% of original until it is less than needed
    //             maximum_width = (maximum_width * .75);
    //             maximum_height = (maximum_height * .75);
    //             that.resizeImage(blob, maximum_width, maximum_height);
    //         }else{
    //             that.props.setProfileImage(blob);
    //         }
    //     });
    // }

    render(){
        return(
            <div onClick={this.triggerFileInput}>
                <div className='clearfix'>
                    <div className='profile-image' >
                        <img className="responsive-image" src={this.props.image ? this.props.image : profileImage} alt="Profile"/>
                    </div>
                </div>
                <h5>New Image</h5>
                <input id='profileImage' type="file" accept="image/png, image/jpeg, image/jpg" className='profileInput' onChange={this.uploadProfileImage}/>
            </div>
        )
    }
}

// if (typeof exports === "undefined") {
//     var exports = {};
// }
//
// if (typeof module === "undefined") {
//     var module = {};
// }
//
// Object.defineProperty(exports, '__esModule', {
//     value: true
// });
//
// var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();
//
// function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }
//
// var hasBlobConstructor = typeof Blob !== 'undefined' && (function () {
//     try {
//         return Boolean(new Blob());
//     } catch (e) {
//         return false;
//     }
// })();
//
// var hasArrayBufferViewSupport = hasBlobConstructor && typeof Uint8Array !== 'undefined' && (function () {
//     try {
//         return new Blob([new Uint8Array(100)]).size === 100;
//     } catch (e) {
//         return false;
//     }
// })();
//
// var hasToBlobSupport = typeof HTMLCanvasElement !== "undefined" ? HTMLCanvasElement.prototype.toBlob : false;
//
// var hasBlobSupport = hasToBlobSupport || typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined';
//
// var hasReaderSupport = typeof FileReader !== 'undefined' || typeof URL !== 'undefined';
//
// var ImageTools = (function () {
//     function ImageTools() {
//         _classCallCheck(this, ImageTools);
//     }
//
//     _createClass(ImageTools, null, [{
//         key: 'resize',
//         value: function resize(file, maxDimensions, callback) {
//             if (typeof maxDimensions === 'function') {
//                 callback = maxDimensions;
//                 maxDimensions = {
//                     width: 640,
//                     height: 480
//                 };
//             }
//
//             var maxWidth = maxDimensions.width;
//             var maxHeight = maxDimensions.height;
//             var orientation = maxDimensions.orientation;
//
//             if (!ImageTools.isSupported() || !file.type.match(/image.*/)) {
//                 callback(file, false);
//                 return false;
//             }
//
//             if (file.type.match(/image\/gif/)) {
//                 // Not attempting, could be an animated gif
//                 callback(file, false);
//                 return false;
//             }
//
//             var image = document.createElement('img');
//
//             image.onload = function (imgEvt) {
//                 var width = image.width;
//                 var height = image.height;
//                 var isTooLarge = false;
//
//                 if (width > height && width > maxDimensions.width) {
//                     // width is the largest dimension, and it's too big.
//                     height *= maxDimensions.width / width;
//                     width = maxDimensions.width;
//                     isTooLarge = true;
//                 } else if (height > maxDimensions.height) {
//                     // either width wasn't over-size or height is the largest dimension
//                     // and the height is over-size
//                     width *= maxDimensions.height / height;
//                     height = maxDimensions.height;
//                     isTooLarge = true;
//                 }
//
//                 if (!isTooLarge) {
//                     // early exit; no need to resize
//                     callback(file, false);
//                     return;
//                 }
//
//                 var canvas = document.createElement('canvas');
//                 canvas.width = width;
//                 canvas.height = height;
//
//                 var ctx = canvas.getContext('2d');
//                 switch (orientation) {
//                     case 2:
//                         // horizontal flip
//                         ctx.translate(width, 0)
//                         ctx.scale(-1, 1)
//                         break
//                     case 3:
//                         // 180° rotate left
//                         ctx.translate(width, height)
//                         ctx.rotate(Math.PI)
//                         break
//                     case 4:
//                         // vertical flip
//                         ctx.translate(0, height)
//                         ctx.scale(1, -1)
//                         break
//                     case 5:
//                         // vertical flip + 90 rotate right
//                         ctx.rotate(0.5 * Math.PI)
//                         ctx.scale(1, -1)
//                         break
//                     case 6:
//                         // 90° rotate right
//                         ctx.rotate(0.5 * Math.PI)
//                         ctx.translate(0, -height)
//                         break
//                     case 7:
//                         // horizontal flip + 90 rotate right
//                         ctx.rotate(0.5 * Math.PI)
//                         ctx.translate(width, -height)
//                         ctx.scale(-1, 1)
//                         break
//                     case 8:
//                         // 90° rotate left
//                         ctx.rotate(-0.5 * Math.PI)
//                         ctx.translate(-width, 0)
//                         break
//                 }
//
//                 ctx.drawImage(image, 0, 0, width, height);
//
//
//                 if (hasToBlobSupport) {
//                     canvas.toBlob(function (blob) {
//                         callback(blob, true);
//                     }, file.type);
//                 } else {
//                     var blob = ImageTools._toBlob(canvas, file.type);
//                     callback(blob, true);
//                 }
//             };
//             ImageTools._loadImage(image, file);
//
//             return true;
//         }
//     }, {
//         key: '_toBlob',
//         value: function _toBlob(canvas, type) {
//             var dataURI = canvas.toDataURL(type);
//             var dataURIParts = dataURI.split(',');
//             var byteString = undefined;
//             if (dataURIParts[0].indexOf('base64') >= 0) {
//                 // Convert base64 to raw binary data held in a string:
//                 byteString = atob(dataURIParts[1]);
//             } else {
//                 // Convert base64/URLEncoded data component to raw binary data:
//                 byteString = decodeURIComponent(dataURIParts[1]);
//             }
//             var arrayBuffer = new ArrayBuffer(byteString.length);
//             var intArray = new Uint8Array(arrayBuffer);
//
//             for (var i = 0; i < byteString.length; i += 1) {
//                 intArray[i] = byteString.charCodeAt(i);
//             }
//
//             var mimeString = dataURIParts[0].split(':')[1].split(';')[0];
//             var blob = null;
//
//             if (hasBlobConstructor) {
//                 blob = new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], { type: mimeString });
//             } else {
//                 // var bb = new BlobBuilder();
//                 // bb.append(arrayBuffer);
//                 blob = new Blob(arrayBuffer);
//             }
//
//             return blob;
//         }
//     }, {
//         key: '_loadImage',
//         value: function _loadImage(image, file, callback) {
//             if (typeof URL === 'undefined') {
//                 var reader = new FileReader();
//                 reader.onload = function (evt) {
//                     image.src = evt.target.result;
//                     if (callback) {
//                         callback();
//                     }
//                 };
//                 reader.readAsDataURL(file);
//             } else {
//                 image.src = URL.createObjectURL(file);
//                 if (callback) {
//                     callback();
//                 }
//             }
//         }
//     }, {
//         key: 'isSupported',
//         value: function isSupported() {
//             return typeof HTMLCanvasElement !== 'undefined' && hasBlobSupport && hasReaderSupport;
//         }
//     }]);
//
//     return ImageTools;
// })();
//
// function getOrientation(file, callback) {
//     var reader = new FileReader();
//     reader.onload = function(e) {
//
//         var view = new DataView(e.target.result);
//         if (view.getUint16(0, false) != 0xFFD8)
//         {
//             return callback(-2);
//         }
//         var length = view.byteLength, offset = 2;
//         while (offset < length)
//         {
//             if (view.getUint16(offset+2, false) <= 8) return callback(-1);
//             var marker = view.getUint16(offset, false);
//             offset += 2;
//             if (marker == 0xFFE1)
//             {
//                 if (view.getUint32(offset += 2, false) != 0x45786966)
//                 {
//                     return callback(-1);
//                 }
//
//                 var little = view.getUint16(offset += 6, false) == 0x4949;
//                 offset += view.getUint32(offset + 4, little);
//                 var tags = view.getUint16(offset, little);
//                 offset += 2;
//                 for (var i = 0; i < tags; i++)
//                 {
//                     if (view.getUint16(offset + (i * 12), little) == 0x0112)
//                     {
//                         return callback(view.getUint16(offset + (i * 12) + 8, little));
//                     }
//                 }
//             }
//             else if ((marker & 0xFF00) != 0xFF00)
//             {
//                 break;
//             }
//             else
//             {
//                 offset += view.getUint16(offset, false);
//             }
//         }
//         return callback(-1);
//     };
//     reader.readAsArrayBuffer(file);
// }

