import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

// styles
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import styles from "./singleTile.module.css";
import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// actions
import { fetchTile, clearTile, clearPlan } from "../../../actions/action_tile";
import { getUserWellnessScore } from "../../../actions/action_wellnessScore";
import { fetchSingleUser } from "../../../actions/action_user";

// components
import { GoalBarSingleTile } from "../CreateTile/GoalBarSingleTile";
import NotesMain from "../Notes/NotesMain";
import ContentDisplayContainer from "../../contentDisplay/MainContainer";
import { SingleTileStats } from "./singleTileStats";
import LineGraph from "../adminGraph/LineGraph";
import PlanSelector from "./PlanSelector";
import { TimeRangeHeader } from "../../Client/graph/timeRangeHeader";
import YearSelect from "../adminGraph/YearSelect";
import { ReactComponent as CircleCheckMark } from "../../../images/icons/circle_checkmark.svg";
import { ReactComponent as CircleTimes } from "../../../images/icons/circle_times.svg";

class SingleTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: [],
      user: "",
      tileId: props.match.params.id,
      tile: "",
      plans: "",
      planId: 0,
      value: [1, 11],
      scaleStart: 0,
      scaleEnd: 20,
      planStatus: "",
      minPoint: 0,
      maxPoint: 0,
      show: false,
      archived: "",
      value1: 0,
      value2: 0,
      initialPlanUnits: "",
      description: "",
      category: "",
      noteTitle: "",
      notes: "",
      singleTileChild: true,
      graph_data: [],
      bestActivity: "",
      worstActivity: "",
      planAverage: "",
      planUnitsHighlights: "",
      differenceHighlights: "",
      lastActivityHighlight: "",
      differenceGoalHighlights: "",
      increment: [0, 0],
      tileStatus: "active",
      //content view state
      content_categories: [],

      //related to an intro tiles last log
      last_log: null,

      timeChangeDisabled: false,
      timerange: "week",
    };
    this.enableTimeChange = this.enableTimeChange.bind(this);
    this.fetchGraphDataSelect = this.fetchGraphDataSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.fetchGraphDataHeader = this.fetchGraphDataHeader.bind(this);
    this.renderGraph = this.renderGraph.bind(this);
  }

  componentDidMount() {
    this.props.fetchTile(this.state.tileId);

    axios.get("/tiles/statuses");
    axios
      .get("/tiles/single/" + this.state.tileId)
      .then((res) => {
        this.setState({ tileStatus: res.data.tile.tile_status });
        this.setState({ currentStreak: res.data.tile.streak_data.current });
        this.setState({ tile: res.data.tile });
        this.setState({ plans: res.data.plan });
        this.setState({ planId: res.data.plan[0].id });
        this.setState({ planStatus: res.data.plan[0].status });
        this.setState({ value1: res.data.plan[0].minimum_point });
        this.setState({ value2: res.data.plan[0].maximum_point });
        this.setState({ scaleStart: res.data.plan[0].scale_start });
        this.setState({ scaleEnd: res.data.plan[0].scale_end });
        this.setState({ initialPlanUnits: res.data.plan[0].scale_units });
        this.setState({ description: res.data.plan[0].description });
        this.setState({ category: res.data.tile.tile_category_id });
        this.setState({
          selected: { label: res.data.plan[0].description },
          selected_id: res.data.plan[0].id,
          timerange: "week",
        });
        //this.fetchGraphDataSelect(this.state.plan[0].id);
        this.setState({ plan_in_graph_focus: res.data.plan[0] });
        this.setState({ increment: [0, res.data.plan[0].incrementer] });

        //set content categories
        let list = res.data.plan.map((item) => ({ name: item.description }));
        this.setState({ content_categories: list });
      })
      .catch((error) => console.log(error))
      .then(() => {
        this.fetchGraphDataInitial(this.state.planId);
        this.fetchBestWorstActivity(this.state.planId);
      });

    // this.fetchBestWorstActivity(this.state.planId)

    axios.get(`/user/client/single/${this.props.username}`).then((res) => {
      this.setState({ user: res.data.results });
    });
  }

  fetchGraphDataInitial(planId) {
    this.handleTimeChange(this.state.timerange);
  }

  componentWillUnmount() {
    this.props.clearTile();
    // this.props.clearPlans();
  }

  handleSelectChange = (e) => {
    const { value } = e.target;
    e
      ? this.setState({ selected: value, selected_id: value }, () => {
          this.fetchGraphDataInitial(value);
        })
      : this.setState({ selected: "" });

    //set plan that is in focus upon select change
    if (this.props.plans && e) {
      let plan = this.props.plans.filter((item) => item.id === e.value);
      this.setState({ plan_in_graph_focus: plan[0] });
    }
  };

  fetchGraphDataSelect(planId) {
    let date = moment().startOf("year");
    let today = moment();
    let days = moment(today.diff(date, "days"));
    days += 1;
    axios
      .get(`/plan/metrics/plan/${planId}?date=${date}&days=${days}`)
      .then((res) => {
        this.setState({ graph_data: res.data.results });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`/plan/metric/last-activity/${this.props.username}`)
      .then((res) => {
        console.log(planId);
        console.log(res.data);
        res.data.result.plan.map((plan) => {
          console.log(plan.id);
          if (plan.id === planId) {
            this.setState({ lastActivityHighlight: plan.last_activity_value });
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`/plan/average/${planId}?days=100000`)
      .then((res) => {
        console.log(res);
        this.setState({ planAverage: res.data.results.average });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`/tiles/single/plan/${planId}`)
      .then((res) => {
        console.log(res.data);
        this.setState({ planUnitsHighlights: res.data.plan[0].units });

        if (res.data.plan[0].inverted === true) {
          console.log(
            `planAverage = ${this.state.planAverage}, minPoint = ${res.data.plan[0].minimum_point}, lastActivity = ${this.state.lastActivityHighlight}`
          );
          let averageDifference =
            this.state.planAverage - res.data.plan[0].minimum_point;

          if (averageDifference <= 0) {
            averageDifference = Math.abs(averageDifference);
            this.setState({
              differenceHighlights: `${averageDifference} ${this.state.planUnitsHighlights} greater than current goal`,
            });
          } else if (averageDifference > 0) {
            averageDifference = Math.abs(averageDifference);
            this.setState({
              differenceHighlights: `${averageDifference} ${this.state.planUnitsHighlights} lower than current goal`,
            });
          }

          let averageGoalDifference =
            this.state.lastActivityHighlight - res.data.plan[0].minimum_point;
          if (averageGoalDifference <= 0) {
            averageGoalDifference = Math.abs(averageGoalDifference);
            this.setState({
              differenceGoalHighlights: `${averageGoalDifference} ${this.state.planUnitsHighlights} greater than current goal`,
            });
          } else if (averageGoalDifference > 0) {
            averageGoalDifference = Math.abs(averageGoalDifference);
            this.setState({
              differenceGoalHighlights: `${averageGoalDifference} ${this.state.planUnitsHighlights} lower than current goal`,
            });
          }

          // this.setState({differenceHighlights:averageDifference < 0 ? `${averageDifference} ${this.state.planUnitsHighlights} greater than current goal` : `${averageDifference} ${this.state.planUnitsHighlights} lower than current goal`})
          // let averageGoalDifference = this.state.lastActivityHighlight - res.data.plan[0].minimum_point;
          // averageGoalDifference = Math.abs(averageGoalDifference);
          // this.setState({differenceGoalHighlights:averageGoalDifference < 0 ? `${averageGoalDifference} ${this.state.planUnitsHighlights} greater than current goal` : `${averageGoalDifference} ${this.state.planUnitsHighlights} lower than current goal`})
        } else {
          let averageDifference =
            this.state.planAverage - res.data.plan[0].maximum_point;
          averageDifference = Math.abs(averageDifference);
          this.setState({
            differenceHighlights:
              averageDifference < 0
                ? `${averageDifference} ${this.state.planUnitsHighlights} lower than current goal`
                : `${averageDifference} ${this.state.planUnitsHighlights} greater than current goal`,
          });
          let averageGoalDifference =
            this.state.lastActivityHighlight - res.data.plan[0].maximum_point;
          averageGoalDifference = Math.abs(averageGoalDifference);
          this.setState({
            differenceGoalHighlights:
              averageGoalDifference < 0
                ? `${averageGoalDifference} ${this.state.planUnitsHighlights} lower than current goal`
                : `${averageGoalDifference} ${this.state.planUnitsHighlights} greater than current goal`,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchBestWorstActivity(planId) {
    axios
      .get(`/tiles/values/plan/min-max/${this.state.planId}`)
      .then((res) => {
        console.log(res.data);
        this.setState({
          bestActivity: res.data.results.best,
          worstActivity: res.data.results.worst,
        });
      })
      .catch((err) => console.log("ERROR: ", err));
  }

  fetchGraphData(planId) {
    let date = moment().startOf("year");
    let today = moment();
    let days = today.diff(date, "days");
    days += 1;
    let end_date = moment(today)
      .subtract(days, "days")
      .add(2, "hours");

    axios
      .get(
        `/plan/metrics/${planId}?date=${today.format()}&end=${end_date.format()}`
      )
      .then((res) => {
        this.setState({ graph_data: res.data.results });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`/plan/average/${planId}?days=100000`)
      .then((res) => {
        this.setState({ planAverage: res.data.results.average });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`/plan/metric/last-activity/${this.props.username}`)
      .then((res) => {
        res.data.result.plan.map((plan) => {
          if (plan.id === planId) {
            this.setState({ lastActivityHighlight: plan.last_activity_value });
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`/tiles/single/plan/${planId}`)
      .then((res) => {
        this.setState({ planUnitsHighlights: res.data.plan[0].units });

        if (res.data.plan[0].inverted === true) {
          console.log(
            `planAverage = ${this.state.planAverage}, minPoint = ${res.data.plan[0].minimum_point}, lastActivity = ${this.state.lastActivityHighlight}`
          );
          let averageDifference =
            this.state.planAverage - res.data.plan[0].minimum_point;

          if (averageDifference <= 0) {
            averageDifference = Math.abs(averageDifference);
            this.setState({
              differenceHighlights: `${averageDifference} ${this.state.planUnitsHighlights} greater than current goal`,
            });
          } else if (averageDifference > 0) {
            averageDifference = Math.abs(averageDifference);
            this.setState({
              differenceHighlights: `${averageDifference} ${this.state.planUnitsHighlights} lower than current goal`,
            });
          }

          let averageGoalDifference =
            this.state.lastActivityHighlight - res.data.plan[0].minimum_point;
          if (averageGoalDifference <= 0) {
            averageGoalDifference = Math.abs(averageGoalDifference);
            this.setState({
              differenceGoalHighlights: `${averageGoalDifference} ${this.state.planUnitsHighlights} greater than current goal`,
            });
          } else if (averageGoalDifference > 0) {
            averageGoalDifference = Math.abs(averageGoalDifference);
            this.setState({
              differenceGoalHighlights: `${averageGoalDifference} ${this.state.planUnitsHighlights} lower than current goal`,
            });
          }
        } else {
          let averageDifference =
            this.state.planAverage - res.data.plan[0].maximum_point;
          averageDifference = Math.abs(averageDifference);
          this.setState({
            differenceHighlights:
              averageDifference < 0
                ? `${averageDifference} ${this.state.planUnitsHighlights} lower than current goal`
                : `${averageDifference} ${this.state.planUnitsHighlights} greater than current goal`,
          });
          let averageGoalDifference =
            this.state.lastActivityHighlight - res.data.plan[0].maximum_point;
          averageGoalDifference = Math.abs(averageGoalDifference);
          this.setState({
            differenceGoalHighlights:
              averageGoalDifference < 0
                ? `${averageGoalDifference} ${this.state.planUnitsHighlights} lower than current goal`
                : `${averageGoalDifference} ${this.state.planUnitsHighlights} greater than current goal`,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  noteTitleHandler = (noteTitleValue) => {
    this.setState({
      noteTitle: noteTitleValue,
    });
  };

  notesHandler = (notesValue) => {
    this.setState({
      notes: notesValue,
    });
  };

  valueHandler = (valueFromGoalBar) => {
    this.setState({
      value: valueFromGoalBar,
    });
  };

  getPlanDescriptions() {
    if (this.state.plans) {
      return this.state.plans.map((plan) => {
        return plan.description;
      });
    }
  }

  changeTileStatusDelete(status) {
    axios
      .put("/tiles/modify-status", {
        tileId: this.state.tileId,
        username: this.props.username,
        updatedStatus: status,
      })
      .then((res) => {
        if (res.data.message === "SUCCESS") {
          this.setState({ show: true });
          this.setState({ archived: "this tile has been deleted" });
          this.props.fetchSingleUser(this.props.username);
          this.props.getUserWellnessScore(this.props.username);
          this.props.history.push(`/client/${this.props.username}/tiles`);
        }
        if (res.status !== 200) {
          this.setState({ archived: "please try again" });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ archived: "please try again" });
      });
  }

  changeTileStatusArchive(status) {
    axios
      .put("/tiles/modify-status", {
        tileId: this.state.tileId,
        username: this.props.username,
        updatedStatus: status,
      })
      .then((res) => {
        if (res.data.message === "SUCCESS") {
          this.setState({ show: true });
          this.setState({ archived: "this tile has been archived" });
          this.props.fetchSingleUser(this.props.username);
          this.props.getUserWellnessScore(this.props.username);
          this.props.history.push(`/client/${this.props.username}/tiles`);
        }
        if (res.status !== 200) {
          this.setState({ archived: "please try again" });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ archived: "please try again" });
      });
  }

  scaleStartHandler = (scaleStartValue) => {
    this.setState({
      scaleStart: scaleStartValue,
    });
  };

  scaleEndHandler = (scaleEndValue) => {
    this.setState({
      scaleEnd: scaleEndValue,
    });
  };

  planStatusHandler = (planStatusValue) => {
    this.setState({
      planStatus: planStatusValue,
    });
  };

  handleClose() {
    this.setState({ show: false });
  }

  planUnitsHandler = (planUnitsValue) => {
    this.setState({ planUnits: planUnitsValue });
  };

  incrementChangeHandler = (value) => {
    this.setState({ increment: value });
  };

  loop() {
    if (this.state.description) {
      let i;
      let bars = [];

      for (i = 0; i < this.state.plans.length; i++) {
        // if(this.state.increment === null){
        //   increment = this.state.plans[i].incrementer;
        // }
        // else{
        //   increment = this.state.increment;
        // }
        bars.push(
          <GoalBarSingleTile
            inverted={this.state.plans[i].inverted}
            increment={this.state.plans[i].incrementer}
            singleDescription={this.state.plans[i].description}
            valueHandler={this.valueHandler}
            incrementChangeHandler={this.incrementChangeHandler}
            scaleStartHandler={this.scaleStartHandler}
            scaleEndHandler={this.scaleEndHandler}
            planStatusHandler={this.planStatusHandler}
            planUnitsHandler={this.planUnitsHandler}
            planStatus={this.state.plans[i].status}
            scaleStart={this.state.plans[i].scale_start}
            scaleEnd={this.state.plans[i].scale_end}
            planUnits={this.state.plans[i].units}
            description={this.state.tile.description}
            category={this.state.tile.category}
            minPoint={this.state.plans[i].minimum_point}
            maxPoint={this.state.plans[i].maximum_point}
            singleTileChild={this.state.singleTileChild}
            planId={this.state.plans[i].id}
            planStatusId={this.state.plans[i].plan_status_id}
            id={i}
            increments={[]}
          />
        );
      }
      return bars;
    }
  }
  setTileCategory() {
    if (this.props.tile) {
      return this.props.tile.tile_category;
    }
  }

  setTileDescription() {
    if (this.state.plans.length > 1) {
      return "activity";
    } else if (this.props.tile) {
      if (this.props.tile.tile_category_id === 4) return null;
    } else {
      return this.state.description;
    }
  }

  setAvgDifference() {
    if (this.state.plans) {
      let { plans } = this.state;
      let averageDifference = plans[0].plan_average - plans[0].maximum_point;
      let averageDifferenceLabel =
        averageDifference < 0
          ? `${plans[0].units} below`
          : `${plans[0].units} above`;

      return (
        <div className="text-center">
          <h4>
            Last 7 Days {Math.round(averageDifference)} {averageDifferenceLabel}
          </h4>
          <p>Average Activity</p>
        </div>
      );
    }
  }

  handleHeaderChange = (e, timerange) => {
    let date = moment().startOf(timerange);
    let today = moment();
    let days = moment(today.diff(date, "days"));
    days = days + 1;

    this.setState({ timerange: timerange, graph_data: [] }, () => {
      this.fetchGraphDataHeader(days, date);
    });
  };

  handleTimeChange = (timerange) => {
    let date = moment(this.state.time).startOf(timerange);
    let end = moment(this.state.time).endOf(timerange);
    if (end.isAfter(moment())) end = moment();
    let days = moment(end.diff(date, "days"));
    days = days + 0;

    this.setState({ timerange: timerange, graph_data: [] }, () => {
      this.fetchGraphDataHeader(days, moment(end)._d);
    });
  };

  fetchGraphDataHeader(days, date) {
    let end_date = moment(date)
      .subtract(days, "days")
      .startOf("day")
      .add(2, "hours")._d;
    if (days === 1) {
      if (this.state.timerange === "month" || this.state.timerange === "year") {
        //only go back to the beginning of today
        end_date = moment(date)
          .startOf("day")
          .add(2, "hours")._d;
      }
    }

    axios
      .get(
        `/plan/metrics/${this.state.selected_id}?date=${date}&end=${end_date}`
      )
      .then((res) => {
        let data = [
          {
            date: moment(this.state.time)
              .startOf(this.state.timerange)
              .format(),
            value: 0,
          },
        ];
        if (res.data.results.length > 0) data = res.data.results;
        if (!this.state.timeChangeDisabled) {
          data = data.map((point) => {
            return {
              date: moment(point.date)
                .local()
                .format(),
              plan_value: point.plan_value,
            };
          });
          this.setState({ graph_data: data });
        }
        this.setState({ graph_data: data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeTimeState = (time, timerange) => {
    if (!this.state.timeChangeDisabled) {
      let that = this;
      this.setState({ time, graph_data: [], timeChangeDisabled: true }, () =>
        that.handleTimeChange(timerange)
      );
    }
  };

  //check what type of tile this is
  renderGraph() {
    if (this.props.tile && this.state.selected_id && !this.state.last_log) {
      if (this.props.tile.tile_category_id === 4) {
        //this is an intro tile
        axios
          .get(
            `/plan/last-log/plan/${this.state.selected_id}/${this.props.username}`
          )
          .then((res) => {
            if (res.data.result) {
              this.setState({ last_log: res.data.result });
            } else {
              this.setState({ last_log: { date: null, plan_value: null } });
            }
          });
      } else {
        return (
          <div className="col-xs-12">
            <div
              class="row graph-container margin-bottom-med"
              style={{ height: 500 }}
            >
              <div class="row">
                <div className="container">
                  <h3>Full Activity Log</h3>
                </div>
                <div className="visible-xs visible-sm margin-top">
                  <div className="row text-center single-tile-stats">
                    {this.state.plans ? (
                      <SingleTileStats
                        plan_average={this.state.planAverage}
                        last_activity={this.state.lastActivityHighlight}
                        best={this.state.bestActivity.plan_value}
                        worst={this.state.worstActivity.plan_value}
                        recordedBest={this.state.bestActivity.dateRecorded}
                        recordedWorst={this.state.worstActivity.dateRecorded}
                        units={this.state.planUnitsHighlights}
                        diff={this.state.differenceHighlights}
                        goalDiff={this.state.differenceGoalHighlights}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="row margin-top tile--graph-container">
                  <div className="col-md-8">
                    <TimeRangeHeader
                      handleHeaderChange={this.handleTimeChange}
                      planId={this.state.selected_id}
                    />
                    <LineGraph
                      graph_data={this.state.graph_data}
                      timerange={
                        this.state.timerange ? this.state.timerange : "week"
                      }
                      time={this.state.time}
                      enableTimeChange={this.enableTimeChange}
                    />
                    <div className="col-xs-12">
                      <YearSelect
                        changeTime={this.changeTimeState}
                        time={this.state.time}
                        timerange={this.state.timerange}
                        disabled={this.state.timeChangeDisabled}
                      />
                    </div>
                  </div>
                  <div className="hidden-xs hidden-sm col-md-4 single-tile-stats">
                    {this.state.plans ? (
                      <SingleTileStats
                        plan_average={this.state.planAverage}
                        last_activity={this.state.lastActivityHighlight}
                        best={this.state.bestActivity.plan_value}
                        worst={this.state.worstActivity.plan_value}
                        recordedBest={this.state.bestActivity.dateRecorded}
                        recordedWorst={this.state.worstActivity.dateRecorded}
                        units={this.state.planUnitsHighlights}
                        diff={this.state.differenceHighlights}
                        goalDiff={this.state.differenceGoalHighlights}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  renderIntroActivity() {
    if (this.state.last_log) {
      return (
        <div className="col-xs-12">
          <div className={"row widget-container"}>
            <div className="col-xs-12 col-sm-6">
              <h3>Tile Completed</h3>
              <p>
                {this.state.last_log.date
                  ? `${moment(this.state.last_log.date).calendar()} - (${moment(
                      this.state.last_log.date
                    ).fromNow()})`
                  : "Not Complete"}
              </p>
            </div>
            <div className="col-xs-12 col-sm-6">
              <h3>Value Entered</h3>
              <p>
                {this.state.last_log.plan_value !== null
                  ? parsePlanValue(this.state.last_log.plan_value)
                  : "Not Complete"}
              </p>
            </div>
          </div>
        </div>
      );
    }

    function parsePlanValue(value) {
      if (value === -1) return "No";
      if (value === 0) return "Maybe";
      if (value === 1) return "Yes";
    }
  }

  enableTimeChange = () => {
    if (this.state.timeChangeDisabled) {
      console.log(this.state.timeChangeDisabled);
      this.setState({ timeChangeDisabled: false });
    }
  };

  render() {
    return (
      <div className="container margin-top">
        {this.props.username ? (
          this.state.tileStatus === "active" ? (
            <div className={styles.statusContainer}>
              <div className={styles.statusLabelAlignCenter}>
                <CircleTimes
                className={styles.circleIcon}
                  fill={"#a94442"}
                  onClick={() => {
                    this.changeTileStatusDelete(2);
                  }}
                />
                <h4 className="status-labels">Delete</h4>
              </div>
              <div className={styles.statusLabelAlignCenter}>
                <CircleCheckMark
                  fill={"#4bbc4e"}
                  className={styles.circleIcon}
                  onClick={() => {
                    this.changeTileStatusArchive(3);
                  }}
                />
                <h4 className="status-labels">Complete</h4>
              </div>
            </div>
          ) : (
            <div className="row">
              <div>
                <h4 className="status-labels">Unretire</h4>
                <i
                  className="fa fa-check statusIcons"
                  onClick={() => {
                    this.changeTileStatusArchive(1);
                  }}
                >
                  {" "}
                </i>
              </div>
            </div>
          )
        ) : null}

        <div className="row">
          <div className="col-sm-12 col-md-4">
            <h2 className="capitalize">{this.setTileCategory()}</h2>
            <p className="capitalize">{this.setTileDescription()}</p>
          </div>
          <div className="col-sm-12 col-md-4 text-center">
            <h4>{this.state.currentStreak} Consecutive Days</h4>
            <p>Current Streak</p>
          </div>
          <div className="col-sm-12 col-md-4 text-right">
            {this.setAvgDifference()}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 no-padding">
            <PlanSelector
              handleSelectChange={this.handleSelectChange}
              plans={this.state.plans}
              selected={this.state.selected_id}
            />
          </div>
          {this.renderGraph()}
          {this.renderIntroActivity()}
        </div>

        <div className="row">
          <div class="col-sm-12">
            <h3>Recommended Resources</h3>
            <div class="widget-container">
              <ContentDisplayContainer
                categories={this.state.content_categories}
                defaultValue={true}
                tile={this.props.tile ? this.props.tile : null}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div class="col-sm-12 col-md-6">
            <h3>Goal Bar</h3>
            <p>Review or change goal details</p>
            {this.state.category === 4 ? (
              <h3>Introduction tiles are not editable.</h3>
            ) : (
              this.loop()
            )}
          </div>
          <div className="col-sm-12 col-md-6 single-tile-notes">
            <NotesMain
              singleTileChild={this.state.singleTileChild}
              noteTitleHandler={this.noteTitleHandler}
              notesHandler={this.notesHandler}
              username={this.props.username}
              tileId={this.state.tileId}
              notes={this.state.notes}
              noteTitle={this.state.noteTitle}
            />
          </div>
        </div>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.archived}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ tile, user }) => {
  return {
    user,
    tile: tile.tile,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTile,
      clearTile,
      clearPlan,
      fetchSingleUser,
      getUserWellnessScore,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleTile)
);
