import React from 'react';
/* eslint-disable */
// components
import {
    BADGES,
    BADGE_IDS,
    Committed_200_shadow,
    Committed_50_shadow,
    Committed_200,
    Dependable_50_shadow,
    Dependable_50,
    Dependable_200,
    Dependable_200_shadow,
    Committed_50,
    Determined_200,
    Determined_200_shadow,
    Determined_50_shadow,
    Determined_50,
    Tenacity_200,
    Tenacity_200_shadow,
    Tenacity_50_shadow,
    Tenacity_50,
    Perseverance_200,
    Perseverance_200_shadow,
    Perseverance_50_shadow,
    Perseverance_50,
    Creative_50_shadow,
    Creative_50,
    Creative_200,
    Creative_200_shadow,
    Trailblazer_200,
    Trailblazer_50,
    Trailblazer_200_shadow,
    Trailblazer_50_shadow,
    Courageous_200,
    Courageous_50,
    Courageous_200_shadow,
    Courageous_50_shadow,
    Explorer_200,
    Explorer_50,
    Explorer_200_shadow,
    Explorer_50_shadow,
    Accepting_200,
    Accepting_50,
    Accepting_200_shadow,
    Accepting_50_shadow,
    Enthusiastic_200,
    Enthusiastic_50,
    Enthusiastic_200_shadow,
    Enthusiastic_50_shadow,
    Proactive_200,
    Proactive_50,
    Proactive_200_shadow,
    Proactive_50_shadow,
    Grit_200,
    Grit_50,
    Grit_200_shadow,
    Grit_50_shadow,
    Bold_50_shadow,
    Bold_50,
    Bold_200_shadow,
    Bold_200,
    Innovator_50_shadow,
    Innovator_50,
    Innovator_200_shadow,
    Innovator_200,
    Dedicated_50_shadow,
    Dedicated_50,
    Dedicated_200_shadow,
    Dedicated_200,
    Curiosity_50_shadow,
    Curiosity_50,
    Curiosity_200_shadow,
    Curiosity_200,
    Motivated_50_shadow,
    Motivated_50,
    Motivated_200_shadow,
    Motivated_200,
    Capable_50,
    Capable_50_shadow,
    Capable_200,
    Capable_200_shadow,
    Endurance_50_shadow,
    Endurance_50,
    Endurance_200_shadow,
    Endurance_200,
    Driven_50_shadow,
    Driven_50,
    Driven_200_shadow,
    Driven_200,
    Engaged_50_shadow,
    Engaged_50,
    Engaged_200_shadow,
    Engaged_200,
    Resolute_50_shadow,
    Resolute_50,
    Resolute_200_shadow,
    Resolute_200,
    Empowered_50_shadow,
    Empowered_50,
    Empowered_200_shadow,
    Empowered_200,
    Accomplished_50_shadow,
    Accomplished_50,
    Accomplished_200_shadow,
    Accomplished_200,

    // gold badges
    Pioneer_200,
    Pioneer_50,
    Pioneer_200_shadow,
    Pioneer_50_shadow,
    Fearless_200,
    Fearless_50,
    Fearless_200_shadow,
    Fearless_50_shadow,
    Adventurer_200,
    Adventurer_50,
    Adventurer_200_shadow,
    Adventurer_50_shadow,
    Adaptable_200,
    Adaptable_50,
    Adaptable_200_shadow,
    Adaptable_50_shadow,
    Energized_200,
    Energized_50,
    Energized_200_shadow,
    Energized_50_shadow,
    Resourceful_200,
    Resourceful_50,
    Resourceful_200_shadow,
    Resourceful_50_shadow,
    Persistent_200,
    Persistent_50,
    Persistent_200_shadow,
    Persistent_50_shadow,
    Resilient_200,
    Resilient_50,
    Resilient_200_shadow,
    Resilient_50_shadow,
    Receptive_200,
    Receptive_50,
    Receptive_200_shadow,
    Receptive_50_shadow,
    Transformed_200,
    Transformed_50,
    Transformed_200_shadow,
    Transformed_50_shadow,
    Inspiring_200,
    Inspiring_50,
    Inspiring_200_shadow,
    Inspiring_50_shadow,
    Reflective_200,
    Reflective_50,
    Reflective_200_shadow,
    Reflective_50_shadow
} from "../../serverVariables/badges";

const Badge = props => {
    const { badge, size, shadow } = props;

    function chooseBadge(size, shadow) {
        let lower;
        if (typeof badge === "number") lower = badge;
        if (typeof badge === "string") lower = badge.toLowerCase();
        if (lower === BADGES.TRAILHEAD || lower === BADGES.TRAILBLAZER || lower == BADGE_IDS.TRAILBLAZER) {
            if (size === 50) return <img {...props} src={shadow ? Trailblazer_50_shadow : Trailblazer_50} alt='' />;
            return <img {...props} src={shadow ? Trailblazer_200_shadow : Trailblazer_200} alt="" />;
        }
        else if (lower === BADGES.COURAGEOUS || lower == BADGE_IDS.COURAGEOUS) {
            if (size === 50) return <img {...props} src={shadow ? Courageous_50_shadow : Courageous_50} alt='' />;
            return <img {...props} src={shadow ? Courageous_200_shadow : Courageous_200} alt="" />;
        }
        else if (lower === BADGES.EXPLORER || lower == BADGE_IDS.EXPLORER) {
            if (size === 50) return <img {...props} src={shadow ? Explorer_50_shadow : Explorer_50} alt='' />;
            return <img {...props} src={shadow ? Explorer_200_shadow : Explorer_200} alt="" />;
        }
        else if (lower === BADGES.ACCEPTING || lower == BADGE_IDS.ACCEPTING) {
            if (size === 50) return <img {...props} src={shadow ? Accepting_50_shadow : Accepting_50} alt='' />;
            return <img {...props} src={shadow ? Accepting_200_shadow : Accepting_200} alt="" />;
        }
        else if (lower === BADGES.ENTHUSIASTIC || lower == BADGE_IDS.ENTHUSIASTIC) {
            if (size === 50) return <img {...props} src={shadow ? Enthusiastic_50_shadow : Enthusiastic_50} alt='' />;
            return <img {...props} src={shadow ? Enthusiastic_200_shadow : Enthusiastic_200} alt="" />;
        }
        else if (lower === BADGES.PROACTIVE || lower == BADGE_IDS.PROACTIVE) {
            if (size === 50) return <img {...props} src={shadow ? Proactive_50_shadow : Proactive_50} alt='' />;
            return <img {...props} src={shadow ? Proactive_200_shadow : Proactive_200} alt="" />;
        }
        else if (lower === BADGES.CREATIVE || lower == BADGE_IDS.CREATIVE) {
            if (size === 50) return <img {...props} src={shadow ? Creative_50_shadow : Creative_50} alt='' />;
            return <img {...props} src={shadow ? Creative_200_shadow : Creative_200} alt="" />;
        }
        else if (lower === BADGES.PERSEVERANCE || lower == BADGE_IDS.PERSEVERANCE) {
            if (size === 50) return <img {...props} src={shadow ? Perseverance_50_shadow : Perseverance_50} alt='' />;
            return <img {...props} src={shadow ? Perseverance_200_shadow : Perseverance_200} alt="" />;
        }
        else if (lower === BADGES.TENACITY || lower == BADGE_IDS.TENACITY) {
            if (size === 50) return <img {...props} src={shadow ? Tenacity_50_shadow : Tenacity_50} alt='' />;
            return <img {...props} src={shadow ? Tenacity_200_shadow : Tenacity_200} alt="" />;
        }
        else if (lower === BADGES.DETERMINED || lower == BADGE_IDS.DETERMINED) {
            if (size === 50) return <img {...props} src={shadow ? Determined_50_shadow : Determined_50} alt='' />;
            return <img {...props} src={shadow ? Determined_200_shadow : Determined_200} alt="" />;
        }
        else if (lower === BADGES.COMMITTED || lower == BADGE_IDS.COMMITTED) {
            if (size === 50) return <img {...props} src={shadow ? Committed_50_shadow : Committed_50} alt='' />;
            return <img {...props} src={shadow ? Committed_200_shadow : Committed_200} alt="" />;
        }
        else if (lower === BADGES.DEPENDABLE || lower == BADGE_IDS.DEPENDABLE) {
            if (size === 50) return <img {...props} src={shadow ? Dependable_50_shadow : Dependable_50} alt='' />;
            return <img {...props} src={shadow ? Dependable_200_shadow : Dependable_200} alt="" />;
        }
        else if (lower === BADGES.GRIT || lower == BADGE_IDS.GRIT) {
            if (size === 50) return <img {...props} src={shadow ? Grit_50_shadow : Grit_50} alt='' />;
            return <img {...props} src={shadow ? Grit_200_shadow : Grit_200} alt="" />;
        }
        else if (lower === BADGES.BOLD || lower == BADGE_IDS.BOLD) {
            if (size === 50) return <img {...props} src={shadow ? Bold_50_shadow : Bold_50} alt='' />;
            return <img {...props} src={shadow ? Bold_200_shadow : Bold_200} alt="" />;
        }
        else if (lower === BADGES.INNOVATOR || lower == BADGE_IDS.INNOVATOR) {
            if (size === 50) return <img {...props} src={shadow ? Innovator_50_shadow : Innovator_50} alt='' />;
            return <img {...props} src={shadow ? Innovator_200_shadow : Innovator_200} alt="" />;
        }
        else if (lower === BADGES.DEDICATED || lower == BADGE_IDS.DEDICATED) {
            if (size === 50) return <img {...props} src={shadow ? Dedicated_50_shadow : Dedicated_50} alt='' />;
            return <img {...props} src={shadow ? Dedicated_200_shadow : Dedicated_200} alt="" />;
        }
        else if (lower === BADGES.CURIOSITY || lower == BADGE_IDS.CURIOSITY) {
            if (size === 50) return <img {...props} src={shadow ? Curiosity_50_shadow : Curiosity_50} alt='' />;
            return <img {...props} src={shadow ? Curiosity_200_shadow : Curiosity_200} alt="" />;
        }
        else if (lower === BADGES.MOTIVATED || lower == BADGE_IDS.MOTIVATED) {
            if (size === 50) return <img {...props} src={shadow ? Motivated_50_shadow : Motivated_50} alt='' />;
            return <img {...props} src={shadow ? Motivated_200_shadow : Motivated_200} alt="" />;
        }
        else if (lower === BADGES.CAPABLE || lower == BADGE_IDS.CAPABLE) {
            if (size === 50) return <img {...props} src={shadow ? Capable_50_shadow : Capable_50} alt='' />;
            return <img {...props} src={shadow ? Capable_200_shadow : Capable_200} alt="" />;
        }
        else if (lower === BADGES.ENDURANCE || lower == BADGE_IDS.ENDURANCE) {
            if (size === 50) return <img {...props} src={shadow ? Endurance_50_shadow : Endurance_50} alt='' />;
            return <img {...props} src={shadow ? Endurance_200_shadow : Endurance_200} alt="" />;
        }
        else if (lower === BADGES.DRIVEN || lower == BADGE_IDS.DRIVEN) {
            if (size === 50) return <img {...props} src={shadow ? Driven_50_shadow : Driven_50} alt='' />;
            return <img {...props} src={shadow ? Driven_200_shadow : Driven_200} alt="" />;
        }
        else if (lower === BADGES.ENGAGED || lower == BADGE_IDS.ENGAGED) {
            if (size === 50) return <img {...props} src={shadow ? Engaged_50_shadow : Engaged_50} alt='' />;
            return <img {...props} src={shadow ? Engaged_200_shadow : Engaged_200} alt="" />;
        }
        else if (lower === BADGES.RESOLUTE || lower == BADGE_IDS.RESOLUTE) {
            if (size === 50) return <img {...props} src={shadow ? Resolute_50_shadow : Resolute_50} alt='' />;
            return <img {...props} src={shadow ? Resolute_200_shadow : Resolute_200} alt="" />;
        }
        else if (lower === BADGES.EMPOWERED || lower == BADGE_IDS.EMPOWERED) {
            if (size === 50) return <img {...props} src={shadow ? Empowered_50_shadow : Empowered_50} alt='' />;
            return <img {...props} src={shadow ? Empowered_200_shadow : Empowered_200} alt="" />;
        }
        else if (lower === BADGES.ACCOMPLISHED || lower == BADGE_IDS.ACCOMPLISHED) {
            if (size === 50) return <img {...props} src={shadow ? Accomplished_50_shadow : Accomplished_50} alt='' />;
            return <img {...props} src={shadow ? Accomplished_200_shadow : Accomplished_200} alt="" />;
        }

        // Gold badges
        else if (lower === BADGES.PIONEER || lower == BADGE_IDS.PIONEER) {
            if (size === 50) return <img {...props} src={shadow ? Pioneer_50_shadow : Pioneer_50} alt='' />;
            return <img {...props} src={shadow ? Pioneer_200_shadow : Pioneer_200} alt="" />;
        }
        else if (lower === BADGES.FEARLESS || lower == BADGE_IDS.FEARLESS) {
            if (size === 50) return <img {...props} src={shadow ? Fearless_50_shadow : Fearless_50} alt='' />;
            return <img {...props} src={shadow ? Fearless_200_shadow : Fearless_200} alt="" />;
        }
        else if (lower === BADGES.ADVENTURER || lower == BADGE_IDS.ADVENTURER) {
            if (size === 50) return <img {...props} src={shadow ? Adventurer_50_shadow : Adventurer_50} alt='' />;
            return <img {...props} src={shadow ? Adventurer_200_shadow : Adventurer_200} alt="" />;
        }
        else if (lower === BADGES.ADAPTABLE || lower == BADGE_IDS.ADAPTABLE) {
            if (size === 50) return <img {...props} src={shadow ? Adaptable_50_shadow : Adaptable_50} alt='' />;
            return <img {...props} src={shadow ? Adaptable_200_shadow : Adaptable_200} alt="" />;
        }
        else if (lower === BADGES.ENERGIZED || lower == BADGE_IDS.ENERGIZED) {
            if (size === 50) return <img {...props} src={shadow ? Energized_50_shadow : Energized_50} alt='' />;
            return <img {...props} src={shadow ? Energized_200_shadow : Energized_200} alt="" />;
        }
        else if (lower === BADGES.RESOURCEFUL || lower == BADGE_IDS.RESOURCEFUL) {
            if (size === 50) return <img {...props} src={shadow ? Resourceful_50_shadow : Resourceful_50} alt='' />;
            return <img {...props} src={shadow ? Resourceful_200_shadow : Resourceful_200} alt="" />;
        }
        else if (lower === BADGES.PERSISTENT || lower == BADGE_IDS.PERSISTENT) {
            if (size === 50) return <img {...props} src={shadow ? Persistent_50_shadow : Persistent_50} alt='' />;
            return <img {...props} src={shadow ? Persistent_200_shadow : Persistent_200} alt="" />;
        }
        else if (lower === BADGES.RESILIENT || lower == BADGE_IDS.RESILIENT) {
            if (size === 50) return <img {...props} src={shadow ? Resilient_50_shadow : Resilient_50} alt='' />;
            return <img {...props} src={shadow ? Resilient_200_shadow : Resilient_200} alt="" />;
        }
        else if (lower === BADGES.RECEPTIVE || lower == BADGE_IDS.RECEPTIVE) {
            if (size === 50) return <img {...props} src={shadow ? Receptive_50_shadow : Receptive_50} alt='' />;
            return <img {...props} src={shadow ? Receptive_200_shadow : Receptive_200} alt="" />;
        }
        else if (lower === BADGES.TRANSFORMED || lower == BADGE_IDS.TRANSFORMED) {
            if (size === 50) return <img {...props} src={shadow ? Transformed_50_shadow : Transformed_50} alt='' />;
            return <img {...props} src={shadow ? Transformed_200_shadow : Transformed_200} alt="" />;
        }
        else if (lower === BADGES.INSPIRING || lower == BADGE_IDS.INSPIRING) {
            if (size === 50) return <img {...props} src={shadow ? Inspiring_50_shadow : Inspiring_50} alt='' />;
            return <img {...props} src={shadow ? Inspiring_200_shadow : Inspiring_200} alt="" />;
        }
        else if (lower === BADGES.REFLECTIVE || lower == BADGE_IDS.REFLECTIVE) {
            if (size === 50) return <img {...props} src={shadow ? Reflective_50_shadow : Reflective_50} alt='' />;
            return <img {...props} src={shadow ? Reflective_200_shadow : Reflective_200} alt="" />;
        }
        else {
            if (size === 50) return <img {...props} src={shadow ? Trailblazer_50_shadow : Trailblazer_50} alt='' />;
            return <img {...props} src={shadow ? Trailblazer_200_shadow : Trailblazer_200} alt="" />;
        }
    }

    return chooseBadge(size, shadow);
}

export default Badge;