import React from 'react';

// styles
import styles from './topClients.module.css';

import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const TopClients = props => {
    // props
    const {data} = props;

    if (data === null || !Array.isArray(data)) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.clients}>
                    <div className={styles.headers}>Clients</div>
                </div>
                <div className={styles.number}>
                    <div className={styles.headers}>Sign Ups</div>
                </div>
            </div>
            {data.map(client => (
                <div className={styles.row}>
                    <div className={styles.clients}>{client.name}</div>
                    <div className={styles.number}>{client.sign_ups}</div>
                </div>
            ))}
        </div>
    )
}

export default TopClients;