import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import styles from './tutorialModal.module.css';

// context
import {ShepherdTourContext} from "react-shepherd";
import tourContext from "../../contexts/tourContext";

// actions
import {tourUpdate} from "../../store/actions/userActions";

// components
import {ReactComponent as HabitIcon} from '../../images/habit-icon.svg';
import {ReactComponent as PathwaysIcon} from '../../images/pathways-icon.svg';
import {ReactComponent as JFYIcon} from '../../images/jfy-icon.svg';
import {ReactComponent as TeammatesIcon} from '../../images/teammates-icon.svg';
import {ReactComponent as SettingsIcon} from '../../images/settings-icon.svg';
import {ReactComponent as CoachIcon} from "../../images/whistle_icon.svg";
import {pathwaysTour} from "../tours/pathwaysTour/pathwaysTour";
import {disabledTour} from "../tours/mainTour/mainTour";
import {mainTour} from "../tours/mainTour/mainTour";
import {jfyTour} from "../tours/jfyTour/jfyTour";
import {teammatesTour} from "../tours/teammatesTour/teammatesTour";
import {profileTour} from "../tours/profileTour/profileTour";
import {coachTour} from "../tours/coachTour/coachTour";
import {TOURS} from "../../serverVariables/tours";

const TutorialModal = props => {
    // props
    const {close, history, location} = props;
    // state
    const {user} = props;
    // local
    const [mounted, setMounted] = useState(false);

    const tour = useContext(ShepherdTourContext);
    const {tourState, setTour} = useContext(tourContext)

    useEffect(() => {
        if (!mounted) return;
        start();
    }, [tourState])

    useEffect(() => setMounted(true), []);

    function onUrl(url) {
        return location.pathname === url;
    }

    function start(){
        const url = `/member/clientDash/${user.username}`;
        if (onUrl(url)){
            close();
            return tour.start();
        }else {
            close();
            history.push({
                pathname: url,
                state: 'start-tour'
            })
        }

    }

    function selectTour(id, steps) {
        // const url = `/member/clientDash/${user.username}`;
        try {
            tourUpdate(id);
            setTour(steps);
            if (tourState === steps) start();
        } catch(e) {
            console.log(e);
        }
    }
    return (
        <div className='padding-small'>
            <div className='margin-bottom-25 d:f j-c:s-b'>
                <h4 className='m:0'>Discover the Whole Person benefits of the Habit Builder</h4>
                <div>
                    <div className={styles.exit} onClick={close}>&times;</div>
                </div>
            </div>

            <p className='m:0 margin-bottom-15'>Click on any of the items below to learn how to use different parts of the habit builder.</p>

            {/* Tutorials */}
            <div className={styles.tutorial} onClick={()=> selectTour(TOURS.HABITS, mainTour)}>
                <HabitIcon/>
                <span> Learn to log in your Habit Builder</span>
            </div>

            <div className={styles.tutorial} onClick={() => selectTour(TOURS.PATHWAYS, pathwaysTour)}>
                <PathwaysIcon/>
                <span>Discover Your Personal Pathway</span>
            </div>

            <div className={styles.tutorial} onClick={() => selectTour(TOURS.JFY, jfyTour)}>
                <JFYIcon/>
                <span>Add content <i>Just For You</i></span>
            </div>

            <div className={styles.tutorial} onClick={() => selectTour(TOURS.TEAMMATES, teammatesTour)}>
                <TeammatesIcon/>
                <span>Add Teammates for encouragement</span>
            </div>

            <div className={styles.tutorial} onClick={() => selectTour(TOURS.PROFILE, profileTour)}>
                <SettingsIcon/>
                <span> Learn how to change your profile settings </span>
            </div>

            {/*<div className={styles.tutorial} onClick={() => selectTour(TOURS.FEATURED, featuredTour)}>*/}
            {/*    <FeaturedIcon/>*/}
            {/*    <span>Explore tools for a Whole-Person approach to wellbeing</span>*/}
            {/*</div>*/}

            <div className={styles.tutorial} onClick={() => selectTour(TOURS.COACH, coachTour)}>
                <CoachIcon/>
                <span>Learn how to contact your coach</span>
            </div>
        </div>
    )
}

function mapStateToProps({user}) {
    return {user}
}
export default withRouter(connect(mapStateToProps)(TutorialModal));