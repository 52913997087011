import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

// styles
import styles from "./threadBox.module.css";

// components
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import { ReactComponent as ExitIcon } from "../../../images/times-solid.svg";
import { PERMISSIONS } from "../../../serverVariables/permissions";
import ProfileImage from "../../../images/avatar-1577909_960_720.png";
import ContentSlider from "../../contentDisplay/contentSlider";
import { PrimaryButton } from "../../buttons/buttons";
import NewMessage from "../newMessage/newMessage";

const ThreadBox = (props) => {
  // props
  const {
    thread,
    user,
    archiveCallback,
    close,
    refreshThread,
    disableReply,
  } = props;
  // local
  const [showReply, setShowReply] = useState(false);
  const replyRef = useRef(null);
  const threadRef = useRef(null);
  const headerRef = useRef(null);
  const threadBodyRef = useRef(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (headerRef && headerRef.current && threadRef && threadRef.current) {
      const headerHeight = headerRef.current.clientHeight;
      const threadHeight = threadRef.current.clientHeight;
      setOffset(threadHeight - headerHeight);
    }
    const x = threadRef.current.getElementsByTagName("img");
    for (let img of x) {
      img.onload = scrollToBottom();
    }
  }, [thread.id]);

  useEffect(() => {
    scrollToBottom();
  }, [offset]);

  function scrollToBottom() {
    if (replyRef && replyRef.current) {
      replyRef.current.scrollIntoView();
    }
  }

  function displayPost(post) {
    let sameUser = false;
    if (user.id === post.user_id) sameUser = true;
    if (!post.user) post.user = {};
    return (
      <div
        key={post.id}
        className={`${sameUser ? styles.left : ""} ${styles.thread} clearfix`}
      >
        {/* meta */}
        <div className={styles.meta}>
          <div
            className={styles.img}
            style={{
              backgroundImage: `url(${post.user.profile_image ||
                ProfileImage})`,
            }}
          />
          <div>
            <p className={styles.user}>
              {post.user.first_name} {post.user.last_name}
            </p>
            <p className={styles.date}>
              {moment
                .parseZone(post.created_at)
                .local()
                .format("MM/DD/YY")}
              &nbsp;&nbsp;
              {moment
                .parseZone(post.created_at)
                .local()
                .format("h:mm a")}
            </p>
          </div>
        </div>

        {/* message */}
        <div>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: post.text }}
          />
        </div>

        {/* attachments */}
        <div>
          <ContentSlider content={post.post_attachments} />
        </div>
      </div>
    );
  }

  function displayThread(posts) {
    if (!Array.isArray(posts)) return null;
    if (posts.length < 1) return null;
    const flipped = [];
    for (let i = posts.length - 1; i >= 0; i--) {
      flipped.push(posts[i]);
    }
    return flipped.map((post, i) => displayPost(post));
  }
  const title = thread.posts[thread.posts.length - 1].title;
  return (
    <BoxContainer className={styles.container}>
      <div ref={threadRef} style={{ height: "100%" }}>
        {/* header */}
        <div ref={headerRef}>
          <div className={styles.header}>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.buttons}>
              {archiveCallback && typeof archiveCallback === "function" ? (
                <button
                  onClick={() =>
                    archiveCallback(thread.id, parseInt(thread.status) !== 1)
                  }
                  className={styles.archive}
                >
                  {parseInt(thread.status) === 1
                    ? "Restore Thread"
                    : "Archive Thread"}
                </button>
              ) : null}
              <button onClick={close} className={styles.close}>
                <ExitIcon />
              </button>
            </div>
          </div>

          {/*recipients*/}
          <div className={styles.recipients}>
            <p className={styles.header}>Recipients</p>
            <div>
              {thread.participants.map((user) => {
                if (!user) return;
                let to = "";
                if (user.permission_id === PERMISSIONS.COACH) {
                  to = `/dashboard/coach/${user.username}/overview`;
                } else if (user.permission_id === PERMISSIONS.CLIENT) {
                  to = `/client/${user.username}/overview`;
                }
                if (!to) {
                  return (
                    <span>
                      {user.first_name} {user.last_name},&nbsp;
                    </span>
                  );
                }
                return (
                  <Link id={user.id} to={to}>
                    {user.first_name} {user.last_name},&nbsp;
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        {/* Thread Body */}
        <div
          ref={threadBodyRef}
          className={`clearfix ${styles.threadBody}`}
          style={{ height: `${offset}px` }}
        >
          <div className="clearfix">{displayThread(thread.posts)}</div>
          {/* Reply/New */}
          <div className="clearfix">
            {showReply ? (
              <NewMessage
                recipients={thread.participants}
                post_id={thread.posts[0].id}
                title={thread.posts[0].title}
                btn="Reply"
                cancel={() => setShowReply(false)}
                refreshThread={refreshThread}
              />
            ) : null}
          </div>

          {!showReply && !disableReply ? (
            <div className={styles.reply}>
              <PrimaryButton onClick={() => setShowReply(true)}>
                Reply
              </PrimaryButton>
            </div>
          ) : null}
          <div className={styles.dummy} ref={replyRef}>
            &nbsp;
          </div>
        </div>
      </div>
    </BoxContainer>
  );
};

export default ThreadBox;
