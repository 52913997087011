import { pathwaysApi } from "../../api/pathwaysApi";

import {
    SET_CLIENT_PATHWAY,
    SET_CLIENT_PATHWAY_LIST,
    RESET_PATHWAY,
} from "../storeConstants";
//**************** Actions that involve the store *****************//

// fetch and set a single pathway given a pathway id and optional user id
export function setClientPathway (pathwayId, userId) {
    return async dispatch => {
        const pathwayRes = await pathwaysApi().getClientPathway(pathwayId, userId);
         dispatch({ type: SET_CLIENT_PATHWAY, payload: pathwayRes.data.result });
    }
}

// clear out client pathway state
export function resetClientPathway(){
    return dispatch => {
        dispatch({ type: RESET_PATHWAY, payload: {} });
    }
}

// fetch and set a list of pathways for a client
// may provide optional userId for coach/admin use
export function setClientPathwayList(userId=null){
    return async dispatch => {
        const pathwayRes = await pathwaysApi().getClientPathwayList(userId);
        dispatch({ type: SET_CLIENT_PATHWAY_LIST, payload: pathwayRes.data.result });
        return pathwayRes.data.result;
    }
}

//**************** Actions that don't involve the store *****************//

// fetch the next pathways content for a logged in client that the client hasn't viewed
export async function fetchNextUnviewedContent() {
    try {
        const { data: { result }} = await pathwaysApi().getNextUnviewedContent();
        if (Array.isArray(result) && result.length > 0) {
            return result[0];
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }

}

// make a new pathway for a member having filled out an assessment
export async function makePathway(pathwayId) {
    return await pathwaysApi().createPathway(pathwayId);
}

export async function postAssessmentProfile(pathwayId) {
    return await pathwaysApi().getLifestyle(pathwayId);

}

// fetch content related to just for you category assigned to a member
export function fetchMemberContent() {
    return {
        getFoundations: async () => getContent('foundation'),
        getCoach: async () => getContent('coach'),
        getHealthGoal: async () => getContent('healthgoal'),
        getJustForYou: async () => getContent('justforyou'),
    }
    async function getContent(content) {
        const {data: { result }} =  await pathwaysApi().getContent(content);
        return result;
    }
}

// fetch the subcategories for the Just For You category
export async function fetchJustForYouCategories() {
    const {data: { result }} = await pathwaysApi().getJustForYouCategories();
    return result;
}

// subscribe/unsubscribe member to just for you content
export async function subscribeJustForYou(categories) {
    await pathwaysApi().assignJustForYou(categories);
}

// check if content exists for a given category
export async function checkContentExists(categoryId) {
    const { data: {result} } = await pathwaysApi().checkContentInCategory(categoryId);
    return result;
}

// fetch content from a specific category
export async function fetchCategoryContent(categoryId) {
    const { data: {result}} = await pathwaysApi().getContentByCategory(categoryId);
    return result;
}

// fetch the next piece of content given a contentId
export async function fetchNextContent(contentId) {
    const { data: {result}} = await pathwaysApi().getNextContent(contentId);
    return result;
}

// fetch a single piece of content given a content id
export async function fetchSingleContent(contentId) {
    const {data: {result}} = await pathwaysApi().getSingleContent(contentId);
    return result;
}

// record that content has been viewed by a member
export async function recordContentView(contentId) {
    const {data} = await pathwaysApi().recordView(contentId);
    return data;
}

// get the stats for a members pathway history
// takes in optional categoryId
export async function fetchMemberPathwayStats(userId, categoryId=null) {
    const {data: {result}} = await pathwaysApi().getStats(userId, categoryId);
    return result;
}