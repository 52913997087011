import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { getOrganizationCoachList } from "../../actions/action_organizations";

// styles
import styles from './coachSearch.module.css';

// components
import Loading from "../Loading/Loading";
import { ErrorAlert } from "../alerts/alerts";
import { BasicButton } from "../buttons/buttons";
import Avatar from '../../images/avatar-1577909_960_720.png';

const CoachSearch = ({ onSelection, organization, coaches, getCoaches }) => {
    const [ loading, setLoading ] = useState(true);
    const [ loadingError, setLoadingError ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);

    function selectCoach(coachId){
        if (!disabled) {
            setDisabled(true);
            onSelection(coachId)
                .then(() => setDisabled(false))
                .catch(() => setDisabled(false));
        }
    }

    // grab coach list on mount
    useEffect(() => {
        getCoaches(organization.id)
            .then(() => setLoading(false))
            .catch(() => {
                setLoading(false);
                setLoadingError(true);
            })
    }, []);

    if (loading) return <Loading/>

    if (loadingError) {
        return (
            <div>
                <ErrorAlert>Unable to retrieve list of coaches.</ErrorAlert>
            </div>
        )
    }

    return (
        <div>
            { coaches.map(coach => (
                <CoachTile
                    key={coach.coach_id}
                    onSelection={selectCoach}
                    coach={coach}
                />
                    ))}
        </div>
    )
}

function mapStateToProps({ organization, organizationAvailableCoaches }) {
    return {
        coaches: organizationAvailableCoaches,
        organization,
    }
}

const actions = {
    getCoaches: getOrganizationCoachList,
}
export default connect(mapStateToProps, actions)(CoachSearch);

function CoachTile({ coach, onSelection }) {
    return (
        <div className={styles.itemContainer}>
            <div className={styles.imageContainer}>
                <img className={styles.image} src={coach.profile_image || Avatar} alt={`${coach.first_name} profile`}/>
            </div>
            <div className={styles.infoContainer}>
                <h4 className={styles.name}>{coach.first_name} {coach.last_name}</h4>
                <p>{coach.title}</p>
            </div>
            <div className={styles.buttonContainer}>
                <BasicButton onClick={() => onSelection(coach.coach_id)} className={styles.button}>Select</BasicButton>
            </div>
        </div>
    )
}