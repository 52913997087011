import React, {useState} from 'react';
import moment from "moment";

// styles
import styles from './inboxList.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {ReactComponent as Archive} from '../../../images/archive-solid.svg';

const InboxList = props => {
    // props
    const {list, selectThread, active, archiveCallback} = props;
    // local
    const [hoverThread, setHoverThread] = useState(null);

    function senders(users={}){
        if (!Array.isArray(users)) return `${users.first_name} ${users.last_name}, `;
        if (users.length > 1) return 'Group';
        if (!users.length) return 'n/a';
        else return `${users[0].first_name} ${users[0].last_name}`
    }
    return (
        <div>
            {list.map(thread => {
                const read = parseInt(thread.unread) === 0;
                const title = thread.posts[thread.posts.length-1].title;
                const date = moment.parseZone(thread.posts[0].created_at).local().format("h:mm a  MM/DD/YY");
                const isActive = active && active.id === thread.id;
                return (
                    <BoxContainer
                        id={thread.id}
                        onMouseEnter={() => setHoverThread(thread.id)}
                        onMouseLeave={() => setHoverThread(null)}
                        className={`${read ? styles.read : ''} ${styles.thread} ${isActive ? styles.active : ''}`}>
                        <span className={`${styles.dot} ${read ? '' : styles.unread}`}/>
                        <p className={styles.sender}>{senders(thread.participants)}</p>
                        <p onClick={() => selectThread(thread.id)} className={styles.subject}>{title}</p>
                        {archiveCallback && typeof archiveCallback === "function" ?
                            <button
                                onClick={()=>archiveCallback(thread.id, parseInt(thread.status) !== 1)}
                                className={styles.archive}>
                                {hoverThread === thread.id ?
                                    <span><Archive/>Archive</span>
                                    :
                                    ''
                                }
                            </button>
                            : null
                        }
                        <p className={styles.date}>{date}</p>
                    </BoxContainer>
                )
            })}
        </div>
    )
}

export default InboxList;