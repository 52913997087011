import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import './Step2.css'

let intialPlanArr = [];

class Step2 extends Component{
   constructor(props){
       super();
           this.state = {
               firstName : '',
               description: [],
               singleDescription: '',
               initialDescription:[],
               tileStyle0: true,
               tileStyle1: true,
               tileStyle2: true,
               tileStyle3: true,
               tileStyle4: true,
               tileStyle5: true,
               tileStyle6: true,
               tileStyle7: true,
               custom: false,
               editCategory: '',
               allTilesArr: [],
               duplicated: true,
       };
   }

   editCategory() {
    this.setState({editCategory: this.props.location.state.category}, () => {
        this.setState({editCategory: this.state.editCategory.replace(/\s+/g, '-')})
    })
   }

    moveToStep3(categoryId, category, description, custom){
        if(this.state.description.reduce((a, b) => a && intialPlanArr.includes(b), true)){
            alert('tile already exists')
            return;
        }
    
        if(this.state.description.length === 0) {
            alert('select a tile please')
            return;
        }
        // if(this.state.duplicated === true){
        //     alert('tile already exists')
        //     return;          
        // }
        this.state.description.push(this.state.description.splice(this.state.description.indexOf("Custom"), 1)[0]);
        let currentPath = this.props.location.pathname,
            parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
        this.props.history.push({
            pathname: parentPath+'/step-3',
            state: {
                categoryId:categoryId,
                category:category,
                description:description,
                planStatus:'active',
                custom:custom
            }
        })
    }

    componentDidMount() {
        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({firstName:res.data.user.first_name});
            })
        axios.get(`/tiles/${this.props.location.state.categoryId}/plans`)
            .then(res => {
                this.setState({initialDescription: res.data.descriptions});
                console.log(res)
            })
        axios.get(`/tiles/list/${this.props.username}`)
            .then(res => {
                console.log(res.data.result)
                this.setState({allTilesArr: res.data.result})

                // console.log(this.state.allTilesArr)
            })
               this.findDuplicateTiles()
    }

    findDuplicateTiles=()=>{
            if(this.state.allTilesArr){
                return this.state.allTilesArr.map((item, i)=>{   
                    console.log(item)
                    // if(item.plan[0])console.log(item.plan[0].description)
                    // let categoryId = item.tile.tile_category_id;
                    // let plan = item.plan[0].description;
                    if(item.plan.length > 1){
                        let y = item.plan.length; 
                        for(let x=0; x < y; x++)
                        intialPlanArr.push(item.plan[x].description);
                    }
                    else{
                        if(item.plan[0])intialPlanArr.push(item.plan[0].description)
                    }
                });
            }
        };

    comparArr() {
        if(this.state.description.reduce((a, b) => a && intialPlanArr.includes(b), true)){
            alert('tile already exists')
            return;
        }
    }

    goBack() {
        window.history.back();
    }

    sleepCheck(initialDescription){
        this.setState({alert:false})
        if(initialDescription === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }
    }


    changeTileClass1() {
        if(this.state.initialDescription[0] === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }

        if(intialPlanArr.includes(this.state.initialDescription[0])){
                alert(`"${this.state.initialDescription[0]}" already exists.` )
                return;
        }

        // if(this.state.initialDescription.includes("sleep")){
        // let sleep;
        // sleep = this.state.description.includes("sleep");
        // if(sleep === false && this.state.description.length > 0){
        //     alert('"Sleep" is only available in a single plan tile.' )
        //     return;
        // }
        // }
        let initialDescription = this.state.initialDescription[0];
        if(this.props.location.state.category !== "what you do" ) {
            this.setState({tileStyle1: !this.state.tileStyle1}, () => {
                if(this.state.tileStyle1 === false){
                    this.setState({description: []}, () => {
                        this.setState({ description: this.state.description.concat(this.state.initialDescription[0]) })
                        this.setState({tileStyle2: true})
                        this.setState({tileStyle3: true})
                        this.setState({tileStyle4: true})
                        this.setState({tileStyle5: true})
                        this.setState({tileStyle6: true})
                        this.setState({tileStyle7: true})
                    })

                }
                else{this.setState({description: []})}
            }) 
        }
        else{
            if(this.state.tileStyle1 === true) {
              this.setState({tileStyle1: !this.state.tileStyle1})   
              this.setState({ description: this.state.description.concat(this.state.initialDescription[0]) })
            }
            else{
                this.setState({tileStyle1: !this.state.tileStyle1}) 
                this.setState({description: this.state.description.filter(function(descriptionValue) { 
                    return descriptionValue !== initialDescription 
                })});
            }
        }

    }

    changeTileClass2() {
        if(this.state.initialDescription[1] === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }
        if(intialPlanArr.includes(this.state.initialDescription[1])){
                alert(`"${this.state.initialDescription[1]}" already exists.` )
                return;
        }
        let initialDescription = this.state.initialDescription[1];
        if(this.props.location.state.category !== "what you do" ) {
            this.setState({tileStyle2: !this.state.tileStyle2}, () => {
                if(this.state.tileStyle2 === false){
                    this.setState({description: []}, () => {
                        this.setState({ description: this.state.description.concat(this.state.initialDescription[1]) })
                        this.setState({tileStyle1: true})
                        this.setState({tileStyle3: true})
                        this.setState({tileStyle4: true})
                        this.setState({tileStyle5: true})
                        this.setState({tileStyle6: true})
                        this.setState({tileStyle7: true})
                    })
                }
                else{this.setState({description: []})}
            })
        }
        else{
            if(this.state.tileStyle2 === true) {
              this.setState({tileStyle2: !this.state.tileStyle2})   
              this.setState({ description: this.state.description.concat(this.state.initialDescription[1]) })
            }
            else{
                this.setState({tileStyle2: !this.state.tileStyle2}) 
                this.setState({description: this.state.description.filter(function(descriptionValue) { 
                    return descriptionValue !== initialDescription 
                })});
            }
        }

    }

    changeTileClass3() {
        if(this.state.initialDescription[2] === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }
        if(intialPlanArr.includes(this.state.initialDescription[2])){
                alert(`"${this.state.initialDescription[2]}" already exists.` )
                return;
        }
        let initialDescription = this.state.initialDescription[2];
        if(this.props.location.state.category !== "what you do" ) {
            this.setState({tileStyle3: !this.state.tileStyle3}, () => {
                if(this.state.tileStyle3 === false){
                    this.setState({description: []}, () => {
                        this.setState({ description: this.state.description.concat(this.state.initialDescription[2]) })
                        this.setState({tileStyle1: true})
                        this.setState({tileStyle2: true})
                        this.setState({tileStyle4: true})
                        this.setState({tileStyle5: true})
                        this.setState({tileStyle6: true})
                        this.setState({tileStyle7: true})
                    })

                }
                else{this.setState({description: []})}
            })
        }
        else{
            if(this.state.tileStyle3 === true) {
              this.setState({tileStyle3: !this.state.tileStyle3})   
              this.setState({ description: this.state.description.concat(this.state.initialDescription[2]) })
            }
            else{
                this.setState({tileStyle3: !this.state.tileStyle3}) 
                this.setState({description: this.state.description.filter(function(descriptionValue) { 
                    return descriptionValue !== initialDescription
                })});
            }
        }
    }

    changeTileClass4() {
        if(this.state.initialDescription[3] === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }
        if(intialPlanArr.includes(this.state.initialDescription[3])){
                alert(`"${this.state.initialDescription[3]}" already exists.` )
                return;
        }
        let initialDescription = this.state.initialDescription[3];
        if(this.props.location.state.category !== "what you do" ) {
            this.setState({tileStyle4: !this.state.tileStyle4}, () => {
                if(this.state.tileStyle4 === false){
                    this.setState({description: []}, () => {
                        this.setState({ description: this.state.description.concat(this.state.initialDescription[3]) })
                        this.setState({tileStyle1: true})
                        this.setState({tileStyle2: true})
                        this.setState({tileStyle3: true})
                        this.setState({tileStyle5: true})
                        this.setState({tileStyle6: true})
                        this.setState({tileStyle7: true})
                    })

                }
                else{this.setState({description: []})}
            })
        }
        else{
            if(this.state.tileStyle4 === true) {
              this.setState({tileStyle4: !this.state.tileStyle4})   
              this.setState({ description: this.state.description.concat(this.state.initialDescription[3]) })
            }
            else{
                this.setState({tileStyle4: !this.state.tileStyle4}) 
                this.setState({description: this.state.description.filter(function(descriptionValue) { 
                    return descriptionValue !== initialDescription 
                })});
            }
        }
    }

    changeTileClass5() {
        if(this.state.initialDescription[4] === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }
        if(intialPlanArr.includes(this.state.initialDescription[4])){
                alert(`"${this.state.initialDescription[4]}" already exists.` )
                return;
        }
        let initialDescription = this.state.initialDescription[4];
        if(this.props.location.state.category !== "what you do" ) {
            this.setState({tileStyle5: !this.state.tileStyle5}, () => {
                if(this.state.tileStyle5 === false){
                    this.setState({description: []}, () => {
                        this.setState({ description: this.state.description.concat(this.state.initialDescription[4]) })
                        this.setState({tileStyle1: true})
                        this.setState({tileStyle2: true})
                        this.setState({tileStyle3: true})
                        this.setState({tileStyle4: true})
                        this.setState({tileStyle6: true})
                        this.setState({tileStyle7: true})
                    })

                }
                else{this.setState({description: []})}
            })
        }
        else{
            if(this.state.tileStyle5 === true) {
              this.setState({tileStyle5: !this.state.tileStyle5})   
              this.setState({ description: this.state.description.concat(this.state.initialDescription[4]) })
            }
            else{
                this.setState({tileStyle5: !this.state.tileStyle5}) 
                this.setState({description: this.state.description.filter(function(descriptionValue) { 
                    return descriptionValue !== initialDescription 
                })});
            }
        }
    }

    changeTileClass6() {
        if(this.state.initialDescription[5] === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }
        if(intialPlanArr.includes(this.state.initialDescription[5])){
                alert(`"${this.state.initialDescription[5]}" already exists.` )
                return;
        }
        let initialDescription = this.state.initialDescription[5];
        if(this.props.location.state.category !== "what you do" ) {
            this.setState({tileStyle6: !this.state.tileStyle6}, () => {
                if(this.state.tileStyle6 === false){
                    this.setState({description: []}, () => {
                        this.setState({ description: this.state.description.concat(this.state.initialDescription[5]) })
                        this.setState({tileStyle1: true})
                        this.setState({tileStyle2: true})
                        this.setState({tileStyle3: true})
                        this.setState({tileStyle4: true})
                        this.setState({tileStyle5: true})
                        this.setState({tileStyle7: true})
                    })

                }
                else{this.setState({description: []})}
            })
        }
        else{
            if(this.state.tileStyle6 === true) {
              this.setState({tileStyle6: !this.state.tileStyle6})   
              this.setState({ description: this.state.description.concat(this.state.initialDescription[5]) })
            }
            else{
                this.setState({tileStyle6: !this.state.tileStyle6}) 
                this.setState({description: this.state.description.filter(function(descriptionValue) { 
                    return descriptionValue !== initialDescription 
                })});
            }
        }
    }


    changeTileClass7() {
        if("custom" === "sleep"){
            if(this.state.initialDescription.includes("sleep")){
            let sleep;
            sleep = this.state.description.includes("sleep");
                if(sleep === false && this.state.description.length > 0){

                    alert('"Sleep" is only available in a single plan tile.' )
                    return;
                }
            }
        }
        else{
            let sleep;
            sleep = this.state.description.includes("sleep");
            if(sleep === true){
 
                alert('"Sleep" is only available in a single plan tile.' )
                return;
            }
        }
        if(intialPlanArr.includes(this.state.initialDescription[6])){
                alert(`"${this.state.initialDescription[6]}" already exists.` )
                return;
        }
        if(this.props.location.state.category !== "what you do" ) {
            this.setState({tileStyle7: !this.state.tileStyle7}, () => {
                if(this.state.tileStyle7 === false){
                    this.setState({description: []}, () => {
                        this.setState({ description: this.state.description.concat('Custom') })
                        this.setState({custom: true})
                        this.setState({tileStyle1: true})
                        this.setState({tileStyle2: true})
                        this.setState({tileStyle3: true})
                        this.setState({tileStyle4: true})
                        this.setState({tileStyle5: true})
                        this.setState({tileStyle6: true})
                    })
                }
                else{this.setState({description: []})}
            })

        }
        else{
            if(this.state.tileStyle7 === true) {
              this.setState({tileStyle7: !this.state.tileStyle7})   
              this.setState({ description: this.state.description.concat('Custom') })
              this.setState({custom: true})
            }
            else{
                this.setState({tileStyle7: !this.state.tileStyle7}) 
                this.setState({custom: false})
                this.setState({description: this.state.description.filter(function(descriptionValue) { 
                    return descriptionValue !== 'Custom' 
                })});
            }
        }
    }

    createStaticList=()=>{
            if(this.state.description){
                return this.state.description.map((item, i)=>{
                    console.log(i)
                });
            }
        };

    render(){
        console.log(this.state.description)
        this.findDuplicateTiles()
        let changeTileClass1 = this.state.tileStyle1 ? "tileDescriptions1 tile-card" : "tile-card tile-selected";
        let changeTileClass2 = this.state.tileStyle2 ? "tileDescriptions2 tile-card" : "tile-card tile-selected";
        let changeTileClass3 = this.state.tileStyle3 ? "tileDescriptions3 tile-card" : "tile-card tile-selected";
        let changeTileClass4 = this.state.tileStyle4 ? "tileDescriptions4 tile-card" : "tile-card tile-selected";
        let changeTileClass5 = this.state.tileStyle5 ? "tileDescriptions5 tile-card" : "tile-card tile-selected";
        let changeTileClass6 = this.state.tileStyle6 ? "tileDescriptions6 tile-card" : "tile-card tile-selected";
        let changeTileClass7 = this.state.tileStyle7 ? "tileDescriptions7 tile-custom tile-card" : "tile-card tile-custom tile-selected";
        return(
            <div className="capitalize">
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.firstName}</p>
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="col-md-1 col-xs-12">
                        <button className="btn"  onClick={()=>this.goBack()}>BACK</button>    
                    </div>
                    <div className="col-md-10 col-xs-12">
                        <ul className="progressbar text-center">
                            <li className="complete">Step 1: Select Tile</li>
                            <li className="active">Step 2: Select Plan</li>
                            <li>Step 3: Set Goals</li>
                            <li>Step 4: Confirm & Save</li>
                        </ul>
                    </div>
                    <div className="col-md-1 col-xs-12">
                        <button className="btn primary" onClick={()=>this.moveToStep3(
                                        this.props.location.state.categoryId,
                                        this.props.location.state.category,
                                        this.state.description,
                                        this.state.custom
                                        )}>
                        Next Step</button>                                                                    
                    </div>
                </div>                
                <div className="row">
                    <div className="tileDescriptionsContainer tile-creation--container">
                        <div className={changeTileClass1} onClick={()=>this.changeTileClass1()}>
                            <h4>{this.props.location.state.category}</h4>
                            <p>{this.state.initialDescription[0]}</p>
                        </div>
                        {this.state.initialDescription.length > 1
                            ?   <div className={changeTileClass2} onClick={()=>this.changeTileClass2()}>
                                    <h4>{this.props.location.state.category}</h4>
                                    <p>{this.state.initialDescription[1]}</p>
                                </div>
                            : null
                        }
                        {this.state.initialDescription.length > 2
                            ?   <div className={changeTileClass3} onClick={()=>this.changeTileClass3()}>
                                    <h4>{this.props.location.state.category}</h4>
                                    <p>{this.state.initialDescription[2]}</p>
                                </div>
                            : null
                        }
                        {this.state.initialDescription.length > 3
                            ?   <div className={changeTileClass4} onClick={()=>this.changeTileClass4()}>
                                    <h4>{this.props.location.state.category}</h4>
                                    <p>{this.state.initialDescription[3]}</p>
                                </div>
                            : null
                        }
                        {this.state.initialDescription.length > 4
                            ?   <div className={changeTileClass5} onClick={()=>this.changeTileClass5()}>
                                    <h4>{this.props.location.state.category}</h4>
                                    <p>{this.state.initialDescription[4]}</p>
                                </div>
                            : null
                        }
                        {this.state.initialDescription.length > 5
                            ?   <div className={changeTileClass6} onClick={()=>this.changeTileClass6()}>
                                    <h4>{this.props.location.state.category}</h4>
                                    <p>{this.state.initialDescription[5]}</p>
                                </div>
                            : null
                        }
                        <div className={changeTileClass7} onClick={()=>this.changeTileClass7()} >
                            <h4>{this.props.location.state.category}</h4>
                            <p>Custom Description</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        )

    }
}

export default withRouter(Step2);
