import React, {useState, useEffect} from 'react';
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import startOfToday from "date-fns/startOfToday";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import FileDownload from "js-file-download";

// styles
import styles from './habitAnalytics.module.css';

// actions
import {
    fetchHealthGoalDistribution,
    fetchMembersWhoMetGoals,
    fetchMembersWhoMetGoalsCsv
} from "../../../store/actions/analyticsActions";

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import GoalDistribution from "./goalDistribution/goalDistribution";
import GoalExportList from "./goalExportList/goalExportList";

const HabitAnalytics = props => {
    // props
    const {filters} = props;
    // local
    const [date, setDate] = useState({
        start: subDays(startOfToday(), 30),
        end: new Date()
    });
    const [goalDistribution, setGoalDistribution] = useState(null);
    const [exportList, setExportList] = useState(null);
    const [query, setQuery] = useState({
        start: date.start,
        end: date.end,
        organizationId: filters.organization,
        statusId: filters.status,
        gender: filters.gender,
        ageGroup: filters.ageGroup,
        coach: filters.coach,
    });
    const [exporting, setIsExporting] = useState(false);

    // on first render
    useEffect(() => {
        runQuery();
    }, [query]);

    useDidMountEffect(() => {
        const newQuery = {
            start: date.start,
            end: date.end,
            organizationId: filters.organization,
            statusId: filters.status,
            gender: filters.gender,
            ageGroup: filters.ageGroup,
            coach: filters.coach,
            rank: filters.rank,
        };
        setQuery(newQuery);
    }, [date, filters]);

    // functions
    function runQuery() {
        getGoalDistribution();
        loadExportList();
    }

    function exportMembers() {
        setIsExporting(true);
        fetchMembersWhoMetGoalsCsv(query)
            .then((response) => {
                setIsExporting(false);
                const date = new Date();
                const day = date.getDate().toString();
                const month = (date.getMonth()+1).toString();
                const year = date.getFullYear().toString();
                const file_name = (`member_goals_${month}-${day}-${year}.csv`).trim();
                FileDownload(response, file_name);
            }).catch(() => setIsExporting(false));
    }

    async function getGoalDistribution() {
        try{
            const goals = await fetchHealthGoalDistribution(query);
            const transformed = {};
            let total = 0;
            goals.forEach(goal => {
                transformed[goal.healthgoal] = goal.count;
                total += goal.count;
            });
            setGoalDistribution({
                lasting_weight_loss: transformed['Lasting Weight Loss'],
                stronger_leaner_body: transformed['Build a Stronger Leaner Body'],
                heart_and_cognitive_health: transformed['Improve Heart and Cognitive Health'],
                more_energy: transformed['More Energy, Less Stress'],
                physical_activity: transformed['Make Physical Activity a Regular Habit'],
                healthier_food_choices: transformed['Healthier Food Choices'],
                better_blood_pressure: transformed['Better Blood Pressure'],
                better_blood_sugar: transformed['Better Blood Sugar'],
                total,
            });
        } catch (e) {
            console.log(e);
        }
    }

    async function loadExportList() {
        try{
            const list = await fetchMembersWhoMetGoals(query);
            console.log(list);
            setExportList(list);
        } catch (e) {
            console.log(e);
        }
    }

    function handleDate(e, range) {
        const val = e.target.value;
        let parsedDate = parse(val, 'yyyy-MM-dd', new Date());
        if (range === 'start') {
            parsedDate = startOfDay(parsedDate);
            return setDate({...date, start: parsedDate});
        } else {
            parsedDate = endOfDay(parsedDate);
            return setDate({...date, end: parsedDate});
        }
    }
    return (
        <div>
            <h3 className={styles.heading}> Habit Analytics</h3>
            <form className={styles.filter}>
                <div>
                    <label>Choose Date Range</label>
                    <div className={`form-group ${styles.dateRange}`} data-cy='date-range-habit'>
                        <input
                            max={format(subDays(date.end, 1), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.start, 'yyyy-MM-dd', {})}
                            onChange={e => handleDate(e, 'start')}
                            onKeyDown={e => e.preventDefault()}
                        />
                        <span className='p-r:1 p-l:1'>to</span>
                        <input
                            min={format(addDays(date.start, 1), 'yyyy-MM-dd')}
                            max={format(new Date(), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.end, 'yyyy-MM-dd')}
                            onChange={e => handleDate(e, 'end')}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>

                </div>
            </form>
            <div className={styles.goalRow}>
                <BoxContainer className={styles.healthGoals} data-cy='health-goal-distribution-habit'>
                    <h3 className={styles.heading2}>Health Goal Distribution - {goalDistribution ? goalDistribution.total : ''}</h3>
                    <GoalDistribution data={goalDistribution}/>
                </BoxContainer>
            </div>

            <div className={styles.exportRow} data-cy='member-goal-habit'>
                <BoxContainer className='padding-standard'>
                    <GoalExportList data={exportList} exporting={exporting} csvExport={exportMembers}/>
                </BoxContainer>
            </div>
        </div>
    )
}

export default HabitAnalytics;