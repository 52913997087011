import React, {useState, useEffect, useContext} from 'react';
import Confetti from "react-dom-confetti";
import { Modal } from "react-bootstrap";
import {ShepherdTourContext} from "react-shepherd";
import {connect} from "react-redux";

// actions
import { fetchRewards } from "../../store/actions/rewardsActions";
import { fetchTips } from "../../store/actions/tipsActions";

// components
import ClientHeader from "../clientHeader/clientHeader";
import WellnessScore from "../Client/clientDetail/WellnessScore";
import Slider from "react-slick";
import PhotoContainer from "../Client/Teammates/container";
import ClientHub from './clientHub';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const config = {
    angle: 90,
    spread: 108,
    startVelocity: 90,
    elementCount: 250,
    decay: 0.9,
    colors: [
        '#fff',
        '#00325c',
        '#7eaf5b',
        '#c4b02d'
    ]
};

const ClientHubContainer = ({ ...props }) => {
    const tour = useContext(ShepherdTourContext);
    const [ state, updateState ] = useState({
        username: props.user.username,
        clientDashChild: true,
        show: false,
        kudos:[],
        confetti:false,
        teammateMessage:null,
        confettiOverlay:false,
        tip: null,
        tourModal: true,
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function handleClose() {
        setState({ show: false, confettiOverlay:false });

    }

    function handleShow() {
        setState({ show: true });
        handleConfetti();
    }

    function handleConfetti() {
        this.setState({ show: true, confettiOverlay:true });
        this.setState({ confetti: true }, () => {
            this.setState({ confetti: false });
        });

    }

    function handleConfettiOverlay(){
        if(state.confettiOverlay){
            return(
                <div className="confetti-overlay">
                    <Confetti active={ state.confetti } config={ config }  />
                </div>
            )
        }
    }

    // on mount
    useEffect(() => {
        const username = props.match.params.username;
        setState({username});
        fetchRewards(username)
            .then((rewards)=>{
                if(rewards.length > 0){
                    setState({kudos:rewards});
                    handleShow();
                }
            })
            .catch(error => console.log(error));

        fetchTips()
            .then(tip=> {
                setState({ tip });
            })
    },[]);

    return (
        <div className="client-container">
            {handleConfettiOverlay()}
            <ClientHeader transparent={true} type={'clear'} tour={tour}/>
            <WellnessScore tour={tour} username={state.username} />

            <ClientHub tip={state.tip}/>

            <div>
                <Modal className="kudos-container" show={state.show} onHide={handleClose}>
                    <div className="exitBtn teammates-exitBtn" onClick={handleClose}><div>X</div></div>
                    <Slider {...settings}>
                        {
                            state.kudos.map((item, i)=>{
                                return(
                                    <div>
                                        <Modal.Header closeButton>
                                            <PhotoContainer
                                                image={item.teammate.profile_picture}
                                            />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <h4 className="capitalize">{item.teammate.first_name} sent you kudos!</h4>
                                            <p className="capitalize">{item.message}</p>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <div className="text-center">
                                                <p>Kudos {i+1} of {state.kudos.length}</p>
                                            </div>
                                        </Modal.Footer>
                                    </div>

                                )
                            })
                        }
                    </Slider>

                </Modal>
            </div>
        </div>
    )
};

function mapStateToProps({user}) {
    return {user};
}

export default connect(mapStateToProps)(ClientHubContainer);