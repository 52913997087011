//client search view
import React from 'react';
import CoachSearch from './coachSearch';
import {withRouter} from 'react-router-dom';


class CoachSearchMain extends React.Component {
    constructor(){
	  super();
	  this.state= {
	    data : ""
	  }
	}
 
    formChild1(params) {
	  this.setState({
	    data : params
	  })
	}

	render() {
		return(
			<div>
				<CoachSearch />
			</div>
			);
	}
};

export default withRouter(CoachSearchMain);