import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import {Modal} from "react-bootstrap";

// styles
import styles from './featuredContent.module.css';

// actions
import {
    fetchCategoryContent,
    fetchCategory,
    fetchCategoryTags,
    fetchCategoryCollections,
    fetchCollectionsInstructors
} from "../../store/actions/contentActions";

// components
import ContentThumbnail from './contentThumbnail/contentThumbnail';
import {ReactComponent as FilterIcon} from "../../images/filter-icon.svg";
import {ErrorAlert} from "../alerts/alerts";
import ClientHeader from "../clientHeader/clientHeader";
import OnDemandFilter from "../onDemandFilter/onDemandFilter";
import Loading from "../Loading/Loading";

const FeaturedContent = props => {
    // props
    const {match} = props;
    // state
    const {user} = props;
    // local
    const [loading, setLoading] = useState(true);
    const [generalError, setGeneralError] = useState('');
    const [category, setCategory] = useState({});
    const [content, setContent] = useState([]);
    const [collections, setCollections] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filters, setFilters] = useState({
        length: null,
        tags: null,
        instructors: null,
    });
    const [showNew, setShowNew] = useState(false);
    const [showOnlyCollections, setShowOnlyCollections] = useState(false);
    const [form, setForm] = useState({});

    // grab list of content and category on mount
    useEffect(() => {
        setLoading(true);
        setForm({});
        Promise.all([
            fetchCategory(match.params.category)
                .then(result => setCategory(result)),
            fetchCategoryContent(match.params.category)
                .then(result => {
                    setContent(result);
                }),
            fetchCategoryCollections(match.params.category)
                .then(result => {
                    setCollections(result);
                }),
        ]).then(() => setLoading(false))
            .catch(e => console.log(e));
    }, [match.params.category]);

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    }, []);

    // grab filters
    useEffect(() => {
        async function prep() {
            const lengths = {
                options: [
                    {content_meta_option: 'Less than 5 min', content_meta_option_id: 1},
                    {content_meta_option: '5 - 9 min', content_meta_option_id: 2},
                    {content_meta_option: '10 - 20 min', content_meta_option_id: 3},
                    {content_meta_option: 'Greater than 20 min', content_meta_option_id: 4},
                ]
            };
            const res = await fetchCollectionsInstructors(match.params.category);
            const instructors = {
                options: [],
            };
            if (res) instructors.options = res.map(val => ({content_meta_option: val.option_label, content_meta_option_id: val.option_id}));
            setFilters(prevState => ({...prevState, instructors, lengths}))
        }
        prep();
    },[match.params.category]);

    // load category tags for filter
    useEffect(() => {
        if (!category || !category.category_id) return;
        fetchCategoryTags(category.category_id)
            .then(list => {
                console.log(list);
                setFilters(prevState => ({
                    ...prevState,
                    tags: {
                        options: list.map(item => ({content_meta_option: item.tag, content_meta_option_id: item.id})),
                    }
                }))
            })
            .catch(e => {
                if (e.response && e.response.data) {
                    setGeneralError(e.response.data.error)
                } else {
                    setGeneralError('Unable to load tags. Please refresh to try again.');
                }
            })
    }, [category]);

    function openFilterModal() {
        setShowFilterModal(true);
    }

    async function submitFilter(val) {
        setLoading(true);
        setShowNew(val.isNew || false);
        setShowOnlyCollections(val.collections || false);
        setForm(val);
        const newFilter = {};
        if (val.tags) newFilter.tags = [val.tags.value];
        if (val.length) newFilter.length = val.length.value;
        if (val.instructor) newFilter.trainer = val.instructor.value;
        if (val.favorite) newFilter.favorite = val.favorite.value;
        setShowFilterModal(false);

        try {
            const content = await Promise.all([
                fetchCategoryContent(match.params.category, newFilter),
                fetchCategoryCollections(match.params.category, newFilter),
            ]);

            setContent(content[0]);
            setCollections(content[1]);
            setLoading(false);
        } catch(e) {
            setLoading(false);
        }
    }

    async function resetForm() {
        setLoading(true);
        setShowFilterModal(false);
        setForm({});
        setShowOnlyCollections(false);
        setShowNew(false);
        try {
            const content = await Promise.all([
                fetchCategoryContent(match.params.category),
                fetchCategoryCollections(match.params.category),
            ]);

            setContent(content[0]);
            setCollections(content[1]);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }

    }

    if (loading) {
        return (
            <div className={styles.loading}>
                <Loading/>
            </div>
        )
    }

    let backUrl = `/member/clientDash/${user.username}`;
    if (category.collection) backUrl = `/member/featured/${category.parent_category_id}`;
    return (
       <>
           <ClientHeader type={'transparent'} backUrl={backUrl}/>
           <div>
               <div
                   className={`wellness-bg wydBG pathwaysBackground margin-bottom-25`}
                   style={{backgroundImage: `url(${category.hero_image_url})`}}>
                   <div className='pathwaysOverlay'/>
                   <div className="container client-dash no-overview">
                       <div className="row">
                           <div className="col-xs-12">
                               <div>
                                   <h2>{category.category_name}</h2>
                                   <p>{category.category_description}</p>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
               <div>
                   <div className="container client-dash">
                       <div className="row">
                           <div className="col-xs-12">
                               {generalError ?
                                   <div><ErrorAlert>{generalError}</ErrorAlert></div>
                                   : null
                               }
                               <div className='d:f j-c:f-e'>
                                   <div className={styles.filterIconContainer} onClick={openFilterModal}>
                                       <FilterIcon className={styles.filterIcon}/>
                                       <span>Filters</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="row d:f flx-w:w">
                           {collections.map((item, i) => {
                               if (showNew && !item.is_new) return;
                               return (
                                   <div key={item.id} className="col-xs-6 col-sm-3 col-md-2 col-xl-1">
                                       <ContentThumbnail
                                           id={item.collection_category_id}
                                           format='collection'
                                           to={`/member/featured/${item.collection_category_id}`}
                                           title={item.collection_name}
                                           img={item.tile_image_url}
                                           isNew={item.is_new}
                                           collectionLabel={category.collection_label}
                                           contentCount={item.content_count}
                                           favorite={item.favorite}/>
                                   </div>
                               )
                           })}
                           {showOnlyCollections ?
                               null
                               :
                               <>
                                   {content.map((item, i) => {
                                       if (showNew && !item.new_flag) return;
                                       if(item.format === 'video') {
                                           return (
                                               <div key={item.id} className="col-xs-6 col-sm-3 col-md-2 col-xl-1" dataCy={`videoTile_${i}`}>
                                                   <ContentThumbnail
                                                       id={item.id}
                                                       className={i===0 ? 'tour-featured-category': ''}
                                                       format='video'
                                                       to={`/member/featured/video/${item.id}`}
                                                       title={item.title}
                                                       img={item.thumbnail}
                                                       isNew={item.new_flag}
                                                       length={item.format_length}
                                                       favorite={item.user_favorite}
                                                       watched={item.user_previously_viewed}/>
                                               </div>
                                           )
                                       }
                                       if (item.format === 'audio') {
                                           return (
                                               <div key={item.id} className="col-xs-6 col-sm-3 col-md-2 col-xl-1" dataCy={`audioTile-${i}`}>
                                                   <ContentThumbnail
                                                       id={item.id}
                                                       className={i===0 ? 'tour-featured-category': ''}
                                                       format='audio'
                                                       to={`/member/featured/audio/${item.id}`}
                                                       title={item.title}
                                                       img={item.thumbnail}
                                                       isNew={item.new_flag}
                                                       length={item.format_length}
                                                       favorite={item.user_favorite}
                                                       watched={item.user_previously_viewed}
                                                       />
                                               </div>
                                           )
                                       }
                                       if (item.format === 'pdf') {
                                           return (
                                               <div key={item.id} className="col-xs-6 col-sm-3 col-md-2 col-xl-1">
                                                   <ContentThumbnail
                                                       id={item.id}
                                                       className={i===0 ? 'tour-featured-category': ''}
                                                       to={`/member/featured/pdf/${item.id}`}
                                                       format='pdf'
                                                       title={item.title}
                                                       isNew={item.new_flag}
                                                       img={item.thumbnail}
                                                       favorite={item.user_favorite}
                                                       watched={item.user_previously_viewed}
                                                       dataCy={`pdfTile-${i}`}/>
                                               </div>
                                           )
                                       }
                                       if (item.format === 'link') {
                                           return (
                                               <div  key={item.id} className="col-xs-6 col-sm-3 col-md-2 col-xl-1">
                                                   <ContentThumbnail
                                                       preventRedirect={user.kiosk}
                                                       externalWarning={item.link_disclaimer}
                                                       id={item.id}
                                                       className={i===0 ? 'tour-featured-category': ''}
                                                       to={item.content}
                                                       format='link'
                                                       title={item.title}
                                                       isNew={item.new_flag}
                                                       img={item.thumbnail}
                                                       favorite={item.user_favorite}
                                                       watched={item.user_previously_viewed}
                                                       dataCy={`featuredTile-${i}`}/>
                                               </div>
                                           )
                                       }
                                   })}
                               </>
                           }
                       </div>
                   </div>
               </div>

               <div className="footer-actions">
                   <div className="container">
                       <div className="row">
                           <div className="col-xs-6">
                               <button className="btn client-white" onClick={()=>props.history.push(`/member/clientDash/${user.username}`)}>Back</button>
                           </div>
                       </div>
                   </div>
               </div>

               {/*Filter Modal*/}
               <Modal
                   className='mobileModal'
                   show={showFilterModal}
                   onHide={() => setShowFilterModal(false)}>
                   {showFilterModal ?
                       <OnDemandFilter
                           close={() => setShowFilterModal(false)}
                           callback={submitFilter}
                           reset={resetForm}
                           filters={filters}
                           showNew
                           showCollections={!category.collection}
                           collectionsLabel={category.collection_label}
                           instructorLabel={category.instructor_label}
                           values={form}
                       />
                       : null
                   }
               </Modal>
           </div>
       </>
    )
};

function mapStateToProps({ user }) {
    return { user };
}

const actions = {
}

export default withRouter(connect(mapStateToProps, actions)(FeaturedContent));
