import React, {Component} from 'react';
import {fetchSelf} from "../../../actions/action_user";
import {connect} from 'react-redux';
import axios from 'axios';
import {ImageUpload} from "../ClientProfile/imageUpload/imageUploadContainer";
import { Alert } from 'react-bootstrap';
import {PrimaryButton} from "../../buttons/buttons";

let data = new FormData();

class AddTeammateFormContainer extends Component{
    constructor(props){
        super(props);
        this.state = {
          nickname:'',
          firstName: '',
          lastName: '',
          email:'',
          profile_image:'',
          show: false,
          alertStyle: true,
          alert: '',
          imgUploaded:true,
          error:'',
          formChanged:false,
          imgFile:null,
        }
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.setProfileImage = this.setProfileImage.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keypress', this.preventEnter);
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.preventEnter)
    }

    preventEnter = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            e.preventDefault();
            return false;
        }

    }

    handleDismiss() {
      this.setState({ show: false });
    }

    handleShow() {
      this.setState({ show: true });
    }

    sendInvite(e){
      e.preventDefault()
      this.setState({show:false})
      data.append('email', this.state.email);
      data.append('nickname', this.state.nickname);
      data.append('first_name', this.state.firstName);
      data.append('last_name', this.state.lastName);
      axios.post(`/teammates`, data)
        .then((res)=>{
            if(res.data.message === 'SUCCESS'){
                data = new FormData();
                this.props.history.push({
                    pathname: `/member/clientDash/${this.props.user.username}/Teammates`
                })
            }})
        .catch((error)=>{
          let alert = "error";
          if(error.response.data.err.teammate_email){
            alert = error.response.data.err.teammate_email[0]
          }
          else{
            alert = error.response.data.err
          }
          this.setState({show: true, alert: alert, alertStyle: false})
          data = new FormData();
          this.setProfileImage(this.state.imgFile)
        });

      // data = new FormData();
      
    }

    setProfileImage(file){
      this.setState({imgFile:file})
      data = new FormData();
      data.append('image', file);
    };

    errorHandler = (error)=>{
        this.setState({error:error});
        this.setState({ show: true });
        this.setState({alert:error});
        this.setState({ alertStyle: false });
    };

    clickImg = (confirmation) => {
        if(!confirmation){
          this.setState({imgUploaded:false})
        }
        if(confirmation){
          this.setState({imgUploaded:true})
        }
    }
  
  readURI(e){
    if(e.target.files && e.target.files[0]){
      let reader = new FileReader();
      reader.onload = function(ev){
        this.setState({imageURI:ev.target.result});
      }.bind(this);
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  
  handleChange(e){
    this.readURI(e); 
    if (this.props.onChange !== undefined)
      this.props.onChange(e); 
  }

  removeImage(e){
    this.setState({imageURI:null})
    this.setProfileImage(this.state.imageURI); 
  }

    goBack() {
      window.history.back();
    } 

    render(){
      let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
      return(
        <div>
          <form onSubmit={(e)=>this.sendInvite(e)}>
              <div className="container client-account-dash">
                  <h3>ADD NEW TEAMMATE</h3>  
                  <p>Enlist Support</p>            
                  <div className="row margin-top">
                      <div className="row">
                        <div className="col-md-1 col-xs-1">

                        <div className="profile-img-container" onChange={this.handleChange.bind(this)}>
                            <ImageUpload
                             image={this.state.imageURI} 
                             setProfileImage={this.setProfileImage}
                             errorHandler={this.errorHandler}
                             clickImg={this.clickImg}
                             />
                        </div>

                          <div>
                            <p onClick={this.removeImage.bind(this)}>x Remove Image</p>
                          </div>
                        </div> 
                      </div>
                      <div className="row container tour-team-form">
                            <div className="col-md-6 col-xs-12">
                              <div className='form-group'>
                                  <label>FIRST NAME</label>       
                                  <input
                                      name="firstName" type="text"
                                      value={this.state.firstName}
                                      onChange={(event)=>this.setState({firstName:event.target.value})}
                                      id='tour-team-firstName'
                                      className='tour-team-firstName'
                                   />
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <div className='form-group'>
                                  <label>LAST NAME</label>       
                                  <input
                                      name="lastName"
                                      type="text"
                                      value={this.state.lastName}
                                      onChange={(event)=>this.setState({lastName:event.target.value})}
                                      className='tour-team-lastName'
                                   />
                              </div>
                            </div>
                          <div className="col-md-6 col-xs-12">
                              <div className='form-group'>
                                  <label>EMAIL ADDRESS</label>
                                  <input
                                      type="email"
                                      value={this.state.email}
                                      onChange={(event)=>this.setState({email:event.target.value})}
                                      className='tour-team-email'
                                  />
                              </div>
                          </div>
                            <div className="col-md-6 col-xs-12">
                              <div className='form-group'>
                                  <label>NICKNAME</label>       
                                  <input
                                      name="nickname"
                                      type="text"
                                      value={this.state.nickname}
                                      onChange={(event)=>this.setState({nickname:event.target.value})}
                                      className='tour-team-nickname'
                                   />
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
              <div className="container client-account-dash">
                  <PrimaryButton className="add--icon float-right tour-team-send" type="submit">SEND INVITE</PrimaryButton>
                  {this.state.show
                      ?  <Alert className="col-md-4 save-profile-alert tour-team-error" bsStyle={changeAlertStyle} onDismiss={this.handleDismiss}>
                          <p>{this.state.alert}</p>
                      </Alert>
                      : null
                  }
              </div>
          </form>
        </div>
        )
    }
}


function mapStateToProps({user}){
    return{user}
}

export default connect(mapStateToProps, {fetchSelf})(AddTeammateFormContainer);
