export const profileTour = [
    {
        id: 'tour-profile-intro',
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        scrollTo: true,
        attachTo: { element: '.tour-profile-intro', on: 'auto' },
        advanceOn: {selector: '.tour-profile-intro', event: 'click'},
        text: "To access and make changes to your Profile Settings at any time, <strong>Click on the Avatar or Photo</strong> in the top right corner of your screen.",
        title: "Access your Profile Settings"
    },
    {
        id: 'tour-profile-dropdown',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-profile-dropdown') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        canClickTarget: false,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        attachTo: { element: '.tour-profile-dropdown', on: 'auto' },
        text: "You can navigate through your profile settings by selecting an option from your dropdown menu.",
        title: "Profile Dropdown Menu"
    },
    {
        id: 'tour-profile-save',
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Finish',
            }
        ],
        canClickTarget: false,
        scrollTo: true,
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 10,
        attachTo: { element: '.tour-profile-save', on: 'auto' },
        text: "You're all set! When you you’ve finished changing your settings, <strong>Click the SAVE button</strong> in the bottom right corner of your screen.",
        title: 'Save Changes'
    },
];