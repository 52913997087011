import React from 'react';

// styles
import styles from './calendars.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const Calendars = props => {
    // props
    const {calendars} = props;

    if (!calendars) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={`${styles.header} ${styles.calendar}`}>Calendar</div>
                <div className={`${styles.header} ${styles.meta}`}># of Appointments</div>
                <div className={`${styles.header} ${styles.meta}`}>Duration (min)</div>
                <div className={`${styles.header} ${styles.meta}`}>Total (min)</div>
            </div>
            {calendars.map(calendar => (
                <div key={calendar.calendar} className={styles.row}>
                    <div className={`${styles.calendar}`}>{calendar.calendar_title}</div>
                    <div className={`${styles.meta}`}>{calendar.appointments}</div>
                    <div className={`${styles.meta}`}>{calendar.duration}</div>
                    <div className={`${styles.meta}`}>{calendar.appointments*calendar.duration}</div>
                </div>
            ))}
        </div>
    )
}

export default Calendars;