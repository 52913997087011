import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import format from "date-fns/format";
import parseISO from 'date-fns/parseISO';
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import startOfToday from "date-fns/startOfToday";
import differenceInDays from 'date-fns/differenceInDays'
import formatISO from 'date-fns/formatISO';
import _ from 'lodash';

// styles
import styles from './ClientSummary.module.css';

// actions
import { fetchSingleUser } from "../../../actions/action_user";
import {
    fetchMemberActivity,
    fetchMemberLevelActivity, fetchMemberStreak, fetchScoreAverages,
    fetchScoreMetricsBreakouts
} from "../../../store/actions/userActions";
import {fetchMemberTiles, fetchPlanMetrics} from "../../../store/actions/tileActions";

// components
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import {SecondaryWarningAlert} from "../../alerts/alerts";
import AverageScores from "../../memberSummary/averageScores/averageScores";
import ScoreActivityGraph from "./scoreActivity/scoreActivityGraph";
import LevelActivityGraph from "./levelActivity/levelActivityGraph";
import TileActivityGraph from "./tileActivityGraph/tileActivityGraph";
import TargetedActivity from "./targetedActivity/targetedActivity";
import MemberStreak from "./memberStreak/memberStreak";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const ClientSummary =(props)=>{
    // state
    const { singleUser } = props;
    // actions
    const { fetchSingleUser } = props;
    // local
    const [date, setDate] = useState({
        start: subDays(startOfToday(), 7),
        end: new Date()
    });
    const [tiles, setTiles] = useState([]);
    const [active, setActive] = useState(null);
    const [scoreAverages, setScoreAverages] = useState(null);
    const [streakData, setStreakData] = useState(null);
    const [scoreActivityData, setScoreActivityData] = useState(null);
    const [levelActivityData, setLevelActivityData] = useState(null);
    const [tileActivityData, setTileActivityData] = useState(null);
    const [targetedActivityData, setTargetedActivityData] = useState({loading: false, page: 0, pages: null, data: []});

    useEffect(() => {
        fetchSingleUser(props.username);
        if (singleUser.user.username) {
            fetchMemberTiles(singleUser.user.username)
                .then(res => {
                    setTiles(res);
                    if (res.length > 0) setActive(res[0].plan[0].id);
                })
                .catch(e=> console.log(e));
        }
    }, [fetchSingleUser]);

    useEffect(() => {
        fetchScoreActivity();
        fetchLevelActivity();
        fetchAverages();
        fetchStreakData();
        if (targetedActivityData.page === 1) {
            fetchTargetedActivity();
        } else {
            setTargetedActivityData(prev => ({...prev, page: 1}));
        }
    }, [date]);

    useEffect(() => {
        if (active) {
            fetchPlanMetrics(active, date.start, date.end)
                .then(res => {
                    const hash = {};
                    const lookup = [];
                    const numberOfDays = differenceInDays(date.end, date.start)
                    res.forEach(item => hash[format(parseISO(item.date), 'MM/dd/yy')] = item)
                    for (let i = 0; i < numberOfDays; i++) {
                        let temp = new Date(date.end.getTime());
                        const dateMinus1 = subDays(temp, i);
                        lookup.push(format(dateMinus1, 'MM/dd/yy'));
                    }
                    const result = lookup.map(date => {
                        if (hash[date]) return {...hash[date], value: hash[date].plan_value, visible: true};
                        else return (
                            {date: formatISO(parse(date, 'MM/dd/yy', new Date())), plan_value: 0, value: 0}
                        )
                    });
                    setTileActivityData(_.reverse(result));
                })
                .catch(e => console.log(e))
        }
    }, [active, date]);

    useDidMountEffect(() => {
        fetchTargetedActivity();
    }, [targetedActivityData.page]);

    function handleDate(e, range) {
        const val = e.target.value;
        let parsedDate = parse(val, 'yyyy-MM-dd', new Date());
        if (range === 'start') {
            parsedDate = startOfDay(parsedDate);
            return setDate({...date, start: parsedDate});
        } else {
            parsedDate = endOfDay(parsedDate);
            return setDate({...date, end: parsedDate});
        }
    }

    async function fetchAverages() {
        try {
            const res = await fetchScoreAverages(singleUser.user.id);
            setScoreAverages(res);
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchStreakData() {
        try {
            const res = await fetchMemberStreak(singleUser.user.username);
            let lastComm;
            const {last_communication} = singleUser.user;
            if (last_communication) {
                lastComm = differenceInDays(new Date(), parseISO(last_communication));
            }
            setStreakData({currentStreak: res.currentStreak, longestStreak: res.longestStreak, lastComm});
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchScoreActivity() {
        try{
            const res = await fetchScoreMetricsBreakouts(singleUser.user.id, date.start, date.end);
            setScoreActivityData(res);
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchLevelActivity() {
        try{
            const res = await fetchMemberLevelActivity(singleUser.user.id, date.start, date.end);
            setLevelActivityData(res);
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchTargetedActivity() {
        if (targetedActivityData.loading) return;
        setTargetedActivityData(prev => ({...prev, loading: true}));
        try{
            const activity = await fetchMemberActivity(singleUser.user.id, date.start, date.end, targetedActivityData.page)
            setTargetedActivityData(prev =>
                ({
                    ...targetedActivityData,
                    pages: activity.pages,
                    data: targetedActivityData.page === 1 ? activity.scores : [...prev.data, ...activity.scores],
                    loading: false
                }));
        } catch (e) {
            console.log(e);
        }
    }

    function nextPage() {
        setTargetedActivityData(prev => ({...prev, page: prev.page +1}));
    }



    if (!singleUser ) return <div/>
    return(
        <div className='margin-top-lrg'>
            {singleUser.user.demo_member ?
                <div className="container">
                    <SecondaryWarningAlert>
                        <h3 className='m-t:0'>Demo Account</h3>
                        <p>
                            This account has been flagged as a demo account. These accounts are used for internal team testing or temporary stakeholder trials.
                        </p>
                        <ul>
                            <li>This member's data is not included in the member export data.</li>
                            <li>This member is not included in any daily, weekly, monthly, or yearly averages displayed within Treo or an organization.</li>
                        </ul>
                    </SecondaryWarningAlert>
                </div>
                :null
            }
            <div className='container'>
                <AverageScores data={scoreAverages}/>

                <BoxContainer className={styles.streakRow}>
                    <MemberStreak data={streakData}/>
                </BoxContainer>

                <form>
                    <h3 className={styles.memberActivity}>Member Activity</h3>
                    <label>Choose Date Range</label>
                    <div className={`form-group ${styles.dateRange}`}>
                        <input
                            max={format(subDays(date.end, 1), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.start, 'yyyy-MM-dd', {})}
                            onChange={e => handleDate(e, 'start')}
                            onKeyDown={e => e.preventDefault()}
                        />
                        <span className='p-r:1 p-l:1'>to</span>
                        <input
                            min={format(addDays(date.start, 1), 'yyyy-MM-dd')}
                            max={format(new Date(), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.end, 'yyyy-MM-dd')}
                            onChange={e => handleDate(e, 'end')}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>
                </form>
            </div>

            {/* Score Activity */}
            <div className="container">
                <BoxContainer className={styles.scoreActivityRow}>
                    <div className={styles.heading2}>
                        Score Activity
                    </div>
                    <ScoreActivityGraph data={scoreActivityData}/>
                </BoxContainer>
            </div>

            {/* Level Activity */}
            <div className="container">
                <div className={styles.levelActivityRow}>
                    <BoxContainer className={styles.levelActivity}>
                        <div className={styles.heading2}>
                            Level Activity
                        </div>
                        <LevelActivityGraph data={levelActivityData}/>
                    </BoxContainer>

                    <BoxContainer className={styles.targetedActivity}>
                        <div className={styles.heading2}>
                            Targeted Activity
                        </div>
                        <TargetedActivity nextPage={nextPage} data={targetedActivityData}/>
                    </BoxContainer>
                </div>
            </div>

            {/* Tile Activity */}
            <div className="container">
                <BoxContainer className={styles.tileActivityRow}>
                   <div className={styles.tabs}>{
                       tiles.map(tile => {
                          return tile.plan.map(plan => (
                              <button
                                  key={plan.id}
                                  onClick={() => setActive(plan.id)}
                                  className={`${styles.tab} ${active === plan.id ? styles.active : ''}`}>{plan.description}</button>
                          ))
                   })}</div>
                    <TileActivityGraph domain={date} data={tileActivityData}/>
                </BoxContainer>
            </div>
        </div>
    )
};

function mapStateToProps({ singleUser }) {
    return { singleUser }
}

const actions = {
    fetchSingleUser,
}
export default connect(mapStateToProps, actions)(ClientSummary);
