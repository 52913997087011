// npm modules
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Glyphicon } from 'react-bootstrap';
import axios from 'axios';

// actions
import { getOrganizationCoaches, resetOrganizationCoaches } from "../../../actions/action_organizations";

// styles
import styles from './organizationOverview.module.css'

// components
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import Loading from "../../Loading/Loading";
import { ErrorAlert, InfoAlert } from "../../alerts/alerts";
import { PrimaryButton } from "../../buttons/buttons";
import SeatsWidget from "../../seatsWidget/seatsWidget";
import CoachSearch from "../../coachSearch/coachSearch";
import Avatar from "../../../images/avatar-1577909_960_720.png";

const OrganizationOverview = ({ organization, getOrganizationCoaches, resetCoaches }) => {
    const [ loadingError, setLoadingError ] = useState(false);
    const [ coachModal, setCoachModal ] = useState(false);
    const [ generalError, setGeneralError ] = useState(null);

    function formatPhoneNumber(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }

    function addCoach(coachId) {
        return axios.post(`/organizations/assign/coach/${organization.id}`, { coach_id: coachId})
            .then(() => getOrganizationCoaches(organization.id))
            .then(() => setCoachModal(false))
            .then(() => setGeneralError(null))
            .catch( e => setGeneralError('Unable to add coach'));
    }

    function removeCoach(coachId) {
        return axios.put(`/organizations/remove/coach/${organization.id}`, { coach_id: coachId})
            .then(() => getOrganizationCoaches(organization.id))
            .then(() => setGeneralError(null))
            .catch( e => setGeneralError(e.response.data.error));
    }

    // grab coaches on mount and add to state
    // remove coach on dismount
    useEffect(() => {
        getOrganizationCoaches(organization.id)
            .catch(() => setLoadingError(true));
        return () => resetCoaches();
    }, []);

    // show error if unable to load coaches
    if (loadingError) {
        return (
            <ErrorAlert>
                Unable to load coaches. Please try again later.
            </ErrorAlert>
        )
    }

    // ensure data has loaded before showing anything
    if (!organization.coaches) {
        return <Loading/>;
    }

    return (
        <div>
            { generalError ?
                <ErrorAlert>{generalError}</ErrorAlert>
                : null
            }
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <h3>Coaches</h3>
                    <p>The coach at the top is assigned to all new member sign-ups. In the future, additional coaches will be used for team coaching but currently are not utilized.</p>
                    <BoxContainer className={styles.boxes}>
                        { !organization.coaches.length ?
                            <div>
                                <InfoAlert>
                                    Click below to assign coach to organization
                                </InfoAlert>
                            </div>
                            : null
                        }
                        { organization.coaches ?
                            organization.coaches.map(coach => <CoachTile key={coach.coach_id} coach={coach} remove={removeCoach}/>)
                            : null
                        }
                        <PrimaryButton onClick={()=>setCoachModal(true)}>+ Add New Coach</PrimaryButton>
                    </BoxContainer>
                </div>
                <div className="col-xs-12 col-md-6">
                    <h3>Seat Usage</h3>
                    <p>[ widget helpful description here ]</p>
                    <SeatsWidget links={true} containerClass={styles.boxes} organization={organization}/>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <h3>Treo Sales Rep</h3>
                    <p>[ widget helpful description here ]</p>
                    <BoxContainer className={styles.boxes}>
                        <p className='m:0'><strong>{organization.sales_rep_name}</strong></p>
                        <p>{organization.sales_rep_position}</p>
                        <p>
                            <a href={`mailto:${organization.sales_rep_email}`} className="d:b">{organization.sales_rep_email}</a>
                            <span className="d:b">{organization.sales_rep_phone ? formatPhoneNumber(organization.sales_rep_phone) : null}</span>
                        </p>
                    </BoxContainer>
                </div>
            </div>
            <Modal show={coachModal} onHide={() => setCoachModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Coach Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CoachSearch onSelection={addCoach}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setCoachModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const actions = {
    getOrganizationCoaches,
    resetCoaches: resetOrganizationCoaches,
};

export default connect(null, actions)(OrganizationOverview);

function CoachTile({ coach, remove }) {
    return (
        <div className={styles.coachTile}>
            <div className={styles.imageContainer}>
                <img className={styles.image} src={coach.profile_image || Avatar} alt={`${coach.first_name} profile`}/>
            </div>
            <div className={styles.infoContainer}>
                <h4 className={styles.name}>{coach.first_name} {coach.last_name}</h4>
            </div>
            <div className={styles.buttonContainer}>
                <span className={`${styles.remove} hover`} onClick={() => remove(coach.coach_id)}>
                    <Glyphicon glyph='glyphicon glyphicon-remove-circle'/> Remove
                </span>
            </div>
        </div>
    )
}