import React, { useState, useEffect } from "react";
import BackButton from "../BackButton";

// styles
import styles from "./DailyWotdVideo.module.css";

// components
import BrightcovePlayer from "../brightcovePlayer/brightcovePlayer";
import { ReactComponent as InstructorIcon } from "../../images/icons/Icons_Filter_FitnessPlayer_Instructor.svg";
import Loading from "../Loading/Loading";

// utilities
import { INTENSITYTYPE } from "../../serverVariables/intensities";
import { CONTENT_META_TYPES } from "../../serverVariables/contentMetaTypes";

//actions
import {
  fetcWotdGenericSelectedVideoSprint8,
  fetcWotdGenericSelectedVideoTrainerLed,
  fetcWotdGenericSelectedVideoVirtualActive,
} from "../../store/actions/wotdActions";

const DailyWotdVideo = (props) => {

  const categoryId = parseInt(props.match.params.category_id);
  const categoryName = props.match.params.categoryName;
  const category = props.match.params.category.toLowerCase();

  // local state
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [videoData, setVideoData] = useState({
    id: "",
    content: "",
    classType: "",
    instructor: "",
    intensity: "",
    title: "",
    episode: "",
    classLength: "",
    description: "",
  });

  async function fetchSelectedVideo(categoryId) {
    try {
      let response;
      if (categoryName === "trainer-led") {
        response = await fetcWotdGenericSelectedVideoTrainerLed(categoryId);
      } else if (categoryName === "virtual-active") {
        response = await fetcWotdGenericSelectedVideoVirtualActive(categoryId);
      } else {
        response = await fetcWotdGenericSelectedVideoSprint8(categoryId); // Make sure to await the API call
      }

      const classType = response.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES
      );
      const intensity = response.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id === CONTENT_META_TYPES.INTENSITY
      );

      const instructor = response.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id === CONTENT_META_TYPES.TRAINER
      );

      const classLength = response.meta.find(
        (item) =>
          item.meta_type.content_meta_type_id ===
          CONTENT_META_TYPES.CLASS_LENGTH
      );

      let level;

      if (
        intensity.selected_option.content_meta_option_label ===
        INTENSITYTYPE.LOW
      ) {
        level = 1;
      }
      if (
        intensity.selected_option.content_meta_option_label ===
        INTENSITYTYPE.MODERATE
      ) {
        level = 2;
      }

      if (
        intensity.selected_option.content_meta_option_label ===
        INTENSITYTYPE.HIGH
      ) {
        level = 3;
      }
      setVideoData({
        id: response.id,
        content: response.content,
        instructor: instructor.selected_option.content_meta_option_label,
        classType: classType.selected_option.content_meta_option_label,
        intensity: level,
        title: response.category[0].name,
        episode: response.episode,
        classLength: response.length,
        description: response.description || "",
      });
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchSelectedVideo(categoryId);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  // for back btn font size
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let fontSize = windowWidth > 510 ? "20px" : "14px";
  const iconSize = windowWidth > 510 ? "40px" : "28px";

  if (windowWidth < 376) {
    fontSize = 0;
  }

  const size = windowWidth > 450 ? "25" : "20";
// get the amount of dots for level of intensity
  const StarRating = ({ level }) => {
    return (
      <div>
        {[...Array(3)].map((_, index) => (
          <div
            className={`${styles.dot} ${
              index < level ? styles.filled : styles.empty
            }`}
            key={index}
          ></div>
        ))}
      </div>
    );
  };

  if (!videoData.id) {
    return (
      <div className={styles.loadingContainer}>
        {" "}
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.headerBackground}>
        <div className={styles.btnLocation}>
          <BackButton
            to={`/workoutoftheday/${category}`}
            color="black"
            fontSize={fontSize}
            IconSize={iconSize}
          />
        </div>
        <h1 className={styles.headerTitle}>Workout of the Day</h1>
      </div>

      <BrightcovePlayer
      colorRed={true}
        wotdView={true}
        id={videoData.id}
        organizationId={10}
        identifier={"wotd##123"}
        videoId={videoData.content}
      />

      <div className={styles.videoWorkoutContainer}>
        <div className={styles.subtitleContainer}>
          <span className={styles.videoTitle}> {videoData.classLength} Min. {videoData.classType}</span>

          {/* Icons */}
          <div className={styles.iconWrapper}>
            <span className={styles.iconContainer}>
              <InstructorIcon stroke={"#E02926"} width={size} />
              <span className={styles.iconText}> {videoData.instructor} </span>
            </span>
            <span className={styles.iconContainer}>
              <StarRating level={parseInt(videoData.intensity)} />
              <span className={styles.iconText}> Level</span>
            </span>
          </div>
        </div>
      </div>

      <div className={styles.alignContainerCenter}>
        <div className={styles.centerWidthWrapper}>
          <span>
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: videoData.description }}></p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DailyWotdVideo;
