import { tileApi } from "../../api/tileApi"

import {
    SET_CLIENT_TILE,
    SET_CLIENT_TILE_LIST,
} from "../storeConstants";

// function to set a specific tile into state
export function setClientTile({ id=null, tile={} }) {
    return async dispatch => {
        // if an id is provided, fetch tile from the server
        if (id) {
            const tileRes = await tileApi().getTile(id);
            dispatch({
                type: SET_CLIENT_TILE,
                payload: { tile: tileRes.data.tile, plan: tileRes.data.plan }
            });
        } else {
            dispatch({ type: SET_CLIENT_TILE, payload: tile });
        }
    }
}

// function to clear a specific tile from state
export function resetClientTile() {
    return dispatch => dispatch({ type: SET_CLIENT_TILE, payload: null });
}

// function to fetch and set a list of tiles for a client
export function fetchClientTiles() {
    return async dispatch => {
        const tileRes = await tileApi().getClientTiles()
            .catch(e => console.log(e) );
        if (tileRes.data.result) {
            dispatch({ type: SET_CLIENT_TILE_LIST, payload: tileRes.data.result });
        } else {
            dispatch({ type: SET_CLIENT_TILE_LIST, payload: [] });
        }
    }
}


//**************** Actions that don't involve the store *****************//

// function fetch and return a list of archived tiles for a client
export async function fetchArchivedClientTiles(page=1) {
    const tileRes = await tileApi().getArchivedTiles(page);
    return tileRes.data;
}

// function to fetch the logging activity for a given tile plan
export async function fetchPlanActivity(planId, date) {
    const activity = await tileApi().getPlanActivity(planId, date);
    const transform = activity.data.activity_calendar.map(item => ({ date: item.date.substr(0, 10), activity: item.activity }));
    return { activity_calendar: transform };
}

// function to change the status of given tile
export async function updateTileStatus(tileId, status, username){
    const {data} = await tileApi().setTileStatus(tileId, username, status);
    return data;
}

// get a list of tiles a member has for the admin
export async function fetchMemberTiles(username){
    const {data} = await tileApi().getMembersTiles(username);
    return data.result;
}

// get a list of metric points for a given plan for admins
export async function fetchPlanMetrics(planId, startDate, endDate){
    const {data} = await tileApi().getPlanMetrics(planId, startDate, endDate);
    return data.results;
}