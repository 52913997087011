import React, {useState, useEffect} from 'react';
import _ from 'lodash';

// styles
import styles from './notification.module.css';

// images
import {ReactComponent as LoginIcon} from '../../../images/icons/login_icon.svg';
import {ReactComponent as HabitIcon} from '../../../images/icons/habit_icon.svg';
import {ReactComponent as TileIcon} from '../../../images/icons/checkmark.svg';
import {ReactComponent as PathwayIcon} from '../../../images/icons/pathways_icon.svg';
import {ReactComponent as VideoIcon} from '../../../images/play.svg';
import {ReactComponent as AudioIcon} from '../../../images/headphones.svg';
import {ReactComponent as LinkIcon} from '../../../images/external-link-alt-solid.svg';
import {ReactComponent as TeammateIcon} from '../../../images/icons/teammates_icon.svg';
import {ReactComponent as CoachIcon} from '../../../images/icons/mail.svg';
import {ReactComponent as StreakIcon} from '../../../images/Notifcation_Streak.svg';
import {ReactComponent as GoalIcon} from '../../../images/icons/goal_icon.svg';


// components
import RankMedal from "../../rankMedal/rankMedal";
import {ALERT_TYPES} from "../../../serverVariables/alertTypes";

const Notification = props => {
    // props
    const {data} = props;
    // local
    const [state, setState] = useState({});
    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        if (!data || _.isEmpty(data)) return;
        setState(data);
        setAnimation(true);
        setTimeout(() => setAnimation(false), 2000);
    }, [data]);

    if (_.isEmpty(state)) return <div/>;


    if (state.alert_type.name === ALERT_TYPES.MONTHLY_GOAL) {
        return (
            <div className={styles.container} data-cy="notifyMonthlyGoal">
                <div className={`${styles.points} ${animation ? styles.animation : ''}`}>
                    <RankMedal style={{stroke: 'none'}} rank={state.level_achievement ? state.level_achievement.level.rank.id : 'bronze'}/>
                </div>
                <div className={styles.text}>
                    <p>You've achieved your Monthly Goal!</p>
                    <span>Congratulations on reaching your goal for the month of {new Date().toLocaleString('default', { month: 'long' })}!</span>
                </div>
            </div>
        )
    }

    if (state.alert_type.name === ALERT_TYPES.DAILY_GOAL) {
        return (
            <div className={styles.container} data-cy="notifyDailyGoal">
                <div className={`${styles.points} ${animation ? styles.animation : ''}`}>
                    <GoalIcon className={styles.padded}/>
                </div>
                <div className={styles.text}>
                    <p>You've achieved your Daily Goal!</p>
                    <span>Small steps each day will help your long term goals.</span>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.container} data-cy="notifyPoints">
            <div className={`${styles.points} ${animation ? styles.animation : ''}`}>
                {scoreIcon(state.wellness_score.scoring_type.name)}
            </div>
            <div className={styles.text}>
                <p>{state.wellness_score.score_value} points</p>
                <span>{state.wellness_score.scoring_type.description}</span>
            </div>
        </div>
    )
}

export default Notification;

function scoreIcon(val) {
    switch(val) {
        case 'login':
            return <LoginIcon/>;
        case 'habit_builder':
            return <HabitIcon className={styles.padded}/>;
        case 'green_tile':
        case 'yellow_tile':
        case 'red_tile':
            return <TileIcon className={styles.padded}/>;
        case 'pathway_content':
            return <PathwayIcon className={styles.padded}/>;
        case 'audio_content':
            return <AudioIcon className={styles.padded}/>;
        case 'video_content':
            return <VideoIcon className={styles.padded}/>;
        case 'link_pdf':
            return <LinkIcon className={styles.padded}/>;
        case 'pathway_module':
            return <PathwayIcon className={styles.padded}/>;
        case 'teammate':
            return <TeammateIcon className={styles.padded}/>;
        case 'contact_coach':
            return <CoachIcon className={styles.padded}/>;
        case '7_day_streak':
        case '28_day_streak':
            return <StreakIcon className={styles.padded}/>;
        default:
            return <TileIcon className={styles.padded}/>;
    }
}