import React from 'react';

// styles
import styles from './singleQuestion.module.css';

const SingleQuestion = props => {
    // props
    const {question, ordinal} = props;
    return (
        <div className={styles.answerContainer} data-cy="taken-single-question">
            <div className={styles.answerTop}>
                <div className={styles.answerOrdinal}>{ordinal+1}</div>
                <div>{question.label}</div>
            </div>
            <div className={styles.answerBottom}>{question.answers.filter(a => a.selected).map(a => <span>{a.label}</span>)}</div>
        </div>
    )
}
export default SingleQuestion;