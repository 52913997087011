//client detail view
import React from 'react';
import {connect} from 'react-redux';

import styles from './clientDetailMain.module.css';

// actions
import {fetchSelf, fetchSingleUser, clearSingleUserState } from "../../../actions/action_user";

// components
import HeaderAdmin from '../headerAdmin';
import HeaderCoach from '../HeaderCoach';
import SubHeader from './subHeader';
import ClientDisplay from '../ClientDisplay/ClientDisplay';
import LinkList from "../../links/linkList/linkList";

class ClientDetailMain extends React.Component {
    constructor(props){
        super(props);
        this.state={
            id:'',
        }
    }
    componentDidMount(){
        this.props.fetchSelf()
            .then(()=> this.setState({id:this.props.user.permission_id}));
        const username = this.props.match.params.username;
        this.props.fetchSingleUser(username)
            .catch(e => console.log(e));
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        this.props.clearSingleUserState();
    }

    userPermission(){
        if(this.props.user){
            if(this.props.user.permission_id === 3){
                return( <HeaderAdmin /> )
            }
            else{
                return( <HeaderCoach /> )
            }
        }
    }

    render() {
        const { match } = this.props;
        const links = [
            { href: `${match.url}/overview`, label: 'Client Summary' },
            { href: `${match.url}/reporting`, label: 'Reporting' },
            { href: `${match.url}/tiles`, label: 'Habit Tiles' },
            { href: `${match.url}/inbox`, label: 'Inbox' },
            { href: `${match.url}/notes`, label: 'Notes' },
            { href: `${match.url}/assessment`, label: 'Assessments' },
            { href: `${match.url}/pathways`, label: 'Pathways' },
            { href: `${match.url}/teammates`, label: 'Teammates' },
            { href: `${match.url}/profile`, label: 'Profile' },
            { href: `${match.url}/coaching-team`, label: 'Coaching Team' },
            { href: `${match.url}/community`, label: 'Community' },
        ];
        if (!this.state.id || !this.props.singleUser) return <div/>
        return(
            <div className="client-detail">
                {this.userPermission()}
                <SubHeader username={this.props.match.params.username} />
                <div className="container">
                    <div className={styles.mainContent}>
                        <div className={`margin-top-lrg ${styles.links}`}>
                            <LinkList links={links}/>
                        </div>
                        <div className={styles.content}>
                            <ClientDisplay match={this.props.match} username={this.props.match.params.username} userPermission={this.state.id}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({user, singleUser}){
    return{user, singleUser}
}

const actions = {
    fetchSelf,
    fetchSingleUser,
    clearSingleUserState,
};

export default connect(mapStateToProps, actions)(ClientDetailMain);