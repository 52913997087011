// npm modules
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

// components
import Loading from "../Loading/Loading";
import BodyComp from "../../images/icon-pathwayreport-primary_bodycomp.png";
import Activity from "../../images/icon-pathwayreport-primary_activity.png";
import BrainHealth from "../../images/icon-pathwayreport-primary_brainhealth.png";
import Endurance from "../../images/icon-pathwayreport-primary_endurance.png";
import Energy from "../../images/icon-pathwayreport-primary_energy.png";
import FoodChoices from "../../images/icon-pathwayreport-primary_foodchoices.png";
import Speed from "../../images/icon-pathwayreport-primary_speed.png";
import WeightLoss from "../../images/icon-pathwayreport-primary_weightloss.png";
import BetterBloodPressure from '../../images/icon-pathway-bbp.png';
import BetterBloodSugar from '../../images/icon-pathway-bbs.png';

const ClientPathwayHealthGoals = ({clientPathway, user}) => {
    function healthGoal(goal) {
        switch (goal.health_goal_name) {
            case "Lasting Weight Loss":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={WeightLoss} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to lose 10 or more pounds while maintaining muscle
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            Permanent weight reduction takes time -so let's build a plan you
                            can stick to for the rest of your life. You are four times more
                            likely to achieve your weight loss goals when combining exercise
                            with dietary changes. Recent studies have shown that eating
                            patterns may be as important as what you eat when trying to reduce
                            body weight. See your personalized Treo Pathways for next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Weight Loss</li>
                            <li className="col-xs-12 col-sm-6">Improved Metabolism</li>
                            <li className="col-xs-12 col-sm-6">Reduce Inflammation</li>
                            <li className="col-xs-12 col-sm-6">Reduce Joint Pain</li>
                            <li className="col-xs-12 col-sm-6">Increase Mobility</li>
                            <li className="col-xs-12 col-sm-6">Reduce Health Risks</li>
                        </ul>
                    </div>
                );
            case "Build a Stronger Leaner Body":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={BodyComp} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to improve body composition and muscle definition
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            Losing body fat while building muscle requires consistent
                            dedication-so let's converge on a strategy that is sustainable.
                            Improving your diet quality will be a requisite adjustment in
                            order to reduce your percentage of body fat while staying active
                            enough to retain lean body mass. Several studies over the past ten
                            years point to important lifestyle priorities, such as getting
                            adequate sleep each night, and optimizing your nutrition by
                            minimizing empty calories. See your personalized Treo Pathways for
                            next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">
                                Decreased percentage body fat
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Increased strength-to-weight ratio
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Improved Growth Hormone Release
                            </li>
                            <li className="col-xs-12 col-sm-6">Enhanced muscle definition</li>
                        </ul>
                    </div>
                );
            case "Improve Heart and Cognitive Health":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={BrainHealth} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to improve heart & cognitive health with lifestyle
                            changes
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            The same habits that support your cardiovascular system lead to
                            better brain health as well, because your blood vessels distribute
                            blood pumped from the heart to all organs, including the brain.
                            Improving your blood vessel health requires a diet that is low in
                            added sugars and high in nutrient density, and involves physical
                            activity that challenges you. These steps contribute to higher
                            fitness levels and a lower risk of heart disease and dementia. See
                            your personalized Treo Pathways for next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Improved heart health</li>
                            <li className="col-xs-12 col-sm-6">Improved cognitive health</li>
                            <li className="col-xs-12 col-sm-6">Increased energy</li>
                            <li className="col-xs-12 col-sm-6">
                                Better immune system function
                            </li>
                            <li className="col-xs-12 col-sm-6">Increased endurance</li>
                            <li className="col-xs-12 col-sm-6">Reduced health risks</li>
                        </ul>
                    </div>
                );
            case "More Energy, Less Stress":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={Energy} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want more energy and less stress each day
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            Your exercise, nutrition, and sleep habits all have a significant
                            impact on your daily energy levels. Engaging in regular exercise
                            combined with practicing stress releasing activities leads to
                            feelings of more energy. Getting ample amounts of sleep and
                            consuming a diet rich in fruits and vegetables will result in less
                            fatigue and help reduce sugar cravings. See your personalized Treo
                            Pathways for next steps
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Increased Energy Levels</li>
                            <li className="col-xs-12 col-sm-6">Improved Eating Decisions</li>
                            <li className="col-xs-12 col-sm-6">
                                Improved Hormone Regulation
                            </li>
                            <li className="col-xs-12 col-sm-6">Improved Sleep Quality</li>
                        </ul>
                    </div>
                );
            case "Make Physical Activity a Regular Habit":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={Activity} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to make regular activity part of your lifestyle
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            When beginning the habit of exercise, it’s important to focus on
                            consistency instead of intensity. As you become more consistent,
                            gradual adjustments will help you reach higher durations and
                            intensities while minimizing your risk of injury. Discover and
                            participate in activities that you enjoy for the greatest
                            long-term success. See your personalized Treo Pathways for next
                            steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Increased Daily Activity</li>
                            <li className="col-xs-12 col-sm-6">Increased Mobility</li>
                            <li className="col-xs-12 col-sm-6">Improved Sleep Quality</li>
                            <li className="col-xs-12 col-sm-6">Enhanced Metabolism</li>
                        </ul>
                    </div>
                );
            case "Healthier Food Choices":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={FoodChoices} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to make healthier food choices while reducing sugar and
                            processed foods
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            Whole-food diets help us manage cravings and appetite more
                            effectively than ultra-processed foods. Consuming whole foods that
                            are high in healthy fats and fiber in particular are proven to
                            reduce hunger. Including one serving of protein in every meal will
                            help repair our cell tissues including muscles, bones, nails, and
                            skin. A colorful plate is a good indication that you’re consuming
                            a variety of nutrients. See your personalized Treo Pathways for
                            next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Improved Energy Balance</li>
                            <li className="col-xs-12 col-sm-6">Improved Gut Health</li>
                            <li className="col-xs-12 col-sm-6">Regulated Blood Sugar</li>
                            <li className="col-xs-12 col-sm-6">Improved Cellular Function</li>
                        </ul>
                    </div>
                );
            case "Improve Endurance":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={Endurance} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to increase your fitness and endurance
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            A gradual progression as part of a balanced training routine will
                            enhance your fitness and endurance. Consuming a balanced diet will
                            improve your physical performance and shorten your recovery time.
                            Getting adequate sleep helps your body efficiently use your food
                            for fuel to further enhance your performance and ability to focus.
                            See your personalized Treo Pathways for next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Improved Endurance</li>
                            <li className="col-xs-12 col-sm-6">Enhanced Fitness</li>
                            <li className="col-xs-12 col-sm-6">
                                Maintenance of Joint Strength
                            </li>
                            <li className="col-xs-12 col-sm-6">Shortened Recovery Times</li>
                        </ul>
                    </div>
                );
            case "Improve Speed and Strength":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={Speed} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to improve your speed and strength
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            Reaching Sprint Intensities is the best strategy to improving your
                            overall fitness. These engaging workouts are time-efficient and
                            stimulate the release of Growth Hormone to help you burn fat and
                            build muscle. They also help you build fast twitch muscle fibers
                            useful for improving speed and acceleration. Optimize physical
                            performance by practicing healthy eating patterns, a whole foods
                            diet, and getting adequate sleep. See your personalized Treo
                            Pathways for next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Increased Speed</li>
                            <li className="col-xs-12 col-sm-6">Increased Metabolism</li>
                            <li className="col-xs-12 col-sm-6">
                                Development of Fast Twitch Muscle
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Optimized Hormonal Regulation
                            </li>
                        </ul>
                    </div>
                );
            case "Better Blood Pressure":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={BetterBloodPressure} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to achieve better blood pressure
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            Rising blood pressure is common with age, but it can be slowed or reversed by adopting
                            healthy habits. These lifestyle improvements include more daily movement and a diet that is
                            low in added sugars and high in vitamins, minerals and fiber. Quality sleep is also crucial
                            for better blood pressure. Together, these behaviors can enhance fitness, wellbeing and
                            quality of life! See your personalized Treo Pathways for next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Improved blood pressure</li>
                            <li className="col-xs-12 col-sm-6">Improved fitness level</li>
                            <li className="col-xs-12 col-sm-6">
                                Increased energy
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Better immune function
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Increased endurance
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Reduced health risks
                            </li>
                        </ul>
                    </div>
                );
            case "Better Blood Sugar":
                return (
                    <div className="health-goal-container">
                        <div className="goal-icon">
                            <img src={BetterBloodSugar} alt="icon"/>
                        </div>
                        <h3 className="no-margin-top">
                            You want to achieve better blood sugar
                        </h3>
                        <h4>Strategy</h4>
                        <p>
                            The development and progression of prediabetes and diabetes can be slowed or reversed by
                            adopting healthy habits for better blood sugar control. These habits include more daily
                            movement and a diet that is low in added sugars and high in nutrients. Quality sleep also
                            helps blood sugar control. These behaviors can enhance fitness, wellbeing and quality of
                            life! See your personalized Treo Pathways for next steps.
                        </p>

                        <h4>Key benefits</h4>
                        <ul className="clearfix">
                            <li className="col-xs-12 col-sm-6">Improved blood sugar control</li>
                            <li className="col-xs-12 col-sm-6">Improved fitness level</li>
                            <li className="col-xs-12 col-sm-6">
                                Increased energy
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Better immune function
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Increased endurance
                            </li>
                            <li className="col-xs-12 col-sm-6">
                                Reduced health risks
                            </li>
                        </ul>
                    </div>
                );
        }
    }

    if (!clientPathway || _.isEmpty(clientPathway)) {
        return <Loading/>;
    }

    // no health goals were created with this assessment
    if (clientPathway.health_goals.length === 0) {
        return <div/>;
    }

    const plural = user.first_name && user.first_name[user.first_name.length - 1] === 's';
    return (
        <div className="container">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                <h1>{plural ? `${user.first_name}'` : `${user.first_name}'s`} Health Goals</h1>
                {healthGoal(clientPathway.health_goals[0])}
            </div>
        </div>
    );
};

function mapStateToProps({user}) {
    return {user};
}

export default connect(mapStateToProps)(ClientPathwayHealthGoals);

ClientPathwayHealthGoals.propTypes = {
    clientPathway: PropTypes.shape({
        health_goals: PropTypes.array
    }),
};
