import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";

 class AdminSummary extends Component{
    constructor(props){
        super(props);
           this.state = {
            coachList: [],
       };
    }

    componentDidMount() {
        axios.get(`/user/admin/single/${this.props.location.state.username}`)
          .then((res => {
            console.log(res)
            this.setState({coachList:res.data.results})
          }))
            .catch((error)=>{
               console.log(error);
            });   
    }

    createList=()=>{
        if(this.state.coachList.qualifications){
            return this.state.coachList.qualifications.map((item)=>{
                   return(                       
                    <li>item</li>
                   )
            });
        }
    };

    render(){
        // console.log(this.props.username)
      return(
        <div className="container margin-top">
          <div className="row">               
          </div>
          <div className="row">
            <div className="col-sm-6">
              <p>ABOUT {this.state.coachList.first_name}</p>
              <p>{this.state.coachList.biography}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <p>QUALIFICATIONS</p>
              <ul>{this.createList()}</ul>
            </div>
          </div>
        </div>       
      );
    }
}

export default withRouter(AdminSummary);
