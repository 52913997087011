import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ToggleButton from "react-toggle-button";
import moment from 'moment';

// actions
import { getSingleOrganization } from "../../../actions/action_organizations";

// styles
import styles from './organizationBrand.module.css';

// components
import OrganizationBranding from "../../organizationBranding/organizationBranding";
import { BoxContainer } from "../../containers/boxContainer/boxContainer";
import { PrimaryButton } from "../../buttons/buttons";
import {InfoAlert, SaveAlert} from "../../alerts/alerts";

const OrganizationBrand = ({ organization, getOrg }) => {
    const [ image, setImage ] = useState(organization.brand_logo || null); // custom logo file for branding
    const [ formState, updateState ] = useState({
        branding: organization.branding_switch || false,
        headerColor: organization.brand_bg_color || '',
        textColor: organization.brand_text_color || '',
    });
    const [ changes, setChanges ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ finishedSaving, setFinishedSaving ] = useState(false);

    function setForm(value){
        setChanges(true);
        setSaving(false);
        setFinishedSaving(false);
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    function setBranding(state) {
        setForm({
            ...formState,
            headerColor: state.headerColor,
            textColor: state.textColor,
        });
        setImage(state.image);
    }

    function submit(e) {
        e.preventDefault();
        setSaving(true);
        const { branding, headerColor, textColor } = formState;

        const data = new FormData();
        // general org stuff not related to branding
        data.append('name', organization.name);
        data.append('sap', organization.sap);
        data.append('sales_channel', organization.sales_channel);
        data.append('city', organization.city);
        data.append('state', organization.state);
        data.append('zip', organization.zip);
        data.append('contact_name', organization.contact_name);
        data.append('contact_position', organization.contact_position);
        data.append('contact_phone', organization.contact_phone);
        data.append('contact_email', organization.contact_email);
        data.append('sales_rep_name', organization.sales_rep_name);
        data.append('sales_rep_position', organization.sales_rep_position);
        data.append('sales_rep_phone', organization.sales_rep_phone);
        data.append('sales_rep_email', organization.sales_rep_email);
        data.append('start_date', moment(organization.start_date).format('MM-DD-YYYY'));
        data.append('end_date', organization.end_date);
        // end

        data.append('branding_switch', branding || false);
        data.append('brand_bg_color', headerColor);
        data.append('brand_text_color', textColor);
        if (image && image.name) {
            data.append('brand_logo', 'image');
            data.append('logo', image);
        }
        // set image to be removed
        if (organization.brand_logo && !image) {
            // image was originally set, but has since been removed
            data.append('brand_logo', 'remove');
        }

        axios.post(`/organizations/update/${organization.id}`, data)
            .then(() => {
                setFinishedSaving(true);
                setChanges(false);
                getOrg(organization.id)
            })
            .catch(e => {
                setFinishedSaving(false);
                setSaving(false);
            })
    }
    return (
        <div>
            <div className='row margin-top-2x margin-bottom-2x'>
                <div className='col-xs-12 col-sm-6 m:0'>
                    <h2>Branding</h2>
                    { changes ?
                        <InfoAlert>You have unsaved changes</InfoAlert>
                        : null
                    }
                </div>

                <div className='col-xs-12 col-sm-6'>
                    <div className={styles.save}>
                        { saving ? <SaveAlert complete={finishedSaving}/> : null }
                        <PrimaryButton className={styles.saveButton} onClick={submit} type='submit'>
                            Save Changes
                        </PrimaryButton>
                    </div>
                </div>
            </div>
            <BoxContainer className={styles.boxes}>
                <form onSubmit={submit}>
                    <div className='margin-bottom-2x'>
                        <label>Activate Branding on Client Side</label>
                        <ToggleButton
                            value={ formState.branding }
                            onToggle={val => {
                                setChanges(true);
                                setForm({ branding: !val})
                            }}/>
                    </div>
                    <OrganizationBranding state={{image, ...formState}} setState={setBranding}/>
                </form>
            </BoxContainer>
        </div>
    )
}

const actions = {
    getOrg: getSingleOrganization
}

export default connect(null, actions)(OrganizationBrand);