import axios from 'axios';

//get all the data on a single tile plus it's plans
export const FETCH_TILE = 'FETCH_TILE';
export function fetchTile(tileId){
    let url = `/tiles/single/${tileId}`;
    let tileRequest = axios.get(url);
    return ({
        type: FETCH_TILE,
        payload:tileRequest
    });
}

export const CLEAR_TILE = 'CLEAR_TILE';
export function clearTile(){
    return{
        type:CLEAR_TILE,
        payload:{tile:null}
    }
}

export const CLEAR_PLANS = 'CLEAR_PLANS';
export function clearPlans() {
    return{
        type:CLEAR_PLANS,
        payload:null
    }
}


//set any singular plan which needs focus
export const FETCH_PLAN = 'FETCH_PLAN';
export function fetchSinglePlan(planId){
    let url = `/tiles/single/plan/${planId}`;
    let planRequest = axios.get(url);
    return{
        type:FETCH_PLAN,
        payload:planRequest
    }
}

export const CLEAR_PLAN = 'CLEAR_PLAN';
export function clearPlan(){
    return{
        type:CLEAR_PLAN,
        payload:null
    }
}

export const GET_INTRO_TILES = 'GET_INTRO_TILES';
export function getIntroTiles(page){
    if(!page) page = 1;
    let url=`/tiles/introduction/page/${page}`;
    let request = axios.get(url);
    return{
        type:GET_INTRO_TILES,
        payload:request
    }
}

//get a singular intro tile from the b/e --note connected to the below action, ie they set the same reducer
export const GET_SINGLE_INTRO_TILE = 'GET_SINGLE_INTRO_TILE';
export function getIntroTile(id){
    let url=`/tiles/single/${id}`;
    let request = axios.get(url);
    return{
        type:GET_SINGLE_INTRO_TILE,
        payload:request
    }
}

//set a singular intro tile --note if this runs the above action wont
export const SET_SINGLE_INTRO_TILE = 'SET_SINGLE_INTRO_TILE';
export function setIntroTile(tile){
    return{
        type:SET_SINGLE_INTRO_TILE,
        payload:tile
    }
}