import React, {useState, useEffect, useRef} from 'react';

// styles
import styles from './filter.module.css';

// components
import {BoxContainer} from "../../../containers/boxContainer/boxContainer";
import {ReactComponent as ArrowIcon} from "../../../../images/back_arrow.svg";
import SearchableDropdown from "../../../dropdowns/searchableDropdown/searchableDropdown";
import {ReactComponent as TimesIcon} from "../../../../images/times-circle.svg";

const Filter = props => {
    // props
    const {organizations, filterRef, callback, close} = props;
    // local
    const [sort, setSort] = useState('created-desc');
    const [organization, setOrganization] = useState('');
    const container = useRef();


    useEffect(() => {
        document.addEventListener('click', clickedInside);
        return () => document.removeEventListener('click', clickedInside);
    }, []);

    useEffect(() => {
        if (callback) callback({sort, organization});
    }, [sort, organization]);

    function clickedInside(event) {
        const isClickInside = container.current.contains(event.target);
        const isClickingBtn = filterRef.current.contains(event.target);
        if (!isClickInside && !isClickingBtn) {
            close();
        }
    }
     return (
         <BoxContainer className={styles.container}>
             <div ref={container}>
                 <div className="d:f j-c:s-b a-i:c">
                     <label>Sort By</label>
                     <TimesIcon className={styles.times} onClick={close}/>
                 </div>
                 <div className='margin-bottom-15'>
                     <button onClick={() => setSort('alpha-asc')} className={`${styles.btn} ${sort === 'alpha-asc' ? styles.selected : ''}`}>A-Z</button>
                     <button onClick={() => setSort('alpha-desc')} className={`${styles.btn} ${sort === 'alpha-desc' ? styles.selected : ''}`}>Z-A</button>
                     <button onClick={() => setSort('created-desc')} className={`${styles.btn} ${sort === 'created-desc' ? styles.selected : ''}`}>Created <ArrowIcon className={styles.arrowDown}/></button>
                     <button onClick={() => setSort('created-asc')} className={`${styles.btn} ${sort === 'created-asc' ? styles.selected : ''}`}>Created <ArrowIcon className={styles.arrowUp}/></button>
                 </div>
                 <label>Filter By</label>
                 <p>Also Assigned To</p>
                 <SearchableDropdown
                     options={organizations}
                     value={organization}
                     placeholder={'Select Organization'}
                     onChange={val => setOrganization(val)}
                 />
             </div>
         </BoxContainer>
     )
}
export default Filter;