import React from 'react';

// styles
import styles from './progressBar.module.css';

export const ProgressBar = ({ color, partial, total, className, ...props }) => {
    const width = total === 0 || partial === 0 ? 0 : `${partial/total*100}%`;
    const styleObj = {};
    if (partial === total && total > 0) styleObj.borderRadius = '5px';
    return (
        <div {...props} className={`${className} ${styles.progressBar}`}>
            <div className={styles.fill} style={{ ...styleObj, backgroundColor: color, width }}/>
        </div>
    )
}