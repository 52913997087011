import mainApi from "./mainApi";

export function contentApi() {
    return {
        // get a list of all content categories related to pathways
        getAllCategories: function() {
            return mainApi.get('/pathways/content/getCategories');
        },
        // get a single piece of content
        getContent: function(id) {
            return mainApi.get(`/content/item/${id}`);
        },
        // get all content types of categories
        getContentTypes: function() {
            return mainApi.get('/content/types');
        },
        // get all the available statuses for content
        getStatuses: function() {
            return mainApi.get('/content/status');
        },
        // get all the available statuses for content
        getFormats: function() {
            return mainApi.get('/content/status');
        },
        // get the categories belonging to a content type
        getCategories: function(contentType, query) {
            let url = `/content/get/categories/${contentType}?`;
            if (query.search) url += `search=${query.search}&`;
            if (query.organization_id) url += `organization_id=${query.organization_id}&`;
            return mainApi.get(url);
        },
        // get categories of a content type is hierarchical form
        getHierarchicalCategories: function(contentType) {
            let url = `/content/categorical-hierarchy/`
            if (contentType) url += contentType;
            return mainApi.get(url);
        },
        // get the subcategories belonging to a content type
        getSubcategories: function(category) {
            return mainApi.get(`/content/category/children/${category}`);
        },
        // get all the content in a given category
        getCategoryContent: function(category, filters) {
            return mainApi.post(`/content/by/category/${category}`, filters);
        },
        // save the sorted order of a category list
        saveCategoryOrder: function(contentType, categories) {
            return mainApi.post(`/content/sort/categories/${contentType}`, categories);
        },
        // save the sorted order of a category list
        saveContentOrder: function(contentType, content) {
            return mainApi.post(`/content/sort/content/${contentType}`, content);
        },
        // save category metadata
        saveCategory: function(category, data) {
            return mainApi.put(`/content/category/${category}`, data);
        },
        // save category metadata
        createCategory: function(category, data) {
            return mainApi.post(`/content/category/${category}`, data);
        },
        // delete a category
        deleteCategory: function(category) {
            return mainApi.delete(`/content/category/${category}`);
        },
        // get all featured content types subcategories
        getFeaturedContent: function () {
            return mainApi.get('/content/featured/top');
        },
        // get a single category
        getCategory: function(category) {
            return mainApi.get(`/content/single/${category}`);
        },
        // get a single piece of FEATURED content
        getFeaturedSingle: function(contentId) {
            return mainApi.get(`/content/featured/single/${contentId}`);
        },
        // get all tags for a given content type
        getTags: function(contentType) {
            return mainApi.get(`/content/tags/${contentType}`);
        },
        // create a new tag
        createTag: function(tag, contentType) {
            return mainApi.post(`/content/tag/${tag}/${contentType}`)
        },
        // update an existing tag
        updateTag: function(tagId, tag, contentType) {
            return mainApi.put(`/content/tag/${tagId}/${tag}/${contentType}`);
        },
        // remove an existing tag
        deleteTag: function(tagId) {
            return mainApi.delete(`/content/tag/${tagId}`)
        },
        // get all content for a given tag, regardless of category
        getContentByTag: function(tagId) {
            return mainApi.get(`/content/by/tag/${tagId}`);
        },
        // get all tags in a category with content
        getCategoryTags: function(category) {
            return mainApi.get(`/content/tags/by/category/${category}`);
        },
        // get viewing stats for content
        getContentStats: function(userId, contentTypeId) {
            return mainApi.get(`/content/user/stats/${userId}/${contentTypeId}`)
        },
        // get all organizations a category is assigned to
        getAssignedOrgs: function(categoryId) {
            return mainApi.get(`/content/assigned/orgs/${categoryId}`);
        },
        // search content
        searchContent: function(page, params={}) {
            let url = `/content/list/${page}?`;
            if (params.title) url += `title=${params.title}&`;
            if (params.type) url += `type=${params.type}&`;
            if (params.status) url += `status=${params.status}&`;
            if (params.category) url += `category=${params.category}&`;
            if (params.format) url += `format=${params.format}&`;
            if (params.instructor) url += `instructor=${params.instructor}&`;
            if (params.classType) url += `class_type=${params.classType}&`;
            if (params.tags) url += `tag=${params.tags}&`;
            if (params.sort) url += `sort=${params.sort}&`;
            return mainApi.get(url);
        },
        // record a content view chunk
        recordContentViewChunk: function(contentId,start, duration, session, total) {
            return mainApi.post('/facts/record/content/', {
                content_id: contentId,
                start_point: start,
                duration_viewed: duration,
                view_session_id: session,
                total_duration: total
            });
        },
        // fist wotd records data for watched first wotd 
        WotdVideoChunk:  function(contentId,start, duration, session, total, organizationId) {
            return mainApi.post('/wotd/record', {
                content_id: contentId,
                start_point: start,
                duration_viewed: duration,
                view_session_id: session,
                total_duration: total,
                organization_id: organizationId
            });
        },
        // new daily wotd records data for new wotd videos
        recordDailyWotdVideoChunk: function(contentId,start, duration, session, total){
            return mainApi.post('/wotd/record/v2',{
                content_id: contentId,
                start_point: start,
                duration_viewed: duration,
                view_session_id: session,
                total_duration: total,
            });
        },
        // get a list of meta content
        getMetaContentType: function(typeId) {
            return mainApi.get(`/content/get/meta/options/${typeId}`);
        },
        // create a new content meta object
        newMetaContentType: function(typeId, label, text) {
            let url = `/content/add/meta/option/${typeId}/${label}`;
            if (text) url += `?text=${text}`;
            return mainApi.post(url);
        },
        // update a content meta object
        updateMetaContentType: function(label, id, text) {
            let url = `/content/edit/meta/option/${id}/${label}`;
            if (text) url += `?text=${text}`;
            return mainApi.put(url);
        },
        // delete a content meta object
        deleteMetaContentType: function(id) {
            return mainApi.delete(`/content/delete/meta/option/${id}`);
        },
        // set a piece of content to be scheduled for the Class of the Week
        scheduleContent: function(id, start, end) {
            return mainApi.post(`/content/fitness/add/cotw/${id}?start_date=${start}&end_date=${end}`);
        },
        // get the content that's been scheduled for Class of the Week
        getScheduled: function(start, end) {
            return mainApi.get(`/content/fitness/cotw/scheduled/list?start_time=${start}&end_time=${end}`);
        },
        // update a scheduled Class of the Week event
        updateScheduled: function(eventId, contentId, start, end) {
            return mainApi.put(`/content/fitness/edit/cotw/${eventId}?content_id=${contentId}&start_date=${start}&end_date=${end}`);
        },
        // delete a scheduled Class of the Week event
        deleteScheduled: function(eventId) {
            return mainApi.delete(`/content/fitness/delete/cotw/${eventId}`);
        },
        // get a scheduled class of the week for clients
        getClassOfTheWeek: function(categoryId) {
            return mainApi.get(`/content/fitness/cotw/${categoryId}`);
        },
        // get available filters for a given on demand category
        getOnDemandFilters: function(categoryId) {
            return mainApi.get(`/content/fitness/filters/${categoryId}`);
        },
        getFitnessContent: function(categoryId, filters) {
            const params = {};
            if (filters.length && filters.length.value) params.length = filters.length.value;
            if (filters.classType && filters.classType.value) params.type = filters.classType.value;
            if (filters.level && filters.level.value) params.intensity = filters.level.value;
            if (filters.instructor && filters.instructor.value) params.trainer = filters.instructor.value;
            if (filters.equipment && filters.equipment.value) params.meta_equipment = filters.equipment.value;
            if (filters.favorite && filters.favorite.value) params.favorite = filters.favorite.value;
            if (filters.page) params.page = filters.page;
            return mainApi.post(`/content/fitness/by/category/${categoryId}`, params)
        },
        favoriteContent: function(contentId, collection) {
            if (collection) return mainApi.put(`/content/category/favorite/${contentId}`)
            return mainApi.put(`/content/favorite/${contentId}`);
        },
        // get the history of a users content views
        getContentHistory: function(userId, sort) {
            return mainApi.get(`/content/user/stats/${userId}?sort=${sort}`);
        },
        getContentCollections: function(categoryId, filters) {
            return mainApi.post(`/content/collections/${categoryId}`, filters)
        },
        // get the list of instructors available to a given featured/org category
        getInstructors: function(categoryId) {
            return mainApi.get(`/content/filter/get/trainers/${categoryId}`);
        },
        // get the list of categories available for drop down
        getClassCategoriesDropdown: function() {
            return mainApi.get(`/wotd/categories`);
        },

        // submit content for class of day to schedule calander
        postClassofTheDayToCalander: function(contentId, start, end){
            return mainApi.post(`/wotd/add/${contentId}?start_time=${start}&end_time=${end}`)
        },

        // get list of classes in class of the day
        getClassList: function(start, end) {
            return mainApi.get(`/wotd/scheduled/list?start_time=${start}&end_time=${end}`)
        },
        // delete classes in class of the day
        deleteClassoftheDay: function(eventId){
            return mainApi.delete(`/wotd/delete/${eventId}`)
        },
        // edit classes in class of the day
        editClassoftheDay: function(eventId, contentId, start, end) {
            return mainApi.put( `/wotd/edit/${eventId}?content_id=${contentId}&start_time=${start}&end_time=${end}`)
        }
    }
}