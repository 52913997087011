import React, {useState, useEffect} from 'react';
import startOfToday from 'date-fns/startOfToday';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import styles from './dataSyncAnalytics.module.css';

//components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

//actions
import { fetchHealthConnectSyncedCount } from '../../../store/actions/analyticsActions';

const DataSyncAnalytics = props => {
    //props
    const {filters} = props;
    //local
    const [date, setDate] = useState({
        start: subDays(startOfToday(), 30),
        end: new Date()
    });

    const [healthConnectSyncedCount, setHealthConnectSyncedCount] = useState(null);

    const [query, setQuery] = useState({
        start: date.start,
        end: date.end,
        organizationId: filters.organization,
        statusId: filters.status,
        gender: filters.gender,
        ageGroup: filters.ageGroup,
        coach: filters.coach,
    });

    // on first render
    useEffect(() => {
        runQuery();
    }, [query]);

    useDidMountEffect(() => {
        const newQuery = {
            start: date.start,
            end: date.end,
            organizationId: filters.organization,
            statusId: filters.status,
            gender: filters.gender,
            ageGroup: filters.ageGroup,
            coach: filters.coach,
        };
        setQuery(newQuery);
    }, [date, filters]);

    function runQuery() {
        getHealthConnectSyncedCount();
    }

    // get the data for the tile that displays how many users are synced with health connect
    async function getHealthConnectSyncedCount() {
        try {
            const syncedCount = await fetchHealthConnectSyncedCount(query);
            setHealthConnectSyncedCount(syncedCount);
        } catch(e) {
            console.log(e);
        }
    }

    function handleDate(e, range) {
        const val = e.target.value;
        let parsedDate = parse(val, 'yyyy-MM-dd', new Date());
        if (range === 'start') {
            parsedDate = startOfDay(parsedDate);
            return setDate({...date, start: parsedDate});
        } else {
            parsedDate = endOfDay(parsedDate);
            return setDate({...date, end: parsedDate});
        }
    }

    return (
        <div>
            <h3 className={styles.heading}>Data Syncing</h3>
            <form className={styles.filter}>
                <div>
                    <label>Choose Date Range</label>
                    <div className={`form-group ${styles.dateRange}`} data-cy="date-range-user">
                        <input
                            max={format(subDays(date.end, 1), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.start, 'yyyy-MM-dd', {})}
                            onChange={e => handleDate(e, 'start')}
                            onKeyDown={e => e.preventDefault()}
                        />
                        <span className='p-r:1 p-l:1'>to</span>
                        <input
                            min={format(addDays(date.start, 1), 'yyyy-MM-dd')}
                            max={format(new Date(), 'yyyy-MM-dd')}
                            type="date"
                            value={format(date.end, 'yyyy-MM-dd')}
                            onChange={e => handleDate(e, 'end')}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>
                </div>
            </form>
            <div className={styles.healthConnectSyncedRow}>
                <BoxContainer className={`${styles.boxesOuter} ${styles.healthConnectSynced}`} data-cy="">
                    <div className={styles.mainLabel}>{healthConnectSyncedCount}</div>
                    <div className={styles.subLabel}>Users Synced with Health Connect</div>
                </BoxContainer>
            </div>
        </div>
        
    )
}

export default DataSyncAnalytics;