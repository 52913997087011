import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Modal} from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import validate from 'validate.js';
import {isEmpty} from 'lodash';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import isSameDay from 'date-fns/isSameDay';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { TreeSelect } from 'antd';



// styles
import styles from './contentCreatorForm.module.css';

// actions
import {clearActiveContent, setActiveContent} from "../../../actions/action_content";
import {
    fetchClassLengths,
    fetchClassTypes,
    fetchEquipment,
    fetchInstructors,
    fetchIntensities,
    fetchTags,
    newTag
} from "../../../store/actions/contentActions";

// Components
import {ErrorAlert, InfoAlert, SaveAlert, WarningAlert} from "../../alerts/alerts";
import {parse as parseQuery} from "query-string";
import {ImageThumbnailDetailed} from "../../imageThumbnail/imageThumbnailDetailed";
import {DangerButton, PrimaryButton} from "../../buttons/buttons";
import WYSIWYGTinyMCE from './wysiwygTinyMCE';
import FileUpload from "./fileUpload";
import {CONTENT_TYPES} from "../../../serverVariables/contentTypes";
import {CONTENT_FORMATS} from "../../../serverVariables/contentFormats";
import {CONTENT_STATUSES} from "../../../serverVariables/contentStatuses";
import {CONTENT_META_TYPES} from "../../../serverVariables/contentMetaTypes";

const constraints = {
    title: {
        presence: true,
        type: 'string',
        length: {
            minimum: 1,
            maximum: 255,
            tooShort: '^Title is required',
            tooLong: '^Title must be less than 255 characters',
        }
    },
    description: {
        presence: false,
        type: 'string',
        length: {
            minimum: 0,
            maximum: 25000,
            tooShort: '^Description is required',
            tooLong: '^Description must be less than 25000 characters',
        }
    },
    thumbnail: {
        presence: false,
    },
    category: {
        presence: true,
        type: {
            type: 'number',
            message: '^Category is required'
        },
    },
};
const optional = {
    blog: {
        presence: true,
        type: 'string',
        length: {
            minimum: 1,
            maximum: 7500,
            tooShort: '^Content is required',
            tooLong: '^Content must be less than 7500 characters',
        }
    },
    pageTitle: {
        presence: false,
        type: 'string',
        length: {
            minimum: 0,
            maximum: 255,
            tooLong: '^Title must be less than 255 characters',
        }
    },
    hero: {
        presence: { message: '^Hero Image is required' },
    },
    videoId: {
        presence: { message: '^Video Id is required' },
        type: {
            type: val => typeof parseInt(val) === 'number' && val !== "",
            message: '^Invalid Video Id'
        },
    },
    vimeoUrl: {
        presence: { message: '^Vimeo Url is required' },
        type: {
            type: val => typeof val === 'string' && val !== "",
            message: '^Invalid Vimeo Url'
        },
    },
    contentLength: {
        presence: { message: '^Length is required' },
        type: {
            type: val => val !== "" && /^\d+$/.test(val),
            message: '^Invalid Length'
        },
    },
    externalUrl: {
        presence: { message: '^External Url is required' },
        type: {
            type: val => typeof val === 'string' && val !== "",
            message: '^Invalid External Url'
        },
    },
    link: {
        presence: { message: '^Vimeo Id is required' },
        type: {
            type: val => typeof parseInt(val) === 'number' && val !== "",
            message: '^Invalid Vimeo Id'
        },
    },
    file: {
        presence: { message: '^File is required' },
        type: {
            type: val => typeof val === 'object' || typeof val === 'string',
            message: '^Invalid File'
        },
    },
    classType: {
        presence: { message: '^Class Type is required', allowEmpty: false },
    },
    instructor: {
        presence: { message: '^Instructor is required', allowEmpty: false },
    },
    intensity: {
        presence: { message: '^Intensity is required', allowEmpty: false },
    },
    theme: {
        length: {
            minimum: 0,
            maximum: 32,
            tooLong: '^Theme must be less than 33 characters',
        }
    },
    episode: {
        presence: { message: '^Episode is required', allowEmpty: false },
        length: {
            minimum: 1,
            maximum: 4,
            tooLong: '^Episode must be less than 5 characters',
        }
    },
    fitnessDescription: {
        presence: false,
        type: 'string',
        length: {
            maximum: 25000,
            tooShort: '^Description is required',
            tooLong: '^Description must be less than 25001 characters',
        }
    },
    IOSUrl: {
        presence: { message: '^IOS Url is required' },
        type: {
            type: val => typeof val === 'string' && val !== "",
            message: '^Invalid IOS Url'
        },
    },
    GooglePlayUrl: {
        presence: { message: '^Google Play Url is required' },
        type: {
            type: val => typeof val === 'string' && val !== "",
            message: '^Invalid Google Play Url'
        },
    },
    IOSStoreUrl: {
        presence: { message: '^IOS Store Url is required' },
        url: {message: '^Url is not a url. Hint: try adding "https://'}
    },
    PlayStoreUrl: {
        presence: { message: '^Play Store Url is required' },
        url: {message: '^Url is not a url. Hint: try adding "https://'}
    },
}
const LENGTHS = {
    TEN: 1,
    TWENTY: 2,
    THIRTY: 3,
    FORTY: 4
};

class ContentCreatorForm extends Component{
    constructor(props){
        super(props);
        this.state={
            mounted: false,
            changes: false,
            saved: false,
            saving: false,
            id: null,
            tags:[],
            categories:[],
            statuses:[],
            contentTypes: [],
            classTypes: [],
            instructors: [],
            equipment: [],
            intensities: [],
            classLengths: [],
            title: '',
            contentType: null,
            format: CONTENT_FORMATS.VIDEO,
            blog: '',
            pageTitle: '',
            hero: '',
            file: null,
            link: '',
            linkDisclaimer: false,
            description: '',
            vimeoUrl: '',
            videoId: '',
            externalUrl: '',
            pdf: null,
            contentLength: '',
            thumbnail: '',
            category: '',
            selectedTags: [],
            author: '',
            status: CONTENT_STATUSES.DRAFT, // this is the status in the form AT THE MOMENT
            currentSavedStatus: null, // this is the currently saved status in the server
            scheduleDate: {date: format(new Date(), 'yyyy-MM-dd'), hour: (new Date().getHours()+1)%12, midday: new Date().getHours() >= 12 ? 'pm' : 'am'},
            overview: false,
            overviewModal: false,
            selectedClassType: null,
            selectedInstructor: null,
            selectedEquipment: null,
            selectedIntensity: null,
            theme: '',
            episode: '',
            IOSUrl: '',
            GooglePlayUrl: '',
            IOSStoreUrl: '',
            PlayStoreUrl: '',
            errors: {},
        };
    }

    componentDidMount(){
        axios.get('/content/status')
            .then(statuses=>this.setState({statuses:statuses.data.statuses}));
        axios.get('/content/types')
            .then(types=>this.setState({contentTypes:types.data.types}));

        const contentId = parseInt(parseQuery(this.props.location.search).content);
        if (contentId) {
            this.props.setActiveContent(contentId)
                .then(res => {
                    const content = res.payload.data.result;
                    switch(content.content_type_id) {
                        case CONTENT_TYPES.BLOG:
                            this.setState({
                                blog: content.content,
                                pageTitle: content.page_title,
                                hero: content.hero_image,
                            })
                            break;
                        case CONTENT_TYPES.FILE:
                            this.setState({
                                file: content.content,
                            });
                            break;
                        case CONTENT_TYPES.LINK:
                            this.setState({
                                link: content.content,
                            });
                            break;
                        case CONTENT_TYPES.PATHWAY:
                            this.setState({
                                videoId: content.content,
                                overview: content.overview,
                            });
                            axios.get(`/pathways/content/checkOverview/${content.categories[0].id}`)
                                .then(res => {
                                    this.setState({ currentOverview: res.data.result});
                                });
                            break;
                        case CONTENT_TYPES.FEATURED:
                        case CONTENT_TYPES.ORGANIZATION:
                            if(content.format_id === CONTENT_FORMATS.AUDIO){
                                this.setState({
                                    vimeoUrl: content.content,
                                    format: CONTENT_FORMATS.AUDIO,
                                    contentLength: content.format_length,
                                })
                            }
                            if (content.format_id === CONTENT_FORMATS.VIDEO) {
                                this.setState({
                                    videoId: content.content,
                                    format: CONTENT_FORMATS.VIDEO,
                                    contentLength: content.format_length,
                                })
                            }
                            if (content.format_id === CONTENT_FORMATS.LINK) {
                                this.setState({
                                    externalUrl: content.content,
                                    format: CONTENT_FORMATS.LINK,
                                    linkDisclaimer: content.link_disclaimer,
                                })
                            }
                            if (content.format_id === CONTENT_FORMATS.PDF) {
                                this.setState({
                                    pdf: content.content,
                                    format: CONTENT_FORMATS.PDF,
                                });
                            }
                            if (content.format_id === CONTENT_FORMATS.DEEP_LINK) {
                                this.setState({
                                    format: CONTENT_FORMATS.DEEP_LINK,
                                    IOSUrl: content.ios_app_link,
                                    IOSStoreUrl: content.ios_store_link,
                                    GooglePlayUrl: content.android_app_link,
                                    PlayStoreUrl: content.android_store_link,
                                    linkDisclaimer: content.link_disclaimer,
                                });
                            }
                            break;
                        case CONTENT_TYPES.FITNESS:
                            this.setState({
                                videoId: content.content,
                                format: CONTENT_FORMATS.VIDEO,
                                contentLength: content.format_length,
                                theme: content.theme,
                                episode: content.episode.toString(),
                            });
                            break;
                        // no default
                    }
                    if (content.status_id === CONTENT_STATUSES.SCHEDULED) {
                        const publishDate = parseISO(content.publish_date);
                        const date = format(publishDate, 'yyyy-MM-dd');
                        const hour = publishDate.getHours()%12;
                        const midday = publishDate.getHours() >= 12 ? 'pm' : 'am';
                        this.setState({scheduleDate: {date, hour, midday}});
                    }
                    this.setState({
                        id: content.id,
                        title: content.title,
                        contentType: content.content_type_id,
                        description: content.description,
                        thumbnail: content.thumbnail,
                        status: content.status_id,
                        currentlySavedStatus: content.status_id || null,
                        category: content.categories && content.categories.length ? content.categories[0].id : null,
                        selectedTags: content.tags ? content.tags.map(tag => ({label: tag.tag, value: tag.id})) : [],
                        mounted: true,
                    });
                })
                .catch(() => this.setState({ error: 'Content Id not recognized. This content may have been deleted.'}))
        } else{
            this.setState({ mounted: true })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // check to see if content type has loaded and if so load any specific data after this
        if(this.state.contentType !== prevState.contentType && this.state.contentType) {
            // get categories when content type changes
            axios.get(`/content/categorical-hierarchy/${this.state.contentType}`)
                .then(categories=> {
                    // check if loading for the 1st time
                    if (prevState.contentType === null) {
                        if(this.props.active_content) {
                            const content = this.props.active_content.data.result;
                            this.setState({
                                categories:categories.data.result,
                                category: content.categories && content.categories.length ? content.categories[0].id : null,
                            })
                        } else {
                            this.setState({
                                categories:categories.data.result,
                                category: ''
                            })
                        }
                    } else {
                        this.setState({
                            categories:categories.data.result,
                            category: ''
                        })
                    }
                });

            // get tags when content type changes
            this.displayTags(this.state.contentType)
                .then(() => {
                    // check if loading for the 1st time
                    if (prevState.contentType === null) {
                        if(this.props.active_content) {
                            const content = this.props.active_content.data.result;
                            this.setState({selectedTags: content.tags ? content.tags.map(tag => ({label: tag.tag, value: tag.id})) : [],});
                        } else {
                            this.setState({selectedTags: []});
                        }
                    } else {
                        this.setState({selectedTags: []});
                    }
                })

            if (this.state.contentType === CONTENT_TYPES.FITNESS ||
                this.state.contentType === CONTENT_TYPES.ORGANIZATION ||
                this.state.contentType === CONTENT_TYPES.FEATURED
            ) {
                Promise.all([
                    this.loadEquipment(),
                    this.loadInstructors(),
                    this.loadClassTypes(),
                    this.loadIntensities(),
                    this.loadClassLengths(),
                ])
                    .then(() => {
                        // check if loading for the 1st time
                        if (prevState.contentType === null) {
                            if(this.props.active_content) {
                                console.log(this.props.active_content);
                                const {meta} = this.props.active_content.data.result;

                                // find instructor
                                let instructor = meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.TRAINER);
                                if (instructor) instructor = {label: instructor.selected_option.content_meta_option_label, value: instructor.selected_option.content_meta_option_id};

                                // find intensity
                                let intensity = meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.INTENSITY);
                                if (intensity) intensity = {label: intensity.selected_option.content_meta_option_label, value: intensity.selected_option.content_meta_option_id};

                                // find equipment
                                let equipment = meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.EQUIPMENT);
                                if (equipment) equipment = {label: equipment.selected_option.content_meta_option_label, value: equipment.selected_option.content_meta_option_id};

                                // find class type
                                let classType = meta.find(item => item.meta_type.content_meta_type_id === CONTENT_META_TYPES.CLASS_TYPES);
                                if (classType) classType = {label: classType.selected_option.content_meta_option_label, value: classType.selected_option.content_meta_option_id};

                                this.setState({
                                    selectedInstructor: instructor,
                                    selectedIntensity: intensity,
                                    selectedEquipment: equipment,
                                    selectedClassType: classType,
                                });
                            }
                        }
                    })
            }
        }
    }

    setForm = (obj) => {
        this.setState( {
            saving: false,
            changed: true,
            ...obj
        });
    }

    componentWillUnmount(){
        this.props.clearActiveContent();
    }

    async uploadThumbnail(thumbnail){
        if (!thumbnail) return null;
        if(typeof this.state.thumbnail === 'string' && this.state.thumbnail !== '' && this.props.active_content) return thumbnail;

        //get thumbnail file info
        let thumbnail_extension = thumbnail.name.split('.').pop();
        let thumbnail_type = thumbnail.type;
        let thumb_url = await axios.post(`/content/create-url/${thumbnail_extension}`, {upload_type:thumbnail_type});
        await axios.put(thumb_url.data.result.presigned_url, thumbnail,{headers:{'Content-Type':thumbnail_type}});

        return thumb_url.data.result.new_url;
    }

    async uploadFile(file_upload){
        if(typeof this.state.file === 'string' && this.props.active_content) return file_upload ;

        let file_extension = file_upload.name.split('.').pop();
        let file_type = file_upload.type;
        let file_url = await  axios.post(`/content/create-url/${file_extension}`, {upload_type:file_type});
        await axios.put(file_url.data.result.presigned_url, file_upload, {headers:{'Content-Type':file_type}});

        return file_url.data.result.new_url;
    }

    async uploadHeroImage(hero_image){
        if(typeof this.state.hero === 'string' && this.props.active_content) return hero_image;

        if(!hero_image) return hero_image;

        let hero_extension = hero_image.name.split('.').pop();
        let hero_type = hero_image.type;
        let hero_url = await axios.post(`/content/create-url/${hero_extension}`, {upload_type:hero_type});
        await axios.put(hero_url.data.result.presigned_url, hero_image,{headers:{'Content-Type':hero_type}});

        return hero_url.data.result.new_url;
    }

    checkOverview = (e) => {
        // show modal if selected
        if (this.state.currentOverview && this.state.currentOverview.id !== this.state.id){
            this.showOverviewModal();
        } else {
            this.setForm({ overview: e.target.checked })
        }
    }

    showOverviewModal = () => {
        this.setState({ overviewModal: true });
    }

    hideOverviewModal = () => {
        this.setState({ overviewModal: false });
    }

    statusChange = (status) => {
        this.setForm({ status: status });
        if (status.value === 'draft') {
            this.setForm({ overview: false });
        }
    }

    categoryChange = category => {
        axios.get(`/pathways/content/checkOverview/${category}`)
            .then(res => {
                this.setForm({
                    currentOverview: res.data.result,
                    category: category
                });
            });
    }

    inlineValidation = (validation, val) => {
        if (validation) {
            this.setForm({ errors: { ...this.state.errors, ...validation } });
        } else {
            const copy = { ...this.state.errors };
            delete copy[val];
            this.setForm({ errors: copy });
        }
    }

    onSubmit = async e => {
        e.preventDefault();
        this.setState({ saving: true });

        // validation
        let checks = constraints;
        let input = {
            title: this.state.title,
            description: this.state.description,
            thumbnail: this.state.thumbnail,
            category: this.state.category,
        };
        const type = this.state.contentType;
        switch (type) {
            case CONTENT_TYPES.BLOG:
                input.blog = this.state.blog;
                input.pageTitle = this.state.pageTitle;
                if (typeof this.state.hero === 'object' || this.state.hero !== '') input.hero = this.state.hero;
                checks = {
                    ...checks,
                    blog: optional.blog,
                    pageTitle: optional.pageTitle,
                    hero: optional.hero,
                };
                break;
            case CONTENT_TYPES.FILE:
                input.file = this.state.file;
                checks = { ...checks, file: optional.file, };
                break;
            case CONTENT_TYPES.LINK:
                input.link = this.state.link;
                checks = { ...checks, link: optional.link, };
                break;
            case CONTENT_TYPES.PATHWAY:
                input.videoId = this.state.videoId;
                checks = { ...checks, videoId: optional.videoId, };
                break;
            case CONTENT_TYPES.FEATURED:
            case CONTENT_TYPES.ORGANIZATION:
                input.content_format = this.state.format;
                input.contentLength = this.state.contentLength;
                input.instructor = this.state.selectedInstructor;
                if (this.state.format === CONTENT_FORMATS.AUDIO) {
                    input.vimeoUrl = this.state.vimeoUrl;
                    checks = {...checks, vimeoUrl: optional.vimeoUrl, contentLength: optional.contentLength,};
                }
                if (this.state.format === CONTENT_FORMATS.VIDEO) {
                    input.videoId = this.state.videoId;
                    checks = {...checks, videoId: optional.videoId, contentLength: optional.contentLength,}
                }
                if (this.state.format === CONTENT_FORMATS.LINK) {
                    input.externalUrl = this.state.externalUrl;
                    input.disclaimer = this.state.linkDisclaimer;
                    checks = {...checks, externalUrl: optional.externalUrl};
                }
                if (this.state.format === CONTENT_FORMATS.PDF) {
                    input.pdf = this.state.pdf;
                }
                if (this.state.format === CONTENT_FORMATS.DEEP_LINK) {
                    input.disclaimer = this.state.linkDisclaimer;
                    input.IOSUrl= this.state.IOSUrl;
                    input.IOSStoreUrl= this.state.IOSStoreUrl;
                    input.GooglePlayUrl= this.state.GooglePlayUrl;
                    input.PlayStoreUrl= this.state.PlayStoreUrl;
                    checks = {
                        ...checks,
                        IOSUrl: optional.IOSUrl,
                        IOSStoreUrl: optional.IOSStoreUrl,
                        GooglePlayUrl: optional.GooglePlayUrl,
                        PlayStoreUrl: optional.PlayStoreUrl,
                    };
                }
                break;
            case CONTENT_TYPES.FITNESS:
                input.content_format = CONTENT_FORMATS.VIDEO;
                input.contentLength = this.state.contentLength;
                input.videoId = this.state.videoId;
                input.classType = this.state.selectedClassType;
                input.instructor = this.state.selectedInstructor;
                input.intensity = this.state.selectedIntensity;
                input.equipment = this.state.selectedEquipment;
                input.theme = this.state.theme;
                input.episode = this.state.episode;
                checks = {
                    ...checks,
                    videoId: optional.videoId,
                    contentLength: optional.contentLength,
                    classType: optional.classType,
                    instructor: optional.instructor,
                    intensity: optional.intensity,
                    theme: optional.theme,
                    episode: optional.episode,
                    description: optional.fitnessDescription,
                }
            // no default
        }
        let validation = validate(input, checks);

        // check if scheduled and > 10min in the future
        if (this.state.status === CONTENT_STATUSES.SCHEDULED) {
            if (differenceInMinutes(this.parseScheduleDate(), new Date()) <= 10) {
                if (validation) validation.schedule = ['Date must be 10 minutes in the future'];
                else {validation = {schedule: ['Date must be 10 minutes in the future']}}
            }
        }

        if (validation) {
            // prevent submission
            return this.setState({errors: validation, saving: false,});
        } else {
            this.setState({ errors: {} });
        }
        try {
            let post_url;
            let update = false;
            if (this.props.active_content) {
                post_url = `/content/update/${this.props.active_content.data.result.id}`;
                update = true;
            } else {
                post_url = '/content/new-content';
            }
            let submission = {
                status: this.state.statuses.find(val => val.id === this.state.status).name,
                content_type: this.state.contentType,
                title: this.state.title,
                overview: this.state.overview === 1,
            };

            //add categories and tags to object
            if (this.state.category) submission.categories = [this.state.category];

            //add description if available
            if (this.state.description) submission.description = this.state.description;

            // create any necessary tags
            const neededTags = this.state.selectedTags.filter(tag => tag.__isNew__);
            const created = await Promise.all(neededTags.map(tag => {
                return newTag(tag.label, type);
            }));

            // add tags to submission
            const conjoined = this.state.selectedTags.map(tag => {
                if (tag.__isNew__) {
                    // find tag in newly created tags
                    const found = created.find(item => item.tag === tag.label);
                    return found ? found.tag_id : null;
                }
                return tag.value;
            });
            submission.tags = conjoined.filter(item => item);

            //upload thumbnail to aws
            submission.thumbnail = await this.uploadThumbnail(this.state.thumbnail);

            // check if scheduled
            if (this.state.status === CONTENT_STATUSES.SCHEDULED) {
                submission.publish_date = this.parseScheduleDate().toISOString();
            }

            switch (type) {
                case CONTENT_TYPES.BLOG:
                    submission.content = this.state.blog;
                    submission.page_title = this.state.pageTitle;
                    submission.hero_image = await this.uploadHeroImage(this.state.hero);
                    break;
                case CONTENT_TYPES.FILE:
                    submission.content = await this.uploadFile(this.state.file);
                    break;
                case CONTENT_TYPES.LINK:
                    submission.content = this.state.link;
                    break;
                case CONTENT_TYPES.PATHWAY:
                    submission.content = this.state.videoId;
                    break;
                case CONTENT_TYPES.FEATURED:
                case CONTENT_TYPES.ORGANIZATION:
                    submission.content_format = input.content_format;
                    if (input.content_format === CONTENT_FORMATS.AUDIO) {
                        submission.content = input.vimeoUrl;
                        submission.content_format_length = input.contentLength;
                    }
                    if (input.content_format === CONTENT_FORMATS.VIDEO) {
                        submission.content = input.videoId;
                        submission.content_format_length = input.contentLength;
                    }
                    if (input.content_format === CONTENT_FORMATS.LINK) {
                        submission.content = input.externalUrl;
                        submission.disclaimer = input.disclaimer;
                    }
                    if (input.content_format === CONTENT_FORMATS.PDF) {
                        if (update && typeof input.pdf === 'string') {
                            submission.content = input.pdf;
                        } else {
                            submission.pdf = input.pdf;
                        }
                    }
                    if (input.content_format === CONTENT_FORMATS.DEEP_LINK) {
                        submission.ios_app_link = input.IOSUrl;
                        submission.ios_store_link = input.IOSStoreUrl;
                        submission.android_app_link = input.GooglePlayUrl;
                        submission.android_store_link = input.PlayStoreUrl;
                        submission.disclaimer = input.disclaimer;
                        submission.content = 'content';
                    }
                    if (input.instructor) {
                        submission.meta = {
                            trainer: input.instructor.value,
                        };
                    }
                    delete submission.overview;
                    if (input.content_format === CONTENT_FORMATS.PDF) {
                        const data = new FormData();
                        Object.keys(submission).map(key => data.append(key, submission[key]));
                        submission = data;
                    }
                    break;
                case CONTENT_TYPES.FITNESS:
                    submission.content_format = input.content_format;
                    submission.content = input.videoId;
                    submission.content_format_length = input.contentLength;
                    submission.theme = input.theme;
                    submission.episode = input.episode;
                    let lengthId = this.state.classLengths[0].id;
                    const {contentLength} = input;
                    this.state.classLengths.forEach(l => {
                        if (contentLength >= l.value) lengthId = l.id;
                    });

                    submission.meta = {
                        class_type: input.classType.value,
                        intensity: input.intensity.value,
                        trainer: input.instructor.value,
                        equipment: input.equipment ? input.equipment.value : null,
                        class_length: lengthId,
                    };
                    // no default
            }
            const res = await axios.post(post_url, submission)
                .catch(e => {
                    throw new Error(e.response.data.error);
                });
            this.setState({ changed: false, saved: true, currentlySavedStatus: this.state.status });
            await this.props.setActiveContent(res.data.result[0].id);
            this.props.history.push(`${this.props.match.url}?content=${res.data.result[0].id}`);
        } catch (err) {
            console.log(err);
            this.setState({
                saving: false,
                errors: { exception: [err.message ? err.message : 'Unable to save content'] }
            });
        }
    }

    renderErrors = () => {
        if (!isEmpty(this.state.errors)) {
            return (
                <div>
                    <p className="text-danger">
                        Please fix the following:
                    </p>
                    <ul>
                        {Object.keys(this.state.errors).map(key => <li>{this.state.errors[key]}</li>)}
                    </ul>
                </div>
            )
        }
    }

    renderFeatured = () => {
        switch(this.state.format) {
            case CONTENT_FORMATS.AUDIO:
                return (
                    <div className='row'>
                        <div className={`form-group col-xs-8 ${this.state.errors.vimeoUrl ? 'has-error': ''}`}>
                            <label>Video Id <span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { vimeoUrl: e.target.value },
                                        { vimeoUrl: optional.vimeoUrl }
                                    );
                                    this.inlineValidation(validation, 'videoId')
                                }}
                                value={this.state.vimeoUrl}
                                onChange={e => this.setForm({ vimeoUrl: e.target.value})}
                                type='text'
                                className='form-control'/>
                            { this.state.errors.vimeoUrl ?
                                <div>
                                    {this.state.errors.vimeoUrl.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className={` form-group col-xs-4 ${this.state.errors.contentLength ? 'has-error': ''}`}>
                            <label>Length (minutes)</label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { contentLength: e.target.value },
                                        { contentLength: optional.contentLength }
                                    );
                                    this.inlineValidation(validation, 'contentLength')
                                }}
                                value={this.state.contentLength}
                                onChange={e => this.setForm({ contentLength: e.target.value})}
                                type="text"
                                className="form-control"/>
                            { this.state.errors.contentLength ?
                                <div>
                                    {this.state.errors.contentLength.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className="col-xs-4 form-group">
                            {this.renderInstructor()}
                        </div>
                    </div>
                )
            case CONTENT_FORMATS.VIDEO:
                return (
                    <div className='row'>
                        <div className={`form-group col-xs-8 ${this.state.errors.vimeoUrl ? 'has-error': ''}`}>
                            <label>Video Id <span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { videoId: e.target.value },
                                        { videoId: optional.videoId }
                                    );
                                    this.inlineValidation(validation, 'videoId')
                                }}
                                value={this.state.videoId}
                                onChange={e => this.setForm({ videoId: e.target.value})}
                                type='text'
                                className='form-control'/>
                            { this.state.errors.videoId ?
                                <div>
                                    {this.state.errors.videoId.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className={`col-xs-4 form-group ${this.state.errors.contentLength ? 'has-error': ''}`}>
                            <label>Length (minutes)</label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { contentLength: e.target.value },
                                        { contentLength: optional.contentLength }
                                    );
                                    this.inlineValidation(validation, 'contentLength')
                                }}
                                value={this.state.contentLength}
                                onChange={e => this.setForm({ contentLength: e.target.value})}
                                type="text"
                                className="form-control"/>
                            { this.state.errors.contentLength ?
                                <div>
                                    {this.state.errors.contentLength.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className="col-xs-4 form-group">
                            {this.renderInstructor()}
                        </div>
                    </div>
                )
            case CONTENT_FORMATS.LINK:
                return (
                    <div className='row'>
                        <div className={`col-xs-12 col-sm-9 form-group ${this.state.errors.externalUrl ? 'has-error': ''}`}>
                            <label>External URL<span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { externalUrl: e.target.value },
                                        { externalUrl: optional.externalUrl }
                                    );
                                    this.inlineValidation(validation, 'externalUrl')
                                }}
                                value={this.state.externalUrl}
                                onChange={e => this.setForm({ externalUrl: e.target.value})}
                                type='text'
                                className='form-control'/>
                            { this.state.errors.externalUrl ?
                                <div>
                                    {this.state.errors.externalUrl.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className="col-xs-12 col-sm-3 form-group">
                            <label>Add Disclaimer</label>
                            <div className="d:f">
                                <input
                                    id='disclaimer'
                                    className='w:a'
                                    type="checkbox"
                                    checked={this.state.linkDisclaimer}
                                    onChange={e => this.setState({linkDisclaimer: e.target.checked})}/>
                                <label htmlFor='disclaimer' className='f-w:400 m-l:1'>Warn user they're leaving site</label>
                            </div>
                        </div>
                        <div className="col-xs-4 form-group">
                            {this.renderInstructor()}
                        </div>
                    </div>
                )
            case CONTENT_FORMATS.PDF:
                return (
                    <div className='row'>
                        <div className={`form-group col-xs-12 ${this.state.errors.pdf ? 'has-error': ''}`}>
                            <label>Upload PDF<span className="text-danger">* required</span></label>
                            <FileUpload
                                file={this.state.pdf}
                                changeFile={val => this.setForm({ pdf: val })}
                            />
                            { this.state.errors.pdf ?
                                <div>
                                    {this.state.errors.pdf.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className='form-group col-xs-4'>
                            {this.renderInstructor()}
                        </div>
                    </div>
                )
            case CONTENT_FORMATS.DEEP_LINK:
                return (
                    <div className='row'>
                        <div className={`col-xs-12 col-sm-6 form-group ${this.state.errors.IOSUrl ? 'has-error': ''}`}>
                            <label>IOS App Link<span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { IOSUrl: e.target.value },
                                        { IOSUrl: optional.IOSUrl }
                                    );
                                    this.inlineValidation(validation, 'IOSUrl')
                                }}
                                value={this.state.IOSUrl}
                                onChange={e => this.setForm({ IOSUrl: e.target.value})}
                                type='text'
                                className='form-control'/>
                            { this.state.errors.IOSUrl ?
                                <div>
                                    {this.state.errors.IOSUrl.map((err, i) => (
                                        <div className={styles.inlineError}>
                                            <span key={i} className="text-danger">{err}</span>
                                        </div>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className={`col-xs-12 col-sm-6 form-group ${this.state.errors.GooglePlayUrl ? 'has-error': ''}`}>
                            <label>Play Store App Link<span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { GooglePlayUrl: e.target.value },
                                        { GooglePlayUrl: optional.GooglePlayUrl }
                                    );
                                    this.inlineValidation(validation, 'GooglePlayUrl')
                                }}
                                value={this.state.GooglePlayUrl}
                                onChange={e => this.setForm({ GooglePlayUrl: e.target.value})}
                                type='text'
                                className='form-control'/>
                            { this.state.errors.GooglePlayUrl ?
                                <div>
                                    {this.state.errors.GooglePlayUrl.map((err, i) => (
                                        <div className={styles.inlineError}>
                                            <span key={i} className="text-danger">{err}</span>
                                        </div>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className={`col-xs-12 col-sm-6 form-group ${this.state.errors.IOSStoreUrl ? 'has-error': ''}`}>
                            <label>IOS Store Link<span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { IOSStoreUrl: e.target.value },
                                        { IOSStoreUrl: optional.IOSStoreUrl }
                                    );
                                    this.inlineValidation(validation, 'IOSStoreUrl')
                                }}
                                value={this.state.IOSStoreUrl}
                                onChange={e => this.setForm({ IOSStoreUrl: e.target.value})}
                                type='text'
                                className='form-control'/>
                            { this.state.errors.IOSStoreUrl ?
                                <div>
                                    {this.state.errors.IOSStoreUrl.map((err, i) => (
                                        <div className={styles.inlineError}>
                                            <span key={i} className="text-danger">{err}</span>
                                        </div>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className={`col-xs-12 col-sm-6 form-group ${this.state.errors.PlayStoreUrl ? 'has-error': ''}`}>
                            <label>Play Store Link<span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { PlayStoreUrl: e.target.value },
                                        { PlayStoreUrl: optional.PlayStoreUrl }
                                    );
                                    this.inlineValidation(validation, 'PlayStoreUrl')
                                }}
                                value={this.state.PlayStoreUrl}
                                onChange={e => this.setForm({ PlayStoreUrl: e.target.value})}
                                type='text'
                                className='form-control'/>
                            { this.state.errors.PlayStoreUrl ?
                                <div>
                                    {this.state.errors.PlayStoreUrl.map((err, i) => (
                                        <div className={styles.inlineError}>
                                            <span key={i} className="text-danger">{err}</span>
                                        </div>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className="col-xs-12 form-group">
                            <label>Add Disclaimer</label>
                            <div className="d:f a-i:c">
                                <input
                                    id='disclaimer'
                                    className='w:a m:0'
                                    type="checkbox"
                                    checked={this.state.linkDisclaimer}
                                    onChange={e => this.setState({linkDisclaimer: e.target.checked})}/>
                                <label htmlFor='disclaimer' className='f-w:400 m-l:1 m-b:0'>Warn user they're leaving site</label>
                            </div>
                        </div>
                    </div>

                )
            // no default 
        }
    }

    displayTags = async (contentType) => {
        try {
            const tags = await fetchTags(contentType);
            this.setState({tags: tags.map(tag => ({value: tag.id, name: tag.name}))})

        } catch (e) {
            if (e.response && e.response.data) {
                this.setState({
                    errors: {exception: e.response.data.error}
                })
            } else {
                this.setState({
                    errors: {exception: 'Unable to load tags. Please refresh to try again. If this error persists contact system admin'}
                });
            }
        }
    }

    renderHourOptions = () => {
        const options = [];
        for(let i=0; i<12; i++) {
            options.push(i+1)
        }
        return options;
    }

    renderMidday = () => {
        // const parsed = parse(this.state.scheduleDate.date, 'yyyy-MM-dd', new Date());
        // const isToday = isSameDay(new Date(), parsed);
        // if (isToday && new Date().getHours() >= 12) return ['pm'];
        return ['am', 'pm'];
    }

    parseScheduleDate = () => {
        let hour = this.state.scheduleDate.hour;
        if (this.state.scheduleDate.midday === 'pm') hour = parseInt(hour) + 12;
        if (this.state.scheduleDate.midday === 'am' && parseInt(hour) === 12) hour = 0;
        const parsedDate = parse(this.state.scheduleDate.date, 'yyyy-MM-dd', new Date());
        parsedDate.setHours(hour);
        parsedDate.setMinutes(0);
        parsedDate.setMilliseconds(0);
        return parsedDate;
    }

    changeScheduleDate = val => {
        const parsed = parse(val, 'yyyy-MM-dd', new Date());
        let hours = this.state.scheduleDate.hour;
        if (this.state.scheduleDate.midday === 'pm') hours = parseInt(hours) + 12;
        parsed.setHours(hours);
        const isToday = isSameDay(new Date(), parsed);
        const passedTheHour = parsed.getHours() < (new Date().getHours()+1)%12;
        this.setState({
            scheduleDate: {
                hour: isToday && passedTheHour ? (new Date().getHours()+1)%12 : this.state.scheduleDate.hour,
                date: val,
                midday: isToday && new Date().getHours() >= 12 ? 'pm' : this.state.scheduleDate.midday,
            }
        })
    }

    loadEquipment = async () => {
        try {
            const eq = await fetchEquipment('', '');
            const options = [
                {name: 'No Equipment', value: ''},
                ...eq.map(item => ({value: item.content_meta_option_id, name: item.label}))
            ]
            this.setState({equipment: options});
        } catch (e) {
            console.log(e);
        }
    }

    loadInstructors = async () => {
        try {
            const instructors = await fetchInstructors('', '');
            this.setState({instructors: instructors.map(trainer => ({value: trainer.content_meta_option_id, name: `${trainer.label} ${trainer.text ? `(${trainer.text})` : ''}`}))});
        } catch (e) {
            console.log(e);
        }
    }

    loadClassTypes = async () => {
        try {
            const classTypes = await fetchClassTypes('', '');
            this.setState({classTypes: classTypes.map(type => ({value: type.content_meta_option_id, name: type.label}))});
        } catch (e) {
            console.log(e);
        }
    }

    loadIntensities = async () => {
        try {
            const intensities = await fetchIntensities('', '');
            this.setState({intensities: intensities.map(i => ({value: i.content_meta_option_id, name: i.label}))});
        } catch (e) {
            console.log(e);
        }
    }

    loadClassLengths = async () => {
        try {
            let lengths = await fetchClassLengths();
            lengths.sort((a, b) => a.value - b.value);
            this.setState({classLengths: lengths.map(l => ({id: l.content_meta_option_id, name: l.label, value: l.value}))});
        } catch (e) {
            console.log(e);
        }
    }

    renderFitness = () => {
        return (
            <div className='row'>
                <div className={`form-group col-xs-4 ${this.state.errors.vimeoUrl ? 'has-error': ''}`}>
                    <label>Video Id <span className="text-danger">* required</span></label>
                    <input
                        onBlur={e => {
                            const validation = validate(
                                { videoId: e.target.value },
                                { videoId: optional.videoId }
                            );
                            this.inlineValidation(validation, 'videoId')
                        }}
                        value={this.state.videoId}
                        onChange={e => this.setForm({ videoId: e.target.value})}
                        type='text'
                        className='form-control'/>
                    { this.state.errors.videoId ?
                        <div className={styles.inlineError}>
                            {this.state.errors.videoId.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={`form-group col-xs-4 ${this.state.errors.contentLength ? 'has-error': ''}`}>
                    <label>Length (minutes)</label>
                    <input
                        onBlur={e => {
                            const validation = validate(
                                { contentLength: e.target.value },
                                { contentLength: optional.contentLength }
                            );
                            this.inlineValidation(validation, 'contentLength')
                        }}
                        value={this.state.contentLength}
                        onChange={e => this.setForm({ contentLength: e.target.value})}
                        type="text"
                        className="form-control"/>
                    { this.state.errors.contentLength ?
                        <div className={styles.inlineError}>
                            {this.state.errors.contentLength.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={`form-group col-xs-4 ${this.state.errors.classType ? 'has-error': ''}`}>
                    <ReactCreatableSelect
                        name='Class Type'
                        label='Class Type'
                        options={this.state.classTypes}
                        multi={false}
                        onChange={val => {
                            const found = this.state.classTypes.find(item => item.value === val.value);
                            if (!found) return this.setForm({selectedClassType: null});
                            this.setForm({ selectedClassType: val})
                        }}
                        value={this.state.selectedClassType}
                    />
                    { this.state.errors.classType ?
                        <div className={styles.inlineError}>
                            {this.state.errors.classType.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={`form-group col-xs-4 ${this.state.errors.instructor ? 'has-error': ''}`}>
                    <ReactCreatableSelect
                        name='Instructor'
                        label='Instructor'
                        options={this.state.instructors}
                        multi={false}
                        onChange={val => {
                            this.setForm({ selectedInstructor: val})
                        }}
                        value={this.state.selectedInstructor}
                    />
                    { this.state.errors.instructor ?
                        <div className={styles.inlineError}>
                            {this.state.errors.instructor.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={`form-group col-xs-4 ${this.state.errors.equipment ? 'has-error': ''}`}>
                    <ReactCreatableSelect
                        name='Equipment'
                        label='Equipment'
                        options={this.state.equipment}
                        multi={false}
                        onChange={val => {
                            this.setForm({ selectedEquipment: val})
                        }}
                        value={this.state.selectedEquipment}
                    />
                    { this.state.errors.equipment ?
                        <div className={styles.inlineError}>
                            {this.state.errors.equipment.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={`form-group col-xs-4 ${this.state.errors.intensity ? 'has-error': ''}`}>
                    <ReactCreatableSelect
                        name='Intensity'
                        label='Intensity'
                        options={this.state.intensities}
                        multi={false}
                        onChange={val => {
                            this.setForm({ selectedIntensity: val})
                        }}
                        value={this.state.selectedIntensity}
                    />
                    { this.state.errors.intensity ?
                        <div className={styles.inlineError}>
                            {this.state.errors.intensity.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={`form-group col-xs-6 ${this.state.errors.contentLength ? 'has-error': ''}`}>
                    <label>Theme</label>
                    <input
                        onBlur={e => {
                            const validation = validate(
                                { theme: e.target.value },
                                { theme: optional.theme }
                            );
                            this.inlineValidation(validation, 'theme')
                        }}
                        value={this.state.theme}
                        onChange={e => this.setForm({ theme: e.target.value})}
                        type="text"
                        className="form-control"/>
                    { this.state.errors.theme ?
                        <div className={styles.inlineError}>
                            {this.state.errors.theme.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={`form-group col-xs-4 ${this.state.errors.contentLength ? 'has-error': ''}`}>
                    <label>Episode</label>
                    <input
                        onBlur={e => {
                            const validation = validate(
                                { episode: e.target.value },
                                { episode: optional.episode }
                            );
                            this.inlineValidation(validation, 'episode')
                        }}
                        value={this.state.episode}
                        onChange={e => this.setForm({ episode: e.target.value})}
                        type="text"
                        className="form-control"/>
                    { this.state.errors.episode ?
                        <div className={styles.inlineError}>
                            {this.state.errors.episode.map((err, i) => (
                                <span key={i} className="text-danger">{err}</span>
                            ))}
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    }

    renderInstructor = () => {
        return (
            <div className={`form-group ${this.state.errors.instructor ? 'has-error': ''}`}>
                <ReactCreatableSelect
                    name='Instructor'
                    label='Instructor'
                    options={this.state.instructors}
                    multi={false}
                    onChange={val => {
                        this.setForm({ selectedInstructor: val})
                    }}
                    value={this.state.selectedInstructor}
                />
                { this.state.errors.instructor ?
                    <div className={styles.inlineError}>
                        {this.state.errors.instructor.map((err, i) => (
                            <span key={i} className="text-danger">{err}</span>
                        ))}
                    </div>
                    : null
                }
            </div>
        )
    }

    formatCategories = (values) => {
        return values.map(item => {
            const formattedCategory = {
                value: item.id,
                title: item.name,
            };
            if (item.subcategories && item.subcategories.length) {
                formattedCategory.children = this.formatCategories(item.subcategories);
            }
            return formattedCategory;
        });
    }

    ddIcon = () => {
        return (
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                 className="css-6q0nyr-Svg">
                <path
                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
        )
    }

    render(){
        if (!this.state.mounted) return <div/>;
        console.log('state: ', this.state.classLengths);
        return (
            <div className='container margin-top-lrg'>
                { this.state.changed ? <InfoAlert>You have unsaved changes</InfoAlert> : null}
                { this.state.errors.exception ?
                    <div>
                        {this.state.errors.exception.map((err, i) => (
                            <ErrorAlert key={i}>{err}</ErrorAlert>
                        ))}
                    </div>
                    : null
                }
                { this.renderErrors()}
                <form onSubmit={this.onSubmit} className='row'>
                    <div className='col-sm-12 col-md-9'>
                        <div className={`form-group ${this.state.errors.title ? 'has-error': ''}`}>
                            <label htmlFor="title">Content Title <span className="text-danger">* required</span></label>
                            <input
                                onBlur={e => {
                                    const validation = validate(
                                        { title: e.target.value },
                                        { title: constraints.title }
                                        );
                                    this.inlineValidation(validation,'title')
                                }}
                                value={this.state.title}
                                onChange={e => this.setForm({ title: e.target.value})}
                                type='text'
                                className='form-control'
                                id='title'/>
                            { this.state.errors.title ?
                                <div>
                                    {this.state.errors.title.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>


                        <div className="row">
                            <div className="col-xs-6">
                                <label>Content Type</label>
                                <div className="form-group">
                                    <select
                                        value={this.state.contentType}
                                        onChange={e=>this.setForm({contentType: parseInt(e.target.value), format: CONTENT_FORMATS.AUDIO, errors: {}})}
                                        className="form-control"
                                        disabled={this.props.active_content}>
                                        <option value=''>Select Content Type</option>
                                        {this.state.contentTypes.map(type => (
                                            <option value={type.id}>{type.type}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {this.state.contentType === CONTENT_TYPES.FEATURED ?
                                <div className="col-xs-6">
                                    <label>Format</label>
                                    <div className="form-group">
                                        <select
                                            value={this.state.format}
                                            onChange={e=>this.setForm({format: parseInt(e.target.value), errors: {}})}
                                            className='form-control'>
                                            {Object.keys(CONTENT_FORMATS).filter(key => key !== 'DEEP_LINK').map(key => (
                                                <option value={CONTENT_FORMATS[key]}>
                                                    {key.toLowerCase().replaceAll('_', ' ')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                : null
                            }

                            {this.state.contentType === CONTENT_TYPES.ORGANIZATION ?
                                <div className="col-xs-6">
                                    <label>Format</label>
                                    <div className="form-group">
                                        <select
                                            value={this.state.format}
                                            onChange={e=>this.setForm({format: parseInt(e.target.value), errors: {}})}
                                            className='form-control'>
                                            {Object.keys(CONTENT_FORMATS).map(key => (
                                                <option value={CONTENT_FORMATS[key]}>
                                                    {key.toLowerCase().replaceAll('_', ' ')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                : null
                            }
                        </div>

                        {this.state.contentType === CONTENT_TYPES.FEATURED ? this.renderFeatured() : null}

                        {this.state.contentType === CONTENT_TYPES.ORGANIZATION ? this.renderFeatured() : null}

                        {this.state.contentType === CONTENT_TYPES.FITNESS ?
                            this.renderFitness()
                            :
                            null
                        }

                        { this.state.contentType === CONTENT_TYPES.PATHWAY ?
                            <div className={`form-group ${this.state.errors.videoId ? 'has-error': ''}`}>
                                <label htmlFor="videoId">Vimeo ID <span className="text-danger">* required</span></label>
                                <input
                                    onBlur={e => {
                                        const validation = validate(
                                            { videoId: e.target.value },
                                            { videoId: optional.videoId }
                                        );
                                        this.inlineValidation(validation, 'videoId')
                                    }}
                                    value={this.state.videoId}
                                    onChange={e => this.setForm({ videoId: e.target.value})}
                                    type='text'
                                    className='form-control'
                                    id='videoId'/>
                                { this.state.errors.videoId ?
                                    <div>
                                        {this.state.errors.videoId.map((err, i) => (
                                            <span key={i} className="text-danger">{err}</span>
                                        ))}
                                    </div>
                                    : null
                                }
                            </div>
                            :
                            null
                        }

                        { this.state.contentType === CONTENT_TYPES.BLOG ?
                            <div>
                                <div className={`form-group ${this.state.errors.blog ? 'has-error': ''}`}>
                                    <label>Content <span className="text-danger">* required</span></label>
                                    { this.state.errors.blog ?
                                        <div>
                                            {this.state.errors.blog.map((err, i) => (
                                                <span key={i} className="text-danger">{err}</span>
                                            ))}
                                        </div>
                                        : null
                                    }
                                    <WYSIWYGTinyMCE
                                        onBlur={val => {
                                            const validation = validate(
                                                { blog: val },
                                                { blog: optional.blog }
                                            );
                                            this.inlineValidation(validation, 'blog')
                                        }}
                                        style={{ marginTop: '-2rem'}}
                                        value={this.state.blog}
                                        plugins ='link image code paste lists textcolor'
                                        toolbar ='undo redo | bold italic | numlist bullist | alignleft aligncenter alignright | code | forecolor backcolor'
                                        onChangeHandler={val => this.setForm({ blog: val })}/>
                                </div>

                                <div className={`form-group ${this.state.errors.pageTitle ? 'has-error': ''}`}>
                                    <label htmlFor="pageTitle">Page Title (optional)</label>
                                    <input
                                        value={this.state.pageTitle}
                                        onChange={e => this.setForm({ pageTitle: e.target.value})}
                                        type='text'
                                        className='form-control'
                                        id='pageTitle'/>
                                    { this.state.errors.pageTitle ?
                                        <div>
                                            {this.state.errors.pageTitle.map((err, i) => (
                                                <span key={i} className="text-danger">{err}</span>
                                            ))}
                                        </div>
                                        : null
                                    }
                                </div>

                                <div className={`form-group ${this.state.errors.hero ? 'has-error': ''}`}>
                                    <label>Hero Image <span className="text-danger">* required</span></label>
                                    <ImageThumbnailDetailed
                                        setImage={img => {
                                            if (img) this.inlineValidation(null, 'hero');
                                            this.setForm({ hero: img })
                                            }
                                        }
                                        image={this.state.hero}
                                    />
                                    { this.state.errors.hero ?
                                        <div>
                                            {this.state.errors.hero.map((err, i) => (
                                                <span key={i} className="text-danger">{err}</span>
                                            ))}
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                            :
                            null
                        }

                        { this.state.contentType === CONTENT_TYPES.FILE ?
                            <div className={`form-group ${this.state.errors.file ? 'has-error': ''}`}>
                                <label>File Upload <span className="text-danger">* required</span></label>
                                <FileUpload
                                    file={this.state.file}
                                    changeFile={val => this.setForm({ file: val })}
                                />
                                { this.state.errors.file ?
                                    <div>
                                        {this.state.errors.file.map((err, i) => (
                                            <span key={i} className="text-danger">{err}</span>
                                        ))}
                                    </div>
                                    : null
                                }
                            </div>
                            :
                            null
                        }

                        { this.state.contentType === CONTENT_TYPES.LINK ?
                            <div className={`form-group ${this.state.errors.link ? 'has-error': ''}`}>
                                <label htmlFor="link">Vimeo ID <span className="text-danger">* required</span></label>
                                <input
                                    onBlur={e => {
                                        const validation = validate(
                                            { link: e.target.value },
                                            { link: optional.link }
                                        );
                                        this.inlineValidation(validation, 'link')
                                    }}
                                    value={this.state.link}
                                    onChange={e => this.setForm({ link: e.target.value})}
                                    type='text'
                                    className='form-control'
                                    id='link'/>
                                { this.state.errors.link ?
                                    <div>
                                        {this.state.errors.link.map((err, i) => (
                                            <span key={i} className="text-danger">{err}</span>
                                        ))}
                                    </div>
                                    : null
                                }
                            </div>
                            :
                            null
                        }

                        <div className={`form-group ${this.state.errors.description ? 'has-error': ''}`}>
                            <label htmlFor="description">Short Description (optional)</label>
                            <WYSIWYGTinyMCE
                                style={{ marginTop: '-2rem'}}
                                value={this.state.description}
                                plugins ='link image code paste lists textcolor'
                                toolbar ='undo redo | bold italic | numlist bullist | alignleft aligncenter alignright | code | forecolor backcolor'
                                onChangeHandler={val => this.setForm({ description: val })}/>
                            { this.state.errors.description ?
                                <div>
                                    {this.state.errors.description.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>

                        <div className='form-group'>
                            <label htmlFor='thumbnail'>Content Thumbnail</label>
                            <ImageThumbnailDetailed
                                setImage={img => this.setForm({ thumbnail: img })}
                                image={this.state.thumbnail}
                            />
                            { this.state.errors.thumbnail ?
                                <div>
                                    {this.state.errors.thumbnail.map((err, i) => (
                                        <span key={i} className="text-danger">{err}</span>
                                    ))}
                                </div>
                                : null
                            }
                        </div>
                        <div className="row">
                            <div className='col-sm-12 col-md-6'>
                                <label>Category <span className="text-danger">* required</span></label>
                                <TreeSelect
                                    treeDefaultExpandAll
                                    style={{ width: '100%' }}
                                    value={this.state.category}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Select Category"
                                    onChange={val => this.categoryChange(val)}
                                    treeData={this.formatCategories(this.state.categories)}
                                    suffixIcon={this.ddIcon()}
                                />
                                { this.state.errors.category ?
                                    <div>
                                        {this.state.errors.category.map((err, i) => (
                                            <span key={i} className="text-danger">{err}</span>
                                        ))}
                                    </div>
                                    : null
                                }
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <ReactCreatableSelect
                                    name='tags'
                                    label='Tags'
                                    options={this.state.tags}
                                    multi={true}
                                    onChange={val => {
                                        this.setForm({ selectedTags: val})
                                    }}
                                    value={this.state.selectedTags}
                                />
                            </div>
                            {this.state.contentType === CONTENT_TYPES.PATHWAY ?
                                <div className='col-sm-12 col-md-6'>
                                    <label style={{ display: 'flex', marginTop: 10 }}>
                                        <input
                                            style={{ flex: .1, maxWidth: 20 }}
                                            id='overview_checkbox'
                                            type='checkbox'
                                            name={'overview_checkbox'}
                                            checked={this.state.overview}
                                            onChange={this.checkOverview}
                                            disabled={this.state.status && this.state.status !== CONTENT_STATUSES.PUBLISHED}
                                        /> Mark as Overview

                                    </label>
                                </div>
                                :null
                            }
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-3'>
                        <div className='form-group'>
                            <label>Status</label>
                            <select value={this.state.status} onChange={e => this.setForm({status: parseInt(e.target.value)})} className="form-control">
                                {Object.keys(CONTENT_STATUSES).map(key => {
                                    if (this.state.currentlySavedStatus === CONTENT_STATUSES.PUBLISHED && key === 'SCHEDULED') {
                                        return null
                                    }
                                    return <option value={CONTENT_STATUSES[key]}>{key.toLowerCase()}</option>;
                                })}
                            </select>
                        </div>
                        {this.state.status === CONTENT_STATUSES.SCHEDULED && this.state.currentlySavedStatus !== CONTENT_STATUSES.PUBLISHED ?
                            <div className='form-group'>
                                <label>Date</label>
                                <div className={styles.dateContainer}>
                                    <input
                                        onChange={e => this.changeScheduleDate(e.target.value)}
                                        value={this.state.scheduleDate.date}
                                        min={format(new Date(), 'yyyy-MM-dd')}
                                        className={`form-control ${styles.date} ${this.state.errors.schedule ? 'has-error': ''}`}
                                        type="date"/>
                                    <select
                                        onChange={e => this.setState({scheduleDate: {...this.state.scheduleDate, hour: e.target.value}})}
                                        value={this.state.scheduleDate.hour}
                                        className={`form-control ${styles.hour} ${this.state.errors.schedule ? 'has-error': ''}`}>
                                        {this.renderHourOptions().map(val => <option value={val}>{val}</option>)}
                                    </select>
                                    <select
                                        onChange={e => this.setState({scheduleDate: {...this.state.scheduleDate, midday: e.target.value}})}
                                        value={this.state.scheduleDate.midday}
                                        className={`form-control ${styles.midday} ${this.state.errors.schedule ? 'has-error': ''}`}>
                                        {this.renderMidday().map(val => <option value={val}>{val}</option>)}
                                    </select>
                                </div>
                            </div>
                            : null
                        }

                        <div className='form-group form-inline'>
                            <label>Author</label>
                            <input
                                type='text'
                                readOnly
                                value={this.props.user ? `${this.props.user.first_name} ${this.props.user.last_name}` : null}
                                name='author'
                            />
                        </div>
                    </div>

                    <div className='col-sm-12 margin-top d:f a-i:c'>
                        <button type='submit' className='btn primary m-r:1'>Save Changes</button>
                        { this.state.saving ? <SaveAlert complete={this.state.saved}/> : null }
                        { this.state.errors && !isEmpty(this.state.errors) ?
                            <WarningAlert complete completedText='Please correct the errors above'/>
                            : null
                        }
                    </div>
                </form>
                { this.state.currentOverview ?
                    <Modal show={this.state.overviewModal} onHide={this.hideOverviewModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Overview Video Change</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <strong>"{this.state.currentOverview.title}"</strong> is currently set as the overview video for this category.
                            </p>
                            <p>
                                Would you like to make your current video the overview instead?
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <DangerButton onClick={this.hideOverviewModal} className='m-r:1'>NO</DangerButton>
                            <PrimaryButton
                                onClick={() => this.setForm({ currentOverview: null, overview: !this.state.overview })}
                            >YES</PrimaryButton>
                        </Modal.Footer>
                    </Modal>
                    : null
                }
            </div>
        )
    }
}

function mapStateToProps({user, active_content}){
    return{user, active_content}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({setActiveContent, clearActiveContent}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentCreatorForm);

function ReactCreatableSelect({options, label, value, onChange, multi}) {
    const handleChange = (newValue, actionMeta) => {
        if (!onChange || typeof onChange !== 'function') return;
        if(newValue) {
            onChange(newValue);
        } else { onChange([])}
    };

    return (
        <div className={styles.createableSelect}>
            <label>{label}</label>
            <CreatableSelect
                isMulti={multi}
                value={value}
                isClearable
                onChange={handleChange}
                options={options.map(option => ({value:option.value, label:option.name}))}
            />
        </div>
    );
}
// eslint-disable-next-line
const categoryStyles = {
    control: styles => ({ ...styles, backgroundColor: '#fff' }),
    option: (styles, data) => {
        return {
            ...styles,
            marginLeft: data.options.filter(item => item.label === data.label).length ? 0 : 20,
        };
    },
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
};