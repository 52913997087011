import mainApi from "./mainApi";

export function policyApi() {
    return {
        // check if a member is in compliance with all policies
        memberCompliance: function (username) {
            return mainApi.get(`/policy/fullcompliance/user/${username}`);
        },
        // get active policies for the app
        getActivePolicies: function() {
            return mainApi.get('/policy/policies/active');
        },
        // get a version of a policy
        getPolicyVersion: function(versionId) {
            return mainApi.get(`/policy/version/${versionId}`)
        },
        // accept a policy
        acceptPolicy: function (policyId, versionId, username) {
            return mainApi.put(`/policy/${policyId}/version/${versionId}/user/${username}/optin`)
        }
    }
}