import React, {Component} from "react";


class PlanList extends Component{
    constructor(props){
        super(props);
        this.createPlanList = this.createPlanList.bind(this);
    }


    createPlanList(){
        if(this.props.plans){
            return this.props.plans.map((item)=>{
                return(
                    <li onClick={()=>{this.props.updateValue(item.id)}}>{item.description}</li>
                )
            })
        }
    }

    render(){
        let categoryClass = 'noCategory';
        if(this.props.category){
            if (this.props.category === 'what you do') {
                categoryClass = 'wydBG';
            } else if (this.props.category === 'what you eat') {
                categoryClass = 'wyeBG';
            } else if (this.props.category === 'when you eat') {
                categoryClass = 'whenyeBG';
            }
        }
        let tileDescription;
        let tileIcon;
        if(this.props.plans)tileDescription = this.props.plans[0].description;
        if(this.props.plans)tileIcon = tileDescription.replace(/\s/g, '').toLowerCase();
        console.log(tileIcon)
        
        return(
            <div className={"add-activity-overlay " + categoryClass}> 
                <div className="container flex-center-vertically text-center">
                    <div className='close-button' onClick={()=>{this.props.goBackToTile()}}>
                        &times;                    
                    </div>
                    <div className='row'>
                        <div className="tile-icon-add-activity">
                            <div className={"the--icon icon-" + tileIcon}>
                                    {/*<object type="image/svg+xml" data={require('../../../images/icon-' + tileIcon + '.svg')} class="the-icon">
                                  Fallback
                                </object>*/}                                        
                            </div>
                        </div>
                        <h2>{this.props.category ? this.props.category:null}</h2>
                        <h3>Select Your Activity</h3>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <ul className='multiple-activities'>
                                {this.createPlanList()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default PlanList;