import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

// styles
import styles from './feed.module.css';

// actions
import {
    fetchCommunityNotifications,
    fetchVictoryFeed,
    victoryReaction
} from "../../../store/actions/communityActions";

// components
import VictoryFeed from "../victoryFeed/victoryFeed";
import {ToggleButtons} from "../../buttons/buttons";

const Feed = props => {
    // props
    const {user, community, setCommunity} = props;
    // local
    const [victories, setVictories] = useState(null);
    const [reachedEnd, setReachedEnd] = useState(false);
    const org = useRef(community);

    // get reactions & victories
    useEffect(() => {
        if (community.all) {
            addVictories();
        }
        if (community.org) {
            addVictories({orgOnly: true});
        }
        org.current = community;
    }, [community]);

    async function addVictories(val={}) {
        const {orgOnly, oldVictories} = val
        try{
            let result;
            if (oldVictories) {
                result = await fetchVictoryFeed(orgOnly, oldVictories[oldVictories.length-1].victory.id);
                if (!result.length) setReachedEnd(true);
                setVictories([...oldVictories, ...result]);
            } else {
                result = await fetchVictoryFeed(orgOnly);
                if (!result.length) setReachedEnd(true);
                setVictories(result);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const toggleOptions = [
        { label: user.organization.community_name, value:'org', onClick: () => setCommunity({all: false, org: true}) },
        { label: 'All Members', value: 'all', onClick: () => setCommunity({all: true, org: false}) },
    ];
    const {community_all, community_org} = user.organization;

    return (
        <div className='padding-small'>
            {community_all && community_org ?
                <div className={styles.toggleContainer} data-cy="toggleCommunity">
                    <ToggleButtons
                        startOption={community.org ? 1 : 2}
                        className={styles.toggle}
                        activeColor='#272727'
                        options={toggleOptions}/>
                </div>
                : null
            }
            <div className={styles.victoriesContainer}>
                <p className={styles.label}>Catch up with others</p>
                <VictoryFeed
                    setReaction={victoryReaction}
                    fetchVictories={(oldVictories) => addVictories({orgOnly: org.current.org, oldVictories})}
                    victories={victories}
                    reachedEnd={reachedEnd}/>
            </div>
        </div>
    )
}

function mapStateToProps({communityNotifications, user}) {
    return {communityNotifications, user};
}

const actions = {
    fetchCommunityNotifications,
};
export default connect(mapStateToProps, actions)(Feed);