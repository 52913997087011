import React, { useState, useEffect } from "react";
import FileDownload from "js-file-download";
import { Table } from "react-bootstrap";

// styles
import styles from "./MemberAssessmentList.module.css";

// components
import { GraphPlaceholder } from "../../../graphPlaceholder/graphPlaceholder";
import { PrimaryButtonSm } from "../../../buttons/buttons";
import { SecondaryInfoAlert } from "../../../alerts/alerts";
import {
  fetchNonAssessmentListMembersExport,
  fetchOptionalAssessmentAmountTaken,
  fetchOptionalAssessmentListMembers,
} from "../../../../store/actions/analyticsActions";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

const MemberAssessmentList = (props) => {
  const { filters, date, query, setQuery, coaches } = props;

  function getCoachName(coachId, coaches) {
    const selectedCoach = coaches.find(coach => coach.value === coachId);
    return selectedCoach ? selectedCoach.name : "Unknown Coach";
  }


  // local
  const [showList, setShowList] = useState(false);
  const [data, setData] = useState([]);
  const [completedAssessment, setCompletedAssessment] = useState(null);
  const [inCompletedAssessment, setInCompletedAssessment] = useState(null);

  const [exporting, setIsExporting] = useState(false);
  

  // on first render
  useEffect(() => {
    runQuery();
  }, [query]);

  function runQuery() {
    loadExportList();
    getAssessmentTaken();
  }

  useDidMountEffect(() => {
    const newQuery = {
      start: date.start,
      end: date.end,
      organizationId: filters.organization,
      statusId: filters.status,
      gender: filters.gender,
      ageGroup: filters.ageGroup,
      coach: filters.coach,
      rank: filters.rank,
    };
    setQuery(newQuery);
  }, [date, filters]);

  function exportMembers() {
    setIsExporting(true);
    const inputDate = new Date(date.start);
    const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = inputDate
      .getUTCDate()
      .toString()
      .padStart(2, "0");
    const year = inputDate.getUTCFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    fetchNonAssessmentListMembersExport(query)
      .then((response) => {
        console.log(filters.coach);
    
        // Use the getCoachName function to get the coach's name
        const coachName = getCoachName(filters.coach, coaches);
    
        let file_name = "";
        if (filters.coach === undefined || coachName === "Select a Coach") {
          file_name = "incomplete assessment" + " " + formattedDate + ".csv";
        } else {
          file_name = `${coachName} incomplete assessment ${date.start}.csv`;
        }
    
        FileDownload(response, file_name);
      })
      .catch(() => setIsExporting(false));
  }

  async function loadExportList() {
    try {
      const list = await fetchOptionalAssessmentListMembers(query);
      console.log(list);
      setData(list);
    } catch (e) {
      console.log(e);
    }
  }

  const getAssessmentTaken = async () => {
    try {
      const amount = await fetchOptionalAssessmentAmountTaken(query);
      if (amount) {
        setCompletedAssessment(amount.complete_assessments);
        setInCompletedAssessment(amount.incomplete_assessments);
      }
      return;
    } catch (e) {
      console.log(e);
    }
  };

  function renderList() {
    if (showList) {
      return (
        <div className={styles.table}>
          <h4>Not Completed Assessments</h4>
          <Table striped bordered hover size="sm">
            <thead className="hidden-xs hidden-sm">
              <tr>
                <th>Member Name</th>
              </tr>
            </thead>
            <tbody>
              {data.map((i) => (
                <tr key={i.name}>
                  <td
                    className={styles.name}
                  >{`${i.first_name} ${i.last_name}`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {showList.length > 500 ? (
            <SecondaryInfoAlert>
              This list contains over 500 members. Please click the Export
              button to see the full list.
            </SecondaryInfoAlert>
          ) : null}
        </div>
      );
    }
  }

  if (!data) {
    return (
      <div className={styles.container}>
        <GraphPlaceholder />
      </div>
    );
  }

  return (
    <div className={styles.containerResponsive}>
      <div className={styles.header}>
        <div>
          <div className={styles.displayFlexLabel}>
            <h4>Completed Assessments: </h4>{" "}
            <h7 className={styles.innerAssessmentLabel}>
              {completedAssessment}
            </h7>
          </div>
          <div className={styles.displayFlexLabel}>
            <h4>Incompleted Assessments: </h4>
            <h7 className={styles.innerAssessmentLabel}>
              {inCompletedAssessment}
            </h7>
          </div>
          <button
            className={styles.listButton}
            onClick={() => setShowList(!showList)}
            tabIndex={0}
            type="button"
          >
            {showList ? "Hide List" : "See List of Incomplete"}
          </button>
        </div>
        <PrimaryButtonSm
          disabled={exporting}
          onClick={exportMembers}
          className={styles.exportButton}
        >
          Export
          <i className="fa-solid fa-file-export" />
        </PrimaryButtonSm>
      </div>
      {renderList()}
    </div>
  );
};

export default MemberAssessmentList;
