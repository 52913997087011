import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import axios from 'axios';

// styles
import styles from './clientSortAndFilter.module.css';

const ClientSortAndFilter = forwardRef((props, ref) => {
    // props
    const { callback, organization } = props;

    // sort states
    const [ wellness, setWellness ] = useState(null);
    const [ lastName, setLastName ] = useState(null);
    const [ lastCommunication, setLastCommunication ] = useState(null);
    const [ lastActivity, setLastActivity ] = useState(null);

    // filter states
    const [ frequencyFilter, setFrequencyFilter ] = useState(null);
    const [ statusFilter, setStatusFilter ] = useState(null);
    const [ statuses, setStatuses ] = useState([]);
    const [ orgFilter, setOrgFilter ] = useState(null);

    useImperativeHandle(ref, () => ({
        resetEverything(){
            resetSorts();
            resetFilters();
        }
    }));

    function runQuery() {
        let query = `sort=`;
        if (wellness) query += `${wellness}&`;
        if (lastName) query += `${lastName}&`;
        if (lastCommunication) query += `${lastCommunication}&`;
        if (lastActivity) query += `${lastActivity}&`;
        if (frequencyFilter) query += `&filter=${frequencyFilter}`;
        if (statusFilter) query += `&filter=${statusFilter}`;
        if (orgFilter) query += `&organization=${orgFilter}`
        if (callback) callback(query);
    }

    function resetSorts() {
        setWellness(null);
        setLastName(null);
        setLastActivity(null);
        setLastCommunication(null);
    }

    function resetFilters() {
        setStatusFilter(null);
        setFrequencyFilter(null);
        setOrgFilter(null);
    }

    function sortWellnessScore(val) {
        resetSorts();
        setWellness(val);
    }

    function sortLastName(val) {
        resetSorts();
        setLastName(val);
    }

    function sortLastActivity(val) {
        resetSorts();
        setLastActivity(val);
    }

    function sortLastCommunication(val) {
        resetSorts();
        setLastCommunication(val);
    }

    function filterByStatus(val) {
        //resetFilters();
        setStatusFilter(val);
    }

    function filterByAContactFrequency(val) {
        //resetFilters();
        setFrequencyFilter(val);
    }

    function filterByOrganization(val) {
        //resetFilters();
        setOrgFilter(val);
    }

    function openDropdowns(prefix) {
        const heading = document.getElementById(`${prefix}-heading`);
        const group = document.getElementById(`${prefix}-group`);
        const container = document.getElementById(`${prefix}-container`);

        if (window.innerWidth > 991) {
            heading.classList.remove(styles.open);
            group.classList.remove(styles.open);
            container.style.maxHeight = null;
            return;
        }

        heading.classList.toggle(styles.open);
        group.classList.toggle(styles.open);

        if (container.offsetHeight > 0) {
            container.style.maxHeight = '0';
        } else {
            let maxHeight = 0;
            const children = container.children;
            for (let child of children) {
                const height = child.clientHeight;
                maxHeight += height;
            }
            container.style.maxHeight = `${maxHeight}px`;
        }
    }

    function removeDropdowns() {
        const heading = document.querySelectorAll('[id$="-heading"]');
        const group = document.querySelectorAll('[id$="-group"]');
        const container = document.querySelectorAll('[id$="-container"]');

        if (window.innerWidth > 991) {
            for (let head of heading) {
                head.classList.remove(styles.open);
            }
            for (let g of group) {
                g.classList.remove(styles.open);
            }
            for (let c of container) {
                c.style.maxHeight = null;
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', removeDropdowns);
        return () => {
            window.removeEventListener('resize', removeDropdowns);
        }
    }, []);

    useEffect(() => {
        if (wellness !== null) runQuery();
    }, [wellness]);

    useEffect(() => {
        if (lastName !== null) runQuery();
    }, [lastName]);

    useEffect(() => {
        if (lastActivity !== null) runQuery();
    }, [lastActivity]);

    useEffect(() => {
        if (lastCommunication !== null) runQuery();
    }, [lastCommunication]);

    useEffect(() => {
        if (statusFilter !== null) runQuery();
    }, [statusFilter]);

    useEffect(() => {
        if (frequencyFilter !== null) runQuery();
    }, [frequencyFilter]);

    useEffect(() => {
        if (orgFilter !== null) runQuery();
    }, [orgFilter]);

    // grab statuses on mount
    useEffect(() => {
        axios.get('/user/statuses')
            .then(({ data }) => setStatuses(data.result));
    }, []);

    return (
        <div className={props.className}>
            <div id='filter-heading' className={styles.selectContainer}>
                    <span className={styles.selectHeading}>
                        <span>Filter Results:</span>
                        <i onClick={()=>openDropdowns('filter')} className={`fas fa-chevron-down`}/>
                    </span>

                <div id='filter-container' className={styles.dropdowns}>
                    <div id='filter-group' className={styles.group}>
                        <div className={styles.singleSelect}>
                            <label className={styles.selectLabel} htmlFor="contactFrequency">Contact Frequency</label>
                            <select
                                value={frequencyFilter || ''}
                                onChange={e => filterByAContactFrequency(e.target.value)}
                                className={styles.selectButton} id="contactFrequency">
                                <option value="">Select An Option</option>
                                <option value={`contactFrequency:1`}>Daily</option>
                                <option value={`contactFrequency:2`}>Every Other Day</option>
                                <option value={`contactFrequency:3`}>Weekly</option>
                                <option value={`contactFrequency:4`}>Monthly</option>
                            </select>
                        </div>
                        <div className={styles.singleSelect}>
                            <label className={styles.selectLabel} htmlFor="accountStatus">Account Status</label>
                            <select
                                value={statusFilter || ''}
                                onChange={e => filterByStatus(e.target.value)}
                                className={styles.selectButton} id="accountStatus">
                                <option value="">Select Account Status</option>
                                { statuses.map(status => <option key={status.id} value={`user_status_id:${status.id}`}>{status.label}</option>)}
                            </select>
                        </div>
                        { organization ?
                            <div className={styles.singleSelect}>
                                <label className={styles.selectLabel} htmlFor="accountStatus">Organization</label>
                                <select
                                    value={orgFilter || ''}
                                    onChange={e => filterByOrganization(e.target.value)}
                                    className={styles.selectButton} id="accountStatus">
                                    <option value="">Select Organization</option>
                                    { organization.map(org => <option key={org.id} value={org.id}>{org.name}</option>)}
                                </select>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>

            <div id='sort-heading' className={styles.selectContainer}>
                    <span className={styles.selectHeading}>
                        <span>Sort Results:</span>
                        <i onClick={()=>openDropdowns('sort')} className={`fas fa-chevron-down`}/>
                    </span>

                <div id="sort-container" className={styles.dropdowns}>
                    <div id='sort-group' className={styles.group}>
                        <div className={styles.singleSelect}>
                            <label className={styles.selectLabel} htmlFor="wellnessScore">Wellness Score</label>
                            <select
                                value={wellness || ''}
                                onChange={e => sortWellnessScore(e.target.value)}
                                className={styles.selectButton} id="wellnessScore">
                                <option value="">Select An Option</option>
                                <option value="wellnessScore:DESC">Daily Score (100-0)</option>
                                <option value="wellnessScore:ASC">Daily Score (0-100)</option>
                                <option value="wellnessWeekly:ASC">Weekly Score (0-100)</option>
                                <option value="wellnessWeekly:DESC">Weekly Score (100-0)</option>
                                <option value="wellnessMonthly:ASC">Monthly Score (0-100)</option>
                                <option value="wellnessMonthly:DESC">Monthly Score (100-0)</option>
                            </select>
                        </div>
                        <div className={styles.singleSelect}>
                            <label className={styles.selectLabel} htmlFor="lastActivity">Last Activity</label>
                            <select
                                value={lastActivity || ''}
                                onChange={e => sortLastActivity(e.target.value)}
                                className={styles.selectButton} id="lastActivity">
                                <option value="">Select An Option</option>
                                <option value="lastActivity:DEsC">Last Activity (Newest First)</option>
                                <option value="lastActivity:ASC">Last Activity (Oldest First)</option>
                            </select>
                        </div>
                        <div className={styles.singleSelect}>
                            <label className={styles.selectLabel} htmlFor="lastCommunication">Last Communication</label>
                            <select
                                value={lastCommunication || ''}
                                onChange={e => sortLastCommunication(e.target.value)}
                                className={styles.selectButton} id="lastCommunication">
                                <option value="">Select An Option</option>
                                <option value="lastCommunication:DESC">Communication (Newest)</option>
                                <option value="lastCommunication:ASC">Communication (Oldest)</option>
                            </select>
                        </div>
                        <div className={styles.singleSelect}>
                            <label className={styles.selectLabel} htmlFor="lastName">Last Name</label>
                            <select
                                value={lastName || ''}
                                onChange={e => sortLastName(e.target.value)}
                                className={styles.selectButton} id="lastName">
                                <option value="">Select An Option</option>
                                <option value="lastName:ASC">Last Name (a-z)</option>
                                <option value="lastName:DESC">Last Name (z-a)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default ClientSortAndFilter;