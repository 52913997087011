// npm modules
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';

// styles
import styles from './assessmentList.module.css';

const AssessmentList = ({ list=[], setDefault, showDuplicate }) => {
    return (
        <div>
            <Table striped bordered hover size="sm">
                <thead class="tile-header hidden-xs hidden-sm">
                <tr>
                    <th >Title</th>
                    <th>Status</th>
                    <th>Author</th>
                    <th>Last Edited</th>
                </tr>
                </thead>
                <tbody>
                { list.map(i => (
                    <tr key={i.id}>
                        <TitleCell listItem={i} setDefault={setDefault} showDuplicate={showDuplicate}/>
                        <td>{i.status}</td>
                        <td>{`${i.user.firstName} ${i.user.lastName}`}</td>
                        <td>{i.createdAt ? moment(i.createdAt).format('LL') : null}</td>
                    </tr>
                ) )}
                </tbody>
            </Table>

        </div>
    )
};

export default AssessmentList;

AssessmentList.propTypes = {
    list: PropTypes.array.isRequired,
    setDefault: PropTypes.func.isRequired,
    showDuplicate: PropTypes.func.isRequired,
};

const TitleCell = ({ listItem, setDefault, showDuplicate }) => {
    const [show, setShow] = useState(false);
    const isDefault = (listItem.group && listItem.group.id === 1);
    return (
        <td>
            <div onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
                { isDefault ? <i className="fas fa-star"/> : null }
                <Link to={`/dashboard/Assessments/modify/${listItem.id}`}>{listItem.title}</Link>
                <p className={`${styles.setDefault} ${show ? styles.show : ''}`}>
                    <span onClick={()=>setDefault(listItem)} >Set as default</span> &nbsp; &nbsp; &nbsp;
                    <span onClick={()=>showDuplicate(listItem)}>Duplicate</span>
                </p>
            </div>
        </td>
    )
};