import React from 'react';

const CreateHeader =()=>{
    return(
        <div className='page-header-container searchForm-container'>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <nav>
                          <ol className="breadcrumb ">
                            <li className="breadcrumb-item underline "><a href="/dashboard" class="text-light">Dashboard</a></li><span className="arrow"></span>
                            <li className="breadcrumb-item underline "><a href="/dashboard/Content" class="text-light">Content</a></li><span className="arrow"></span> 
                            <li className="breadcrumb-item text-light active">Add New Content</li>  
                          </ol>
                        </nav>
                    </div>
                    <div className="col-sm-12">
                        <h1>Add New Content</h1>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CreateHeader;