import React from 'react';
import {withRouter, Route} from 'react-router-dom';
import ContentCreator from './contentCreateContainer';
import TagsContainer from "../../tags/tagsContainer/tagsContainer";
import Categories from "../../categories/categories";
import ContentSearch from "../../contentSearch/contentSearch";
import Instructors from "../../contentMeta/instructors/instructors";
import Equipment from "../../contentMeta/equipment/equipment";
import ClassTypes from "../../contentMeta/classTypes/classTypes";
import ContentScheduler from "../../contentScheduler/contentScheduler";
import ClassofDayContentSchedular from "../../contentScheduler/ClassofDayContentSchedular";

const contentRoutes =(props)=>{
    return(
        <div style={{ marginBottom: '-7rem'}}>
            <Route path={props.match.url} exact component={ContentSearch}/>
            <Route path={props.match.url + '/new'} render={()=><ContentCreator match={props.match}/>}/>
            <Route path={`${props.match.url}/type/:type`} component={Categories}/>
            <Route path={`${props.match.url}/tags/:type`} component={TagsContainer}/>
            <Route path={`${props.match.url}/instructors`} component={Instructors}/>
            <Route path={`${props.match.url}/equipment`} component={Equipment}/>
            <Route path={`${props.match.url}/class-types`} component={ClassTypes}/>
            <Route path={`${props.match.url}/schedule`} component={ContentScheduler}/>
            <Route path={`${props.match.url}/scheduleClassofDay`} component={ClassofDayContentSchedular}/>

        </div>
    );
}

export default withRouter(contentRoutes);