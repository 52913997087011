import React, {useState, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// styles
import styles from './myVictories.module.css';

// actions
import {
    fetchCommunityNotifications,
    fetchReactionFeed, setReactionsSeen
} from "../../../store/actions/communityActions";

// component
import {ReactComponent as Chevron} from "../../../images/chevron-right-solid.svg";
import ReactionFeed from "../reactionsFeed/reactionFeed";
import MyVictoryFeed from "./myVictoryFeed/myVictoryFeed";

const LIMIT = 3;

const MyVictories = props => {
    // actions
    const {fetchCommunityNotifications} = props;
    // state
    const {communityNotifications} = props;
    // local

    const container = useRef();
    const [reactions, setReactions] = useState(null);
    const [height, setHeight] = useState(0);

    // update the number of community notifications on first render
    useEffect(() => {
        loadNotifications();
        addReactions();
    }, []);

    // keep the victories' height flush with the size of the window
    useEffect(() => {
        reSize();
        window.addEventListener('resize', reSize);
        return () => window.removeEventListener('resize', reSize);
    }, []);

    function reSize() {
        if (container.current) {
            const bottomNavHeight = 45;
            const topOffset = container.current.offsetTop;
            const calculatedHeight = window.innerHeight - topOffset - bottomNavHeight;
            setHeight(calculatedHeight);
        }
    }

    async function addReactions() {
        try {
            let result = await fetchReactionFeed(null, LIMIT);
            setReactions(result.reactions);
            const ids = result.reactions.map(res => res.reaction_id);
            await setReactionsSeen(ids);
            loadNotifications();
        } catch (e) {
            console.log(e);
        }
    }

    function loadNotifications() {
        try {
            fetchCommunityNotifications();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div ref={container} style={{height: `${height}px`}} className={styles.container}>
            <p className={styles.label2}>
                Reactions
                <Link to='/member/view-reactions' className={`${styles.more} ${!communityNotifications ? styles.noMore : ''}`}>
                    { communityNotifications ? <span>{communityNotifications}</span> : ''}
                    <Chevron/>
                </Link>
            </p>
            <div className={styles.reactionsFeed} data-cy="myVicReactions">
                <ReactionFeed reactions={reactions}/>
            </div>
            <div className={styles.divider}/>
            <MyVictoryFeed/>
        </div>
    )
}

function mapStateToProps({communityNotifications}) {
    return {communityNotifications}
}

const actions = {
    fetchCommunityNotifications,
}

export default connect(mapStateToProps, actions)(MyVictories);