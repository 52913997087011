import { authApi } from "../../api/authApi";
import { userApi } from "../../api/userApi";
import { wellnessScoreApi } from "../../api/wellnessScoreApi";
import {gameApi} from "../../api/gameApi";
import {USER_AUTH, APP_READY, DAILY_SCORE, SESSION, GET_BADGES, SET_ALERT_OPTIONS} from "../storeConstants";
import { FETCH_SELF } from "../../actions/action_user";

export const login = (email, password) => {
    return async dispatch => {
        const authRes = await authApi().login(email, password);
        if (authRes.data) {
            const userRes = await userApi().getUser();
            if (userRes.data.user.permission_id === 1) {
                const badges = await gameApi().getBadges()
                    .catch(e => {});
                const alertRes = await userApi().getAlertOptions()
                    .catch(e => {});
                const scoreRes = await wellnessScoreApi().getDailyScore();
                dispatch({ type: DAILY_SCORE, payload: scoreRes.data.results.score})
                if (badges) dispatch({type: GET_BADGES, payload: badges.data.result});
                if (alertRes) dispatch({type: SET_ALERT_OPTIONS, payload: alertRes.data});
            }

            dispatch({ type: USER_AUTH, payload: true });
            dispatch({ type: FETCH_SELF, payload: userRes });
            dispatch({ type: APP_READY, payload: true });
        }
    }
}

export const checkLogin = () => {
    return async dispatch => {
        try {
            const authRes = await authApi().checkLogin();

            if (authRes.data) {
                const userRes = await userApi().getUser();
                if (userRes.data.user.permission_id === 1) {
                    const scoreRes = await wellnessScoreApi().getDailyScore();
                    const badges = await gameApi().getBadges()
                        .catch(e => {});
                    const alertRes = await userApi().getAlertOptions()
                        .catch(e => {});
                    dispatch({ type: DAILY_SCORE, payload: scoreRes.data.results.score})
                    if (badges) dispatch({type: GET_BADGES, payload: badges.data.result});
                    if (alertRes) dispatch({type: SET_ALERT_OPTIONS, payload: alertRes.data});
                }

                dispatch({ type: USER_AUTH, payload: true });
                dispatch({ type: FETCH_SELF, payload: userRes });
                dispatch({ type: APP_READY, payload: true });
                return authRes;
            } else {
                dispatch({ type: APP_READY, payload: true });
            }
        } catch (e) {
            dispatch({ type: APP_READY, payload: true });
        }
    }
}

export const logout = () => {
    return async dispatch => {
        const res = await authApi().logout();
        dispatch({ type: USER_AUTH, payload: false });
        dispatch({ type: APP_READY, payload: false });
        dispatch({ type: FETCH_SELF, payload: null });
        dispatch({ type: DAILY_SCORE, payload: 0});
        return res;
    }
}

export const registerSession = (seconds) => {
    return async (dispatch, getState) => {
        const {user, sessionKey} = getState();
        // don't report session time of demo members
        if (user.demo_member) return;
        // create new session key if not present
        if (!sessionKey) {
            const key = `${user.username}-${new Date().getTime()}`;
            dispatch({type: SESSION, payload: key});
            return authApi().recordSession(seconds, key);
        } else if (seconds !== 0) {
            // only log session start once
            return authApi().recordSession(seconds, sessionKey);
        } else {
            return null;
        }
    }
}
