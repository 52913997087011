import React, {useState} from 'react';

// styles
import styles from './section_1.module.css';

// components
import VeggiePlate from '../../../images/veggie_plate.jpg';
import {PrimaryButton} from "../../buttons/buttons";

const Section_1_Intro = props => {
    // props
    const {next} = props;
    // local
    const [ready, setReady] = useState(false);

    return (
        <div className={`${ready ? styles.fadeIn : ''} ${styles.container}`}>
            <h2 className={styles.heading}>THE <strong>FOODS</strong> YOU EAT</h2>
            <img onLoad={() => setReady(true)} className={styles.image} src={VeggiePlate} alt="" data-cy="imgFood"/>
            <p className={styles.text}>No judgement here! Let’s learn about your current food choices and what you want to focus on.</p>
            <div className="t-a:c">
                <PrimaryButton onClick={next} data-cy="nextBtn">Next</PrimaryButton>
            </div>
        </div>
    )
}
export default Section_1_Intro;