//client detail view
import React from 'react';
import {withRouter} from 'react-router-dom';
import {HeaderCoach} from '../headerCoach';
import SubHeader from './subHeader';
import ClientDisplay from '../ClientDisplay/ClientDisplay'

class ClientDetailMain extends React.Component {

    render() {
        return(
            <div>
                <HeaderCoach />
                <SubHeader username={this.props.match.params.username} />
                <ClientDisplay match={this.props.match} username={this.props.match.params.username}/>
            </div>
        );
    }
}

export default withRouter(ClientDetailMain);