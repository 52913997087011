import React, {Component} from 'react';
import { Editor } from '@tinymce/tinymce-react';

//props
//value > string
//toolbar > string
//plugins > string
//onChangeHandler > function
class WYSIWYGTinyMCE extends Component{
    constructor(props){
        super(props);
        this.state = {
            editor : null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.addContent && prevProps.addContent !== this.props.addContent){
            this.state.editor.editorManager.activeEditor.insertContent(this.props.addContent);
        }
    }


    render(){
        return (
            <Editor
                disabled={this.props.disabled}
                inline={false}
                init={{
                    plugins : this.props.plugins,
                    toolbar : this.props.toolbar,
                    height: "480",
                    setup:editor=>{
                        this.setState({editor})
                    }
                }}
                onChange={(event) =>{
                    this.props.onChangeHandler(event.target.getContent());
                    }
                }

                onInput={(event) => {
                    this.props.onChangeHandler(event.target.getContent());
                    }
                }
                onKeyUp={(event) => {
                    this.props.onChangeHandler(this.state.editor.getContent());
                    }
                }
            />
        )
    }
}
export default WYSIWYGTinyMCE;
