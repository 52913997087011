import React, {Component} from 'react';
import ClientAddBar from './clientAddBar';
import axios from 'axios';

let custom = true;

class ClientGoalBar extends Component{
    constructor(props){
        super(props);
        this.state={
            plan_value:null,
            allCategories:[],
            descriptions:[],
        }
    }

    componentDidMount() {
        this.findCustom()
    }

    findCustom(){
        axios.get(`/tiles/categories`)
            .then((res)=>{
                this.setState({allCategories:res.data.categories})
                  this.state.allCategories.map((categories) => {
                     axios.get(`/tiles/${categories.id}/plans`)
                        .then((res)=>{
                            this.setState({ descriptions: this.state.descriptions.concat(res.data.descriptions) }, () => {
                               
                            })
                        })
                          .catch(error=>{
                            console.log(error.message);
                        })               
                 })
            })
              .catch(error=>{
                console.log(error.message);
        })        
    }

    render(){
        let categoryClass = 'noCategory';
        let description = this.props.plan.description;
        custom = this.state.descriptions.includes(description);
        if(this.props.plan){
            if (this.props.plan.tile_category === 'what you do') {
                categoryClass = 'wydBG';
            } else if (this.props.plan.tile_category === 'what you eat') {
                categoryClass = 'wyeBG';
            } else if (this.props.plan.tile_category === 'when you eat') {
                categoryClass = 'whenyeBG';
            } else if (this.props.plan.tile_category === 'introduction') {
                categoryClass = 'wydBG';           
            }
        }

        // sets tile class to pull in proper icon
        let tileDescription = this.props.plan.tile_category;
        let planDescription = this.props.plan.description;
        let tileIcon = planDescription.replace(/\s/g, '').toLowerCase();
        if(custom === false && tileDescription === 'what you do')tileIcon = `custom-physicalactivity`;
        if(custom === false && tileDescription === 'what you eat')tileIcon = `custom-veggieandfruitservings`;
        if(custom === false && tileDescription === 'when you eat')tileIcon = `custom-mealtimespacing`;
        if(tileIcon === "vegetables&fruit")tileIcon = "veggieandfruitservings";
        if(tileIcon === 'didyoufindthiscontenthelpful?') tileIcon ='doc';
        
        return(
            <div className={"add-activity-overlay " + categoryClass}> 
                <div className="container flex-center-vertically text-center">
                    <div className='close-button' onClick={()=>{this.props.goBackToTile()}}>
                        &times;                    
                    </div>
                    <div className='row'>
                        <div className="tile-icon-add-activity">
                            <div className={"the--icon icon-" + tileIcon}>
                                    {/*<object type="image/svg+xml" data={require('../../../images/icon-' + tileIcon + '.svg')} class="the-icon">
                                  Fallback
                                </object>*/}                                        
                            </div>
                        </div>
                        <h2 className="add-activity--description">{this.props.plan.description}</h2>
                        <h3 className="add-activity--category">{this.props.plan.tile_category}</h3>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>                            

                            {/*<p className="plan--units">{this.props.plan.units}</p>*/}
                             <ClientAddBar
                                scaleStart={this.props.plan.scale_start}
                                scaleEnd={this.props.plan.scale_end}
                                planId={this.props.plan.id}
                                tileId={this.props.plan.tile_id}
                                incrementer={this.props.plan.incrementer}
                                minimum={this.props.plan.minimum_point}
                                maximum={this.props.plan.maximum_point}
                                category={this.props.plan.tile_category}
                                inverted={this.props.plan.inverted}
                                planUnits={this.props.plan.units}
                                status={this.props.plan.status}
                              />
                                                         
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ClientGoalBar;