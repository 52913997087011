import React, {useEffect, useState, useRef} from 'react';
import { Document, Page, pdfjs  } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {connect} from 'react-redux';

// actions
import {fetchFeaturedSingle} from "../../../store/actions/contentActions";
import {recordContentView} from "../../../store/actions/pathwaysActions";
import {checkAlerts} from "../../../store/actions/alertActions";
import {getDailyScore, setChangedScoreFlag} from "../../../store/actions/wellnessScoreActions";

// styles
import styles from './featuredPdf.module.css';

// components
import {ReactComponent as RightIcon} from '../../../images/chevron-right-solid.svg';
import {ReactComponent as LeftIcon} from '../../../images/chevron-left-solid.svg';
import ClientHeader from "../../clientHeader/clientHeader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const FeaturedPdf = props => {
    // props
    const {match} = props;
    // state
    const {user} = props;
    // actions
    const {checkAlerts, getDailyScore, setChangedScoreFlag} = props;
    // local
    const [content, setContent] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [width, setWidth] = useState(0);
    const container = useRef();
    const [showOverlay,] = useState(false); // determines if the page navigation is an overlay or stuck to the bottom

    // grab content on mount
    useEffect(() => {
        fetchFeaturedSingle(match.params.contentId)
            .then(result => setContent(result))
            .catch(e => console.log(e));
    }, []);

    // record content view
    useEffect(() => {
        async function prepare() {
            try {
                const res = await recordContentView(match.params.contentId);
                if (res && res.result.alert_waiting){
                    console.log(res);
                    setChangedScoreFlag(parseInt(res.result.score.score));
                    checkAlerts();
                    getDailyScore();
                }
            } catch (e) {
                console.log(e);
            }
        }
        prepare();
    }, []);

    // adjust scale size on first call
    useEffect(() => {
        if (content) {
            const width = container.current.clientWidth - 30;
            setWidth(width);
        }
    },[content]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    if (!content) return <div/>;
    let backUrl = `/member/clientDash/${user.username}`;
    if (content.categories.length) backUrl = `/member/featured/${content.categories[0].id}`;
    return (
        <>
            <ClientHeader type={'transparent'} backUrl={backUrl}/>
            <div>
                <div className={`wellness-bg wydBG pathwaysBackground`}>
                    <div className='pathwaysOverlay'/>
                    <div className="container client-dash">
                        <div className="row">
                            <div className="col-xs-12">

                            </div>
                        </div>
                    </div>
                </div>
                <div className={`container client-dash ${styles.container}`} style={showOverlay ? {paddingBottom: '105px'} : {}}>
                    <div className={styles.window} ref={container}>
                        <Document
                            file={content.content}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page width={width} pageNumber={pageNumber} scale={scale} />
                        </Document>
                    </div>

                </div>
                <div className="footer-actions">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6">
                                <button className="btn client-white" onClick={()=>props.history.push(`/member/featured/${content.categories[0].id}`)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.pageContainer}>
                    <div className={styles.pages}>
                        <p>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                        </p>
                        <div className={styles.buttonContainer}>
                            <button
                                type="button"
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                            >
                                <LeftIcon/>
                            </button>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                                <RightIcon/>
                            </button>
                        </div>
                        <div className={styles.buttonContainer}>
                            <a href={content.content} download={content.title}><i className="fas fa-download"/></a>
                            <button onClick={() => setScale(prev => prev * .8)}><i className="fas fa-search-minus"/></button>
                            <button onClick={() => setScale(prev => prev * 1.2)}><i className="fas fa-search-plus"/></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps({user}) {
    return {user}
}

const actions = {
    checkAlerts,
    getDailyScore,
    setChangedScoreFlag,
}
export default connect(mapStateToProps, actions)(FeaturedPdf);