import {gameApi} from "../../api/gameApi";
import {GET_BADGES} from "../storeConstants";

//**************** Actions that involve the store *****************//
export function getBadges() {
    return async dispatch => {
        const badges = await gameApi().getBadges();
        dispatch({type: GET_BADGES, payload: badges.data.result});
    }
}

//**************** Actions that don't involve the store *****************//
export async function getCurrentBadge() {
    const {data: {result}} = await gameApi().getCurrentBadge();
    return result;
}

export async function getHowToScore() {
    const {data} = await gameApi().getHowToScore();
    return data.result;
}