import React, {useState} from 'react';
import {Modal} from "react-bootstrap";

// styles
import styles from './imageModeration.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {SecondaryInfoAlert} from "../../alerts/alerts";

const ImageModeration = props => {
    // props
    const {images, setStatus} = props;
    // local
    const [show, setShow] = useState(false);
    const [rejection, setRejection] = useState(null);

    function openModal(item) {
        setShow({user_id: item.user_id, username: item.username, url: item.profile_image});
    }

    function confirm(userId) {
        if (rejection === null) return setRejection(userId);
        setStatus(userId, 'rejected');
    }

    if (images.length < 1) {
        return (
            <div>
                <SecondaryInfoAlert>There are no images awaiting review at this time</SecondaryInfoAlert>
            </div>
        )
    }

    return (
        <div className={styles.container}>
                {images.map(image => (
                    <div className={styles.cardContainer}>
                        <BoxContainer className={styles.card}>
                            <div className={styles.username}>{image.username}</div>
                            <div onClick={() => openModal(image)} className={styles.image} style={{backgroundImage: `url(${image.profile_image})`}}/>
                            <div className={styles.buttons}>
                                <button onClick={() => confirm(image.user_id)} className={styles.reject}>{rejection === image.user_id ? 'Confirm' : 'Reject'}</button>
                                <button onClick={() => setStatus(image.user_id, 'accepted')} className={styles.approve}>Approve</button>
                            </div>
                        </BoxContainer>
                    </div>
                ))}
            <Modal show={show} onHide={() => setShow(false)}>
                {show ?
                    <div>
                        <BoxContainer className={styles.card}>
                            <div className={styles.username}>{show.username}</div>
                            <img className={styles.imageActual} src={show.url} alt=""/>
                            <div className={styles.buttons}>
                                <button onClick={() => confirm(show.user_id)} className={styles.reject}>{rejection === show.user_id ? 'Confirm' : 'Reject'}</button>
                                <button onClick={() => setStatus(show.user_id, 'accepted')} className={styles.approve}>Approve</button>
                            </div>
                        </BoxContainer>
                    </div>
                    : null
                }
            </Modal>
        </div>
    )
}

export default ImageModeration;