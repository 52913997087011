// npm modules
import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import { removeList, alterList } from "../../actions/action_forms";

const SingleResponse = ({ list, question, removeList, alterList }) => {
    return (
        <div className="form-group">
			<div className="row">
				<div className="col-sm-6">
					<label>Label/Title</label>
				</div>
				<div className="col-sm-6 text-right">
					<p className="text-delete" onClick={()=>removeList(question, list)}>Delete</p>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12">
					<input
						type="text"
						value={list.value.item !== null || list.value.item !== undefined ? list.value.item : ''}
						onChange={e => alterList(question, {...list, value: { item: e.target.value, value: list.value.value } })}
						className='form-control'/>
					{ list.validation && list.validation.value ?
						list.validation.value.map(err => <span className="text-danger">{err}</span>):
						null
					}            
				</div>
			</div>
			{ question.categoryId ?
				<div className="row">
					<div className="col-sm-4">
						<label>Score</label>
						<input
							type="text"
							value={list.value.value !== null || list.value.value !== undefined ? list.value.value : ''}
							onChange={(e)=> alterList(question, { ...list, value: { item: list.value.item, value: e.target.value } })}
						/>
						{ list.validation && list.validation.score ?
							list.validation.score.map(err => <span className="text-danger">{err}</span>):
							null
						}
					</div>
				</div>
				: null
			}
			<hr/>
        </div>
    )
};

const actions = {
    removeList,
    alterList,
};

export default connect(null, actions)(SingleResponse);

SingleResponse.propTypes = {
    list: propTypes.shape({
        value: propTypes.string,
    }).isRequired,
    question: propTypes.object.isRequired,
    removeList: propTypes.func,
    alterList: propTypes.func,
};

SingleResponse.defaultProps = {
    removeList: propTypes.func,
    alterList: propTypes.func,
};