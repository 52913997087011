import React, {useEffect, useState} from 'react';

// styles
import style from './justForYouAdd.module.css';

// actions
import {
    fetchMemberContent,
    fetchJustForYouCategories,
    subscribeJustForYou,
} from "../../store/actions/pathwaysActions";

// components
import {AnimatedCheckmark} from "../animatedIcons/checkmark/animatedCheckmark";
import ContentImage from "../../images/icon-circles.png";
import ClientHeader from "../clientHeader/clientHeader";

const JustForYouAdd = props => {
    // local
    const [ state, updateState ] = useState({
        content: null,
        categories: [],
        loading: true,
        saving: false,
    });
    const [alert, setAlert] = useState([]);

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    async function subscribe(category) {
        if (state.saving) return;
        setState({ saving: true });
        // check if category is already subbed to
        const subscribed = state.categories.find(cat => cat.category_id === category && cat.status);

        let updated;
        if (subscribed) {
                updated = state.categories
                    .filter(cat => cat.category_id !== category && cat.status)
                    .map(cat=> cat.category_id);
        } else {
            updated = state.categories
                .filter(cat => cat.category_id && cat.status)
                .map(cat => cat.category_id);
            updated.push(category);
        }

        await subscribeJustForYou(updated)
            .catch(e => console.log(e));
        await loadCategories();
        showAlert(category, !subscribed);
        setState({saving: false});
    }

    async function loadCategories() {
        const getContent = () => {
            return new Promise(resolve => {
                fetchMemberContent().getJustForYou()
                    .then(jfy => resolve(jfy));
            })
        }

        const getCategories = () => {
            return new Promise(resolve => {
                fetchJustForYouCategories()
                    .then(cats => resolve(cats));
            })
        }

        await Promise.all([getContent(), getCategories()])
            .then(([jfy, categories]) => {
                setState({
                    content: jfy,
                    categories,
                    loading: false,
                })
            })
            .catch(e => console.log(e));
    }

    function showAlert(id, message) {
        const newAlert = { id, message };
        setAlert(prev => [...prev, newAlert]);
        setTimeout(() => {
            setAlert(prev => prev.filter(item => item.id !== id));
        }, 2000);
    }

    useEffect(() => {
        loadCategories();
    }, []);

    if (state.loading) return <div/>;
    return (
        <>
            <ClientHeader type={'transparent'} backUrl={'/member/just_for_you'}/>
            <div>
                <div
                    style={{ backgroundImage: `url(${state.content.hero_image_url})`}}
                    className={`wellness-bg wydBG pathwaysBackground`}>
                    <div className='pathwaysOverlay'/>
                    <div className="container client-dash">
                        <div className="row">
                            <div className="col-xs-12">
                                <div>
                                    <h2 className="no-margin">
                                        Let's find content that connects with you
                                    </h2>
                                    <p>
                                        Select a few and try them out. Change them again anytime. We'll save your progress.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container client-dash no-overview">
                    <div className="row" >
                        <div className='contentLinkContainer tour-jfy-categories'>
                            { state.categories.map((category, index) => (
                                <div className="col-xs-12 col-sm-6 contentLink">
                                    <ContentCategory
                                        overlay
                                        key={category.category_id}
                                        text={category.category_name}
                                        tileImage={category.tile_image || ContentImage}
                                        selected={category.status}
                                        onClick={() => subscribe(category.category_id)}
                                        alert={alert.find(item => item.id === category.category_id)}
                                        animate={alert.find(item => item.id === category.category_id && item.message)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default JustForYouAdd;

function ContentCategory(props) {
    const {
        text,
        tileImage,
        overlay,
        background,
        selected,
        className = '',
        alert,
        animate,
    } = props;

    const styleObj = {};
    if (background) styleObj.background = background;
    if (tileImage) styleObj.backgroundImage = `url(${tileImage})`;

    return (
        <div
            style={styleObj}
            className={`pathway-tile ${style.contentLink} ${className}`}
            {...props}>
            { overlay ? <div className={style.overlay}/> : null }
            <div className="col-xs-9">
                <div className={style.title}>{text}</div>
                <div className='pos:r'>
                    <p className={`${style.alert} ${alert && alert.message ? style.active : null}`}>Content Added</p>
                    <p className={`${style.alert} ${alert && !alert.message ? style.active : null}`}>Content Removed</p>
                </div>
            </div>
            <div className="col-xs-3 text-right pathway-icon">
                {
                    selected ?
                        <AnimatedCheckmark animated={!!animate}/>
                        :
                        <span className={style.unselected}/>
                }
            </div>
        </div>
    )
}