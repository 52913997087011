import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route
} from 'react-router-dom';

// actions
import { setClientPathwayList, setClientPathway } from "../../store/actions/pathwaysActions";
import { fetchUserCoaches } from "../../store/actions/userActions";

// component
import PathwayContentMain from "./pathwayContentMain";
import PathwayContentCategory from './pathwayContentCategory';
import PathwayContentSingle from "./pathwayContentSingle";

const PathwaysContentContainer = props => {
    // state
    const { clientPathway, clientPathwayList } = props;
    // actions
    const { setClientPathway, setClientPathwayList } = props;
    // local
    const [coach, setCoach] = useState(null);

    // on mount
    useEffect(() => {
        // set state username based on href
        // get list of pathways a client has
        setClientPathwayList();
        // get list of coaches assigned to client
        fetchUserCoaches()
            .then(coaches => {
                if (coaches && coaches.length > 0) {
                    setCoach(coaches[0]);
                }
            }).catch(e => console.log(e));

    },[]);

    // grab a specific pathway when list is populated
    useEffect(() => {
        if (clientPathwayList.length > 0) {
            setClientPathway(clientPathwayList[0].id);
        }
    },[clientPathwayList]);

    return (
        <div className="client-container pathways-container">
            <Route path={props.match.url} exact render={() => <PathwayContentMain pathway={clientPathway} coach={coach}/>}/>
            <Route path={`${props.match.url}/pathway`} exact component={PathwayContentCategory}/>
            <Route path={`${props.match.url}/content/:contentId`} exact render={() => <PathwayContentSingle content={{}}/>}/>
        </div>
    )
};

function mapStateToProps({ user, clientPathway, clientPathwayList }) {
    return { user, clientPathway, clientPathwayList };
}

const actions = {
    setClientPathwayList,
    setClientPathway,
};

export default connect(mapStateToProps, actions)(PathwaysContentContainer);


