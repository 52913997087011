import mainApi from "./mainApi";

export function wotdApi() {
    return {
        // get a list of all wotd categories for landing page
        getWotdAllCategories: function() {
            return mainApi.get('/wotd/categories');
        },
        getWotdSelectedCategory: function(categoryId, currentTime) {
            return mainApi.get(`/wotd/${categoryId}?&current_time=${currentTime}`)
        },
        getWotdOrgAccess: function(orgId){
            return mainApi.get(`/wotd/org/info/${orgId}`)
        },

        // wotd generic routes
        getWotdGenericVideos: function(){
            return mainApi.get(`/wotd/generic/categories`)
        },
        getWotdGenericSelectedVideoSprint8: function(categoryId) {
            return mainApi.get(`/wotd/sprint8/${categoryId}`);
          },

          getWotdGenericSelectedVideoVirtualActive: function(categoryId) {
            return mainApi.get(`/wotd/virtual-active/${categoryId}`);
          },

          getWotdGenericSelectedVideoTrainerLed: function(categoryId) {
            return mainApi.get(`/wotd/trainer-led/${categoryId}`);
          },

    }
}


 