import mainApi from "./mainApi";

export function organizationApi() {
    return {
        // get either a total list of organizations or a list that a coach is assigned to
        getOrganizations: function(userId) {
            let url = '/organizations/list';
            if (userId) url += `/${userId}`;
            return mainApi.get(url);
        },
        // get content that can be assigned to a given org
        getAssignable: function(orgId, search, assignedOrgId, sort) {
            let url = `/organizations/get/assignable/content/${orgId}?`;
            if (search) url += `search=${search}&`;
            if (assignedOrgId) url += `filter_org_id=${assignedOrgId}&`;
            if (sort) url += `sort=${sort}&`
            return mainApi.get(url);
        },
        // assign categories to an organization
        setAssigned: function(orgId, categoryId) {
            return mainApi.post(`/organizations/assign/category/${orgId}/${categoryId}`);
        },
        // get content already assigned to an org
        getAssigned: function(orgId) {
            return mainApi.get(`/organizations/get/assigned/content/${orgId}/`);
        },
        // unassign category from org
        deleteAssigned: function(orgId, categoryId) {
            return mainApi.delete(`/organizations/delete/assigned/content/${orgId}/${categoryId}`);
        },
        // order the categories assigned to an org
        orderOrganizationCategories: function(orgId, categories) {
            return mainApi.post(`/organizations/sort/categories/${orgId}`, categories);
        },
        // update the email list preference of an organization
        updateOrgEmailPreference: function(orgId, optIn) {
            return mainApi.put(`/organizations/wellness/resources/${orgId}/${optIn}`);
        },
        // connect an organization to daxko
        connectDaxko: function(orgId, scope) {
            return mainApi.post(`/organizations/connect/daxko/${orgId}`, {scope});
        },
        // disconnect an organization to daxko
        disconnectDaxko: function(orgId, scope) {
            return mainApi.delete(`/organizations/connect/daxko/destroy/${orgId}`);
        },
        // get Daxko user info
        getDaxkoUser: function(code) {
            return mainApi.put(`/user/daxko/success/${code}`);
        },
        // check if an organization code is valid
        checkOrgCode: function(orgId, invitation) {
            return mainApi.get(`/organizations/check/code/${orgId}/${invitation}`)
        },
        // check if an org has seats available
        checkOrgSeatAvailability: function(orgId) {
            return mainApi.get(`/organizations/new-client/check/${orgId}`);
        },
        // get list of retail organizations
        getRetailers: function() {
            return mainApi.get(' /organizations/get/jht/retailers');
        },
        // change the status of an orgainzation
        changeStatus: function(orgId, status) {
            return mainApi.get(`/organizations/change/status/${orgId}?status=${status}`)
        }
    }
}