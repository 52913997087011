export const pathwaysTour = [
    {
        id: 'tour-pathway-intro',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-pathwaysBtn') || count > 25){
                        clearInterval(interval);
                        window.scrollTo(0, 0);
                        resolve();
                    }
                }, 250);
            });
        },
        advanceOn: {selector: '.tour-pathwaysBtn', event: 'click'},
        attachTo: { element: '.tour-pathwaysBtn', on: 'auto' },
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 7,
        text: 'Your Personal Pathway is determined by the health goal you selected in the Lifestyle Assessment. Your Pathway is a series of videos - all 3 minutes or less – that explain why, and how, your selected habits will help you achieve your health goal. <br><strong>Click the Pathway Button<strong/>',
        title: 'Welcome to Your Personal Pathway'
    },
    {
        id: 'tour-pathway-overview',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-pathway-overview') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        canClickTarget: false,
        text: 'This is your Pathways video content library. The red Up Next label is a shortcut to the next video ready for viewing in Your Pathway.',
        title: 'Pathways Home Page'
    },
    {
        id: 'tour-pathway-foundation',
        attachTo: {element: '.tour-pathway-foundation', on: 'auto'},
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        canClickTarget: false,
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: 'We recommend you start by watching the Foundations For Change video series which is all about tips for habit formation. No need to binge-watch! Watch one or two at a time to let the lessons sink in.',
        title: 'Foundations For Change',
    },
    {
        id: 'tour-pathway-tile',
        attachTo: {element: '.tour-pathway-tile', on: 'auto'},
        advanceOn: {selector: '.tour-pathway-tile', event: 'click'},
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        scrollTo: true,
        text: 'This is your Goal video content. These videos explain why and how your personalized Habit Tiles will help you achieve the goal you selected. <br><strong>Click on your highlighted Goal Tile</strong>',
        title: 'Goal Specific Content'
    },
    {
        id: 'tour-pathway-category',
        attachTo: {element: '.tour-pathway-category', on: 'auto'},
        advanceOn: {selector: '.tour-pathway-category', event: 'click'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-pathway-category') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: `Your Pathway content is organized around the habits in your Habit Builder. Knowing why and how a new habit helps achieve your health goal boosts your motivation and commitment. <strong>Click on the highlighted tile</strong> to view the list of video topics for this habit.  `,
        title: 'Organized around habits'
    },
    {
        id: 'tour-pathway-subcategory',
        attachTo: {element: '.tour-pathway-subcategory', on: 'auto'},
        advanceOn: {selector: '.tour-pathway-subcategory', event: 'click'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-pathway-subcategory') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: `The video topics for each habit are arranged in the order they are to be watched. <strong>Click on the highlighted tile.</strong>`,
        title: "Video Topics"
    },
    {
        id: 'tour-pathway-content',
        attachTo: {element: '.tour-pathway-content', on: 'auto'},
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                let count = 0;
                const interval = setInterval(function () {
                    count++;
                    if(document.querySelector('.tour-pathway-content') || count > 25){
                        clearInterval(interval);
                        resolve();
                    }
                }, 250);
            });
        },
        buttons: [
            {
                action() {
                    return this.next();
                },
                classes: 'shepherd-button-primary',
                text: 'Next',
            }
        ],
        canClickTarget: false,
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: "You can choose to watch, listen to, or read your content depending on your preferred learning style.",
        title: "Watch, listen, or read your content"
    },
    {
        id: 'tour-pathway-next',
        attachTo: {element: '.tour-pathway-next', on: 'auto'},
        advanceOn: {selector: '.tour-pathway-next', event: 'click'},
        modalOverlayOpeningPadding: 3,
        modalOverlayOpeningRadius: 5,
        text: `Once you have viewed or read a piece of content, you will automatically unlock the next video in this series. <strong>Click on the NEXT VIDEO button</strong> if you are ready to learn more.`,
        title: "Unlocking more content"
    },
    {
        id: 'tour-pathway-back',
        attachTo: {element: '.tour-pathway-back', on: 'auto'},
        advanceOn: {selector: '.tour-pathway-back', event: 'click'},
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 7,
        text: `Congratulations! You're all set. If you are ready to take a break or want to view a video again, <strong>Click on the back arrow</strong> to return to the previous page.`,
        title: 'Return'
    },
];