import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'

// styles
import styles from './memberMail.module.css';

// actions
import {fetchMessages} from "../../store/actions/postsActions";

// components
import ClientHeader from "../clientHeader/clientHeader";
import {fetchUserCoaches} from "../../store/actions/userActions";
import {PrimaryLink} from "../links/links";
import MailList from "./mailList/mailList";
import {MAIL_VIEWS} from "./views";

const MemberMail = props => {
    // props
    const {user} = props;
    // local
    const [coaches, setCoaches] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [messages, setMessages] = useState(null);
    const [view, setView] = useState(MAIL_VIEWS.CURRENT);
    const loading = useRef(false);

    useEffect(() => {
        loadCoaches();
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = '#fff';
        return () => document.body.style.backgroundColor = null;
    }, []);

    useEffect(() => {
        loadMail();
    }, [page]);

    useEffect(() => {
        setMessages(null);
        if (page === 1) {
            setPage(1);
            loadMail();
        } else {
            setPage(1)
        }
    }, [view]);

    // grab user coaches
    async function loadCoaches() {
        try {
            const res = await fetchUserCoaches();
            setCoaches(res);
        } catch (e) {
            console.log(e);
        }
    }

    // load member message threads
    async function loadMail() {
        if (page > pages && page > 1){
            return loading.current = false;
        }
        try {
            const archived = view === MAIL_VIEWS.ARCHIVED;
            const res = await fetchMessages(page, {archived});
            if (messages && page > 1) {
                setMessages([...messages, ...res.threads]);
            } else {
                setMessages(res.threads);
            }
            setPages(res.pages);
            loading.current = false;
            console.log(res.threads);
        } catch(e) {
            console.log(e);
        }
    }

    function next() {
        if (loading.current) return;
        loading.current = true;
        setPage(prevState => prevState +1);
    }

    return (
        <>
            <div className={`client-container ${styles.top}`}>
                <div >
                    <ClientHeader backUrl={`/member/clientDash/${user.username}`}/>
                </div>
                <div className="general-container container">
                    <h3>Coaching Team</h3>
                    <div className={styles.coachContainer}>
                        {coaches.map((coach, i) => {
                            const profileImg = coach.profile_image || 'https://treo.s3.us-east-2.amazonaws.com/assets/avatar.png';
                            return (
                                <Link to={`/member/clientDash/${user.username}/coaching-team/coach/${coach.username}`} className={`${styles.coach} ${i === 0 ? 'tour-coach-coachBtn': ''}`}>
                                    <div className={styles.profile} style={{backgroundImage: `url(${profileImg})`}}/>
                                    <span>Coach</span>
                                    <h4 className='m:0'>{coach.first_name}</h4>
                                </Link>
                            )
                        })}
                    </div>

                    {/* Messages */}
                    <div className={styles.messageContainer}>
                        <div className={styles.header}>
                            <h4 className={styles.title}>Messages</h4>
                            <PrimaryLink className={coaches.length ? 'tour-coach-newTopic' : ''} to={`/member/clientDash/${user.username}/inbox-newmessage`}>+ New</PrimaryLink>
                        </div>
                    </div>

                </div>
            </div>
            <div className='client-container'>
                <div className="general-container container">
                    <MailList view={view} setView={setView} messages={messages} setPage={next}/>
                </div>
            </div>
        </>

    )
}

function mapStateToProps({user}) {
    return {
        user
    }
}

export default connect(mapStateToProps)(MemberMail);