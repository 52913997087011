import React, { Component } from "react";
import "./GoalBar.css";
import axios from "axios";
import { OverlayTrigger, Tooltip, Alert, } from "react-bootstrap";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";
import Radium from "radium";
/* eslint-disable */
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const tooltip = (
  <Tooltip>Information about enabling or disabling goal bar</Tooltip>
);

let scaleStart;
let scaleEnd;
let minPoint;
let maxPoint;

let bar;
let red;
let yellow;
let green;

let styles;
let noStyles = {};
let barStyles;

let dynamicScaleEnd;
let dynamicScaleStart;

let disableGoalBarClass;

export class GoalBarSingleTile extends Component {
  constructor(props) {
    super(props);
    let marks;
    this.state = {
      singleDescription: this.props.singleDescription,
      value: [this.props.minPoint, this.props.maxPoint],
      planUnits: "",
      description1: "",
      planStatus: this.props.planStatusId,
      goalBarStyle: true,
      marks: {},
      // scaleStart:this.props.scaleStart,
      // scaleEnd:this.props.scaleEnd,
      newPlan: {},
      id: this.props.id,
      statusList: [],
      show: false,
      alertStyle: true,
      alert: "",
      active: false,
      disabled: false,
      inverted: this.props.inverted,
      increment: this.props.increment,
      dynamicScaleStart: this.props.scaleStart,
      dynamicScaleEnd: this.props.scaleEnd,
    };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  componentWillReceiveProps = (props) => {
    var newPlan = {};
    if (props.planStatus === "disabled") {
      this.setState({ goalBarStyle: false });
    }
  };

  componentDidMount() {
    if (this.state.planStatus === 1) {
      this.setState({ goalBarStyle: true });
    } else {
      this.setState({ goalBarStyle: false });
    }
    this.scaleEndChange();
    this.scaleStartChange();
    axios.get(`/tiles/plans/statuses`).then((res) => {
      this.setState({ statusList: res.data.statuses });
    });
    let i = this.state.dynamicScaleStart;
    let j = this.state.dynamicScaleEnd;
    let tempMarks = {};
    for (i = i; i <= j; i += this.state.increment) {
      tempMarks[i] = i;
    }
    this.setState({ marks: tempMarks });
  }

  planUnitsChange = (e) => {
    this.props.planUnitsHandler(this.props.id + e.target.value);
    this.setState({ planUnits: e.target.value });
  };

  descriptionChange = (e) => {
    this.props.descriptionHandler(e.target.value);
    this.setState({ description1: e.target.value });
  };

  planStatusChange() {
    if (this.state.planStatus === 1) {
      this.setState({ planStatus: 3 });
      this.setState({ goalBarStyle: false });
    } else {
      this.setState({ planStatus: 1 });
      this.setState({ goalBarStyle: true });
    }
  }

  valueChange = (value) => {
    this.setState({
      value,
    });
  };

  maxDecrement() {
    if (this.state.dynamicScaleEnd === this.state.increment) {
      return;
    }
    this.scaleEndChange();
    this.scaleStartChange();
    this.setState(
      { dynamicScaleEnd: this.state.dynamicScaleEnd - this.state.increment },
      () => {
        this.props.scaleEndHandler([this.props.id, this.state.dynamicScaleEnd]);
      }
    );
    let i = this.state.dynamicScaleStart;
    let j = this.state.dynamicScaleEnd - this.state.increment;
    let tempMarks = {};
    for (i = i; i <= j; i += this.state.increment) {
      tempMarks[i] = i;
    }
    this.setState({ marks: tempMarks });
    this.setState({
      value: [this.state.dynamicScaleStart, this.state.dynamicScaleEnd],
    });
  }
  maxIncrement() {
    this.scaleEndChange();
    this.scaleStartChange();
    this.setState(
      { dynamicScaleEnd: this.state.dynamicScaleEnd + this.state.increment },
      () => {
        this.props.scaleEndHandler([this.props.id, this.state.dynamicScaleEnd]);
      }
    );
    let i = this.state.dynamicScaleStart;
    let j = this.state.dynamicScaleEnd + this.state.increment;
    let tempMarks = {};
    for (i = i; i <= j; i += this.state.increment) {
      tempMarks[i] = i;
    }
    this.setState({ marks: tempMarks });
    this.setState({
      value: [this.state.dynamicScaleStart, this.state.dynamicScaleEnd],
    });
  }

  minDecrement() {
    this.scaleEndChange();
    this.scaleStartChange();
    this.setState(
      {
        dynamicScaleStart: this.state.dynamicScaleStart - this.state.increment,
      },
      () => {
        this.props.scaleStartHandler([
          this.props.id,
          this.state.dynamicScaleStart,
        ]);
      }
    );
    let i = this.state.dynamicScaleStart - this.state.increment;
    let j = this.state.dynamicScaleEnd;
    let tempMarks = {};
    for (i = i; i <= j; i += this.state.increment) {
      tempMarks[i] = i;
    }
    this.setState({ marks: tempMarks });
    this.setState({
      value: [this.state.dynamicScaleStart, this.state.dynamicScaleEnd],
    });
  }
  minIncrement() {
    if (
      this.state.dynamicScaleEnd - this.state.dynamicScaleStart ===
      this.state.increment
    ) {
      return;
    }
    // if((this.state.dynamicScaleStart + this.state.increment) === this.state.increment){return;}
    this.scaleEndChange();
    this.scaleStartChange();
    this.setState(
      {
        dynamicScaleStart: this.state.dynamicScaleStart + this.state.increment,
      },
      () => {
        this.props.scaleStartHandler([
          this.props.id,
          this.state.dynamicScaleStart,
        ]);
      }
    );
    let i = this.state.dynamicScaleStart + this.state.increment;
    let j = this.state.dynamicScaleEnd;
    let tempMarks = {};
    for (i = i; i <= j; i += this.state.increment) {
      tempMarks[i] = i;
    }
    this.setState({ marks: tempMarks });
    this.setState({
      value: [this.state.dynamicScaleStart, this.state.dynamicScaleEnd],
    });
  }

  editTile() {
    if (this.state.planStatus === this.props.planStatusId) {
      {
        this.changeGoal();
      }
    } else {
      {
        this.changePlanStatus();
      }
      {
        this.changeGoal();
      }
    }
  }

  changePlanStatus() {
    axios
      .put("/tiles/plan/modify-status", {
        planId: this.props.planId,
        updatedStatus: this.state.planStatus,
      })
      .then((res) => {
        if (res.data.message === "SUCCESS") {
          this.setState({ show: true });
          this.setState({ alert: "success" });
          this.setState({ alertStyle: true });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ show: true });
        this.setState({ alert: "error" });
        this.setState({ alertStyle: false });
      });
  }

  changeGoal() {
    if (this.state.planUnits === "") {
      axios
        .put("/tiles/modify/goal", {
          planId: this.props.planId,
          scaleStart: this.state.dynamicScaleStart,
          scaleEnd: this.state.dynamicScaleEnd,
          minPoint: this.state.value[0],
          maxPoint: this.state.value[1],
          increment: this.state.increment,
          scale_units: this.props.planUnits,
        })
        .then((res) => {
          if (res.data.message === "SUCCESS") {
            this.setState({ show: true });
            this.setState({ alert: "success" });
            this.setState({ alertStyle: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ show: true });
          this.setState({ alert: "error" });
          this.setState({ alertStyle: false });
        });
    } else {
      axios
        .put("/tiles/modify/goal", {
          planId: this.props.planId,
          scaleStart: this.state.dynamicScaleStart,
          scaleEnd: this.state.dynamicScaleEnd,
          minPoint: this.state.value[0],
          maxPoint: this.state.value[1],
          increment: this.state.increment,
          scale_units: this.state.planUnits,
        })
        .then((res) => {
          if (res.data.message === "SUCCESS") {
            this.setState({ show: true });
            this.setState({ alert: "success" });
            this.setState({ alertStyle: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ show: true });
          this.setState({ alert: "error" });
          this.setState({ alertStyle: false });
        });
    }
  }

  goalBarGradient() {
    bar = this.state.dynamicScaleEnd - this.state.dynamicScaleStart;
    red = ((this.state.value[0] - this.state.dynamicScaleStart) / bar) * 100;
    yellow = ((this.state.value[1] - this.state.value[0]) / bar) * 100;
    green = red + yellow;
  }

  invert() {
    this.setState({ inverted: !this.state.inverted });
  }

  increment(event) {
    let number = event.target.value;
    number = parseInt(number);
    this.setState({ increment: number }, () => {
      this.scaleEndChange();
      this.scaleStartChange();
      let i = this.state.dynamicScaleStart;
      let j = this.state.dynamicScaleEnd;
      let tempMarks = {};
      for (i = i; i <= j; i += this.state.increment) {
        tempMarks[i] = i;
      }
      this.setState({ marks: tempMarks });
      this.setState({
        value: [this.state.dynamicScaleStart, this.state.dynamicScaleEnd],
      });
    });
  }

  scaleEndChange() {
    if (this.state.dynamicScaleEnd % this.state.increment === 0) {
      this.state.dynamicScaleEnd = this.state.dynamicScaleEnd;
    } else {
      this.state.dynamicScaleEnd = this.state.increment;
    }
  }

  scaleStartChange() {
    if (this.state.dynamicScaleStart % this.state.increment === 0) {
      this.state.dynamicScaleStart = this.state.dynamicScaleStart;
    } else {
      this.state.dynamicScaleStart = 0;
    }
  }

  disabledStyleChange() {
    if (this.state.goalBarStyle === false) {
      barStyles = noStyles;
    } else {
      barStyles = styles;
    }
  }

  gradientStyles() {
    if (this.props.step4Child) {
      if (this.state.goalBarStyle === false) {
        styles = {};
      } else if (this.props.inverted) {
        styles = {
          background: `-webkit-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-webkit-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-o-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
        };
      } else {
        styles = {
          background: `-webkit-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-webkit-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-o-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
        };
      }
    } else {
      if (this.state.goalBarStyle === false) {
        styles = {};
      } else if (this.state.inverted) {
        styles = {
          background: `-webkit-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-webkit-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-o-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
        };
      } else {
        styles = {
          background: `-webkit-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-webkit-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-o-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
        };
      }
    }
  }

  render() {
    {
      this.disabledStyleChange();
    }
    {
      this.goalBarGradient();
    }
    {
      this.gradientStyles();
    }
    let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
    let found;
    if (this.props.hide) {
      found = this.props.description.find(function(element) {
        return element === "Custom";
      });
    }
    disableGoalBarClass = this.state.goalBarStyle
      ? "goal-bar-container col-md-8 col-xs-12"
      : "goal-bar-container-inactive col-md-8 col-xs-12";
    if (this.props.hasOwnProperty("planStatus")) {
      if (this.props.planStatus === 3 && this.state.goalBarStyle === true)
        this.setState({ goalBarStyle: !this.state.goalBarStyle });
    }
    return (
      <div className="goal-container margin-top-2x">
        {this.props.singleDescription !== "Custom" ? null : (
          <div>
            <label className="custom-description-label">
              {this.props.category}:
            </label>
            <input
              type="text"
              className="custom-description"
              placeholder="custom description"
              onChange={this.descriptionChange}
              value={this.state.description}
              required
            />
          </div>
        )}

        {this.props.step4Child ? null : (this.props.planStatus === "active" &&
            this.state.planStatus === "") ||
          this.state.planStatus === 1 ? (
          <div>
            <div className="row">
              {this.props.singleTileChild ? null : (
                <div className="col-md-6">
                  <input
                    className="sprint8-check"
                    id="checkBox"
                    defaultChecked={this.state.inverted}
                    value={this.state.inverted}
                    type="checkbox"
                    onChange={(e) => this.invert(e)}
                  />
                  <label className="sprint8-check-label">Invert</label>
                </div>
              )}
              <div className="col-md-6">
                <select
                  value={this.state.increment}
                  onChange={(e) => this.increment(e)}
                >
                  <option value={1}>{1}</option>
                  <option value={5}>{5}</option>
                  <option value={10}>{10}</option>
                  <option value={15}>{15}</option>
                  <option value={30}>{30}</option>
                </select>
              </div>
            </div>
            <i
              className="fa fa-times disable-goal-bar"
              onClick={() => {
                this.planStatusChange();
              }}
            >
              {" "}
            </i>
            <span>
              Disable goal bar for this tile
              <OverlayTrigger placement="top" overlay={tooltip}>
                <i className="fa fa-info-circle info-disable-goal-bar"> </i>
              </OverlayTrigger>{" "}
            </span>
            {this.props.singleTileChild ? (
              <button
                onClick={() => this.editTile()}
                className="goalBar-btn btn btn--actions"
              >
                Save Changes
              </button>
            ) : null}
            {this.state.show ? (
              <Alert
                className="col-md-4 save-profile-alert"
                bsStyle={changeAlertStyle}
                onDismiss={this.handleDismiss}
              >
                <p>{this.state.alert}</p>
              </Alert>
            ) : null}
          </div>
        ) : (
          <div>
            <div className="row">
              {this.props.singleTileChild ? null : (
                <div className="col-md-6">
                  <input
                    className="sprint8-check"
                    id="checkBox"
                    defaultChecked={this.state.inverted}
                    value={this.state.inverted}
                    type="checkbox"
                    onChange={(e) => this.invert(e)}
                  />
                  <label className="sprint8-check-label">Invert</label>
                </div>
              )}
              <div className="col-md-6">
                <select
                  value={this.state.increment}
                  onChange={(e) => this.increment(e)}
                >
                  <option value={1}>{1}</option>
                  <option value={5}>{5}</option>
                  <option value={10}>{10}</option>
                  <option value={15}>{15}</option>
                  <option value={30}>{30}</option>
                </select>
              </div>
            </div>
            <i
              className="fa fa-check disable-goal-bar"
              onClick={() => {
                this.planStatusChange();
              }}
            >
              {" "}
            </i>
            <span>
              Enable goal bar for this tile
              <OverlayTrigger placement="top" overlay={tooltip}>
                <i className="fa fa-info-circle info-disable-goal-bar"> </i>
              </OverlayTrigger>
            </span>

            <button onClick={() => this.editTile()} className="goalBar-btn">
              Save Changes
            </button>

            {this.state.show ? (
              <Alert
                className="col-md-4 save-profile-alert"
                bsStyle={changeAlertStyle}
                onDismiss={this.handleDismiss}
              >
                <p>{this.state.alert}</p>
              </Alert>
            ) : null}
          </div>
        )}

        <div className="margin-top-2x">
          <div className="row">
            {this.props.step4Child ? null : (
              <div>
                <i
                  onClick={() => {
                    this.minDecrement();
                  }}
                  className="fa fa-minus-circle col-md-1"
                >
                  {" "}
                </i>
                <i
                  onClick={() => {
                    this.minIncrement();
                  }}
                  className="fa fa-plus-circle col-md-1"
                >
                  {" "}
                </i>
              </div>
            )}

            <div className={disableGoalBarClass} style={styles}>
              {this.props.step4Child ? (
                <Range
                  placement="top"
                  value={this.state.value}
                  allowCross={false}
                  min={this.props.scaleStart}
                  max={this.props.scaleEnd}
                  step={this.props.increment}
                  marks={this.state.marks}
                  {...this.props}
                />
              ) : (
                <Range
                  placement="top"
                  value={this.state.value}
                  onChange={this.valueChange}
                  allowCross={false}
                  min={this.state.dynamicScaleStart}
                  max={this.state.dynamicScaleEnd}
                  step={this.state.increment}
                  marks={this.state.marks}
                  {...this.props}
                />
              )}
            </div>
            {this.props.step4Child ? null : (
              <div>
                <i
                  onClick={() => {
                    this.maxDecrement();
                  }}
                  className="fa fa-minus-circle col-md-1"
                >
                  {" "}
                </i>
                <i
                  onClick={() => {
                    this.maxIncrement();
                  }}
                  className="fa fa-plus-circle col-md-1"
                >
                  {" "}
                </i>
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            {this.props.hide || this.props.singleTileChild ? (
              <div>
                <label className="plan-units-label">Plan Units</label>
                <input
                  type="text"
                  className="plan-units col-md-12"
                  placeholder={this.props.planUnits}
                  onChange={this.planUnitsChange}
                  value={this.state.planUnits}
                />
                <p className="capitalize">{this.state.singleDescription}</p>
              </div>
            ) : (
              <div>
                <p>Plan Status: {this.props.planStatus}</p>
                <p>Category: {this.props.category}</p>
                <p>Plan: {this.props.singleDescription}</p>
                <p>Scale Start: {this.props.scaleStart}</p>
                <p>Scale End: {this.props.scaleEnd}</p>
                <p>Plan Units: {this.props.planUnits}</p>
                <p>Minimum Point: {this.props.minPoint}</p>
                <p>Maximum Point: {this.props.maxPoint}</p>
                {this.props.inverted ? (
                  <p>Inverted: yes</p>
                ) : (
                  <p>Inverted: no</p>
                )}
                <p>Increment: {this.props.increment}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
GoalBarSingleTile = Radium(GoalBarSingleTile);
