import React, {useState, useEffect, useRef} from 'react';

// styles
import styles from './phoneDisplay.module.css';

// components
import Phone from '../../../images/phone.png';
import {DangerButton, PrimaryButton} from "../../buttons/buttons";
import {Modal} from "react-bootstrap";

const LOCATIONS = {
    TILE: 'TILE',
    HEADER: 'HEADER',
};
const PhoneDisplay = props => {
    // props
    const {
        providedHeader,
        providedTile,
        headerImageCallback,
        tileImageCallback,
        showTiles,
        squareThumbnails
    } = props;
    // local
    const phoneDisplay = useRef();
    const phoneImage = useRef();
    const [header, setHeader] = useState({width: 0, height: 0, borderRadius: 0});
    const [row, setRow] = useState({
        width: 0,
        height: 0,
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 0,
        borderRadius: 0
    });
    const [square, setSquare] = useState({
        width: 0,
        height: 0,
        marginTop: 0,
        marginLeft: 0,
        borderRadius: 0
    });
    const [phoneHeight, setPhoneHeight] = useState(0);
    const [showWarning, setShowWarning] = useState({location: null, show: false});

    // hold the header image
    const [preview, setPreview] = useState(null);
    const [headerImage, setHeaderImage] = useState(providedHeader);

    // hold the tile image
    const [tilePreview, setTilePreview] = useState(null);
    const [tileImage, setTileImage] = useState(providedTile);

    // capture resizing
    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        if(header.width === 0) setResizeValues();
    }, [header]);

    // change the header preview to an image if passed in
    useEffect(() => {
        if (headerImage && headerImage.name) {
            setTemp(headerImage, LOCATIONS.HEADER);
        } else if (headerImage && typeof headerImage === 'string') {
            setPreview(headerImage)
        } else {
            setPreview(null);
        }
        if(headerImageCallback && typeof headerImageCallback === 'function') {
            headerImageCallback(headerImage);
        }
    }, [headerImage]);

    // change the tile preview to an image if passed in
    useEffect(() => {
        if (tileImage && tileImage.name) {
            setTemp(tileImage, LOCATIONS.TILE);
        } else if (tileImage && typeof tileImage === 'string') {
            setTilePreview(tileImage)
        } else {
            setTilePreview(null);
        }
        if(tileImageCallback && typeof tileImageCallback === 'function') {
            tileImageCallback(tileImage);
        }
    }, [tileImage]);

    // look for change events from the provided header image
    useEffect(() => {
        setHeaderImage(providedHeader);
    }, [providedHeader]);

    // look for change events from the provided tile image
    useEffect(() => {
        setTileImage(providedTile);
    }, [providedTile]);

    const finals = () => {
        const comparor = 375;
        const phoneWidth = phoneDisplay.current.clientWidth;
        let ratio = phoneWidth/comparor;
        if (phoneWidth > 375) ratio =1;
        return {comparor, phoneWidth, ratio};
    }

    // set header bg
    const setResizeValues = () => {
        const {comparor, ratio} = finals();
        setHeader({
            width: comparor*ratio,
            height: 242*ratio,
            borderRadius: 55*ratio
        });
        setRow({
            width: 300*ratio,
            height: 99*ratio,
            marginTop: 25*ratio,
            marginLeft: 37*ratio,
            marginBottom: 15*ratio,
            borderRadius: 8*ratio,
        });
        setSquare({
            width: 130*ratio,
            height: 130*ratio,
            marginTop: 15*ratio,
            marginLeft: 35*ratio,
            borderRadius: 10*ratio,
        })
        setPhoneHeight(phoneImage.current.clientHeight);
    }

    const onResize = () => {
        setHeader({width:0, height: 0});
        setRow({
            width: 0,
            height: 0,
            marginTop: 0,
            marginLeft: 0,
            marginBottom: 0,
            borderRadius: 0,
        });
    }

    const setTemp = (file, location) => {
        const reader = new FileReader();

        reader.onload = function(event) {
            if (location === LOCATIONS.HEADER) {
                setPreview(event.target.result);
                setHeaderImage(file);
            }
            if (location === LOCATIONS.TILE) {
                setTilePreview(event.target.result);
                setTileImage(file);
            }
        };

        reader.readAsDataURL(file);
    }

    const removeTileImage = () => {
        setShowWarning({location: LOCATIONS.TILE, show: true});
    }

    const removeHeaderImage = () => {
        setShowWarning({location: LOCATIONS.HEADER, show: true});
    }

    const hideWarning = () => {
        setShowWarning({location: null, show: false});
    }

    const confirmRemoveImage = (location) => {
        if (location === LOCATIONS.HEADER) {
            setHeaderImage(null);
            setPreview(null);
        }
        if (location === LOCATIONS.TILE) {
            setTileImage(null);
            setTilePreview(null);
        }
        hideWarning();
    }

    const headerStyles = {
        width: header.width,
        height: header.height,
        borderTopLeftRadius: header.borderRadius,
        borderTopRightRadius: header.borderRadius,
    };
    if (preview) headerStyles.backgroundImage = `url(${preview})`;

    const tileStyles = {
        width: row.width,
        height: row.height,
        marginTop: row.marginTop,
        marginLeft: row.marginLeft,
        marginBottom: row.marginBottom,
        borderRadius: row.borderRadius,
    };
    if (tilePreview) tileStyles.backgroundImage = `url(${tilePreview})`;

    const squareStyles = {
        width: square.width,
        height: square.height,
        marginTop: square.marginTop,
        marginLeft: square.marginLeft,
        borderRadius: square.borderRadius,
    }
    if (tilePreview) squareStyles.backgroundImage = `url(${tilePreview})`;


    return (
        <div ref={phoneDisplay} className={styles.container} id='phoneDisplay' style={{height: phoneHeight}}>
            <img onLoad={onResize} ref={phoneImage} className={styles.phone} src={Phone} alt="" />
            {/*header input*/}
            <div className={styles.headerBg} style={headerStyles}>
                <input
                    onChange={e => {
                        setTemp(e.target.files[0], LOCATIONS.HEADER)
                    }}
                    id="headerInput"
                    type="file"
                    style={{visibility: "hidden", width: 0, padding: 0}}/>
                {headerImage || preview ?
                    <button onClick={removeHeaderImage} className={styles.headerRemove}>Remove</button>
                    :
                    <PrimaryButton className={styles.button} onClick={() => document.getElementById('headerInput').click()}>Add Image</PrimaryButton>
                }
            </div>

            {/*tile input*/}
            {showTiles ?
                <div>
                    {squareThumbnails ?
                        <div>
                            <div className={styles.square} style={squareStyles}>
                                <input
                                    onChange={e => {
                                        setTemp(e.target.files[0], LOCATIONS.TILE)
                                    }}
                                    id="squareInput"
                                    type="file"
                                    style={{visibility: "hidden", width: 0, padding: 0}}/>
                                {tileImage || tilePreview ?
                                    <button onClick={removeTileImage} className={styles.tileRemove}>Remove</button>
                                    :
                                    <PrimaryButton className={styles.buttonSmall} onClick={() => document.getElementById('squareInput').click()}>Add</PrimaryButton>
                                }
                            </div>
                        </div>
                        :
                        <div>
                            <div
                                className={styles.row} style={tileStyles}>
                                <div className={styles.overlay}/>
                                <input
                                    onChange={e => {
                                        setTemp(e.target.files[0], LOCATIONS.TILE)
                                    }}
                                    id="tileInput"
                                    type="file"
                                    style={{visibility: "hidden", width: 0, padding: 0}}/>
                                {tileImage || tilePreview ?
                                    <button onClick={removeTileImage} className={styles.tileRemove}>Remove</button>
                                    :
                                    <PrimaryButton className={styles.button} onClick={() => document.getElementById('tileInput').click()}>Add Image</PrimaryButton>
                                }
                            </div>
                            <div
                                className={styles.row}
                                style={{
                                    width: row.width,
                                    height: row.height,
                                    marginLeft: row.marginLeft,
                                    marginBottom: row.marginBottom,
                                    borderRadius: row.borderRadius,
                                }}>
                                <div className={styles.overlay}/>
                            </div>
                            <div
                                className={styles.row}
                                style={{
                                    width: row.width,
                                    height: row.height,
                                    marginLeft: row.marginLeft,
                                    marginBottom: row.marginBottom,
                                    borderRadius: row.borderRadius,
                                }}>
                                <div className={styles.overlay}/>
                            </div>
                        </div>
                    }
                </div>
                : null
            }
            <Modal show={showWarning.show} onHide={hideWarning}>
                <Modal.Body>
                    <h4>Confirm image removal</h4>
                </Modal.Body>
                <Modal.Footer>
                    <DangerButton onClick={hideWarning} className='m-r:1'>NO</DangerButton>
                    <PrimaryButton
                        onClick={() => confirmRemoveImage(showWarning.location)}
                    >YES</PrimaryButton>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default PhoneDisplay;