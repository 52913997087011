// npm modules
import React from 'react';
import PropTypes from 'prop-types';
import { VictoryPie } from 'victory';
import _ from 'lodash';
import { Glyphicon } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

// styles
import styles from './clientPathwayLifestyle.module.css';

// components
import Loading from "../Loading/Loading";
import VeggieIcon from '../../images/icon-pathwayreport_veggies.png';
import ProteinIcon from '../../images/icon-pathwayreport_protein.png';
import SugarIcon from '../../images/icon-pathwayreport_sugar.png';
import EatingPatternsIcon from '../../images/icon-pathwayreport_eating.png';
import ActivityIcon from '../../images/icon-pathwayreport_activity.png';
import RecoveryIcon from '../../images/icon-pathwayreport_recovery.png';
import ReadinessIcon from '../../images/icon-pathwayreport_readiness.png';
import MotivationIcon from '../../images/icon-pathwayreport_motivation.png';
import SupportIcon from '../../images/icon-pathwayreport_support.png';

const LIFESTYLES = {
	FRUITS_VEGGIES: 1,
	PROTEIN: 2,
	SUGAR: 3,
	EATING_PATTERNS: 4,
	ACTIVITIES: 5,
	RECOVERY: 6,
	READINESS: 7,
	MOTIVATION: 8,
	SUPPORT: 9,
};

const ClientPathwayLifestyle = ({ clientPathway }) => {
	function LifeStylePopover(lifestyle, color) {
		const lifestyles = {
			[LIFESTYLES.FRUITS_VEGGIES]: {
				green: 'Nicely done! You enjoy multiple servings of vegetables and fruit each day, suggesting that you prioritize colorful and nutritious meals!',
				yellow: 'To get to Green, try enjoying one or more servings of vegetables or fruit with every meal. Treo assigns extra credit for vegetables since they are especially nutrient-dense. Due to their fiber and nutrition content, vegetables and fruits are much more filling and satisfying compared to ultra-processed foods. If you snack, try snacking on vegetables or fruits instead of packaged foods which are often high in calories and low in vitamins and minerals.',
				red: 'To get to Green, try enjoying one or more servings of vegetables or fruit with every meal. Treo assigns extra credit for vegetables since they are especially nutrient-dense. Due to their fiber and nutrition content, vegetables and fruits are much more filling and satisfying compared to ultra-processed foods. If you snack, try snacking on vegetables or fruits instead of packaged foods which are often high in calories and low in vitamins and minerals.'
			},
			[LIFESTYLES.PROTEIN]: {
				green: 'Yes! You typically enjoy at least one protein serving with every meal. Daily dietary protein is important for building and maintaining your strength and repairing your body.',
				yellow: 'To get to Green, try enjoying a serving of protein with each meal.  While we tend to associate protein with meat sources, there are a wide variety of non-meat sources including eggs, milk, cheese, cottage cheese, unsweetened yogurt, beans, nuts and seeds. Prioritizing protein can help you feel full and satisfied at mealtimes, plus it maintains your muscle, bone, skin and hair, and aids your strength and recovery.',
				red: 'To get to Green, try enjoying a serving of protein with each meal.  While we tend to associate protein with meat sources, there are a wide variety of non-meat sources including eggs, milk, cheese, cottage cheese, unsweetened yogurt, beans, nuts and seeds. Prioritizing protein can help you feel full and satisfied at mealtimes, plus it maintains your muscle, bone, skin and hair, and aids your strength and recovery.',
			},
			[LIFESTYLES.SUGAR]: {
				green: 'Congratulations! You minimize your daily consumption of added sugars. You are already making one of the most importance dietary choices for a lifetime of health.',
				yellow: 'To get to Green, try substituting your sweetened drinks with unsweetened beverages - such as plain, flavored, or sparkling water. To satisfy a sweet tooth, enjoy your favorite fresh fruit rather than a candy bar or sweetened granola bar.  The added fiber in the fruit slows down the digestion process and prevents blood sugar spikes, and the nutrition from fruit helps you feel your best.',
				red: 'To get to Green, try substituting your sweetened drinks with unsweetened beverages - such as plain, flavored, or sparkling water. To satisfy a sweet tooth, enjoy your favorite fresh fruit rather than a candy bar or sweetened granola bar.  The added fiber in the fruit slows down the digestion process and prevents blood sugar spikes, and the nutrition from fruit helps you feel your best.',
			},
			[LIFESTYLES.EATING_PATTERNS]: {
				green: 'Well done! You have already established eating patterns that support long-term health. There is increased recognition that when and how often you eat may be as important as what you consume.',
				yellow: 'When and how often you eat may be as important as what you eat. There are many ways you can improve your daily eating patterns to get to Green. For instance, you may choose to eat out less often, limiting your after-dinner snacks and beverages, reducing the number of snacking occasions, or gradually lengthening your mealtime spacing by extending the hours between your last snack or beverage in the evening and your first meal the following day.',
				red: 'When and how often you eat may be as important as what you eat. There are many ways you can improve your daily eating patterns to get to Green. For instance, you may choose to eat out less often, limiting your after-dinner snacks and beverages, reducing the number of snacking occasions, or gradually lengthening your mealtime spacing by extending the hours between your last snack or beverage in the evening and your first meal the following day.',
			},
			[LIFESTYLES.ACTIVITIES]: {
				green: 'Well done! The ideal balance of regular exercise includes aerobic activities - like walking, running and cycling - and some strength or body weight training.  A variety of activities helps keep you engaged over time, and can help you learn new motor skills that lead to better coordination and less risk of falls.',
				yellow: 'Keep trying a variety of activities until you find the ones that you enjoy. These activities may  include:  walking, gardening, yoga, lifting, swimming, cycling or running. To get to Green, try occasionally increasing your intensity or finding a good balance between aerobic activity and strength or body weight training.',
				red: 'Keep trying a variety of activities until you find the ones that you enjoy. These activities may  include:  walking, gardening, yoga, lifting, swimming, cycling or running. To get to Green, try occasionally increasing your intensity or finding a good balance between aerobic activity and strength or body weight training.',
			},
			[LIFESTYLES.RECOVERY]: {
				green: 'Nicely done! Giving yourself time to recover and getting enough sleep is so important for your overall physical and mental health, and is closely linked to wellbeing and quality of life. Keep up the good work!',
				yellow: 'Getting sufficient sleep each night is important for your overall wellbeing and helps you to better handle the daily stresses of life. To get to Green, make sure to prioritize your sleep each night, and consider setting aside time each day for a few moments of stress releasing activities, such as deep breathing, prayer, yoga, or meditation.',
				red: 'Getting sufficient sleep each night is important for your overall wellbeing and helps you to better handle the daily stresses of life. To get to Green, make sure to prioritize your sleep each night, and consider setting aside time each day for a few moments of stress releasing activities, such as deep breathing, prayer, yoga, or meditation.',
			},
			[LIFESTYLES.READINESS]: {
				green: 'Congratulations! Being ready and open to making lifestyles changes significantly improves your likelihood of adopting healthier habits.',
				yellow: 'Being ready and open to making lifestyle changes significantly improves your likelihood of adopting new healthier habits. If you are not ready or are unsure about making multiple changes to your current lifestyle, consider working on fewer, or even just one new habit at a time. Successfully adopting one healthy habit will give you the confidence to try additional changes in the future.',
				red: 'Being ready and open to making lifestyle changes significantly improves your likelihood of adopting new healthier habits. If you are not ready or are unsure about making multiple changes to your current lifestyle, consider working on fewer, or even just one new habit at a time. Successfully adopting one healthy habit will give you the confidence to try additional changes in the future.',
			},
			[LIFESTYLES.MOTIVATION]: {
				green: 'Great! Being motivated to make healthy changes is one of the key indicators of future success. Motivation often comes in waves, but once you have established a new healthy habit, maintaining it becomes more automatic and less dependent on high levels of motivation.',
				yellow: 'Motivation often comes in waves, but once you have established a new healthy habit, maintaining it becomes more automatic. Your Pathway provides Foundations mini-lessons that will share insights and best practices for how to make healthy habits automatic and less dependent on fluctuating motivation levels.',
				red: 'Motivation often comes in waves, but once you have established a new healthy habit, maintaining it becomes more automatic. Your Pathway provides Foundations mini-lessons that will share insights and best practices for how to make healthy habits automatic and less dependent on fluctuating motivation levels.',
			},
			[LIFESTYLES.SUPPORT]: {
				green: 'Congratulations! You have people around you who can help you to realize your health goals.',
				yellow: 'Additional social support may improve your likelihood of success in adopting a healthier lifestyle. Whenever possible and practical, encourage friends and family members to join you on your journey. Group fitness classes and team sports are another way to make physical activities more enjoyable and make new connections. Also, your Treo Habit Builder allows you to enlist weekly email support from family and friends by using the Treo Teammates feature.',
				red: 'Additional social support may improve your likelihood of success in adopting a healthier lifestyle. Whenever possible and practical, encourage friends and family members to join you on your journey. Group fitness classes and team sports are another way to make physical activities more enjoyable and make new connections. Also, your Treo Habit Builder allows you to enlist weekly email support from family and friends by using the Treo Teammates feature.',
			}
		};
		return lifestyles[lifestyle][color.toLowerCase()]
	}

	function renderIcon(category) {
		switch(category) {
			case LIFESTYLES.FRUITS_VEGGIES:
				return <img src={VeggieIcon} alt="icon"/>;
			case LIFESTYLES.PROTEIN:
				return <img src={ProteinIcon} alt="icon"/>;
			case LIFESTYLES.SUGAR:
				return <img src={SugarIcon} alt="icon"/>;
			case LIFESTYLES.EATING_PATTERNS:
				return <img src={EatingPatternsIcon} alt="icon"/>;
			case LIFESTYLES.ACTIVITIES:
				return <img src={ActivityIcon} alt="icon"/>;
			case LIFESTYLES.RECOVERY:
				return <img src={RecoveryIcon} alt="icon"/>;
			case LIFESTYLES.READINESS:
				return <img src={ReadinessIcon} alt="icon"/>;
			case LIFESTYLES.MOTIVATION:
				return <img src={MotivationIcon} alt="icon"/>;
			case LIFESTYLES.SUPPORT:
				return <img src={SupportIcon} alt="icon"/>;

			// no default

		}
	}
	
	function renderGraph(score, category) {
		if (String(score).toLowerCase() === 'green') score = 100;
		if (String(score).toLowerCase() === 'yellow') score = 67;
		if (String(score).toLowerCase() === 'red') score = 33;

		return(
			<div className={styles.iconContainer}>
				<div className={styles.icon}>
					{renderIcon(category)}
				</div>
				<div className={styles.iconCircle}>
					<VictoryPie
					  innerRadius={200}
					  cornerRadius={50}
					  data={[ 
						{ x: 1, y: score }, // ie 20 point score out of 100										
						{ x: 2, y: 100 - score }  // difference to add up to 100
					  ]}
					  labels={() => null}
					  style={{
						  data: {
							  fill: (d) => {
								  let color;
								  if(d.y <= 33) color = "#d65241";
								  if(d.y > 33 && d.y <= 67) color = "#f3d364";
								  if(d.y > 67) color = "#5da046";
								  return d.x === 1 ? color : "rgba(255,255,255,.75)";
							  }
						  }
					  }}
					/>
				</div>
			</div>
		)
	}

	function FoodChoices() {
		// only process certain categories
		return (
			<div>
				<h2 className="margin-top-3x">Food Choices</h2>
				<div className="content-container">
					<div className="flex-row">
						{
							clientPathway.lifestyle.map(lifestyle => {
								if (
									lifestyle.taxonomy_id === LIFESTYLES.FRUITS_VEGGIES ||
									lifestyle.taxonomy_id === LIFESTYLES.PROTEIN ||
									lifestyle.taxonomy_id === LIFESTYLES.SUGAR
								) {
									return (
										<div className="col-xs-12 col-sm-6 col-md-4">
											<div className="pathway-category">
												<h4 className='lifestyleHeadings'>
													{lifestyle.taxonomy_name}
													<p data-tip={LifeStylePopover(lifestyle.taxonomy_id, lifestyle.color)}>
														<Glyphicon className='lifestyle-glyph hover' glyph="glyphicon glyphicon-question-sign" />
													</p>
												</h4>
												{renderGraph(lifestyle.color, lifestyle.taxonomy_id)}
											</div>
										</div>

									)
								}
								return null;
							})
						}
					</div>
				</div>
			</div>
		)
	}

	function DailyPatterns() {
		// only process certain categories
		return (
			<div>
				<h2 className="margin-top-3x">Daily Patterns</h2>
				<div className="content-container">
					<div className="flex-row">
						{
							clientPathway.lifestyle.map(lifestyle => {
								if (
									lifestyle.taxonomy_id === LIFESTYLES.EATING_PATTERNS ||
									lifestyle.taxonomy_id === LIFESTYLES.ACTIVITIES ||
									lifestyle.taxonomy_id === LIFESTYLES.RECOVERY
								) {
									return (
										<div className="col-xs-12 col-sm-6 col-md-4">
											<div className="pathway-category">
												<h4 className='lifestyleHeadings'>
													{lifestyle.taxonomy_name}
													<p data-tip={LifeStylePopover(lifestyle.taxonomy_id, lifestyle.color)}>
														<Glyphicon className='lifestyle-glyph hover' glyph="glyphicon glyphicon-question-sign" />
													</p>
												</h4>
												{renderGraph(lifestyle.color, lifestyle.taxonomy_id)}
											</div>
										</div>

									)
								}
								return null;
							})
						}
					</div>
				</div>
			</div>
		)
	}

	function Readiness() {
		// only process certain categories
		return (
			<div>
				<h2 className="margin-top-3x">Readiness</h2>
				<div className="content-container">
					<div className="flex-row">
						{
							clientPathway.lifestyle.map(lifestyle => {
								if (
									lifestyle.taxonomy_id === LIFESTYLES.READINESS ||
									lifestyle.taxonomy_id === LIFESTYLES.MOTIVATION ||
									lifestyle.taxonomy_id === LIFESTYLES.SUPPORT
								) {
									return (
										<div className="col-xs-12 col-sm-6 col-md-4">
											<div className="pathway-category">
												<h4 className='lifestyleHeadings'>
													{lifestyle.taxonomy_name}
													<p data-tip={LifeStylePopover(lifestyle.taxonomy_id, lifestyle.color)}>
														<Glyphicon className='lifestyle-glyph hover' glyph="glyphicon glyphicon-question-sign" />
													</p>
												</h4>
												{renderGraph(lifestyle.color, lifestyle.taxonomy_id)}
											</div>
										</div>

									)
								}
								return null;
							})
						}
					</div>
				</div>
			</div>
		)
	}

	if (!clientPathway || _.isEmpty(clientPathway)) {
		return (
			<Loading/>
		)
	}
	
    return (
        <div className="container text-center">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2 ">        		      
				<h1>Lifestyle Snapshot</h1>   				
				<div className="content-container">
					<h4 className="no-margin-top">Color key</h4>
					<div className="row">
						<div className="col-xs-4 snapshot-legend">
							<div className="legend-icon healthy"></div>
							<div className="legend-label">Healthy habits practiced</div>
						</div>
						<div className="col-xs-4 snapshot-legend">
							<div className="legend-icon small-opportunity"></div>
							<div className="legend-label">Small opportunity to improve</div>
						</div>
						<div className="col-xs-4 snapshot-legend">
							<div className="legend-icon large-opportunity"></div>
							<div className="legend-label">Large opportunity to improve</div>
						</div>
					</div>
				</div>
			
				{ FoodChoices() }
				{ DailyPatterns() }
				{ Readiness() }
				<ReactTooltip  place='top' type='light' event='click' effect='solid' globalEventOff='click' clickable />
			</div>
        </div>
    )
};

ClientPathwayLifestyle.propTypes = {
	clientPathway: PropTypes.shape({
		lifestyle: PropTypes.array,
	}),
};

export default ClientPathwayLifestyle;