const stepIntro = {
  id: "intro",
  beforeShowPromise: function () {
    return new Promise(function (resolve) {
      setTimeout(function () {
        window.scrollTo(0, 0);
        resolve();
      }, 500);
    });
  },
  buttons: [
    {
      action() {
        return this.next();
      },
      classes: "shepherd-button-primary",
      text: "Next",
    },
  ],
  title: "Welcome to the Treo Habit Builder",
  text: "Logging daily habits that support your health goal is one of your most powerful tools for building new habits. It takes less than 2 minutes a day to log habits in your personalized Habit Builder.",
};
const stepWs = {
  id: "step-ws",
  attachTo: { element: ".tour-ws", on: "auto" },
  canClickTarget: false,
  buttons: [
    {
      action() {
        return this.next();
      },
      classes: "shepherd-button-primary",
      text: "Next",
    },
  ],
  modalOverlayOpeningRadius: 15,
  text: [
    `This is your daily wellness score. Daily logging keeps new habits top of mind. You can set your own daily point goal in your game settings on your profile page. Earn 25 points for opening the platform and 25 points for clicking Habit Builder daily!`,
  ],
  title: "Your Wellness Score",
};
const stepHabitBtn = {
  id: "step-habitBtn",
  advanceOn: { selector: ".tour-habitBtn", event: "click" },
  attachTo: { element: ".tour-habitBtn", on: "auto" },
  modalOverlayOpeningPadding: 3,
  modalOverlayOpeningRadius: 5,
  text: [
    `Your Personalized Habit Tiles are found in the Habit Builder Tile.<br><strong>Click the Habit Builder Tile.</strong>`,
  ],
  title: "How to open the Habit Builder",
};
const stepTile = {
  id: "step-tile",
  attachTo: { element: ".tour-tile .tour-tileHeader", on: "auto" },
  beforeShowPromise: function () {
    return new Promise(function (resolve) {
      let count = 0;
      const interval = setInterval(function () {
        count++;
        if (document.querySelector(".tour-tile .tour-tileHeader")) {
          clearInterval(interval);
          resolve();
        }
        if (count > 25) {
          clearInterval(interval);
        }
      }, 200);
    });
  },
  buttons: [
    {
      action() {
        return this.next();
      },
      classes: "shepherd-button-primary",
      text: "Next",
    },
  ],
  scrollTo: true,
  canClickTarget: false,
  text: [
    `Your Habit Tiles have been personalized by the health goal you selected. Each evidence-based habit most supportive of your goal is shown as its own Habit Tile.`,
  ],
  title: "Your Habit Tiles",
};
const stepTileBtn = {
  id: "step-tileBtn",
  advanceOn: { selector: ".tour-tileBtn", event: "click" },
  attachTo: { element: ".tour-tile .tour-tileBtn", on: "auto" },
  popperOptions: {
    modifiers: [{ name: "offset", options: { offset: [0, 24] } }],
  },
  modalOverlayOpeningPadding: 3,
  modalOverlayOpeningRadius: 5,
  text: `<strong>Click on the 'Log' button </strong> to view your Logging Screen.`,
  title: "Logging an activity",
};
const stepLogMain = {
  id: "step-log-main",
  arrow: false,
  attachTo: { element: ".tour-log-main", on: "bottom" },
  beforeShowPromise: function () {
    return new Promise(function (resolve) {
      let count = 0;
      const interval = setInterval(function () {
        count++;
        if (document.querySelector(".tour-log-main")) {
          clearInterval(interval);
          resolve();
        }
        if (count > 25) {
          clearInterval(interval);
        }
      }, 200);
    });
  },
  buttons: [
    {
      action() {
        return this.next();
      },
      classes: "shepherd-button-primary",
      text: "Next",
    },
  ],
  text: [
    `This is your Logging Screen. The Goal Bar is where you will enter your total daily activity. Your Goal Bar is color-coded and your daily target goal is anywhere in the green section. `,
  ],
  title: "Logging Screen",
  canClickTarget: false,
};
const stepLogSlider = {
  id: "step-log-slider",
  attachTo: { element: ".tour-log-slider", on: "auto" },
  buttons: [
    {
      action() {
        return this.next();
      },
      classes: "shepherd-button-primary",
      text: "Next",
    },
  ],
  scrollTo: true,
  modalOverlayOpeningRadius: 5,
  modalOverlayOpeningPadding: 15,
  text: [
    `Enter your daily totals for this activity by either dragging the slider left to right or tapping on the achieved value on the Goal Bar.`,
  ],
  title: "Goal Bar Entry",
};
const stepLogLog = {
  id: "step-log-log",
  advanceOn: { selector: ".tour-log-log", event: "click" },
  attachTo: { element: ".tour-log-log", on: "auto" },
  modalOverlayOpeningPadding: 3,
  modalOverlayOpeningRadius: 5,
  scrollTo: true,
  text: `Daily logging helps form new habits even on days you don’t achieve your target goal! New habits take time to form. Give yourself the freedom to be flexible. Life happens. Click <strong>LOG YOUR PROGRESS</strong> to record your activity.`,
  title: "Record your activity.",
};
const stepOpenNav = {
  id: "step-open-nav",
  arrow: false,
  attachTo: { element: ".tour-open-nav", on: "top" },
  advanceOn: { selector: ".tour-open-nav", event: "click" },
  beforeShowPromise: function () {
    return new Promise(function (resolve) {
      let count = 0;
      const interval = setInterval(function () {
        count++;
        if (document.querySelector(".tour-open-nav")) {
          clearInterval(interval);
          resolve();
        }
        if (count > 25) {
          clearInterval(interval);
        }
      }, 200);
    });
  },
  text: [
    'Clicking the "More" button will reveal other parts of the app you may navigate to.',
  ],
  title: 'Click the "More" button!',
  canClickTarget: true,
};
const finalStep = {
  id: "step-end",
  attachTo: { element: ".tour-end", on: "top" },
  beforeShowPromise: function () {
    return new Promise(function (resolve) {
      let count = 0;
      const interval = setInterval(function () {
        count++;
        if (document.querySelector('.tour-end') || count > 25) {
          clearInterval(interval);
          resolve();
        }
      }, 250);
    });
  },
  modalOverlayOpeningRadius: 5,
  modalOverlayOpeningPadding: 3,
  scrollTo: true,
  text: `To explore additional features of the Treo Whole-Person platform, <strong>Click this Question Mark icon</strong> anytime.`,
  title: "Explore more features!",
  canClickTarget: false,
  buttons: [
    {
      action() {
        return this.next();
      },
      classes: "shepherd-button-primary",
      text: "Finish",
    },
  ],
};

// export const mainTour = [
//   stepIntro,
//   stepWs,
//   stepHabitBtn,
//   stepTile,
//   stepTileBtn,
//   stepLogMain,
//   stepLogSlider,
//   stepLogLog,
//   stepOpenNav,
//   finalStep,
// ].map((item) => ({ ...item, cancelIcon: { enabled: false } }));


export const mainTour = [
  stepIntro,
  stepWs,
  stepHabitBtn,
  stepTile,
  stepTileBtn,
  stepLogMain,
  stepLogSlider,
  stepLogLog,
  stepOpenNav,
  finalStep,
]

// export const disabledTour = [
//   stepIntro,
//   stepWs,
//   stepHabitBtn,
//   stepTile,
//   stepTileBtn,
//   stepLogMain,
//   stepLogSlider,
//   stepLogLog,
//   stepOpenNav,
//   finalStep,
// ];
