import React, {useState, useEffect, useRef} from 'react';
import QRCode from "react-qr-code";
import {connect} from 'react-redux';

// styles
import styles from './consoleLanding.module.css';

// actions
import {checkLogin} from "../../../store/actions/authActions";

// variables
import {STATUSES} from "../../../serverVariables/statuses";

// hooks
import {useWindowSize} from "../../../hooks/useWindowSize";
import Loader from "react-loader-spinner";

const ConsoleLanding = props => {
    // state
    const { appState, user } = props;
    // props
    const { location, history } = props;
    // actions
    const { checkLogin } = props;
    // local
    const [loading, setLoading] = useState(true);
    const {height} = useWindowSize();

    // check if a user is logged in when mounting
    useEffect(() => {
        checkLogin()
            .then(() => setLoading(false));
    }, []);

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => document.body.style.background = null;
    }, []);

    // forward a user to the appropriate place
    useEffect(() => {
        if (location.state && window.location.href.lastIndexOf('localhost') > 0) return;
        if (user && appState.isLoggedIn && appState.appReady) {
            if(user.permission_id === 1){
                if(user.user_status_id < STATUSES.onboarding_coach_consult_pending){
                    //client is in onboarding
                    history.push({
                        pathname : `/member/onboard-assessment`,
                    });
                }else{
                    //forward to dashboard
                    let url = {pathname : `/member/clientDash/${user.username}`,};
                    if (location.state && location.state.from) {
                        const pattern = /.*\account\/notifications/gm;
                        const match = pattern.test(location.state.from.pathname);
                        if (match) url = location.state.from;
                    }
                    history.push(url);
                }
            }else if(user.permission_id === 2){
                history.push({
                    pathname: '/dashboard/analytics/user'
                });
            }else{
                history.push({
                    pathname:'/dashboard/analytics/user'
                });
            }
        }
    }, [ appState ]);

    if(loading){
        return (
            <div style={{height}} className='flex-vertically'>
                <Loader
                    type="Puff"
                    color="#354052"
                    height="100"
                    width="100"
                />
            </div>
        )
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.columnContainer}>
                <div className={styles.leftColumn}>
                    <div className='t-a:c margin-bottom-25'>
                        <h1 className={`${styles.mainHeading} ${styles.green}`}> Experience motivating workouts on your Matrix equipment!</h1>
                        <h2 className={styles.subHeading}>Check out the latest Workout of the Week!</h2>
                    </div>

                    <div className={`padding-standard ${styles.greyBackground} margin-bottom-25 ${styles.shadow}`}>
                        <div className='margin-bottom-25 t-a:c'><span className={`${styles.boldCopy} margin-right-15`}>Free 12 month subscription.</span><span className={styles.italicCopy}>No credit card required.</span></div>
                        <div className={styles.qrCodeContainer}>
                            <div>
                                <div className={styles.qrCode}>
                                    <QRCode value="https://habit.treowellness.com/console/signup" />
                                </div>
                            </div>
                            <div>
                                <div className={styles.boldCopy}>Create your account today!</div>
                                <div className={`${styles.subHeading} ${styles.small}`}>Scan the QR code or visit</div>
                                <a className={`${styles.italicCopy} ${styles.boldCopy} ${styles.link}`} href="https://habit.treowellness.com/console/signup">habit.treowellness.com/console/signup</a>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.greenBackground} ${styles.shadow}`}>
                        <p className={`m:0 ${styles.boldCopy} ${styles.white} ${styles.extraBold}`}>Already have an account?</p>
                        <a className={`${styles.boldCopy} ${styles.extraBold} ${styles.whiteButton} ${styles.green} ${styles.shadow}`} href="https://habit.treowellness.com">Login here</a>
                    </div>
                </div>
                <div className={styles.rightColumn}>
                    <img className={styles.image} src="https://treo.s3.us-east-2.amazonaws.com/assets/console/Icons_Group.png" alt=""/>
                </div>
                <div className={styles.mobileList}>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Ascent Trainer' trademark img='https://treo.s3.us-east-2.amazonaws.com/assets/console/Ascent+TrainerTM.png'/>
                    </div>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Climb Mill' trademark img='https://treo.s3.us-east-2.amazonaws.com/assets/console/ClimbMill.png'/>
                    </div>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Elliptical' img='https://treo.s3.us-east-2.amazonaws.com/assets/console/Elliptical.png'/>
                    </div>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Recumbent Bike' img='https://treo.s3.us-east-2.amazonaws.com/assets/console/Recumbent+Bike.png'/>
                    </div>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Studio Cycle' img='https://treo.s3.us-east-2.amazonaws.com/assets/console/Studio+Cycle.png'/>
                    </div>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Treadmill' img='https://treo.s3.us-east-2.amazonaws.com/assets/console/Treadmill.png'/>
                    </div>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Upright Bike' img='https://treo.s3.us-east-2.amazonaws.com/assets/console/Upright+Bike.png'/>
                    </div>
                    <div className={styles.mobileImageContainer}>
                        <MobileImage title='Virtual Active' trademark img='https://treo.s3.us-east-2.amazonaws.com/assets/console/Virtual+ActiveTM.png'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps({ appState, user }) {
    return {
        appState,
        user,
    }
}

const actions = {
    checkLogin,
}
export default connect(mapStateToProps, actions)(ConsoleLanding);

function MobileImage({img, title, trademark}) {
    const [width, setWidth] = useState(0);
    const container = useRef();

    useEffect(() => {
        getWidth();
        window.addEventListener('resize', getWidth);
        return () => window.removeEventListener('resize', getWidth);
    }, []);

    function getWidth() {
        setWidth(container.current.clientWidth);
    }
    return (
        <div ref={container} style={{backgroundImage: `url(${img})`, height: `${width}px`}} className={styles.mobileImage}>
            <p className={styles.mobileTitle}>{title} {trademark ? <span className={styles.trademark}>&#8482;</span> : null}</p>
            <div className={styles.overlay}/>
        </div>
    )
}