import React from 'react';
import {Route} from 'react-router-dom';
import ConsoleLanding from "./consoleLanding/consoleLanding";
import ConsoleSignup from "./consoleSignup/consoleSignup";

const ConsoleLandingRoutes = props => {
    return (
        <div>
            <Route component={ConsoleLanding} path={props.match.url} exact/>
            <Route component={ConsoleSignup} path={props.match.url + '/signup'}/>
        </div>
    )
}
export default ConsoleLandingRoutes;