import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

class ContentTabs extends Component{
    constructor(props){
        super(props);
        this.state={current:0};
        this.renderTabs=this.renderTabs.bind(this);
        this.dropDownHandleClick=this.dropDownHandleClick.bind(this);
    }

    updateDimensions() {
      if(window.innerWidth < 500) {
        this.setState({ width: 450, height: 102 });
      } else {
        let update_width  = window.innerWidth-100;
        let update_height = Math.round(update_width/4.4);
        this.setState({ width: update_width, height: update_height });
      }
    }

    componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    renderTabs(){
        if(this.props.categories){
            let categories=this.props.categories;
            const category_length=categories.length;
            let list;
            if(this.props.categories.length > 6 || this.state.width < 668){
                return(
                    <select onChange={this.dropDownHandleClick}>
                    {
                        list = categories.map((item, i)=>{
                             return(<option value={item.name}>{item.name}</option>)
                        })
                    }
                    </select>
                    )
            }
            else{
            list = categories.map((item, i)=>{
                if(this.props.hasOwnProperty('defaultValue')){
                    if(this.props.defaultValue){
                        //set first element to active
                        if(i===0){
                            return (<div className='content-tab active' id={'tab-'+i} onClick={()=>this.handleClick(item.name, i)}>{item.name}</div>);
                        }

                        //else
                        return (<div className='content-tab' id={'tab-'+i} onClick={()=>this.handleClick(item.name, i)}>{item.name}</div>)
                    }

                    //if defaultValue was set to false
                    return (<div className='content-tab' id={'tab-'+i} onClick={()=>this.handleClick(item.name, i)}>{item.name}</div>)
                }

                //if defaultValue wasn't provided
                return (<div className='content-tab' id={'tab-'+i} onClick={()=>this.handleClick(item.name, i)}>{item.name}</div>)
            });
            //tile is in view and adding final tab of content assigned to this tile
            if(this.props.tile) list.push(<div className='content-tab' id={'tab-'+category_length} onClick={()=>this.handlePreviouslyShared(category_length)}>Previously Shared</div>)                
            }

            return list;
        }
    }

    handlePreviouslyShared(tabId){
        this.props.getContentAssigned();
        let tab = document.getElementById(`tab-${tabId}`);
        let current_tab = document.getElementById(`tab-${this.state.current}`);
        current_tab.classList.remove('active');
        tab.classList.add('active');
        this.setState({current:tabId});
    }

    handleClick(name, tabId){
        this.props.getContent(name);
        let tab = document.getElementById(`tab-${tabId}`);
        let current_tab = document.getElementById(`tab-${this.state.current}`);
        current_tab.classList.remove('active');
        tab.classList.add('active');
        this.setState({current:tabId});
    }

  dropDownHandleClick(event) {
    this.props.getContent(event.target.value);
  }


    render(){
        return(
            <div className="capitalize">
                {this.renderTabs()}
            </div>
        )
    }
}

export default withRouter(ContentTabs)