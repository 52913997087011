import React from 'react';

const PathwaysVictoryTiles = props => {
    const {pathway, size} = props;
    let url = '';
    if (pathway === 'mealtime spacing' || pathway === 5) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_MealSpacing50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_MealSpaing200x200.png';
    }
    else if (pathway === 'eating_occasions' || pathway === 6) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_EatingOccasions50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_EatingOccasions200x200.png';
    }
    else if (pathway === 'vegetables_fruit' || pathway === 7) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_FruitVeggies50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_FruitVeggies200x200.png';
    }
    else if (pathway === 'protein_servings' || pathway === 8) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_Protein50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_Protein200x200.png';
    }
    else if (pathway === 'sweetened_foods' || pathway === 9) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_SweetFoods50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_SweetFoods200x200.png';
    }
    else if (pathway === 'sweetened_drinks' || pathway === 10) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_SweetDrinks50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_SweetDrinks200x200.png';
    }
    else if (pathway === 'stress_releasing' || pathway === 11) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_StressRelease50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_StressRelease200x200.png';
    }
    else if (pathway === 'sleep' || pathway === 12) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_Sleep50x50+.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_Sleep200x200.png';
    }
    else if (pathway === 'physical_activity' || pathway === 13) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_PhysicalActivity50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_PhysicalActive200x200.png';
    }
    else if (pathway === 'sprint_intensity' || pathway === 14) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_SprintIntensity50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_SprintIntensity200x200.png';
    }
    else if (pathway === 'strength_building' || pathway === 15) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_StrengthBuilding50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_StrengthBuild200x200.png';
    }
    else if (pathway === 'related_skills' || pathway === 16) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_Related50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_Related200x200.png';
    }
    else if (pathway === 'foundations' || pathway === 17) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_Foundations50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_Foundations200x200.png';
    }
    else if (pathway === 'coach' || pathway === 18) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_Coach50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_Coach200x200.png';
    }
    else if (pathway === 'just_for_you' || pathway === 19) {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/small/Pathways_DropShadow_JFY50x50.png';
        if (size === 200) url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Pathways_DropShadow_JFY200x200.png';
    } else {
        url = 'https://treo.s3.us-east-2.amazonaws.com/assets/pathways-victories/large/Victories_Pathways_DropShadow_WhatEat200x200.png'
    }
    return (
        <img src={url} alt="" className={props.className}/>
    )
};

export default PathwaysVictoryTiles;