import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from "axios/index";
import './Step1.css'

class Step1 extends Component{
   constructor(props){
       super();
           this.state = {
               user : {},
       };
   }

    moveToStep2(category){
        let currentPath = this.props.location.pathname,
            parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
        this.props.history.push({
            pathname: parentPath+'/step-2',
            state: {
                category:category,
                planStatus:'active',            
            }
        })
    }

    componentDidMount() {
        axios.get(`/user/client/single/${this.props.username}`)
            .then(res => {
                this.setState({user:res.data.results});
            })
    }

    render(){
        return(
            <div>
                <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <h2>Add New Tile</h2>
                        <p>Select new tile for {this.state.user.firstName}</p>
                    </div>
                    <div className="col-md-9 col-xs-12">
                        <ul className="progressbar">
                            <li className="active">Step 1</li>
                            <li>Step 2</li>
                            <li>Step 3</li>
                            <li>Step 4</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="tileCategoriesContainer">
                        <div className="tileCategories" onClick={()=>this.moveToStep2('when you eat')}>
                            <h4>When You Eat</h4>
                            <p>Description Text</p>
                            <p><i>2 Active Tiles</i></p>
                        </div>
                        <div className="tileCategories" onClick={()=>this.moveToStep2('what you eat')}>
                            <h4>What You Eat</h4>
                            <p>Description Text</p>
                            <p><i>2 Active Tiles</i></p>
                        </div>
                        <div className="tileCategories" onClick={()=>this.moveToStep2('what you do')}>
                            <h4>What You Do</h4>
                            <p>Description Text</p>
                            <p><i>2 Active Tiles</i></p>
                        </div>
                    </div>
                    <button>Save &amp; Next Step</button>
                </div>
            </div>
        )

    }
}

export default withRouter(Step1);