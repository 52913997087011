import {postsApi} from "../../api/postsApi";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// get the number of unread messages for a user
export async function fetchUnreadInbox(username) {
    const {data: {result}} = await postsApi().getUnread(username);
    return result;
}

// get all messages for a user given a page
export async function fetchMessages(page=1, options) {
    if (options.archived) {
        const {data: {threads, pages}} = await postsApi().getArchived(page, options);
        return {threads, pages};
    } else {
        const {data: {threads, pages}} = await postsApi().getMessages(page, options);
        return {threads, pages};
    }
}

// get a single thread
export async function fetchThread(threadId) {
    const {data} = await postsApi().getThread(threadId);
    return data;
}

// set a single thread to be marked as read
export async function markThreadRead(threadId, username) {
    const {data} = await postsApi().setThreadRead(threadId, username);
    return data;
}

// set the archival status of a thread
export async function setArchivalStatus(archived=false, threadId, username){
    let status = archived ? 1: 0;
    const {data: {result}} = await postsApi().setArchiveStatus(threadId, status, username);
    return result;
}

// get list of coaches and their associated clients with unread messages
export async function fetchAdminCoachList() {
    const {data: {result}} = await postsApi().getAdminCoachList();
    return result;
}