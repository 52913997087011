import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {ImageUpload} from "./imageUpload/imageUploadContainer";
import {connect} from 'react-redux';
import ListItem from './ListItem';
import {Modal} from 'react-bootstrap';
import Error from '../../ErrorMessage/Error';
import {fetchSelf} from "../../../actions/action_user";

class Account extends React.Component {
    constructor(){
        super();
        this.state={
            firstName: '',
            lastName: '',
            email: '',
            password:'',
            address:'',
            city:'',
            state:'',
            zipcode:'',
            phone: '',
            title:'',
            biography:'',
            qualifications:[],
            hobbies:[],
            habits:[],
            profile_image:null,
            active:null,
            notification_setting: '',

            //image upload error
            image_message:null,

            //timekit stuff
            timekit_email:null,
            timekit_id:null,
            timekit_message:null,
            timekit_message_type:null,

            //holds temporary data for qualification, hobbies, and habits
            new_qualification:null,
            new_hobby:null,
            new_habit:null,

            //ensures all ids are unique
            id:0,

            //list of us states
            states:['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],

            //modal
            show:false,
        };
    }

    componentDidMount() {
        if(this.props.user === null){
            return this.props.history.push('/dashboard');
        }
        let url;
        if(this.props.user.permission_id === 3) url = `/user/admin/single/${this.props.user.username}`;
        if(this.props.user.permission_id === 2) url = `/user/coach/single/${this.props.user.username}`;

        axios.get(url)
      .then((res)=> {
        this.setUserState(res.data.results);
      })
        .catch((error)=>{
           console.log(error);
        });
    }

    setUserState=(userData)=>{
        let that = this;

        //add habits, hobbies, and qualifications here to ensure they have ids
        userData.qualifications.map(item=>this.addQualification(item.qualification));
        userData.hobbies.map(item=>this.addHobby(item.hobby));
        userData.habits.map(item=>this.addHabit(item.habit));
        this.setState({
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            address: userData.address,
            city: userData.city,
            state: userData.state,
            zipcode: userData.zipcode,
            phone: userData.phone,
            title: userData.title,
            biography: userData.biography,
            profile_image: userData.profile_image,
            timekit_id: userData.timekit_id,
            timekit_email: userData.timekit_email,
            active: userData.active,
            password: "",
            notification_setting: userData.notification_setting,
        }, () => console.log(that.state));
    }


  goBack() {
    window.history.back();
  }


  //package profile details and upload to server
  accountUpdate=(e)=>{
        e.preventDefault();
     let profile={
         first_name:this.state.firstName,
         last_name:this.state.lastName,
         email:this.state.email,
         address:this.state.address,
         city:this.state.city,
         state:this.state.state,
         zipcode:this.state.zipcode,
         phone:this.state.phone,
         title:this.state.title,
         biography:this.state.biography,
         active:this.state.active,
     };
     //only change password if provided
     if(this.state.password)profile.password=this.state.password;

     //change profile image
      if(this.state.profile_image)profile.profile_image=this.state.profile_image;

     //add on qualifications
      profile.qualifications = this.state.qualifications.map(item=>item.qualification);

      //add hobbies
      profile.hobbies = this.state.hobbies.map(item=>item.hobby);

      //add habits
      profile.habits = this.state.habits.map(item=>item.habit);

      // update message notification settings
      profile.notification_setting = this.state.notification_setting;

      let url;
      if(this.props.user.permission_id === 3) url = '/admin/update-details';
      if(this.props.user.permission_id === 2) url = '/coach/update-details';

     axios.put(url, profile)
         .then(result=>{
             let that=this;
             this.setState({message:'Change were saved', qualifications:[], hobbies:[], habits:[]}, ()=>that.handleShow());
             this.setUserState(result.data.result);
         })
         .catch(e=>console.log(e));
  };
    setProfileImage = (file)=>{
        let data = new FormData();
        data.append('image_upload', file);
        data.append('user', this.props.user.username);
        axios.post(`/user/add-photo`, data)
            .then(res=>{
                this.setState({
                    profile_image : res.data.result.profile_image,
                    formChanged : true,
                    imgUploaded : true,
                });
                this.props.fetchSelf();
            })
            .catch(e=>this.setState({image_message: e.message}));

    };

    //change the id that list items share
    //called whenever a new list item is generated
    newUniqueId=()=>{
      this.setState({id:this.state.id+1});
    };

    //render all the qualificaions a coach/admin has
    renderQualifications=()=>{
        let that = this;
      return this.state.qualifications.map(qualification=>{
          return <ListItem text={qualification.qualification} id={qualification.id} remove={that.removeQualification}/>
      });
    };

    addQualification=(qualification)=>{
        if(!qualification) return;
        if(qualification.trim() === '') return;
        qualification={id:this.state.id, qualification};
        this.newUniqueId();
        this.setState({qualifications:[...this.state.qualifications, qualification], new_qualification:''});
    };

    removeQualification=(id)=>{
        let qualifications = this.state.qualifications.filter(qualification=>qualification.id !== id);
        this.setState({qualifications});
    };

    //end functions relating to qualifications

    //render all the hobbies a coach/admin has
    renderHobbies=()=>{
        let that = this;
        return this.state.hobbies.map(hobby=>{
            return <ListItem text={hobby.hobby} id={hobby.id} remove={that.removeHobby}/>
        });
    };

    addHobby=(hobby)=>{
        if(!hobby) return;
        if(hobby.trim() === '') return;
        hobby={id:this.state.id, hobby};
        this.newUniqueId();
        this.setState({hobbies:[...this.state.hobbies, hobby], new_hobby:''});
    };

    removeHobby=(id)=>{
      let hobbies = this.state.hobbies.filter(hobby=>hobby.id !== id);
      this.setState({hobbies});
    };

    //end functions related to hobbies

    //render all the habits a coach/admin has
    renderHabits=()=>{
        let that=this;
        return this.state.habits.map(habit=>{
            return <ListItem text={habit.habit} id={habit.id} remove={that.removeHabit}/>
        });
    };

    addHabit=(habit)=>{
        if(!habit) return;
        if(habit.trim() === '') return;
        habit={id:this.state.id, habit};
        this.newUniqueId();
        this.setState({habits:[...this.state.habits, habit], new_habit:''});
    };

    removeHabit=(id)=>{
      let habits = this.state.habits.filter(habit=>habit.id !== id);
      this.setState({habits});
    };

    renderState=()=>{
      let selected_state=this.state.state;
      return this.state.states.map((state, i)=>{
         if(!selected_state){
             if(i === 0){
                 return <option selected value={null}>Select A State</option>;
             }else{
                 return <option value={state}>{state}</option>;
             }
         }else if(state.toLowerCase() === selected_state.toLowerCase()){
             return <option selected value={state}>{state}</option>;
         }else{
             return <option value={state}>{state}</option>;
         }
      });
    };

    confirmTimekit=(e)=>{
        e.preventDefault();
        let email = {email : this.state.timekit_email};
        let url = `/coach/confirm-timekit/user/${this.props.user.username}`;
        let that = this;
        axios.put(url, email)
            .then(res=>{
                that.setState({
                    timekit_message:'Successfully Confirmed',
                    timekit_message_type:'success',
                    timekit_id:res.data.result.timekit_id,
                    timekit_email:res.data.result.timekit_email,
                });
            })
            .catch(e=>{
                that.setState({
                    timekit_message:e.response.data.error,
                    timekit_message_type:'danger'
                });
            });
    };

    renderTimekitConfirmation=()=>{
        if(this.props.user){
            if(this.props.user.permission_id === 2){
                return(
                    <div className="row">
                        <div className="col-xs-12">
                            <h3>Confirm Timekit Account</h3>
                            <Error message={this.state.timekit_message} type={this.state.timekit_message_type}/>
                            <h4>Status {this.state.timekit_id ? 'Confirmed' : 'Not Confirmed'}</h4>
                            <h4>{this.state.timekit_id ? `Timekit ID: ${this.state.timekit_id}` : null}</h4>
                            {this.state.timekit_id ? this.renderAvailabilityButton() : null}
                            <label>Enter email used in timekit account</label>
                            <input type="text" placeholder='email...' value={this.state.timekit_email} onChange={(e)=>{this.setState({timekit_email:e.target.value})}}/>
                            <button className='btn' onClick={(e=>this.confirmTimekit(e))}>{this.state.timekit_id ? 'Change Email' : 'Confirm Email'}</button>
                        </div>
                    </div>
                );
            }
        }
    };

    renderAvailabilityButton=()=>{
        return(
            <div>
                <h4>Checking this will make you available to receive new members</h4>
                <label><input type="checkbox" checked={this.state.active} onChange={()=>this.setState({active : !this.state.active})}/> Available</label>

            </div>
        );
    };

    handleShow=()=>{
        this.setState({show:true});
    };

    handleHide=()=>{
        this.setState({show:false, message:''});
    };

	render() {

    return (
      <div>
          <h2 className='m-t:0'>Account</h2>
          <hr/>
      <form onSubmit={(e)=>this.accountUpdate(e)}>
        <div>
            <div className="row">
              <div className="col-xs-1">
                <div className="profile-img-container">
                    <Error message={this.state.image_message}/>
                  <ImageUpload image={this.state.profile_image} setProfileImage={this.setProfileImage}/>
                </div>
              </div>
            </div>
            <div className="row">
                  <div className="col-xs-6">
                    <div className='form-group'>
                        <label>Firstname</label>
                        <input name="firstname" type="text"
                        value={this.state.firstName}
                        onChange={(event)=>this.setState({firstName:event.target.value})}
                         />
                    </div>
                  </div>
                <div className="col-xs-6">
                    <div className='form-group'>
                        <label>Lastname</label>
                        <input
                        type="text"
                        value={this.state.lastName}
                        onChange={(event)=>this.setState({lastName:event.target.value})}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                  <div className="col-xs-12 col-md-3">
                    <div className='form-group'>
                        <label for="email">Email</label>
                        <input name="email" type="email"
                        value={this.state.email}
                        onChange={(event)=>this.setState({email:event.target.value})}
                         />
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-3">
                      <div className='form-group'>
                        <label for="title">Title</label>
                        <input name="title" type="text"
                        value={this.state.title}
                        onChange={(event)=>this.setState({title:event.target.value})}
                        />
                      </div>
                  </div>
                <div className="col-xs-12 col-md-3">
                    <div className="form-group">
                        <label for="password">Password</label>
                        <input
                            type="password"
                            className='form-control'
                            name='password'
                            placeholder={'********'}
                            value={this.state.password}
                            onChange={event => this.setState({password:event.target.value})}
                        />
                    </div>
                </div>
              </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <label>Biography</label>
                    <textarea className='form-control' name="bio" id="bio" cols="30" rows="10" value={this.state.biography} onChange={event => this.setState({biography:event.target.value})}> </textarea>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <ul>{this.renderQualifications()}</ul>
                    <div>
                        <input type="text" value={this.state.new_qualification} onChange={event => this.setState({new_qualification:event.target.value})}/>
                        <button className='btn btn--actions' onClick={(e)=>{e.preventDefault(); this.addQualification(this.state.new_qualification)}}>Add Qualification</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <ul>{this.renderHobbies()}</ul>
                    <div>
                        <input type="text" value={this.state.new_hobby} onChange={event => this.setState({new_hobby:event.target.value})}/>
                        <button className='btn btn--actions' onClick={(e)=>{e.preventDefault(); this.addHobby(this.state.new_hobby)}}>Add Hobby</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-xs-12">
                    <ul>{this.renderHabits()}</ul>
                    <div>
                        <input type="text" value={this.state.new_habit} onChange={event => this.setState({new_habit:event.target.value})}/>
                        <button className='btn btn--actions' onClick={(e)=>{e.preventDefault(); this.addHabit(this.state.new_habit)}}>Add Habit</button>
                    </div>
                </div>
            </div>
                <div className="row">
                  <div className="col-xs-12">
                      <div className='form-group'>
                          <label>Phone (xxx) xxx-xxxx</label>
                          <NumberFormat
                          format="(###) ###-####" mask="_"
                          className="phone-input"
                          type="tel"
                          value={this.state.phone}
                          onChange={(event)=>this.setState({phone:event.target.value.replace(/\D/g,'')})}
                          />
                      </div>
                  </div>
                </div>
                  <div className="row">
                      <div className="col-xs-12 col-md-3 form-group">
                          <label>Address</label>
                          <input type="text" value={this.state.address} onChange={event => this.setState({address:event.target.value})} className="form-control"/>
                      </div>
                      <div className="col-xs-12 col-md-3 form-group">
                          <label>City</label>
                          <input type="text" value={this.state.city} onChange={event => this.setState({city:event.target.value})} className="form-control"/>
                      </div>
                      <div className="col-xs-12 col-md-3 form-group">
                          <label>State</label>
                          <select className='form-control' onChange={e=>this.setState({state:e.target.value})}>{this.renderState()}</select>
                      </div>
                      <div className="col-xs-12 col-md-3 form-group">
                          <label>Zipcode</label>
                          <input type="text" value={this.state.zipcode} onChange={event => this.setState({zipcode:event.target.value})} className="form-control"/>
                      </div>
                    <div className="col-xs-12 col-md-3 form-group">
                      <label>Message Notification Emails</label>
                      <select value={this.state.notification_setting} className='form-control' onChange={e=>this.setState({notification_setting:e.target.value})}>
                        <option value='true'>Get Notifications</option>
                        <option value='false'>Opt Out</option>
                      </select>
                    </div>
                  </div>
            {this.renderTimekitConfirmation()}

              </div>

        <div className="footer-actions">
            <div className="container">
                <div className="row">
                    <div className="col-xs-6">
                        <button className="btn client-white" onClick={this.goBack}>Back</button>
                    </div>
                    <div className="col-xs-6 text-right">
                        <input className="btn primary" type="submit" value="SAVE CHANGES" />
                    </div>
                </div>
            </div>
        </div>
        </form>
          <Modal show={this.state.show} onHide={this.handleHide}>
              <Modal.Body>
                  {this.state.message}
              </Modal.Body>
          </Modal>
    </div>
    );
  }
}

function mapStateToProps({user}) {
    return{user};
}

export default withRouter(connect(mapStateToProps, {fetchSelf})(Account));
