import React from 'react';
import {withRouter} from 'react-router-dom';

class PrivacyStatement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render() {
		    return (
                <div></div>
            )
	}

}

export default withRouter(PrivacyStatement);