// npm modules
import React from 'react';
import { connect } from 'react-redux';


const ClientPathwayIntro = () => {

    return (
        <div className="container">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6  col-md-offset-3">        		    
				<h1>Your Personalized Pathway</h1>				
				<p>
					You may view your personalized Pathway report on your screen or download a PDF version for your reference. This report includes:
				</p>
				<ul>
					<li>Your Lifestyle Snapshot</li>
					<li>Your Health Goal</li>
					<li>Your Personalized Treo Pathway</li>
					<li>Next Steps</li>
				</ul>
			</div>
        </div>
    )
};

function mapStateToProps({ user, clientPathway }) {
	return { user, clientPathway }
}

export default connect(mapStateToProps)(ClientPathwayIntro);