import {FETCH_MESSAGE_BY_ID} from "../actions/action_message";
import {FETCH_MESSAGES_BY_USERNAME} from "../actions/action_message";
import {SET_MESSAGE} from "../actions/action_message";

export default function (state={},action ){
    switch (action.type){

        case SET_MESSAGE:
            console.log(action.payload);
            return Object.assign({}, action.payload);

        case FETCH_MESSAGE_BY_ID:
          if(action.payload){
            return Object.assign({}, action.payload.data);
          } else {
            return Object.assign({}, []);
          }
        case FETCH_MESSAGES_BY_USERNAME:

          if(action.payload){
            return Object.assign({}, action.payload);
          } else {
            return Object.assign({}, []);
          }
        // no default

    }
    return state;
}
