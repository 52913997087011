import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import parse from "date-fns/parse";
import format from "date-fns/format";

// styles
import styles from './scoreActivityGraph.module.css';

// components
import {GraphPlaceholder} from "../../../graphPlaceholder/graphPlaceholder";

const ScoreActivityGraph = props => {
    // props
    const {data} = props;

    if (!data) {
        return (
            <div className={styles.container}>
                <GraphPlaceholder/>
            </div>
        )
    }

    const dateFormatter = date => {
        const result =  parse(date, 'yyyy-MM-dd', new Date()).getTime();
        return format(result, 'MM/dd/yy');
    };

    return (
        <div>
            <div className={styles.container}>
                <ResponsiveContainer width="100%" height="100%" debounce={1}>
                    <LineChart
                        data={data}
                        margin={{
                            top: 15,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <XAxis
                            dataKey="date"
                            tickFormatter={dateFormatter}/>/>
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="total" stroke="#4BBC4E" dot={false} strokeWidth={3}/>
                        <Line type="monotone" dataKey="live" stroke="#A59735" dot={false}/>
                        <Line type="monotone" dataKey="log" stroke="#4F86A0" dot={false}/>
                        <Line type="monotone" dataKey="learn" stroke="#F75D14" dot={false}/>
                        <Line type="monotone" dataKey="bonus" stroke="#9200A2" dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default ScoreActivityGraph;