import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

// styles
import styles from './community.module.css';

// actions
import {getBadges} from "../../store/actions/badgesActions";
import {fetchLeaderboard} from "../../store/actions/leaderboardActions";

// components
import BadgePath from './badgePath/badgePath';
import {VIEWS} from "./views";
import Leaderboard from "./leaderboard/leaderboard";
import ClientHeader from "../clientHeader/clientHeader";
import Feed from "./feed/feed";
import MyVictories from "./myVictories/myVictories";
import { BADGES } from '../../serverVariables/badges';

const Community = props => {
    // props
    const {badges} = props;
    // state
    const {user, communityNotifications} = props;
    // actions
    const {getBadges} = props;
    // local
    const [rankId, setRankId] = useState(user.game.rank_id);
    const [rankName, setRankName] = useState(user.game.rank_name);
    const [view, setView] = useState(VIEWS.COMMUNITY);
    const [leaderboard, setLeaderboard] = useState([]);
    const [community, setCommunity] = useState(selectDefaultCommunity());

    function selectDefaultCommunity() {
        const {community_all, community_org} = user.organization;
        if (community_all && community_org) {
            return {all: false, org: true};
        } else if (community_all) {
            return {all: true, org: false};
        } else {
            return {all: false, org: true};
        }
    }

    useEffect(() => {
        if (community.all) refreshLeaderboard();
        if (community.org) refreshLeaderboard(true)
    }, [community]);

    useEffect(() => {
        getBadges();



    }, [getBadges]);


    console.log(BADGES)
    

    async function refreshLeaderboard(onlyOrg) {
        try {
            const board = await fetchLeaderboard(onlyOrg);
            setLeaderboard(board);
        } catch (e) {
            console.log(e);
        }
    }

    function changeRank(rankId, rankName) {
        setRankId(rankId);
        setRankName(rankName);
    }

    return (
        <>
            <div className="client-container">
                <ClientHeader backUrl={`/member/clientDash/${user.username}`}/>
            </div>
            <div className={`${styles.containerOuter}`}>
                <div className={`${styles.containerInner}`}>
                    {/* NAVIGATION */}
                    <div className={styles.buttonContainer} data-cy="Comm-Nav-Btn">
                        <button
                            onClick={() => setView(VIEWS.COMMUNITY)}
                            className={view === VIEWS.COMMUNITY ? styles.active : ''}>Community</button>
                        <button
                            onClick={() => setView(VIEWS.VICTORIES)}
                            className={view === VIEWS.VICTORIES ? styles.active : ''}>My Victories {communityNotifications > 0 ? <span className={styles.notification}>{communityNotifications}</span> : null}</button>
                        <button
                            onClick={() => setView(VIEWS.LEADERBOARD)}
                            className={view === VIEWS.LEADERBOARD ? styles.active : ''}>Leaderboard</button>
                        <button
                            onClick={() => setView(VIEWS.BADGES)}
                            className={view === VIEWS.BADGES ? styles.active : ''}>Badges</button>
                    </div>


                    {view === VIEWS.COMMUNITY ?
                        <Feed
                            community={community}
                            setCommunity={setCommunity}/> : null}

                    {view === VIEWS.BADGES ? <BadgePath
                        rank={rankId}
                        rankName={rankName}
                        changeRank={changeRank}
                        data={badges.find(level => level.rank.id === rankId)}/> : null}

                    {view === VIEWS.VICTORIES ? <MyVictories/> : null}

                    {view === VIEWS.LEADERBOARD ?
                        <Leaderboard
                            community={community}
                            setCommunity={setCommunity}
                            nicknameStatus={user.nickname_status}
                            nickname={user.nickname}
                            leaderboard={leaderboard}/>: null}
                </div>
            </div>
        </>
    )
}

function mapStateToProps({badges, user, communityNotifications}){
    return {badges, user, communityNotifications};
}
const actions = {
    getBadges,
}
export default withRouter(connect(mapStateToProps, actions)(Community));