import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from "react-router-dom";

// styles
import styles from './signupLoading.module.css';

import BlueRing from '../../../images/blue ring.png';
import YellowRing from '../../../images/yellow ring.png';
import GreenRing from '../../../images/green ring.png';
// component
import { ErrorAlert } from '../../alerts/alerts';

const rotate = 180;

const SignUpLoading = ({ finished, url, history , error, setError}) => {
    const rotation = useRef(0);
    const completed = useRef(false);
    const finishedRef = useRef(finished);



    useEffect(() => {
        finishedRef.current = finished;
    }, [finished]);

    useEffect(() => {
        const heading = document.getElementById('heading');
        const green = document.getElementById('green');
        const yellow = document.getElementById('yellow');
        const blue = document.getElementById('blue');
        const greenText = document.getElementById('greenText');
        const yellowText = document.getElementById('yellowText');
        const blueText = document.getElementById('blueText');
        const tagline = document.getElementById('tagline');

        // begin rotating rings
        const rotationTimer = setInterval(() => {
            green.style.transform = `rotate(${rotation.current + rotate}deg)`;
            yellow.style.transform = `rotate(${rotation.current + rotate}deg)`;
            blue.style.transform = `rotate(${rotation.current + rotate}deg)`;
            rotation.current = rotation.current + rotate;
        }, 4000);

        const completedTimer = setInterval(() => {
            if (completed.current && finishedRef.current && url) {
                history.push(url);
            }
        }, 4000);

        heading.classList.add(styles.show);

        setTimeout(() => {
            green.style.opacity = '1';
            greenText.style.opacity = '1';
        }, 1000);
        setTimeout(() => {
            greenText.style.opacity = '0';
            yellow.style.opacity = '1';
            yellowText.style.opacity = '1';
        }, 4000);
        setTimeout(() => {
            yellowText.style.opacity = '0';
            blue.style.opacity = '1';
            blueText.style.opacity = '1';
        }, 8000);
        setTimeout(() => {
            blueText.style.opacity = '0';
            tagline.style.opacity = '1';
            completed.current = true;
        }, 12000);

        const timeoutPromise = new Promise((resolve) => {
            const timeout = setTimeout(() => {
              resolve();
            }, 20000);
          });
        
          const runTimeoutAndNavigate = async () => {
            await timeoutPromise;
          };
        
          runTimeoutAndNavigate();

        return () => {
            clearInterval(rotationTimer);
            clearInterval(completedTimer);
            // clearTimeout(timeout);
        };
    }, [history, url]);


    return (
        <div className={styles.mainContainer}>
            {error ? ( 
                <ErrorAlert className={styles.error}>{error}</ErrorAlert>
                
            ) : (
                <>
                    <p id='heading' className={styles.heading}>Getting your account ready</p>
                    <div className={styles.ringContainer}>
                        <img id='green' className={styles.ring} src={GreenRing} alt=""/>
                        <img id='yellow' className={styles.ring} src={YellowRing} alt=""/>
                        <img id='blue' className={styles.ring} src={BlueRing} alt=""/>
                        <p id='greenText' className={styles.text} style={{color: '#6ba543'}}>What You Eat</p>
                        <p id='yellowText' className={styles.text} style={{color: '#eebf38'}}>When You Eat</p>
                        <p id='blueText' className={styles.text} style={{color: '#4e88a1'}}>What You Do</p>
                        <p id='tagline' className={styles.text} style={{color: '#999999'}}>Stay Well. <br/> Be Well.</p>
                    </div>
                </>
            )}
        </div>
    );
}

export default withRouter(SignUpLoading);
