import { assessmentsApi } from "../../api/assessmentsApi";

//**************** Actions that involve the store *****************//

//**************** Actions that don't involve the store *****************//

// fetch a list of assessments for a user from the server
export async function fetchAssessmentList(userId) {
    const {data: {result}} = await assessmentsApi().getAssessments(userId);
    return result;
}

// get the assessment from the server
export async function fetchAssessment() {
    const {data: {result}} = await assessmentsApi().getAssessment();
    return result;
}

// get a taken assessment from the server with optional userId for admin
export async function fetchTakenAssessment(pathwayId, userId=null) {
    const {data: {result}} = await assessmentsApi().getTakenAssessment(pathwayId, userId);
    return result;
}

// set the health goal of a member
export async function updateHealthGoal(healthGoalId) {
    const {data: {result}} = await assessmentsApi().setHealthGoal(healthGoalId);
    return result;
}

// record an answer to an assessment
export async function updateAssessmentAnswer(pathwayId, questionId, answerId) {
    const {data: {result}} = await assessmentsApi().recordAnswer(pathwayId, questionId, answerId);
    return result;
}

// record an assessment attempt
export async function assessmentAttempt(pathwayId) {
    const {data: {result}} = await assessmentsApi().recordAssessmentAttempt(pathwayId);
    return result;
}

// legacy: get answer to a form/assessment question
export async function fetchAnswer(fieldId) {
    const {data: {result}} = await assessmentsApi().getAnswer(fieldId);
    return result;
}



export async function fetchAssessmentReminder(userId) {
    const {data: {result}} = await assessmentsApi().getAssessmentReminder(userId);
    return result;
}



