// npm modules
import React, { useState, useEffect } from 'react';
import FileDownload from "js-file-download";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import axios from "axios";

// hooks
import { usePrevious } from "../../hooks/usePrevious";

// components
import { BasicButton } from "../buttons/buttons";
import { BasicLink } from "../links/links";
import ClientPathwayIntro from "./clientPathwayIntro";
import ClientPathwayLifestyle from "./clientPathwayLifestyle";
import ClientPathwayHealthGoals from "./clientPathwayHealthGoals";
import ClientPathwayFundamentals from "./clientPathwayFundamentals";
import ClientPathwayPrimary from "./clientPathwayPrimary";
import ClientPathwayNextSteps from "./clientPathwayNextSteps";
import ClientPathwayReferences from "./clientPathwayReferences";
import { STATUSES } from "../../serverVariables/statuses";

const ClientPathwayReportContainer = props => {
	// state
	const { user, clientPathway } = props;
	


	// props
	const { history } = props;
	// local
    const [ page, setPage ] = useState(3);
	const [ totalPages ] = useState(5); // total imports - 1 because first page = 0
	const prevClientPathway = usePrevious(clientPathway); // hold the previous state of client pathways

	function downloadReport() {
		axios.get(`/pathways/download/${clientPathway.id}`, {
			responseType: 'arraybuffer',
			headers: {
				Accept: 'application/pdf',
			},
		})
			.then(res => {
				const fileName = `${user.first_name} ${user.last_name} Pathway Report.pdf`;
				FileDownload(res.data, fileName);
			})
	}

    function nextPage() {
		if ( page === totalPages) return; // last page
        setPage(page + 1);
        if (user && user.permission_id === 1) {
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		}
    }

    function prevPage() {
        if (page === 0) return; // first page
        setPage(page-1);
    }
	
	function viewReferences() {        
        setPage(6);
		if (user && user.permission_id === 1) {
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		}
    }
	 function renderPathway() {
		// Check page and variables, spit out stuff
		switch (page) {
		  case 0:
			// static - not dynamic
			setPage(1)
		  case 1:
			// Check if clientPathway has a "lifestyle" key before rendering
			if (clientPathway.lifestyle) {
			  return <ClientPathwayLifestyle clientPathway={clientPathway} />;
			} else {
				setPage(2)
			}
		  case 2:
			return <ClientPathwayHealthGoals clientPathway={clientPathway} />;
		  case 3:
			return <ClientPathwayFundamentals />;
		  case 4:
			  return <ClientPathwayPrimary clientPathway={clientPathway} />;
		  case 5:
			// static - not dynamic
			return <ClientPathwayNextSteps user={user} />;
		  case 6:
			return <ClientPathwayReferences clientPathway={clientPathway} />;
		  default:
			return <div />;
		}
	  }
	  
    function navigation() {
    	if (user.permission_id === 1) {
			// user is at 1st page
			if (page === 0) {
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
						<div>
							<BasicButton className="btn primary margin-bottom" onClick={nextPage} data-cy="vDigitalBtn">View Digital Report</BasicButton>
						</div>
						<div>
							<BasicButton className="btn primary outline transparent margin-bottom-med" onClick={downloadReport} data-cy="dPdfBtn">Download a PDF Version</BasicButton>
						</div>

					</div>
				)
			} else if(page === 2 && !clientPathway.lifestyle){
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
					<BasicButton className="btn primary transparent margin-top-3x" onClick={nextPage}>Next</BasicButton>
				</div>
				)
			}
			
			
			else if (page === totalPages) { // user is at last page
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">						
						<button className="references-link" onClick={viewReferences}>View Goal Specific References</button>
						<BasicButton className="btn primary outline transparent margin-right margin-top-3x" onClick={prevPage}>Back</BasicButton>
						<BasicLink to={`/member/clientDash/${user.username}`} className="btn primary margin-top-3x">Go to Dashboard</BasicLink>
					</div>
				)
			} else if (page > totalPages ) { // this is mainly used for the reference page because it's no longer part of the progression (included in total pages)
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">						
						<BasicButton className="btn primary outline transparent margin-right margin-top-3x" onClick={prevPage}>Back</BasicButton>						
					</div>
				)			
			} else {
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
						<BasicButton className="btn primary outline transparent margin-right margin-top-3x" onClick={prevPage}>Back</BasicButton>
						<BasicButton className="btn primary transparent margin-top-3x" onClick={nextPage}>Next</BasicButton>
					</div>
				)
			}
		} else {
			// user is at 1st page
			if (page === 0) {
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
						<BasicButton className="btn primary transparent margin-top-3x" onClick={nextPage}>Next</BasicButton>
					</div>
				)
			} else if (page === totalPages) { // user is at last page
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
						<BasicButton className="btn primary outline transparent margin-right margin-top-3x" onClick={prevPage}>Back</BasicButton>
					</div>
				)
			} else {
				return (
					<div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
						<BasicButton className="btn primary outline transparent margin-right margin-top-3x" onClick={prevPage}>Back</BasicButton>
						<BasicButton className="btn primary transparent margin-top-3x" onClick={nextPage}>Next</BasicButton>
					</div>
				)
			}
		}
	}

	// when clientPathway is updated, reset the page
	useEffect(() => {
		if (!_.isEqual(clientPathway, prevClientPathway)) {
			setPage(1);
		}
	}, [clientPathway]);

	if (_.isEmpty(clientPathway)) return ( <div/> );
        
    return (
		
			<div className='client-container pathwayreport-container'> 			
				{ renderPathway()}      
				<div className="container">
					{ navigation() }
				</div>
				{ user.user_status_id > STATUSES.onboarding_coach_consult_schedule && page === 0 ?
					<div className="footer-actions">
						<div className="container">
							<div className="row">
								<div className="col-xs-6">
									<button className="btn client-white" onClick={()=>history.push(`/member/clientDash/${user.username}/habits`)}>Back</button>
								</div>
							</div>
						</div>
					</div>
					: null
				}
			</div>
		
    )
};

function mapStateToProps({ user, clientPathway }) {
	return { user, clientPathway }
}

export default withRouter(connect(mapStateToProps)(ClientPathwayReportContainer));