//search subheader component 
import React from 'react';
import axios from 'axios';
import { FormGroup, FormControl, ControlLabel, Button, Form, Modal, } from 'react-bootstrap';
import {withRouter, Link} from 'react-router-dom';

class CoachClientSearch extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userCount:0,
            users: [],
            username: '',
        };
        this.getTiles = this.getTiles.bind(this);

    }


    onChange = (event) => {
      this.setState({ username: event.target.value });
    }

    getUsers(){
      return this.state.users.map(user =>{
          return <Link to={'/coach/Client/'+user.username}>
                      <li>
                          <div className="row">
                              <div className="col-md-4 col-s-6">
                                  <div>
                                      <img src="http://via.placeholder.com/30x30" alt="profile"/>
                                  </div>
                                  <div>
                                      <h4>{`${user.firstName}, ${user.lastName}`}</h4>
                                      <p>Last Communication : X Weeks</p>
                                      <p>Last Active : X Days</p>
                                  </div>
                              </div>
                              <div className="col-md-2 col-sm-6">
                                  <p><span>0/</span>0</p>
                                  <p><span>Today</span> Avg</p>
                              </div>
                              <div className="col-md-2">

                              </div>
                              <div className="col-md-2">

                              </div>
                              <div className="col-md-2">

                              </div>
                          </div>
                      </li>
                </Link>
        });
      }

    getTiles(e) {
      e.preventDefault();
      axios.get(`/user/clients/${this.state.username}/1`)
        .then(res => {
          const users = res.data;
          this.setState({users : users.results});
          
        })      
    }

    componentDidMount() {
        axios.get('/user/clients/1')
        .then(res => {
          this.setState({users: res.data.results});
            this.setState({userCount :res.data.results.length});
        })
            .catch(err=>console.log(err));
    }

	render() {
		return(
			<div>
		      <div className="searchForm-container">
		        <div className="searchForm-text-button-container">
		          <h3 className="searchForm-text">Members</h3>
                <div>

                <Button className="searchForm-addNew"  bsSize="sm" onClick={this.handleShow}>
                  + Add New Member
                </Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                      dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                      ac consectetur ac, vestibulum at eros.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.handleClose}>Close</Button>
                  </Modal.Footer>
                </Modal>
              </div>

		        </div>
		        <Form inline onSubmit={this.getTiles}>
		          <FormGroup value={this.state.username} onChange={this.onChange} controlId="formInlineName">
		            <ControlLabel>Name</ControlLabel>{' '}
		            <FormControl  type="text" placeholder="Jane Doe" />
		          </FormGroup>{' '}
		          <FormGroup controlId="formControlsSelect">
		            <ControlLabel>Overall Status</ControlLabel>
		            <FormControl componentClass="select" placeholder="select">
		              <option value="select">select</option>
		              <option value="other">...</option>
		            </FormControl>
		          </FormGroup>
		              <FormGroup controlId="formControlsSelect">
		            <ControlLabel>Last active</ControlLabel>
		            <FormControl componentClass="select" placeholder="select">
		              <option value="select">select</option>
		              <option value="other">...</option>
		            </FormControl>
		          </FormGroup>
		          <FormGroup controlId="formInlineEmail">
		            <ControlLabel>Current Tile(s)</ControlLabel>{' '}
		            <FormControl type="email" placeholder="" />
		          </FormGroup>{' '}
		          <Button className="searchForm-btn" type="submit">Search</Button>
		        </Form>
		      </div>
                <div className="row">
                    <h2 className="col-12-xs">{this.state.userCount} Members</h2>
                </div>
				<div>
                  <ul className="tiles-ul">
                    {this.getUsers()}
                  </ul>
				</div>
			</div>
			);
	}
};

export default withRouter(CoachClientSearch);