import React, { useEffect} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from 'react-redux';

// styles
import styles from './clientNavBar.module.css';

// actions
import {touchedHabitBuilder, setChangedScoreFlag, getDailyScore} from "../../../store/actions/wellnessScoreActions";
import {checkAlerts} from "../../../store/actions/alertActions";
import {fetchCommunityNotifications} from "../../../store/actions/communityActions";

// images
import {ReactComponent as CommunityIcon} from '../../../images/icons/Menu_Community.svg';
import {ReactComponent as CommunitySelectedIcon} from '../../../images/icons/Menu_Selected_Comm.svg';
import {ReactComponent as PathwayIcon} from '../../../images/icons/Menu_Pathway.svg';
import {ReactComponent as PathwaySelectedIcon} from '../../../images/icons/Menu_Selected_Pathway.svg';
import {ReactComponent as HomeIcon} from '../../../images/icons/Menu_Home.svg';
import {ReactComponent as HomeSelectedIcon} from '../../../images/icons/Menu_Selected_Home.svg';
import {ReactComponent as HabitIcon} from '../../../images/icons/Menu_Habit.svg';
import {ReactComponent as HabitSelectedIcon} from '../../../images/icons/Menu_Selected_Habit.svg';
import {ReactComponent as MoreIcon} from '../../../images/icons/Menu_More.svg';
import {ReactComponent as MoreSelectedIcon} from '../../../images/icons/Icons_Selected_More.svg';

const ClientNavBar = props => {
    // props
    const {match, location, more, openMore, closeMore} = props;
    // state
    const {user, dailyScore, communityNotifications} = props;
    // actions
    const {checkAlerts, getDailyScore, setChangedScoreFlag, fetchCommunityNotifications} = props;

    useEffect(() => {
        loadNotifications();
        const timer = setInterval(loadNotifications, 1000*60);
        return () => {
            clearInterval(timer);
        }
    }, []);

    function matchLocation(urls) {
        let didMatch = false;
        for(let url of urls) {
            const regex = new RegExp(url);
            if (regex.test(location.pathname)) {
                didMatch = true;
                break;
            }
        }
        if (more) return false;
        return didMatch;
    }

    async function clickedHB() {
        closeMore();
        try {
            const res = await touchedHabitBuilder();
            if (res.alert_waiting) {
                const newScore = parseInt(res.daily_score.score);
                setChangedScoreFlag(newScore-dailyScore);
                await getDailyScore();
                await checkAlerts();
            }
        } catch(e) {
            console.log(e);
        }
    }

    function loadNotifications() {
        try {
            fetchCommunityNotifications();
        } catch (e) {
            console.log(e);
        }
    }

    const HOME_LINKS = [
        `^${match.url}/clientDash/${user.username}$`,
        `^${match.url}/featured`,
        `^${match.url}/score`,
    ];
    const PATHWAY_LINKS = [
        `^${match.url}/pathways/${user.username}`,
        `^${match.url}/just_for_you`,
    ];
    const COMMUNITY_LINKS = [
        `^${match.url}/community`,
        `^${match.url}/view-victories`,
    ];

    const HABIT_LINKS = [
        `^${match.url}/clientDash/${user.username}/habits`,
        `^${match.url}/tile`,
        `^${match.url}/addActivity`,
    ];

    return (
        <div className={styles.container}>
            <Link onClick={closeMore}
                className={styles.link}
                to={`${match.url}/community`}>
                {matchLocation(COMMUNITY_LINKS) ? <CommunitySelectedIcon/> : <CommunityIcon/>}
                Community
                {communityNotifications > 0 ?
                    <span className={styles.notification}>
                        {communityNotifications > 9 ? '9+' : communityNotifications}
                    </span>
                    : null}
            </Link>
            <Link onClick={closeMore}
                className={`${styles.link} tour-pathwaysBtn tour-jfyBtn`}
                to={`${match.url}/pathways/${user.username}`}>
                {matchLocation(PATHWAY_LINKS) ? <PathwaySelectedIcon/> : <PathwayIcon/>}
                My Pathway
            </Link>
            <Link onClick={closeMore}
                className={styles.link}
                to={`${match.url}/clientDash/${user.username}`}>
                {matchLocation(HOME_LINKS) ? <HomeSelectedIcon/> : <HomeIcon/>}
                Home
            </Link>
            <Link
                onClick={clickedHB}
                className={`${styles.link} tour-habitBtn`}
                to={`${match.url}/clientDash/${user.username}/habits`}>
                {matchLocation(HABIT_LINKS) ? <HabitSelectedIcon/> : <HabitIcon/>}
                My Habits
            </Link>
            <button
                onClick={openMore}
                className={`${styles.link} tour-open-nav`}
                data-cy="navMoreBtn">
                {more ? <MoreSelectedIcon/> : <MoreIcon/>}
                More
            </button>
        </div>
    )
}

function mapStateToProps({user, dailyScore, communityNotifications}){
    return {user, dailyScore, communityNotifications};
}

const actions = {
    setChangedScoreFlag,
    getDailyScore,
    checkAlerts,
    fetchCommunityNotifications,
}

export default withRouter(connect(mapStateToProps, actions)(ClientNavBar));