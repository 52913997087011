import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

// styles
import styles from './organizationSingle.module.css';

// actions
import { getSingleOrganization } from "../../actions/action_organizations";

// components
import Loading from "../Loading/Loading";
import OrganizationInfo from './organizationInfo';
import { ErrorAlert } from "../alerts/alerts";
import OrganizationOverview from "./organizationOverview/organizationOverview";
import OrganizationClients from "./organizationClients/oganizationClients";
import OrganizationNewClient from "./organizationClients/organizationNewClient";
import OrganizationCoaches from "./organizationCoaches/organizationsCoaches";
import OrganizationCodes from "./organizationCodes/organizationCodes";
import OrganizationSeats from "./organizationSeats/organizatinSeats";
import OrganizationBrand from "./organizaitonBrand/organizationBrand";
import OrganizationInfoContainer from "./organizationInfo/organizationInfo";
import OrganizationWaitlist from "./organizationWaitlist/organizationWaitlist";
import LinkList from "../links/linkList/linkList";
import OrganizationContent from "./organizationContent/organizationContent";
import OrganizationStudio from './organizationStudio/OrganizationStudio';

const OrganizationSingle = ({ organization, getSingleOrganization, match }) => {
    const [ loadingError, setLoadingError ] = useState(false); // use to alert user that they've provided an incorrect id

    // on mount, grab the organization
    useEffect(() => {
       const orgId = parseInt(match.params.id);
       if (!orgId) return;
       getSingleOrganization(orgId)
           .catch( () => setLoadingError(true));
    }, [])

    // show user an error message
    if (loadingError) {
        return (
            <div className="container">
                <ErrorAlert>
                    Unable to load organization. If this error persists, please contact your system administrator.
                </ErrorAlert>
            </div>
        )
    }

    // ensure state is ready
    if (!organization) {
        return <Loading/>;
    }

    const links = [
        { href: `/dashboard/organization/${organization.id}/overview`, label: 'Overview' },
        { href: `/dashboard/organization/${organization.id}/coaches`, label: 'Coaches' },
        { href: `/dashboard/organization/${organization.id}/clients`, label: 'Members' },
        { href: `/dashboard/organization/${organization.id}/codes`, label: 'Codes' },
        { href: `/dashboard/organization/${organization.id}/branding`, label: 'Branding' },
        { href: `/dashboard/organization/${organization.id}/seats`, label: 'Seats' },
        { href: `/dashboard/organization/${organization.id}/info`, label: 'Info' },
        { href: `/dashboard/organization/${organization.id}/waitlist`, label: 'Waitlist' },
        { href: `/dashboard/organization/${organization.id}/content`, label: 'Content' },
        { href: `/dashboard/organization/${organization.id}/studioPlayer`, label: 'Studio Player' },
    ];

    return (
        <div>
            <div className='header-container'>
                <div className="container">
                    <OrganizationInfo organization={organization}/>
                </div>
            </div>
            <div className='container'>
                <div className={styles.mainContent}>
                    <div className={`margin-top-2x ${styles.links}`}>
                        <LinkList links={links}/>
                    </div>
                    <div className={styles.content}>
                        <div className="container">
                            <Route path={`${match.url}/overview`} render={() => <OrganizationOverview organization={organization}/>}/>
                            <Route path={`${match.url}/coaches`} render={() => <OrganizationCoaches organization={organization}/>}/>
                            <Route path={`${match.url}/clients`} render={() => {
                                return (
                                    <div>
                                        <Route path={`${match.url}/clients`} exact render={() => <OrganizationClients organization={organization}/>}/>
                                        <Route path={`${match.url}/clients/new`} render={() => <OrganizationNewClient organization={organization}/>}/>
                                    </div>
                                )
                            }}/>
                            <Route path={`${match.url}/codes`} render={() => <OrganizationCodes organization={organization}/>}/>
                            <Route path={`${match.url}/seats`} render={() => <OrganizationSeats organization={organization}/>}/>
                            <Route path={`${match.url}/branding`} render={() => <OrganizationBrand organization={organization}/>}/>
                            <Route path={`${match.url}/info`} render={() => <OrganizationInfoContainer organization={organization}/>}/>
                            <Route path={`${match.url}/waitlist`} render={() => <OrganizationWaitlist organization={organization}/>}/>
                            <Route path={`${match.url}/content`} render={() => <OrganizationContent organization={organization}/>}/>
                            <Route path={`${match.url}/studioPlayer`} render={() => <OrganizationStudio organization={organization}/>}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

function mapStateToProps({ organization }) {
    return {
        organization,
    }
}

const actions = {
    getSingleOrganization,
}
export default connect(mapStateToProps, actions)(OrganizationSingle);