import React from "react";
import { Link } from "react-router-dom";

// styles
import styles from "./BackButton.module.css";

// images
import { ReactComponent as BackArrow } from "../images/icons/back_arrow_long_tail.svg";

const BackButton = (props) => {
  // props
  const { to, color, fontSize, IconSize = "35px" } = props;

  return (
    <Link
      to={to}
      type="button"
      className={`${styles.back}`}
      style={{ all: "unset", cursor: "pointer", color: color || "unset" }}
    >
      {" "}
      <div style={{ display: "flex", gap: "10px", justifyContent:'center', alignItems: "center", display: "inline-block"}}>
      <BackArrow style={{ fill: color, width: IconSize  || "unset" }} /> 
      <p style={{fontSize: fontSize, display: "inline-block"}}>Back</p>
      </div>

    </Link>
  );
};

export default BackButton;
