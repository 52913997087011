import React, {useState} from 'react';
import validate from "validate.js";
import _ from 'lodash';
import getDaysInMonth from 'date-fns/getDaysInMonth';

// styles
import styles from './profileQuestions.module.css';

// components
import {PrimaryButton} from "../../buttons/buttons";
import Loading from "../../Loading/Loading";

const ProfileQuestions = props => {
    // props
    const {finish} = props;
    // local
    const [form, setForm] = useState({
        weight: null,
        height: null,
        gender: null,
        month: null,
        day: null,
        year: null
    });
    const [busy, setBusy] = useState(false);
    const [errors, setErrors] = useState({});

    function createWeightArray() {
        const weights = [];
        for(let i=80; i<400; i++){
            weights.push(i);
        }
        return weights;
    }

    function submit(e) {
        e.preventDefault();
        setBusy(true);
        let errors = {};
        const constraints = {
            month: {presence: {allowEmpty: false}},
            day: {presence: {allowEmpty: false}},
            year: {presence: {allowEmpty: false}},
            weight: {presence: {allowEmpty: false}},
            height: {presence: {allowEmpty: false}},
            gender: {presence: {allowEmpty: false}},
        };
        errors = validate(form, constraints);
        if (!_.isEmpty(errors)) {
            setBusy(false);
            return setErrors(errors);
        }
        setErrors(errors);
        finish(new Date(form.year, form.month, form.day), form.weight, form.height, form.gender);
    }

    function renderDaysInMonth() {
        let days = 31;
        if (form.month) {
            let year = form.year || new Date().getFullYear();
            days = getDaysInMonth(new Date(year, parseInt(form.month)));
        }
        const options = [];
        for(let i=1; i<=days; i++) {
            options.push(<option value={i}>{i}</option>)
        }
        if (form.day > days) setForm({...form, day: 1});
        return options;
    }

    function renderYears() {
        const options =[];
        for(let i=new Date().getFullYear()-18; i>=1930; i--) {
            options.push(<option value={i}>{i}</option>);
        }
        return options;
    }


    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>Almost finished!</h2>
            <p className='m:0 margin-bottom-15'>Complete the profile questions below</p>
            <form onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="birthday">What is your date of birth?</label>
                    <div className={styles.birthday} data-cy="proBirth">
                        <select
                            onChange={e => setForm({...form, month: e.target.value})}
                            value={form.month}>
                            <option disabled={form.month} value="">Month</option>
                            <option value={0}>Jan</option>
                            <option value={1}>Feb</option>
                            <option value={2}>Mar</option>
                            <option value={3}>Apr</option>
                            <option value={4}>May</option>
                            <option value={5}>June</option>
                            <option value={6}>July</option>
                            <option value={7}>Aug</option>
                            <option value={8}>Sept</option>
                            <option value={9}>Oct</option>
                            <option value={10}>Nov</option>
                            <option value={11}>Dec</option>
                        </select>
                        <select
                            onChange={e => setForm({...form, day: e.target.value})}
                            value={form.day}>
                            <option disabled={form.day} value="">Day</option>
                            {renderDaysInMonth()}
                        </select>
                        <select
                            onChange={e => setForm({...form, year: e.target.value})}
                            value={form.year}>
                            <option disabled={form.year} value="">Year</option>
                            {renderYears()}
                        </select>
                    </div>
                    {errors && (errors.month || errors.day || errors.year) ? <span className="text-danger">Invalid Birthday</span> : null}
                </div>
                <div className="form-group">
                    <label htmlFor="weight">How much do you weigh? (lbs)</label>
                    <select
                        id="weight"
                        className="form-control"
                        value={form.weight}
                        onChange={e => setForm({...form, weight: e.target.value})}>
                        <option value="">Select a weight</option>
                        {createWeightArray().map(weight => (
                            <option value={weight}>{weight}</option>
                        ))}
                    </select>
                    {errors && errors.weight ? errors.weight.map(err => <span className="text-danger">{err}</span>) : null}
                </div>
                <div className="form-group">
                    <label htmlFor="height">How tall are you?</label>
                    <select
                        id="height"
                        className="form-control"
                        value={form.height}
                        onChange={e => setForm({...form, height: e.target.value})}>
                        <option value=''>Select a height</option>
                        <option value="48">4'0</option>
                        <option value="49">4'1</option>
                        <option value="50">4'2</option>
                        <option value="51">4'3</option>
                        <option value="52">4'4</option>
                        <option value="53">4'5</option>
                        <option value="54">4'6</option>
                        <option value="55">4'7</option>
                        <option value="56">4'8</option>
                        <option value="57">4'9</option>
                        <option value="58">4'10</option>
                        <option value="59">4'11</option>

                        <option value="60">5'0</option>
                        <option value="61">5'1</option>
                        <option value="62">5'2</option>
                        <option value="63">5'3</option>
                        <option value="64">5'4</option>
                        <option value="65">5'5</option>
                        <option value="66">5'6</option>
                        <option value="67">5'7</option>
                        <option value="68">5'8</option>
                        <option value="69">5'9</option>
                        <option value="70">5'10</option>
                        <option value="71">5'11</option>

                        <option value="72">6'0</option>
                        <option value="73">6'1</option>
                        <option value="74">6'2</option>
                        <option value="75">6'3</option>
                        <option value="76">6'4</option>
                        <option value="77">6'5</option>
                        <option value="78">6'6</option>
                        <option value="79">6'7</option>
                        <option value="80">6'8</option>
                        <option value="81">6'9</option>
                        <option value="82">6'10</option>
                        <option value="83">6'11</option>

                        <option value="84">7'0</option>
                        <option value="85">7'1</option>
                        <option value="86">7'2</option>
                        <option value="87">7'3</option>
                        <option value="88">7'4</option>
                        <option value="89">7'5</option>
                    </select>
                    {errors && errors.height ? errors.height.map(err => <span className="text-danger">{err}</span>) : null}
                </div>
                <div className="form-group">
                    <label htmlFor="height">What is your gender?</label>
                    <select
                        id="height"
                        className="form-control"
                        value={form.gender}
                        onChange={e => setForm({...form, gender: e.target.value})}>
                        <option value="">Select Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                        <option value="prefer not to say">Other</option>
                    </select>
                    {errors && errors.gender ? errors.gender.map(err => <span className="text-danger">{err}</span>) : null}
                </div>
            </form>
            <div className="t-a:c">
                {busy ?
                    <Loading/>
                    :
                    <PrimaryButton disabled={busy} onClick={submit} data-cy="proBtn">Submit</PrimaryButton>
                }
            </div>
        </div>
    )
}
export default ProfileQuestions;