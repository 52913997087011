import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "react-bootstrap";
import validate from "validate.js";
import { parse as parseQuery } from "query-string";

// styles
import styles from "./category.module.css";

// actions
import { fetchAssignedOrgs } from "../../../store/actions/contentActions";

// components
import CategoryRow from "../categoryRow/categoryRow";
import { DangerButton, PrimaryButton } from "../../buttons/buttons";
import { ReactComponent as BackArrow } from "../../../images/back_arrow.svg";
import PhoneDisplay from "../phoneDisplay/phoneDisplay";
import {
  SaveAlert,
  SecondaryInfoAlert,
  SecondaryWarningAlert,
} from "../../alerts/alerts";
import { CONTENT_TYPES } from "../../../serverVariables/contentTypes";
import { ImageThumbnailDetailed } from "../../imageThumbnail/imageThumbnailDetailed";
import Loading from "../../Loading/Loading";

const constraints = {
  title: {
    presence: { message: "^Category title is required" },
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^Category title is required",
    },
  },
  instructor: {
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^Instructor label is required",
    },
  },
  collectionLabel: {
    type: "string",
    length: {
      minimum: 1,
      tooShort: "^Collection label is required",
    },
  },
};

const Category = (props) => {
  const {
    contentType,
    category,
    subcategories,
    content,
    getCategory,
    getContent,
    cleanUp,
    setCategoryOrder,
    setContentOrder,
    saveCategory,
    remove,
    match,
    history,
  } = props;

  // local
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [headerImage, setHeaderImage] = useState(null);
  const [tileImage, setTileImage] = useState(null);
  const [studioImage, setStudioImage] = useState(null);
  const [overviewModal, setOverviewModal] = useState(false);
  const [overview, setOverview] = useState(null);
  const [tempReorder, setTempReorder] = useState(null);
  const [saving, setSaving] = useState(false);
  const [finishedSaving, setFinishedSaving] = useState(false);
  const [showWarning, setShowWarning] = useState({
    category: null,
    show: false,
  });
  const [validation, setValidation] = useState(null);
  const [assigned, setAssigned] = useState([]);
  const [parent, setParent] = useState(null);
  const [collection, setCollection] = useState(false);
  const [collectionLabel, setCollectionLabel] = useState("");
  const [instructor, setInstructor] = useState("");
  const HAS_PARENT = isChild();

  useEffect(() => {
    setLoading(true);
    cleanUp(contentType ? contentType.id : null);
    setTitle("");
    setDescription("");
    setHeaderImage(null);
    setTileImage(null);
    setAssigned([]);
    if (match.params.category !== "new") {
      Promise.all([
        getContent(match.params.category),
        getCategory(match.params.category).then((cat) => {
          setTitle(cat.category_name);
          setDescription(cat.category_description);
          setHeaderImage(cat.hero_image_url);
          setTileImage(cat.tile_image_url);
          setStudioImage(cat.studio_image_url);
          if (cat.parent_category_id) {
            setParent(parseQuery(props.location.search).parent);
          }
        }),
        fetchAssignedOrgs(match.params.category).then((orgs) =>
          setAssigned(orgs)
        ),
      ]).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    return () => cleanUp(contentType ? contentType.id : null);
  }, [match.params.category]);

  useEffect(() => {
    setInstructor(category.instructor_label);
  }, [category.instructor_label]);

  useEffect(() => {
    setCollectionLabel(category.collection_label);
  }, [category.collection_label]);

  useEffect(() => {
    setCollection(category.collection);
  }, [category.collection]);

  function isChild() {
    return (
      (match.params.category === "new" &&
        !parseQuery(props.location.search).main) ||
      category.parent_category_id
    );
  }

  // function that's called when a category is dragged and released
  const onDragEnd = async ({ destination, source }) => {
    // do nothing if no movement was made
    if (!destination) return;
    if (destination.index === source.index) return;
    if (subcategories.length <= 1) return; // can't reorder a list of 1

    const clone = [...subcategories];
    const [removed] = clone.splice(source.index, 1);
    clone.splice(destination.index, 0, removed);
    setCategoryOrder(clone);
  };

  // function that's called when content is dragged and released
  const onDragEndContent = async ({ destination, source }) => {
    // do nothing if no movement was made
    if (!destination) return;
    if (destination.index === source.index) return;
    if (content.length <= 1) return; // can't reorder a list of 1

    // check if item was an overview or if item is displacing overview
    if (source.index === 0 || destination.index === 0) {
      // find overview if exists
      const foundOverview = content.find((item) => item.overview === 1);
      if (foundOverview) {
        // show modal warning
        setOverview(foundOverview);
        setOverviewModal(true);
        setTempReorder({ source, destination });
        return;
      }
    }

    const clone = [...content];
    const [removed] = clone.splice(source.index, 1);
    clone.splice(destination.index, 0, removed);
    setContentOrder(clone);
  };

  const hideOverviewModal = () => {
    setOverviewModal(false);
    setOverview(null);
    setTempReorder(null);
  };

  const finishReorder = async () => {
    const { source, destination } = tempReorder;
    const clone = [...content];
    const [removed] = clone.splice(source.index, 1);
    clone.splice(destination.index, 0, removed);
    clone.forEach((item) => (item.overview = 0));
    setContentOrder(clone);
    hideOverviewModal();
  };

  const submit = (e) => {
    e.preventDefault();
    setFinishedSaving(false);
    setSaving(true);

    const info = {
      id: category.category_id,
      title,
      description,
      hero: headerImage,
      tile: tileImage,
      studio: studioImage, 
      instructor,
      collection,
      collectionLabel,
      parent,
    };

    // Validation and submission logic
    const formedConstraints = { title: constraints.title };
    if (
      contentType.id === CONTENT_TYPES.FEATURED ||
      contentType.id === CONTENT_TYPES.ORGANIZATION
    ) {
      if (HAS_PARENT) {
        formedConstraints.instructor = constraints.instructor;
        if (!collection) {
          formedConstraints.collectionLabel = constraints.collectionLabel;
        }
      }
    }
    const valid = validate(
      { title, instructor, collectionLabel },
      formedConstraints
    );
    if (valid) {
      setValidation(valid);
      setSaving(false);
      return;
    } else {
      setValidation(null);
    }

    saveCategory(info).then((result) => {

    console.log(result, 'submitted data')
      setFinishedSaving(true);
    });
  };

  const hideWarning = () => {
    setShowWarning({ category: null, show: false });
  };

  const confirmRemove = (category) => {
    remove(category);
    hideWarning();
  };

  function goBack() {
    let link = "/dashboard/Content/type/";
    if (contentType) link += contentType.id;
    if (match.params.category === "new") {
      if (parseQuery(props.location.search).main) return link;
      if (!parent) {
        link += `/cat/${parseQuery(props.location.search).parent}`;
      } else {
        link += `/cat/${parent}`;
      }
    } else if (HAS_PARENT) {
      link += `/cat/${category.parent_category_id}`;
    }
    return link;
  }

  function sendToNewCategoryPage() {
    let link = "/dashboard/Content/type/";
    if (contentType) link += contentType.id;
    link += `/cat/new?parent=${category.category_id}`;
    return link;
  }

  function showAddNewCategory() {
    setParent(category.category_id);
    history.push(sendToNewCategoryPage());
  }

  function showSideMeta() {
    if (contentType) {
      if (
        contentType.id === CONTENT_TYPES.FEATURED ||
        contentType.id === CONTENT_TYPES.ORGANIZATION ||
        contentType.id === CONTENT_TYPES.FITNESS
      ) {
        if (!HAS_PARENT) {
          return (
            <>
              <h5>Tile Image</h5>
              <ImageThumbnailDetailed
                image={tileImage}
                setImage={setTileImage}
              />

              <h5>Studio Image</h5>
              <ImageThumbnailDetailed
                image={studioImage}
                setImage={(image) => {
                  setStudioImage(image);
                }}
              />
            </>
          );
        } else {
          return (
            <div className="margin-bottom-15 pos:r">
              <form className={styles.collection}>
                <div className={`form-group ${styles.collectionCheckbox}`}>
                  <input
                    className="form-control"
                    checked={collection}
                    onChange={(e) => setCollection(e.target.checked)}
                    disabled={subcategories.length}
                    type="checkbox"
                    id="collection"
                  />
                  <label htmlFor="collection">Collection</label>
                </div>
                {!collection ? (
                  <div className="form-group">
                    <label htmlFor="collectionLabel">Collection Label</label>
                    <input
                      className="form-control"
                      type="text"
                      id="collectionLabel"
                      value={collectionLabel}
                      onChange={(e) => setCollectionLabel(e.target.value)}
                    />
                    {validation && validation.collectionLabel ? (
                      <div>
                        {validation.collectionLabel.map((err) => (
                          <span className="text-danger d:b">{err}</span>
                        ))}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="instructor">Instructor Label</label>
                  <input
                    className="form-control"
                    type="text"
                    id="instructor"
                    value={instructor}
                    onChange={(e) => setInstructor(e.target.value)}
                  />
                  {validation && validation.instructor ? (
                    <div>
                      {validation.instructor.map((err) => (
                        <span className="text-danger d:b">{err}</span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </form>
              <PhoneDisplay
                showTiles={true}
                squareThumbnails
                providedHeader={headerImage}
                providedTile={tileImage}
                headerImageCallback={setHeaderImage}
                tileImageCallback={setTileImage}
              />
            </div>
          );
        }
      } else {
        return (
          <div className="margin-bottom-15 pos:r">
            <PhoneDisplay
              showTiles
              providedHeader={headerImage}
              providedTile={tileImage}
              headerImageCallback={setHeaderImage}
              tileImageCallback={setTileImage}
            />
          </div>
        );
      }
    }
  }

  if (!contentType) return <div />;

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const goBackLink = goBack();
  return (
    <div>
      <div className="margin-bottom-med d:f a-i:b">
        <Link className="d:f" to={goBackLink}>
          <BackArrow className={styles.back} />
        </Link>
        {match.params.category === "new" ? (
          <h3 className={styles.head}>New Category</h3>
        ) : (
          <div>
            <h3 className={styles.head}>
              {category ? category.category_name : null}
            </h3>
            <Link
              className={styles.head}
              to={`/dashboard/Content/type/${
                contentType ? contentType.id : ""
              }/cat/${category.parent_category_id}`}
            >
              {category.parent_category_name}
            </Link>
          </div>
        )}
      </div>

      <div className={styles.container}>
        <div className={styles.containerContent}>
          {match.params.category === "new" ? (
            <div className="margin-bottom-25">
              <SecondaryWarningAlert>
                <p>Add new title to category and click save to create.</p>
              </SecondaryWarningAlert>
            </div>
          ) : null}

          {/*content area*/}
          <div className="margin-bottom-med">
            <div className={styles.subCategoryHead}>
              <h5 className="m:0">Content</h5>
            </div>
            <DragDropContext onDragEnd={onDragEndContent}>
              <Droppable droppableId={"content_" + category.category_id}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {content.map((item, i) => (
                      <Draggable
                        key={item.id}
                        draggableId={`${item.id}`}
                        index={i}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={item.id}
                          >
                            <CategoryRow
                              key={item.id}
                              title={item.title}
                              to={`/dashboard/Content/new?content=${item.id}`}
                              movable
                              provided={provided}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {/*categories area*/}
          <div>
            {!collection ? (
              <>
                <div className={styles.subCategoryHead}>
                  <h5>Sub-Categories</h5>
                  {match.params.category !== "new" ? (
                    <PrimaryButton onClick={showAddNewCategory}>
                      + Add Category
                    </PrimaryButton>
                  ) : null}
                </div>
                <div className="margin-bottom-med">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={"id_" + category.category_id}>
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {subcategories.map((cat, i) => (
                            <Draggable
                              key={cat.category_id}
                              draggableId={`${cat.category_id}`}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  key={cat.category_id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <CategoryRow
                                    provided={provided}
                                    movable
                                    title={cat.category_name}
                                    removeAction={() => {
                                      setShowWarning({
                                        category: cat,
                                        show: true,
                                      });
                                    }}
                                    to={`/dashboard/Content/type/${
                                      contentType ? contentType.id : ""
                                    }/cat/${cat.category_id}`}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </>
            ) : null}
            {contentType.id === CONTENT_TYPES.ORGANIZATION ? (
              <div>
                <h5>Assigned To Organizations</h5>
                <div>
                  {assigned.length ? (
                    <div>
                      {assigned.map((item) => (
                        <CategoryRow
                          key={item.organization_id}
                          title={item.organization_name}
                          to={`/dashboard/Organization/${item.organization_id}/overview`}
                        />
                      ))}
                    </div>
                  ) : (
                    <SecondaryInfoAlert>
                      This category is not assigned to any organizations
                    </SecondaryInfoAlert>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {/* metadata form & phone display*/}
        <div className={styles.containerForm}>
          <form onSubmit={submit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                id="title"
              />
              {validation && validation.title ? (
                <div>
                  {validation.title.map((err) => (
                    <span className="text-danger">{err}</span>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={5}
                id="description"
              />
            </div>
          </form>
          {showSideMeta()}
          <PrimaryButton onClick={submit} className="fl:r margin-top">
            Save
          </PrimaryButton>
          {saving ? <SaveAlert complete={finishedSaving} /> : null}
        </div>
      </div>

      {/*Overview Warning*/}
      <Modal show={overviewModal} onHide={hideOverviewModal}>
        <Modal.Body>
          {overview ? (
            <div>
              <p>
                <strong>"{overview.title}"</strong> is currently set as the
                overview video for this category.
              </p>
              <p>
                Reordering this video will change the overview to a regular
                video. Are you sure you want to do this?
              </p>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <DangerButton onClick={hideOverviewModal} className="m-r:1">
            NO
          </DangerButton>
          <PrimaryButton onClick={finishReorder}>YES</PrimaryButton>
        </Modal.Footer>
      </Modal>

      {/*Removal Warning*/}
      <Modal show={showWarning.show} onHide={hideWarning}>
        <Modal.Body>
          <div>
            {showWarning.category ? (
              <div>
                <p>
                  Are you sure you want to remove{" "}
                  <strong>{showWarning.category.category_name}</strong>?
                </p>
                <h4 className="text-danger">This action cannot be undone.</h4>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <DangerButton onClick={hideWarning}>Cancel</DangerButton>
          <PrimaryButton
            className="m-l:1"
            onClick={() => confirmRemove(showWarning.category.category_id)}
          >
            YES
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default withRouter(Category);