import React, { useState } from 'react';
import validate from "validate.js";
import axios from 'axios';
import { isEmpty, isNil, omitBy } from 'lodash';
import { withRouter } from 'react-router-dom';

// styles
import styles from './organizationChoice.module.css';

// components
import { PrimaryButton } from "../../buttons/buttons";
import { ErrorAlert } from "../../alerts/alerts";

const OrganizationChoice = (props) => {
    // props
    const { setCode, setPage, setLogo, setOrgId, history } = props;

    const [ company, setCompany ] = useState('');
    const [ invitation, setInvitation ] = useState('');
    const [ validation, setValidation ] = useState({});
    const [ error, setError ] = useState('');

    async function submit(e) {
        e.preventDefault();
        const errors = {};
        const constraints = {
            company: {
                type: {
                    type: 'integer',
                    message: '^Invalid Organization ID'
                },
                presence:'^Organization ID is required',
            },
            invitation: {
                type:'string',
                presence:'^Organization code is required',
                length: {
                    minimum: 1,
                    maximum: 255,
                    tooShort: '^Organization code is required',
                    tooLong: '^Organization code must be less than 255 characters',
                }
            }
        };
        const validation = validate({ company: parseInt(company), invitation }, constraints);
        if (validation) {
            errors.company = validation.company;
            errors.invitation = validation.invitation;
        }
        setValidation(errors);
        if (!isEmpty(omitBy(errors, isNil))) return;

        try {
            // test to see if code is valid
            const { data: { result: valid } } = await axios.get(`/organizations/check/code/${company}/${invitation}`);
            // check to see if seats available
            const seatsRes = await axios.get(`/organizations/new-client/check/${company}`);
            const { data: { result: seats } } = seatsRes;

            setError('');
            setOrgId(company);
            setCode(valid.code_id);
            setLogo(valid.logo);
            if (seats) {
                // seats available
                setPage(2);

            } else {
                // send to wait list screen
                setPage(3);
            }

        } catch (e) {
            if (e.response && e.response.data) {
                setError(e.response.data.error);
            } else {
                setError('Unable to verify code. Please contact your Treo administrator');
            }
        }
    }
    return (
        <div>
            <div>
                { error ?
                    <ErrorAlert>{error}</ErrorAlert> : null
                }
            </div>
            <form onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="company">Organization ID</label>
                    <input
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        id='company'
                        className='form-control'
                        type="text"/>
                    { validation && validation.company ?
                        <div>
                            { validation.company.map(err=> <span className="d:b text-danger">{err}</span>)}
                        </div>
                        : null
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="invitation">Code</label>
                    <input
                        value={invitation}
                        onChange={e => setInvitation(e.target.value)}
                        id='invitation'
                        className='form-control'
                        type="text"/>
                    { validation && validation.invitation ?
                        <div>
                            { validation.invitation.map(err=> <span className="d:b text-danger">{err}</span>)}
                        </div>
                        : null
                    }
                </div>
                <div className="t-a:c">
                    <button type='button' tabIndex={0} className={styles.back} onClick={() => history.push('/')}>Back</button>
                    <PrimaryButton type='submit'>Next Step</PrimaryButton>
                </div>
            </form>
        </div>
    )
}

export default withRouter(OrganizationChoice);