import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
// redux
import { connect } from "react-redux";
import { thrivePassApiMap } from "../../../store/actions/thrivePassActions";

// icons
import { FaTimes } from "react-icons/fa";

// custom components
import Loading from "../../Loading/Loading";
import { GeneralPagination } from "../../pagination/generalPagination/generalPagination";
import { StandardDropdown } from "../../dropdowns/standardDropdown/standardDropdown";
import { PrimaryButton } from "../../buttons/buttons";
//styles
import styles from "./ThrivePass.module.css";

const ThrivePass = (props) => {
  // action
  const { thrivePassApiMap } = props;

  const [orderList, setOrderList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const stateAbbreviations = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address1: "",
    city: "",
    state: "",
    zip: "",
    items: [{ product_id: "", qty: 1 }],
  });

  // Fetching Data -------------------------------------------------//
  const getOrders = async (totalPages, limit = 10) => {
    const { getThrivePassOrders } = await thrivePassApiMap(); // This probably does not need to be called with params
    const response = await getThrivePassOrders(totalPages + 1, limit);
    const orders = response.data.result.orders;
    const total = response.data.result.pages;
    setOrderList(orders);
    setTotalPages(total);
  };
  useEffect(() => {
    try {
      getOrders(currentPage); // Call with currentPage
    } catch (err) {
      console.log(err, "Unable to fetch list of orders");
    }
  }, [currentPage]); //

  const getProducts = async () => {
    const { getThrivePassProducts } = await thrivePassApiMap();
    const response = await getThrivePassProducts();
    const products = response.data.result;
    setProductList(products);
  };

  useEffect(() => {
    try {
      getProducts();
    } catch (err) {
      console.log(err, "Unable to fetch list of products");
    }
  }, []);

  useEffect(() => {
    try {
      getOrders(currentPage);
    } catch (err) {
      console.log(err, "Unable to fetch list of orders");
    }
  }, [currentPage]);

  // Form Functions -------------------------------------------------//
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle quantity and other item-specific changes
  const handleItemChange = (e, productId) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedItems = prevState.items.map((item) =>
        item.product_id === productId ? { ...item, [name]: value } : item
      );
      return { ...prevState, items: updatedItems };
    });
  };

  // Submit the form data
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Filters out items with "Select Product" as the selected value
    const validItems = formData.items.filter((item) => item.product_id !== "");

    // If no valid items, don't submit the form
    if (validItems.length === 0) {
      console.log("No valid products selected.");
      return;
    }

    // Submit the form data
    const finalFormData = {
      ...formData,
      items: validItems,
    };

    try {
      setIsLoading(true);
      const { postThrivePassNewOrder } = await thrivePassApiMap();
      const response = await postThrivePassNewOrder(finalFormData);

      getOrders();

      setIsLoading(false);
    } catch (error) {
      // Handle any errors that may occur
      console.error("Error creating order:", error);
      setIsLoading(false);
    }
  };

  const removeItemFromOrder = (productIdToRemove) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        items: prevState.items.filter(
          (item) => item.product_id !== productIdToRemove
        ),
      };
    });
    setSelectedProductIds((prevIds) =>
      prevIds.filter((id) => id !== productIdToRemove)
    );
  };

  // Form Functions
  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.toUpperCase().substring(0, 2), // Ensure only two characters and uppercase
    }));
  };

  // Prepare options for the dropdown
  const productOptions = (currentProductId) => [
    { value: null, label: "Select Product" }, // Placeholder option
    ...productList
      .filter(
        (product) =>
          !selectedProductIds.includes(product.product_id) ||
          product.product_id === currentProductId
      )
      .map((product) => ({
        value: product.product_id,
        label: product.product_name,
      })),
  ];

  const handleDropdownSelect = (selectedOption, index) => {
    setFormData((prevState) => {
      const updatedItems = prevState.items.map((item, idx) => {
        if (idx === index) {
          if (item.product_id !== selectedOption.value) {
            // Change detected
            if (selectedOption.value === null) {
              // Removing the selection
              setSelectedProductIds((prevIds) =>
                prevIds.filter((id) => id !== item.product_id)
              );
            } else {
              // New product selected
              if (item.product_id !== null) {
                // Replace existing product_id
                setSelectedProductIds((prevIds) => {
                  return [
                    ...prevIds.filter((id) => id !== item.product_id),
                    selectedOption.value,
                  ];
                });
              } else {
                // Add new product_id
                setSelectedProductIds((prevIds) => [
                  ...prevIds,
                  selectedOption.value,
                ]);
              }
            }
          }
          return { ...item, product_id: selectedOption.value };
        }
        return item;
      });
      return { ...prevState, items: updatedItems };
    });
  };

  // Determine button visibility
  const isPlaceholderSelected = formData.items.some((item) => {
    if (item.product_id === null) {
      return true;
    }
    return false;
  });

  const isOnlyOneOptionAvailable = productOptions.length <= 2;

  function changePage(selectedItem) {
    setCurrentPage(selectedItem.selected); // 'selected' is zero-based index
  }

  // Render the main component
  return (
    <>
      <h2 className={styles.title}>THRIVE PASS</h2>
      <div className={styles.thrivePassContainer}>
        <div className={styles.placePaginationBottom}>
          <Row className={styles.thriveGridContainer}>
            <Col>
              <Table striped bordered hover size="large">
                <thead>
                  <tr>
                    <th>Ship To:</th>
                    <th>Items:</th>
                    <th>Order Created:</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList.map((order, index) => (
                    <tr key={order.order_id}>
                      <td>
                        {order.first_name} {order.last_name}
                      </td>
                      <td>
                        <ul>
                          {order.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              {item.product_name} (Quantity: {item.quantity})
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>{new Date(order.order_date).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          <GeneralPagination onPageChange={changePage} pages={totalPages} />
        </div>

        <Row className={styles.thriveCreatOrderBtnContainer}>
          {!showForm ? (
            <Col>
              <PrimaryButton onClick={() => setShowForm(true)}>
                Create Order
              </PrimaryButton>
            </Col>
          ) : (
            <>
              <div
                className={styles.closeBtnContainer}
                onClick={() => setShowForm(false)}
              >
                <FaTimes size={12} color="black" />
              </div>
              <Form
                className={styles.thrivePassFormContainer}
                onSubmit={handleSubmit}
              >
                {/* User Information */}
                <FormGroup className={styles.thrivePassFormInputLabelContainer}>
                  <FormControl
                    className={styles.thrivePassInput}
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    placeholder="First Name"
                  />
                </FormGroup>

                <FormGroup>
                  <FormControl
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Phone"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    type="text"
                    name="address1"
                    value={formData.address1}
                    onChange={handleInputChange}
                    placeholder="Address"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    placeholder="City"
                  />
                </FormGroup>

                <FormGroup className={styles.stateZipContainer}>
                  <FormControl
                    type="text"
                    list="stateList"
                    name="state"
                    value={formData.state}
                    onChange={handleStateChange}
                    placeholder="State"
                    maxLength={2}
                  />
                  <datalist id="stateList">
                    {stateAbbreviations.map((state, index) => (
                      <option key={index} value={state} />
                    ))}
                  </datalist>

                  <FormControl
                    type="text"
                    name="zip"
                    value={formData.zip}
                    onChange={handleInputChange}
                    placeholder="Zip Code"
                  />
                </FormGroup>
                <div className={styles.productContainer}>
                  <ControlLabel className={styles.marginBottom}>
                    Product
                  </ControlLabel>
                  <ControlLabel className={styles.qtylabel}>
                    Quantity
                  </ControlLabel>
                  <ControlLabel className={styles.qtylabel}></ControlLabel>
                </div>

                {/* Product Selection */}
                {formData.items.map((item, index) => {
                  return (
                    <>
                      <div
                        key={item.product_id}
                        className={styles.productContainer}
                      >
                        <Col className={styles.dropdown}>
                          <StandardDropdown
                            maxHeight="300px"
                            options={productOptions(item.product_id)}
                            callback={(selectedOption) =>
                              handleDropdownSelect(selectedOption, index)
                            }
                            providedValue={
                              productOptions(item.product_id).find(
                                (opt) => opt.value === item.product_id
                              ) || { value: null, label: "Select Product" }
                            }
                            className={styles.dropdownLabel}
                          />
                        </Col>
                        <Col>
                          <FormControl
                            type="number"
                            name="qty"
                            min={0}
                            value={item.qty}
                            onChange={(e) =>
                              handleItemChange(e, item.product_id)
                            }
                          />
                        </Col>
                        <Col>
                          <div
                            className={styles.removeBtn}
                            onClick={() => removeItemFromOrder(item.product_id)}
                          >
                            <span className={styles.rmvText}>REMOVE</span>
                            <FaTimes
                              size={13}
                              color="rgb(193, 14, 14)"
                              style={{ verticalAlign: "middle" }}
                            />
                          </div>
                        </Col>
                      </div>
                    </>
                  );
                })}

                {/* Other UI components */}
                {!isPlaceholderSelected && isOnlyOneOptionAvailable && (
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        items: [
                          ...prevState.items,
                          { product_id: null, qty: 1 },
                        ],
                      }))
                    }
                  >
                    Add Another Item
                  </Button>
                )}
                <hr />

                {isLoading ? (
                  <Loading />
                ) : (
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                )}
              </Form>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

const actions = {
  thrivePassApiMap,
};

export default connect(null, actions)(ThrivePass);
