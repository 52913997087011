import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Slider from 'rc-slider';

import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';


const Handle = Slider.Handle;

let bar;
let red;
let yellow;
let green;

let styles;
let noStyles = {};
// eslint-disable-next-line
let barStyles;

class LastActiveBar extends Component{
    constructor(props){
        super(props);
        this.state={
            startDate:moment(),
            scaleStart:this.props.scaleStart,
            scaleEnd:this.props.scaleEnd,
            planId:props.planId,
            tileId:props.tileId,
            steps:props.incrementer,
            marks:{},
            sliderValue:props.scaleStart,
            tile_category:props.tile_category,
            value:6
        };
    }

    componentDidMount(){
        console.log(this.props.lastActiveValue)
        let i=this.state.scaleStart;
        let j = this.state.scaleEnd;

        //place marks of no, maybe, and yes for intro tile
        if(this.state.tile_category === 4){
            //this is an intro tile id
            let tempMarks = {};
            for(i; i<=j; i+=this.props.increment){
                if(i < 0){
                    tempMarks[i] = 'No';
                }else if(i === 0){
                    tempMarks[i] = 'Maybe';
                }else if(i > 0){
                    tempMarks[i] = 'Yes';
                }
            }
            this.setState({marks : tempMarks});
            return;
        }

        //handle tempMarks if not intro tile
        let tempMarks = {};
        for(i; i<=j; i+=this.props.increment){
            tempMarks[i]=i
        }
        this.setState({marks:tempMarks});
    }

    goalBarGradient(){
     bar = this.state.scaleEnd - this.state.scaleStart;
     red = ((this.props.minPoint - this.state.scaleStart)/bar*100);
     yellow = (this.props.maxPoint - this.props.minPoint)/bar*100;
     green = (red+yellow);
    }

    disabledStyleChange(){
      console.log(this.props.status)
      if(this.props.status === "disabled"){
        barStyles = noStyles;
      }
      else{
        barStyles = styles;
      }
    }

  /* eslint-disable no-dupe-keys */
    gradientStyles(){
      if(this.props.status === "disabled"){
         styles = {};
      }
      else if(this.props.inverted){
        styles = {
          background: `-webkit-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-webkit-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-o-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red}%, #fff130 ${green}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `linear-gradient(to right, #27dd1a 0%, #fff130 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #fff130 ${green}%, #ff3232 100%)`
        }
      }
      else{
        styles = {
          background: `-webkit-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-webkit-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-o-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `linear-gradient(to right, #ff3232 0%, #fff130 ${red}%, #fff130 ${red}%, #fff130 ${green}%, #fff130 ${green}%, #27dd1a 100%)`         
        }
      }
    };

    handleShow(handle, sliderStyle){
      if(this.props.lastActivity){
        return(
                <Slider
                    style={styles}
                    className={sliderStyle}
                    min={this.state.scaleStart}
                    max={this.state.scaleEnd}
                    step={this.props.increment}
                    marks={this.state.marks}
                    value={this.props.lastActiveValue}
                    handle={handle}
                  />
          )
      }
      else{
        return(
                <Slider
                    style={styles}
                    className={sliderStyle}
                    min={this.state.scaleStart}
                    max={this.state.scaleEnd}
                    step={this.props.increment}
                    marks={this.state.marks}
                    value={this.props.lastActiveValue}
                    handleStyle={{display:'none'}}
                />
          )
      }
    }


    render(){
      this.goalBarGradient()
      this.gradientStyles()

      
      let sliderStyle = this.props.status === "disabled" ? "goal-bar-container-inactive" : "activitySlider";
      const handle = (props) => {
      const { value, index, ...restProps } = props;
      return (
                <Tooltip
                  prefixCls="rc-slider-tooltip"
                  overlay={this.props.lastActivity}
                  visible={true}
                  placement="top"
                  key={index}
                >
              <Handle className="hide-handle" value={value} {...restProps} />
            </Tooltip>
          );
        };
        return(
          <div>
            {this.handleShow(handle, sliderStyle)}
          </div>
        );
    }
}

export default withRouter(LastActiveBar);