import mainApi from "./mainApi";

export function wellnessScoreApi() {
    return {
        getLifetimeScore: async function() {
            return mainApi.get('/score/lifetime-score');
        },
        getDailyScore: async function() {
            return mainApi.get('/score/wellness-score');
        },
        getWellnessScoreHistory: async function(username, start, end) {
            return mainApi.get(`/score/history/user/${username}?start=${start}&end=${end}`)
        },
        getLifetimeScoreHistory: async function(date, days, userId) {
            let url = `/score/graph/lifetime-score?date=${date}&days=${days}`;
            if (userId) url += `&user_id=${userId}`;
            return mainApi.get(url);
        },
        getGeneralScoreActivity: async function(date) {
            return mainApi.get(`/score/activity/calendar/?date=${date}`);
        },
        getStreak: async function() {
            return mainApi.get('/score/streak');
        },
        habitBuilder: function() {
            return mainApi.post('/score/habit-builder/');
        },
        getMonthlyScore: async function() {
            return mainApi.get('/score/current/month');
        }
    }
}