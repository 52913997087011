import React, {useEffect, useState} from 'react';
import {
    addHours,
    addDays,
    addMonths,
    addYears,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    formatISO,
    startOfMonth,
    startOfWeek,
    startOfYear, subDays, subMonths, subYears
} from "date-fns";
import moment from 'moment';
import axios from "axios";

// styles
import styles from "./habitActivities.module.css";

// components
import { RANGES } from "../ranges";
import Graph from "../../graph/graph";

const HabitActivities = props => {
    // props
    const { range, plan } = props;
    // local
    const [ date, setDate ] = useState(new Date());
    const [ loading, setLoading ] = useState(false);
    const [ activities, setActivities ] = useState([]);

    function setRangeText() {
        switch(range) {
            case RANGES.YEAR:
                return format(date, 'yyyy');
            case RANGES.MONTH:
                return format(date, 'MMMM yyyy');
            case RANGES.WEEK:
                return `${format(startOfWeek(date), "L'/'d'/'yy")} - ${format(endOfWeek(date), "L'/'d'/'yy")}`;
            // no default
        }
    }

    function getHistory(start, end) {
        if (loading) return;
        setLoading(true);
        try{
            axios.get(`/plan/metrics/${plan.id}?date=${end}&end=${start}`)
                .then(res => {
                    if (res.data.results.length){
                        const activity = res.data.results.map(point => ({date:moment(point.date).local().format(), plan_value:point.plan_value}));
                        setActivities(handleData(activity));
                    } else { setActivities(handleData([{date : moment(date).startOf(range).format(), value : 0}]))}
                    setLoading(false);
                })
        } catch (err) {
            setLoading(false);
        }
    }

    function getYearlyData() {
        const start = formatISO(addHours(startOfYear(date), 2));
        const end = formatISO(addHours(endOfYear(date), 2));
        getHistory(start, end);
    }

    function getMonthlyData() {
        const start = formatISO(addHours(startOfMonth(date), 2));
        const end = formatISO(addHours(endOfMonth(date), 2));
        getHistory(start, end);
    }

    function getWeeklyData() {
        const start = formatISO(addHours(startOfWeek(date), 2));
        const end = formatISO(addHours(endOfWeek(date), 2));
        getHistory(start, end);
    }

    function goBack() {
        switch(range) {
            case RANGES.YEAR:
                return setDate(subYears(date, 1));
            case RANGES.MONTH:
                return setDate(subMonths(date, 1));
            case RANGES.WEEK:
                return setDate(subDays(date, 7));
            // no default
        }
    }

    function goForward() {
        switch(range) {
            case RANGES.YEAR:
                return setDate(addYears(date, 1));
            case RANGES.MONTH:
                return setDate(addMonths(date, 1));
            case RANGES.WEEK:
                return setDate(addDays(date, 7));
            // no default
        }
    }

    function handleData(data){
        let list = data.map(item => {
            return ({date: moment(item.date).format('YYYY-MM-DD'), value: item.plan_value})
        });

        if(list && list.length > 0){
            let date_tracker = moment(date).startOf(range);
            let timespan = moment(date).endOf(range).add(1, 'days');
            for(let i=0; i<=list.length; i++){
                if(i === list.length && date_tracker.isSame(timespan, "day")){
                    break;
                }else if(i === list.length){
                    //handle data if element at this array index was empty
                    let datapoint = {date:moment(date_tracker).format('YYYY-MM-DD'), value:null};
                    //place new data at loop index
                    list.splice(i, 0, datapoint);
                }else{
                    if(moment(list[i].date).isAfter(date_tracker, 'day')){
                        //handle data if element at this array index was empty
                        let datapoint = {date:moment(date_tracker).format('YYYY-MM-DD'), value:null};
                        //place new data at loop index
                        list.splice(i, 0, datapoint);
                    }
                }
                //increment date_tracker
                if(moment(date_tracker).isBefore(timespan)){
                    date_tracker = moment(date_tracker).add(1, 'days');
                }else if(moment(date_tracker).isAfter(timespan, 'day')){
                    break;
                }
            }
            list = list.map(item=>{
                if(moment(item.date).isAfter(moment())) item.value = null;
                return item;
            });
            return list;
        }
        return [];
    }

    // fetch new data whenever date or graph buttons change
    useEffect(() => {
        if (range === RANGES.YEAR) getYearlyData();
        if (range === RANGES.MONTH) getMonthlyData();
        if (range === RANGES.WEEK) getWeeklyData();
    }, [date, range]);

    return (
        <div className={styles.container}>
            <div className={styles.dateHeader}>
                <span className={styles.dateYear}>{setRangeText()}</span>
                <span className={styles.dateNav}>
                    <i onClick={goBack} className="fas fa-chevron-left"/>
                    <i onClick={goForward} className="fas fa-chevron-right"/>
                </span>
            </div>
            <Graph data={activities} height={400}/>
        </div>
    )
};

export default HabitActivities;