import React from 'react';

// components
import {ErrorAlert} from "../../alerts/alerts";
import ActivityCalendar from "../../activityCalendar/activityCalendar";

const HabitCalendar = props => {
    // props
    const { calendarError, events, setDate } = props;
    return (
        <div>
            { calendarError ? <ErrorAlert>{calendarError}</ErrorAlert> : null }
            <ActivityCalendar events={events} callback={setDate}/>
        </div>
    )
}

export default HabitCalendar;