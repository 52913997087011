import React from 'react';

// styles
import styles from './animatedCheckmark.module.css';

export const AnimatedCheckmark = props => {
    const {animated} = props;
    let classStyles = `${styles.checkmark}`;
    if (animated) classStyles += ` ${styles.checkAnimated}`;
    return (
        <svg className={classStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <path className={styles.check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
    )
}