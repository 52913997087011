import React from 'react';
import {withRouter} from 'react-router-dom';
import ClientHeader from "../../clientHeader/clientHeader";
import SingleMessage from './SingleMessage';
import {connect} from 'react-redux';
import {fetchThread} from "../../../store/actions/postsActions";
import Loading from "../../Loading/Loading";

class MessageMain extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      archived:false
    }
  }

  componentDidMount() {
    fetchThread(this.props.match.params.id)
        .then(res => {
          this.setState({message: res});
        })
        .catch(e => console.log(e));
  }

  render() {
      const {user} = this.props;
      if (!this.state.message) return (
          <div className="padding-standard">
            <Loading/>
          </div>
      )

    return (
          <div className="client-container">
              <ClientHeader backUrl={`/member/clientDash/${user.username}/inbox`}/>

                {this.props.user
                  ?   <SingleMessage
                      username={this.props.user.username}
                      message={this.state.message}
                />
                  :null
                }
          </div>
    );
  }
}

function mapPropsToState({user}){
    return {user}
}

export default withRouter(connect(mapPropsToState)(MessageMain));