import React from "react";

const hours12 = Array.from({ length: 12 }, (_, i) => (i === 0 ? 12 : i)); 
const periods = ["AM", "PM"];

const TimePicker = ({
  selectedHour,
  selectedPeriod,
  onHourChange,
  onPeriodChange,
}) => {
  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <select
        style={{ minWidth: "100px" }}
        className="form-control"
        onChange={onHourChange}
        value={selectedHour}
      >
        {hours12.map((hour) => (
          <option key={hour} value={hour}>
            {hour}:00
          </option>
        ))}
      </select>

      <select
        className="form-control"
        onChange={onPeriodChange}
        value={selectedPeriod}
      >
        {periods.map((period) => (
          <option key={period} value={period}>
            {period}
          </option>
        ))}
      </select>
    </div>
  );
};
export default TimePicker;
