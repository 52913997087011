// npm modules
import React from 'react';
import parseISO from "date-fns/parseISO";
import format from 'date-fns/format';

import styles from './clientAssessmentViewTaken.module.css';

// components
import WeightLossIcon from "../../../images/icon-pathwayreport-primary_weightloss.png";
import BodyCompIcon from "../../../images/icon-pathwayreport-primary_bodycomp.png";
import BrainHealthIcon from "../../../images/icon-pathwayreport-primary_brainhealth.png";
import EnergyIcon from "../../../images/icon-pathwayreport-primary_energy.png";
import ActivityIcon from "../../../images/icon-pathwayreport-primary_activity.png";
import FoodChoicesIcon from "../../../images/icon-pathwayreport-primary_foodchoices.png";
import BetterBloodPressureIcon from "../../../images/icon-pathway-bbp.png";
import BetterBloodSugarIcon from "../../../images/icon-pathway-bbs.png";
import {HEALTH_GOALS} from "../../../serverVariables/healthGoals";
import SingleQuestion from "../singleQuestion/singleQuestion";

const ClientAssessmentViewTaken = props  => {
    const {assessment, questions} = props;

    function chooseHealthIcon(id) {
        switch(id) {
            case HEALTH_GOALS.LASTING_WEIGHT_LOSS:
                return WeightLossIcon;
            case HEALTH_GOALS.STRONGER_LEANER_BODY:
                return BodyCompIcon;
            case HEALTH_GOALS.IMPROVE_HEART_COGNITIVE_HEALTH:
                return BrainHealthIcon;
            case HEALTH_GOALS.MORE_ENERGY_LESS_STRESS:
                return EnergyIcon;
            case HEALTH_GOALS.PHYSICAL_ACTIVITY_HABIT:
                return ActivityIcon;
            case HEALTH_GOALS.HEALTHIER_FOOD_CHOICES:
                return FoodChoicesIcon;
            case HEALTH_GOALS.BETTER_BLOOD_SUGAR:
                return BetterBloodSugarIcon;
            case HEALTH_GOALS.BETTER_BLOOD_PRESSURE:
                return BetterBloodPressureIcon;
            // no default
        }
    }
    return (
        <div>
            <h2 className={styles.heading}>Health Goal Assessment</h2>
            <p className={styles.date}>Taken on {format(parseISO(assessment.assessment_completed), 'MM/dd/yyyy')}</p>
            <div className={styles.healthGoal} data-cy="client-health-goal">
                <img src={chooseHealthIcon(assessment.healthgoal.id)} alt=""/>
                <div className={styles.goal}>
                    <strong>You've chosen the health goal:</strong>
                    <span>{assessment.healthgoal.label}</span>
                </div>
            </div>
            {questions.map((question, i) => {
                return (
                    <SingleQuestion question={question} ordinal={i}/>
                )
            })}
        </div>
    )
};

export default ClientAssessmentViewTaken;
