import axios from 'axios';

//set an active piece of content being viewed
export const SET_ACTIVE_CONTENT = 'SET_ACTIVE_CONTENT';
export function setActiveContent(content_id){
    let url=`/content/item/${content_id}`;
    let request=axios.get(url);
    return {
        type: SET_ACTIVE_CONTENT,
        payload: request
    };
}

//clear active content
export const CLEAR_ACTIVE_CONTENT = 'CLEAR_ACTIVE_CONTENT';
export function clearActiveContent() {
    return{
        type:CLEAR_ACTIVE_CONTENT,
        payload:null
    }
}


// //set an active piece of content being viewed
// export const SET_ACTIVE_CONTENT = 'SET_ACTIVE_CONTENT';
// export function setContentId(content_id){
//     return {
//         type: SET_ACTIVE_CONTENT,
//         payload: content_id
//     };
// }
