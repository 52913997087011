import React from 'react';
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format';
import {Link} from 'react-router-dom';

// styles
import styles from './contentRow.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import {CONTENT_FORMATS} from "../../../serverVariables/contentFormats";
import {CONTENT_TYPES} from "../../../serverVariables/contentTypes";
import {ReactComponent as VideoIcon} from "../../../images/play.svg";
import {ReactComponent as AudioIcon} from "../../../images/headphones.svg";
import {ReactComponent as LinkIcon} from "../../../images/external-link-alt-solid.svg";
import {ReactComponent as PdfIcon} from "../../../images/file-pdf-solid.svg";
import {ReactComponent as PaperIcon} from "../../../images/file-alt-solid.svg";
const ContentRow = props => {
    const {content} = props;

    function chooseStatus(status) {
        let className = styles.status;
        if (status === 'published') className += ` ${styles.published}`;
        if (status === 'draft') className += ` ${styles.draft}`;
        if (status === 'scheduled') className += ` ${styles.scheduled}`;
        return (
            <span className={className}>{status}</span>
        )
    }

    function icon(type, format){
        if (type === CONTENT_TYPES.BLOG) {
            return <PaperIcon/>;
        } else if (type === CONTENT_TYPES.LINK) {
            return <LinkIcon/>;
        } else if (type === CONTENT_TYPES.FILE) {
            return <PdfIcon/>;
        } else if (type === CONTENT_TYPES.PATHWAY) {
            return <VideoIcon/>;
        } else if (type === CONTENT_TYPES.FEATURED || type === CONTENT_TYPES.ORGANIZATION || type === CONTENT_TYPES.FITNESS) {
            if (format === CONTENT_FORMATS.VIDEO) return <VideoIcon/>;
            if (format === CONTENT_FORMATS.AUDIO) return <AudioIcon/>;
            if (format === CONTENT_FORMATS.LINK) return <LinkIcon/>;
            if (format === CONTENT_FORMATS.PDF) return <PdfIcon/>;
        } else {
            return '?';
        }
    }

    function showCategories(categories){
        if (!categories || !Array.isArray(categories) || categories.length === 0) return <i>Uncategorized</i>;

        const category = categories[0];
        if (category.parent_category && category.parent_category.name) {
            return (
                <div>
                    <div>{category.parent_category.name}</div>
                    <div className='d:f'>
                        <span className={styles.childCategory}/><span>{category.name}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div>{category.name}</div>
            )
        }
    }

    function showDate(published) {
        const date = parseISO(published);
        return format(date, "MM/dd/yy h:mmaaa");
    }
    return (
        <BoxContainer className={styles.row}>
            <Link to={`/dashboard/Content/new?content=${content.id}`} className={styles.title}>
                {content.title}
            </Link>
            <div>
                {chooseStatus(content.status)}
            </div>
            <div className={styles.type}>
                {content.content_type}
            </div>
            <div className={styles.format}>
                {icon(content.content_type_id, content.format_id)}
            </div>
            <div className={styles.category}>
                {showCategories(content.categories)}
            </div>
            <div className={styles.date}>
                {showDate(content.last_updated)}
            </div>
            <div className={styles.view}>
                <button>View</button>
            </div>
        </BoxContainer>
    )
}

export default ContentRow;