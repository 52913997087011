import React, {useEffect, useRef, useState} from 'react';
import FileDownload from "js-file-download";
import {isNil} from "lodash";
import {connect} from 'react-redux';

// styles
import styles from './clientSearchMain.module.css';

// actions
import {fetchClientExport, fetchBiweeklyExport} from "../../../store/actions/exportActions";
import {fetchOrganizations} from "../../../store/actions/organizationActions";
import {fetchClients} from "../../../store/actions/userActions";


// components
import ClientSortAndFilter from "../../clientSortAndFilter/clientSortAndFilter";
import ClientResult from "../../clientSearch/clientResult";
import {GeneralPagination} from "../../pagination/generalPagination/generalPagination";
import {PrimaryButton, SecondaryButton} from "../../buttons/buttons";
import {Modal} from "react-bootstrap";


const ClientSearchMain = props => {
    // state
    const { singleCoach } = props;

    // local
    const resetRef = useRef();
    const [ state, updateState ] = useState({
        clients: [],
        total: 0,
        pages: 0,
        query: '',
        name: '',
        page: 1,
        exportModal: false,
        exporting: false,
        organizations: []
    });

    function setState(value){
        updateState( prevState => ({
            ...prevState,
            ...value
        }));
    }

    async function changePage({ selected }) {
        window.scrollTo(0, 0);
        const num = selected + 1;
        const updatedQuery = `${state.query}&coach=${singleCoach.username}`;
        const clients = await fetchClients(num, updatedQuery);
        setState({
            page: num,
            clients: clients.users,
            pages: clients.pages,
            total: clients.count,
        });
    }

    function sortAndFilter(queryString) {
        if (state.name) {
            setState({ query: `${queryString}&filter=name:${state.name}`})
        } else {
            setState({ query: queryString });
        }
    }

    function resetSearch() {
        setState({
            query: '',
        });
        resetRef.current.resetEverything();
    }

    function exportClients() {
        setState({ exporting: true, exportModal: true });
        fetchClientExport(singleCoach.username)
            .then((response) => {
                setState({ exporting: false, exportModal: false, });
                const date = new Date();
                const day = date.getDate().toString();
                const month = (date.getMonth()+1).toString();
                const year = date.getFullYear().toString();
                const file_name = (`report_${singleCoach.last_name}_${month}-${day}-${year}.csv`).trim();
                FileDownload(response, file_name);
            });
    }

    function biweeklyExport() {
        setState({ exporting: true, exportModal: true });
        fetchBiweeklyExport(singleCoach.user_id)
            .then((response) => {
                setState({ exporting: false, exportModal: false, });
                const date = new Date();
                const day = date.getDate().toString();
                const month = (date.getMonth()+1).toString();
                const year = date.getFullYear().toString();
                const file_name = (`bi-weekly report ${singleCoach.last_name}_${month}-${day}-${year}.xlsx`).trim();
                FileDownload(response, file_name);
                // console.log(response);
                // const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // saveAs(blob, 'fixi.xlsx');
            })
            .catch(e => console.log(e));
    }

    useEffect(() => {
        if(!isNil(state.query)) changePage({ selected: 0 });
    }, [ state.query ]);

    useEffect(() => {
        fetchOrganizations(singleCoach.id)
            .then(orgs => setState({ organizations: orgs || [] }))
            .catch(e => console.log(e));
    }, []);

    return (
        <div>
            <div className={styles.header}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 flex-horizontally">
                            <h1 className="inline-block">Members</h1>
                            <div>
                                <PrimaryButton
                                    disabled={state.exporting}
                                    onClick={biweeklyExport}
                                    className={`btn primary float-right m-l:2 ${styles.exportBtn}`}
                                >Bi-Weekly Report</PrimaryButton>
                                <SecondaryButton
                                    disabled={state.exporting}
                                    onClick={exportClients}
                                    className={`primary float-right ${styles.exportBtn}`}
                                >Export Members</SecondaryButton>
                            </div>
                        </div>
                    </div>
                    <form className='row' onSubmit={e => {
                        e.preventDefault();
                        sortAndFilter(state.query);
                    }}>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <label>Search</label>
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Search by name, username, phone, or email'
                                value={state.name}
                                onChange={e => setState({ name: e.target.value })}/>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <PrimaryButton className="margin-top-2x m-r:2" onClick={() => sortAndFilter(state.query)}>Search</PrimaryButton>
                            <SecondaryButton
                                className='margin-top-2x'
                                onClick={resetSearch}
                            >Reset</SecondaryButton>
                        </div>
                    </form>
                </div>
            </div>
            <div className={`container ${styles.container}`}>
                <div className="row">
                    <div className="col-xs-12 col-lg-3">
                        <ClientSortAndFilter
                            className={styles.search}
                            ref={resetRef}
                            callback={sortAndFilter}
                            organization={state.organizations}/>
                    </div>
                    <div className="col-xs-12 col-lg-9">
                        <p className={styles.count}>{state.total || 0} members found</p>
                        {state.clients.map(client => <ClientResult client={client}/>)}

                        <div className="pagination-container">
                            <GeneralPagination pages={state.pages} onPageChange={changePage}/>
                        </div>
                    </div>
                </div>
            </div>
            <Modal backdrop={'static'} show={state.exportModal} onHide={() => setState({ exportModal: false })}>
                <Modal.Body>
                    <h3 className='text-danger'>Exporting Members. Please do not navigate away from page.</h3>
                </Modal.Body>
            </Modal>
        </div>
    )
}

function mapStateToProps({ user, singleCoach }){
    return {
        user,
        singleCoach,
    }
}
export default connect(mapStateToProps)(ClientSearchMain);