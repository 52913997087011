import React from 'react';
import {Link} from 'react-router-dom';
import profileImage from '../../images/avatar-1577909_960_720.png';
import $ from 'jquery';
import randomstring from 'randomstring';
import EXIF from 'exif-js'


const ProfileImage = ({username, image, url_path, permission, color}) =>{
    //generate id for each image
    let randomString = randomstring.generate(20);
    let img = $(`#${randomString}`);
    img.attr('src', image ? image : profileImage).width('50%').height('auto');
    fixExifOrientation(img)
    return(
            <div style={color ? {border: `2.5px solid ${color}` } : {}} className="profile-image -list">
                <Link to={{
					  pathname: `/${url_path}`,
					  state: { 
					    username: username,
                        permission: permission,
                        image: image
					  }
					}}>
                    <img
                        id ={randomString}
                        src={image ? image : profileImage}
                        alt="Profile"
                        className="responsive-image"
                    />
                </Link>
            </div>
    )
};

export default ProfileImage;

function fixExifOrientation($img) {
    $img.on('load', function() {
        EXIF.getData($img[0], function() {
            console.log('Exif=', EXIF.getTag(this, "Orientation"));
            switch(parseInt(EXIF.getTag(this, "Orientation"))) {
                case 2:
                    $img.addClass('flip'); break;
                case 3:
                    $img.addClass('rotate-180'); break;
                case 4:
                    $img.addClass('flip-and-rotate-180'); break;
                case 5:
                    $img.addClass('flip-and-rotate-270'); break;
                case 6:
                    $img.addClass('rotate-90'); break;
                case 7:
                    $img.addClass('flip-and-rotate-90'); break;
                case 8:
                    $img.addClass('rotate-270'); break;
                // no default
            }
        });
    });
}

/*
         <ExifOrientationImg
            src={image ? image : profileImage}
            alt="Profile Image"
            className="responsive-image"
        />
* */