import React from 'react';
import SignUp from './SignUp';
import Unsubscribe from './Unsubscribe';
import axios from 'axios';

class SignUpMain extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            firstName:'',
            lastName:'',
            email:'',
            token:'',
            invitee:null,
            accept:null,
            profile_picture:'',
        }
    }

    componentDidMount(){
      let url = window.location.href;
      let accept = url.split('accept=').pop().split(' ')[0];
      let token = url.split('token=').pop().split('&accept=')[0];

        axios.get(`/teammates/${token}`)
          .then((res)=>{
              let response = res.data.results
              this.setState({
                  firstName:response.first_name,
                  lastName:response.last_name,
                  email:response.email,
                  profile_picture:response.profile_picture,
                  invitee: response.invitee,
              });
              this.setState({token:token, accept:accept});
          })
        .catch((error)=>{
          console.log(error)
        });
    }

    teammateDecision(){
      if(this.state.accept !== null) {
        if(this.state.accept === "true" ){ 
          return <SignUp 
          token={this.state.token}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          profile_picture={this.state.profile_picture}
          invitee={this.state.invitee}
          /> 
        }
        else if( this.state.accept === "false" ){ return <Unsubscribe token={this.state.token}/> }
        else{ 
          return(
              <div className="container text-center">
                  <div className="row">
                    <div className="teammate-logo-container margin-top">
                      <img src={'https://treo.s3.us-east-2.amazonaws.com/assets/logo.png'} alt='Treo Logo' />
                    </div>
                  </div>
                  <div className="row">
                    <h2>We were unable to determine your teammate status.</h2>
                  </div>
              </div>
            ) 
        }
      }
    }

    render(){
        return (
            <div>
              {this.teammateDecision()}
            </div>
        )
    }
}

export default SignUpMain;