//placholder elements for admin dash
import React from 'react';
import './metaData.css';

export class MetaData extends React.Component {

	render() {
		return(
			<div className="container">
			    <div className="row">
                    <div className="col-sm-12">                        
                        <div class="graph-container">
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <img style={{maxWidth:'100%', height: "auto"}} src="http://via.placeholder.com/1600x900" alt=""/>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    [ list of stats ]
                                </div>
                            </div>
                        </div>                                                
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">                        
                        <div class="graph-container">
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <img style={{maxWidth:'100%', height: "auto"}} src="http://via.placeholder.com/1600x900" alt=""/>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    [ list of stats ]
                                </div>
                            </div>
                        </div>                                                
                    </div>
                </div>
			</div>
			);
	}
};

//imported to:
//Admin/adminDash/adminDashMain
//&&
//Coach