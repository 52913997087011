import React from 'react';

import styles from './boxContainer.module.css';

export const BoxContainer = ({ children, className='', ...props}) => {
    return (
        <div className={`${styles.container} ${className}`} {...props}>
            {children}
        </div>
    )
}