import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';

// styles
import styles from './mainCategories.module.css';

// actions
import {fetchOrganizations} from "../../../store/actions/organizationActions";

// components
import CategoryRow from "../categoryRow/categoryRow";
import {PrimaryLink} from "../../links/links";
import {CONTENT_TYPES} from "../../../serverVariables/contentTypes";
import {Modal} from "react-bootstrap";
import {DangerButton, PrimaryButton} from "../../buttons/buttons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import SearchableDropdown from "../../dropdowns/searchableDropdown/searchableDropdown";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const MainCategories = props => {
    // props
    const {user, contentType, categories, match, remove, cleanUp, setCategoryOrder, getCategories} = props;
    // local
    const [showWarning, setShowWarning] = useState({category: null, show: false});
    const [orgs, setOrgs] = useState([]);
    const [orgValue, setOrgValue] = useState(null);
    const [query, setQuery] = useState('');

    useEffect(() => {
        return () => cleanUp();
    }, []);

    // get organizations if content type is organizations
    useEffect(() => {
        if (contentType && contentType.id === CONTENT_TYPES.ORGANIZATION && user) {
            try{
                fetchOrganizations()
                    .then(organizations => {
                        setOrgs(organizations.map(org => ({
                            name: `${org.name} (${org.id})`,
                            value: org.id,
                        })));
                    })
            } catch (e) {
                console.log(e);
            }
        }
    }, [contentType, user]);

    useDidMountEffect(() => {
        getCategories({search: query, orgId: orgValue})
    }, [orgValue]);

    const hideWarning = () => {
        setShowWarning({category: null, show: false});
    }

    const confirmRemove = category => {
        remove(category);
        hideWarning();
    }

    // function that's called when a category is dragged and released
    const onDragEnd = async ({ destination, source }) => {
        // do nothing if no movement was made
        if(!destination) return;
        if(destination.index === source.index) return;
        if (categories.length <= 1) return; // can't reorder a list of 1

        const clone = [...categories];
        const [removed] = clone.splice(source.index, 1);
        clone.splice(destination.index, 0, removed);
        setCategoryOrder(clone);
    }

    // filter out and search categories
    const search = e => {
        e.preventDefault();
        getCategories({search: query, orgId: orgValue})
    }

    if (!contentType) return <div/>;
    return (
        <div>
            <div className={`${styles.head} margin-bottom-med`}>
                <h3>{contentType ? contentType.type : ''}</h3>
                {contentType && contentType.id !== CONTENT_TYPES.PATHWAY ?
                    <PrimaryLink to={`${match.url}/cat/new?main=true`}>+ Add Category</PrimaryLink>
                    : null
                }
            </div>
            {contentType && contentType.id === CONTENT_TYPES.ORGANIZATION ?
                <form className={styles.form} onSubmit={search}>
                    <div className={styles.search}>
                        <div className={styles.text}>
                            <label htmlFor="search">Search</label>
                            <input
                                id='search'
                                type='text'
                                value={query}
                                onChange={event => setQuery(event.target.value)}
                            />
                        </div>
                        <PrimaryButton className={styles.submit} onClick={search}>Search</PrimaryButton>
                    </div>
                    <div>
                        <SearchableDropdown
                            options={[{name: 'No Organization', value: ''}, ...orgs]}
                            value={orgValue}
                            onChange={setOrgValue}
                            placeholder='Filter by Organization'/>
                    </div>
                </form>
                : null
            }
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='mainCategoryDrop'>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {categories.map((cat, i) => (
                                <Draggable key={cat.category_id} draggableId={`${cat.category_id}`} index={i}>
                                    {(provided) => (
                                        <div
                                            key={cat.category_id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}>
                                            <CategoryRow
                                                provided={provided}
                                                movable={cat.content_type_id === CONTENT_TYPES.FEATURED}
                                                to={`/dashboard/Content/type/${contentType.id}/cat/${cat.category_id}`}
                                                title={cat.category_name}
                                                locked={cat.content_type_id === CONTENT_TYPES.PATHWAY}
                                                removeAction={() => {
                                                    if (cat.content_type_id === CONTENT_TYPES.PATHWAY){
                                                        setShowWarning({category: null, show: true});
                                                    } else {
                                                        setShowWarning({category:cat, show: true});
                                                    }

                                                }}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Modal show={showWarning.show} onHide={hideWarning}>
                <Modal.Body>
                    { showWarning.category ?
                        <div>
                            <p>Are you sure you want to remove <strong>{showWarning.category.category_name}</strong>?</p>
                            <h4 className="text-danger">This action cannot be undone.</h4>
                        </div>
                        :
                        <h4>Pathways categories require review before removal. Please contact a developer for assistance.</h4>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <DangerButton onClick={hideWarning}>Cancel</DangerButton>
                    {showWarning.category ?
                        <PrimaryButton className='m-l:1'
                            onClick={() => confirmRemove(showWarning.category.category_id)}
                        >YES</PrimaryButton>
                        : null
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function mapStateToProps({user}) {
    return {user};
}
export default withRouter(connect(mapStateToProps)(MainCategories))