import {
    MEMBER_ASSESSMENTS,
    SINGLE_MEMBER_ASSESSMENT,
} from "../storeConstants";

// memberAssessments
export const memberAssessmentsReducer = (state=[], action) => {
    switch(action.type){
        case MEMBER_ASSESSMENTS:
            return action.payload;

        default:
            return state;
    }
};

// singleMemberAssessment
export const singleMemberAssessmentReducer = (state=null, action) => {
    switch(action.type){
        case SINGLE_MEMBER_ASSESSMENT:
            return action.payload;
        default:
            return state;
    }
};