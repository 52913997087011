import React from 'react';
import {withRouter} from 'react-router-dom';
import Reply from './Reply';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';

class NewMessageMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            reply: '',
            username: '',
            emailArr: [],
            // recipient:"clairekir"
        }
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    handleDismiss() {
        this.setState({show: false});
    }

    handleShow() {
        this.setState({show: true});
    }

    titleHandler = (title) => {
        this.setState({title: title})
    }

    render() {
        let changeAlertStyle = this.state.alertStyle ? "success" : "danger";
        return (
            <div className="reply-container large">
                <div className="">
                    {this.props.user
                        ? <Reply
                            recipientHandler={this.props.recipientHandler}
                            filteredRecipients={this.props.filteredRecipients}
                            username={this.props.username}
                            titleHandler={this.titleHandler}
                            replyHandler={this.props.replyHandler}
                            message={this.props.message}

                        />
                        : null
                    }
                    <div className="row">
                        <div className="col-md-3">
                            {this.state.show
                                ? <Alert className="col-xs-12 save-profile-alert" bsStyle={changeAlertStyle}
                                         onDismiss={this.handleDismiss}>
                                    <p>{this.state.alert}</p>
                                </Alert>
                                : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapPropsToState({user, message}) {
    return {user, message}
}

export default withRouter(connect(mapPropsToState)(NewMessageMain));