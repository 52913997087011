import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Slider from 'rc-slider';
import axios from 'axios';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import {connect} from 'react-redux';
import Confetti from 'react-dom-confetti';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// styles
import './clientAddBar.css';

// actions
import { setChangedScoreFlag } from "../../../store/actions/wellnessScoreActions";

// components
import Error from '../../ErrorMessage/Error';
import alternativeCheckmark from '../../../images/checkmark.png';

let bar;
let red;
let yellow;
let green;
let styles;


const config = {
  angle: 90,
  spread: 108,
  startVelocity: 90,
  elementCount: 250,
  decay: 0.9,
  colors: [
      '#fff',
      '#00325c',
      '#7eaf5b',
      '#c4b02d'
  ]
};

let marks;

class ClientAddBar extends Component{
    constructor(props){
        super();
        this.state={
            confetti:false,
            show:false,
            startDate:moment(),
            scaleStart:props.scaleStart,
            scaleEnd:props.scaleEnd,
            planId:props.planId,
            tileId:props.tileId,
            steps:props.incrementer,
            marks:{},
            sliderValue:props.scaleStart,
            streak:'',
            streakRecord:'',
            previous_score:0,
            disable:false,
            data_i_need:null,
            data_i_need_2:null,

            //error message for date picker
            date_message : null,
        };
        this.changeValue=this.changeValue.bind(this);
        this.updateInput=this.updateInput.bind(this);
        this.modalHandleShow = this.modalHandleShow.bind(this);

    }

    submitActivity(){
        if(this.state.date_message){
            //prevent submission due to error in date format
            return;
        }

        if(this.props.user.id === undefined){
            this.setState({noUserId: "User ID is undefined."})
            return;
        }

        if(this.props.user.id === null){
            this.setState({noUserId: "User ID is null."})
            return;
        }

        if(this.state.planId === undefined){
            this.setState({noUserId: "planId is undefined."})
            return;
        }

        if(this.state.planId === null){
            this.setState({noUserId: "planId is null."})
            return;
        }

        if(this.state.sliderValue === undefined){
            this.setState({noUserId: "sliderValue is undefined."})
            return;
        }

        if(this.state.sliderValue === null){
            this.setState({noUserId: "sliderValue is null."})
            return;
        }
        this.setState({disabled: true});
        if (this.state.disabled) return;

        let sliderValue = this.state.sliderValue;
        if(this.state.sliderValue === -0){
            sliderValue = Math.abs(sliderValue);
        };
        // this.setState({data_i_need_2:this.state.startDate.format()});
        //this.state.startDate will be set to UTC, we want local, this strips it of timezone info
        let submission_date = this.state.startDate;
        //make sure time is after 2am turn over for previous days,
        //other wise it will get marked for the day previous
        if(submission_date.isBefore(moment(), 'day')){
            submission_date.hour(12);
        }
        submission_date = submission_date.format('YYYY-MM-DDTHH:mm:ss');

        //remomentize date, this time with local timezone
        submission_date = moment(submission_date).format();
        this.props.setChangedScoreFlag(this.props.dailyScore);
        axios.post(`/tiles/add-activity/${this.props.user.id}`, {
            activityDate:submission_date,
            planId:this.state.planId,
            value:sliderValue
        })
            .then(res=>{
                this.setState({disabled: false});
                this.streakData();
                this.modalHandleShow();
                setTimeout(function(){
                    this.props.history.push(`/member/clientDash/${this.props.user.username}/habits`);
                }.bind(this),3500);
            })
            .catch(err=>{
                this.setState({disabled: false});
                this.setState({add_activity_error:err.response.data.message})
            });
    }

    streakData(){
        axios.get(`/score/streak`)
        .then((res => {
            this.setState({streak:res.data.results.currentStreak});

            let record = res.data.results.longestStreak;
            if(this.state.streak < record){
                record = record - this.state.streak;
                this.setState({streakRecord:`Only ${record} more days to beat your record`})
            }
            else{
                this.setState({streakRecord:`${record} is your record, keep it up`})
            }

        }))
    }

    goBackToTile(){
        if(this.props.category === 'introduction'){
            this.props.history.push(`/tile/intro/${this.state.tileId}`)
        }
        else{
            this.props.history.push(`/tile/${this.state.tileId}`)
        }
    }

   changeValue(value) {
        this.setState({sliderValue:value});
    }

    updateInput(date){
        this.setState({
            startDate:date,
            sliderValue:0,
            data_i_need : date.format(),
        })
    }

    modalHandleShow() {
        this.setState({ show: true });
        this.setState({ confetti: true }, () => {
            this.setState({ confetti: false });
        });

    }

    componentDidMount(){
        let that=this;
        axios.get(`/plan/metric/plan/${this.props.planId}/${this.props.user.username}`)
            .then(res=>{
                if(res.data.result){
                    that.setState({sliderValue : res.data.result.plan_value});
                }
            });
        let i=this.state.scaleStart;
        let j = this.state.scaleEnd;
        let tempMarks = {};
        for(i; i<=j; i+=this.props.incrementer){
            tempMarks[i]=i
        }

        this.setState({marks:tempMarks});
    }

    goalBarGradient(){
     bar = this.state.scaleEnd - this.state.scaleStart;
     red = ((this.props.minimum - this.state.scaleStart)/bar*100);
     yellow = ((this.props.maximum - this.props.minimum)/bar*100);
     // red = ((this.props.minimum)/bar*100);
     // yellow = ((this.props.maximum)/bar*100);
     green = (red+yellow);
    }

    intro(){
         marks = {
          '-1': 'NO',
          '-0.1': 'SOMEWHAT',
          1: 'YES',
        };
    }

    handleChangeRaw=(value)=>{
        if(moment(value).isValid() && moment(value).isBefore(moment().add(1, 'day'), 'day')){
            //clear any error the date message may have had
            this.setState({date_message : null});
            //send value to the same method which updates a regular input from date picker
            value = moment(value);

            //make sure text inputs for today have time set to now
            if(value.isSame(moment(), 'day')){
                let milliseconds = moment().millisecond();
                let minutes = moment().minute();
                let seconds = moment().second();
                let hour = moment().hour();
                value.hour(hour).minute(minutes).seconds(seconds).millisecond(milliseconds);
            }

            this.updateInput(value);
        }else{
            //set error message for date picker having wrong type of value
            this.setState({date_message : 'Please enter a valid date before now'});
        }
    };
/* eslint-disable no-dupe-keys */
    gradientStyles(){
      if(this.props.status === "disabled"){
         styles = {
         background: `#000000`,
         background: `-moz-linear-gradient(left, #333 0%, #ddd 50%, #333 100%)`,
         background: `-webkit-linear-gradient(left, #333 0%,#d6d6d6 50%,#333 100%)`,
         background: `linear-gradient(to right, #333 0%,#d6d6d6 50%,#333 100%)`,
         filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 )`,
         };
      }
      else if(this.props.inverted){
        styles = {
          background: `-webkit-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-webkit-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `-o-linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #ff3232 ${green}%, #ff3232 100%)`,
          background: `linear-gradient(to right, #27dd1a 0%, #27dd1a ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #ff3232 ${green}%, #ff3232 100%)`
        }
      }
      else{
        styles = {
          background: `-webkit-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-webkit-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `-o-linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #27dd1a ${green}%, #27dd1a 100%)`,
          background: `linear-gradient(to right, #ff3232 0%, #ff3232 ${red}%, #fff130 ${red+4}%, #fff130 ${green-3}%, #27dd1a ${green}%, #27dd1a 100%)`
        }
      }
    };

    render(){
      this.intro()
      this.goalBarGradient()
      this.gradientStyles()
      let handleColor = '#F9EB2E';
      if (this.state.sliderValue <= this.props.minimum) handleColor = this.props.inverted ? '#23C916' : '#ff0000';
      if (this.state.sliderValue >= this.props.maximum) handleColor = this.props.inverted ? '#ff0000' : '#23C916';
      if(this.props.status === "disabled") handleColor = '#575757';
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        return(
            <div>
            {this.props.category === 'introduction'
                ? <Slider
                    defaultValue={1}
                    className="activitySlider"
                    min={this.state.scaleStart}
                    max={this.state.scaleEnd}
                    step={2}
                    marks={marks}
                    onChange={this.changeValue}
                    style={styles}
                    handleStyle={{backgroundColor: handleColor}}
                />
                : <Slider
                    className="activitySlider"
                    min={this.state.scaleStart}
                    max={this.state.scaleEnd}
                    step={this.props.incrementer}
                    marks={this.state.marks}
                    onChange={this.changeValue}
                    style={styles}
                    value={this.state.sliderValue || 0}
                    handleStyle={{backgroundColor: handleColor}}
                 />
            }
                <p className="plan--units">{this.props.planUnits}</p>
                <div className='flex-center'>
                    <Error message={this.state.date_message}/>
                    <Error message={this.state.add_activity_error}/>
                    <Error message={this.state.noUserId}/>
                </div>
                <DatePicker
                    withPortal
                    readOnly={false}
                    selected={this.state.startDate}
                    onChange={this.handleChangeRaw}
                    filterDate={(date) => {
                      return moment() > date;
                    }}
                    onChangeRaw={(event) =>
                        this.handleChangeRaw(event.target.value)}
                />

                <Modal
                    {...this.props}
                    show={this.state.show}
                    dialogClassName="modal--added-activity"
                    style={{
                        position:'fixed',
                        width:'100%',
                        height:'100%',
                        margin:0,
                        padding:0
                    }}
                >
                    <div className="confetti-overlay">
                        { isIE11 ? <div style={{width:1, height: 1}}/> : <Confetti active={ this.state.confetti } config={ config }  />}
                    </div>
                    <Modal.Body>
                        <div className="success-icon">
                            { isIE11 ? <img className="alternateCheckmark"  src={alternativeCheckmark} alt="checkmark"/>
                            : <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                </svg>
                            }
                        </div>
                        {this.state.streak === 1
                          ? <h2><strong>{this.state.streak}</strong> Day In A Row</h2>
                          : <h2><strong>{this.state.streak}</strong> Days In A Row</h2>
                        }
                        <p>Keep Up The Momentum</p>
                        <p>{this.state.streakRecord}</p>
                    </Modal.Body>
                </Modal>

                <button disabled={this.state.disabled} className='btn primary add--icon' onClick={
                    ()=>{this.submitActivity(this); if(!this.state.date_message) this.setState({disabled:true})}
                }>ADD</button>
            </div>
        );
    }
}

function mapStateToProps({user, dailyScore}) {
    return {user, dailyScore}
}

const actions = {
    setChangedScoreFlag
}

export default withRouter(connect(mapStateToProps, actions)(ClientAddBar));
