//client detail subheader component
import React from "react";
import "./ClientSubHeader.css";
import Tiles from "../tiles/tiles";
import { withRouter } from "react-router-dom";

let btnClass1;
let btnClass2;

class ClientSubHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userId: "",
      btnClass: "",
      activeTiles: true,
    };
  }

  btnClass() {
    if (this.state.activeTiles === true) {
      btnClass1 = "btn margin-right";
      btnClass2 = "";
    } else {
      btnClass2 = "btn margin-left";
      btnClass1 = "";
    }
  }

  render() {
    this.btnClass();
    return (
      <div>
        <div className="client-subheader-content subheader--desktop hidden-xs hidden-sm">
          <div className="container">
            <div className="row">
              {this.props.clientDashChild ? (
                <div>
                  <div className="col-xs-12">
                    <button
                      className={btnClass1}
                      onClick={(event) => this.setState({ activeTiles: true })}
                    >
                      Current Tiles
                    </button>
                    <button
                      className={btnClass2}
                      onClick={(event) => this.setState({ activeTiles: false })}
                    >
                      Completed Tiles
                    </button>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        <div className="container client-dash margin-top-3x">
          {this.props.clientDashChild ? (
            <Tiles
              username={this.props.username}
              activeTiles={this.state.activeTiles}
            />
          ) : null}
          <div className="client-subheader-content visible-xs visible-sm">
            <div className="row">
              {this.props.clientDashChild ? (
                <div>
                  <div className="col-xs-6">
                    <button
                      className={btnClass1}
                      onClick={(event) => this.setState({ activeTiles: true })}
                    >
                      Current Tiles
                    </button>
                  </div>
                  <div className="col-xs-6 text-right">
                    <button
                      className={btnClass2}
                      onClick={(event) => this.setState({ activeTiles: false })}
                    >
                      Completed Tiles
                    </button>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClientSubHeader);
