import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { Button, Navbar, Nav} from 'react-bootstrap';
import '../header.css';
import '../treo.css';

// let token = localStorage.getItem("key");
// let currentUser = localStorage.getItem("user");
let currentUser = "Coach";

//currentUser will be user from localStorage, 
export class HeaderCoach extends React.Component {
	constructor() {
		super();
		if(currentUser) {
			this.state={loggedIn: true};
		}
		else {
			this.state={loggedIn: false};
		}
    	this.logOutUser=this.logOutUser.bind(this)
	}


  	logOutUser(e) {
  		e.preventDefault();
        axios.get('/logout')
            .then(res=>{
                if(res.status === 200) window.location.assign('/');
            })
            .catch()
      }

	render() {
		// eslint-disable-next-line
		let loggedInMessage;
		// eslint-disable-next-line
		let logOutButton;
		if(this.state.loggedIn) {
			loggedInMessage = <h3 className="current-user">{currentUser}</h3>
			logOutButton = <input type="submit" value="Log Out"/>
		}
		else {
			window.location.assign('/');
		}

    return (
      <Navbar inverse collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="#brand"><img src={require('./header-logo.png')} alt='Treo Logo' /></a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <Link to='/coachDash'>
                    Dashboard
            </Link>
              <Link to='/coach/ClientSearch'>
                      Members
              </Link>
          </Nav>
        
          <form className="logout-form" onSubmit={this.logOutUser}>
	          <Navbar.Form pullRight>
	            <h4 className="header-text user">{currentUser}</h4>
	            <Button type="submit">Log Out</Button>
	          </Navbar.Form>
          </form>
          </Navbar.Collapse>
      </Navbar>
    );
  }
}

// 		return(
// 			<div>
// 				<header>
// 					<ul className="header-ul">
// 						<li className="header-li logo">
// 							<h3 className="header-text" href='#'>Treo Logo</h3>
// 						</li>
// 						<li className="header-li links">
// 							<a className="header-text" href='/adminDash'>Dashboard</a>
// 						</li>
// 						<li className="header-li links">
// 							<a className="header-text" href='/clientSearch'>Coaches</a>
// 						</li>	
// 						<li className="header-li links">
// 							<a className="header-text" href='/clientSearch'>Clients</a>
// 						</li>					
// 						<li className="header-li profile">
// 							<h4 className="header-text user">{currentUser}</h4>
// 							<div className="log-button">
// 								<div>
// 					              <form onSubmit={this.logOutUser}>
// 					                <div>
// 					                    {logOutButton}
// 					                </div>
// 					              </form>
// 					            </div>
// 							</div>
// 						</li>
// 					</ul>
// 				</header>
// 			</div>
// 			);
// 	}
// };

	// something like this to authorize user
	// ComponentDidMount() {
	// 	let token = localStorage.getItem('key');
	// 	fetch(URL + 'tiles', {
	// 		method: 'GET', 
	// 		headers: {
	// 			'Authorization': `bearer ${token}`;
	// 		}
	// 	})
	// }



	//