import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

// styles
import styles from "./planSelection.module.css";

const PlanSelection = props => {
    // state
    const { user } = props;
    // props
    const { tile, setPlan, history } = props;

    return (
        <div>
            <div className={styles.selectionContainer}>
                <img
                    className={styles.icon}
                    style={{ background: tileColor(tile.tile.tile_category) }}
                    src={tileIcon(null, tile.tile.tile_category)}
                    alt={`${tile.tile.tile_category}`}/>
                <p className={styles.group}>{tile.tile.tile_category}</p>
                <h3 className={styles.action}>Select Your Activity</h3>
                <div className={styles.buttonContainer}>
                    { tile.plan.map(plan => {
                        return (<button key={plan.id} className={styles.button} onClick={() => {setPlan(plan)}}>{plan.description}</button>)
                    })}
                </div>
            </div>
            <div className="footer-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6">
                            <button className="btn client-white" onClick={()=>history.push(`/member/clientDash/${user.username}/habits`)}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

export default withRouter(connect(mapStateToProps)(PlanSelection));

function tileIcon (icon, tile) {
    const mealtimeSpacingIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/plan_icons/icon-mealtimespacing.png';
    const sprintActivityIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/plan_icons/icon-sprintintensityactivity.png';
    const veggiesIcon = 'https://treo.s3.us-east-2.amazonaws.com/assets/plan_icons/icon-veggie%26fruitservings.png';

    switch(tile) {
        case 'what you do':
            return sprintActivityIcon;
        case 'when you eat':
            return mealtimeSpacingIcon;
        case 'what you eat':
            return veggiesIcon;
        //no default
    }
}

function tileColor (tile) {
    switch(tile) {
        case 'what you do':
            return 'rgba(80, 135, 160, .12)';
        case 'when you eat':
            return 'rgba(198, 177, 47, .12)';
        case 'what you eat':
            return 'rgba(106, 163, 66, .12)';
        //no default
    }
}