import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setMessage} from "../../../actions/action_message";
import PhotoContainer from './container';

 class ArchivedMessages extends Component{
    constructor(){
        super();
           this.state = {
       };
    }

  moveToSingleMessage(email){
    email=this.props.setMessage(this.props.email) 
    console.log(email)   
        this.props.history.push({
            pathname: `/member/clientDash/${this.props.username}/inbox-message`,
            state: {
                archived:true,         
            }
        })
  }

    render(){
      return(   
          <li className="tiles-li icon--left archived-tile  col-xs-12" onClick={()=>this.moveToSingleMessage()}>
                <div className={"tile"}>
                    <div className="tile-icon">                                  
                          <PhotoContainer
                            image={this.props.profile_pic}
                          />                             
                    </div>
                    <div className="tile-titles">
                      <h4><span className="archived icon-archive"></span>{this.props.title}</h4>
                      <p>{this.props.time} - Archived</p>
                    </div>                        
                </div>
          </li>
      )
    }
}

function mapPropsToState({}){
    return {}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({setMessage}, dispatch);
}

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ArchivedMessages));