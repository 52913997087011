import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ClientHeader from "../../clientHeader/clientHeader";
import _ from 'lodash';
import axios from 'axios';
import Error from "../../ErrorMessage/Error";
import {fetchSelf} from "../../../actions/action_user";

class ScheduleContainer extends Component{
    constructor(props){
        super(props);
        this.state={
          coach:null,
          resource:null,
          noCoach: false
        };
    }

    componentDidMount(){
        console.log('container')
        this.props.fetchSelf();
        axios.get('/score/wellness-score')
            .then(
                res=>{
                    this.setState({
                        wellnessScore:res.data.results.score
                    });
                }
            )
            .catch(err=>console.log('ERROR: ', err));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.resource) {
            if(this.props.location.state !== undefined){
                axios.get( `/calendar/resources?username=${this.props.location.state.coachUsername}`)
                    .then(result=>{
                        this.setState({resource:result.data.result.id})
                    })
                    .catch(e=>{
                        this.setState({noCoach:true});
                    });
            }
            else if(!_.isEmpty(this.props.coach)){
                //call for coaches resource id based on username
                axios.get( `/calendar/resources?username=${this.props.coach.username}`)
                    .then(result=>{
                        this.setState({resource:result.data.result.id})
                    })
                    .catch(e=>{
                        this.setState({noCoach:true});
                    });
            }else{
                let username = this.props.match.params.username;
                this.props.history.push(`/member/clientDash/${username}/profile`);
            }
        }
    }

    renderContainer=()=>{
        const resource = this.state.resource;

        //prevent state loop as no coach is available
        if(this.state.noCoach) return;

        //render calendar container based on current state
        if(resource){
            return <div/>
        }else{
            return <div/>
        }
    };

    render(){
        return(
            <div className="client-container">
                <ClientHeader backUrl={`/member/clientDash/${this.props.user.username}/coaching-team/coaches`}/>
                <Error message={this.state.message}/>
                {this.state.noCoach
                    ? <div className="margin-top margin-left">
                          <p>You do not have an assigned coach, please send an email to <a href="mailto:info@treowellness.com">info@treowellness.com</a> with your full name and phone number, and a coach will follow-up with you.</p>
                          <p>Thank you,</p>  
                          <p>Treo Coaches</p>   
                      </div>
                    : null
                }
                {this.renderContainer()}
            </div>
        )
    }
}

const mapStateToProps=({user, coach})=>{
    return{user,coach};
};


export default withRouter(connect(mapStateToProps, {fetchSelf})(ScheduleContainer));