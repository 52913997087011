import React from 'react';

// styles
import styles from './newThreadBox.module.css';

// components
import {BoxContainer} from "../../containers/boxContainer/boxContainer";
import NewMessage from "../newMessage/newMessage";
import {ReactComponent as ExitIcon} from "../../../images/times-solid.svg";

const NewThreadBox = props => {
    // props
    const {close, newThreadCallback} = props;

    return (
        <BoxContainer className={styles.container}>
            <div className={styles.header}>
                <div className={styles.buttons}>
                    <button onClick={close} className={styles.close}><ExitIcon/></button>
                </div>
            </div>
            {/* New */}
            <div>
                <NewMessage
                    btn='Send New Message'
                    cancel={close}
                    refreshThread={val=>newThreadCallback(val, true)}
                />
            </div>
        </BoxContainer>
    )
}

export default NewThreadBox;