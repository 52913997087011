import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import ContentDisplayContainer from '../../../contentDisplay/MainContainer';
import {connect} from 'react-redux';
import {setIntroTile} from "../../../../actions/action_tile";

class TileList extends Component{
    constructor(props){
        super(props);
        this.state={
        }
    }
    renderTiles=()=>{
        let tiles = this.props.tiles;
        return tiles.map(tile=>{
            return(
                <li className="tile">
                    <div className="row">
                        <div className="col-sm-12 col-md-4" onClick={()=>this.singleTile(tile)}>
                                <h3>{tile.tile.tile_category}</h3>
                                <h4>{tile.plan.map(plan=>plan.description)}</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <ContentDisplayContainer categories='none' tile={tile.tile.id}/>
                        </div>
                    </div>
                </li>
            )
        });
    };

    singleTile=(tile)=>{
        this.props.setIntroTile(tile);
        this.props.history.push(`/dashboard/Tile-List/tile/${tile.tile.id}`);
    };


    render(){
        return(
            <div className="container margin-top-lrg">
                <div className="row">
                    <div className="col-sm-6">
                        <h2>{this.props.count} Intro Tiles</h2>
                    </div>
                    <div className="col-sm-6 text-right margin-top">
                        <Link to='/dashboard' className={'btn primary'} >Edit Active Intro Tile</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="tiles-ul">
                            {this.renderTiles()}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null,{setIntroTile})(TileList));